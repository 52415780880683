import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL, FRONTEND_URL, IMAGE_URL } from '../../config/axios';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { handleAllError, handleErrorToast } from '../CustomFunctions';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { PieChart, Pie, Cell } from 'recharts';
import { Arrow, UpdatePencil } from '../AllSvg';
import { padding } from '@mui/system';

const ProfileProgressChart = ({ percentage, color, imageUrl }) => {

  const fillRotation = (percentage > 50 ? 180 + (percentage - 50) * 3.6 : percentage * 3.6);
  const chartSize = 100;
  const radius = chartSize / 2;

  const chartStyle = {
    width: `${chartSize}px`,
    height: `${chartSize}px`,
    position: 'relative',
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    margin: '10px auto 0',
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '70%',
    height: '70%',
    position: 'absolute',
    top: '15%',
    left: '15%',
    borderRadius: '50%',
    zIndex: 1,
  };

  const percentStyle = {
    position: 'absolute',
    bottom: '10%',
    right: '10%',
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: color,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '0px 5px',
    zIndex: 2,
  };

  return (
    <div className="progress-profile-chart" style={{ "--color": color }}>
      <PieChart width={chartSize} height={chartSize}>
        <Pie
          data={[{ value: percentage }, { value: 100 - percentage }]}
          innerRadius={radius - 10}
          outerRadius={radius}
          startAngle={90}
          endAngle={-270}
          cornerRadius={20}
        >
          <Cell fill={color} />
          <Cell fill="#EFEFEF" />
        </Pie>
      </PieChart>
      {/* <a href={`${FRONTEND_URL}/userdetail/`}> */}
      <img src={imageUrl} alt="profile" />
      {/* </a> */}
      <div className="progress-profile-percent">
        {percentage?.toFixed(2)}%
      </div>
    </div>
  );
};

const ProfileProgressIcon = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [progress, setProgress] = useState();
  const [profileProgress, setProfileProgress] = useState();
  const [bankProgress, setBankProgress] = useState();
  const [hoveredItem, setHoveredItem] = useState(null);  // For tracking hover state

  const routes = [
    { name: 'Personal Details', progress: 8, route: `${FRONTEND_URL}/personalDetailss/${sessionStorage.getItem('email')}/` },
    { name: 'Education Details', progress: 100, route: `${FRONTEND_URL}/educationDetailss/${sessionStorage.getItem('email')}/` },
    { name: 'Work Experience', progress: 40, route: `${FRONTEND_URL}/workexperiencee/${sessionStorage.getItem('email')}/` },
    { name: 'Emergency Contacts', progress: 70, route: `${FRONTEND_URL}/emergencycontacts/${sessionStorage.getItem('email')}/` },
    { name: 'Account Details', progress: 50, route: `${FRONTEND_URL}/accountdetailss/${sessionStorage.getItem('email')}/` },
    { name: 'Documents', progress: 30, route: `${FRONTEND_URL}/documentdetailss/${sessionStorage.getItem('email')}/` },
  ];
  let totalprogress = 90

  const fetchData = async () => {
    setBuffering(true); // Start Buffering
    try {
      const profileprogress = await axios.get(`${BASE_URL}/userdata/newoldusercompletion/${sessionStorage.getItem('email')}/`);
      setProfileProgress(profileprogress.data);
      const bankprogress = await axios.get(`${BASE_URL}/userdata/profilepercentageBank/${sessionStorage.getItem('emp_code')}`);
      setBankProgress(bankprogress.data);

    } catch (err) {
      // handleAllError(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getProgressColor = (percentage) => {
    // if (percentage < 10) return '#ff747c';
    if (percentage < 33) return '#ff747c';
    if (percentage < 66) return '#f7ba1e';
    return '#06ad06';
  };

  return (
    <>
      <div className="navbar-logo-icon" style={{ position: 'relative' }}>
        <img
          src={`${sessionStorage.getItem("profilepic") ? `${IMAGE_URL}${sessionStorage.getItem("profilepic")}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}`}
          alt="user"
          className="navbar-user-login"
        />

        <div className="user-profile-progress custom-notify-modal ">
          {/* Profile Details Completion status */}
          <h1 className="task-Tab-heading align-center font-weight500 font-size-heading">
            <Modal.Title>Profile Status
            </Modal.Title>
          </h1>

          <ProfileProgressChart percentage={totalprogress} color={getProgressColor(totalprogress)} imageUrl={`${IMAGE_URL}${sessionStorage.getItem("profilepic")}`} />

          {routes.map((item, index) => (
            <div className='flex-row align-center progress-item' key={index} >
              <div style={{ flex: '1', textAlign: 'left' }}>
                <div className='justify-between' style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                  <span>{item.name}</span>
                  <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>{item.progress}%</span>
                </div>
                <div style={{
                  width: '100%',
                  height: '5px',
                  backgroundColor: '#EFEFEF',
                  borderRadius: '5px',
                  position: 'relative',
                }}>
                  <div style={{
                    width: `${item.progress}%`,
                    height: '100%',
                    backgroundColor: getProgressColor(item.progress),
                    borderRadius: '15px',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }} />
                </div>
              </div>
              <a
                href={item.route}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
                className="progress-link"
              >
                {item.progress === 100 ?
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    id="tick"
                    style={{
                      fill: hoveredItem === index ? '#fff' : getProgressColor(item.progress),
                      backgroundColor: hoveredItem === index ? getProgressColor(item.progress) : '#fff',
                      borderRadius: '30px',
                    }}>
                    <path fill={hoveredItem === index ? '#fff' : getProgressColor(item.progress)} fill-rule="evenodd" d="M16.7724 5.21182C17.0696 5.5003 17.0767 5.97513 16.7882 6.27236L8.53819 14.7724C8.39571 14.9192 8.19945 15.0014 7.99489 15C7.79032 14.9986 7.5952 14.9137 7.45474 14.765L3.20474 10.265C2.92033 9.96383 2.9339 9.48915 3.23503 9.20474C3.53617 8.92033 4.01085 8.93389 4.29526 9.23503L8.0074 13.1655L15.7118 5.22764C16.0003 4.93041 16.4751 4.92333 16.7724 5.21182Z" clip-rule="evenodd"></path>
                  </svg> :
                  < svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                    id="arrow"
                    style={{
                      fill: hoveredItem === index ? '#fff' : getProgressColor(item.progress),
                      backgroundColor: hoveredItem === index ? getProgressColor(item.progress) : '#fff',
                      borderRadius: '30px',
                    }}
                  >
                    <path fill="none" d="M0 0h48v48H0z" />
                    <path d="m24 8-2.83 2.83L32.34 22H8v4h24.34L21.17 37.17 24 40l16-16z" />
                  </svg>
                }
              </a>
            </div>
          ))}

        </div>
      </div >

    </>
  );
};


const ProfileProgressModal = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [progress, setProgress] = useState();
  const [profileProgress, setProfileProgress] = useState();
  const [bankProgress, setBankProgress] = useState();
  const [hoveredItem, setHoveredItem] = useState(null);  // For tracking hover state

  // const routes = [
  //   { name: 'Personal Details', progress: 8, route: `${FRONTEND_URL}/personalDetailss/${sessionStorage.getItem('email')}/` },
  //   { name: 'Education Details', progress: 100, route: `${FRONTEND_URL}/educationDetailss/${sessionStorage.getItem('email')}/` },
  //   { name: 'Work Experience', progress: 40, route: `${FRONTEND_URL}/workexperiencee/${sessionStorage.getItem('email')}/` },
  //   { name: 'Emergency Contacts', progress: 70, route: `${FRONTEND_URL}/emergencycontacts/${sessionStorage.getItem('email')}/` },
  //   { name: 'Account Details', progress: 50, route: `${FRONTEND_URL}/accountdetailss/${sessionStorage.getItem('email')}/` },
  //   { name: 'Documents', progress: 30, route: `${FRONTEND_URL}/documentdetailss/${sessionStorage.getItem('email')}/` },
  // ];

  const routes = [
    { name: 'Personal Details', progress: profileProgress?.personal_data?.completion_percentage, route: `${FRONTEND_URL}/personalDetailss/${sessionStorage.getItem('email')}/` },
    { name: 'Education Details', progress: profileProgress?.education_details?.completion_percentage, route: `${FRONTEND_URL}/educationDetailss/${sessionStorage.getItem('email')}/` },
    { name: 'Work Experience', progress: profileProgress?.work_experience_details?.completion_percentage, route: `${FRONTEND_URL}/workexperiencee/${sessionStorage.getItem('email')}/` },
    { name: 'Emergency Contacts', progress: profileProgress?.emergency_contact_details?.completion_percentage, route: `${FRONTEND_URL}/emergencycontacts/${sessionStorage.getItem('email')}/` },
    { name: 'Account Details', progress: bankProgress?.completion_percentage, route: `${FRONTEND_URL}/accountdetailss/${sessionStorage.getItem('email')}/` },
    { name: 'Documents', progress: profileProgress?.document_details?.completion_percentage, route: `${FRONTEND_URL}/documentdetailss/${sessionStorage.getItem('email')}/` },
  ];

  console.log("routes")
  console.log(routes)
  // let totalprogress = 90
  const totalProgress = routes
    .map(route => route.progress) // Get all progress values
    .filter(progress => progress !== undefined && progress !== null) // Filter out undefined or null progress
    .reduce((acc, progress) => acc + progress, 0); // Sum the remaining values

  const numberOfValidEntries = routes.filter(route => route.progress !== undefined && route.progress !== null).length;

  const overallCompletion = (numberOfValidEntries > 0) ? (totalProgress / numberOfValidEntries) : 0;

  console.log("overallCompletion")
  console.log(overallCompletion)
  const fetchData = async () => {
    setBuffering(true); // Start Buffering
    try {
      // setProfileProgress([]);
      const profileprogress = await axios.get(`${BASE_URL}/userdata/newoldusercompletion/${sessionStorage.getItem('email')}/`);
      setProfileProgress(profileprogress.data);
      // setBankProgress([]);
      const bankprogress = await axios.get(`${BASE_URL}/userdata/profilepercentageBank/${sessionStorage.getItem('emp_code')}`);
      setBankProgress(bankprogress.data);


    } catch (err) {
      // handleAllError(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const getProgressColor = (percentage) => {
  //   // if (percentage < 10) return '#ff747c';
  //   if (percentage < 33) return '#ff747c';
  //   if (percentage < 66) return '#f7ba1e';
  //   return '#06ad06';
  // };

  const getProgressColor = (percentage) => {
    // if (percentage < 10) return '#ff747c';
    if (percentage <= 33) return '#ff747c';
    if (percentage <= 66) return '#f7ba1e';
    if (percentage <= 100) return '#06ad06';
    return '#2576bc';
  };


  return (
    <>
      <div className={`navbar-logo-icon ${overallCompletion >= 100 ? '' : 'navbar-svg-profile'}`} style={{ position: 'relative' }}>
        {
          overallCompletion >= 100 ? null : <span className="notification-count">!</span>}
        <img
          src={`${sessionStorage.getItem("profilepic") ? `${IMAGE_URL}${sessionStorage.getItem("profilepic")}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}`}
          // src={`${IMAGE_URL}${sessionStorage.getItem("profilepic")}`}
          alt="user"
          className="navbar-user-login"
        />

        <div className="user-profile-progress custom-notify-modal">
          {/* Profile Details Completion status */}
          <h1 className="task-Tab-heading align-center font-weight500 font-size-heading">
            <Modal.Title>Profile Status</Modal.Title>
          </h1>
          <ProfileProgressChart
            percentage={overallCompletion}
            color={getProgressColor(overallCompletion)}
            // imageUrl={`${IMAGE_URL}${sessionStorage.getItem("profilepic")}`}
            imageUrl={`${sessionStorage.getItem("profilepic") ? `${IMAGE_URL}${sessionStorage.getItem("profilepic")}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}`}
          />
          {routes.map((item, index) => (
            <div className='progress-item' key={index} >
              <div className="progress-info">
                <div className='progress-details'>
                  <span>{item.name}</span>
                  <span style={{ marginLeft: '10px' }}>{item?.progress?.toFixed(2)}%</span>
                </div>
                <div className="progress-bar">
                  <div
                    className="progress-fill"
                    style={{
                      width: `${item.progress}%`,
                      backgroundColor: getProgressColor(item.progress),
                    }} />
                </div>
              </div>
              <a
                href={item.route}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
                className="progress-link"
              >
                {item.progress === 100 ?
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    id="tick"
                    style={{
                      fill: hoveredItem === index ? '#fff' : getProgressColor(item.progress),
                      backgroundColor: hoveredItem === index ? getProgressColor(item.progress) : '#fff',
                      borderRadius: '30px',
                    }}>
                    <path fill={hoveredItem === index ? '#fff' : getProgressColor(item.progress)} fill-rule="evenodd" d="M16.7724 5.21182C17.0696 5.5003 17.0767 5.97513 16.7882 6.27236L8.53819 14.7724C8.39571 14.9192 8.19945 15.0014 7.99489 15C7.79032 14.9986 7.5952 14.9137 7.45474 14.765L3.20474 10.265C2.92033 9.96383 2.9339 9.48915 3.23503 9.20474C3.53617 8.92033 4.01085 8.93389 4.29526 9.23503L8.0074 13.1655L15.7118 5.22764C16.0003 4.93041 16.4751 4.92333 16.7724 5.21182Z" clip-rule="evenodd"></path>
                  </svg> :
                  < svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                    id="arrow"
                    style={{
                      fill: hoveredItem === index ? '#fff' : getProgressColor(item.progress),
                      backgroundColor: hoveredItem === index ? getProgressColor(item.progress) : '#fff',
                      borderRadius: '30px',
                    }}
                  >
                    <path fill="none" d="M0 0h48v48H0z" />
                    <path d="m24 8-2.83 2.83L32.34 22H8v4h24.34L21.17 37.17 24 40l16-16z" />
                  </svg>
                }
              </a>
            </div>
          ))}

        </div>
      </div >
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export { ProfileProgressModal };
