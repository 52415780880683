import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import usePermission from "../../config/permissions";

const Assets = (email, emp_code) => {
  const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook
  const [assetname, setAssetname] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getAsset = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/assetbyemployee/${sessionStorage.getItem("emp_code")}/`
        // `${BASE_URL}/wfm/assetbyemployee/${emp_code}/`
      );
      setAssetname(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getAsset();
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Asset ({assetname.length})</div>
          {assetname.length < 1 ? "No System Alloted" : ""}
          <div></div>
        </div>

        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr. No.</th>

              <th className="align-center">Date</th>
              <th className="align-center">Description</th>
              <th className="align-center">Quantity</th>
              <th className="align-center">Item</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> :
            <tbody>
              {assetname.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>

                    <td className="align-center">{i.date}</td>
                    <td className="align-center">{i.description}</td>
                    <td className="align-center">{i.quantity}</td>
                    <td className="align-center">{i.item}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>}
        </table>
      </div>
    </>
  );
};

export default Assets;
