import React, { useEffect, useState } from 'react';
import { hierarchy, tree } from 'd3-hierarchy';
import axios from 'axios';
import { Arrow, DropdownArrow, Employee, Search, SearchBig, Sort } from '../AllSvg';
import { BASE_URL } from '../../config/axios';
import * as d3 from 'd3';
import { select } from 'd3-selection';
import { zoom as d3Zoom } from 'd3-zoom';
import { linkHorizontal } from 'd3-shape';

const HierarchyChart = () => {
  const [data, setData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/wfm/herasubcomp/${sessionStorage.getItem('company_id')}/`
        );
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      drawChart();
    }
  }, [data]);

  const drawChart = () => {
    if (!data || !data[0]) {
      console.warn('No data available for chart rendering');
      return;
    }

    d3.select('#svgChart').selectAll('*').remove();

    const svg = select('#svgChart')
      .append('svg')
      .attr('width', window.innerWidth - 20)
      .attr('height', window.innerWidth - 20)
      .attr('viewBox', `0 0 ${window.innerWidth - 20} 700`)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .append('g');

    const zoom = d3Zoom()
      .scaleExtent([0.5, 2])
      .on('zoom', (event) => {
        svg.attr('transform', event.transform);
      });

    select('#svgChart svg').call(zoom);

    const hierarchyData = hierarchy(data[0], d => d.subordinates);
    const treeLayout = tree().size([window.innerWidth - 100, 600]);
    const root = treeLayout(hierarchyData);

    svg.selectAll('.link')
      .data(root.links())
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', d => linkHorizontal()
        .x(d => d.x)
        .y(d => d.y)({
          source: d.source,
          target: d.target,
        })
      );

    const node = svg.selectAll('.node')
      .data(root.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${d.x},${d.y})`);

    node.append('rect')
      .attr('x', -70)
      .attr('y', -45)
      .attr('width', 140)
      .attr('height', 110)
      .attr('class', 'node-rect');

    node.append('image')
      .attr('xlink:href', d => {
        const profilePicUrl = d.data.profilepic
          ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${d.data.profilepic}`
          : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
        return profilePicUrl;
      })
      .attr('x', -25)
      .attr('y', -35)
      .attr('width', 50)
      .attr('height', 50)
      .attr('clip-path', 'circle(25px)');

    node.append('text')
      .attr('class', 'name')
      .attr('x', 0)
      .attr('y', 20)
      .text(d => d.data.user_detail?.name || 'Unnamed');

    node.append('text')
      .attr('class', 'position-name')
      .attr('x', 0)
      .attr('y', 35)
      .text(d => d.data.designation_name || 'No Position');

    node.append('text')
      .attr('class', 'area')
      .attr('x', 0)
      .attr('y', 50)
      .text(d => d.data.department_name || 'No Department');

    node.append('text')
      .attr('class', 'toggle-btn')
      .attr('x', 60)
      .attr('y', -20)
      .html(d => (d.children ? <DropdownArrow /> : d._children ? <DropdownArrow /> : ''))
      .style('cursor', 'pointer')
      .on('click', (event, d) => {
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else if (d._children) {
          d.children = d._children;
          d._children = null;
        }
        updateChart(d);
      });

    root.descendants().forEach(d => {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    const updateChart = source => {
      const nodes = root.descendants();
      const links = root.links();

      svg.selectAll('.link')
        .data(links)
        .join('path')
        .attr('class', 'link')
        .attr('d', d => linkHorizontal()
          .x(d => d.x)
          .y(d => d.y)({
            source: d.source,
            target: d.target,
          })
        );

      const nodeUpdate = svg.selectAll('.node')
        .data(nodes, d => d.id || (d.id = d.data.emp_code))
        .join('g')
        .attr('class', 'node')
        .attr('transform', d => `translate(${d.x},${d.y})`);

      nodeUpdate.select('.toggle-btn')
        .text(d => (d.children ? '-' : d._children ? '+' : ''));

      root.descendants().forEach(d => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    };

    updateChart(root);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleShowMyself = () => {
    const email = sessionStorage.getItem('email');
    const myProfile = findInTree(data[0], email)[0];
    if (myProfile) {
      setSelectedEmployee(myProfile);
    } else {
      console.warn('Profile not found');
    }
  };

  const closeSearchBox = () => {
    setShowSearchBox(false);
    setSearchValue('');
    setSearchResults([]);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);
    if (searchTerm.length >= 3) {
      const results = findInTree(data[0], searchTerm);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const findInTree = (root, searchText) => {
    const results = [];
    const regex = new RegExp(searchText, 'i');

    const search = (node) => {
      if (node && node.user_detail) {
        if (regex.test(node.user_detail.name) || regex.test(node.user_detail.employee)) {
          results.push(node);
        }
        if (Array.isArray(node.subordinates)) {
          node.subordinates.forEach(search);
        }
      }
    };

    search(root);
    return results;
  };

  const renderSearchResults = () => {
    return searchResults.map((result) => (
      <div className="list-item" key={result.emp_code}>
        <div className="image-wrapper">
          <img
            src={result.profilepic ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${result.profilepic}`
              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
            alt={result.user_detail.name}
            className="image"
          />
        </div>
        <div className="description">
          <p className="name">{result.user_detail.name}</p>
          <p className="position-name">{result.designation_name}</p>
          <p className="area">{result.department_name}</p>
        </div>
        <div className="buttons">
          <button className="btn-action btn-search-box" onClick={() => setSelectedEmployee(result)}>
            Locate
          </button>
        </div>
      </div>
    ));
  };

  return (
    <div id="full-container" className={isFullScreen ? 'full-screen' : ''}>
      <button className="btn-action btn-fullscreen" onClick={toggleFullScreen}>
        Fullscreen <Sort />
      </button>
      <button className="btn-action btn-show-my-self" onClick={handleShowMyself}>
        Show Myself <Employee />
      </button>
      <button className="btn-action btn-search" onClick={() => setShowSearchBox(!showSearchBox)}>
        Search <SearchBig />
      </button>
      <button className="btn-action btn-back" onClick={() => setSelectedEmployee(null)} style={{ display: selectedEmployee ? 'block' : 'none' }}>
        Back
      </button>

      <div className="department-information" style={{ display: selectedEmployee ? 'block' : 'none' }}>
        <div className="dept-name">{selectedEmployee?.department_name}</div>
        <div className="dept-description">Position: {selectedEmployee?.designation_name}</div>
        <div className="dept-emp-count">Reporting Employees: {selectedEmployee?.subordinates?.length || 0}</div>
      </div>

      {showSearchBox && (
        <div className="user-search-box open">
          <div className="input-box">
            <div className="close-button-wrapper" onClick={closeSearchBox}>
              <Arrow />
            </div>
            <div className="input-wrapper">
              <input
                type="text"
                className="search-input"
                placeholder="Search"
                value={searchValue}
                onChange={handleSearch}
              />
              <div className="input-bottom-placeholder">By Firstname, Lastname, Tags</div>
            </div>
          </div>
          <div className="result-box">
            <div className="result-header">RESULTS</div>
            <div className="result-list">{renderSearchResults()}</div>
          </div>
        </div>
      )}

      <div id="svgChart" style={{ marginTop: '20px', backgroundColor: 'white', width: '100%', height: '700px' }}></div>
    </div>
  );
};

export default HierarchyChart;
