import axios from "axios";
import { BASE_URL } from "../../config/axios";
import React, { useState, useEffect } from 'react';
import { formattedDateLong } from "../Date";
import { ToastContainer, toast } from "react-toastify";

const Performance = ({ email, emp_code }) => {

  const [performanceName, setPreformanceName] = useState([])
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getperformancename = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/performancebyemployee/${emp_code}`);
      setPreformanceName(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };


  useEffect(() => {
    getperformancename();
  }, []);


  return (
    <div className='performance-table-cont'>
      <div className="table-mainheading">
        <div>Total Awards({performanceName.length})</div>
      </div>
      {buffer ? <div className="spinner"></div> :

        <div className="awards-main-cont">
          {performanceName.map((item) => {
            return (
              <>
                <div className="awards-card">
                  <div className="primary-subcard">
                    <img src={item.profilepic} alt="Profile" className="performance-profile-img  width-5vw" />
                    <img src={item.image_reward} alt="Badge" className="performance-reward-img  width-5vw" />
                  </div>
                  <div className="secondary-subcard">
                    <div className="top-details">
                      <div className='hr-sep' />
                      <div className="award-title font-weight600  font-size-text ">{item.title}</div>
                      <div className='hr-sep' />
                    </div>
                    <div className="middle-details">
                      <div className='awards-cards-heading  font-weight500    font-size-label'>Reason for recognition</div>
                      <div className='  font-weight500  font-size-text '>{item.reason_for_recogntion}</div>
                    </div>
                    <div className="bottom-details">
                      <div className="bottom-subcards">
                        <div className='awards-cards-heading  font-weight500    font-size-label'>Recognized by</div>
                        <div className='  font-weight500  font-size-text '>{item.recognized_by}</div>
                      </div>
                      <div className="bottom-subcards">
                        <div className='awards-cards-heading  font-weight500    font-size-label'>Date</div>
                        <div className='  font-weight500  font-size-text '> {formattedDateLong(item.date)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>}
    </div>
  )
}

export default Performance;