import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import {
  Application,
  Applicationupdate,
  ApplicationDelete,
} from "./Application";
import { formatDate } from "../Date";
import usePermission from "../../config/permissions";

const Posting = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [toggleStatus, setToggleStatus] = useState("null");
  const [jobposition, setJobPosition] = useState([]);
  const [jobpositionList, setJobPositionList] = useState([]);

  const getJobpositionlist = async (toggleStatus) => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(
        PERMISSION_AUTHORITY ?
          `${BASE_URL}/jd/jobpostfilter/${toggleStatus}/${toggleCompany}/`
          :
          `${BASE_URL}/jd/jobpostfilter/${toggleStatus}/${sessionStorage.getItem("company_id")}/`
      );
      setJobPosition(res.data);
    } catch (err) {
      // alert(err.message);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const getCompleteJobpostlist = async () => {
    try {
      // const res = await axios.get(`${BASE_URL}/jd/jobpost/`);
      const res = await axios.get(
        PERMISSION_AUTHORITY ?
          `${BASE_URL}/jd/jobpostfilter/null/${toggleCompany}/`
          :
          `${BASE_URL}/jd/jobpostfilter/null/${sessionStorage.getItem("company_id")}/`);
      setJobPositionList(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [designationname, setDesignationname] = useState([]);

  const [departmentname, setDepartmentname] = useState([]);

  const getDesignation = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/designationfilterbysubcompanycount/${sessionStorage.getItem(
          "company_id"
        )}/active/`
      );
      const ress = await axios.get(
        `${BASE_URL}/wfm/departmentfilterbysubcompanycount/${sessionStorage.getItem(
          "company_id"
        )}/active/`
      );

      setDesignationname(res.data);
      setDepartmentname(ress.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getDesignation();
    getJobpositionlist(toggleStatus);
    getCompleteJobpostlist();
  }, [toggleStatus, toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex field-cont-between">
        <div className="repo-heading font-weight500    font-size-heading">
          Showing {jobposition.length} Jobs Post
          {/* <p className='career-para font-size-subheading">Based your performance</p>          */}
        </div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select Sub Company</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }
          <div>
            <Application
              designationname={designationname}
              departmentname={departmentname}
              getJobpositionlist={getJobpositionlist}
              getCompleteJobpostlist={jobpositionList}
              status={toggleStatus}
            />
          </div>
        </div>
      </div>
      <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg">
        <button
          className={
            toggleStatus === "null"
              ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
          }
          onClick={() => setToggleStatus("null")}
        >
          All
        </button>
        <button
          className={
            toggleStatus === "active"
              ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
          }
          onClick={() => setToggleStatus("active")}
        >
          Active
        </button>
        <button
          className={
            toggleStatus === "inactive"
              ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
          }
          onClick={() => setToggleStatus("inactive")}
        >
          Inactive
        </button>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-leftt">Company</th>
            <th className="align-leftt">Department</th>
            <th className="align-leftt">Position</th>
            <th className="align-center">Experience</th>
            <th className="align-center">Job post</th>
            {/* <th className="align-center">Close Date</th> */}
            <th className="align-center">Type</th>
            <th className="align-center">Position</th>
            <th className="align-center">Status</th>
            <th className="align-center">Link</th>

            {toggleStatus === "null" ? (
              <th className="align-center width-5vw">Action</th>
            ) : (
              ""
            )}
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> :

          <tbody>
            {jobposition
              .sort((a, b) => {
                // First, sort by status
                if (a.status === "active" && b.status === "inactive") return -1;
                if (a.status === "inactive" && b.status === "active") return 1;

                // If statuses are the same, sort by start_date
                return new Date(b.start_date) - new Date(a.start_date);
              })
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-leftt">{i.sub_company_name}</td>
                    <td className="align-leftt">{i.department_name}</td>
                    <td className="align-leftt">{i.position_name}</td>
                    <td className="align-center">{i.experience}</td>
                    <td className="align-center">
                      {formatDate(i.start_date)} - {formatDate(i.end_date)}
                    </td>
                    {/* <td className="align-center">{i.end_date}</td> */}
                    <td className="align-center form-text-trasformation-uppercase">
                      {i.job_type}
                    </td>
                    <td className="align-center">{i.location}</td>
                    <td className="align-center form-text-trasformation-uppercase">
                      {i.status}
                    </td>
                    {/* <td className="align-center">{i.status === 'active' ?
                //   <a href={`${FRONTEND_URL}/personaldetails/${i.id}/`} target="_blank" rel="noopener noreferrer">View</a>
                //   : "Freezed"}
                // </td> */}

                    {/* <td className="align-center">{i.status === 'active' ?
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(`${FRONTEND_URL}/personaldetails/${i.id}/`);
                      alert("Link copied to clipboard!");
                    }}
                  >
                    Copy
                  </button>
                  : "Freezed"}
                </td> */}

                    <td className="align-center">
                      {i.status === "active" ? (
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${FRONTEND_URL}/personaldetails/${i.id}/`
                            );
                            const notification = document.createElement("div");
                            notification.classList.add("notification");
                            notification.textContent =
                              "Link copied to clipboard!";
                            document.body.appendChild(notification);
                            setTimeout(() => {
                              document.body.removeChild(notification);
                            }, 2000); // Remove notification after 2 seconds
                          }}
                          title="Click to Copy Form link"
                          className="Color-tag-active"
                        >
                          Get Link
                        </button>
                      ) : (
                        <div className="Color-tag-inactive">Freezed</div>
                      )}
                    </td>
                    {toggleStatus === "null" ? (
                      <td className="align-center width-10vw ">
                        <Applicationupdate
                          designationname={designationname}
                          departmentname={departmentname}
                          getJobpositionlist={getJobpositionlist}
                          status={toggleStatus}
                          i={i}
                        />

                        {PERMISSION_AUTHORITY ? <ApplicationDelete getJobpositionlist={getJobpositionlist} i={i} /> : null}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                </React.Fragment>
              ))}
          </tbody>}
      </table>
    </div>
  );
};

export default Posting;
