import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Attachment, Eye } from "./AllSvg";
import { IMAGE_URL } from "../config/axios";


const handleErrorToast = (err, loadingToastId = null) => {
    if (loadingToastId) {
        toast.dismiss(loadingToastId);
    }

    if (err.response) {
        const errorData = err.response.data;

        if (errorData.detail && errorData.messages) {
            const detail = errorData.detail;
            const code = errorData.code;
            const messages = errorData.messages;

            toast.error(`Error: ${detail} (Code: ${code})`);

            messages.forEach(messageObj => {
                const tokenClass = messageObj.token_class || 'Unknown Token';
                const tokenType = messageObj.token_type || 'Unknown Type';
                const message = messageObj.message || 'Unknown Error';

                toast.error(`Token Class: ${tokenClass}, Type: ${tokenType} - ${message}`);
            });

        } else if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);

        } else if (typeof errorData === 'object' && errorData !== null) {
            Object.entries(errorData).forEach(([field, messages]) => {
                messages.forEach(message => toast.error(`"${field}": ${message}`));
            });

        } else {
            toast.error('Error: Failed to Process!');
        }
    } else {
        console.log("err : handleErrorToast")
        console.log(err)
        toast.error('Error processing your request.');
    }
};

// const handleAllError = (err, loadingToastId = null) => {
//     console.log("err");
//     console.log(err);

//     if (loadingToastId) {
//         toast.dismiss(loadingToastId);
//     }

//     if (err.response) {
//         const errorData = err.response.data;

//         // Check if there's a generic message field
//         if (errorData.message) {
//             toast.error(`Error: ${errorData.message}`);
//         } else if (errorData.detail && errorData.messages) {
//             const detail = errorData.detail;
//             const code = errorData.code;
//             const messages = errorData.messages;

//             toast.error(`Error: ${detail} (Code: ${code})`);

//             messages.forEach((messageObj) => {
//                 const tokenClass = messageObj.token_class || "Unknown Token";
//                 const tokenType = messageObj.token_type || "Unknown Type";
//                 const message = messageObj.message || "Unknown Error";

//                 toast.error(
//                     `Token Class: ${tokenClass}, Type: ${tokenType} - ${message}`
//                 );
//             });
//         } else if (typeof errorData.error === "string") {
//             // Single error message
//             toast.error(`Error: ${errorData.error}`);
//         } else if (typeof errorData === "object" && errorData !== null) {
//             Object.entries(errorData).forEach(([field, messages]) => {
//                 messages.forEach((message) => toast.error(`"${field}": ${message}`));
//             });
//         } else if (typeof errorData === "string" && errorData.includes("<html")) {
//             // Handling HTML error page
//             const parser = new DOMParser();
//             const doc = parser.parseFromString(errorData, "text/html");
//             const pageTitle =
//                 doc.querySelector("title")?.innerText || "Unknown Error";

//             toast.error(`Error: ${pageTitle}`);
//         } else {
//             toast.error("Error: Failed to Process!");
//         }
//     } else {
//         toast.error("Error processing your request.");
//     }
// };




const handleAllError = (err, loadingToastId = null) => {
    if (loadingToastId) {
        toast.dismiss(loadingToastId);
    }

    if (err.response) {
        const errorData = err.response.data;

        // Check if errorData matches the new error structure
        if (errorData.status === 'error' && typeof errorData.message === 'string') {
            toast.error(`Error: ${errorData.message}`);
        }
        // Check if there's a generic message field
        else if (errorData.message) {
            toast.error(`Error: ${errorData.message}`);
        }
        else if (errorData.detail && errorData.messages) {
            const detail = errorData.detail;
            const code = errorData.code;
            const messages = errorData.messages;

            toast.error(`Error: ${detail} (Code: ${code})`);

            messages.forEach(messageObj => {
                const tokenClass = messageObj.token_class || 'Unknown Token';
                const tokenType = messageObj.token_type || 'Unknown Type';
                const message = messageObj.message || 'Unknown Error';

                toast.error(`Token Class: ${tokenClass}, Type: ${tokenType} - ${message}`);
            });
        }
        else if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
        }
        else if (typeof errorData === 'object' && errorData !== null) {
            Object.entries(errorData).forEach(([field, messages]) => {
                messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
        }
        else if (typeof errorData === 'string' && errorData.includes("<html")) {
            // Handling HTML error page
            const parser = new DOMParser();
            const doc = parser.parseFromString(errorData, "text/html");
            const pageTitle = doc.querySelector("title")?.innerText || 'Unknown Error';

            toast.error(`Error: ${pageTitle}`);
        }
        else {
            console.log("handleAllError 1:");
            console.log(err);
            toast.error('Error: Failed to Process!');
        }
    } else {
        console.log("err : handleAllError");
        console.log(err);
        toast.error('Error processing your request.');
    }
};


// const handleAllError = (err, loadingToastId = null) => {
//     if (!err) {
//         console.error('Undefined error encountered');
//         toast.error('An unknown error occurred.');
//         return;
//     }

//     if (loadingToastId) {
//         toast.dismiss(loadingToastId);
//     }

//     if (err.response) {
//         const errorData = err.response.data;
//         console.log('Error type:', typeof errorData);
//         console.log('Error data:', errorData);

//         // Check if errorData matches the new error structure
//         if (errorData.status === 'error' && typeof errorData.message === 'string') {
//             toast.error(`Error: ${errorData.message}`);
//         }
//         // Check if there's a generic message field
//         else if (errorData.message) {
//             toast.error(`Error: ${errorData.message}`);
//         }
//         else if (errorData.detail && errorData.messages) {
//             const detail = errorData.detail;
//             const code = errorData.code;
//             const messages = errorData.messages;

//             toast.error(`Error: ${detail} (Code: ${code})`);

//             messages.forEach((messageObj) => {
//                 const tokenClass = messageObj.token_class || 'Unknown Token';
//                 const tokenType = messageObj.token_type || 'Unknown Type';
//                 const message = messageObj.message || 'Unknown Error';

//                 toast.error(`Token Class: ${tokenClass}, Type: ${tokenType} - ${message}`);
//             });
//         }
//         else if (typeof errorData.error === 'string') {
//             // Single error message
//             toast.error(`Error: ${errorData.error}`);
//         }
//         else if (typeof errorData === 'object' && errorData !== null) {
//             Object.entries(errorData).forEach(([field, messages]) => {
//                 if (Array.isArray(messages)) {
//                     messages.forEach((message) => toast.error(`"${field}": ${message}`));
//                 } else {
//                     toast.error(`"${field}": ${messages}`);
//                 }
//             });
//         }
//         else if (typeof errorData === 'string' && errorData.includes('<!DOCTYPE' || '<html')) {
//             // Handling HTML error page
//             try {
//                 const parser = new DOMParser();
//                 const doc = parser.parseFromString(errorData, 'text/html');
//                 const pageTitle = doc.querySelector('title')?.innerText || 'Unknown Error';
//                 toast.error(`Error: ${pageTitle}`);
//             } catch (parseError) {
//                 console.error('Error parsing HTML error page:', parseError);
//                 toast.error('Error: An unknown HTML error occurred.');
//             }
//         }
//         else {
//             console.log('handleAllError - Unhandled error structure:', errorData);
//             toast.error('Error: Failed to process your request!');
//         }
//     } else {
//         console.log('Error without response:', err);
//         toast.error('Error processing your request.');
//     }
// };

const formatCurrencyIndian = (amount) => {
    if (amount === null || amount === undefined) return "-";
    const hasDecimals = amount % 1 !== 0;

    return new Intl.NumberFormat('en-IN', {
        maximumSignificantDigits: 21,
        minimumFractionDigits: hasDecimals ? 2 : 0,
        maximumFractionDigits: hasDecimals ? 2 : 0
    }).format(amount);
};

const formatToIndianCurrencyBackup = (amount) =>
    new Intl.NumberFormat('en-IN', {
        maximumSignificantDigits: 21,
        style: 'decimal',
        minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    }).format(amount);


const formatExcelToJson = (file, dateFields = []) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                const sheetName = workbook.SheetNames[0]; // Use the first sheet by default
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

                const headers = json[0];
                const rows = json.slice(1);

                // Function to convert Excel serial number to a date
                const convertExcelDate = (serial) => {
                    const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                    const daysOffset = serial - 1; // Subtract 1 because Excel's date system includes a leap year bug
                    return new Date(excelEpoch.getTime() + daysOffset * 86400000).toISOString().slice(0, 10); // Format as YYYY-MM-DD
                };

                // Create a formatted JSON object based on headers and row values
                const formattedJson = rows.map((row) => {
                    const obj = {};
                    headers.forEach((header, index) => {
                        // Check if the column is one of the date fields and convert it if necessary
                        if (dateFields.includes(header) && row[index] && typeof row[index] === "number") {
                            obj[header] = convertExcelDate(row[index]); // Convert date
                        } else {
                            obj[header] = row[index] !== undefined ? row[index] : null;
                        }
                    });
                    return obj;
                });

                // Resolve the promise with the formatted JSON data
                resolve(formattedJson);
            } catch (error) {
                reject(error); // Reject the promise if there's an error
            }
        };

        reader.onerror = (error) => {
            reject(error); // Reject the promise if there's a FileReader error
        };

        reader.readAsArrayBuffer(file);
    });
};


const sortProjects = (data, codeKey) => {
    const splitCodeParts = (code) => {
        if (!code || typeof code !== 'string') {
            return { firstPart: "", numericPart: 0, alphaPart: "" };
        }

        // Split the code into two parts: before and after the last "/"
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/"); // Everything before the last "/"
        const lastPart = parts.pop(); // The last part after the "/"

        // Match numeric and alphabetic parts from the last part (e.g., "001CM" -> 001 and CM)
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/); // Adjust regex to prioritize alphabetic part
        if (match) {
            return {
                firstPart, // Part before the last "/"
                numericPart: match[1] ? parseInt(match[1], 10) : 0, // Numeric part (if present)
                alphaPart: match[2] || "" // Alphabetic part
            };
        }

        return { firstPart, numericPart: 0, alphaPart: "" };
    };

    return data.sort((a, b) => {
        const aParts = splitCodeParts(a[codeKey]);
        const bParts = splitCodeParts(b[codeKey]);

        // Compare the first part (e.g., "AE/IE" vs "OM")
        if (aParts.firstPart !== bParts.firstPart) {
            return aParts.firstPart.localeCompare(bParts.firstPart);
        }

        // If the first parts are the same, compare the alphabetic part (e.g., "CM" vs "SP")
        if (aParts.alphaPart !== bParts.alphaPart) {
            return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }

        // If alphabetic parts are the same, compare the numeric part (e.g., "001" vs "002")
        return aParts.numericPart - bParts.numericPart;
    });
};



// const downloadAsExcel = (tableRef, sheetName = "Sheet1", fileName = "download.xls") => {
//     if (!tableRef || !tableRef.current) {
//         console.error("Invalid table reference");
//         return;
//     }

//     const workbook = XLSX.utils.table_to_book(tableRef.current, { sheet: sheetName });
//     const workbookBinary = XLSX.write(workbook, { bookType: "xls", type: "binary" });

//     const s2ab = (str) => {
//         const buf = new ArrayBuffer(str.length);
//         const view = new Uint8Array(buf);
//         for (let i = 0; i < str.length; i++) view[i] = str.charCodeAt(i) & 0xff;
//         return buf;
//     };

//     const buffer = s2ab(workbookBinary);
//     const blob = new Blob([buffer], { type: "application/vnd.ms-excel" });
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = fileName;
//     a.click();
//     window.URL.revokeObjectURL(url);
// };

const downloadAsExcel = (tableRef, sheetName = "Sheet1", fileName = "download.xls") => {
    if (!tableRef || !tableRef.current) {
        console.error("Invalid table reference");
        return;
    }

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    let rowIndex = 0;

    for (let row of tableRef.current.rows) {
        const rowData = [];
        for (let cell of row.cells) {
            const nestedTable = cell.querySelector("table");

            if (nestedTable) {
                // Format nested table data into a single cell
                let nestedData = "";
                for (let nestedRow of nestedTable.rows) {
                    for (let nestedCell of nestedRow.cells) {
                        nestedData += nestedCell.innerText + "\n"; // Append with line breaks
                    }
                }
                rowData.push(nestedData.trim()); // Add formatted nested data into the cell
            } else {
                rowData.push(cell.innerText); // Normal cells
            }
        }

        if (rowData.length > 0) {
            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: rowIndex });
            rowIndex++;
        }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const workbookBinary = XLSX.write(workbook, { bookType: "xls", type: "binary" });
    const s2ab = (str) => {
        const buf = new ArrayBuffer(str.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < str.length; i++) view[i] = str.charCodeAt(i) & 0xff;
        return buf;
    };

    const buffer = s2ab(workbookBinary);
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
};

// usage
// const handleDownload = () => {
//     downloadTableAsExcel(bulktableRef, "Employee Details", "Employee_Details_Format.xls");
//   };
//   <button onClick={handleDownload}>Download Excel</button>

const customSortByKey = (data, key) => {
    const regex = /([A-Za-z]+)|(\d+)|(\W+)/g;

    // Function to access a deeply nested object property using dot notation
    const getNestedValue = (obj, key) => {
        return key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj);
    };

    // Custom sort function for object comparison
    const sortFunction = (a, b) => {
        // Get the values to be compared from the key (handle nested properties)
        const aValue = getNestedValue(a, key);
        const bValue = getNestedValue(b, key);

        if (!aValue || !bValue) return 0; // Return 0 if either value is undefined

        // Split the strings into alphabetic, numeric, and special character parts
        const aParts = aValue.match(regex);
        const bParts = bValue.match(regex);

        for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
            const aPart = aParts[i];
            const bPart = bParts[i];

            // Check if both parts are numeric
            if (!isNaN(aPart) && !isNaN(bPart)) {
                const numA = Number(aPart);
                const numB = Number(bPart);
                if (numA !== numB) {
                    return numA - numB; // Compare numerically
                }
            } else if (aPart !== bPart) {
                return aPart.localeCompare(bPart); // Compare alphabetically or by special characters
            }
        }

        // If we reach here, the longer string is considered greater
        return aParts.length - bParts.length;
    };

    // Sort the data using the custom sort function
    return data.sort(sortFunction);
};

const getFileNameFromLink = (url) => {
    try {
        const ImgFileUrl = "https://cipl-aimantra.s3.amazonaws.com/" || "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/";
        // const ImgFileUrl = "https://cipl-aimantra.s3.amazonaws.com/" || "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/";

        if (url.startsWith(ImgFileUrl)) {
            const pathAfterBase = url.slice(ImgFileUrl.length);

            const fileName = pathAfterBase.split('/').slice(1).join('/').split('?')[0];

            return fileName;
        }

        return url;
    } catch (error) {
        console.error("Error extracting file name:", error);
        return "";
    }
};

const ViewFile = ({ doc, filename, filesrc }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const isImage = (url) => {
        return /\.(jpg|jpeg|png|gif|bmp|webp)(\?.*)?$/.test(url);
    }
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button title={"View File"} style={{ cursor: "pointer" }} onClick={handleShow}>
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 16.933 16.933"
                    id="bill">
                    <path
                        d="M1.854 0C.819 0 0 .83 0 1.852c-.002 4.94.002 9.878.002 14.816 0 .221.256.345.429.207l1.488-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.488-1.19 1.49 1.19a.265.265 0 0 0 .33 0l1.489-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.487-1.19 1.488 1.19c.174.139.43.015.43-.207l.002-8.733h2.91a.26.26 0 0 0 .263-.263V1.854A1.85 1.85 0 0 0 15.083 0Zm0 .53h11.932c-.35.337-.556.8-.556 1.324l-.002 14.265-1.222-.98a.265.265 0 0 0-.33 0l-1.49 1.191-1.488-1.191a.264.264 0 0 0-.33 0L6.878 16.33 5.391 15.14a.265.265 0 0 0-.33 0L3.575 16.33 2.085 15.14a.264.264 0 0 0-.33 0l-1.224.98L.53 1.852c0-.77.634-1.322 1.324-1.322zm13.228 0c.73 0 1.324.586 1.324 1.324v5.554H13.76V1.854c0-.738.592-1.324 1.322-1.324zM3.707 3.439c-.133 0-.265.089-.265.266v.287c-.599.116-1.058.63-1.058 1.252 0 .42.217.672.48.799.264.127.552.147.816.174.265.027.506.06.639.125.133.064.181.15.181.32 0 .406-.34.743-.782.746-.384-.014-.658-.23-.784-.623-.106-.339-.614-.175-.503.162.152.475.48.844 1.011.96v.294c0 .354.53.354.53 0v-.282c.598-.116 1.058-.635 1.058-1.257 0-.388-.18-.654-.48-.799-.264-.126-.552-.146-.817-.173-.264-.028-.502-.056-.639-.126-.122-.062-.181-.142-.181-.32 0-.41.34-.742.783-.745.421.016.666.26.783.622.107.339.615.175.504-.162-.152-.474-.5-.857-1.012-.959v-.295c0-.177-.132-.266-.264-.266zm2.645.265c-.355 0-.355.527 0 .53h4.763c.353 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.53 0 .53h4.763c.352 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.527 0 .527h4.763c.352 0 .352-.527 0-.527zM2.649 9.26c-.356 0-.356.537 0 .529h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.53 0 .53h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.529 0 .53h8.466c.352 0 .352-.53 0-.53z"
                        fill="#2576BC"
                    >
                    </path>
                </svg> */}
                <Attachment />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>{filename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="flex-row justify-center">
                            {/* <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} /> */}
                            {isImage(filesrc) ? (
                                <img className="bill-img" src={`${IMAGE_URL}${filesrc}`} alt={`File/ Image of ${filename}`} />
                            ) : (
                                <a href={`${IMAGE_URL}${filesrc}`} target="_blank" rel="noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16" color="#FF747C">
                                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                        <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                    </svg>
                                    Open Document
                                </a>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

const ViewOtherFile = ({ doc, filename, filesrc }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const isImage = (url) => {
        return /\.(jpg|jpeg|png|gif|bmp|webp)(\?.*)?$/.test(url);
    }
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button title={"View File"} style={{ cursor: "pointer" }} onClick={handleShow}>
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 16.933 16.933"
                    id="bill">
                    <path
                        d="M1.854 0C.819 0 0 .83 0 1.852c-.002 4.94.002 9.878.002 14.816 0 .221.256.345.429.207l1.488-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.488-1.19 1.49 1.19a.265.265 0 0 0 .33 0l1.489-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.487-1.19 1.488 1.19c.174.139.43.015.43-.207l.002-8.733h2.91a.26.26 0 0 0 .263-.263V1.854A1.85 1.85 0 0 0 15.083 0Zm0 .53h11.932c-.35.337-.556.8-.556 1.324l-.002 14.265-1.222-.98a.265.265 0 0 0-.33 0l-1.49 1.191-1.488-1.191a.264.264 0 0 0-.33 0L6.878 16.33 5.391 15.14a.265.265 0 0 0-.33 0L3.575 16.33 2.085 15.14a.264.264 0 0 0-.33 0l-1.224.98L.53 1.852c0-.77.634-1.322 1.324-1.322zm13.228 0c.73 0 1.324.586 1.324 1.324v5.554H13.76V1.854c0-.738.592-1.324 1.322-1.324zM3.707 3.439c-.133 0-.265.089-.265.266v.287c-.599.116-1.058.63-1.058 1.252 0 .42.217.672.48.799.264.127.552.147.816.174.265.027.506.06.639.125.133.064.181.15.181.32 0 .406-.34.743-.782.746-.384-.014-.658-.23-.784-.623-.106-.339-.614-.175-.503.162.152.475.48.844 1.011.96v.294c0 .354.53.354.53 0v-.282c.598-.116 1.058-.635 1.058-1.257 0-.388-.18-.654-.48-.799-.264-.126-.552-.146-.817-.173-.264-.028-.502-.056-.639-.126-.122-.062-.181-.142-.181-.32 0-.41.34-.742.783-.745.421.016.666.26.783.622.107.339.615.175.504-.162-.152-.474-.5-.857-1.012-.959v-.295c0-.177-.132-.266-.264-.266zm2.645.265c-.355 0-.355.527 0 .53h4.763c.353 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.53 0 .53h4.763c.352 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.527 0 .527h4.763c.352 0 .352-.527 0-.527zM2.649 9.26c-.356 0-.356.537 0 .529h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.53 0 .53h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.529 0 .53h8.466c.352 0 .352-.53 0-.53z"
                        fill="#2576BC"
                    >
                    </path>
                </svg> */}
                <Eye />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>{filename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500  font-size-heading"><Modal.Title> File :</Modal.Title> </h1>
                        <h6 className="text-center">
                            {getFileNameFromLink(filesrc)}
                        </h6>
                        <br />
                        <div className="flex-row justify-center">
                            {/* <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} /> */}
                            {isImage(filesrc) ? (
                                <img className="bill-img" src={`${IMAGE_URL}${filesrc}`} alt={`File/ Image of ${filename}`} />
                            ) : (
                                <a href={filesrc} target="_blank" rel="noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16" color="#FF747C">
                                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                        <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                    </svg>
                                    Open Document
                                </a>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export {
    handleErrorToast,
    handleAllError,
    formatCurrencyIndian,
    formatExcelToJson,
    sortProjects,
    downloadAsExcel,
    customSortByKey,
    ViewFile,
    ViewOtherFile,
    getFileNameFromLink,
}
