import React, { useState } from "react";
import Userdetailsmain from "./Userdetailsmain.jsx";
import Personaldetails from "./Personaldetails.jsx";
import Jobdetails from "./Jobdetails.jsx";
import Leave from "./Leave.jsx";
import Attendances from "./Attendances.jsx";
import Document from "./Document.jsx"
import Assets from "./Assets.jsx"
import Finance from "./Finance.jsx"
import Expenses from "./Expenses.jsx"
import Performance from "./Performance.jsx"
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy.jsx";
import MyClockins from "./Clockin.jsx";
import Assettable from "./Assettable.jsx";
import { useParams } from "react-router-dom";
import usePermission from "../../config/permissions.js";
import { EmployeeHistoryTab } from "../RequestManagement/RequestManagementComponents.jsx";

const UserDetails = () => {
  const { PERMISSION_ROLE, PERMISSION_AUTHORITY, SUPER_ADMIN } = usePermission(); // Use the custom hook

  let { emp_code, email } = useParams()

  const [toggleState, setToggleState] = useState(1);


  const getSelectedTitle = () => {
    switch (toggleState) {
      case 0:
        return 'Clock Ins';
      case 1:
        return 'About';
      case 2:
        return 'Job';
      case 3:
        return 'Requests';
      case 4:
        return 'Attendence';
      case 5:
        return 'Documents';
      case 6:
        return 'Assets';
      case 7:
        return 'Finances';
      case 8:
        return 'Expenses';
      case 9:
        return 'Performance';
      case 10:
        return 'History Logs';

      default:
        return 'About';
    }
  };


  return (
    <div>
      <Dashboardnavbarcopy name={getSelectedTitle()} url="Profile" />
      <div style={{ background: "#F7F8FA" }}>
        <Userdetailsmain email={email} emp_code={emp_code} />

        <div className="bloc-tabss wfm-marginn">
          <button
            className={
              toggleState === 1
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs dashboardcomponent-tabss"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
            }
            onClick={() => setToggleState(1)}
          >
            About
          </button>

          <button
            className={
              toggleState === 5
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
            }
            onClick={() => setToggleState(5)}
          >
            Documents
          </button>

          {/*  //? @SC Customization */}
          {/* view in SC hide for CIPL/GENG */}
          {sessionStorage.getItem("company") === "Space Creattors" ||
            sessionStorage.getItem("company") ===
            "Space Creattors Heights" ? (
            <button
              className={
                toggleState === 0
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(0)}
            >
              ClockIns
            </button>

          ) : (
            ""
          )}
          <button
            className={
              toggleState === 4
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
            }
            onClick={() => setToggleState(4)}
          >
            Attendance
          </button>
          <button
            className={
              toggleState === 3
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
            }
            onClick={() => setToggleState(3)}
          >
            Requests
          </button>

          {SUPER_ADMIN ?
            <button
              className={
                toggleState === 7
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(7)}
            >
              Finance
            </button>
            : null}

          <button
            className={
              toggleState === 8
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
            }
            onClick={() => setToggleState(8)}
          >
            Expenses
          </button>

          <button
            className={
              toggleState === 6
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
            }
            onClick={() => setToggleState(6)}
          >
            Assets
          </button>

          <button
            className={
              toggleState === 9
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
            }
            onClick={() => setToggleState(9)}
          >
            Performance
          </button>
          <button
            className={
              toggleState === 10
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
            }
            onClick={() => setToggleState(10)}
          >
            History Logs
          </button>

        </div>

        <div className="content-tabss">
          {toggleState === 1 ? <Personaldetails email={email} emp_code={emp_code} /> : null}
          {toggleState === 3 ? <Leave email={email} emp_code={emp_code} /> : null}
          {toggleState === 4 ? <Attendances email={email} emp_code={emp_code} /> : null}
          {toggleState === 0 ? <MyClockins email={email} emp_code={emp_code} /> : null}
          {toggleState === 5 ? <Document email={email} emp_code={emp_code} /> : null}
          {toggleState === 6 ? (
            PERMISSION_ROLE ? (
              <Assettable email={email} emp_code={emp_code} />
            ) : (
              <Assets email={email} emp_code={emp_code} />
            )
          ) : null}
          {toggleState === 7 ? <Finance email={email} emp_code={emp_code} /> : null}
          {toggleState === 8 ? <Expenses email={email} emp_code={emp_code} /> : null}
          {toggleState === 9 ? <Performance email={email} emp_code={emp_code} /> : null}
          {toggleState === 10 ? <EmployeeHistoryTab email={email} emp_code={emp_code} /> : null}

        </div>
      </div>
    </div>
  );
};

export default UserDetails;