import axios from 'axios';
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import React, { useState, useEffect } from 'react';
import { formatDate } from '../Date';



// Main Dashboard Component
const JobInformation = () => {

    const [alljobopenings, setAlljobopenings] = useState([]);

    useEffect(() => {
        const getAlljobopenings = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/active/${sessionStorage.getItem("company_id")}/`);
                setAlljobopenings(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAlljobopenings();
    }, []);



    return (
        <div className='backgroundmain-task'>
            <div className='dashboardcomponent-task width-40vw'>
                <div className="task-heading">
                    <div className='task-sub-heading  font-weight400  font-size-subheading  '>Job Opening</div>

                    <div className='task-hr' ></div>

                    <div className=" EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap" >

                        {alljobopenings.map((data, index) => {

                            return (
                                <>
                                    <div className="EmployeeDashboad-notification-card dashcomp-bg-card-style">
                                        <h3 className='dashcomp-announcement-heading  font-weight500    font-size-subheading  '>{data.position_name}</h3>
                                        <span className="dashcomp-announcement-date   font-weight500   font-size-subheading  ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='dashcomp-announcement-date-svg'>
                                                <rect x="2.66602" y="3.3335" width="10.6667" height="10.6667" rx="2" stroke="#2576BC" stroke-width="2" />
                                                <path d="M10.666 1.3335L10.666 3.3335C10.666 4.2763 10.666 4.74771 10.3731 5.0406C10.0802 5.33349 9.60882 5.33349 8.66602 5.33349L7.33268 5.33349C6.38987 5.3335 5.91847 5.3335 5.62558 5.0406C5.33268 4.74771 5.33268 4.2763 5.33268 3.33349L5.33268 1.3335" stroke="#2576BC" stroke-width="2" stroke-linecap="round" />
                                                <path d="M6 9.3335L10 9.3335" stroke="black" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            {formatDate(data.start_date)} - {formatDate(data.end_date)}
                                        </span>
                                        <br />
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.experience}</p>
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.job_type}</p>
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.status}</p>
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.location}</p>
                                        <div className="dashcomp-announcement-p font-weight400  font-size-text">{data.description}</div>
                                        {/* <a href={`${FRONTEND_URL}/personaldetails/${data.id}/`} target="_blank" rel="noopener noreferrer">Interview From Link</a> */}
                                        {/* <a href={`${FRONTEND_URL}/personaldetails/${data.id}/`} target="_blank" rel="noopener noreferrer">Interview From Link</a> */}
                                        <button
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    `${FRONTEND_URL}/personaldetails/${data.id}/`
                                                );
                                                const notification = document.createElement("div");
                                                notification.classList.add("notification");
                                                notification.textContent =
                                                    "Link copied to clipboard!";
                                                document.body.appendChild(notification);
                                                setTimeout(() => {
                                                    document.body.removeChild(notification);
                                                }, 2000); // Remove notification after 2 seconds
                                            }}
                                            title="Click to Copy Form link"
                                            className="Color-tag-active"
                                        >
                                            Get Link
                                        </button>
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

// Option Component 2
const JobInformation2 = () => {
    const [alljobopenings, setAlljobopenings] = useState([]);

    useEffect(() => {
        const getAlljobopenings = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/active/${sessionStorage.getItem("company_id")}/`);
                setAlljobopenings(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAlljobopenings();
    }, []);

    return (
        <div className='backgroundmain-task'>
            <div className='dashboardcomponent-task width-40vw'>
                <div className="task-heading">
                    <div className='task-sub-heading  font-weight400  font-size-subheading  '>Job Opening</div>
                    <div className='task-hr'></div>

                    <div className="EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap">
                        <table className='dashcomp-bg-card-style'>
                            <thead>
                                <tr>
                                    <th>Position</th>
                                    {/* <th>Date Range</th> */}
                                    <th>Experience</th>
                                    <th>Job Type</th>
                                    {/* <th>Status</th> */}
                                    <th>Location</th>
                                    <th>Description</th>
                                    <th>Interview Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {alljobopenings.map((data, index) => (
                                    <tr key={index}>
                                        {/* <h3 className='dashcomp-announcement-heading  font-weight500    font-size-subheading  '>{data.position_name}</h3> */}
                                        <td>{data.position_name}-{data.department_name}</td>
                                        {/* <td>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='dashcomp-announcement-date-svg'>
                                                <rect x="2.66602" y="3.3335" width="10.6667" height="10.6667" rx="2" stroke="#2576BC" strokeWidth="2" />
                                                <path d="M10.666 1.3335L10.666 3.3335C10.666 4.2763 10.666 4.74771 10.3731 5.0406C10.0802 5.33349 9.60882 5.33349 8.66602 5.33349L7.33268 5.33349C6.38987 5.3335 5.91847 5.3335 5.62558 5.0406C5.33268 4.74771 5.33268 4.2763 5.33268 3.33349L5.33268 1.3335" stroke="#2576BC" strokeWidth="2" strokeLinecap="round" />
                                                <path d="M6 9.3335L10 9.3335" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                            {formatDate(data.start_date)} - {formatDate(data.end_date)}
                                        </td> */}
                                        <td>{data.experience}</td>
                                        <td>{data.job_type}</td>
                                        {/* <td>{data.status}</td> */}
                                        <td>{data.location}</td>
                                        <td>{data.description}</td>
                                        <td>
                                            <a href={`${FRONTEND_URL}/personaldetails/${data.id}/`} target="_blank" rel="noopener noreferrer">
                                                Interview Form Link
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Option Component 3
const JobInformation3 = () => {

    const [alljobopenings, setAlljobopenings] = useState([]);

    useEffect(() => {
        const getAlljobopenings = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/active/${sessionStorage.getItem("company_id")}/`);
                setAlljobopenings(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAlljobopenings();
    }, []);



    return (
        <div className='backgroundmain-task'>
            <div className='dashboardcomponent-task width-40vw'>
                <div className="task-heading">
                    <div className='task-sub-heading  font-weight400  font-size-subheading  '>Job Opening</div>


                </div>
                <div className='task-hr' ></div>

                <div style={{ gap: '1em' }} className="EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap" >

                    {alljobopenings.map((data, index) => {

                        return (
                            <>
                                <div className="EmployeeDashboad-notification-card" key={index}>
                                    <h6 className="dashcomp-announcement-heading  font-weight500   ">
                                        {index + 1}. {data.position_name}
                                    </h6>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.experience}</p>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.job_type}</p>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.status}</p>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.location}</p>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.description}</p>
                                    <a
                                        href={`${FRONTEND_URL}/personaldetails/${data.id}/`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="dashcomp-announcement-link"
                                    >
                                        Interview Form Link
                                    </a>
                                </div>
                            </>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}
export default JobInformation;