import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../config/axios"
import axios from "axios";
import { formattedDateLong } from "../Date";
import { ToastContainer, toast } from "react-toastify";

const Personaldetails = ({ email, emp_code }) => {
  const [formData, setFormData] = useState({})

  const [edudata, setEduData] = useState([])
  const [workData, setWorkData] = useState([])
  const [emgData, setemgData] = useState([])
  const [perData, setPerData] = useState([])
  const [buffer, setBuffering] = useState(true); //buffering logic


  useEffect(() => {

    const getUSerDetails = async (email, emp_code) => {
      setBuffering(true); // Start Buffering
      try {
        const res = await axios.get(`${BASE_URL}/userdata/userdataget/${email}/`);

        const resedu = await axios.get(`${BASE_URL}/userdata/EmployeeEducationDetailsallget/${email}/`);

        const work = await axios.get(`${BASE_URL}/userdata/userworkexpallget/${email}/`);
        const Emg = await axios.get(`${BASE_URL}/userdata/userEmergencyContactallget/${email}/`);
        const per = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${emp_code}/`);

        setFormData(res.data);
        setEduData(resedu.data);
        setWorkData(work.data)
        setemgData(Emg.data)
        setPerData(per.data)

      } catch (err) {
        // alert(err.message);

        //toast Logic
        if (err.response) {
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setBuffering(false);// End Buffering
      }

    };

    getUSerDetails(email, emp_code);
  }, [email, emp_code]);






  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      {/* {buffer ? <div className="spinner-z"></div> : null} */}
      <div className="main-div-personaldetial">
        <div className="presonal-info">
          <div className="personal-detail">
            <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3>
            {buffer ? <div className="spinner-small"></div> :
              <table>
                <tr>
                  <td className="personal-detail-1   font-weight500  font-size-text ">Name</td>
                  <th className="personal-detail-2   font-weight500  font-size-text ">{formData.name}</th>
                </tr>
                <tr>
                  <td className="personal-detail-1   font-weight500  font-size-text ">Marital Status</td>
                  <th className="personal-detail-2   font-weight500  font-size-text ">{formData.married_status}</th>
                </tr>
                <tr>
                  <td className="personal-detail-1   font-weight500  font-size-text ">Date of Birth</td>
                  <th className="personal-detail-2   font-weight500  font-size-text ">{formattedDateLong(formData.dob)}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1   font-weight500  font-size-text ">Blood Group</td>
                  <th className="personal-detail-2   font-weight500  font-size-text ">{formData.blood_group}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1   font-weight500  font-size-text ">Birth Mark</td>
                  <th className="personal-detail-2   font-weight500  font-size-text ">{formData.birth_mark}</th>
                </tr >

              </table >
            }
          </div >

          <div className="contact-details">
            <h3 className="personal-detail-heading   font-weight500  font-size-heading">Offical Details</h3>
            {buffer ? <div className="spinner-small"></div> :
              <>
                < tr >
                  <td className="personal-detail-1   font-weight500  font-size-text ">Designation</td>
                  <th className="personal-detail-2   font-weight500  font-size-text ">{perData.designation_name} </th>
                </tr>
                <tr>
                  <td className="personal-detail-1   font-weight500  font-size-text ">Empolyee Role</td>
                  <th className="personal-detail-2   font-weight500  font-size-text ">{perData.employee_type}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1   font-weight500  font-size-text ">Joining Date</td>
                  <th className="personal-detail-2   font-weight500  font-size-text ">{formattedDateLong(perData.joining_date)}</th>
                </tr >
              </>
            }
          </div >
        </div >

        <div className="address-details">
          <h3 className="personal-detail-heading   font-weight500  font-size-heading">Address Details</h3>
          {buffer ? <div className="spinner-small"></div> :
            <div className="address-details-1 ">
              <div className="current-address font-weight400 -margin">
                <span className="current-address font-weight400  ">
                  <h5 className="font-size-subheading">Current Address</h5>
                  <p className="font-size-text font-weight500">
                    {formData.current_address}
                  </p>
                </span>
              </div>
              <div>
                <span className="current-address font-weight400 ">
                  <h5 className="font-size-subheading">Permanent Address</h5>
                  <p className="font-size-text font-weight500">
                    {formData.permanent_address}
                  </p>
                </span>
              </div>
            </div>
          }
        </div>

        <div className="Education-details">
          <h3 className="personal-detail-heading   font-weight500  font-size-heading">Education Details</h3>
          {buffer ? <div className="spinner-small"></div> :
            <div className="education-info">
              {edudata.map((e, index) => {
                return (
                  <div className="education-info-1">
                    <h3 className="experiance-details  font-weight500  font-size-subheading  ">Education Details {index + 1}</h3>
                    <table>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">College</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{e.college}</th>
                      </tr>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Course</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{e.department}</th>
                      </tr>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Grade</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{e.grade}</th>
                      </tr >
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">From Year</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{formattedDateLong(e.startDate)}</th>
                      </tr >
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">End Year</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{formattedDateLong(e.endDate)}</th>
                      </tr >
                    </table >
                  </div >
                )
              })}


            </div >}
        </div >

        <div className="Education-details Experience Detials">
          <h3 className="personal-detail-heading   font-weight500  font-size-heading">Experience Details</h3>
          {buffer ? <div className="spinner-small"></div> :
            <div className="education-info">
              {workData.map((a, index) => {
                return (
                  <div className="education-info-1">
                    <h3 className="experiance-details  font-weight500  font-size-subheading  ">Company {index + 1}</h3>
                    <table>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Company Name</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{a.company}</th>
                      </tr>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Designation</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{a.jobposition}</th>
                      </tr>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Job position</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{a.jobtype}</th>
                      </tr >
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Duration</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{formattedDateLong(a.startDate)}
                          {' '} to < br /> {formattedDateLong(a.endDate)}</th >
                      </tr >
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Monthly CTC</td>
                        <th className="personal-detail-2   font-weight500  font-size-text "> {a.monthlyCtc}</th>
                      </tr >
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Monthly in hand</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{a.monthlyInhand}</th>
                      </tr >
                    </table >
                  </div >
                )
              })}
            </div >
          }
        </div >

        <div className="Education-details Experience Detials">
          <h3 className="personal-detail-heading   font-weight500  font-size-heading">Emergency Details</h3>
          {buffer ? <div className="spinner-small"></div> :
            <div className="education-info">
              {emgData.map((a, index) => {
                return (
                  <div className="education-info-1">
                    <h3 className="experiance-details  font-weight500  font-size-subheading  ">Contact Person Detail {index + 1}</h3>
                    <table>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Name</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{a.person_name}</th>
                      </tr>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Relation</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{a.relation}</th>
                      </tr>
                      <tr>
                        <td className="personal-detail-1   font-weight500  font-size-text ">Number</td>
                        <th className="personal-detail-2   font-weight500  font-size-text ">{a.phone_number}</th>
                      </tr >
                    </table >
                  </div >
                )
              })}
            </div >
          }
        </div >
      </div >
    </div >
  );
};

export default Personaldetails;
