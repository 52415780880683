import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../config/axios';

const Careernavbar = ({id}) => {

const [jobposition, setJobPosition] = useState({}) 


useEffect(() => {
const getJobpositionlist = async () => {
  try {
  const res = await axios.get(`${BASE_URL}/jd/jobpost/${id}/`);
  setJobPosition(res.data)
} catch (err) {
      // alert(err.message);
}
};

getJobpositionlist();
}, []);

  return (
    <div className='career-navbar'>
        <div>
        <img className="sidebar-expanded-logo" src="https://g-eng.s3.ap-south-1.amazonaws.com/template-img/%60logo.png" alt="logo" />
        </div>
        <div>
              <span className='font-size-subheading'>{jobposition.department_name}</span><br/>

            <span className='font-size-heading'>{jobposition.position_name}</span><br/>
            <span className='font-size-subheading'>{jobposition.location}</span>

        </div>
      
    </div>
  )
}

export default Careernavbar
