import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../config/axios";
import axios from "axios";
import {
  AppViewNavBar,
  ApplicantAddRoundData,
  ApplicantDataView,
  DocsTab,
  EducationInfoTab,
  InterviewHeading,
  PersonalInfoTab,
  WorkInfoTab,
} from "./ApplicantViewComponents";


const ApplicantviewRound1 = () => {
  let { id, email } = useParams();

  const [allJobappdata, setAlljobappdata] = useState({});

  useEffect(() => {
    const getUSerDetails = async (id, email) => {
      try {
        const remark = await axios.get(
          `${BASE_URL}/jd/jobapplicant/${id}/${email}/`
        );

        setAlljobappdata(remark.data);
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails(id, email);
  }, [id, email]);

  return (
    <div className="jdview-outer">
      <AppViewNavBar />

      <div className="jdview-section2">
        <PersonalInfoTab />
        <EducationInfoTab />
        <WorkInfoTab />

        <div className="jdfinal-section2">
          <InterviewHeading />

          <div className="jdfinal-section2-b">
            <ApplicantDataView roundNumber={1} allJobappdata={allJobappdata} />

          </div>

          <ApplicantAddRoundData
            roundNumber={1}
            id={id}
            email={email}
            allJobappdata={allJobappdata}
          />
        </div>
      </div>
    </div>
  );
};



const ApplicantviewRound2 = () => {
  let { id, email } = useParams();

  const [allJobappdata, setAlljobappdata] = useState({});

  useEffect(() => {
    const getUSerDetails = async (id, email) => {
      try {
        const remark = await axios.get(
          `${BASE_URL}/jd/jobapplicant/${id}/${email}/`
        );

        setAlljobappdata(remark.data);
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails(id, email);
  }, [id, email]);

  return (
    <div className="jdview-outer">
      <AppViewNavBar />

      <div className="jdview-section2">
        <PersonalInfoTab />
        <EducationInfoTab />
        <WorkInfoTab />

        <div className="jdfinal-section2">
          <InterviewHeading />

          <div className="jdfinal-section2-b">
            <ApplicantDataView roundNumber={1} allJobappdata={allJobappdata} />

            <ApplicantDataView roundNumber={2} allJobappdata={allJobappdata} />
          </div>

          <ApplicantAddRoundData
            roundNumber={2}
            id={id}
            email={email}
            allJobappdata={allJobappdata}
          />
        </div>
      </div>
    </div>
  );
};

const ApplicantviewRound3 = () => {
  let { id, email } = useParams();

  const [allJobappdata, setAlljobappdata] = useState({});

  useEffect(() => {
    const getUSerDetails = async (id, email) => {
      try {
        const remark = await axios.get(
          `${BASE_URL}/jd/jobapplicant/${id}/${email}/`
        );

        setAlljobappdata(remark.data);
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails(id, email);
  }, [id, email]);

  return (
    <div className="jdview-outer">
      <AppViewNavBar />

      <div className="jdview-section2">
        <PersonalInfoTab />
        <EducationInfoTab />
        <WorkInfoTab />
        {/* <DocsTab /> */}


        <div className="jdfinal-section2">
          <InterviewHeading />

          <div className="jdfinal-section2-b">
            <ApplicantDataView roundNumber={1} allJobappdata={allJobappdata} />

            <ApplicantDataView roundNumber={2} allJobappdata={allJobappdata} />

            <ApplicantDataView roundNumber={3} allJobappdata={allJobappdata} />

          </div>

          <ApplicantAddRoundData
            roundNumber={3}
            id={id}
            email={email}
            allJobappdata={allJobappdata}
          />
        </div>
      </div>
    </div>
  );
};


const ApplicantviewRound4 = () => {
  let { id, email } = useParams();

  const [allJobappdata, setAlljobappdata] = useState({});

  useEffect(() => {
    const getUSerDetails = async () => {
      try {
        const remark = await axios.get(
          `${BASE_URL}/jd/jobapplicant/${id}/${email}/`
        );

        setAlljobappdata(remark.data);
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails();
  }, [email]);



  const [formData, setFormData] = useState({
    round4by: "Susheel Bhardwaj",
    r4remark: "",
  });
  // const [round4, setRound1] = useState({

  // });



  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [

    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let handleSubmitt = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (formData.round4by === null || allJobappdata.round4 === true) {
        alert("Please fill in all required fields.");
        return;
      }

      const updatedFormData = {
        ...formData,
        round4: "true",

      };
      try {
        let res = await axios.put(
          `${BASE_URL}/jd/jobapplicant/${allJobappdata.id}/`,
          updatedFormData
        );
        if (res.status === 200) {
          window.location.reload();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };
  console.log(formData);

  return (
    <div className="jdview-outer">
      <AppViewNavBar />

      <div className="jdview-section2">
        <PersonalInfoTab />
        <EducationInfoTab />
        <WorkInfoTab />
        <DocsTab />

        <div className="jdfinal-section2">
          <InterviewHeading />

          <div className="jdfinal-section2-b">
            <ApplicantDataView roundNumber={1} allJobappdata={allJobappdata} />

            <ApplicantDataView roundNumber={2} allJobappdata={allJobappdata} />

            <ApplicantDataView roundNumber={3} allJobappdata={allJobappdata} />
          </div>

          <>
            <div className="jdfinal-sub-heading font-weight600  font-size-heading">Round 4</div>
            <form className="register-form">
              <div className="form-flex-wrap">
                <div className="flex-column">
                  <label htmlFor="round4by" className="form-labels  font-weight500    font-size-subheading">
                    Interviewer Name<span className="required">*</span>
                  </label>
                  <input
                    className={`form-input ${errors.round4by
                      ? "error"
                      : inputState.round4by
                        ? "success"
                        : ""
                      }`}
                    placeholder={
                      allJobappdata.round4by
                        ? allJobappdata.round4by
                        : "Interviewer"
                    }
                    type="text"
                    name="round4by"
                    value={
                      allJobappdata.round4by
                        ? allJobappdata.round4by
                        : formData.round4by
                    }
                    onChange={handleInputChange}
                  />
                </div>
                <br />

              </div>
              <div className="flex-column">
                <label htmlFor="r4remark" className="jd-heading   font-weight500 font-size-subheading ">
                  Remarks:
                </label>
                <textarea
                  type="text"
                  id="r4remark"
                  name="r4remark"
                  placeholder={
                    allJobappdata.r4remark ? allJobappdata.r4remark : "Remarks"
                  }
                  onChange={handleInputChange}
                  value={formData.r4remark}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.r4remark
                    ? "error"
                    : inputState.r4remark
                      ? "success"
                      : ""
                    }`}
                />
                {errors.r4remark && (
                  <span className="error-message font-size-text ">{errors.r4remark}</span>
                )}
              </div>
            </form>
            <br />
            <button
              style={{ marginTop: "20px", width: "100px" }}
              onClick={handleSubmitt}
              className="model-button   font-weight500   "
            >
              Submit
            </button>{" "}
          </>


        </div>
      </div>
    </div>
  );
};


const Applicantview = () => {
  let { id, email } = useParams();

  const [allJobappdata, setAlljobappdata] = useState({});

  useEffect(() => {
    const getUSerDetails = async () => {
      try {
        const remark = await axios.get(
          `${BASE_URL}/jd/jobapplicant/${id}/${email}/`
        );

        setAlljobappdata(remark.data);
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails();
  }, [email]);





  return (
    <div className="jdview-outer">
      <AppViewNavBar />

      <div className="jdview-section2">
        <PersonalInfoTab />
        <EducationInfoTab />
        <WorkInfoTab />
        <DocsTab />

        <div className="jdfinal-section2">
          <InterviewHeading />

          <div className="jdfinal-section2-b">
            <ApplicantDataView roundNumber={1} allJobappdata={allJobappdata} />

            <ApplicantDataView roundNumber={2} allJobappdata={allJobappdata} />

            <ApplicantDataView roundNumber={3} allJobappdata={allJobappdata} />

            {allJobappdata.round4 ? (
              <>
                {/* add more */}
                <div className="section-d3 width-35vw">
                  <div className="personal-info font-weight400 font-size-text ">
                    <div classname="info-heading   font-weight500 font-size-heading">Round 4</div>
                    <table>
                      <tr>
                        <th className="personal-0   font-weight300  font-size-heading">Assign To</th>
                        <td className="personal-info-1 font-weight400  font-size-subheading  ">{allJobappdata.round4by}</td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <th className="personal-0   font-weight300  font-size-heading">Round Remarks</th>
                        <td className="personal-info-1 font-weight400  font-size-subheading  ">{allJobappdata.r4remark}</td>
                      </tr>


                    </table>
                  </div>
                </div>

              </>

            ) : (
              <>
                <br></br>Interview Round 4 pending...</>
            )}

          </div>



        </div>
      </div>
    </div>
  );
};


const AppliedPage = () => {
  return (
    <>
      <center>  <br /><br /><br /><br /><br /><br /><br /><br /><h3>
        <br /><b>Thankyou for Applying, </b> <br />Please contanct Our HR Team for Further Process<br />

      </h3></center>

    </>
  );
};

export { ApplicantviewRound1, ApplicantviewRound2, ApplicantviewRound3, ApplicantviewRound4, Applicantview, AppliedPage };
