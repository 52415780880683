import React from 'react';
import { PieChart, Tooltip, Pie, ResponsiveContainer } from 'recharts';




const AttendanceChart = ({ allattendanceStatus }) => {

  const data = [
    // {
    //   name: "Present",
    //   uv: allattendanceStatus.present,
    //   fill: "#18A118",
    // },
    {
      name: "On Time",
      uv: allattendanceStatus.ontime,
      fill: "#18A118",
    },
    {
      name: "Late",
      uv: allattendanceStatus.late,
      fill: "#F7BA1E",
    },
    {
      name: "Half Day",
      uv: allattendanceStatus.halfday,
      fill: "#FF747C",
    },
    {
      name: "Absent",
      uv: allattendanceStatus.absent,
      fill: "#FF0000",
    },
    // {
    //   name: "Leave",
    //   uv: allattendanceStatus.leave,
    //   fill: "#AC98C7",
    // },
    // {
    //   name: "Out Duty",
    //   uv: allattendanceStatus.outduty,
    //   fill: "#30C9C9",
    // },
  ];
  const display = [
    {
      name: "Present",
      uv: allattendanceStatus.present,
      fill: "#18A118",
    },
    {
      name: "Out Duty",
      uv: allattendanceStatus.outduty,
      fill: "#2576BC",
    },
    {
      name: "Leave",
      uv: allattendanceStatus.leave,
      fill: "#30C9C9",
      // fill: "#AC98C7",
    },

    {
      name: "Absent",
      uv: allattendanceStatus.absent,
      fill: "#FF0000",
    },
  ];

  return (
    <ResponsiveContainer className="piechart" height={300} width="90%">
      <div className="attendancechart-heading">
        <div className="repo-heading-a font-weight500   font-size-subheading">Behaviour:</div>
        <div>
          {data.map((item) => {
            return (
              <div key={item.name} className="piechart-legend">
                <div
                  className="piechart-dot"
                  style={{ backgroundColor: item.fill }}
                ></div>
                <div className="piechart-subheading  font-weight500    font-size-subheading" style={{ color: item.fill }}>
                  {item.name} - {item.uv}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <PieChart>
        <Pie
          data={data}
          // label={renderCustomizedLabel}
          outerRadius={140}
          innerRadius={90}
          dataKey="uv"
        ></Pie>
        {/* <Tooltip /> */}
      </PieChart>
      <div className="attendancechart-heading">
        <div className="repo-heading-a font-weight500   font-size-subheading">Attendance:</div>
        <div>
          {display.map((item) => {
            return (
              <div key={item.name} className="piechart-legend">
                <div
                  className="piechart-dot"
                  style={{ backgroundColor: item.fill }}
                ></div>
                <div className="piechart-subheading  font-weight500    font-size-subheading" style={{ color: item.fill }}>
                  {item.name} - {item.uv}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ResponsiveContainer>
  );
};

export default AttendanceChart;