import React, { useState } from "react";

import {
    Requestoutdutytable,
    RequestLeavetable,
    RequestMisspunchtable,
    RequestCompositortable,
    RequestImpresttableAdmin,
    RequestExpensetableAdmin,
    RequestClockInTableHR,
    LogCompensatorytable,
} from "./Requestoutdutytable";

import { RequestRequisitionTableHR } from "../RequisitionManagement/RequisitionManagementTables";
import usePermission from "../../config/permissions";

const Requestmanagment = ({ secondtoggle }) => {
    const { PERMISSION_HR_ADMIN, PERMISSION_AUTHORITY } = usePermission();
    const [toggleState, setToggleState] = useState(secondtoggle);
    return (
        <>
            <div>
                <div>
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={
                                toggleState === 1
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                            }
                            onClick={() => setToggleState(1)}
                        >
                            Out Duty
                        </button>
                        <button
                            className={
                                toggleState === 2
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                            }
                            onClick={() => setToggleState(2)}
                        >
                            Leave
                        </button>
                        {PERMISSION_AUTHORITY ? (
                            <button
                                className={
                                    toggleState === 4
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(4)}
                            >
                                Compensatory Leave Log
                            </button>
                        ) : (
                            ""
                        )}

                        {/* {PERMISSION_HR_ADMIN ? (
                            <button
                                className={
                                    toggleState === 5
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(5)}
                            >
                                Imprest
                            </button>
                        ) : (
                            ""
                        )}
                        {PERMISSION_HR_ADMIN ? (
                            <button
                                className={
                                    toggleState === 6
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(6)}
                            >
                                Expense
                            </button>
                        ) : (
                            ""
                        )} */}

                        <button
                            className={
                                toggleState === 7
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                            }
                            onClick={() => setToggleState(7)}
                        >
                            Requisition
                        </button>

                        {/*  //? @SC Customization */}
                        {/* view in SC hide for CIPL/GENG */}
                        {sessionStorage.getItem("company") === "Space Creattors" ||
                            sessionStorage.getItem("company") === "Space Creattors Heights" ? (
                            <button
                                className={
                                    toggleState === 8
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(8)}
                            >
                                Clock-In Log
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? <Requestoutdutytable /> : null}

                    {toggleState === 2 ? <RequestLeavetable /> : null}

                    {toggleState === 3 ? <RequestMisspunchtable /> : null}

                    {toggleState === 4 ? <LogCompensatorytable /> : null}

                    {toggleState === 5 ? <RequestImpresttableAdmin /> : null}

                    {toggleState === 6 ? <RequestExpensetableAdmin /> : null}

                    {toggleState === 7 ? <RequestRequisitionTableHR /> : null}

                    {toggleState === 8 ? <RequestClockInTableHR /> : null}
                </div>
            </div>
        </>
    );
};

export default Requestmanagment;
