import React, { useState } from 'react';
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import { ContactUsTable, ProjectsManagementTabs, ProjectsTable, SectorManagementTabs, SectorsTable } from './WebsiteTables';
import usePermission from '../../config/permissions';

const WebsiteManagement = () => {

    const [toggleState, setToggleState] = useState(1);
    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return 'Sectors';
            case 2:
                return 'Project';
            case 3:
                return 'Contact Us';
            case 4:
                return 'Enquiry';
            default:
                return 'Website';
        }
    };

    return (
        <>
            <Dashboardnavbarcopy name={getSelectedTitle()} url="Website Managment" />
            <div >
                <div className="bloc-tabss2  wfm-margin">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(1)}
                    >
                        Sectors
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(2)}
                    >
                        Project
                    </button>
                    <button
                        className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(3)}
                    >
                        Contact Us
                    </button>

                    {/* <button
                        className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(4)}
                    >
                        Enquiry
                    </button> */}
                </div>
            </div>
            <div className="content-tabs">
                {toggleState === 1 ? <SectorManagementTabs /> : null}
                {toggleState === 2 ? <ProjectsTable /> : null}
                {toggleState === 3 ? <ContactUsTable /> : null}
            </div>
        </>
    );
};





export default WebsiteManagement;
