import React from 'react';
import EmployeeData from '../../../components/dashboard-comp/EmployeeData';
import Dashboardnavbarcopy from '../../../layout/Dashboardnavbarcopy';
import NewEmployee from '../../../components/dashboard-comp/NewEmployee';
import JobInformation from '../../../components/dashboard-comp/JobInformation';
import TaskInformation from '../../../components/dashboard-comp/TaskInformation';
// import {NewJoiningEmployee, JobOpening} from '../../../components/UserDetails/Tables';
import Informationcomponent from '../../../components/dashboard-comp/Informationcomponent';

import AttendanceArc from '../../../components/dashboard-comp/AttendanceArc';

import CareerSummaryBars from '../../../components/dashboard-comp/CareerSummaryBars';

const Dashboard = () => {
  // Live time

  const [today, setDate] = React.useState(new Date());
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);


  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
    } `;



  return (
    <div className='dashboard-designs'>
      <Dashboardnavbarcopy url="Dashboard" name={wish} />
      <div className='hr-dashboard-designs-flex'>
        <div>
          {/* <Halfarc /> */}
          <AttendanceArc />
        </div>
        {/* <div className='dashboard-designs-column'> */}
        <div>
          {/* <Radicalchart /> */}
          {/* <JobApplicantChart /> */}
          {/* <CareerSummary /> */}
          <CareerSummaryBars />
        </div>


      </div>
      <div className='dashboard-designs-secondary-flex'>
        <EmployeeData />
        {/* <Radicalchart /> */}
      </div>

      <div className="dashboard-designs-secondary-flex">
        <div className="dashboard-designs-left-flex">
          <Informationcomponent />
          <NewEmployee />
        </div>
        <div className="dashboard-designs-right-flex">
          <TaskInformation />
          <JobInformation />
        </div>
      </div>

    </div>
  )
}

export default Dashboard
