import React, { useState, } from 'react'

import { Onboardingtable, Upcomingonboarding, NewEmployeeJoining } from './Onboardingtable';

const Onboarding = () => {
    const [toggleState, setToggleState] = useState(1);

    return (
        <>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Upcoming Onboarding
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Current Onboarding
                        </button>
                        <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(3)}
                        >
                            New Employee Joining
                        </button>
                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? <Upcomingonboarding /> : null}

                    {toggleState === 2 ? <Onboardingtable /> : null}

                    {toggleState === 3 ? <NewEmployeeJoining /> : null}

                </div>
            </div>
        </>
    )
}

export default Onboarding
