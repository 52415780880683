import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Select from 'react-select';
import { BASE_URL } from "../../config/axios";
import { ErrorModal } from "../Alerts/AlertModals";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usePermission from "../../config/permissions";
import { handleAllError, handleErrorToast } from "../CustomFunctions";
import { AddwithBlueCircle, AddwithWhiteCircle, DeleteDustbin, DropdownArrowOption, UpdatePencil } from "../AllSvg";


// ! *************** Departments Crud Models Start ***************

const Department = ({ getDepartment, departmentname }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredList, setFilteredList] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    // Filter logic based on the input
    if (name === 'title') {
      const filtered = departmentname.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() && formData.title != null) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/department/`, formData);

        if (res.status === 200) {
          await getDepartment();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        // alert(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Department Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}

              </div>
              {PERMISSION_AUTHORITY ?
                <div className="flex-column">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  {errors.title && (
                    <span className="error-message font-size-text ">{errors.title}</span>
                  )}
                </div>
                : null}
              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const DepartmentUpdate = ({ i, getDepartment, departmentname }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredList, setFilteredList] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    // Filter logic based on the input
    if (name === 'title') {
      const filtered = departmentname.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/department/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getDepartment();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {

        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update Department {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Department Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="button-models">
                <button className="model-button   font-weight500    " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmitPut}
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DepartmentDelete = ({ i, getDepartment }) => {
  const [show, setShow] = useState(false);
  const [inactiveDepartment, setInactiveDepartment] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    // getInactiveDepartment();
  };


  // const getInactiveDepartment = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${BASE_URL}/wfm/departmentfilterbysubcompanycount/${sessionStorage.getItem(
  //         "company_id"
  //       )}/inactive/`
  //     );
  //     // const res = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/910ee6d1-9fd8-4da1-a9cb-369b1ffecd7c/active/`);
  //     setInactiveDepartment(res.data);
  //   } catch (err) {
  //     // alert(err.message);
  //   }
  // }
  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/department/${i.id}/`);

      if (res.status === 200) {
        await getDepartment();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Department {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete department {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Departments Crud Models End ***************

// ! *************** Designagtion Crud Models Start ***************



const DesignationName = ({ getDesignation, designationname }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id')
  });
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [filteredList, setFilteredList] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(designationname)

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });



    // Filter logic based on the input
    if (name === 'title') {
      const filtered = designationname.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() && formData.title != null) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/designation/`, formData);
        if (res.status === 200) {
          await getDesignation();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Designation Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""}`}
                />
                {errors.title && <span className="error-message font-size-text ">{errors.title}</span>}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}
                {PERMISSION_AUTHORITY ?
                  <div className="flex-column">
                    <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                      Sub Company<span className="required">*</span>
                    </label>
                    <select
                      id="sub_company"
                      name="sub_company"
                      value={formData.sub_company}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                        }`}
                    >
                      {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

                    </select>
                    {errors.title && (
                      <span className="error-message font-size-text ">{errors.title}</span>
                    )}
                  </div>
                  : null}
                <div className="button-models">
                  <button className="model-button   font-weight500   " onClick={handleClose}>Cancel</button>
                  <button className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading" onClick={handleFormSubmit} disabled={loading}>Submit</button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const DesignationUpdate = ({ i, getDesignation, designationname }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredList, setFilteredList] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    // Filter logic based on the input
    if (name === 'title') {
      const filtered = designationname.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/designation/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getDesignation();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update Designation {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Department Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="button-models">
                <button className="model-button font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={handleFormSubmitPut}
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const DesignationDelete = ({ i, getDesignation }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/designation/${i.id}/`);

      if (res.status === 200) {
        await getDesignation();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Designatiion {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete department {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button type="submit" className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading" onClick={handleFormSubmitDelete}>
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Designagtion Crud Models End ***************

// ! *************** Sub Company Crud Models Start ***************

const Subcompanys = ({ getSubCompany }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/subcompany/`, formData);

        if (res.status === 200) {
          setShow(false);
          await getSubCompany();
        } else {
          alert(res);
        }
      } catch (err) {
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Company Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                <div className="button-models">
                  <button className="model-button   font-weight500   " onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    //   type="submit"
                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    Submit
                  </button>
                </div>{" "}
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const Subcompanyupdate = ({ i, getSubCompany }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/subcompany/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          setShow(false);
          await getSubCompany();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update SubCompany {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="SubCompany Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button   font-weight500    " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmitPut}
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const SubcompanyDelete = ({ i, getSubCompany }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/subcompany/${i.id}/`);

      if (res.status === 200) {
        setShow(false);
        await getSubCompany();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete SubCompany {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete SubCompany {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SubComany Crud Models End ***************

// ! *************** Sub Company Crud Models Start ***************

const Woksiteadd = ({ getWoksiteList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/worksite/`, formData);

        if (res.status === 200) {
          setShow(false);
          await getWoksiteList();
        } else {
          alert(res);
        }
      } catch (err) {
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Worksite Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Worksite Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}

                {PERMISSION_AUTHORITY ?
                  <div className="flex-column">
                    <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                      Sub Company<span className="required">*</span>
                    </label>
                    <select
                      id="sub_company"
                      name="sub_company"
                      value={formData.sub_company}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                        }`}
                    >
                      {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

                    </select>
                    {errors.title && (
                      <span className="error-message font-size-text ">{errors.title}</span>
                    )}
                  </div>
                  : null}
                <div className="button-models">
                  <button className="model-button   font-weight500   " onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    //   type="submit"
                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    Submit
                  </button>
                </div>{" "}
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const Woksiteupdate = ({ i, getWoksiteList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/worksite/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          setShow(false);
          await getWoksiteList();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Worksite Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Worksite Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Worksite Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button   font-weight500    " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmitPut}
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const WoksiteDelete = ({ i, getWoksiteList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/worksite/${i.id}/`);

      if (res.status === 200) {
        setShow(false);
        await getWoksiteList();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete this Worksite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete worksite with name {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SubComany Crud Models End ***************

// ! *************** Attendance Policy Crud Models Start ***************

const AttendancePolicy = ({ getAttPolicyName }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: "",
    ontime: false,
    ontime_in_time_check: false,
    ontime_in_time: "",
    ontime_out_time: "",
    ontime_durations: false,
    ontime_duration: "",

    late_mark: false,
    late_in_time_check: false,
    late_in_time: "",
    late_out_time: "",
    late_durations: false,
    late_duration: "",

    halfday: false,
    halfday_in_time_check: false,
    halfday_in_time: "",
    halfday_out_time: "",
    halfday_durations: false,
    halfday_duration: "",
    shortleave_durations: false,
    shortleave_duration: "",
    weeklyoff_durations: false,
    weeklyoff_duration: "",
    rectified_late_mark_count: 0,

    sub_company: sessionStorage.getItem('company_id')

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/attendancepolicy/`, formData);

        if (res.status === 200) {

          await getAttPolicyName();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        // console.log("err");
        // console.log(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" title="Add Attendance Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Attendance Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>
              {PERMISSION_AUTHORITY ?
                <div className="flex-column">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  {errors.title && (
                    <span className="error-message font-size-text ">{errors.title}</span>
                  )}
                </div>
                : null}
              <div className="flex-column">
                <label htmlFor="rectified_late_mark_count" className="form-labels  font-weight500    font-size-subheading">
                  Rectified Late Mark<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="rectified_late_mark_count"
                  name="rectified_late_mark_count"
                  placeholder="Number of rectified late Marks"
                  onChange={handleInputChange}
                  value={formData.rectified_late_mark_count}
                  className={`form-input ${errors.rectified_late_mark_count ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.rectified_late_mark_count && (
                  <span className="error-message font-size-text ">{errors.rectified_late_mark_count}</span>
                )}
              </div>

              {/* <div className="flex-row">

                <div className="flex-column">

                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label htmlFor="rectified_late_mark_count" className="form-labels  font-weight500    font-size-subheading">
                        Rectified Late Marks <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        id="rectified_late_mark_count"
                        name="rectified_late_mark_count"
                         placeholder="00.0"
                        onChange={handleInputChange}
                        value={formData.rectified_late_mark_count}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Ontime Check
                </label>
                <input
                  type="checkbox"
                  name="ontime"
                  onChange={handleInputChange}
                  checked={formData.ontime}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row justify-between">

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Ontime In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="ontime_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.ontime_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">Ontime In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="ontime_in_time"
                        value={formData.ontime_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="flex-column multi-day">
                  <label className="form-labels  font-weight500    font-size-subheading">Ontime Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="ontime_out_time"
                    type="time"
                    step="1"
                    value={formData.ontime_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Ontime Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="ontime_durations"
                      onChange={handleInputChange}
                      checked={formData.ontime_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="ontime_duration" className="form-labels  font-weight500    font-size-subheading">
                      Ontime Work Durations
                    </label>
                    <input
                      type="text"
                      id="ontime_duration"
                      name="ontime_duration"
                      placeholder="0.00"
                      onChange={handleInputChange}
                      value={formData.ontime_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>


              </div>


              <div className="flex-row">
                <label htmlFor="late_mark" className="form-labels  font-weight500    font-size-subheading">
                  Late Check
                </label>
                <input
                  type="checkbox"
                  name="late_mark"
                  onChange={handleInputChange}
                  checked={formData.late_mark}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Late In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="late_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.late_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">Late In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="late_in_time"
                        value={formData.late_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="flex-column multi-day">
                  <label className="form-labels  font-weight500    font-size-subheading">Late Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="late_out_time"
                    type="time"
                    step="1"
                    value={formData.late_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Late Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="late_durations"
                      onChange={handleInputChange}
                      checked={formData.late_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="late_duration" className="form-labels  font-weight500    font-size-subheading">
                      Late Work Durations
                    </label>
                    <input
                      type="text"
                      id="late_duration"
                      name="late_duration"
                      placeholder="0.00"
                      onChange={handleInputChange}
                      value={formData.late_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Halfday Check
                </label>
                <input
                  type="checkbox"
                  name="halfday"
                  onChange={handleInputChange}
                  checked={formData.halfday}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Halfday In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="halfday_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.halfday_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">Halfday In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="halfday_in_time"
                        value={formData.halfday_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* <div className="flex-column multi-day">
                  <label className="form-labels  font-weight500    font-size-subheading">Halfday Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="halfday_out_time"
                    type="time"
                    step="1"
                    value={formData.halfday_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="halfday_durations" className="form-labels  font-weight500    font-size-subheading">
                      Halfday Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="halfday_durations"
                      onChange={handleInputChange}
                      checked={formData.halfday_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="halfday_duration" className="form-labels  font-weight500    font-size-subheading">
                      Halfday Work Durations
                    </label>
                    <input
                      type="text"
                      id="halfday_duration"
                      name="halfday_duration"
                      placeholder="0.00"
                      onChange={handleInputChange}
                      value={formData.halfday_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>

              </div>


              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="shortleave_durations" className="form-labels  font-weight500    font-size-subheading">
                      Short Leave Eligibility
                    </label>
                    <input
                      type="checkbox"
                      name="shortleave_durations"
                      onChange={handleInputChange}
                      checked={formData.shortleave_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label htmlFor="shortleave_duration" className="form-labels  font-weight500    font-size-subheading">
                        Short Leave Eligibility Work Durations
                      </label>
                      <input
                        type="text"
                        id="shortleave_duration"
                        name="shortleave_duration"
                        placeholder="00.0"
                        onChange={handleInputChange}
                        value={formData.shortleave_duration}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="weeklyoff_durations" className="form-labels  font-weight500    font-size-subheading">
                      WeeklyOff Eligibility
                    </label>
                    <input
                      type="checkbox"
                      name="weeklyoff_durations"
                      onChange={handleInputChange}
                      checked={formData.weeklyoff_durations}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label htmlFor="weeklyoff_duration" className="form-labels  font-weight500    font-size-subheading">
                        WeeklyOff Eligibility Work Durations
                      </label>
                      <input
                        type="text"
                        id="weeklyoff_duration"
                        name="weeklyoff_duration"
                        placeholder="00.0"
                        onChange={handleInputChange}
                        value={formData.weeklyoff_duration}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const AttendancePolicyUpdate = ({ i, getAttPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: "",
    ontime: false,
    ontime_in_time_check: false,
    ontime_in_time: "",
    ontime_out_time: "",
    ontime_durations: false,
    ontime_duration: "",

    late_mark: false,
    late_in_time_check: false,
    late_in_time: "",
    late_out_time: "",
    late_durations: false,
    late_duration: "",

    halfday: false,
    halfday_in_time_check: false,
    halfday_in_time: "",
    halfday_out_time: "",
    halfday_durations: false,
    halfday_duration: "",
    shortleave_durations: false,
    shortleave_duration: "",
    weeklyoff_durations: false,
    weeklyoff_duration: "",
    rectified_late_mark_count: 0,
  });


  useEffect(() => {
    setFormData(i);
  }, [i]);

  console.log(formData)

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name", "rectified_late_mark_count"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/attendancepolicy/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getAttPolicyName();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Attendance Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update Policy {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Attendance Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="rectified_late_mark_count" className="form-labels  font-weight500    font-size-subheading">
                  Rectified Late Mark<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="rectified_late_mark_count"
                  min={0}
                  name="rectified_late_mark_count"
                  placeholder="Number of rectified late Marks"
                  onChange={handleInputChange}
                  value={formData.rectified_late_mark_count}
                  className={`form-input ${errors.rectified_late_mark_count ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.rectified_late_mark_count && (
                  <span className="error-message font-size-text ">{errors.rectified_late_mark_count}</span>
                )}
              </div>
              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Ontime Check
                </label>
                <input
                  type="checkbox"
                  name="ontime"
                  onChange={handleInputChange}
                  checked={formData.ontime}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Ontime In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="ontime_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.ontime_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">Ontime In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="ontime_in_time"
                        value={formData.ontime_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="flex-column multi-day">
                  <label className="form-labels  font-weight500    font-size-subheading">Ontime Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="ontime_out_time"
                    type="time"
                    step="1"
                    value={formData.ontime_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Ontime Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="ontime_durations"
                      onChange={handleInputChange}
                      checked={formData.ontime_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="ontime_duration" className="form-labels  font-weight500    font-size-subheading">
                      Ontime Work Durations
                    </label>
                    <input
                      type="text"
                      id="ontime_duration"
                      name="ontime_duration"
                      placeholder="0.00"
                      onChange={handleInputChange}
                      value={formData.ontime_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>

              </div>

              <div className="flex-row">
                <label htmlFor="late_mark" className="form-labels  font-weight500    font-size-subheading">
                  Late Check
                </label>
                <input
                  type="checkbox"
                  name="late_mark"
                  onChange={handleInputChange}
                  checked={formData.late_mark}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Late In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="late_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.late_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">Late In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="late_in_time"
                        value={formData.late_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="flex-column multi-day">
                  <label className="form-labels  font-weight500    font-size-subheading">Late Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="late_out_time"
                    type="time"
                    step="1"
                    value={formData.late_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Late Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="late_durations"
                      onChange={handleInputChange}
                      checked={formData.late_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="late_duration" className="form-labels  font-weight500    font-size-subheading">
                      Late Work Durations
                    </label>
                    <input
                      type="text"
                      id="late_duration"
                      name="late_duration"
                      placeholder="0.00"
                      onChange={handleInputChange}
                      value={formData.late_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Halfday Check
                </label>
                <input
                  type="checkbox"
                  name="halfday"
                  onChange={handleInputChange}
                  checked={formData.halfday}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Halfday In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="halfday_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.halfday_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">Halfday In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="halfday_in_time"
                        value={formData.halfday_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* <div className="flex-column multi-day">
                  <label className="form-labels  font-weight500    font-size-subheading">Halfday Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="halfday_out_time"
                    type="time"
                    step="1"
                    value={formData.halfday_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Halfday Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="halfday_durations"
                      onChange={handleInputChange}
                      checked={formData.halfday_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="halfday_duration" className="form-labels  font-weight500    font-size-subheading">
                      Halfday Work Durations
                    </label>
                    <input
                      type="text"
                      id="halfday_duration"
                      name="halfday_duration"
                      placeholder="0.00"
                      onChange={handleInputChange}
                      value={formData.halfday_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>

              </div>

              <div className="flex-row">

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="shortleave_durations" className="form-labels  font-weight500    font-size-subheading">
                      Short Leave Eligibility
                    </label>
                    <input
                      type="checkbox"
                      name="shortleave_durations"
                      onChange={handleInputChange}
                      checked={formData.shortleave_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label htmlFor="shortleave_duration" className="form-labels  font-weight500    font-size-subheading">
                        Short Leave Eligibility Work Durations
                      </label>
                      <input
                        type="text"
                        id="shortleave_duration"
                        name="shortleave_duration"
                        placeholder="00.0"
                        onChange={handleInputChange}
                        value={formData.shortleave_duration}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="weeklyoff_durations" className="form-labels  font-weight500    font-size-subheading">
                      WeeklyOff Eligibility
                    </label>
                    <input
                      type="checkbox"
                      name="weeklyoff_durations"
                      onChange={handleInputChange}
                      checked={formData.weeklyoff_durations}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row">
                    <div className="flex-column multi-day">
                      <label htmlFor="weeklyoff_duration" className="form-labels  font-weight500    font-size-subheading">
                        WeeklyOff Eligibility Work Durations
                      </label>
                      <input
                        type="text"
                        id="weeklyoff_duration"
                        name="weeklyoff_duration"
                        placeholder="00.0"
                        onChange={handleInputChange}
                        value={formData.weeklyoff_duration}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal >
    </>
  );
};

const AttendancePolicyDelete = ({ i, getAttPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/attendancepolicy/${i.id}/`);

      if (res.status === 200) {
        await getAttPolicyName();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Aattendance Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Attendance Policy with {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Attendance Policy with {i.name}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Attendance Policy Crud Models End ***************



// ! ***************  Salary Structure Heading Crud Models Start ***************

const SalaryStructureHeadingAdd = ({ salaryStructureHeadings, getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id'),
    sorting_var: "",
    salary_type: "",
  });
  const [suggestion, setSuggestPosition] = useState("")

  useEffect(() => {
    const getNextSortingPosition = () => {
      const maxSortingVar = Math.max(
        ...salaryStructureHeadings.map((heading) => Number(heading.sorting_var)),
        1
      );
      setSuggestPosition(maxSortingVar + 1);
    };

    getNextSortingPosition();
  }, [salaryStructureHeadings]);



  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "sub_company",
      "sorting_var",
      "salary_type",
    ];


    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    // // Add more validation for numerical fields as needed
    // if (formData.epf_criteria && isNaN(formData.epf_criteria)) {
    //   newErrors.epf_criteria = 'EPF criteria must be a valid number!';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/salaryheading/`, formData);
        if (res.status === 200) {
          await getSalaryStructureHeadings();
          setShow(false);
          // toast.success('Salary Policy created successfully!');
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" title="Add Salary Heading" onClick={handleShow}>
        <AddwithBlueCircle />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Add Salary Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Heading Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>


              <div className="flex-column form-group-selectt">
                <label htmlFor="salary_type " className="form-labels  font-weight500    font-size-subheading">
                  Salary Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="salary_type"
                  name="salary_type"
                  value={formData.salary_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.salary_type ? "error" : inputState.salary_type ? "success" : ""
                    }`}
                >
                  <option value="">Select Salary Type</option>
                  <option value="allowance">Allowance</option>
                  <option value="deduction">Deduction</option>
                  <option value="total">Total</option>

                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.salary_type && (
                  <span className="error-message font-size-text ">{errors.salary_type}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sorting_var" className="form-labels  font-weight500    font-size-subheading">
                  Heading Sort Position (1 is Top)<span className="required">*</span>
                </label>
                <input
                  id="sorting_var"
                  name="sorting_var"
                  type="number"
                  min={1}
                  maxLength={10}
                  placeholder={`Heading Position (Suggestion: ${suggestion})`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.sorting_var}
                  className={`form-input ${errors.sorting_var ? "error" : inputState.sorting_var ? "success" : ""
                    }`}
                />
                {errors.sorting_var && (
                  <span className="error-message font-size-text ">{errors.sorting_var}</span>
                )}
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select Site Company  </option>
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.sub_company && (
                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                  )}
                </div>
                : null
              }

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const SalaryStructureHeadingUpdate = ({ i, salaryStructureHeadings, getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id'),
    sorting_var: "",
    salary_type: "",
  });

  const [suggestion, setSuggestPosition] = useState("")

  useEffect(() => {
    const getNextSortingPosition = () => {
      const maxSortingVar = Math.max(
        ...salaryStructureHeadings.map((heading) => Number(heading.sorting_var)),
        1
      );
      setSuggestPosition(maxSortingVar + 1);
    };

    getNextSortingPosition();
  }, [salaryStructureHeadings]);

  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "sub_company",
      "sorting_var",
      "salary_type",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.put(`${BASE_URL}/wfm/salaryheading/${i.id}/`, formData);
        if (res.status === 200) {
          await getSalaryStructureHeadings();
          setShow(false);
        }
      } catch (err) {
        handleAllError(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Salary Heading" onClick={handleShow}>
        <UpdatePencil />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Update Salary Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Salary Heading Title"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              <div className="flex-column form-group-selectt">
                <label htmlFor="salary_type " className="form-labels  font-weight500    font-size-subheading">
                  Salary Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="salary_type"
                  name="salary_type"
                  value={formData.salary_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.salary_type ? "error" : inputState.salary_type ? "success" : ""
                    }`}
                >
                  <option value="">Select Salary Type</option>
                  <option value="allowance">Allowance</option>
                  <option value="deduction">Deduction</option>
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.salary_type && (
                  <span className="error-message font-size-text ">{errors.salary_type}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sorting_var" className="form-labels  font-weight500    font-size-subheading">
                  Heading Sort Position (1 is Top)<span className="required">*</span>
                </label>
                <input
                  id="sorting_var"
                  name="sorting_var"
                  type="number"
                  min={1}
                  maxLength={10}
                  placeholder={`Heading Position (Suggestion: ${suggestion})`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.sorting_var}
                  className={`form-input ${errors.sorting_var ? "error" : inputState.sorting_var ? "success" : ""
                    }`}
                />
                {errors.sorting_var && (
                  <span className="error-message font-size-text ">{errors.sorting_var}</span>
                )}
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select Site Company  </option>
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.sub_company && (
                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                  )}
                </div>
                : null
              }

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const SalaryStructureHeadingDelete = ({ i, getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/salaryheading/${i.id}/`);

      if (res.status === 200) {
        await getSalaryStructureHeadings();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Salary Heading" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Salary Heading: {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Salary Salary Heading: "{i.title}"
            <div className="button-models">
              <button className="model-button model-button-cancel  font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Salary Structure Heading Policy Crud Models End ***************


// ! ***************  SalaryCalculationPolicy Crud Models Start ***************

const SalaryCalculationPolicyAdd = ({ getSalaryCalculationPolicyList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    policy_name: "",
    sub_company: sessionStorage.getItem('company_id'),
  });



  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "policy_name",
      "sub_company",
    ];


    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/salarypolicy/`, formData);
        if (res.status === 200) {
          await getSalaryCalculationPolicyList();
          setShow(false);
          // toast.success('Salary Policy created successfully!');
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" title="Add Salary Calculation" onClick={handleShow}>
        <AddwithBlueCircle />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Add Salary Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="policy_name" className="form-labels  font-weight500    font-size-subheading">
                  Policy Name<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="policy_name"
                  name="policy_name"
                  placeholder="Policy Name"
                  onChange={handleInputChange}
                  value={formData.policy_name}
                  className={`form-input ${errors.policy_name ? "error" : inputState.policy_name ? "success" : ""
                    }`}
                />
                {errors.policy_name && (
                  <span className="error-message font-size-text ">{errors.policy_name}</span>
                )}
              </div>


              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select Site Company  </option>
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.sub_company && (
                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                  )}
                </div>
                : null
              }

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const SalaryCalculationPolicyUpdate = ({ i, getSalaryCalculationPolicyList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    getSalaryPolicyRelatedData();
    setShow(true);
  };

  const [formData, setFormData] = useState({
    policy_name: "",
    sub_company: sessionStorage.getItem('company_id'),
  });


  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "policy_name",
      "sub_company",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.put(`${BASE_URL}/wfm/salarypolicy/${i.id}/`, formData);
        if (res.status === 200) {
          await getSalaryCalculationPolicyList();
          setShow(false);
        }
      } catch (err) {
        handleAllError(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  // Custom Deduction Policy part start 

  const [customData, setCustomData] = useState([]);
  const [deductionData, setDeductionData] = useState([]);
  const [salaryStructureHeadings, setSalaryStructureHeadings] = useState([]);

  const getSalaryPolicyRelatedData = async () => {
    try {
      // const deduction = await axios.get(`${BASE_URL}/wfm/customdeductionbysalary_policy/${i.id}/`);
      // setDeductionData(deduction.data);
      const headings = await axios.get(`${BASE_URL}/wfm/salaryHeadingbysubcompany/${i.sub_company}/`);
      if (Array.isArray(headings.data)) {
        setSalaryStructureHeadings(headings.data);
        const customData = headings.data.map(item => ({
          name: item.id,
          salary_policy: i.id,
          percentage: '',
          percentage_of: [],
          fixed_amount: '',
        }));
        setCustomData(customData);
      } else {
        console.error("Error: Expected a list of Salary Headings");
      }
    } catch (err) {
      handleAllError(err)
    } finally {
    }
  };


  return (
    <>
      <button className="model-edit-button" title="Update Salary Calculation" onClick={handleShow}>
        <UpdatePencil />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="four-fifth-modal">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500 font-size-heading ">
          <Modal.Title>Update Salary Calculation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-row justify-evenly">
                <div className="flex-column">
                  <label htmlFor="policy_name" className="form-labels  font-weight500    font-size-subheading">
                    Policy Name<span className="required">*</span>
                  </label>
                  <input
                    maxLength={100}
                    type="text"
                    id="policy_name"
                    name="policy_name"
                    placeholder="Policy Name"
                    onChange={handleInputChange}
                    value={formData.policy_name}
                    className={`form-input ${errors.policy_name ? "error" : inputState.policy_name ? "success" : ""
                      }`}
                  />
                  {errors.policy_name && (
                    <span className="error-message font-size-text ">{errors.policy_name}</span>
                  )}
                </div>
                <div className="button-models">
                  <button
                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    Update
                  </button>
                </div>
              </div>
              {/* <div className="parent-div">
                <div className="bdy-div">

                </div>
              </div> */}
              <CustomDeductionsPolicy
                calculationPolicy={i}
                deductionData={deductionData}
                customData={customData}
                salaryStructureHeadings={salaryStructureHeadings}
                getSalaryPolicyRelatedData={getSalaryPolicyRelatedData}
              />
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const CustomDeductionsPolicy = ({
  calculationPolicy,
  customData,
  deductionData,
  salaryStructureHeadings,
  getSalaryPolicyRelatedData,
}) => {
  const [formData, setFormData] = useState(customData || []);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  console.log("customData")
  console.log(customData)
  useEffect(() => {
    setFormData(customData);
  }, [customData]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    // Update formData and clear dependent fields as needed
    const updatedFormData = formData.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          [name]: value,
          ...(name === "fixed_amount" ? { percentage: "", percentage_of: [] } : {}),
          ...(name === "percentage" ? { fixed_amount: "" } : {}),
        };
      }
      return entry;
    });

    setFormData(updatedFormData);
  };

  const handleSelectChange = (selectedOptions, index) => {
    // Update percentage_of with selected values
    const updatedFormData = formData.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          percentage_of: selectedOptions ? selectedOptions.map(option => option.value) : [],
        };
      }
      return entry;
    });

    setFormData(updatedFormData);
  };

  const validateForm = () => {
    const newErrors = {};
    formData.forEach((entry, index) => {
      const hasFixedAmount = !!entry.fixed_amount;
      const hasPercentage = !!entry.percentage;
      const hasRelatedTo = entry.percentage_of && entry.percentage_of.length > 0;

      if (hasFixedAmount && (hasPercentage || hasRelatedTo)) {
        newErrors[index] = "Specify either Fixed Amount or Percentage with Related To, not both.";
      } else if (hasPercentage && !hasRelatedTo) {
        newErrors[index] = "Related To is required when Percentage is specified.";
      }
      else if (!hasPercentage && hasRelatedTo) {
        newErrors[index] = "Percentage is required when Related To is specified.";
      }
      // } else if (!hasFixedAmount && (!hasPercentage || !hasRelatedTo)) {
      //     newErrors[index] = "Either Fixed Amount or both Percentage and Related To are required.";
      // }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  const handleSubmitCustomDeduction = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const loadingToastId = toast.loading("Loading: Please wait...");

    try {
      const res = await axios.post(`${BASE_URL}/wfm/bulkcustomdeduction/`, formData);
      if (res.status === 200 || res.status === 201) {
        await getSalaryPolicyRelatedData();
        toast.success("Deductions saved successfully.");
      }
    } catch (err) {
      await getSalaryPolicyRelatedData();

      // toast.error("An error occurred while saving deductions.");
    } finally {
      // setLoading(false);
      // toast.dismiss(loadingToastId);
    }
  };

  const deductionIds = deductionData.map(item => item.name);

  // Format options for react-select
  const relatedToOptions = salaryStructureHeadings
    .filter(i => !deductionIds.includes(i.id))
    .sort((a, b) => a.sorting_var - b.sorting_var)
    .map(i => ({ value: i.id, label: i.title }));

  return (
    <div>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
      <table className="table-css width-10vw">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left width-5vw">Heading</th>
            <th className="align-center width-5vw">Type</th>
            <th className="align-center width-5vw">Amount</th>
            <th className="align-center" style={{ width: '0.5vw' }}>OR</th>
            <th className="align-center width-5vw">Percentage</th>
            <th className="align-center width-5vw">Related To</th>
            {deductionData.length > 0 && <th className="align-center width-5vw">Action</th>}
          </tr>
        </thead>
        <tbody>
          <>
            {deductionData.length > 0 ?
              <>
                {deductionData
                  .sort((a, b) => a.name_sorting_var - b.name_sorting_var)
                  .map((entry, index) => (
                    <tr className="custom-table-head-td" key={index}>
                      <td className="align-left">{entry.name_title}</td>
                      <td className="align-left form-text-trasformation-uppercase">{entry.name_type}</td>

                      <td className="align-center">{entry.fixed_amount || '-'}</td>
                      <td className="align-center">
                        <div style={{ width: '1vw' }} className="align-center width-5vw ">OR</div>
                        {/* <hr className="field-cont-hr" /> */}
                      </td>
                      <td className="align-center">
                        {entry.percentage ?
                          `${parseFloat(entry.percentage) % 1 === 0 ?
                            parseFloat(entry.percentage).toFixed(0)
                            : parseFloat(entry.percentage).toFixed(2)}%`
                          : '-'}
                      </td>
                      <td className="align-leftt width-10vw">
                        {entry.percentage_of.length === 0 ?
                          <div className="align-center">-</div> :
                          // entry.percentage_of?.join(", ") || '-'
                          <ul style={{ paddingLeft: '0' }}>
                            {entry.percentage_of.map((data, index) => (
                              <li key={index}>
                                <span className="form-text-trasformation-uppercase">
                                  {data.name}
                                </span>
                              </li>
                            ))}
                          </ul>
                        }
                      </td>
                      <td className="align-center">
                        <UpdateCustomDeductionPolicy
                          i={entry}
                          deductionData={deductionData}
                          salaryStructureHeadings={salaryStructureHeadings}
                          getSalaryPolicyRelatedData={getSalaryPolicyRelatedData}
                        />
                      </td>
                    </tr>
                  ))}
              </>
              :
              <>
                {formData
                  .sort((a, b) => a.sorting_var - b.sorting_var)
                  .map((entry, index) => (
                    <>
                      <tr className="custom-table-head-td" key={index}>
                        <td className="align-left">{salaryStructureHeadings.find(t => t.id === entry.name)?.title || "Loading..."}</td>
                        <td className="align-center form-text-trasformation-uppercase">{salaryStructureHeadings.find(t => t.id === entry.name)?.salary_type || "Loading..."}</td>
                        <td className="align-center">
                          <input
                            type="number"
                            name="fixed_amount"
                            min={0}
                            placeholder="Fixed Amount"
                            value={entry.fixed_amount || ''}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleInputChange(e, index)}
                            className={`form-input-date ${errors[index] ? 'is-invalid' : ''}`}
                            disabled={!!entry.percentage} // Disable if percentage is provided
                          />
                        </td>
                        <td className="align-center">
                          <div style={{ width: '1vw' }} className="align-center width-5vw ">OR</div>
                          {/* <hr className="field-cont-hr" /> */}
                        </td>
                        <td className="align-center">
                          <input
                            type="number"
                            name="percentage"
                            min={0}
                            placeholder="Percentage"
                            value={entry.percentage || ''}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleInputChange(e, index)}
                            className={`form-input-date ${errors[index] ? 'is-invalid' : ''}`}
                            disabled={!!entry.fixed_amount} // Disable if fixed amount is provided
                          />
                        </td>
                        <td className="align-leftt width-10vw">
                          <Select
                            isMulti
                            name="percentage_of"
                            value={relatedToOptions.filter(option => entry.percentage_of?.includes(option.value))}
                            onChange={(selectedOptions) => handleSelectChange(selectedOptions, index)}
                            options={relatedToOptions}
                            className={` width-15vw ${errors[index] ? 'is-invalid' : ''}`}
                            classNamePrefix="select"
                            placeholder="Select Related To"
                            isDisabled={!!entry.fixed_amount} // Disable if fixed amount is provided
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                // width: '33vw',
                                // height: '50px',
                                borderRadius: '10px',
                                border: '1px solid #e3e3e3',
                                margin: '10px 0px 20px 0px',
                                color: '#4a4a4a',
                                backgroundColor: '#f7f8fa',
                                padding: '0px 10px',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: '10px',
                                marginTop: '0',
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        {errors[index] && <td colSpan='6' className="align-center error-message font-size-text">{errors[index]}</td>}
                      </tr>
                    </>
                  ))}
              </>
            }
          </>
        </tbody>
      </table>

      <div className="button-models">
        {deductionData.length > 0 ?
          (salaryStructureHeadings?.filter(i => !deductionIds.includes(i.id)).length > 0 ?
            <AddCustomDeductionPolicy
              calculationPolicy={calculationPolicy}
              deductionData={deductionData}
              salaryStructureHeadings={salaryStructureHeadings}
              getSalaryPolicyRelatedData={getSalaryPolicyRelatedData}
            /> :
            null
          ) :
          <button
            onClick={handleSubmitCustomDeduction}
            className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Deductions'}
          </button>
        }
      </div>
    </div>
  );
};

const AddCustomDeductionPolicy = ({
  calculationPolicy,
  deductionData,
  salaryStructureHeadings,
  getSalaryPolicyRelatedData,
}) => {

  const [formData, setFormData] = useState({
    name: "",
    salary_policy: calculationPolicy.id,
    salary_type: "",
    percentage: '',
    percentage_of: [], // Initialize as an array for multiple selections
    fixed_amount: '',
  });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const validateForm = () => {
    const newErrors = {};
    const isSalaryHeadingSelected = !!formData.name;
    const hasFixedAmount = formData.fixed_amount !== '';
    const hasPercentage = formData.percentage !== '' && formData.percentage_of.length > 0;

    // Validate Salary Heading
    if (!isSalaryHeadingSelected) {
      newErrors.name = 'Salary Heading is required!';
    }

    // Validation for Fixed Amount or Percentage
    if (!hasFixedAmount && !hasPercentage) {
      newErrors.mixed = 'Either Fixed Amount or Percentage with Related To is required!';
    }

    // Additional validation for Fixed Amount if it's entered
    if (hasFixedAmount && !formData.fixed_amount) {
      newErrors.fixed_amount = 'Fixed Amount is required!';
    }

    // Additional validation for Percentage if it's entered
    if (hasPercentage) {
      if (!formData.percentage) {
        newErrors.percentage = 'Percentage Amount is required!';
      }
      if (formData.percentage_of.length === 0) {
        newErrors.percentage_of = 'Related To is required!';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Handle multiple selections for 'percentage_of'
    if (name === "percentage_of") {
      const options = Array.from(e.target.selectedOptions, option => option.value);
      setFormData({ ...formData, percentage_of: options });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setInputState({ ...inputState, [name]: value.trim() ? "green" : "" });
  };

  const handleHeadingChange = (e) => {
    const { value } = e.target;
    const selectedHeading = salaryStructureHeadings.find(heading => heading.id === value);

    setFormData({
      ...formData,
      name: selectedHeading ? selectedHeading.id : "",
      salary_type: selectedHeading ? selectedHeading.salary_type : "",
    });

    setInputState({
      ...inputState,
      name: value.trim() ? "green" : "",
    });
  };
  const handleSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      percentage_of: selectedOptions ? selectedOptions.map(option => option.value) : [],
    });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const loadingToastId = toast.loading("Loading: Please wait...");

    try {
      const res = await axios.post(`${BASE_URL}/wfm/customdeductionpolicy/`, formData);

      if (res.status === 200 || res.status === 201) {
        handleClose();
        await getSalaryPolicyRelatedData();
      } else {
        toast.error("Error adding deduction");
      }
    } catch (err) {
      toast.error("An error occurred while adding the deduction");
    } finally {
      setLoading(false);
      toast.dismiss(loadingToastId);
    }
  };
  const deductionIds = deductionData.map(item => item.name);
  const relatedToOptions = salaryStructureHeadings
    .filter(i => deductionIds.includes(i.id))
    .map(i => ({ value: i.title, label: i.title }));

  return (
    <div>
      <button title='Add Deduction Policy' className="model-button model-button-leave" onClick={handleShow}>
        Add
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Add Custom Deduction Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <form className="register-form" onSubmit={handleFormSubmit}>
            <div className="form-flex-wrap">
              <div title="Salary Structure Heading" className="flex-column form-group-selectt">
                <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                  Salary Structure Heading<span className="required">*</span>
                </label>
                <br />
                <select
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleHeadingChange}
                  className={`form-input form-group-selection ${errors.name ? "error" : inputState.name ? "success" : ""}`}
                >
                  <option value="">Select Salary Heading</option>
                  {salaryStructureHeadings
                    ?.filter(i => !deductionIds.includes(i.id))
                    .map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.title}
                      </option>
                    ))}
                </select>
                {/* <br /> */}
                <div>
                  {errors.name && <span className="error-message font-size-text">{errors.name}</span>}
                </div>
              </div>

              {formData.percentage || formData.percentage_of.length > 0 ? null : (
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    Fixed Amount<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    name="fixed_amount"
                    placeholder="Fixed Amount"
                    min={0}
                    value={formData.fixed_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                </div>
              )}
              {formData.fixed_amount ? null : (
                <>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading">
                      Percentage Amount<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      name="percentage"
                      placeholder="Percentage Amount"
                      min={0}
                      value={formData.percentage}
                      onChange={handleInputChange}
                      className={`form-input ${errors.percentage ? 'is-invalid' : ''}`}
                    />
                    {errors.percentage && <div className="error-message font-size-text">{errors.percentage}</div>}
                  </div>

                  <div title="Related To" className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading">
                      Related To<span className="required">*</span>
                    </label>
                    <Select
                      isMulti
                      name="percentage_of"
                      value={relatedToOptions.filter(option => formData.percentage_of?.includes(option.value))}
                      onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
                      options={relatedToOptions}
                      className={`width-15vw ${errors.percentage_of ? 'is-invalid' : ''}`}
                      classNamePrefix="select"
                      placeholder="Select Related To"
                      isDisabled={!!formData.fixed_amount} // Disable if fixed amount is provided
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '33vw',
                          minHeight: '50px',
                          borderRadius: '10px',
                          border: '1px solid #e3e3e3',
                          margin: '10px 0px 20px 0px',
                          color: '#4a4a4a',
                          backgroundColor: '#f7f8fa',
                          padding: '0px 10px',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: '33vw',
                          borderRadius: '10px',
                          marginTop: '0',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                      }}
                    />
                    {errors.percentage_of && <div className="error-message font-size-text">{errors.percentage_of}</div>}
                  </div>
                </>
              )}
            </div>
            {errors.mixed && <div className="error-message font-size-text">{errors.mixed}</div>}
            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button className="model-button font-weight500 model-button-leave font-size-heading" disabled={loading}>
                {loading ? 'Adding...' : 'Add'}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const UpdateCustomDeductionPolicy = ({
  i,
  calculationPolicy,
  deductionData,
  salaryStructureHeadings,
  getSalaryPolicyRelatedData
}) => {

  const [formData, setFormData] = useState({
    name: i.id,
    salary_policy: "",
    percentage: '',
    percentage_of: [],
    fixed_amount: '',
  })
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [inputState, setInputState] = useState({});
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const isSalaryHeadingSelected = !!formData.name;
    const hasFixedAmount = formData.fixed_amount !== '';
    const hasPercentage = formData.percentage !== '' && formData.percentage_of.length > 0;

    // Validate Salary Heading
    if (!isSalaryHeadingSelected) {
      newErrors.name = 'Salary Heading is required!';
    }

    // Validation for Fixed Amount or Percentage
    if (!hasFixedAmount && !hasPercentage) {
      newErrors.mixed = 'Either Fixed Amount or Percentage with Related To is required!';
    }

    // Additional validation for Fixed Amount if it's entered
    if (hasFixedAmount && !formData.fixed_amount) {
      newErrors.fixed_amount = 'Fixed Amount is required!';
    }

    // Additional validation for Percentage if it's entered
    if (hasPercentage) {
      if (!formData.percentage) {
        newErrors.percentage = 'Percentage Amount is required!';
      }
      if (formData.percentage_of.length === 0) {
        newErrors.percentage_of = 'Related To is required!';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      percentage_of: selectedOptions ? selectedOptions.map(option => option.value) : [],
    });
  };
  // ?  ************** Validation End **************  ! //


  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      let sucessToastId;


      try {
        let ress = await axios.put(
          `${BASE_URL}/wfm/customdeduction/${i.id}/`,
          formData
        );

        if (ress.status === 200) {
          handleClose();
          await getSalaryPolicyRelatedData();

        } else {
          alert(ress);
        }
      } catch (err) {
        //toast Logic
        handleAllError(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };
  const deductionIds = deductionData.map(item => item.name);
  const relatedToOptions = salaryStructureHeadings
    .filter(i => deductionIds.includes(i.id))
    .map(i => ({ value: i.id, label: i.title }));

  return (
    <div>
      <>
        <button title='Update Deduction Policy' className='model-edit-button' onClick={handleShow}>
          <UpdatePencil />
        </button>
        <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
          <Modal.Header closeButton>
            <Modal.Title>Update Deduction of : "{salaryStructureHeadings?.find(t => t.id === formData.name)?.title} ({salaryStructureHeadings?.find(t => t.id === formData.name)?.salary_type})"</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <form className="register-form">
              <div className="">
                {formData.percentage || formData.percentage_of.length > 0 ? "" :
                  <div className="flex-column">
                    <label className="form-labels  font-weight500 font-size-subheading">Fixed Amount<span className="required">*</span></label>
                    <input
                      type="number"
                      name="fixed_amount"
                      placeholder="Fixed Amount"
                      min={0}
                      value={formData.fixed_amount}
                      onChange={handleInputChange}
                      className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                    />
                    {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                  </div>
                }
                {formData.fixed_amount ? "" :
                  <>
                    <div className="flex-column">
                      <label className="form-labels  font-weight500 font-size-subheading">Percentage Amount<span className="required">*</span></label>
                      <input
                        type="number"
                        name="percentage"
                        placeholder="Percentage Amount"
                        min={0}
                        value={formData.percentage}
                        onChange={handleInputChange}
                        className={`form-input ${errors.percentage ? 'is-invalid' : ''}`}
                      />
                      {errors.percentage && <div className="error-message font-size-text">{errors.percentage}</div>}
                    </div>
                    <div title="Related To" className="flex-column ">
                      <label className="form-labels  font-weight500 font-size-subheading">Related To<span className="required">*</span></label>
                      <Select
                        isMulti
                        name="percentage_of"
                        value={relatedToOptions.filter(option => formData.percentage_of?.includes(option.value))}
                        onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
                        options={relatedToOptions}
                        className={`width-15vw ${errors.percentage_of ? 'is-invalid' : ''}`}
                        classNamePrefix="select"
                        placeholder="Select Related To"
                        isDisabled={!!formData.fixed_amount} // Disable if fixed amount is provided
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '33vw',
                            minHeight: '50px',
                            borderRadius: '10px',
                            border: '1px solid #e3e3e3',
                            margin: '10px 0px 20px 0px',
                            color: '#4a4a4a',
                            backgroundColor: '#f7f8fa',
                            padding: '0px 10px',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: '33vw',
                            borderRadius: '10px',
                            marginTop: '0',
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                        }}
                      />
                      {errors.percentage_of && <div className="error-message font-size-text">{errors.percentage_of}</div>}
                    </div>
                  </>
                }
              </div>
            </form>
            {errors.mixed && <div className="error-message font-size-text">{errors.mixed}</div>}
            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                {loading ? 'Updating...' : 'Update'}
              </button>
            </div>

          </Modal.Body>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>

    </div>
  )
}


const SalaryCalculationPolicyDelete = ({ i, getSalaryCalculationPolicyList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/salarypolicy/${i.id}/`);

      if (res.status === 200) {
        await getSalaryCalculationPolicyList();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Salary Calculation Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Salary Calculation Policy : {i.policy_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Salary Calculation Policy : {i.policy_name}
            <div className="button-models">
              <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete font-weight500"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SalaryCalculationPolicy Crud Models End ***************


// ! ***************  Salary Deduction Calculation Policy Crud Models Start ***************

const SalaryDeductionPolicyAdd = ({ getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: '',
    epf: false,
    epf_criteria: '',
    epf_employee: false,
    epf_employer: false,
    epf_employee_rate: '',
    epf_employer_rate: '',
    esi: false,
    esi_criteria: '',
    esi_employee: false,
    esi_employer: false,
    esi_employee_rate: '',
    esi_employer_rate: '',
    sub_company: '',
  });

  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'name',
      'epf_criteria',
      'epf_employee_rate',
      'epf_employer_rate',
      'esi_criteria',
      'esi_employee_rate',
      'esi_employer_rate',
      'sub_company',
    ];


    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    // // Add more validation for numerical fields as needed
    // if (formData.epf_criteria && isNaN(formData.epf_criteria)) {
    //   newErrors.epf_criteria = 'EPF criteria must be a valid number!';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/salarypolicy/`, formData);
        if (res.status === 200) {
          await getSalaryDeductionPolicyList();
          setShow(false);
          // toast.success('Salary Policy created successfully!');
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" title="Add Salary Deduction Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Add Salary Deduction Policy</Modal.Title></h1>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Salary Deduction Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company<span className="required">*</span>
                </label>
                <select
                  id="sub_company"
                  name="sub_company"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                >
                  <option value=''>Select Sub Company</option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                </select>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">{errors.sub_company}</span>
                )}
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF
                </label>
                <input
                  type="checkbox"
                  name="epf"
                  onChange={handleInputChange}
                  checked={formData.epf}
                  className={`form-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF Criteria
                </label>
                <input
                  id="epf_criteria"
                  name="epf_criteria"
                  value={formData.epf_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.epf_criteria ? "error" : inputState.epf_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.epf_criteria && (
                  <span className="error-message font-size-text ">{errors.epf_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employee"
                      onChange={handleInputChange}
                      checked={formData.epf_employee}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="epf_employee_rate"
                        name="epf_employee_rate"
                        value={formData.epf_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.epf_employee_rate && (
                        <span className="error-message font-size-text ">{errors.epf_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employer"
                      checked={formData.epf_employer}
                      onChange={handleInputChange}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="epf_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer  Rate
                    </label>
                    <input
                      id="epf_employer_rate"
                      name="epf_employer_rate"
                      value={formData.epf_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.epf_employer_rate && (
                      <span className="error-message font-size-text ">{errors.epf_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI
                </label>
                <input
                  type="checkbox"
                  name="esi"
                  onChange={handleInputChange}
                  checked={formData.esi}
                  className={`form-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI Criteria
                </label>
                <input
                  id="esi_criteria"
                  name="esi_criteria"
                  value={formData.esi_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.esi_criteria ? "error" : inputState.esi_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.esi_criteria && (
                  <span className="error-message font-size-text ">{errors.esi_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employee"
                      onChange={handleInputChange}
                      checked={formData.esi_employee}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="esi_employee_rate"
                        name="esi_employee_rate"
                        value={formData.esi_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.esi_employee_rate && (
                        <span className="error-message font-size-text ">{errors.esi_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employer"
                      onChange={handleInputChange}
                      checked={formData.esi_employer}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="esi_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer Rate
                    </label>
                    <input
                      id="esi_employer_rate"
                      name="esi_employer_rate"
                      value={formData.esi_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.esi_employer_rate && (
                      <span className="error-message font-size-text ">{errors.esi_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>


              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const SalaryDeductionPolicyUpdate = ({ i, getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };

  const [formData, setFormData] = useState({
    name: '',
    epf: false,
    epf_criteria: '',
    epf_employee: false,
    epf_employer: false,
    epf_employee_rate: '',
    epf_employer_rate: '',
    esi: false,
    esi_criteria: '',
    esi_employee: false,
    esi_employer: false,
    esi_employee_rate: '',
    esi_employer_rate: '',
    sub_company: '',
  });
  console.log("formData")
  console.log(formData)
  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'name',
      'epf_criteria',
      'epf_employee_rate',
      'epf_employer_rate',
      'esi_criteria',
      'esi_employee_rate',
      'esi_employer_rate',
      'sub_company',
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/salarypolicy/${i.id}/`,
          formData
        );
        if (res.status === 200) {
          await getSalaryDeductionPolicyList();
          setShow(false);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Salary Deduction Policy" onClick={handleShow}>
        <UpdatePencil />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Salary Deduction Policy: <br />{i.name}</Modal.Title></h1>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Salary Deduction Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company<span className="required">*</span>
                </label>
                <select
                  id="sub_company"
                  name="sub_company"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                >
                  <option value=''>Select Sub Company</option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                </select>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">{errors.sub_company}</span>
                )}
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF
                </label>
                <input
                  type="checkbox"
                  name="epf"
                  onChange={handleInputChange}
                  checked={formData.epf}
                  className={`form-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF Criteria
                </label>
                <input
                  id="epf_criteria"
                  name="epf_criteria"
                  value={formData.epf_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.epf_criteria ? "error" : inputState.epf_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.epf_criteria && (
                  <span className="error-message font-size-text ">{errors.epf_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employee"
                      onChange={handleInputChange}
                      checked={formData.epf_employee}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="epf_employee_rate"
                        name="epf_employee_rate"
                        value={formData.epf_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.epf_employee_rate && (
                        <span className="error-message font-size-text ">{errors.epf_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employer"
                      checked={formData.epf_employer}
                      onChange={handleInputChange}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="epf_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer  Rate
                    </label>
                    <input
                      id="epf_employer_rate"
                      name="epf_employer_rate"
                      value={formData.epf_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.epf_employer_rate && (
                      <span className="error-message font-size-text ">{errors.epf_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI
                </label>
                <input
                  type="checkbox"
                  name="esi"
                  onChange={handleInputChange}
                  checked={formData.esi}
                  className={`form-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI Criteria
                </label>
                <input
                  id="esi_criteria"
                  name="esi_criteria"
                  value={formData.esi_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.esi_criteria ? "error" : inputState.esi_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.esi_criteria && (
                  <span className="error-message font-size-text ">{errors.esi_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employee"
                      onChange={handleInputChange}
                      checked={formData.esi_employee}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="esi_employee_rate"
                        name="esi_employee_rate"
                        value={formData.esi_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.esi_employee_rate && (
                        <span className="error-message font-size-text ">{errors.esi_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employer"
                      onChange={handleInputChange}
                      checked={formData.esi_employer}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="esi_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer Rate
                    </label>
                    <input
                      id="esi_employer_rate"
                      name="esi_employer_rate"
                      value={formData.esi_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.esi_employer_rate && (
                      <span className="error-message font-size-text ">{errors.esi_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const SalaryDeductionPolicyDelete = ({ i, getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/salarypolicy/${i.id}/`);

      if (res.status === 200) {
        await getSalaryDeductionPolicyList();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Salary Deduction Policy" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Salary Deduction Policy : {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Salary Deduction Policy : {i.name}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


// ? *************** Salary Deduction Calculation Policy Crud Models End ***************

// ! ***************  Investment Heading Crud Models Start ***************

const InvestmentHeadingAdd = ({ getInvestmentHeadings }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id'),
  });

  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "sub_company",
    ];


    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    // // Add more validation for numerical fields as needed
    // if (formData.epf_criteria && isNaN(formData.epf_criteria)) {
    //   newErrors.epf_criteria = 'EPF criteria must be a valid number!';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/investmentheading/`, formData);
        if (res.status === 200) {
          await getInvestmentHeadings();
          setShow(false);
          // toast.success('Salary Policy created successfully!');
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" title="Add Investment Heading" onClick={handleShow}>
        <AddwithBlueCircle />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Add Investment Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Heading Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select Site Company  </option>
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.sub_company && (
                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                  )}
                </div>
                : null
              }

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const InvestmentHeadingUpdate = ({ i, getInvestmentHeadings }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id'),
  });
  console.log("formData")
  console.log(formData)
  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "sub_company",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.put(`${BASE_URL}/wfm/investmentheading/${i.id}/`, formData);
        if (res.status === 200) {
          await getInvestmentHeadings();
          setShow(false);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Salary Heading" onClick={handleShow}>
        <UpdatePencil />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Update Investment Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Investment Heading Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select Site Company  </option>
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.sub_company && (
                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                  )}
                </div>
                : null
              }

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const InvestmentHeadingDelete = ({ i, getInvestmentHeadings }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/investmentheading/${i.id}/`);

      if (res.status === 200) {
        await getInvestmentHeadings();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Investment Heading" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Investment Heading : {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Investment Heading : "{i.title}"
            <div className="button-models">
              <button className="model-button model-button-cancel  font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


// ? *************** Investment Heading Policy Crud Models End ***************


// ! *************** WeeklyOffPolicy Crud Models Start ***************

const WeeklyOffPolicy = ({ getWeeklyOffPolicyName }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: "",
    off_days: "",
    sub_company: sessionStorage.getItem('company_id')

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/weeklyoffPolicy/`, formData);

        if (res.status === 200) {

          setShow(false);
          await getWeeklyOffPolicyName();
        } else {
          alert(res);
        }
      } catch (err) {
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  // const optionss = allemployeedata
  //   .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
  //   .map((e) => ({
  //     value: e.emp_code,
  //     label: `${e.emp_code} - ${e.name}`,
  //   }));
  const options = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  const weekdays = [
    { code: 1, name: "Sunday" },
    { code: 2, name: "Monday" },
    { code: 3, name: "Tuesday" },
    { code: 4, name: "Wednesday" },
    { code: 5, name: "Thursday" },
    { code: 6, name: "Friday" },
    { code: 7, name: "Saturday" }
  ];

  const weekdaysOptions = weekdays.map(day => ({
    value: day.name,
    label: `${day.name}`
  }));

  const handleChangeDays = (selected) => {
    const offDays = selected.length > 0 ? selected.map((item) => item.value).join(", ") : "";
    setFormData((prevState) => ({
      ...prevState,
      off_days: offDays,
    }));
  };




  return (
    <>
      <button className="models-button model-add" title="Add WeekOff Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Attendance Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  {errors.title && (
                    <span className="error-message font-size-text ">{errors.title}</span>
                  )}
                </div>
                : null}
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Days to be Weekly Off
                </label>

                <Select
                  options={weekdaysOptions}
                  isMulti
                  onChange={handleChangeDays}
                />
                {errors.remarks && (
                  <span className="error-message font-size-text ">{errors.remarks}</span>
                )}
              </div>



              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const WeeklyOffPolicyUpdate = ({ i, getWeeklyOffPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
    // console.log("formData -");
    // console.log(formData);
    // console.log("formData x");
    // console.log("I -");
    // console.log(i);
    // console.log("I x");
  };

  const [formData, setFormData] = useState({
    name: "",
    off_days: "",
  });

  const [selectedDays, setSelectedDays] = useState([]);

  // useEffect(() => {
  //   setFormData(i);
  // }, [i]);

  console.log(formData)

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/weeklyoffPolicy/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getWeeklyOffPolicyName();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };
  // const weekdays = [
  //   { code: 1, name: "Sunday" },
  //   { code: 2, name: "Monday" },
  //   { code: 3, name: "Tuesday" },
  //   { code: 4, name: "Wednesday" },
  //   { code: 5, name: "Thursday" },
  //   { code: 6, name: "Friday" },
  //   { code: 7, name: "Saturday" }
  // ];

  // const weekdaysOptions = weekdays.map(day => ({
  //   value: day.name,
  //   label: `${day.name}`
  // }));

  // const handleChangeDays = (selected) => {
  //   const offDays = selected.length > 0 ? selected.map((item) => item.value).join(", ") : "";
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     off_days: offDays,
  //   }));
  //   setSelectedDays(selected);

  // };

  // useEffect(() => {
  //   // Assuming formData.off_type contains the value "Friday, Saturday, Sunday"
  //   const selectedDaysArray = formData.off_type?.split(", ").map((day) => ({
  //     value: day,
  //     label: day,
  //   }));
  //   setSelectedDays(selectedDaysArray);
  // }, [formData.off_type]);


  const weekdaysOptions = [
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" }
  ];

  const handleChangeDays = (selected) => {
    const offDays = selected.map((item) => item.value).join(", ");
    setFormData((prevState) => ({
      ...prevState,
      off_days: offDays,
    }));
  };

  useEffect(() => {
    if (formData.off_days) {
      const selectedDaysArray = formData.off_days.split(", ").map((day) => ({
        value: day,
        label: day,
      }));
      setSelectedDays(selectedDaysArray);
    }
  }, [formData.off_days]);

  return (
    <>
      <button className="model-edit-button" title="Update WeekOff Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update Policy {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Attendance Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>


              <div className="flex-column">
                <label htmlFor="off_days" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Days to be Weekly Off
                </label>

                <Select
                  options={weekdaysOptions}
                  isMulti
                  value={selectedDays}
                  onChange={handleChangeDays}
                />
                {errors.remarks && (
                  <span className="error-message font-size-text ">{errors.remarks}</span>
                )}
              </div>



              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal >
    </>
  );
};

const WeeklyOffPolicyDelete = ({ i, getWeeklyOffPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/weeklyoffPolicy/${i.id}/`);

      if (res.status === 200) {
        await getWeeklyOffPolicyName();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete WeekOff Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Weeklyoff Policy : {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Weekly Off Policy : {i.name}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Weekly Off Policy Crud Models End ***************

// ! *************** Calender Crud Models Start ***************
const CalenderModel = ({ i, getcalender }) => {

  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    date: "",
    descritpion: "",
    offtype: "",
    sub_company: sessionStorage.getItem('company_id')
  });



  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["date", "descritpion", "offtype"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/calander/`, formData);

        if (res.status === 200) {
          await getcalender();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };
  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Calender</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input form-input-background ${errors.date ? "error" : inputState.date ? "success" : ""
                    }`}
                />
                {errors.date && <span className="error-message font-size-text ">{errors.date}</span>}
              </div>
              <div className="flex-column form-group-select">
                <label className="form-labels  font-weight500    font-size-subheading">
                  Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="offtype"
                  name="offtype"
                  value={formData.offtype}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection form-input-background ${errors.offtype ? "error" : inputState.offtype ? "success" : ""
                    }`}
                >
                  <option value="">Offtype</option>
                  {/* <option value="weeklyoff">Weekly off</option> */}
                  <option value="holiday">Holiday</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.offtype && (
                    <span className="error-message font-size-text ">{errors.offtype}</span>
                  )}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="descritpion" className="form-labels  font-weight500    font-size-subheading">
                  Description<span className="required">*</span>
                </label>
                <input
                  type="descritpion"
                  id="descritpion"
                  name="descritpion"
                  placeholder="Description ..."
                  onChange={handleInputChange}
                  value={formData.descritpion}
                  className={`form-input ${errors.descritpion ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.descritpion && (
                  <span className="error-message font-size-text ">{errors.descritpion}</span>
                )}
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  {errors.title && (
                    <span className="error-message font-size-text ">{errors.title}</span>
                  )}
                </div>
                : null}

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  )
}

const CalenderUpdate = ({ i, getcalender }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    date: "",
    descritpion: "",
    offtype: "",
  });
  console.log(formData)

  useEffect(() => {
    setFormData(i);
  }, [i]);

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["date", "descritpion", "offtype"];
    console.log(requiredFields)
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/calander/${i.date}/`,
          formData);

        if (res.status === 200) {
          await getcalender();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Calender</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
                  Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  placeholder="Date"
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input ${errors.date ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.date && (
                  <span className="error-message font-size-text ">{errors.date}</span>
                )}
              </div>

              <div className="flex-column form-group-select">
                <label className="form-labels  font-weight500    font-size-subheading">
                  Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="offtype"
                  name="offtype"
                  value={formData.offtype}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection form-input-background ${errors.offtype ? "error" : inputState.offtype ? "success" : ""
                    }`}
                >
                  <option value="">Offtype</option>
                  <option value="weeklyoff">Weekly off</option>
                  <option value="holiday">Holiday</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.offtype && (
                    <span className="error-message font-size-text ">{errors.offtype}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="descritpion" className="form-labels  font-weight500    font-size-subheading">
                  Description<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="descritpion"
                  name="descritpion"
                  placeholder="Description..."
                  onChange={handleInputChange}
                  value={formData.descritpion}
                  className={`form-input ${errors.descritpion ? "error" : inputState.descritpion ? "success" : ""
                    }`}
                />
                {errors.descritpion && (
                  <span className="error-message font-size-text ">{errors.descritpion}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  )
}

const CalenderDelete = ({ i, getcalender }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/calander/${i.date}/`);

      if (res.status === 200) {
        await getcalender();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };
  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Calender {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  )
}
// ! *************** Calender Crud Models End ***************

// ! *************** InterCom Crud Models Start ***************

const InterCom = ({ getintercom, employeeList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [formData, setFormData] = useState({
    user_detail: "",
    work_email: "",
    contactno: "",
    extensionno: "",
    sub_company: sessionStorage.getItem('company_id')

  })
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["user_detail", "work_email", "contactno", "extensionno"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); // loading logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/intercom/`, formData);

        if (res.status === 200) {
          await getintercom();
          setShow(false);
        }
      } catch (err) {
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); // Stop loading regardless of the outcome
        toast.dismiss(loadingToastId);
      }
    }

  };
  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>InterCom</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}


              <div className="flex-column form-group-selectt">
                <label htmlFor="user_detail" className="form-labels  font-weight500    font-size-subheading">
                  Employee<span className="required">*</span>
                </label>
                <br />
                <select
                  id="user_detail"
                  name="user_detail"
                  value={formData.user_detail}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.user_detail ? "error" : inputState.user_detail ? "success" : ""
                    }`}
                >
                  <option value="">Select Employee</option>
                  {employeeList.map((i) => (<>
                    <option value={i.emp_code}>{i.emp_code}-{i.name}</option>
                  </>))
                  }
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.emp_code && (
                    <span className="error-message font-size-text ">{errors.emp_code}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="work_email" className="form-labels  font-weight500    font-size-subheading">
                  Work Email<span className="required">*</span>
                </label>
                <input
                  type="emial"
                  id="work_email"
                  name="work_email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  value={formData.work_email}
                  className={`form-input ${errors.work_email ? "error" : inputState.work_email ? "success" : ""
                    }`}
                />
                {errors.work_email && (
                  <span className="error-message font-size-text ">{errors.work_email}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="contactno" className="form-labels  font-weight500    font-size-subheading">
                  Contact no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="contactno"
                  name="contactno"
                  placeholder="Contact no"
                  onChange={handleInputChange}
                  value={formData.contactno}
                  className={`form-input ${errors.contactno ? "error" : inputState.contactno ? "success" : ""
                    }`}
                />
                {errors.contactno && (
                  <span className="error-message font-size-text ">{errors.contactno}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="extensionno" className="form-labels  font-weight500    font-size-subheading">
                  Extension no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="extensionno"
                  name="extensionno"
                  placeholder="Office extension no"
                  onChange={handleInputChange}
                  value={formData.extensionno}
                  className={`form-input ${errors.extensionno ? "error" : inputState.extensionno ? "success" : ""
                    }`}
                />
                {errors.extensionno && (
                  <span className="error-message font-size-text ">{errors.extensionno}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>

      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  )
}

const InterComUpdate = ({ i, getintercom, employeeList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    user_detail: "",
    work_email: "",
    contactno: "",
    extensionno: "",
  })

  useEffect(() => {
    setFormData(i);
  }, [i]);


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["user_detail", "work_email", "contactno", "extensionno"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(`${BASE_URL}/wfm/intercom/${i.id}/`, formData);

        if (res.status === 200) {
          await getintercom();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };
  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update InterCom</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}


              <div className="flex-column form-group-selectt">
                <label htmlFor="user_detail" className="form-labels  font-weight500    font-size-subheading">
                  Employee<span className="required">*</span>
                </label>
                <br />
                <select
                  id="user_detail"
                  name="user_detail"
                  value={formData.user_detail}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.user_detail ? "error" : inputState.user_detail ? "success" : ""
                    }`}
                >
                  <option value="">Select Employee</option>
                  {employeeList.map((i) => (<>
                    <option value={i.emp_code}>{i.emp_code}-{i.name}</option>
                  </>))
                  }
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.emp_code && (
                    <span className="error-message font-size-text ">{errors.emp_code}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="work_email" className="form-labels  font-weight500    font-size-subheading">
                  Work Email<span className="required">*</span>
                </label>
                <input
                  type="emial"
                  id="work_email"
                  name="work_email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  value={formData.work_email}
                  className={`form-input ${errors.work_email ? "error" : inputState.work_email ? "success" : ""
                    }`}
                />
                {errors.work_email && (
                  <span className="error-message font-size-text ">{errors.work_email}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="contactno" className="form-labels  font-weight500    font-size-subheading">
                  Contact no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="contactno"
                  name="contactno"
                  placeholder="Contact no"
                  onChange={handleInputChange}
                  value={formData.contactno}
                  className={`form-input ${errors.contactno ? "error" : inputState.contactno ? "success" : ""
                    }`}
                />
                {errors.contactno && (
                  <span className="error-message font-size-text ">{errors.contactno}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="extensionno" className="form-labels  font-weight500    font-size-subheading">
                  Extension no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="extensionno"
                  name="extensionno"
                  placeholder="Office extension no"
                  onChange={handleInputChange}
                  value={formData.extensionno}
                  className={`form-input ${errors.extensionno ? "error" : inputState.extensionno ? "success" : ""
                    }`}
                />
                {errors.extensionno && (
                  <span className="error-message font-size-text ">{errors.extensionno}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  )
}

const InterComDelete = ({ i, getintercom }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/intercom/${i.id}/`);

      if (res.status === 200) {
        await getintercom();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };
  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete InterCom {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete nterCom {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  )
}

// ! *************** InterCom Crud Models End ***************

// ! *************** Essl Crud Models End ***************

const EsslList = ({ getEssl }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    user_detail: "",
    work_email: "",
    contactno: "",
    extensionno: "",
    sub_company: sessionStorage.getItem('company_id')

  })
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["user_detail", "work_email", "contactno", "extensionno"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/intercom/`, formData);

        if (res.status === 200) {
          await getEssl();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };
  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Essl Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}

              <div className="flex-column">
                <label htmlFor="user_detail" className="form-labels  font-weight500    font-size-subheading">
                  Employee Code<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="user_detail"
                  placeholder="Emp Code"
                  name="user_detail"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formData.user_detail}
                  className={`form-input form-input-background ${errors.user_detail ? "error" : inputState.user_detail ? "success" : ""
                    }`}
                />
                {errors.user_detail && <span className="error-message font-size-text ">{errors.user_detail}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="work_email" className="form-labels  font-weight500    font-size-subheading">
                  Work Email<span className="required">*</span>
                </label>
                <input
                  type="emial"
                  id="work_email"
                  name="work_email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  value={formData.work_email}
                  className={`form-input ${errors.work_email ? "error" : inputState.work_email ? "success" : ""
                    }`}
                />
                {errors.work_email && (
                  <span className="error-message font-size-text ">{errors.work_email}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="contactno" className="form-labels  font-weight500    font-size-subheading">
                  Contact no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="contactno"
                  name="contactno"
                  placeholder="Contact no"
                  onChange={handleInputChange}
                  value={formData.contactno}
                  className={`form-input ${errors.contactno ? "error" : inputState.contactno ? "success" : ""
                    }`}
                />
                {errors.contactno && (
                  <span className="error-message font-size-text ">{errors.contactno}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="extensionno" className="form-labels  font-weight500    font-size-subheading">
                  Extension no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="extensionno"
                  name="extensionno"
                  placeholder="Office extension no"
                  onChange={handleInputChange}
                  value={formData.extensionno}
                  className={`form-input ${errors.extensionno ? "error" : inputState.extensionno ? "success" : ""
                    }`}
                />
                {errors.extensionno && (
                  <span className="error-message font-size-text ">{errors.extensionno}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  )
}

const EsslUpdate = ({ i, getEssl }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    user_detail: "",
    work_email: "",
    contactno: "",
    extensionno: "",
  })

  useEffect(() => {
    setFormData(i);
  }, [i]);


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["user_detail", "work_email", "contactno", "extensionno"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(`${BASE_URL}/wfm/intercom/${i.id}/`, formData);

        if (res.status === 200) {
          await getEssl();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };
  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update InterCom</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="user_detail" className="form-labels  font-weight500    font-size-subheading">
                  Employee Code<span className="required">*</span>
                </label>
                <input
                  // type=""
                  id="user_detail"
                  placeholder="Emp Code"
                  name="user_detail"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formData.user_detail}
                  className={`form-input form-input-background ${errors.user_detail ? "error" : inputState.user_detail ? "success" : ""
                    }`}
                />
                {errors.user_detail && <span className="error-message font-size-text ">{errors.user_detail}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="work_email" className="form-labels  font-weight500    font-size-subheading">
                  Work Email<span className="required">*</span>
                </label>
                <input
                  type="emial"
                  id="work_email"
                  name="work_email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  value={formData.work_email}
                  className={`form-input ${errors.work_email ? "error" : inputState.work_email ? "success" : ""
                    }`}
                />
                {errors.work_email && (
                  <span className="error-message font-size-text ">{errors.work_email}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="contactno" className="form-labels  font-weight500    font-size-subheading">
                  Contact no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="contactno"
                  name="contactno"
                  placeholder="Contact no"
                  onChange={handleInputChange}
                  value={formData.contactno}
                  className={`form-input ${errors.contactno ? "error" : inputState.contactno ? "success" : ""
                    }`}
                />
                {errors.contactno && (
                  <span className="error-message font-size-text ">{errors.contactno}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="extensionno" className="form-labels  font-weight500    font-size-subheading">
                  Extension no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="extensionno"
                  name="extensionno"
                  placeholder="Office extension no"
                  onChange={handleInputChange}
                  value={formData.extensionno}
                  className={`form-input ${errors.extensionno ? "error" : inputState.extensionno ? "success" : ""
                    }`}
                />
                {errors.extensionno && (
                  <span className="error-message font-size-text ">{errors.extensionno}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  )
}

const EsslDelete = ({ i, getEssl }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/intercom/${i.id}/`);

      if (res.status === 200) {
        await getEssl();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };
  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete InterCom {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete nterCom {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  )
}

// ! *************** Essl Crud Models End ***************



export {
  Department,
  DepartmentUpdate,
  DepartmentDelete,
  DesignationName,
  DesignationUpdate,
  DesignationDelete,
  Subcompanys,
  Subcompanyupdate,
  SubcompanyDelete,
  AttendancePolicy,
  AttendancePolicyUpdate,
  AttendancePolicyDelete,
  WeeklyOffPolicy,
  WeeklyOffPolicyUpdate,
  WeeklyOffPolicyDelete,
  SalaryStructureHeadingAdd,
  SalaryStructureHeadingUpdate,
  SalaryStructureHeadingDelete,
  SalaryDeductionPolicyAdd,
  SalaryDeductionPolicyUpdate,
  SalaryDeductionPolicyDelete,
  InvestmentHeadingAdd,
  InvestmentHeadingUpdate,
  InvestmentHeadingDelete,
  SalaryCalculationPolicyAdd,
  SalaryCalculationPolicyUpdate,
  SalaryCalculationPolicyDelete,
  Woksiteadd,
  Woksiteupdate,
  WoksiteDelete,
  CalenderModel,
  CalenderUpdate,
  CalenderDelete,
  InterCom,
  InterComUpdate,
  InterComDelete,
  EsslList,
  EsslUpdate,
  EsslDelete,
};
