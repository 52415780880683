import React, { useState } from "react";
import {
  AttendancePolicyTable,
  InvestmentHeadingTable,
  SalaryCalculationPolicyTable,
  SalaryDeductionPolicyTable,
  SalaryStructureHeadingTable,
  WeeklyOffPolicyTable,
} from "./Organizationtables";



const OrgPolicies = () => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <>
      <div>
        <div>
          <div className="bloc-tabs-with-white-bg">
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(1)}
            >
              Attendace Policy
            </button>

            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(2)}
            >
              Weekly Off Policy
            </button>

            <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(3)}
            >
              Salary Structure Headings
            </button>
            {/* <button
              className={
                toggleState === 4
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(4)}
            >
              Statutory Deduction Policy
            </button> */}
            <button
              className={
                toggleState === 5
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(5)}
            >
              Salary Custom Deductions
            </button>
            <button
              className={
                toggleState === 6
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(6)}
            >
              Investment Heading
            </button>

          </div>
        </div>

        <div className="content-tabs-without-margin">

          {toggleState === 1 ? <AttendancePolicyTable /> : null}

          {toggleState === 2 ? <WeeklyOffPolicyTable /> : null}

          {toggleState === 3 ? <SalaryStructureHeadingTable /> : null}

          {toggleState === 4 ? <SalaryDeductionPolicyTable /> : null}

          {toggleState === 5 ? <SalaryCalculationPolicyTable /> : null}

          {toggleState === 6 ? <InvestmentHeadingTable /> : null}

        </div>
      </div>
    </>
  );
};

export default OrgPolicies;