import React, { useState } from 'react'
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import usePermission from '../../config/permissions';
import { EmployeeItemsList, ItemListTable, StockToEmployeeList, StockListTable } from './StationaryTables';


const StationaryManagement = () => {
    const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission();

    const [toggleState, setToggleState] = useState(1);
    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return 'Items List';
            case 2:
                return 'Stock List';
            case 3:
                return 'Employee Stationary';
            default:
                return 'Items List';
        }
    };

    return (
        <>
            <Dashboardnavbarcopy name={getSelectedTitle()} url="Stationary Managment" />
            <div className="bloc-tabss2  wfm-margin">
                <button
                    className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                    onClick={() => setToggleState(1)}
                >
                    Items List
                </button>
                <button
                    className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                    onClick={() => setToggleState(2)}
                >
                    Stock List
                </button>
                <button
                    className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                    onClick={() => setToggleState(3)}
                >
                    Employee Stationary
                </button>


            </div>
            <div className="content-tabs">

                {toggleState === 1 ? (
                    <ItemListTable />
                ) : null}
                {toggleState === 2 ? (
                    <StockListTable />
                ) : null}
                {toggleState === 3 ? (
                    <EmployeeItemsList />
                ) : null}

            </div >
        </>
    );
};





export default StationaryManagement;
