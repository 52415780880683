import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";



const SidebarLink = (props) => {
  const { data, index, currentPath } = props;
  let paths = currentPath.split("/");
  paths = paths[2];
  return (
    <Link
      to={`${data.userId}`}
      className="dashboard-sidebar-middle-menu-closed tooltipp-container"
      key={index}
    >
      <div className="tooltippp" dangerouslySetInnerHTML={{ __html: data.userId === paths ? data.svgfill : data.svg }} />
      <div className="tooltipp">
        {data.name}
      </div>
    </Link>
  );
};

const DashboardSidebar = (props) => {
  const { sidebarData, isexpand, setExpanded } = props;
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [location]);





  return (
    <div className={isexpand ? "dashboard-main-sidebar-section" : "dashboard-main-sidebar-section-closed"}>
      <div>
        <img className="sidebar-closed-logo-cipl" src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt="logo" />

      </div>

      <div className="sidebar-title   font-weight400  font-size-subheading">
        Menu
      </div>
      <div>
        {sidebarData.map((data, index) => {
          return data.subMenu ? (
            ""
          ) : (
            <SidebarLink data={data} currentPath={currentPath} index={index} isexpand={isexpand} key={index} />
          );
        })}
      </div>
    </div>
  );
};

export default DashboardSidebar