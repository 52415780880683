import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FeatureItem = ({ item, currentPath }) => {
  const isActive = currentPath === item.path;

  return (
    <Link to={item.path} className={`feature-item ${isActive ? 'active' : ''}`}>
      <div
        className="feature-icon"
        dangerouslySetInnerHTML={{ __html: isActive ? item.svgfill : item.svg }}
      />
      <span className="feature-label">{item.label}</span>
    </Link>
  );
};

const FeatureMenu = ({ features, currentPath }) => {
  return (
    <div className="feature-menu">
      {features.map((item, index) => (
        <FeatureItem key={index} item={item} currentPath={currentPath} />
      ))}
    </div>
  );
};

// Define prop types for TypeScript or PropTypes validation
FeatureMenu.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      svg: PropTypes.string.isRequired,
      svgfill: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default FeatureMenu;
