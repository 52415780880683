import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import { formattedDateLong, formattedDateNoYear } from "../Date";

const AddStationaryItem = ({ getItemsList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        item_name: "",
        sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_name"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/wfm/stationary/`,
                    formData,
                );

                if (res.status === 200) {
                    await getItemsList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Add Item" className="models-button model-add" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6>

                            <form>
                                <div className="flex-column">
                                    <label htmlFor="item_name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                        Name
                                    </label>
                                    <input
                                        id="item_name"
                                        type="item_name"
                                        name="item_name"
                                        maxLength={50}
                                        placeholder="Item Name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.item_name ? "error" : inputState.item_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.item_name && (
                                        <span className="error-message font-size-text ">{errors.item_name}</span>
                                    )}
                                </div>

                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateStationaryItem = ({ i, getItemsList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i)
        setShow(true)
    };

    const [formData, setFormData] = useState({
        item_name: "",
    });


    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_name"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/wfm/stationary/${i.id}/`,
                    formData,

                );

                if (res.status === 200) {
                    await getItemsList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Update Item" className="model-edit-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Announcement</h6>

                            <form>
                                <div className="flex-column">
                                    <label htmlFor="item_name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                        Name
                                    </label>
                                    <input
                                        id="item_name"
                                        type="item_name"
                                        name="item_name"
                                        placeholder="Item Name"
                                        onChange={handleInputChange}
                                        value={formData.item_name}
                                        className={`form-input ${errors.item_name ? "error" : inputState.item_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.item_name && (
                                        <span className="error-message font-size-text ">{errors.item_name}</span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteStationaryItem = ({ i, getItemsList }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/wfm/stationary/${i.id}/`
            );

            if (res.status === 200) {
                await getItemsList();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Item" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item {i.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to Delete Item {i.name}
                        <div className="button-models">
                            <button className="model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};


const AddItemToStock = ({ getStockList, itemList }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [formData, setFormData] = useState({
        item_id: "",
        quantity: 0,
        vendor_name: "",
        purchase_date: formattedDate,
        rate_per_item: 0,
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_id", "quantity", "vendor_name", "rate_per_item"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/wfm/stationary-inventory-details/`, formData,);

                if (res.status === 200) {
                    await getStockList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    return (
        <>
            <button title="Add Stock Item" className="models-button model-add" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Stock Item</h6>

                            <form>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="item_id" className="form-labels  font-weight500    font-size-subheading">
                                        Item<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="item_id"
                                        name="item_id"
                                        value={formData.item_id}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.item_id ? "error" : inputState.item_id ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Item</option>
                                        {itemList.map((i) => (<>
                                            <option value={i.id}>{i.item_name}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.item_id && (
                                            <span className="error-message font-size-text ">{errors.item_id}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                                        Quantity<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="quantity"
                                        name="quantity"
                                        onChange={handleInputChange}
                                        value={formData.quantity}
                                        className={`form-text-trasformation-uppercase form-input ${errors.quantity ? "error" : inputState.quantity ? "success" : ""
                                            }`}
                                    />
                                    {errors.quantity && <span className="error-message font-size-text ">{errors.quantity}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="vendor_name" className="form-labels  font-weight500    font-size-subheading">
                                        Vendor<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="vendor_name"
                                        name="vendor_name"
                                        onChange={handleInputChange}
                                        value={formData.vendor_name}
                                        className={`form-input ${errors.vendor_name ? "error" : inputState.vendor_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.vendor_name && <span className="error-message font-size-text ">{errors.vendor_name}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="rate_per_item" className="form-labels  font-weight500    font-size-subheading">
                                        Rate Per Item<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="rate_per_item"
                                        name="rate_per_item"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_item}
                                        className={`form-text-trasformation-uppercase form-input ${errors.rate_per_item ? "error" : inputState.rate_per_item ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_item && <span className="error-message font-size-text ">{errors.rate_per_item}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="purchase_date" className="form-labels  font-weight500    font-size-subheading">
                                        Purchase Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="purchase_date"
                                        name="purchase_date"
                                        onChange={handleInputChange}
                                        value={formData.purchase_date}
                                        className={`form-input ${errors.purchase_date ? "error" : inputState.purchase_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.purchase_date && <span className="error-message font-size-text ">{errors.purchase_date}</span>}
                                </div>

                            </form>

                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateItemInStock = ({ i, getStockList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i);
        setShow(true)
    };


    const [formData, setFormData] = useState({
        item_id: "",
        quantity: 0,
        vendor_name: "",
        purchase_date: "",
        rate_per_item: 0,
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_id", "quantity", "vendor_name", "rate_per_item", "purchase_date"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/wfm/stationary-inventory-details/${i.id}/`,
                    formData,
                );

                if (res.status === 200) {
                    await getStockList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    return (
        <>
            <button title="Update Stock Item" className="model-edit-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Update : {i.item_name}</h6>

                            <form>


                                {/* <div className="flex-column">
                                        <label htmlFor="quantity" className="form-labels  font-weight500   ">
                                            Quantity<span className="required">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            min={0}
                                            id="quantity"
                                            name="quantity"
                                            onChange={handleInputChange}
                                            value={formData.quantity}
                                            className={`form-text-trasformation-uppercase form-input ${errors.quantity ? "error" : inputState.quantity ? "success" : ""
                                                }`}
                                        />
                                        {errors.quantity && <span className="error-message">{errors.quantity}</span>}
                                    </div> */}

                                <div className="flex-column">
                                    <label htmlFor="vendor_name" className="form-labels  font-weight500    font-size-subheading">
                                        Vendor<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="vendor_name"
                                        name="vendor_name"
                                        onChange={handleInputChange}
                                        value={formData.vendor_name}
                                        className={`form-input ${errors.vendor_name ? "error" : inputState.vendor_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.vendor_name && <span className="error-message font-size-text ">{errors.vendor_name}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="rate_per_item" className="form-labels  font-weight500    font-size-subheading">
                                        Rate Per Item<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="rate_per_item"
                                        name="rate_per_item"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_item}
                                        className={`form-text-trasformation-uppercase form-input ${errors.rate_per_item ? "error" : inputState.rate_per_item ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_item && <span className="error-message font-size-text ">{errors.rate_per_item}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="purchase_date" className="form-labels  font-weight500    font-size-subheading">
                                        Purchase Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="purchase_date"
                                        name="purchase_date"
                                        onChange={handleInputChange}
                                        value={formData.purchase_date}
                                        className={`form-input ${errors.purchase_date ? "error" : inputState.purchase_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.purchase_date && <span className="error-message font-size-text ">{errors.purchase_date}</span>}
                                </div>


                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteItemFromStock = ({ i, getStockList }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/wfm/stationary-inventory-details/${i.id}/`
            );

            if (res.status === 200) {
                await getStockList();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Stock Item" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete {i.item_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete {i.item_name} from Inventory
                        <div className="button-models">
                            <button className="model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};



const GiveEmployeeStationary = ({ getEmployeeItemsList, getStockList, stockItemList, employeeList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getStockList()
        setShow(true)
    };

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [formData, setFormData] = useState({
        stat_inventory_details: "",
        employee: "",
        quantity: 0,
        recevied_date: formattedDate,
    });
    const [availableStock, setAvailableStock] = useState(0); // New state for available stock

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["stat_inventory_details", "employee", "quantity", "recevied_date"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/wfm/stationary-employee-details/`,
                    formData,
                );

                if (res.status === 200) {
                    await getStockList();
                    await getEmployeeItemsList();

                    setShow(false)
                } else {
                    // console.log("res.data")
                    // console.log(res.data)
                    // console.log("res.status")
                    // console.log(res.status)
                    if (res.data) {
                        toast.dismiss(loadingToastId);
                        const warningData = res.data;

                        if (typeof warningData.message === 'string') {
                            // Single error message
                            toast.warning(`Warning: ${warningData.message}`);
                        } else if (typeof warningData === 'object' && warningData !== null) {
                            // Multiple error messages
                            Object.entries(warningData).forEach(([field, messages]) => {
                                messages.forEach(message => toast.warning(`"${field}": ${message}`));
                            });
                        } else {
                            toast.warning('Warning:- something went wrong !');
                        }
                    } else {
                        toast.warning('Warning couldnt process your request.');
                    }
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });


        if (name === "stat_inventory_details") {
            const selectedItem = stockItemList.find(item => item.id === value);
            if (selectedItem) {
                setAvailableStock(selectedItem.quantity - selectedItem.distributed_quantity);
            } else {
                setAvailableStock(0);
            }
        }
    };

    return (
        <>
            <button title="Issue Items to Employee" className="models-button model-add" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Issue Item to Employee</h6>

                            <form>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="employee" className="form-labels  font-weight500    font-size-subheading">
                                        Employee<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="employee"
                                        name="employee"
                                        value={formData.employee}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.employee ? "error" : inputState.employee ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Employee</option>
                                        {employeeList.map((i) => (<>
                                            <option value={i.emp_code}>{i.emp_code}-{i.name}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.employee && (
                                            <span className="error-message font-size-text ">{errors.employee}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="stat_inventory_details" className="form-labels  font-weight500    font-size-subheading">
                                        Item<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="stat_inventory_details"
                                        name="stat_inventory_details"
                                        value={formData.stat_inventory_details}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.stat_inventory_details ? "error" : inputState.stat_inventory_details ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Item</option>
                                        {stockItemList.map((i) => (<>
                                            {(i.quantity - i.distributed_quantity) > 0 ?
                                                <option value={i.id}>{i.item_name}  |  Stock: {formattedDateNoYear(i.purchase_date)},  items left: {i.quantity - i.distributed_quantity}</option>
                                                : null
                                            }
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.stat_inventory_details && (
                                            <span className="error-message font-size-text ">{errors.stat_inventory_details}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                                        Quantity<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="quantity"
                                        max={availableStock} // Set max limit
                                        name="quantity"
                                        onChange={handleInputChange}
                                        value={formData.quantity}
                                        className={`form-text-trasformation-uppercase form-input ${errors.quantity ? "error" : inputState.quantity ? "success" : ""
                                            }`}
                                    />
                                    {errors.quantity && <span className="error-message font-size-text ">{errors.quantity}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="recevied_date" className="form-labels  font-weight500    font-size-subheading">
                                        Issue Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="recevied_date"
                                        name="recevied_date"
                                        onChange={handleInputChange}
                                        value={formData.recevied_date}
                                        className={`form-input ${errors.recevied_date ? "error" : inputState.recevied_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.recevied_date && <span className="error-message font-size-text ">{errors.recevied_date}</span>}
                                </div>

                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateEmployeeStationary = ({ i, getEmployeeItemsList, stockItemList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i);
        setShow(true)
    };


    const [formData, setFormData] = useState({
        stat_inventory_details: "",
        employee: "",
        quantity: 0,
        recevied_date: "",
    });

    useEffect(() => {
        setFormData(i);
    }, [i]);

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["stat_inventory_details", "employee", "quantity", "recevied_date"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/wfm/stationary-employee-details/${i.id}/`,
                    formData,

                );

                if (res.status === 200) {
                    await getEmployeeItemsList();
                    setShow(false)
                } else {
                    if (res.data) {
                        toast.dismiss(loadingToastId);
                        const warningData = res.data;

                        if (typeof warningData.message === 'string') {
                            // Single error message
                            toast.warning(`Warning: ${warningData.message}`);
                        } else if (typeof warningData === 'object' && warningData !== null) {
                            // Multiple error messages
                            Object.entries(warningData).forEach(([field, messages]) => {
                                messages.forEach(message => toast.warning(`"${field}": ${message}`));
                            });
                        } else {
                            toast.warning('Warning:- something went wrong !');
                        }
                    } else {
                        toast.warning('Warning couldnt process your request.');
                    }
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    return (
        <>
            <button className="model-edit-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Update Issued Item for {i.employee} </h6>

                            <form>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="stat_inventory_details" className="form-labels  font-weight500    font-size-subheading">
                                        Item<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        disabled
                                        id="stat_inventory_details"
                                        name="stat_inventory_details"
                                        value={formData.stat_inventory_details.id}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.stat_inventory_details ? "error" : inputState.stat_inventory_details ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Item</option>
                                        {stockItemList.map((i) => (<>
                                            <option value={i.id}>{i.item_name} - (Stock : {formattedDateLong(i.purchase_date)})</option>
                                        </>))
                                        }
                                    </select>
                                    <div>
                                        {errors.stat_inventory_details && (
                                            <span className="error-message font-size-text ">{errors.stat_inventory_details}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                                        Quantity<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="quantity"
                                        name="quantity"
                                        onChange={handleInputChange}
                                        value={formData.quantity}
                                        className={`form-text-trasformation-uppercase form-input ${errors.quantity ? "error" : inputState.quantity ? "success" : ""
                                            }`}
                                    />
                                    {errors.quantity && <span className="error-message font-size-text ">{errors.quantity}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="recevied_date" className="form-labels  font-weight500    font-size-subheading">
                                        Issue Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="recevied_date"
                                        name="recevied_date"
                                        onChange={handleInputChange}
                                        value={formData.recevied_date}
                                        className={`form-input ${errors.recevied_date ? "error" : inputState.recevied_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.recevied_date && <span className="error-message font-size-text ">{errors.recevied_date}</span>}
                                </div>
                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteEmployeeStationary = ({ i, getEmployeeItemsList }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/wfm/stationary-employee-details/${i.id}/`
            );

            if (res.status === 200) {
                await getEmployeeItemsList();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete {i.employee}'s Issue data of {i.recevied_date}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete issue data of item : {i.stat_inventory_details.item_name}
                        <div className="button-models">
                            <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

export {
    AddStationaryItem,
    UpdateStationaryItem,
    DeleteStationaryItem,
    AddItemToStock,
    UpdateItemInStock,
    DeleteItemFromStock,
    GiveEmployeeStationary,
    UpdateEmployeeStationary,
    DeleteEmployeeStationary
};
