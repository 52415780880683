import React, { useState, useEffect } from "react";
import EmployeeData from '../../../components/dashboard-comp/EmployeeData';
import Dashboardnavbarcopy from '../../../layout/Dashboardnavbarcopy';
import NewEmployee from '../../../components/dashboard-comp/NewEmployee';
import JobInformation from '../../../components/dashboard-comp/JobInformation';
import TaskInformation from '../../../components/dashboard-comp/TaskInformation';
import Informationcomponent from '../../../components/dashboard-comp/Informationcomponent';
import AttendanceArc from '../../../components/dashboard-comp/AttendanceArc';

import CareerSummaryBars from '../../../components/dashboard-comp/CareerSummaryBars';


const dashboard = () => {


  return (
    <div className='dashboard-designs'>
      <Dashboardnavbarcopy url="Dashboard" name={"Welcome"} />
      <div className='hr-dashboard-designs-flex'>
        <div>
          <AttendanceArc />
        </div>
        <div>
          <CareerSummaryBars />
        </div>


      </div>
      <div className='dashboard-designs-secondary-flex'>
        <EmployeeData />
      </div>

      <div className="dashboard-designs-secondary-flex justify-between dashboard-designs-margin">
        <div className="dashboard-designs-left-flex">
          <Informationcomponent />
          <NewEmployee />
        </div>
        <div className="dashboard-designs-right-flex">
          <TaskInformation />
          <JobInformation />
        </div>
      </div>

    </div>
  )
}

export default dashboard
