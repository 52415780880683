import React, { useState } from 'react'
import { Resumelist, CandidateScreening, InterviewSchedule } from './AppsubandtrackTables';



const Appsubandtrack = () => {
    const [toggleState, setToggleState] = useState(1);

    return (
        <>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Resume List
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Candidate Screening
                        </button>
                        <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(3)}
                        >
                            Interview Schedule
                        </button>



                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? <Resumelist /> : null}

                    {toggleState === 2 ? <CandidateScreening /> : null}

                    {toggleState === 3 ? <InterviewSchedule /> : null}
                </div>


            </div>


        </>
    )
}

export default Appsubandtrack
