
const formatDate = (dateString,) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = new Date(dateString);
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: userTimeZone,
  };
  return date.toLocaleString('en-US', options);
};

const formatDateDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;
const day = today.getDate();
const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
var datearray = formattedDate.split("-");


const formattedDateLong = (date) =>
  new Date(date).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

const formattedDateNoYear = (date) =>
  new Date(date).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
  });

const formatDateTime = (datetimeStr) => {
  const dateObj = new Date(datetimeStr);

  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();

  const dateFormatted = `${day}/${month}/${year}`;
  const timeFormatted = dateObj.toLocaleTimeString();

  const defaultFormat = `${dateFormatted}(${timeFormatted})`;
  return {
    toString: () => defaultFormat,
    full: `${dateFormatted} ${timeFormatted}`,
    date: dateFormatted,
    time: timeFormatted
  };
};

export { formatDate, formatDateDDMMYYYY, formattedDate, datearray, formattedDateLong, formattedDateNoYear, formatDateTime }