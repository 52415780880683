import React, { useState, useEffect } from 'react'

import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import Attendance from './tlwfm/TlAttendance'
import EmployeeDetails from './tlwfm/EmployeeDetails'
import TeamRequestManagment from './tlwfm/Requestmanagement'
import TlOrgstructure from './tlwfm/TlOrgstructure';
import { RequestRequisitionTableRH } from '../RequisitionManagement/RequisitionManagementTables';
import { useLocation } from 'react-router-dom';

const TlWorkfm = () => {
  const location = useLocation();
  const firsttoggle = location.state !== null ? location.state?.firsttoggle : 1;
  const secondtoggle = location.state !== null ? location.state?.secondtoggle : 1;

  const [toggleState, setToggleState] = useState(firsttoggle);


  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'Organization Structure';
      case 2:
        return 'My Team Members';
      case 3:
        return 'My Team Attendance';
      case 4:
        return 'Team Request management';

      default:
        return 'Organization Structure';
    }
  };

  return (
    <div>
      <Dashboardnavbarcopy name={getSelectedTitle()} url="Organization & Team Details" />
      <div className="bloc-tabss  wfm-margin">
        <button
          className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(1)}
        >
          Organization Structure
        </button>
        <button
          className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(2)}
        >
          Team Members
        </button>
        <button
          className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(3)}
        >
          Attendance
        </button>
        {/* <button
          className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(4)}
        >
          Request Management
        </button> */}
        {/* <button
          className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(5)}
        >
          Requisition Management
        </button> */}

      </div>
      <div className="content-tabs">

        {toggleState === 1 ? <TlOrgstructure /> : null}

        {toggleState === 2 ? <EmployeeDetails /> : null}

        {toggleState === 3 ? <Attendance /> : null}

        {toggleState === 4 ? <TeamRequestManagment secondtoggle={secondtoggle} /> : null}

        {toggleState === 5 ? <RequestRequisitionTableRH /> : null}



      </div>
    </div>
  )
}

export default TlWorkfm
