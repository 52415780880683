import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";


// ! *************** Job Post Crud Models Start ***************


const Application = ({ designationname, departmentname, getJobpositionlist, getCompleteJobpostlist, status }) => {
  const [show, setShow] = useState(false);
  const [jobExists, setJobExists] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    // getCompleteJobpostlist();
    setShow(true)
  };


  const [formData, setFormData] = useState({
    department: "",
    position: "",
    experience: "",
    location: "",
    description: "",
    job_type: "",
    start_date: "",
    end_date: "",
    status: "",
    sub_company: sessionStorage.getItem('company_id')
  });


  useEffect(() => {
    const jobExistsForSpecificRow = Array.isArray(getCompleteJobpostlist) &&
      getCompleteJobpostlist.find(
        item =>
          item.department === formData.department &&
          item.position === formData.position
      );
    setJobExists(!!jobExistsForSpecificRow); // Set jobExists to true if jobExistsForSpecificRow is truthy
  }, [getCompleteJobpostlist, formData]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "department",
      "position",
      "experience",
      "location",
      "description",
      "job_type",
      "start_date",
      "end_date",
      "status",

    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {

      try {
        let res = await axios.post(`${BASE_URL}/jd/jobpost/`, formData,)

        if (res.status === 200) {
          setShow(false)
          await getJobpositionlist(status)
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };


  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading  font-weight500 font-size-heading">Birthday </h6> */}

              <div className="flex-column form-group-selectt">
                <label htmlFor="department" className="form-labels font-weight500 font-size-subheading">
                  Department<span className="required">*</span>
                </label>
                <br />
                <select
                  id="department"
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                    }`}
                >
                  <option value="">Select a Department</option>
                  {departmentname?.sort((a, b) => a.title?.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                    <option value={i.id}>{i.title}</option>
                  </>))
                  }
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.department && (
                    <span className="error-message font-size-text ">{errors.department}</span>
                  )}
                </div>
              </div>



              <div className="flex-column form-group-selectt">
                <label htmlFor="position" className="form-labels font-weight500 font-size-subheading">
                  Position<span className="required">*</span>
                </label>
                <br />
                <select
                  id="position"
                  name="position"
                  value={formData.position}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.position ? "error" : inputState.position ? "success" : ""
                    }`}
                >
                  <option value="">Select a Position</option>
                  {designationname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                    <option value={i.id}>{i.title}</option>
                  </>))
                  }
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.position && (
                    <span className="error-message font-size-text ">{errors.position}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="experience" className="form-labels font-weight500 font-size-subheading">
                  Experience<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="experience"
                  name="experience"
                  onChange={handleInputChange}
                  value={formData.experience}
                  className={`form-text-trasformation-uppercase form-input ${errors.experience ? "error" : inputState.experience ? "success" : ""
                    }`}
                />
                {errors.experience && <span className="error-message font-size-text ">{errors.experience}</span>}
              </div>


              <div className="flex-column">
                <label htmlFor="location" className="form-labels font-weight500 font-size-subheading">
                  Location<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  onChange={handleInputChange}
                  value={formData.location}
                  className={`form-input ${errors.location ? "error" : inputState.location ? "success" : ""
                    }`}
                />
                {errors.location && <span className="error-message font-size-text ">{errors.location}</span>}
              </div>


              <div className="flex-column">
                <label htmlFor="start_date" className="form-labels font-weight500 font-size-subheading">
                  Start Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  onChange={handleInputChange}
                  value={formData.start_date}
                  className={`form-input ${errors.start_date ? "error" : inputState.start_date ? "success" : ""
                    }`}
                />
                {errors.start_date && <span className="error-message font-size-text ">{errors.start_date}</span>}
              </div>


              <div className="flex-column">
                <label htmlFor="end_date" className="form-labels font-weight500 font-size-subheading">
                  End Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  onChange={handleInputChange}
                  value={formData.end_date}
                  className={`form-input ${errors.end_date ? "error" : inputState.end_date ? "success" : ""
                    }`}
                />
                {errors.end_date && <span className="error-message font-size-text ">{errors.end_date}</span>}
              </div>

              <div className="flex-column form-group-selectt">
                <label htmlFor="job_type" className="form-labels font-weight500 font-size-subheading">
                  Job Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="job_type"
                  name="job_type"
                  value={formData.job_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.job_type ? "error" : inputState.job_type ? "success" : ""
                    }`}
                >
                  <option value="">Select Option</option>
                  <option value="full-time">Full Time</option>
                  <option value="part-time">Part Time</option>
                  <option value="intern">Intern</option>





                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.job_type && (
                    <span className="error-message font-size-text ">{errors.job_type}</span>
                  )}
                </div>
              </div>


              <div className="flex-column form-group-selectt">
                <label htmlFor="status" className="form-labels font-weight500 font-size-subheading">
                  Status<span className="required">*</span>
                </label>
                <br />
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""
                    }`}
                >
                  <option value="">Select Option</option>
                  <option value="active">Active</option>
                  <option value="inactive">In Active</option>




                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.status && (
                    <span className="error-message font-size-text ">{errors.status}</span>
                  )}
                </div>
              </div>


              <div className="flex-column">
                <label htmlFor="description" className="form-labels font-weight500 font-size-subheading">
                  Description<span className="required">*</span>
                </label>
                <textarea
                  placeholder="Description"

                  type="text"
                  id="description"
                  name="description"
                  onChange={handleInputChange}
                  value={formData.description}
                  className={`form-input-textarea   font-weight400  font-size-subheading form-input-background ${errors.description ? "error" : inputState.description ? "success" : ""
                    }`}
                />
              </div>
              <div className="flex-column">
                {jobExists ?
                  <span className="required">Job post already exists for this department and designation.</span> :
                  ""}
              </div>

              <div className="button-models">
                <button className="model-button  font-weight500 " onClick={handleClose}>Cancel</button>
                {
                  jobExists ? "" :
                    <button
                      type="submit"
                      className="model-button  font-weight500 model-button-leave font-weight500 font-size-heading"
                      onClick={handleFormSubmit}
                      disabled={loading}
                    >
                      Add
                    </button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};


const Applicationupdate = ({ i, designationname, departmentname, getJobpositionlist, status }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [formData, setFormData] = useState({
    department: i.department,
    position: i.position,
    experience: i.experience,
    location: i.location,
    description: i.description,
    job_type: i.job_type,
    start_date: i.start_date,
    end_date: i.end_date,
    status: i.status,
  });


  // useEffect(() => {
  //   setFormData(i);
  // }, [i]);


  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "department",
      "position",
      "experience",
      "location",
      "description",
      "job_type",
      "start_date",
      "end_date",
      "status",

    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      try {
        let res = await axios.put(`${BASE_URL}/jd/jobpost/${i.id}/`, formData,)

        if (res.status === 200) {
          setShow(false)
          await getJobpositionlist(status)
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };


  return (
    <>
      <button className="model-edit-button" title="Edit Job Posting" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading  font-weight500 font-size-heading">Birthday </h6> */}

              <div className="flex-column form-group-selectt">
                <label htmlFor="department" className="form-labels font-weight500 font-size-subheading">
                  Department<span className="required">*</span>
                </label>
                <br />
                <select
                  id="department"
                  name="department"
                  value={formData.department}
                  disabled
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                    }`}
                >
                  <option value="">Select a Department</option>
                  {departmentname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                    <option value={i.id}>{i.title}</option>
                  </>))
                  }
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.department && (
                    <span className="error-message font-size-text ">{errors.department}</span>
                  )}
                </div>
              </div>

              <div className="flex-column form-group-selectt">
                <label htmlFor="position" className="form-labels font-weight500 font-size-subheading">
                  Position<span className="required">*</span>
                </label>
                <br />
                <select
                  id="position"
                  name="position"
                  disabled
                  value={formData.position}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.position ? "error" : inputState.position ? "success" : ""
                    }`}
                >
                  <option value="">Select a Position</option>
                  {designationname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                    <option value={i.id}>{i.title}</option>
                  </>))
                  }
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.position && (
                    <span className="error-message font-size-text ">{errors.position}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="experience" className="form-labels font-weight500 font-size-subheading">
                  Experience<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="experience"
                  name="experience"
                  onChange={handleInputChange}
                  value={formData.experience}
                  className={`form-text-trasformation-uppercase form-input ${errors.experience ? "error" : inputState.experience ? "success" : ""
                    }`}
                />
                {errors.experience && <span className="error-message font-size-text ">{errors.experience}</span>}
              </div>


              <div className="flex-column">
                <label htmlFor="location" className="form-labels font-weight500 font-size-subheading">
                  Location<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  onChange={handleInputChange}
                  value={formData.location}
                  className={`form-input ${errors.location ? "error" : inputState.location ? "success" : ""
                    }`}
                />
                {errors.location && <span className="error-message font-size-text ">{errors.location}</span>}
              </div>


              <div className="flex-column">
                <label htmlFor="start_date" className="form-labels font-weight500 font-size-subheading">
                  Start Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  onChange={handleInputChange}
                  value={formData.start_date}
                  className={`form-input ${errors.start_date ? "error" : inputState.start_date ? "success" : ""
                    }`}
                />
                {errors.start_date && <span className="error-message font-size-text ">{errors.start_date}</span>}
              </div>


              <div className="flex-column">
                <label htmlFor="end_date" className="form-labels font-weight500 font-size-subheading">
                  End Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  onChange={handleInputChange}
                  value={formData.end_date}
                  className={`form-input ${errors.end_date ? "error" : inputState.end_date ? "success" : ""
                    }`}
                />
                {errors.end_date && <span className="error-message font-size-text ">{errors.end_date}</span>}
              </div>

              <div className="flex-column form-group-selectt">
                <label htmlFor="job_type" className="form-labels font-weight500 font-size-subheading">
                  Job Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="job_type"
                  name="job_type"
                  value={formData.job_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.job_type ? "error" : inputState.job_type ? "success" : ""
                    }`}
                >
                  <option value="">Select Option</option>
                  <option value="full-time">Full Time</option>
                  <option value="part-time">Part Time</option>
                  <option value="intern">Intern</option>





                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.job_type && (
                    <span className="error-message font-size-text ">{errors.job_type}</span>
                  )}
                </div>
              </div>


              <div className="flex-column form-group-selectt">
                <label htmlFor="status" className="form-labels font-weight500 font-size-subheading">
                  Status<span className="required">*</span>
                </label>
                <br />
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""
                    }`}
                >
                  <option value="">Select Option</option>
                  <option value="active">Active</option>
                  <option value="inactive">In Active</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.status && (
                    <span className="error-message font-size-text ">{errors.status}</span>
                  )}
                </div>
              </div>


              <div className="flex-column">
                <label htmlFor="description" className="form-labels font-weight500 font-size-subheading">
                  Description<span className="required">*</span>
                </label>
                <textarea
                  placeholder="Description"

                  type="text"
                  id="description"
                  name="description"
                  onChange={handleInputChange}
                  value={formData.description}
                  className={`form-input-textarea   font-weight400  font-size-subheading form-input-background ${errors.description ? "error" : inputState.description ? "success" : ""
                    }`}
                />
              </div>

              <div className="button-models">
                <button className="model-button  font-weight500 " onClick={handleClose}>Cancel</button>
                <button
                  type="submit"
                  className="model-button  font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};


const ApplicationDelete = ({ i, getJobpositionlist }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/jd/jobpost/${i.id}/`);

      if (res.status === 200) {
        await getJobpositionlist("null");
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Job Posting" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title className="align-center">Delete Job Post </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div align-center">
            Are You Sure You Want to delete job post with
            <br />
            Department : {i.department_name}
            <br />
            Designation : {i.position_name}
            <br />
            <b>Note : This will also delete all job applicants for the same post including previously Accepted / Rejected or Pending Applicants</b>
            <div className="button-models">
              <button className="model-button  font-weight500 " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button  font-weight500 model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

// ? *************** Job Post Crud Models End ***************




export { Application, Applicationupdate, ApplicationDelete };

