import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../config/axios';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EmployeeDetailCard from '../EmployeeDetailCard';
import RequisitionAddmodel from '../../career/update_details/RequisitionAddmodel';
import { Active, CircularGrid, Search, SearchBig } from '../../AllSvg';

const EmployeeDetails = () => {


  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([])
  const [filteredEmployee, setFilteredEmployee] = useState([]);

  const getAllEmployee = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyRH/${sessionStorage.getItem("email")}/`);
      setAllemployee(res.data)
      // setFilteredEmployee(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };


  useEffect(() => {

    getAllEmployee();
  }, []);
  // *********** Employee Details Api End ***********


  // *********** Filter Logic ***********

  const [selectedStatus, setSelectedStatus] = useState("active");
  const [jobTitle, setJobTitle] = useState('');

  console.log(selectedStatus)
  const [designationname, setDesignationname] = useState([])

  const [departmentname, setDepartmentname] = useState([])
  const [selectedName, setName] = useState('');

  const getDesignation = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/designation/`);
      const ress = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(res.data)
      setDepartmentname(ress.data)

    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getDesignation();

  }, []);

  const applyFilters = () => {
    // Filter employees based on selectedStatus and jobTitle
    let filteredData = allemployee;

    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code.toString().includes(selectedName);
        return nameMatch || empCodeMatch;
      });
    }
    if (selectedStatus !== '') {
      filteredData = filteredData.filter(employee => employee.employee_status === selectedStatus);
    }
    if (selectedDep) {

      filteredData = filteredData.filter(employee => employee.department_name === selectedDep);
    }
    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter(employee => {
        const desiMatch = employee.designation_name.toLowerCase().includes(lowercaseSelectedName);
        return desiMatch;
      });
    }


    if (jobTitle !== '') {
      // Use includes for a case-insensitive search
      filteredData = filteredData.filter(employee => employee.designation_name.toLowerCase().includes(jobTitle.toLowerCase()));
    }
    setFilteredEmployee(filteredData);

    console.log(filteredData)
  };
  const [selectedDep, setDep] = useState('');
  const [selectedDesi, setDesi] = useState('');
  // console.log(filteredData)

  useEffect(() => {

    applyFilters();
  }, [selectedStatus, selectedName, jobTitle, allemployee, selectedDep, selectedDesi]);
  // }, [selectedStatus, jobTitle, allemployee]);


  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/tl/userdetailscrud/${emp_code}/${user_detail}/`)
  }


  return (
    <div>

      <div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Active />
              <select className="attendance-input-field width-5vw   date-field" type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>

                <option value="active">Active</option>
                <option value="inactive">Inactive</option>



              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select className="attendance-input-field width-15vw   date-field" type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              >
                <option value=""> All Department</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {departmentname.sort((a, b) => a.title - b.title).map((i, index) => (<option value={i.title}>{i.title}</option>))};

              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_650_3324)">
                  <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                  <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                  <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                  <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                  <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                  <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                  <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                  <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                  <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg><input className="attendance-input-field width-15vw  " placeholder='Designation' type="text" value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)} />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input className="attendance-input-field width-15vw  " placeholder='Name or Code' type="text" value={selectedName}
                onChange={(e) => setName(e.target.value)} />
            </div>

            {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg><input className="attendance-input-field width-5vw  " placeholder='Job Title' type="text" value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)} /> */}
          </div>
          <div className="btn-cont">
            {/* <button className='attendance-btn' onClick={getAllEmployee}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 11 12" fill="none">
              <circle cx="5" cy="5" r="4.3" stroke="#FFFFFF" stroke-width="1.4" />
              <line x1="10.0101" y1="11" x2="8" y2="8.98995" stroke="#FFFFFF" stroke-width="1.4" stroke-linecap="round" />
            </svg>Search</button> */}

            {/* <RequisitionAddmodel
              getAllEmployee={getAllEmployee}
              departmentname={departmentname}
              designationname={designationname}
            /> */}

          </div>
        </div>
      </div>
      <div className='filter-showing'>
        <div></div>
        <div>Showing  {filteredEmployee.length} of {allemployee.length} </div>
      </div>

      <EmployeeDetailCard filteredEmployee={filteredEmployee} Viewdetails={Viewdetails} />


    </div>
  )
}

export default EmployeeDetails
