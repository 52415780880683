import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { formatDate, formattedDate } from "../Date";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import { useLocation } from "react-router-dom";
import Expenses from "../UserDetails/Expenses";
import { RequestExpensetableAccStage1, RequestExpensetableAccStage2, RequestImpresttableAcc } from "../workforcemanagement/Requestoutdutytable";


// const ImprestExpenseRequestManagement = () => {
//     const location = useLocation();
//     const toggleexpense = location.state !== null ? location.state?.toggleexpense : 1;
//     const expensetogglemanagenet = true;
//     // const expensetogglemanagenet = toggleexpense;
//     const emp_code = sessionStorage.getItem("emp_code")
//     const email = sessionStorage.getItem("email")

//     const getSelectedTitle = () => {
//         switch (toggleexpense) {
//             case 1:
//                 return "My Imprest";
//             case 2:
//                 return "My Expense";
//             default:
//                 return "My Imprest";
//         }
//     };

//     return (
//         <>
//             <ToastContainer
//                 position="top-center"
//                 autoClose={1000}
//                 hideProgressBar={false}
//                 newestOnTop={true}
//                 closeOnClick
//                 rtl={false}
//                 pauseOnFocusLoss
//                 draggable
//                 pauseOnHover
//             />
//             <Dashboardnavbarcopy
//                 name={getSelectedTitle()}
//                 url="Imprest & Expense Management"
//             />

//             <div className="content-tabs">
//                 <Expenses email={email} emp_code={emp_code} toggleexpense={toggleexpense} expensetogglemanagenet={expensetogglemanagenet} />
//             </div>
//         </>
//     );
// };

const AccountsImprestExpenseRequestMangement = () => {
    const location = useLocation();
    const accfirsttoggle = location.state !== null ? location.state?.accfirsttoggle : 1;
    const [toggleState, setToggleState] = useState(accfirsttoggle);

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return "Imprest Requests";
            case 2:
                return "Expense Requests";
            case 3:
                return "Expense Settelment";
            default:
                return "Imprest Requests";
        }
    };

    return (
        <>
            <div>
                <Dashboardnavbarcopy name={getSelectedTitle()} url="Imprest & Expense Request Management" />
                <div className="bloc-tabs-with-white-bg content-tabs">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggleState(1)}
                    >
                        Imprest
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggleState(2)}
                    >
                        Expense Requests
                    </button>
                    <button
                        className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggleState(3)}
                    >
                        Expense Finalization
                    </button>
                </div>
            </div>
            <div className="content-tabs">
                {toggleState === 1 ? <RequestImpresttableAcc /> : null}
                {toggleState === 2 ? <RequestExpensetableAccStage1 /> : null}
                {toggleState === 3 ? <RequestExpensetableAccStage2 /> : null}
            </div>
        </>
    )
}

export {
    // ImprestExpenseRequestManagement, 
    AccountsImprestExpenseRequestMangement,
};
