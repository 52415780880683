import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../config/axios"
import axios from "axios";
import TimelineItem from "../../components/Timeline";
import { ToastContainer, toast } from "react-toastify";
import { Documentdetails, EducationdetailsAdd, PersonaldetailsAdd, WorkexperienceAdd } from "../../components/career/CareerComponent";
import GoodJob from '../../assets/gif/thumbsUp.gif'


const PersonaldetailsProfile = () => {
  let { email } = useParams();

  const navigate = useNavigate()

  let path_Personal_details = "personalDetailss"
  let path_Education_details = "educationDetailss"
  let path_Work_details = "workexperiencee"
  let path_Emergency_details = "emergencycontacts"
  let path_Account_details = "accountdetailss"
  let path_Document_details = "documentdetailss"

  const route_Personal_details = () => {
    navigate(`/${path_Personal_details}/${email}/`);
  }
  const route_Education_details = () => {
    navigate(`/${path_Education_details}/${email}/`);
  }
  const route_Work_details = () => {
    navigate(`/${path_Work_details}/${email}/`);
  }
  const route_Emergency_details = () => {
    navigate(`/${path_Emergency_details}/${email}/`);
  }
  const route_Account_details = () => {
    navigate(`/${path_Account_details}/${email}/`);
  }
  const route_Document_details = () => {
    navigate(`/${path_Document_details}/${email}/`);
  }

  return (
    <div className="jd">
      {/*  ************** timeline Seaction  ************** */}

      {/* <div className="jd-timeline">
        <TimelineItem heading="Personal Information" color="#2576BC" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Education Detail" color="#707070" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Work Experience" color="#707070" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Emergency Contact" color="#707070" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Account Details" color="#707070" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Documents Upload" color="#707070" />
      </div> */}

      <div className="jd-timeline">
        <button onClick={route_Personal_details}>
          <TimelineItem heading="Personal Information" color="#2576BC" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Education_details}>
          <TimelineItem heading="Education Detail" color="#707070" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Work_details}> 
          <TimelineItem heading="Work Experience" color="#707070" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Emergency_details}>
          <TimelineItem heading="Emergency Contact" color="#707070" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Account_details}>
          <TimelineItem heading="Account Details" color="#707070" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Document_details}>
          <TimelineItem heading="Documents Upload" color="#707070" />
        </button>
      </div>

      <PersonaldetailsAdd email={email} forwardroutepath="educationDetailss" />
    </div>
  );
};



const PersonaldetailsEducationdetails = () => {
  let { email } = useParams();

  const navigate = useNavigate()

  let path_Personal_details = "personalDetailss"
  let path_Education_details = "educationDetailss"
  let path_Work_details = "workexperiencee"
  let path_Emergency_details = "emergencycontacts"
  let path_Account_details = "accountdetailss"
  let path_Document_details = "documentdetailss"

  const route_Personal_details = () => {
    navigate(`/${path_Personal_details}/${email}/`);
  }
  const route_Education_details = () => {
    navigate(`/${path_Education_details}/${email}/`);
  }
  const route_Work_details = () => {
    navigate(`/${path_Work_details}/${email}/`);
  }
  const route_Emergency_details = () => {
    navigate(`/${path_Emergency_details}/${email}/`);
  }
  const route_Account_details = () => {
    navigate(`/${path_Account_details}/${email}/`);
  }
  const route_Document_details = () => {
    navigate(`/${path_Document_details}/${email}/`);
  }

  return (
    <div className="jd">
      {/* <div className="jd-timeline">
        <TimelineItem heading="Personal Information" color="#2576BC" />
        <div className="jd-timeline-borderr timeline-color"></div>
        <TimelineItem heading="Education Detail" color="#2576BC" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Work Experience" color="#707070" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Emergency Contact" color="#707070" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Account Details" color="#707070" />
        <div className="jd-timeline-borderr width-5vw"></div>
        <TimelineItem heading="Documents Upload" color="#707070" />
      </div> */}

      <div className="jd-timeline">
        <button onClick={route_Personal_details}>
          <TimelineItem heading="Personal Information" color="#2576BC" />
        </button>
        <div className="jd-timeline-borderr timeline-color"></div>
        <button onClick={route_Education_details}>
          <TimelineItem heading="Education Detail" color="#2576BC" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Work_details}>
          <TimelineItem heading="Work Experience" color="#707070" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Emergency_details}>
          <TimelineItem heading="Emergency Contact" color="#707070" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Account_details}>
          <TimelineItem heading="Account Details" color="#707070" />
        </button>
        <div className="jd-timeline-borderr width-5vw"></div>
        <button onClick={route_Document_details}>
          <TimelineItem heading="Documents Upload" color="#707070" />
        </button>
      </div>

      <EducationdetailsAdd email={email} forwardroutepath="workexperiencee" backroutepath="personalDetailss" />
    </div>
  );
};



const Personaldetailsworkexprience = () => {
  let { email } = useParams()

  const navigate = useNavigate()

  let path_Personal_details = "personalDetailss"
  let path_Education_details = "educationDetailss"
  let path_Work_details = "workexperiencee"
  let path_Emergency_details = "emergencycontacts"
  let path_Account_details = "accountdetailss"
  let path_Document_details = "documentdetailss"

  const route_Personal_details = () => {
    navigate(`/${path_Personal_details}/${email}/`);
  }
  const route_Education_details = () => {
    navigate(`/${path_Education_details}/${email}/`);
  }
  const route_Work_details = () => {
    navigate(`/${path_Work_details}/${email}/`);
  }
  const route_Emergency_details = () => {
    navigate(`/${path_Emergency_details}/${email}/`);
  }
  const route_Account_details = () => {
    navigate(`/${path_Account_details}/${email}/`);
  }
  const route_Document_details = () => {
    navigate(`/${path_Document_details}/${email}/`);
  }

  return (

    <>

      <div className="jd">
        {/*  ************** timeline Seaction  ************** */}

        {/* <div className="jd-timeline">
          <TimelineItem heading="Personal Information" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Education Detail" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Work Experience" color="#2576BC" />
          <div className="jd-timeline-borderr width-5vw"></div>
          <TimelineItem heading="Emergency Contact" color="#707070" />
          <div className="jd-timeline-borderr width-5vw"></div>
          <TimelineItem heading="Account Details" color="#707070" />
          <div className="jd-timeline-borderr width-5vw"></div>
          <TimelineItem heading="Documents Upload" color="#707070" />
        </div> */}

        <div className="jd-timeline">
          <button onClick={route_Personal_details}>
            <TimelineItem heading="Personal Information" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Education_details}>
            <TimelineItem heading="Education Detail" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Work_details}>
            <TimelineItem heading="Work Experience" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr width-5vw"></div>
          <button onClick={route_Emergency_details}>
            <TimelineItem heading="Emergency Contact" color="#707070" />
          </button>
          <div className="jd-timeline-borderr width-5vw"></div>
          <button onClick={route_Account_details}>
            <TimelineItem heading="Account Details" color="#707070" />
          </button>
          <div className="jd-timeline-borderr width-5vw"></div>
          <button onClick={route_Document_details}>
            <TimelineItem heading="Documents Upload" color="#707070" />
          </button>
        </div>

        <WorkexperienceAdd email={email} forwardroutepath="emergencycontacts" backroutepath="educationDetailss" />
      </div>

    </>
  );
};


const PersonaldetailsEmergencycontactdetails = () => {
  let { email } = useParams()
  const [inputFields, setInputFields] = useState([
    { person_name: "", phone_number: "", relation: "", user: email },
  ]);
  const [errors, setErrors] = useState({});

  // const [empCode, setEmpCode] = useState()
  // const getCompanyDetails = async () => {
  //   try {
  //     const res = await axios.get(`${BASE_URL}/wfm/ourcompanyuserdetail/`);
  //     const emp = res.data.find(emp => emp.user_detail === email);
  //     setEmpCode(emp.emp_code)
  //   } catch (err) {
  //     // alert(err.message);
  //   }
  // };
  // console.log("empCode");
  // console.log(empCode);
  // useEffect(() => {
  //   getCompanyDetails();
  // }, [empCode]);



  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    setInputFields(updatedFields);
  };

  const handleAddInputField = () => {
    setInputFields([
      ...inputFields,
      { person_name: "", phone_number: "", relation: "", user: email },
    ]);
  };

  const handleRemoveInputField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  const validateForm = () => {
    const newErrors = {};
    inputFields.forEach((field, index) => {
      const requiredFields = [
        "person_name",
        "phone_number",
        "relation"

      ];
      requiredFields.forEach((reqField) => {
        if (!field[reqField]) {
          newErrors[`${reqField}_${index}`] = `${reqField.charAt(0).toUpperCase() + reqField.slice(1)
            } is required!`;
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(inputFields);


  const navigate = useNavigate()
  let path_Personal_details = "personalDetailss"
  let path_Education_details = "educationDetailss"
  let path_Work_details = "workexperiencee"
  let path_Emergency_details = "emergencycontacts"
  let path_Account_details = "accountdetailss"
  let path_Document_details = "documentdetailss"

  const route_Personal_details = () => {
    navigate(`/${path_Personal_details}/${email}/`);
  }
  const route_Education_details = () => {
    navigate(`/${path_Education_details}/${email}/`);
  }
  const route_Work_details = () => {
    navigate(`/${path_Work_details}/${email}/`);
  }
  const route_Emergency_details = () => {
    navigate(`/${path_Emergency_details}/${email}/`);
  }
  const route_Account_details = () => {
    navigate(`/${path_Account_details}/${email}/`);
  }
  const route_Document_details = () => {
    navigate(`/${path_Document_details}/${email}/`);
  }

  const backroute = () => {
    navigate(`/workexperiencee/${email}/`)
  }
  const forwardroute = () => {
    navigate(`/accountdetailss/${email}/`)
  }

  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(`${BASE_URL}/userdata/userEmergencyContactall/`, inputFields,)

        if (res.status === 200) {
          // navigate(`/accountdetailss/${email}/`)
          forwardroute()
          // navigate(`/accountdetailss/${email}/`, { state: { empCode } })
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        await Promise.all(
          inputFields.map(async (educationDetail) => {
            if (educationDetail.id) {
              await axios.put(`${BASE_URL}/userdata/userEmergencyContact/${educationDetail.id}/`, educationDetail);
            } else {
              await axios.post(`${BASE_URL}/userdata/userEmergencyContact/`, educationDetail);
            }
          })
        );

        // navigate(`/accountdetailss/${email}/`)
        forwardroute()
        // navigate(`/accountdetailss/${email}/`, { state: { empCode } })
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };


  const [buffer, setBuffering] = useState(true); //buffering logic

  const [data, setData] = useState(false)




  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {
        const res = await axios.get(`${BASE_URL}/userdata/userEmergencyContactallget/${email}/`);
        if (res.data.length > 0) {
          setInputFields(res.data);
          setData(true)
        }
      } catch (err) {
        // alert(err.message);

        // toast Logic
        // if (err.response) {
        //     const errorData = err.response.data;

        //     if (typeof errorData.error === 'string') {
        //       // Single error message
        //       toast.error(`Error: ${errorData.error}`);
        //     } else if (typeof errorData === 'object' && errorData !== null) {
        //       // Multiple error messages
        //       Object.entries(errorData).forEach(([field, messages]) => {
        //         messages.forEach(message => toast.error(`"${field}": ${message}`));
        //       });
        //     } else {
        //       toast.error('Error:- Failed to Process!');
        //     }
        //   } else {
        //     toast.error('Error processing your request.');
        //   }
      } finally {
        setBuffering(false);// End Buffering
      }
    };

    getUSerDetails();
  }, []);



  return (
    // <div>

    //   <button type="button" onClick={handleAddInputField}>
    //     Add More Fields
    //   </button>

    // </div>
    <>
      {buffer ? <div className="spinner-z"></div> : null}{/* //buffering logic */}
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="jd">
        {/*  ************** timeline Seaction  ************** */}

        {/* <div className="jd-timeline">
          <TimelineItem heading="Personal Information" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Education Detail" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Work Experience" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Emergency Contact" color="#2576BC" />
          <div className="jd-timeline-borderr width-5vw"></div>
          <TimelineItem heading="Account Details" color="#707070" />
          <div className="jd-timeline-borderr width-5vw"></div>
          <TimelineItem heading="Documents Upload" color="#707070" />
        </div> */}

        <div className="jd-timeline">
          <button onClick={route_Personal_details}>
            <TimelineItem heading="Personal Information" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Education_details}>
            <TimelineItem heading="Education Detail" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Work_details}>
            <TimelineItem heading="Work Experience" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Emergency_details}>
            <TimelineItem heading="Emergency Contact" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr width-5vw"></div>
          <button onClick={route_Account_details}>
            <TimelineItem heading="Account Details" color="#707070" />
          </button>
          <div className="jd-timeline-borderr width-5vw"></div>
          <button onClick={route_Document_details}>
            <TimelineItem heading="Documents Upload" color="#707070" />
          </button>
        </div>

        <div className="jd-heading-outer">
          <div className="jd-heading-outer-flex">
            <h2 className="jd-heading-main font-weight600  font-size-heading">Emergency Contact Details *</h2>

            <h2 className="jd-heading-main font-weight600 -right font-size-text ">
              Please click here to add more{" "}
              <button
                type="button"
                onClick={handleAddInputField}
                className="jd-heading-main font-weight600 -right-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_450_2151)">
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                      fill="#2576BC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_450_2151">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </h2>
          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <form className="register-form" >
          {inputFields.map((field, index) => (
            <div key={index} className="form-flex-wrap">
              <div className="flex-column">
                <label htmlFor={`person_name_${index}`} className="form-labels  font-weight500    font-size-subheading">
                  Preson Name {index + 1}<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id={`person_name_${index}`}
                  name="person_name"
                  value={field.person_name}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`person_name_${index}`]
                    ? "error"
                    : field.person_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`person_name_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`person_name_${index}`]}
                  </span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor={`phone_number_${index}`} className="form-labels  font-weight500    font-size-subheading">
                  Phone number<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id={`phone_number_${index}`}
                  name="phone_number"
                  value={field.phone_number}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`phone_number_${index}`]
                    ? "error"
                    : field.phone_number
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`phone_number_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`phone_number_${index}`]}
                  </span>
                )}
              </div>


              <div className="flex-column">
                <label htmlFor={`relation_${index}`} className="form-labels  font-weight500    font-size-subheading">
                  Relation<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id={`relation_${index}`}
                  name="relation"
                  value={field.relation}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`relation_${index}`]
                    ? "error"
                    : field.relation
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`relation_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`relation_${index}`]}
                  </span>
                )}
              </div>

              <div className="flex-column"></div>

              <div>
                {index > 0 ? (
                  <button
                    type="button"
                    onClick={() => handleRemoveInputField(index)}
                    className="remove-button   font-weight500    font-size-heading"
                  >
                    Remove
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
          <div className="button-models">
            <button className="model-button   font-weight500   " onClick={backroute}>Back</button>
            {data ? (<button onClick={handleFormPut} className="model-button   font-weight500    model-button-submit">
              Next
            </button>) : (<button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit">
              Next
            </button>)}
          </div>
        </form>
      </div>
    </>
  );
};



const PersonaldetailsAccountdetails = () => {

  let { email } = useParams()
  // const location = useLocation();
  // const { empCode } = location.state;

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [empCode, setEmpCode] = useState()
  const getCompanyDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemail/${email}/`);
      // const emp = res.data.find(emp => emp.user_detail === email);
      // const emp = res.data.find(emp => emp.user_detail === email);
      setEmpCode(res.data.emp_code)
    } catch (err) {
      // alert(err.message);
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  console.log("empCode");
  console.log(empCode);
  // useEffect(() => {
  //   getCompanyDetails();
  // }, [empCode]);

  const [formData, setFormData] = useState({

    // pan_name: "",
    // father_name_pan: "",
    // pan_dob: "",
    // pan_no: "",
    // bank_name: "",
    // name_require: "",
    // account_number: "",
    // ifsc_code: "",
    // branch_address: "",

    account_number: "",
    ifsc_code: "",
    bank_name: "",
    branch_address: "",
    user: empCode
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      // "pan_name",
      // "father_name_pan",
      // "pan_dob",
      // "pan_no",
      "bank_name",
      // "name_require",
      "account_number",
      "ifsc_code",
      "branch_address"


    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //


  const navigate = useNavigate()

  let path_Personal_details = "personalDetailss"
  let path_Education_details = "educationDetailss"
  let path_Work_details = "workexperiencee"
  let path_Emergency_details = "emergencycontacts"
  let path_Account_details = "accountdetailss"
  let path_Document_details = "documentdetailss"

  const route_Personal_details = () => {
    navigate(`/${path_Personal_details}/${email}/`);
  }
  const route_Education_details = () => {
    navigate(`/${path_Education_details}/${email}/`);
  }
  const route_Work_details = () => {
    navigate(`/${path_Work_details}/${email}/`);
  }
  const route_Emergency_details = () => {
    navigate(`/${path_Emergency_details}/${email}/`);
  }
  const route_Account_details = () => {
    navigate(`/${path_Account_details}/${email}/`);
  }
  const route_Document_details = () => {
    navigate(`/${path_Document_details}/${email}/`);
  }

  const backroute = () => {
    navigate(`/emergencycontacts/${email}/`)
  }

  const forwardroute = () => {
    navigate(`/documentdetailss/${email}/`)
  }


  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      // Cookies.set("userData", JSON.stringify(formData));
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const updatedFormData = {
        ...formData,
        user: empCode
        // employee: formData.email,
      };


      try {
        let res = await axios.post(`${BASE_URL}/wfm/userBankDetails/`, updatedFormData)

        if (res.status === 200) {
          // navigate(`/documentdetailss/${email}/`)
          forwardroute()
          // window.location.reload();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/userBankDetails/${empCode}/`, formData,)

        if (res.status === 200) {
          // navigate(`/documentdetailss/${email}/`)
          forwardroute()
          // window.location.reload();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };







  const [data, setData] = useState(false)
  // const [companyDetails, setCompanyDetails] = useState([]);
  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {
        const res = await axios.get(`${BASE_URL}/wfm/userBankDetailsgetall/${empCode}/`);
        setFormData(res.data);
        setData(true)
      } catch (err) {
        //toast Logic
        // if (err.response) {
        //   const errorData = err.response.data;

        //   if (typeof errorData.error === 'string') {
        //     // Single error message
        //     toast.error(`Error: ${errorData.error}`);
        //   } else if (typeof errorData === 'object' && errorData !== null) {
        //     // Multiple error messages
        //     Object.entries(errorData).forEach(([field, messages]) => {
        //       messages.forEach(message => toast.error(`"${field}": ${message}`));
        //     });
        //   } else {
        //     toast.error('Error:- Failed to Process!');
        //   }
        // } else {
        //   toast.error('Error processing your request.');
        // } 
        // alert(err.message);
      } finally {
        setBuffering(false);// End Buffering
      }
    };

    getCompanyDetails();
    getUSerDetails();
  }, [empCode]);


  return (
    <>
      {buffer ? <div className="spinner-z"></div> : null}{/* //buffering logic */}
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="jd">
        {/*  ************** timeline Seaction  ************** */}

        {/* <div className="jd-timeline">
          <TimelineItem heading="Personal Information" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Education Detail" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Work Experience" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Emergency Contact" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Account Details" color="#2576BC" />
          <div className="jd-timeline-borderr width-5vw"></div>
          <TimelineItem heading="Documents Upload" color="#707070" />
        </div> */}


        <div className="jd-timeline">
          <button onClick={route_Personal_details}>
            <TimelineItem heading="Personal Information" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Education_details}>
            <TimelineItem heading="Education Detail" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Work_details}>
            <TimelineItem heading="Work Experience" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Emergency_details}>
            <TimelineItem heading="Emergency Contact" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Account_details}>
            <TimelineItem heading="Account Details" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr width-5vw"></div>
          <button onClick={route_Document_details}>
            <TimelineItem heading="Documents Upload" color="#707070" />
          </button>
        </div>

        <div className="jd-heading-outer">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Account Details*</h2>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <form className="register-form">
          <div className="form-flex-wrap">


            {/* <div className="flex-column">
            <label htmlFor="pan-name" className="form-labels  font-weight500    font-size-subheading">
              Name<span className="required">*</span><span className="label-sub-heading   font-weight400  font-size-label">( As per Pan Card )</span>
            </label>
            <input
              type="text"
              id="pan-name"
              maxLength={30}
              name="pan_name"
              placeholder="Your name"
              onChange={handleInputChange}
              value={formData.pan_name}
              className={`form-input ${errors.pan_name
                ? "error"
                : inputState.pan_name
                  ? "success"
                  : ""
                }`}
            />
            {errors.pan_name && (
              <span className="error-message font-size-text ">{errors.pan_name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="pan_dob" className="form-labels  font-weight500    font-size-subheading">
              Date of Birth<span className="required">*</span><span className="label-sub-heading   font-weight400  font-size-label">( As per Pan Card )</span>
            </label>
            <input
              type="date"
              id="pan_dob"
              name="pan_dob"
              onChange={handleInputChange}
              value={formData.pan_dob}
              className={`form-input ${errors.pan_dob ? "error" : inputState.pan_dob ? "success" : ""
                }`}
            />
            {errors.pan_dob && <span className="error-message font-size-text ">{errors.pan_dob}</span>}
          </div>


          <div className="flex-column">
            <label htmlFor="father_name_pan" className="form-labels  font-weight500    font-size-subheading">
              Father Name<span className="required">*</span><span className="label-sub-heading   font-weight400  font-size-label">( As per Pan Card )</span>
            </label>
            <input
              type="text"
              id="father_name_pan"
              name="father_name_pan"
              maxLength={30}
              placeholder="Father' name"
              onChange={handleInputChange}
              value={formData.father_name_pan}
              className={`form-input ${errors.father_name_pan
                ? "error"
                : inputState.father_name_pan
                  ? "success"
                  : ""
                }`}
            />
            {errors.father_name_pan && (
              <span className="error-message font-size-text ">{errors.father_name_pan}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="pan_no" className="form-labels  font-weight500    font-size-subheading">
              PAN No.<span className="required">*</span>
            </label>
            <input
              type="text"
              id="pan_no"
              name="pan_no"
              maxLength={10}
              placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.pan_no}
              className={`form-input ${errors.pan_no
                ? "error"
                : inputState.pan_no
                  ? "success"
                  : ""
                }`}
            />
            {errors.pan_no && (
              <span className="error-message font-size-text ">{errors.pan_no}</span>
            )}
          </div> */}

            <div className="flex-column">
              <label htmlFor="bank_name" className="form-labels  font-weight500    font-size-subheading">
                Bank Name<span className="required">*</span>
              </label>
              <input
                type="write"
                id="bank_name"
                name="bank_name"
                maxLength={60}
                placeholder="Bank name"
                onChange={handleInputChange}
                value={formData.bank_name}
                className={`form-input ${errors.bank_name ? "error" : inputState.bank_name ? "success" : ""
                  }`}
              />
              {errors.bank_name && (
                <span className="error-message font-size-text ">{errors.bank_name}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="branch_address" className="form-labels  font-weight500    font-size-subheading">
                Branch address<span className="required">*</span>
              </label>
              <input
                type="write"
                id="branch_address"
                name="branch_address"
                maxLength={100}
                placeholder="Bank Address"
                onChange={handleInputChange}
                value={formData.branch_address}
                className={`form-input ${errors.branch_address ? "error" : inputState.branch_address ? "success" : ""
                  }`}
              />
              {errors.branch_address && (
                <span className="error-message font-size-text ">{errors.branch_address}</span>
              )}
            </div>

            {/* <div className="flex-column">
            <label htmlFor="name_require" className="form-labels  font-weight500    font-size-subheading">
              Account Holder Name <span className="required">*</span>
            </label>
            <input
              type="text"
              id="name_require"
              name="name_require"
              maxLength={30}
              placeholder="Your name"
              onChange={handleInputChange}
              value={formData.name_require}
              className={`form-input ${errors.name_require ? "error" : inputState.name_require ? "success" : ""
                }`}
            />
            {errors.name_require && (
              <span className="error-message font-size-text ">{errors.name_require}</span>
            )}
          </div> */}

            <div className="flex-column">
              <label htmlFor="account_number" className="form-labels  font-weight500    font-size-subheading">
                Account Number<span className="required">*</span>
              </label>
              <input
                type="number"
                id="account_number"
                name="account_number"
                // maxLength={30}
                placeholder="1234 1234 1234"
                onChange={handleInputChange}
                value={formData.account_number}
                className={`form-input ${errors.account_number ? "error" : inputState.account_number ? "success" : ""
                  }`}
              />
              {errors.account_number && (
                <span className="error-message font-size-text ">{errors.account_number}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="ifsc_code" className="form-labels  font-weight500    font-size-subheading">
                IFSC Code<span className="required">*</span>
              </label>
              <input
                type="text"
                id="ifsc_code"
                name="ifsc_code"
                placeholder="AAAAA1234A"
                maxLength={15}
                onChange={handleInputChange}
                value={formData.ifsc_code}
                className={`form-input ${errors.ifsc_code ? "error" : inputState.ifsc_code ? "success" : ""
                  }`}
              />
              {errors.ifsc_code && (
                <span className="error-message font-size-text ">{errors.ifsc_code}</span>
              )}
            </div>
          </div>


          <div className="button-models">
            <button className="model-button   font-weight500   " onClick={backroute}>Back</button>
            {data ? (<button onClick={handleFormPut} className="model-button   font-weight500    model-button-submit" disabled={loading}>
              Update
            </button>) : (<button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading}>
              Next
            </button>)}
          </div>
        </form>
      </div>
    </>
  );
};

const PersonaldetailsDocumentdetails = () => {

  let { id, email } = useParams()

  const navigate = useNavigate()

  let path_Personal_details = "personalDetailss"
  let path_Education_details = "educationDetailss"
  let path_Work_details = "workexperiencee"
  let path_Emergency_details = "emergencycontacts"
  let path_Account_details = "accountdetailss"
  let path_Document_details = "documentdetailss"

  const route_Personal_details = () => {
    navigate(`/${path_Personal_details}/${email}/`);
  }
  const route_Education_details = () => {
    navigate(`/${path_Education_details}/${email}/`);
  }
  const route_Work_details = () => {
    navigate(`/${path_Work_details}/${email}/`);
  }
  const route_Emergency_details = () => {
    navigate(`/${path_Emergency_details}/${email}/`);
  }
  const route_Account_details = () => {
    navigate(`/${path_Account_details}/${email}/`);
  }
  const route_Document_details = () => {
    navigate(`/${path_Document_details}/${email}/`);
  }
  return (
    <>
      <div className="jd">
        {/*  ************** timeline Seaction  ************** */}


        {/* <div className="jd-timeline">
          <TimelineItem heading="Personal Information" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Education Detail" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Work Experience" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Emergency Contact" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Account Details" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Documents Upload" color="#2576BC" />
        </div> */}

        <div className="jd-timeline">
          <button onClick={route_Personal_details}>
            <TimelineItem heading="Personal Information" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Education_details}>
            <TimelineItem heading="Education Detail" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Work_details}>
            <TimelineItem heading="Work Experience" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Emergency_details}>
            <TimelineItem heading="Emergency Contact" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Account_details}>
            <TimelineItem heading="Account Details" color="#2576BC" />
          </button>
          <div className="jd-timeline-borderr timeline-color"></div>
          <button onClick={route_Document_details}>
            <TimelineItem heading="Documents Upload" color="#2576BC" />
          </button>
        </div>

        <Documentdetails email={email} backroutepath="accountdetailss" />



      </div>

    </>
  );
};


const CompletePage = () => {


  return (
    <>
      <center>  <br /><br /><br /><br /><br /><br /><br /><br /><h3>
        <br /><b>Process Completed, </b> <br />You can close the tab 👍<br /><br />
        <img style={{ borderRadius: "20px", boxShadow: "0px 1px 38px 0px rgba(0, 0, 0, 0.10)" }} src={GoodJob} alt="" />
        {/* <img style={{ borderRadius: "20px", boxShadow: "0px 1px 38px 0px rgba(0, 0, 0, 0.10)" }} src={Gif} alt="" /> */}
      </h3></center>

    </>
  );
};


export { PersonaldetailsProfile, PersonaldetailsEducationdetails, Personaldetailsworkexprience, PersonaldetailsEmergencycontactdetails, PersonaldetailsAccountdetails, PersonaldetailsDocumentdetails, CompletePage }
