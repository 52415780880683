import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../config/axios';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EmployeeDetailCard from './EmployeeDetailCard';
import Employeeaddmodel from '../career/update_details/Employeeaddmodel';
import { customSortByKey } from '../CustomFunctions';
import { Active, CircularGrid, Office, Search, SearchBig } from '../AllSvg';

// const EmployeeDetails = () => {

//   // *********** Department Api Start ***********

//   const [departmentname, setDepartmentname] = useState([])
//   useEffect(() => {
//     const getDepartment = async () => {
//       try {
//         const res = await axios.get(`${BASE_URL}/wfm/department/`);
//         setDepartmentname(res.data)
//       } catch (err) {
//         // alert(err.message);
//       }
//     };
//     getDepartment();
//   }, []);

//   // *********** Department Api End ***********


//   // *********** Employee Details Api Start ***********
//   const [allemployee, setAllemployee] = useState([])
//   const [filteredEmployee, setFilteredEmployee] = useState([]);

//   useEffect(() => {
//     const getAllEmployee = async () => {
//       try {
//         const res = await axios.get(`${BASE_URL}/wfm/department/`);
//         setAllemployee(res.data)
//         setFilteredEmployee(res.data)
//       } catch (err) {
//         // alert(err.message);
//       }
//     };

//     getAllEmployee();
//   }, []);
//   // *********** Employee Details Api End ***********


//   // *********** Filter Logic ***********

//   const [selectedDepartment, setSelectedDepartment] = useState('');
//   const [selectedStatus, setSelectedStatus] = useState('');
//   const [jobTitle, setJobTitle] = useState('');


//   const handleSearch = () => {
//     // Filter employees based on selected criteria
//     let filteredData = allemployee.filter((employee) => {
//       const departmentMatch =
//         selectedDepartment === '' || employee.department === selectedDepartment;
//       const statusMatch =
//         selectedStatus === '' || employee.status === selectedStatus;
//       const jobTitleMatch =
//         jobTitle === '' || employee.jobTitle.toLowerCase().includes(jobTitle.toLowerCase());

//       return departmentMatch && statusMatch && jobTitleMatch;
//     });

//     setFilteredEmployee(filteredData);
//   };


//   return (
//     <div>

//       <div>
//         <div className="attendance-subcont">
//           <div className="field-cont">
//             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 17 17" fill="none">
//               <g clip-path="url(#clip0_650_3315)">
//                 <path d="M13.4583 2.83333H12.6792C12.5148 2.03392 12.0798 1.31562 11.4476 0.799509C10.8153 0.283397 10.0245 0.00103029 9.20833 0L7.79167 0C6.97552 0.00103029 6.18468 0.283397 5.55245 0.799509C4.92021 1.31562 4.48523 2.03392 4.32083 2.83333H3.54167C2.6027 2.83446 1.70252 3.20796 1.03857 3.87191C0.374625 4.53585 0.00112473 5.43604 0 6.375L0 13.4583C0.00112473 14.3973 0.374625 15.2975 1.03857 15.9614C1.70252 16.6254 2.6027 16.9989 3.54167 17H13.4583C14.3973 16.9989 15.2975 16.6254 15.9614 15.9614C16.6254 15.2975 16.9989 14.3973 17 13.4583V6.375C16.9989 5.43604 16.6254 4.53585 15.9614 3.87191C15.2975 3.20796 14.3973 2.83446 13.4583 2.83333ZM7.79167 1.41667H9.20833C9.64627 1.41848 10.0729 1.55556 10.43 1.80915C10.787 2.06273 11.057 2.42044 11.203 2.83333H5.797C5.94298 2.42044 6.21296 2.06273 6.57 1.80915C6.92705 1.55556 7.35373 1.41848 7.79167 1.41667ZM3.54167 4.25H13.4583C14.0219 4.25 14.5624 4.47388 14.9609 4.8724C15.3595 5.27091 15.5833 5.81141 15.5833 6.375V8.5H1.41667V6.375C1.41667 5.81141 1.64055 5.27091 2.03906 4.8724C2.43758 4.47388 2.97808 4.25 3.54167 4.25ZM13.4583 15.5833H3.54167C2.97808 15.5833 2.43758 15.3595 2.03906 14.9609C1.64055 14.5624 1.41667 14.0219 1.41667 13.4583V9.91667H7.79167V10.625C7.79167 10.8129 7.86629 10.993 7.99913 11.1259C8.13197 11.2587 8.31214 11.3333 8.5 11.3333C8.68786 11.3333 8.86803 11.2587 9.00087 11.1259C9.13371 10.993 9.20833 10.8129 9.20833 10.625V9.91667H15.5833V13.4583C15.5833 14.0219 15.3595 14.5624 14.9609 14.9609C14.5624 15.3595 14.0219 15.5833 13.4583 15.5833Z" fill="#707070" />
//               </g>
//               <defs>
//                 <clipPath id="clip0_650_3315">
//                   <rect width="17" height="17" fill="white" />
//                 </clipPath>
//               </defs>
//             </svg>
//             <select className="attendance-input-field width-5vw   date-field" placeholder='Date' type="text"
//               value={selectedDepartment}
//               onChange={(e) => setSelectedDepartment(e.target.value)}>
//               <option value="">All Department</option>
//               {
//                 departmentname.map((e, index) => (
//                   <option value={e.title}>{e.title}</option>
//                 ))
//               }


//             </select><hr />
//             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 17 17" fill="none">
//               <g clip-path="url(#clip0_650_3351)">
//                 <mask id="mask0_650_3351" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
//                   <path d="M17 0H0V17H17V0Z" fill="white" />
//                 </mask>
//                 <g mask="url(#mask0_650_3351)">
//                   <path d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z" fill="#707070" />
//                   <path d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z" fill="#707070" />
//                 </g>
//               </g>
//               <defs>
//                 <clipPath id="clip0_650_3351">
//                   <rect width="17" height="17" fill="white" />
//                 </clipPath>
//               </defs>
//             </svg>
//             <select className="attendance-input-field width-5vw   date-field" type="text"
//               value={selectedStatus}
//               onChange={(e) => setSelectedStatus(e.target.value)}
//             >
//               <option value="">All</option>

//               <option value="active">Active</option>
//               <option value="inactive">Inactive</option>



//             </select>
//             <hr />
//             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
//               <g clip-path="url(#clip0_650_3324)">
//                 <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
//                 <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
//                 <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
//                 <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
//                 <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
//                 <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
//                 <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
//                 <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
//                 <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
//               </g>
//               <defs>
//                 <clipPath id="clip0_650_3324">
//                   <rect width="14" height="14" fill="white" />
//                 </clipPath>
//               </defs>
//             </svg><input className="attendance-input-field width-5vw  " placeholder='Job Title' type="text" value={jobTitle}
//               onChange={(e) => setJobTitle(e.target.value)} />
//           </div>
//           <div className="btn-cont">
//             <button className='attendance-btn' onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 11 12" fill="none">
//               <circle cx="5" cy="5" r="4.3" stroke="#FFFFFF" stroke-width="1.4" />
//               <line x1="10.0101" y1="11" x2="8" y2="8.98995" stroke="#FFFFFF" stroke-width="1.4" stroke-linecap="round" />
//             </svg>Search</button>
//           </div>
//         </div>
//       </div>
//       <div className='filter-showing'>
//         <div></div>
//         <div>Showing  {filteredEmployee.length} of {allemployee.length} </div>
//       </div>

//       <div className='employee-cards'>


//         <div className='employee-card'>
//           <div className='employee-card-a'>
//             <div className='employee-card-image'>
//               <div className='employee-card-image-background'>

//               </div>
//               <div className='employee-card-icons'>
//                 <div className='employee-card-name font-weight500   font-size-subheading">Name</div>
//                 <div className='employee-card-designation font-weight400   font-size-subheading'>IT Expert</div>


//               </div>

//             </div> 
//             <div className='employee-edit '>
//               <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" className='employee-edit-svg'>
//                 <path d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
//                 <path d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
//                 <path d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />

//               </svg>
//             </div>
//           </div>
//           <div className='employee-card-b'>
//             <div className='employee-card-b-flex'>
//               <div><span className='employee-card-b-heading  font-weight400  font-size-label'>Department  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>Admin</span></div>
//               <div><span className='employee-card-b-heading  font-weight400  font-size-label'>Email  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>Admin@gmail.com</span></div>

//             </div>
//             <div className='employee-card-b-flex'>
//               <div><span className='employee-card-b-heading  font-weight400  font-size-label'>Location  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>Admin</span></div>
//               <div><span className='employee-card-b-heading  font-weight400  font-size-label'>Mobile No :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>9882641949</span></div>
//             </div>

//           </div>


//         </div>




//       </div>

//     </div>
//   )
// }

const EmployeeDetails = () => {

  const [buffer, setBuffering] = useState(false); //buffering logic

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([])
  const [filteredEmployee, setFilteredEmployee] = useState([]);

  const getAllEmployee = async () => {
    setBuffering(true); //buffering logic
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/ourcompanyuserdetail/`);
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyuserlessdetail/`);
      const sortedList = customSortByKey(res.data, "emp_code");

      const sortedData = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedData)
      // setAllemployee(res.data)
      // setFilteredEmployee(res.data)
    } catch (err) {
      // alert(err.message);
    } finally {
      setBuffering(false); //buffering logic
    }
  };


  useEffect(() => {

    getAllEmployee();
  }, []);
  // *********** Employee Details Api End ***********

  const refreshdata = async () => {
    setBuffering(true); //buffering logic
    await getAllEmployee();
    setBuffering(false); //buffering logic
  };


  // *********** Filter Logic ***********

  const [selectedStatus, setSelectedStatus] = useState("active");
  const [jobTitle, setJobTitle] = useState('');

  console.log(selectedStatus)

  const [designationname, setDesignationname] = useState([])

  const [selectedName, setName] = useState('');


  const getDesignation = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/designation/`);
      const ress = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${sessionStorage.getItem('company_id')}/active/`);

      setDesignationname(res.data)
      setDepartmentname(ress.data)

    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getDesignation();

  }, []);
  const applyFilters = () => {
    // Filter employees based on selectedStatus and jobTitle
    let filteredData = allemployee;
    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code.toString().includes(selectedName);
        return nameMatch || empCodeMatch;
      });
    }

    if (selectedStatus !== '') {
      filteredData = filteredData.filter(employee => employee.employee_status === selectedStatus);
    }

    if (selectedDep) {

      filteredData = filteredData.filter(employee => employee.department_name === selectedDep);
    }
    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter(employee => {
        const desiMatch = employee.designation_name.toLowerCase().includes(lowercaseSelectedName);
        return desiMatch;
      });
    }
    if (selectedRH) {

      filteredData = filteredData.filter(employee => employee.rh_name === selectedRH);
    }
    if (selectedDivision) {

      filteredData = filteredData.filter(employee => employee.division_name === selectedDivision);
    }

    if (jobTitle !== '') {
      // Use includes for a case-insensitive search
      filteredData = filteredData.filter(employee => employee.designation_name.toLowerCase().includes(jobTitle.toLowerCase()));
    }
    setFilteredEmployee(filteredData);

    console.log(filteredData)
  };
  const [selectedDep, setDep] = useState('');
  const [selectedDesi, setDesi] = useState('');
  const [selectedRH, setRh] = useState('');
  const [selectedDivision, setDivision] = useState(`${sessionStorage.getItem('company')}`);
  const [selectedCompany, setCompany] = useState(`${sessionStorage.getItem('company_id')}`);

  useEffect(() => {

    applyFilters();
  }, [selectedStatus, selectedName, jobTitle, allemployee, selectedDep, selectedDesi, selectedRH, selectedDivision]);

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");



  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${urlParts[3]}/userdetailscrud/${emp_code}/${user_detail}/`)
  }


  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/department/`);
      setDepartmentname(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };
  const [worksite, setWorksite] = useState([]);

  const getWoksiteList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/worksite/`);
      setWorksite(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };


  const [rhname, setRhname] = useState([]);

  const getRhlist = async () => {
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/rhlist/`);
      const res = await axios.get(`${BASE_URL}/wfm/rhlistactive/null/active/`);
      setRhname(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [attendpolicy, setAttendpolicy] = useState([]);

  const getAttendpolicy = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/attendancepolicy/`);
      setAttendpolicy(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [weeklypolicy, setWeeklypolicy] = useState([]);

  const getWeeklypolicy = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/weeklyoffPolicy/`);
      setWeeklypolicy(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getRhlist();
    getDesignation();
    getDepartment();
    getSubCompany();
    getAttendpolicy();
    getWeeklypolicy();
    getWoksiteList();
  }, []);



  return (
    <div>

      <div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <Active />
              <select className="attendance-input-field  date-field" type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input className="attendance-input-field width-10vw  " placeholder='Name or Code' type="text" value={selectedName}
                onChange={(e) => setName(e.target.value)} />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select className="attendance-input-field width-10vw   date-field" type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              >
                <option value=""> All Department</option>
                {departmentname.sort((a, b) => a.title - b.title).map((i, index) => (<option value={i.title}>{i.title}</option>))};




              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input className="attendance-input-field width-10vw  " placeholder='Designation' type="text" value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)} />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select className="attendance-input-field width-10vw   date-field" type="text"
                value={selectedRH}
                onChange={(e) => setRh(e.target.value)}
              >
                <option value=""> All Reporting Heads</option>
                {rhname.sort((a, b) => a.name - b.name).map((i, index) => (<option value={i.name}>{i.name}</option>))};
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />
              <select className="attendance-input-field width-10vw   date-field" type="text"
                value={selectedDivision}
                onChange={(e) => { setDivision(e.target.value) }}
              >
                <option value=""> All Sub Companies</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (<option value={i.title}>{i.title}</option>))};

              </select>
            </div>

          </div>
          <div className="btn-cont">
            {/* <button className='attendance-btn' onClick={getAllEmployee}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 11 12" fill="none">
              <circle cx="5" cy="5" r="4.3" stroke="#FFFFFF" stroke-width="1.4" />
              <line x1="10.0101" y1="11" x2="8" y2="8.98995" stroke="#FFFFFF" stroke-width="1.4" stroke-linecap="round" />
            </svg>Search</button> */}
            <Employeeaddmodel
              getAllEmployee={getAllEmployee}
              departmentname={departmentname}
              designationname={designationname}
              rhname={rhname}
              SubCompany={SubCompany}
              attendpolicy={attendpolicy}
              weeklypolicy={weeklypolicy}
              worksite={worksite} />
          </div>
        </div>
      </div>
      <div className='filter-showing'>
        <div>Showing  {filteredEmployee.length} of {allemployee.length} </div>
        <div>
          {buffer ?
            <div className="spinner-vsmall"></div>
            :
            <div
              className="refresh-button"
              onClick={refreshdata}
              disabled={buffer} //buffering logic
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="refresh-icon"
                fill="#fff"
                viewBox="0 0 24 24"
                id="refresh"
              >
                <path d="M21 21a1 1 0 0 1-1-1V16H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5A1 1 0 0 1 21 21zM8 10H3A1 1 0 0 1 2 9V4A1 1 0 0 1 4 4V8H8a1 1 0 0 1 0 2z"></path>
                <path d="M12 22a10 10 0 0 1-9.94-8.89 1 1 0 0 1 2-.22 8 8 0 0 0 15.5 1.78 1 1 0 1 1 1.88.67A10 10 0 0 1 12 22zM20.94 12a1 1 0 0 1-1-.89A8 8 0 0 0 4.46 9.33a1 1 0 1 1-1.88-.67 10 10 0 0 1 19.37 2.22 1 1 0 0 1-.88 1.1z"></path>
              </svg>
            </div>
          }
        </div>
      </div>

      {
        buffer ? <div className="spinner"></div> :
          <EmployeeDetailCard
            filteredEmployee={filteredEmployee}
            Viewdetails={Viewdetails}
            departmentname={departmentname}
            designationname={designationname}
            rhname={rhname}
            SubCompany={SubCompany}
            attendpolicy={attendpolicy}
            weeklypolicy={weeklypolicy}
            worksite={worksite} />}

    </div>
  )
}

export default EmployeeDetails
