import React, { useEffect, useState } from "react";
import { LineChart, ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { handleAllError } from "../CustomFunctions";




const CareerSummaryBars = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
    let previousYear = currentYear;
    let previousMonth = today.getMonth();
    if (previousMonth === 0) {
        previousYear--;
        previousMonth = 12;
    }
    const [selectedOption, setSelectedOption] = useState('this month');


    const [jobAppChart, setJobAppChart] = useState({});


    const getJobappdata = async (selectedOption) => {
        try {
            // const res = await axios.get(`${BASE_URL}/jd/pendingstats/`);
            const res = await axios.get(
                selectedOption === "this month"
                    ?
                    `${BASE_URL}/jd/pendingstatsbysubcompany/${sessionStorage.getItem('company_id')}/${currentMonth}/`
                    :
                    `${BASE_URL}/jd/pendingstatsbysubcompany/${sessionStorage.getItem('company_id')}/${previousMonth}/`
            );
            setJobAppChart(res.data);
        } catch (err) {
            handleAllError(err)
        }
    };

    useEffect(() => {
        getJobappdata(selectedOption);
    }, [selectedOption]);

    // const handleChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };
    const data = [
        // { name: '', Pending: 0, Approved: 0, Rejected: 0 },
        {
            name: 'Resume Shortlisting',
            Pending: jobAppChart.resume_list_pending_count,
            Approved: jobAppChart.resume_list_approved_count,
            Rejected: jobAppChart.resume_list_rejected_count,
            Total: jobAppChart.resume_list_total_count
        },
        {
            name: 'Interview Schedule',
            Pending: jobAppChart.candidate_screening_pending_count,
            Approved: jobAppChart.candidate_screening_approved_count,
            Rejected: jobAppChart.candidate_screening_rejected_count,
            Total: jobAppChart.candidate_screening_total_count
        },
        // { name: 'Interviews', Pending: jobAppChart.round4_pending_count, Approved: jobAppChart.round4_true_count, Rejected: getRandomNumber(10, 90), Total: jobAppChart.round4_pending_count + jobAppChart.round4_true_count },
        {
            name: 'Onboarding',
            Pending: jobAppChart.upcoming_onboarding_status_pending,
            Approved: jobAppChart.upcoming_onboarding_status_approved,
            Rejected: jobAppChart.upcoming_onboarding_status_rejected,
            Total: jobAppChart.upcoming_onboarding_status_total
        },
        // { name: '', Pending: 0, Approved: 0, Rejected: 0 },

    ];

    return (
        <div className="balance-cont width-40vw">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="bgwhite">
                <div>
                    <div className="invoice-heading card-heading">Career Stats</div>
                </div>
                <div className="radialchart-dropdown">
                    <select style={{ border: "none" }} onChange={(e) => { setSelectedOption(e.target.value) }} value={selectedOption}>
                        <option value="this month">This Month</option>
                        <option value="last month">Last Month</option>
                    </select>
                </div>
                {/* <div className="dashboard-priorities-select" >
                    <select
                        value={statsduration}
                        onChange={(e) => setStatsDuration(e.target.value)}
                        className="dashboard-sectiona-selection"
                    >
                        <option value="2024">2024</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                    </select>
                </div> */}
            </div>

            <ResponsiveContainer height={240} width="100%">
                <ComposedChart
                    // width={150}
                    // height={250}
                    data={data}
                // margin={{
                //     top: 5,
                //     right: 30,
                //     left: 20,
                //     bottom: 5,
                // }}
                >
                    <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
                    <XAxis dataKey="name" tickMargin={10} />
                    <YAxis />
                    <Tooltip />
                    {/* <Label /> */}
                    {/* <Line legendType="dot" type="monotone" dataKey="Total" stroke="pink" strokeWidth={5} Dot={10} /> */}
                    <Legend verticalAlign='top' height="5vh" margin={{ left: 50 }} />
                    <Bar legendType="dot" type="monotone" dataKey="Approved" fill="#2576BC" barSize={20} radius={7} />
                    <Bar legendType="dot" type="monotone" dataKey="Pending" fill="#F7BA1E" barSize={20} radius={7} />
                    <Bar legendType="dot" type="monotone" dataKey="Rejected" fill="#FF747C" barSize={20} radius={7} />

                    {/* <Bar dataKey="Total" barSize={20} fill="#CACACA" /> */}
                    {/* <Line legendType="dot" type="monotone" dataKey="Approved" stroke="#2576BC" strokeWidth={5} />
                    <Line legendType="dot" type="monotone" dataKey="Rejected" stroke="#FF747C" strokeWidth={5} /> */}

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}

export default CareerSummaryBars