import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { sidebarData } from './Admin_sidebar';
import FeatureMenu from '../../layout/DashboardMenu';

const AdminMenu = (props) => {
    // const { sidebarData, isexpand, setExpanded } = props;
    const location = useLocation();
    const currentPath = location.pathname;


    return <FeatureMenu features={sidebarData} currentPath={currentPath} />;
};

export default AdminMenu;
