import React, { useState } from 'react'
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import Announcetable from './Announcetable';
import Importantmessagetable from './Importantmessagetable';
import Starperformancetables from './Starperformancetables';
import Eventtables from './Eventtables';
import Assettable from '../UserDetails/Assettable';


const Settings = () => {

    const [toggleState, setToggleState] = useState(1);


    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return 'Announcement';
            case 2:
                return 'Important message';
            case 3:
                return 'Star Performance';
            case 4:
                return 'Event';
            case 5:
                return 'Asset';
            case 6:
            default:
                return '';
        }
    };


    return (
        <>
            <div>
                <Dashboardnavbarcopy name={getSelectedTitle()} url="Settings" />
                <div className="bloc-tabss  wfm-margin">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
                        onClick={() => setToggleState(1)}
                    >
                        Announcement
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
                        onClick={() => setToggleState(2)}
                    >
                        Important message
                    </button>
                    <button
                        className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
                        onClick={() => setToggleState(3)}
                    >
                        Star Performance
                    </button>
                    <button
                        className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
                        onClick={() => setToggleState(4)}
                    >
                        Event
                    </button>
                    <button
                        className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
                        onClick={() => setToggleState(5)}
                    >
                        Asset
                    </button>



                </div>
                <div className="content-tabs">

                    {toggleState === 1 ? <Announcetable /> : null}

                    {toggleState === 2 ? <Importantmessagetable /> : null}

                    {toggleState === 3 ? <Starperformancetables /> : null}

                    {toggleState === 4 ? <Eventtables /> : null}

                    {toggleState === 5 ? <Assettable /> : null}

                </div>
            </div>

        </>
    )
}

export default Settings
