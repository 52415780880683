import { useState, useEffect } from "react";
import Docs from "../../assets/svg/docs.svg";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import { formattedDateLong } from "../Date";



const DocDeleteButton = ({ i, getDocuments }) => {


  const handleDocSubmitDelete = async (e) => {
    // e.preventDefault();
    console.log(i)

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/userdata/userdocs/${i.id}/`);

      if (res.status === 200) {
        // getDocuments();

        await getDocuments();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button style={{ marginLeft: '10px' }} onClick={(e) => handleDocSubmitDelete()} className="model-delete-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
    </>
  )
}

const EmployeeDocumentAdd = ({ getDocuments, email }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const [formData, setFormData] = useState({
    user: email,
    document_name: "",
    document_type: "",
    document_file: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["document_name", "document_type", "document_file"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      const formDataa = new FormData();
      formDataa.append("user", formData.user);

      formDataa.append("document_name", formData.document_name);
      formDataa.append("document_type", formData.document_type);
      formDataa.append("document_file", formData.document_file);
      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          handleClose();
          await getDocuments();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      if (file.type === "application/pdf") {
        setFormData({
          ...formData,
          [name]: file,
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="task-Tab-heading   font-weight500    font-size-heading">Document</h6>

              <form>
                <div className="flex-column">
                  <label htmlFor="document_name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Document Name
                  </label>
                  <input
                    id="document_name"
                    type="document_name"
                    name="document_name"
                    placeholder="Document Name"
                    onChange={handleInputChange}
                    value={formData.document_name}
                    className={`form-input ${errors.document_name ? "error" : inputState.document_name ? "success" : ""
                      }`}
                  />
                  {errors.document_name && (
                    <span className="error-message font-size-text ">{errors.document_name}</span>
                  )}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="document_type" className="form-labels  font-weight500    font-size-subheading">
                    Document Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="document_type"
                    name="document_type"
                    value={formData.document_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.document_type ? "error" : inputState.document_type ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Document Type</option>
                    <option value="identity">Identity</option>
                    <option value="employeeLetter">Employee Letter</option>
                    <option value="previousExperience">Previous Experience</option>
                    <option value="degree">Degree</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.document_type && (
                      <span className="error-message font-size-text ">{errors.document_type}</span>
                    )}
                  </div>
                </div>



                <div className="flex-column">
                  <label htmlFor="attachment" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Attachment
                  </label>
                  <input
                    id="file"
                    type="file"
                    name="document_file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="file-input"
                  />

                  {formData.document_file ? (
                    <span
                      className="file-clear"
                      onClick={() => handleFileClear("file")}
                    >
                      Clear
                    </span>
                  ) : null}
                </div>
              </form>
              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Adds
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};


const DocumentAdd = ({ getDocuments, email }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const [formData, setFormData] = useState({
    user: email,
    document_name: "",
    document_type: "",
    document_file: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["document_name", "document_type", "document_file"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("user", formData.user);
      formDataa.append("document_name", formData.document_name);
      formDataa.append("document_type", formData.document_type);
      formDataa.append("document_file", formData.document_file);
      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          handleClose();
          await getDocuments();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      if (file.type === "application/pdf") {
        setFormData({
          ...formData,
          [name]: file,
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="task-Tab-heading   font-weight500    font-size-heading">Document</h6>

              <form>
                <div className="flex-column">
                  <label htmlFor="document_name" className="form-labels  font-weight500   font-size-heading">
                    Document Name
                  </label>
                  <input
                    id="document_name"
                    type="document_name"
                    name="document_name"
                    placeholder="Document Name"
                    onChange={handleInputChange}
                    value={formData.document_name}
                    className={`form-input ${errors.document_name ? "error" : inputState.document_name ? "success" : ""
                      }`}
                  />
                  {errors.document_name && (
                    <span className="error-message font-size-text ">{errors.document_name}</span>
                  )}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="document_type" className="form-labels  font-weight500    font-size-subheading">
                    Document Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="document_type"
                    name="document_type"
                    value={formData.document_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.document_type ? "error" : inputState.document_type ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Document Type</option>
                    <option value="identity">Identity</option>
                    {/* {sessionStorage.getItem('role') === 'HR' ? <option value="employeeLetter">Employee Letter</option> : ""} */}
                    <option value="previousExperience">Previous Experience</option>
                    <option value="degree">Degree</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.document_type && (
                      <span className="error-message font-size-text ">{errors.document_type}</span>
                    )}
                  </div>
                </div>



                <div className="flex-column">
                  <label htmlFor="attachment" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Attachment
                  </label>
                  <input
                    id="file"
                    type="file"
                    name="document_file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="file-input"
                  />

                  {formData.document_file ? (
                    <span
                      className="file-clear"
                      onClick={() => handleFileClear("file")}
                    >
                      Clear
                    </span>
                  ) : null}
                </div>
              </form>
              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Adds
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Document = ({ email, emp_code }) => {
  const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook

  const navigate = useNavigate();

  const [identityDocuments, setidentityDocuments] = useState([])
  const [buffer, setBuffering] = useState(true); //buffering logic


  const getDocuments = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/userdata/userdocsfilter/${email}/`);
      setidentityDocuments(res.data)
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };


  const [letterData, setLetterData] = useState({})
  const getLetterData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/incrementbyemployee/${emp_code ? emp_code : sessionStorage.getItem("emp_code")}`);

      // const sortedData = res.data.sort((a, b) => new Date(b.wefdate) - new Date(a.wefdate));
      // setLetterData(sortedData);
      setLetterData(res.data)
    } catch (err) {
    }
  };

  console.log("letterData")
  console.log(letterData)

  useEffect(() => {
    getDocuments();
    getLetterData();
  }, []);

  const [toggleState, setToggleState] = useState(1);

  const downloadDocument = (url) => {
    // Implement download logic using provided URL
    // window.open(url, "_blank");

  };


  const filteredIdentityDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "identity"
  );

  const filteredEmployeeDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "employeeLetter"
  );

  const filteredPreExpDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "previousExperience"
  );

  const filteredDegreeDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "degree"
  );


  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');


  const url1 = `letterhead`
  // const url2 = `letterheadPR`
  // const url3 = `letterheadPRP`

  console.log(url1)

  const handleLetterClick = () => {
    navigate(`letterhead/`)
    window.location.reload()
  };

  const ViewIncrementLetter = (emp_code, lettertype, index) => {
    console.log("Data shoee")
    console.log(emp_code)
    console.log(lettertype)
    console.log(index)
    navigate(`letterhead`, { state: { emp_code, lettertype, index } })
  }

  return (
    <div>
      <div className="expense-bloc-tabs">
        <div className="left-expense-bloc-tab">
          <button
            className={
              toggleState === 1
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
            }
            onClick={() => setToggleState(1)}
          >
            Identity ({filteredIdentityDocuments.length})
          </button>
          <button
            className={
              toggleState === 2
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
            }
            onClick={() => setToggleState(2)}
          >
            Employee Letters ({letterData.length > 0 ? filteredEmployeeDocuments.length + letterData.length : filteredEmployeeDocuments.length})
          </button>
          <button
            className={
              toggleState === 3
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
            }
            onClick={() => setToggleState(3)}
          >
            Previous Experience ({filteredPreExpDocuments.length})
          </button>
          <button
            className={
              toggleState === 4
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
            }
            onClick={() => setToggleState(4)}
          >
            Degree & Certificates ({filteredDegreeDocuments.length})
          </button>
        </div>
        <div className="right-expense-bloc-tab">

        </div>


        {
          sessionStorage.getItem("company") === "Space Creattors" ||
            sessionStorage.getItem("company") ===
            "Space Creattors Heights" ?
            (null) :
            (PERMISSION_ROLE ?
              <EmployeeDocumentAdd email={email} getDocuments={getDocuments} />
              :
              <DocumentAdd email={email} getDocuments={getDocuments} />
            )
        }
      </div>





      <div >
        <>
          {toggleState === 1 ? (
            <div className="document-content-tab">
              {filteredIdentityDocuments.length === 0 ? (
                <div className="no-documents-message">
                  No documents available.
                </div>
              ) : (
                <div className="document-grid2">
                  {filteredIdentityDocuments.map((doc, index) => (
                    <div className="document-item" key={index}>
                      <div className="document-icons">
                        <div className="document-name-div">
                          <img
                            src={Docs}
                            alt="Document Icon"
                            className="document-icon"
                          />
                          <div>
                            <div className="document-name">{doc.document_name}</div>
                            <div className="document-upload-date font-size-subheading  ">
                              Doc Type <b>{doc.document_type}</b>
                              {/* Uploaded on {doc.upload} */}
                            </div>
                          </div>
                        </div>
                        <div>
                          <a href={doc.document_file} target="_blank" className="document-download-button  width-5vw  width-5vw" rel="noreferrer">
                            View
                          </a>

                          {PERMISSION_ROLE ? <DocDeleteButton i={doc} getDocuments={getDocuments} /> : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : null}



          {toggleState === 2 ? (
            <div className="document-content-tab">
              {filteredEmployeeDocuments.length === 0 && letterData.length === 0 ? (
                <div className="no-documents-message">
                  No documents available.
                </div>
              ) : (
                <div className="document-grid2">
                  {letterData.length > 0 ? (
                    <>
                      {/* <div className="document-content-tab"> Increment Letter
                      <button
                        className="document-download-button  width-5vw  width-5vw"
                        onClick={() => navigate(url1)}
                      >
                        View
                      </button>
                    </div> */}

                      {letterData.map((doc, index) => (

                        <div className="document-item">
                          <div className="document-icons">
                            <div className="document-name-div">
                              <img
                                src={Docs}
                                alt="Document Icon"
                                className="document-icon"
                              />
                              <div>
                                <div className="document-name">Increment Letter</div>
                                <div className="document-upload-date font-size-subheading  ">
                                  {/* Doc Type <b>Employee Letter</b> */}
                                  WEF : {formattedDateLong(doc.wefdate)}
                                </div>
                              </div>
                            </div>
                            <button
                              className="document-download-button  width-5vw  width-5vw"
                              // onClick={() => navigate(url1)}
                              onClick={() => ViewIncrementLetter(emp_code, doc.letter_type, index)}
                            >
                              View
                            </button>


                          </div>
                        </div>
                      ))}

                    </>
                  ) : ""}
                  {filteredEmployeeDocuments.map((doc, index) => (
                    <div className="document-item" key={index}>
                      <div className="document-icons">
                        <div className="document-name-div">
                          <img
                            src={Docs}
                            alt="Document Icon"
                            className="document-icon"
                          />
                          <div>
                            <div className="document-name">{doc.document_name}</div>
                            <div className="document-upload-date font-size-subheading  ">
                              Doc Type <b>{doc.document_type}</b>
                              {/* Uploaded on {doc.upload} */}
                            </div>
                          </div>
                        </div>
                        <div>
                          <a href={doc.document_file} target="_blank" className="document-download-button  width-5vw  width-5vw" rel="noreferrer">
                            View
                          </a>
                          {PERMISSION_ROLE ? <DocDeleteButton i={doc} getDocuments={getDocuments} /> : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : null}
          {toggleState === 3 ? (
            <div className="document-content-tab">
              {filteredPreExpDocuments.length === 0 ? (
                <div className="no-documents-message">
                  No documents available.
                </div>
              ) : (
                <div className="document-grid2">
                  {filteredPreExpDocuments.map((doc, index) => (
                    <div className="document-item" key={index}>
                      <div className="document-icons">
                        <div className="document-name-div">
                          <img
                            src={Docs}
                            alt="Document Icon"
                            className="document-icon"
                          />
                          <div>
                            <div className="document-name">{doc.document_name}</div>
                            <div className="document-upload-date font-size-subheading  ">
                              Doc Type <b>{doc.document_type}</b>
                              {/* Uploaded on {doc.upload} */}
                            </div>
                          </div>
                        </div>
                        <div>
                          <a href={doc.document_file} target="_blank" className="document-download-button  width-5vw  width-5vw" rel="noreferrer">
                            View
                          </a>

                          {PERMISSION_ROLE ? <DocDeleteButton i={doc} getDocuments={getDocuments} /> : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : null}
          {toggleState === 4 ? (
            <div className="document-content-tab">
              {filteredDegreeDocuments.length === 0 ? (
                <div className="no-documents-message">
                  No documents available.
                </div>
              ) : (
                <div className="document-grid2">
                  {filteredDegreeDocuments.map((doc, index) => (
                    <div className="document-item" key={index}>
                      <div className="document-icons">
                        <div className="document-name-div">
                          <img
                            src={Docs}
                            alt="Document Icon"
                            className="document-icon"
                          />
                          <div>
                            <div className="document-name">{doc.document_name}</div>
                            <div className="document-upload-date font-size-subheading  ">
                              Doc Type <b>{doc.document_type}</b>
                              {/* Uploaded on {doc.upload} */}
                            </div>
                          </div>
                        </div>
                        <div>
                          <a href={doc.document_file} target="_blank" className="document-download-butto" rel="noreferrer">
                            View
                          </a>
                          {PERMISSION_ROLE ? <DocDeleteButton i={doc} getDocuments={getDocuments} /> : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : null}
        </>

      </div>
    </div>
  );
};

export default Document;