import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, IMAGE_URL } from "../../config/axios";
import { formatDate, formattedDate, formattedDateLong } from "../Date";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import Calaneder from "../dashboard-comp/Calaneder";
import AttendanceChart from "../UserDetails/userDetails-comp/AttendanceChart";
import LeaveChart from "../UserDetails/userDetails-comp/LeaveChart";
import OutDutyChart from "../UserDetails/userDetails-comp/OdChart";
import { customSortByKey, handleErrorToast } from "../CustomFunctions";
import Attendances from "../UserDetails/Attendances";
import Leave from "../UserDetails/Leave";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import TeamRequestManagment from "../workforcemanagement/tlwfm/Requestmanagement";
import { useLocation } from "react-router-dom";
import { RequestClockInTable, RequestCompensatorytable, RequestExpensetable, RequestImpresttable, RequestLeavetable, RequestMisspunchtable, Requestoutdutytable, TeamLeaveLogTable } from "../workforcemanagement/tlwfm/TlRequestoutdutytable";
import { RequestRequisitionTableAdmin, RequestRequisitionTableRH } from "../RequisitionManagement/RequisitionManagementTables";
import { LogCompensatorytable, RequestClockInTableHR, RequestExpensetableAdmin, RequestImpresttableAdmin } from "../workforcemanagement/Requestoutdutytable";
import Requestmanagment from "../workforcemanagement/Requestmanagment";
import { CircularGrid, Filter } from "../AllSvg";

const AttendanceAndSelfRequestManagement = () => {

    const [toggleState, setToggleState] = useState(1);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const emp_code = sessionStorage.getItem("emp_code")
    const email = sessionStorage.getItem("email")
    const profile = false
    const requesttoggleleave = 1
    const requesttoggleod = 2

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 0:
                return 'Attendance Dashboard';
            case 1:
                return 'My Attendance';
            case 2:
                return 'My Leave Requests';
            case 3:
                return 'My Outduty Requests';
            case 4:
                return 'My Compensatory Requests';
            case 5:
                return 'My Leave History';
            default:
                return 'My Attendance';
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Dashboardnavbarcopy name={getSelectedTitle()} url="Attendance & Request Management" />

            <div className="bloc-tabs-with-white-bg subcontent-tabs content-tabs">
                <div className="wfm-marginn  field-cont">
                    <button
                        className={
                            toggleState === 0
                                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                        }
                        onClick={() => { setToggleState(0) }}
                    >
                        Dashboard
                    </button>
                    <button
                        className={
                            toggleState === 1
                                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                        }
                        onClick={() => { setToggleState(1) }}
                    >
                        Attendance
                    </button>

                    <button
                        className={
                            toggleState === 2
                                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                        }
                        onClick={() => { setToggleState(2) }}
                    >
                        Leaves
                    </button>

                    <button
                        className={
                            toggleState === 3
                                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                        }
                        onClick={() => { setToggleState(3) }}
                    >
                        Out Duties
                    </button>

                    <button
                        className={
                            toggleState === 4
                                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                        }
                        onClick={() => { setToggleState(4) }}
                    >
                        Compensatory
                    </button>

                    {/* <button
                        className={
                            toggleState === 5
                                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                        }
                        onClick={() => { setToggleState(5) }}
                    >
                        History Logs
                    </button> */}

                </div>
            </div>


            <div className="content-tabs">

                {toggleState === 0 ? (
                    <AttendanceDashboard email={email} emp_code={emp_code} />
                ) : null}
                {toggleState === 1 ? (
                    <Attendances email={email} emp_code={emp_code} />
                ) : null}
                {toggleState === 2 ? (
                    <Leave requesttoggle={requesttoggleleave} email={email} emp_code={emp_code} />
                ) : null}
                {toggleState === 3 ? (
                    <Leave requesttoggle={requesttoggleod} email={email} emp_code={emp_code} />
                ) : null}
                {toggleState === 4 ? (
                    <EmployeeCompensatoryRequests email={email} emp_code={emp_code} />
                ) : null}
                {toggleState === 5 ? (
                    <EmployeeHistoryTab email={email} emp_code={emp_code} />
                ) : null}



            </div >
        </>
    );
};

const AttendanceDashboard = ({ email, emp_code }) => {

    const today = new Date();
    today.setDate(today.getDate() - 30);

    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formattedDatee = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [startdate, setStartDate] = useState(`${formattedDatee}`);
    const [enddate, setEndDate] = useState(`${formattedDate}`);
    const [allattendanceStatus, setAttendanceStatus] = useState({});
    const [leavesData, setLeavesData] = useState({});
    const [graphData, setGraphData] = useState({});

    const [buffer, setBuffering] = useState(true); //buffering logic
    const getAttendanceDetails = async () => {
        setBuffering(true); // Start Buffering
        try {
            const att = await axios.get(
                `${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatusquick/${emp_code}/${startdate}/${enddate}/`
            );
            setAttendanceStatus(att.data);

            const leave = await axios.get(`${BASE_URL}/wfm/leavebalance/${emp_code}/`);
            setLeavesData(leave.data);

            const graph = await axios.get(`${BASE_URL}/wfm/requeststatus/${emp_code}`);
            setGraphData(graph.data);

        } catch (err) {
            handleErrorToast(err)
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getAttendanceDetails();
    }, [startdate, enddate]);


    return (
        <>
            <div className="attendance-top-cont" style={{ display: 'flex' }}>
                <div style={{ width: '40vw' }}>
                    <Calaneder />
                </div>
                <div className="primary-left-chart" style={{ width: '40vw' }}>
                    <AttendanceChart allattendanceStatus={allattendanceStatus} />
                    <div className="attendance-history-cont">
                        <div className=" attendance-subcont">
                            <div className="justify-evenly flex-row">
                                <div className="field-cont-div">
                                    <div className="attendance-heading  font-weight500    font-size-heading">Attendance Stats From</div>
                                    <hr className="field-cont-hr" />
                                    <input
                                        className="attendance-input-field width-10vw   date-field"
                                        placeholder="From Date"
                                        type="date"
                                        value={startdate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                <div className="attendance-heading  font-weight500    font-size-heading">To</div>
                                <div className="field-cont-div">
                                    <hr className="field-cont-hr" />
                                    <input
                                        className="attendance-input-field width-10vw   date-field"
                                        placeholder="To Date"
                                        type="date"
                                        value={enddate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>

                            </div>
                            <div className="btn-cont">
                                {/* <button className='attendance-btn' onClick={getAttendanceDetails}><img src={Searchicon} alt="Search" className='searchicon' />Search</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="attendance-top-cont">
                        <div className="profile-leave-primary" style={{ flexBasis: "45%" }}>
                            <div className="profile-leave-status">
                                <span className="profile-leave-status-heading">
                                    Total Leave Requests
                                </span>
                                <span>{graphData.allleaverequest} </span>
                            </div>
                            <div className="profile-leave-status">
                                <span className="profile-leave-status-heading">
                                    Pending Requests
                                </span>
                                <span>{graphData.leave_details_pending} </span>
                            </div>
                            <div className="profile-leave-status">
                                <span className="profile-leave-status-heading">
                                    Approved Requests
                                </span>
                                <span>{graphData.leave_details_approved} </span>
                            </div>
                            <div className="profile-leave-status">
                                <span className="profile-leave-status-heading">
                                    Rejected Requests
                                </span>
                                <span>{graphData.leave_details_rejected} </span>
                            </div>
                        </div>

                        <div
                            className="profile-leave-secondary"
                            style={{ flexBasis: "50%" }}
                        >
                            <LeaveChart />
                        </div>
                    </div>
                    <br />
                    <div className="bloc-tabs-with-white-bg">
                        <div
                            className="field-container"
                            style={{ display: "flex", justifyContent: "space-evenly" }}
                        >
                            <div className="bloc-tabs-lb  font-weight400  font-size-subheading  ">
                                <label className="age-label   font-weight500    font-size-heading">
                                    Previous Leaves:{" "}
                                    <span className="age-label   font-weight500    font-size-heading">
                                        {leavesData.leavebalance
                                            ? `${leavesData.leavebalance} leave`
                                            : "No Remaining"}
                                    </span>
                                </label>
                            </div>
                            <div className="bloc-tabs-lb  font-weight400  font-size-subheading  ">
                                <label className="age-label   font-weight500    font-size-heading">
                                    Earned Leaves:{" "}
                                    <span className="age-label   font-weight500    font-size-heading">
                                        {leavesData.earn_leave
                                            ? `${leavesData.earn_leave} leave`
                                            : "No Remaining"}
                                    </span>
                                </label>
                            </div>
                            <div className="bloc-tabs-lb  font-weight400  font-size-subheading  ">
                                <label className="age-label   font-weight500    font-size-heading">
                                    Casual Leaves:{" "}
                                    <span className="age-label   font-weight500    font-size-heading">
                                        {leavesData.casual_leave
                                            ? `${leavesData.casual_leave} leave`
                                            : "No Remaining"}
                                    </span>
                                </label>
                            </div>

                            <div className="bloc-tabs-lb  font-weight400  font-size-subheading  ">
                                <label className="age-label   font-weight500    font-size-heading">
                                    Sick Leaves:{" "}
                                    <span className="age-label   font-weight500    font-size-heading">
                                        {leavesData.sick_leave
                                            ? `${leavesData.sick_leave} leave`
                                            : "No Remaining"}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="btn-cont"></div>
                    </div>
                </div>
            </div>
            <div className="attendance-top-cont" style={{ display: 'flex' }}>

                <div className="profile-leave-secondary" style={{ flexBasis: "50%" }}>
                    <OutDutyChart />
                </div>

                <div className="profile-leave-primary" style={{ flexBasis: "45%" }}>
                    <div className="profile-leave-status">
                        <span className="profile-leave-status-heading">
                            Total OutDuty Requests
                        </span>
                        <span>{graphData.alloutdutyrequest} </span>
                    </div>
                    <div className="profile-leave-status">
                        <span className="profile-leave-status-heading">
                            Pending Requests
                        </span>
                        <span>{graphData.outduty_details_pending} </span>
                    </div>
                    <div className="profile-leave-status">
                        <span className="profile-leave-status-heading">
                            Approved Requests
                        </span>
                        <span>{graphData.outduty_details_approved} </span>
                    </div>
                    <div className="profile-leave-status">
                        <span className="profile-leave-status-heading">
                            Rejected Requests
                        </span>
                        <span>{graphData.outduty_details_rejected} </span>
                    </div>
                </div>
            </div>
        </>

    );
};

const EmployeeHistoryTab = ({ emp_code, email }) => {

    const [toggleState, setToggleState] = useState(1);
    return (
        <>
            <div className="bloc-tabss wfm-marginn">
                <button
                    className={
                        "leave-tab " +
                        (toggleState === 1
                            ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs "
                            : "dashboardcomponent-tabs  font-weight400  font-size-subheading   ")
                    }
                    onClick={() => setToggleState(1)}
                >
                    Leave History
                </button>
                <button
                    className={
                        "leave-tab " +
                        (toggleState === 2
                            ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs "
                            : "dashboardcomponent-tabs  font-weight400  font-size-subheading   ")
                    }
                    onClick={() => setToggleState(2)}
                >
                    Notification History
                </button>
            </div>

            <div className="content-tabs">

                {toggleState === 1 ? (
                    <EmployeeLeaveLogTable email={email} emp_code={emp_code} />
                ) : null}

                {toggleState === 2 ? (
                    <EmployeeNotificationLogTable email={email} emp_code={emp_code} />
                ) : null}



            </div >
        </>
    )
};

const EmployeeLeaveLogTable = ({ emp_code }) => {
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
    const [checkedItems, setCheckedItems] = useState([]);
    // const [date, setDate] = useState();
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [employeeList, setEmployeeList] = useState([]);
    // const [date, setDate] = useState(`${formattedDate}`);
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");
    const [selectedStatus, setSelectedStatus] = useState("null");
    const [employeeGet, setEmployeeGet] = useState("null");
    const [active, setActive] = useState("active");
    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("company_id")}`
    );

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err)
        }
    };

    // *********** Employee Details Api Start ***********
    const [leaveLogList, setLeaveLogList] = useState([]);
    const [filteredleaveLogList, setfilteredleaveLogList] = useState([]);

    const getEmpList = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
            );
            const sortedData = customSortByKey(res.data, "emp_code");

            setEmployeeList(sortedData);
        } catch (err) {
            handleErrorToast(err)
        }
    };

    const getLeaveLogList = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${emp_code}/null/active/`
            );
            setLeaveLogList(res.data);
            // setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    // *********** Employee Details Api End ***********

    useEffect(() => {
        getLeaveLogList();
        getEmpList();
        getSubCompany();
    }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

    useEffect(() => {
    }, []);

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">

                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="To Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />

                        </div>

                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />

                        </div>

                    </div>

                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Employee Leave Logs </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">Sr.no</th>
                                <th className="align-center">Date - Time</th>
                                <th className="align-center">Leave Type</th>
                                <th className="align-center">Balance Before</th>
                                <th className="align-center">Leaves Affected</th>
                                <th className="align-center">Balance After</th>
                                <th className="align-center width-20vw">Description</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div>
                        ) : (
                            <tbody>
                                {leaveLogList.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>
                                            <td className="align-center">{formattedDateLong(i.date)}-{i.in_time}</td>
                                            <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                                            <td className="align-center">{i.out_time}</td>
                                            <td className="align-center">{i.work_duration}</td>
                                            <td className="align-center">{i.rh_assigned}</td>
                                            <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

const EmployeeNotificationLogTable = ({ emp_code }) => {
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
    const [checkedItems, setCheckedItems] = useState([]);
    // const [date, setDate] = useState();
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [employeeList, setEmployeeList] = useState([]);
    // const [date, setDate] = useState(`${formattedDate}`);
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");
    const [selectedStatus, setSelectedStatus] = useState("null");
    const [employeeGet, setEmployeeGet] = useState("null");
    const [active, setActive] = useState("active");
    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("company_id")}`
    );

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err)
        }
    };

    // *********** Employee Details Api Start ***********
    const [leaveLogList, setLeaveLogList] = useState([]);
    const [filteredleaveLogList, setfilteredleaveLogList] = useState([]);

    const getEmpList = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
            );
            const sortedData = customSortByKey(res.data, "emp_code");

            setEmployeeList(sortedData);
        } catch (err) {
            handleErrorToast(err)
        }
    };

    const getLeaveLogList = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${emp_code}/null/active/`
            );
            setLeaveLogList(res.data);
            // setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    // *********** Employee Details Api End ***********

    useEffect(() => {
        getLeaveLogList();
        getEmpList();
        getSubCompany();
    }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

    useEffect(() => {
    }, []);

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">

                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />

                        </div>

                        <div className="field-cont-div">
                            <CircularGrid />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />

                        </div>

                    </div>

                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Employee Notification Logs </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">Sr.no</th>
                                <th className="align-center">Date - Time</th>
                                <th className="align-center">Leave Type</th>
                                <th className="align-center">Balance Before</th>
                                <th className="align-center">Leaves Affected</th>
                                <th className="align-center">Balance After</th>
                                <th className="align-center width-20vw">Description</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div>
                        ) : (
                            <tbody>
                                {leaveLogList.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>
                                            <td className="align-center">{formattedDateLong(i.date)}-{i.in_time}</td>
                                            <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                                            <td className="align-center">{i.out_time}</td>
                                            <td className="align-center">{i.work_duration}</td>
                                            <td className="align-center">{i.rh_assigned}</td>
                                            <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

const TeamRequestsManagementTab = () => {

    // const [toggleState, setToggleState] = useState(1);
    const location = useLocation();
    const firsttoggle = location.state !== null ? location.state?.firsttoggle : 1;
    const secondtoggle = location.state !== null ? location.state?.secondtoggle : 1;
    const [toggleState, setToggleState] = useState(secondtoggle);

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 0:
                return 'My Team Clockins';
            case 1:
                return 'My Team OutDuties';
            case 2:
                return 'My Team Leaves';
            case 3:
                return 'My Team Compensatory Requests';
            case 4:
                return 'My Team Imprests';
            case 5:
                return 'My Team Expenses';
            case 6:
                return 'My Team Requisition Requests';
            case 7:
                return 'My Team Leave History Log';
            default:
                return 'My Team OutDuties';
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Dashboardnavbarcopy name={getSelectedTitle()} url="Team Request Management" />
            <div className="content-tabs">
                {/* <TeamRequestManagment secondtoggle={secondtoggle} /> */}
                <>
                    <div>
                        <div className="bloc-tabs-with-white-bg">
                            {/*  //? @SC Customization */}
                            {/* view in SC hide for CIPL/GENG */}
                            {sessionStorage.getItem("company") === "Space Creattors" || sessionStorage.getItem("company") === "Space Creattors Heights" ?
                                <button
                                    className={toggleState === 0 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                    onClick={() => setToggleState(0)}
                                >
                                    Team Clock-Ins
                                </button> : ""}
                            <button
                                className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(1)}
                            >
                                Out Duty
                            </button>
                            <button
                                className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(2)}
                            >
                                Leave
                            </button>

                            <button
                                className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(3)}
                            >
                                Compensatory Leave
                            </button>
                            <button
                                className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(4)}
                            >
                                Imprest
                            </button>
                            <button
                                className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(5)}
                            >
                                Expense
                            </button>
                            <button
                                className={toggleState === 6 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(6)}
                            >
                                Requisition Requests
                            </button>
                            {/* <button
                                className={toggleState === 7 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(7)}
                            >
                                Leave History Log
                            </button> */}
                        </div>

                        <div className="content-tabs-without-margin">
                            {toggleState === 0 ? <RequestClockInTable /> : null}
                            {toggleState === 1 ? <Requestoutdutytable /> : null}
                            {toggleState === 2 ? <RequestLeavetable /> : null}
                            {toggleState === 3 ? <RequestCompensatorytable /> : null}

                            {toggleState === 4 ? <RequestImpresttable /> : null}
                            {toggleState === 5 ? <RequestExpensetable /> : null}

                            {toggleState === 6 ? <RequestRequisitionTableRH /> : null}
                            {toggleState === 7 ? <TeamLeaveLogTable /> : null}
                            {/* {toggleState === 3 ? <RequestMisspunchtable /> : null} */}
                        </div>
                    </div>
                </>
            </div >
        </>
    );
};

const HRRequestsManagementTab = () => {
    const location = useLocation();

    const firsttoggle = location.state !== null ? location.state?.firsttoggle : 7;
    const secondtoggle = location.state !== null ? location.state?.secondtoggle : 1;


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {/* <Dashboardnavbarcopy name={getSelectedTitle()} url="Admin Requests Management" /> */}
            <Dashboardnavbarcopy name={"Requests Management"} url="HR Requests Management" />
            <div className="content-tabs">
                <Requestmanagment secondtoggle={secondtoggle} />
            </div >
        </>
    );
};

const AdminRequestsManagementTab = () => {

    // const [toggleState, setToggleState] = useState(1);
    const location = useLocation();
    const firsttoggle = location.state !== null ? location.state?.firsttoggle : 1;
    const secondtoggle = location.state !== null ? location.state?.secondtoggle : 1;
    const [toggleState, setToggleState] = useState(secondtoggle);

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 0:
                return 'Clock-In Logs';
            case 1:
                return 'Imprest Requests';
            case 2:
                return 'Expense Requests';
            case 3:
                return 'Compensatory Logs';
            case 4:
                return 'Requisition Requests';
            default:
                return 'Imprest Requests';
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Dashboardnavbarcopy name={getSelectedTitle()} url="Admin Requests Management" />
            <div className="content-tabs">
                {/* <TeamRequestManagment secondtoggle={secondtoggle} /> */}
                <>
                    <div>
                        <div className="bloc-tabs-with-white-bg">
                            {/*  //? @SC Customization */}
                            {/* view in SC hide for CIPL/GENG */}
                            {sessionStorage.getItem("company") === "Space Creattors" ||
                                sessionStorage.getItem("company") === "Space Creattors Heights" ? (
                                <button
                                    className={
                                        toggleState === 0
                                            ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                            : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                    }
                                    onClick={() => setToggleState(0)}
                                >
                                    Clock-In Log
                                </button>
                            ) : null}
                            <button
                                className={
                                    toggleState === 1
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(1)}
                            >
                                Imprest
                            </button>
                            <button
                                className={
                                    toggleState === 2
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(2)}
                            >
                                Expense
                            </button>
                            {/* <button
                                className={
                                    toggleState === 3
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(3)}
                            >
                                Compensatory Leaves Log
                            </button> */}
                            <button
                                className={toggleState === 4
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(4)}
                            >
                                Requisition Requests
                            </button>
                        </div>

                        <div className="content-tabs-without-margin">
                            {toggleState === 0 ? <RequestClockInTableHR /> : null}
                            {toggleState === 1 ? <RequestImpresttableAdmin /> : null}
                            {toggleState === 2 ? <RequestExpensetableAdmin /> : null}
                            {toggleState === 3 ? <LogCompensatorytable /> : null}
                            {toggleState === 4 ? <RequestRequisitionTableAdmin /> : null}

                        </div>
                    </div>
                </>
            </div >
        </>
    );
};

const EmployeeCompensatoryRequests = ({ emp_code }) => {
    const [date, setDate] = useState(`${formattedDate}`);
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");
    const [selectedStatus, setSelectedStatus] = useState("null");
    const [employeeGet, setEmployeeGet] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic

    // *********** Employee Details Api Start ***********
    const [compensatoryList, setCompensatoryList] = useState([]);
    const [filteredCompensatoryList, setfilteredCompensatoryList] = useState([]);


    const getCompensatoryList = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${emp_code}/null/active/`
            );
            setCompensatoryList(res.data);
        } catch (err) {
            handleErrorToast(err)
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    // *********** Employee Details Api End ***********

    useEffect(() => {
        getCompensatoryList();
    }, [date, fromdate, todate, selectedStatus]);

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className="  date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 17 17"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3351)">
                                    <mask
                                        id="mask0_650_3351"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="17"
                                        height="17"
                                    >
                                        <path d="M17 0H0V17H17V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_650_3351)">
                                        <path
                                            d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                            fill="#707070"
                                        />
                                        <path
                                            d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                            fill="#707070"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3351">
                                        <rect width="17" height="17" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                    </div>

                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Compensatory leaves </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">Sr.no</th>



                                <th className="align-center">Date</th>
                                <th className="align-center">In-Time</th>
                                <th className="align-center">Out-Time</th>
                                <th className="align-center">Working Hours</th>
                                <th className="align-center">Compensatory Type</th>
                                <th className="align-center">Status</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {compensatoryList.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>

                                            <td className="align-center">
                                                {formattedDateLong(i.date)}
                                            </td>
                                            <td className="align-center">{i.in_time}</td>
                                            <td className="align-center">{i.out_time}</td>
                                            <td className="align-center">{i.work_duration}</td>
                                            <td className="align-center form-text-trasformation-uppercase">
                                                {i.duration}
                                            </td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_leave_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_leave_status === "pending"
                                                        ? "Pending For Rh Approval ⌛"
                                                        : i.rh_leave_status === "rejected"
                                                            ? "Rh Rejected ✖, "
                                                            : "Rh Approved ✔, "}
                                                </span>
                                                {/* <br />
                      <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin Approved ✔, " : ""}</span> */}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </>
    );
};

export { AttendanceDashboard, EmployeeCompensatoryRequests, AttendanceAndSelfRequestManagement, TeamRequestsManagementTab, HRRequestsManagementTab, AdminRequestsManagementTab, EmployeeHistoryTab, EmployeeLeaveLogTable, EmployeeNotificationLogTable };
