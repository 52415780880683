import React from 'react'
import LetterHeadPr from './LetterHeadPR'
import LetterHeadPrP from './LetterHeadPRP'
import LetterHead from './LetterHead'
import { useParams } from "react-router-dom";
import LetterHead4 from './LetterHead4';

const Letter = () => {
let { id, emp } = useParams();

    return (
        <div>
            {id === "1" ? <LetterHeadPr emp={emp} /> : ""}
            {id === "2" ? <LetterHeadPrP emp={emp} /> : ""}
            {id === "3" ? <LetterHead emp={emp} /> : ""}
            {id === "4" ? <LetterHead4 emp={emp} /> : ""}
        </div>
    )
}

export default Letter
