import React, { useEffect, useState } from "react";
import AttendanceChart from "./userDetails-comp/AttendanceChart";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { formattedDate, formattedDateLong } from "../Date";
import { ToastContainer, toast } from "react-toastify";
import { Filter } from "../AllSvg";

const Attendances = ({ email, emp_code }) => {
  // *********** Attendance Api Start ***********

  const today = new Date();
  today.setDate(today.getDate() - 30);

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedDatee = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  // const datearrayy = formattedDate.split("-");

  const [allattendance, setAttendance] = useState([]);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [allattendanceStatus, setAttendanceStatus] = useState({});

  const [startdate, setStartDate] = useState(`${formattedDatee}`);

  const [enddate, setEndDate] = useState(`${formattedDate}`);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDayStatus, setSelectedDayStatus] = useState("");
  const [status_list, setStatus_list] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getAttendanceDetails = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatus/${emp_code}/${startdate}/${enddate}/${selectedStatus}`
      );
      const ress = await axios.get(
        `${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatusquick/${emp_code}/${startdate}/${enddate}/`
      );
      setAttendance(res.data);
      const uniqueDayStatus = [
        ...new Set(res.data.map((entry) => entry.day_status)),
      ];
      setStatus_list(uniqueDayStatus);
      setAttendanceStatus(ress.data);
    } catch (err) {
      // alert(err.message);
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  useEffect(() => {
    getAttendanceDetails();
  }, [selectedStatus, startdate, enddate]);
  // *********** Attendance Api End ***********

  const applyFilters = () => {
    let filteredData = allattendance;
    if (selectedDayStatus !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.day_status === selectedDayStatus
      );
    }
    setFilteredAttendance(filteredData);
  };
  useEffect(() => {
    applyFilters();
  }, [selectedDayStatus, allattendance]);

  return (
    <>
      {/* Attendance Details */}
      {buffer ? <div className="spinner"></div> :

        <div className="attendance-top-cont">
          <div className="attendance-primary-details">
            <div className="primary-left">
              <div className="ontime-items items">
                <span>On Time</span>
                <span>{allattendanceStatus.ontime}</span>
              </div>
              <div className="late-items items">
                <span>Late</span>
                <span>{allattendanceStatus.late}</span>
              </div>
              <div className="late-items items">
                <span>Halfday</span>
                <span>{allattendanceStatus.halfday}</span>
              </div>
              <div className="outduty-items items">
                <span>Out duty</span>
                <span>{allattendanceStatus.outduty}</span>
              </div>
            </div>
            <div className="secondary-right">
              <div className="present-items items">
                <span>Present</span>
                <span>{allattendanceStatus.present}</span>
              </div>
              <div className="absent-items items">
                <span>Absent</span>
                <span>{allattendanceStatus.absent}</span>
              </div>

              <div className="leave-items items">
                <span>Leave</span>
                <span>{allattendanceStatus.leave}</span>
              </div>
            </div>
          </div>

          {/* Attendance Chart */}
          <div className="primary-left-chart">
            <AttendanceChart allattendanceStatus={allattendanceStatus} />
          </div>
        </div>
      }
      {/* Attendance History Container */}
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading">Attendance History</div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                className="attendance-input-field width-10vw   date-field"
                placeholder="From Date"
                type="date"
                value={startdate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <input
                className="attendance-input-field width-10vw   date-field"
                placeholder="To Date"
                type="date"
                value={enddate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDayStatus}
                onChange={(e) => setSelectedDayStatus(e.target.value)}
              >
                <option value="">All Behaviour</option>
                {status_list.map((status) => (
                  <option value={status}>{status}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All Status</option>

                <option value="present/">Present</option>
                <option value="leave/">Leave</option>
                <option value="absent/">Absent</option>
              </select>
            </div>
          </div>
          <div className="btn-cont">
            {/* <button className='attendance-btn' onClick={getAttendanceDetails}><img src={Searchicon} alt="Search" className='searchicon' />Search</button> */}
          </div>
        </div>
      </div>

      {/* Attendance History Details */}
      <div className="table-css-white-background">
        <div className="table-mainheading">
          <div>Attendance</div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr. No.</th>
              <th className="align-center">Date</th>
              <th className="align-center">Punch in</th>
              <th className="align-center">Punch out</th>
              <th className="align-center">Total hours</th>
              <th className="align-right">Behaviour</th>
              <th className="align-right">Status</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> :
            <tbody>
              <tr className="tr-border-bottom">
                <td colSpan={6}></td>
              </tr>
              {filteredAttendance
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((item, index) => {
                  return (
                    <>
                      <tr className="custom-table-head-td" key={index}>
                        <td className="align-left">{index + 1}</td>
                        <td className="align-center">
                          {formattedDateLong(item.date)}
                        </td>
                        <td className="align-center">{item.in_time}</td>
                        <td className="align-center">{item.out_time}</td>
                        <td className="align-center">{item.work_duration}</td>

                        <td
                          data-status={item.day_status}
                          className="align-right attendance-status form-text-trasformation-uppercase"
                        >
                          {item.day_status}
                        </td>
                        <td
                          data-status={item.attendence_status}
                          className="align-right attendance-status form-text-trasformation-uppercase"
                        >
                          {item.attendence_status}
                        </td>
                      </tr>
                      <tr className="tr-border-bottom">
                        <td colSpan={6}></td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

export default Attendances;
