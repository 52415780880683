import React from 'react'
import { useLocation } from "react-router-dom";
import LetterHeadPr from './LetterHeadPR'
import LetterHeadPrP from './LetterHeadPRP'
import LetterHeadPE from './LetterHeadPE'
import LetterHeadE from './LetterHeadE'

const Letter = () => {
    const location = useLocation();
    const lettertype = location.state ? location.state.lettertype : null;
    const letterno = location.state ? location.state.index : 0;
    const empcode = location.state.emp_code ? location.state.emp_code : sessionStorage.getItem("emp_code");
    return (
        <div>
            {/* {sessionStorage.getItem("letter_type") === "1" ? <LetterHeadPr /> : ""} */}
            {lettertype === "1" ? <LetterHeadPr lno={letterno} empcode={empcode} /> : ""}
            {lettertype === "2" ? <LetterHeadPrP lno={letterno} empcode={empcode} /> : ""}
            {lettertype === "3" ? <LetterHeadPE lno={letterno} empcode={empcode} /> : ""}
            {lettertype === "4" ? <LetterHeadE lno={letterno} empcode={empcode} /> : ""}
        </div>
    )
}

export default Letter
