import React, { useEffect, useState } from 'react';
import { hierarchy, tree } from 'd3-hierarchy';
import * as d3 from 'd3';
import axios from 'axios';
import { Arrow, DropdownArrow, DropdownArrowOption, Employee, Eye, Search, Sort } from '../AllSvg';
import { BASE_URL } from '../../config/axios';
import Tree from "react-d3-tree";

const HierarchyChartBoxed = () => {
  const [treeData, setTreeData] = useState([]);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedNode, setExpandedNode] = useState(null);
  const [initialDepth, setInitialDepth] = useState(1);
  const [expandedNodes, setExpandedNodes] = useState({});

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/wfm/herasubcomp/${sessionStorage.getItem('company_id')}/`);
      // console.log("response.data")
      // console.log(response.data)
      // console.log("-------------------------------------------")
      const transformedData = transformData(response.data);
      console.log("[transformedData]")
      console.log([transformedData])
      console.log("+++++++++++++++++++++++++++++++++++++++++++++++")
      setTreeData([transformedData]);
      setInitialTranslate();
      calculateDepthToExpand(transformedData);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (treeData.length > 0) {
      const updatedTree = transformData(treeData); // Re-transform based on the latest expandedNodes.
      setTreeData([updatedTree]); // Update treeData with the new state.
    }
  }, [expandedNodes]);

  const setInitialTranslate = () => {
    const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();
    setTranslate({ x: dimensions.width / 2, y: 50 });
  };

  // const transformData = (data) => {
  //   const transformNode = (node) => ({
  //     name: node.user_detail?.name,
  //     attributes: {
  //       emp_code: node.emp_code,
  //       position: node.designation_name,
  //       department: node.department_name,
  //       profilepic: node.profilepic,
  //     },
  //     toggled: expandedNodes[node.emp_code] || false,
  //     children: node.subordinates ? node.subordinates.map(transformNode) : [],
  //   });

  //   return transformNode(data[0]);
  // };

  const transformData = (data) => {
    
    const transformNode = (node) => {
      // console.log("node")
      // console.log(node)
      // Safeguard against undefined or missing properties
      if (expandedNodes[node.emp_code]) {
        console.log("node.emp_code")
        console.log(node.emp_code)
        console.log("expandedNodes[node.emp_code]")
        console.log(expandedNodes[node.emp_code])
      }

      return {
        name: node.user_detail.name,
        user_detail: node.user_detail,
        emp_code: node.emp_code,
        designation_name: node.designation_name,
        department_name: node.department_name,
        profilepic: node.profilepic,
        attributes: {
          emp_code: node.emp_code,
          designation_name: node.designation_name,
          department_name: node.department_name,
          profilepic: node.profilepic,
        },
        toggled: expandedNodes[node.emp_code] || false,
        // children: node.subordinates ? node.subordinates.map(transformNode) : [],
        children: node.subordinates ? node.subordinates.map(transformNode) : node.children ? node.children.map(transformNode) : [],

      };
    };
    // console.log("data[0]")
    // console.log(data[0])
    console.log("====================================================================")
    return transformNode(data[0]);
  };



  const calculateDepthToExpand = (tree) => {
    const targetEmpCode = sessionStorage.getItem('emp_code');

    const findDepth = (node, currentDepth) => {
      if (node.attributes.emp_code === targetEmpCode) {
        setExpandedNode(node.attributes.emp_code); // Set the initially expanded node
        return currentDepth;
      }

      if (node.children) {
        for (let child of node.children) {
          const childDepth = findDepth(child, currentDepth + 1);
          if (childDepth !== -1) {
            return childDepth;
          }
        }
      }

      return -1; // If no match is found
    };

    const depth = findDepth(tree, 1);
    setInitialDepth(depth !== -1 ? depth : 1); // Default to 1 if not found
  };
  // const handleNodeClick = (node) => {
  //   setExpandedNodes((prevExpandedNodes) => {
  //     const updatedExpandedNodes = { ...prevExpandedNodes };
  //     console.log("Click")
  //     // Close all nodes at the same level by setting them to false
  //     Object.keys(updatedExpandedNodes).forEach((key) => {
  //       if (key !== node.attributes.emp_code) {
  //         updatedExpandedNodes[key] = false;
  //       }
  //     });

  //     // Toggle the current node's expanded state
  //     updatedExpandedNodes[node.attributes.emp_code] = !prevExpandedNodes[node.attributes.emp_code];
  //     console.log("updatedExpandedNodes")
  //     console.log(updatedExpandedNodes)
  //     console.log("prevExpandedNodes")
  //     console.log(prevExpandedNodes)
  //     return updatedExpandedNodes;
  //   });

  //   // Re-transform the tree data with the updated expanded state
  //   setTreeData((prevTreeData) => {
  //     // console.log("prevTreeData")
  //     // console.log(prevTreeData)
  //     console.log("-------------------------------------------")
  //     // console.log("prevTreeData[0]")
  //     // console.log(prevTreeData[0])
  //     // console.log("prevTreeData.length")
  //     // console.log(Object.keys(prevTreeData).length)
  //     // console.log("prevTreeData.children")
  //     // console.log(prevTreeData[0].children)
  //     // console.log("prevTreeData.children.length")
  //     // console.log(prevTreeData[0].children.length)
  //     if (prevTreeData.length > 0) {
  //       const updatedTree = transformData(prevTreeData);
  //       console.log("[updatedTree]")
  //       console.log([updatedTree])
  //       console.log("+++++++++++++++++++++++++++++++++++++++++++++++")
  //       return [updatedTree];
  //     }
  //     return prevTreeData;
  //   });
  // };


  // const CustomLabel = ({ nodeData }) => {
  //   const profilePicUrl = nodeData.attributes.profilepic
  //     ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`
  //     : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  //   return (
  //     <div style={customLabelStyle} onClick={() => handleNodeClick(nodeData)}>
  //       <img
  //         src={profilePicUrl}
  //         alt={`${nodeData.name}'s profile`}
  //         style={imageStyle}
  //         onError={(e) => { e.target.src = 'https://via.placeholder.com/50'; }}
  //       />
  //       <strong>{nodeData.name}</strong>
  //       <div>{nodeData.attributes.position}</div>
  //       <div>{nodeData.attributes.department}</div>
  //     </div>
  //   );
  // };

  const handleNodeClick = (node) => {
    setExpandedNodes((prevExpandedNodes) => {
      const updatedExpandedNodes = { ...prevExpandedNodes };
      updatedExpandedNodes[node.attributes.emp_code] = !prevExpandedNodes[node.attributes.emp_code];
      return updatedExpandedNodes;
    });
  };


  const CustomLabel = ({ nodeData }) => {
    const profilePicUrl = nodeData.attributes.profilepic
      ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`
      : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    const boxStyle = {
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      cursor: 'pointer',
      width: '150px',
    };

    const imageStyle = {
      borderRadius: '50%',
      width: '60px',
      height: '60px',
      objectFit: 'cover',
      marginBottom: '10px',
    };

    return (
      <div style={boxStyle} >
        <img
          src={profilePicUrl}
          alt={`${nodeData.name}'s profile`}
          style={imageStyle}
          onError={(e) => {
            e.target.src = 'https://via.placeholder.com/50';
          }}
        />
        <br />
        <strong>{nodeData.name}</strong>
        <div>{nodeData.attributes.department_name} : </div>
        <div>{nodeData.attributes.designation_name}</div>

        {
          nodeData.children.length > 0 ?
            <button onClick={() => handleNodeClick(nodeData)}>
              <DropdownArrowOption />
            </button>
            : null}
      </div>
    );
  };



  const customLabelStyle = {
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'center',
    background: '#fff',
    width: '150px',
    height: '150px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const imageStyle = {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    marginBottom: '10px',
  };

  return (
    <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : treeData.length > 0 ? (
        // <Tree
        //   data={treeData}
        //   orientation="vertical"
        //   translate={translate}
        //   nodeSvgShape={{ shape: 'none' }}
        //   pathFunc="elbow"
        //   styles={{
        //     links: {
        //       stroke: '#2576BC',
        //       strokeWidth: 2,
        //     },
        //   }}
        //   renderCustomNodeElement={({ nodeDatum }) => (
        //     <foreignObject width={200} height={500} x={-75} y={-50}>
        //       <CustomLabel nodeData={nodeDatum} />
        //     </foreignObject>
        //   )}
        //   // nodeLabelComponent={{
        //   //   render: <CustomLabel />,
        //   //   foreignObjectWrapper: {
        //   //     y: -50,
        //   //     x: -75,
        //   //     width: 150,
        //   //     height: 150,
        //   //   },
        //   // }}
        //   collapsible
        //   initialDepth={initialDepth}
        //   separation={{ siblings: 2, nonSiblings: 2 }}
        //   // onNodeClick={handleNodeClick}
        //   // onNodeClick={handleNodeClick}
        //   onLinkClick={handleNodeClick}
        // />
        <Tree
          data={treeData}
          orientation="vertical"
          translate={translate}
          nodeSvgShape={{ shape: 'none' }}
          pathFunc="step"
          styles={{
            links: {
              stroke: '#2576BC',
              strokeWidth: 2,
            },
          }}
          renderCustomNodeElement={({ nodeDatum }) => (
            <foreignObject width={500} height={500} x={-100} >
              <CustomLabel nodeData={nodeDatum} />
            </foreignObject>
          )}
          collapsible
          initialDepth={initialDepth}
          separation={{ siblings: 2, nonSiblings: 2 }}
        />


      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};


// const HierarchyChartBoxed = () => {
//   const [treeData, setTreeData] = useState([]);
//   const [translate, setTranslate] = useState({ x: 0, y: 0 });
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [expandedNodes, setExpandedNodes] = useState({});

//   const setInitialTranslate = () => {
//     const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();
//     setTranslate({ x: dimensions.width / 2, y: 50 });
//   };

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/wfm/herasubcomp/${sessionStorage.getItem('company_id')}/`
//       );
//       const transformedData = transformData(response.data);
//       console.log("Fetched and transformed data:", transformedData);  // Log fetched data
//       setTreeData([transformedData]);
//       setInitialTranslate();
//     } catch (error) {
//       setError('Error fetching data');
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const transformData = (data) => {
//     const transformNode = (node) => {
//       const transformedNode = {
//         name: node.user_detail.name,
//         user_detail: node.user_detail,
//         emp_code: node.emp_code,
//         designation_name: node.designation_name,
//         department_name: node.department_name,
//         profilepic: node.profilepic,
//         toggled: expandedNodes[node.emp_code] || false,
//         children: node.subordinates
//           ? node.subordinates.map(transformNode)
//           : node.children
//           ? node.children.map(transformNode)
//           : [],
//       };
//       console.log("Transformed Node:", transformedNode);  // Log each transformed node
//       return transformedNode;
//     };
//     return transformNode(data[0]);
//   };

//   const handleNodeClick = (nodeData) => {
//     console.log("Clicked Node:", nodeData);  // Log clicked node data

//     // Toggle expanded state of the node
//     setExpandedNodes((prevExpandedNodes) => {
//       const newExpandedNodes = {
//         ...prevExpandedNodes,
//         [nodeData.emp_code]: !prevExpandedNodes[nodeData.emp_code],
//       };
//       console.log("Updated expandedNodes:", newExpandedNodes);  // Log updated expandedNodes
//       return newExpandedNodes;
//     });

//     // Re-fetch and re-transform the data to update `toggled` state
//     const updatedTreeData = transformData(treeData[0]);
//     setTreeData([updatedTreeData]);
//   };

//   const CustomLabel = ({ nodeData }) => {
//     const profilePicUrl = nodeData.attributes?.profilepic
//       ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`
//       : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

//     const boxStyle = {
//       border: '1px solid #ddd',
//       borderRadius: '8px',
//       padding: '10px',
//       backgroundColor: '#fff',
//       boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//       textAlign: 'center',
//       cursor: 'pointer',
//       width: '150px',
//     };

//     const imageStyle = {
//       borderRadius: '50%',
//       width: '60px',
//       height: '60px',
//       objectFit: 'cover',
//       marginBottom: '10px',
//     };

//     return (
//       <div style={boxStyle} onClick={() => handleNodeClick(nodeData)}>
//         <img
//           src={profilePicUrl}
//           alt={`${nodeData.name}'s profile`}
//           style={imageStyle}
//           onError={(e) => {
//             e.target.src = 'https://via.placeholder.com/50';
//           }}
//         />
//         <strong>{nodeData.name}</strong>
//         <div>{nodeData.attributes?.department_name}</div>
//         <div>{nodeData.attributes?.designation_name}</div>
//         {nodeData.children.length > 0 && (
//           <button onClick={(e) => e.stopPropagation()}>
//             <DropdownArrowOption />
//           </button>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
//       {loading ? (
//         <p>Loading...</p>
//       ) : error ? (
//         <p>{error}</p>
//       ) : treeData.length > 0 ? (
//         <Tree
//           data={treeData}
//           orientation="vertical"
//           translate={translate}
//           nodeSvgShape={{ shape: 'none' }}
//           pathFunc="step"
//           styles={{
//             links: {
//               stroke: '#2576BC',
//               strokeWidth: 2,
//             },
//           }}
//           renderCustomNodeElement={({ nodeDatum }) => (
//             <foreignObject width={500} height={500} x={-100}>
//               <CustomLabel nodeData={nodeDatum} />
//             </foreignObject>
//           )}
//           collapsible
//           initialDepth={1}
//           separation={{ siblings: 2, nonSiblings: 2 }}
//         />
//       ) : (
//         <p>No data available.</p>
//       )}
//     </div>
//   );
// };








export default HierarchyChartBoxed;
