import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { customSortByKey, handleErrorToast } from "../CustomFunctions";

const AssetModel = ({ getAsset, employeeList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    emp_code: "",
    date: "",
    description: "",
    quantity: "",
    item: "",
    sub_company: sessionStorage.getItem('company_id')
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "date",
      "description",
      "quantity",
      "item",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };

      if (name === "emp_code") {
        const selectedEmployee = employeeList.find(emp => emp.emp_code === value);
        if (selectedEmployee) {
          updatedFormData.name = selectedEmployee.name;
        } else {
          updatedFormData.name = "";
        }
      }

      return updatedFormData;
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/assets/`, formData);

        if (res.status === 200) {
          await getAsset();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        handleErrorToast(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column form-group-selectt">
                <label htmlFor="emp_code" className="form-labels  font-weight500    font-size-subheading">
                  Employee<span className="required">*</span>
                </label>
                <br />
                <select
                  id="emp_code"
                  name="emp_code"
                  value={formData.emp_code}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.emp_code ? "error" : inputState.emp_code ? "success" : ""
                    }`}
                >
                  <option value="">Select Employee</option>
                  {employeeList.map((i) => (<>
                    <option value={i.emp_code}>{i.emp_code}-{i.name}</option>
                    {/* <option value={i.emp_code}>{i.emp_code}</option> */}
                  </>))
                  }
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.emp_code && (
                    <span className="error-message font-size-text ">{errors.emp_code}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
                  Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  placeholder="Date"
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input ${errors.date ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.date && (
                  <span className="error-message font-size-text ">{errors.date}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="item" className="form-labels  font-weight500    font-size-subheading">
                  Item<span className="required">*</span>
                </label>
                <input
                  type="item"
                  id="item"
                  name="item"
                  placeholder="Name of the item"
                  onChange={handleInputChange}
                  value={formData.item}
                  className={`form-input ${errors.item ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.item && (
                  <span className="error-message font-size-text ">{errors.item}</span>
                )}
              </div>


              <div className="flex-column">
                <label htmlFor="description" className="form-labels  font-weight500    font-size-subheading">
                  Description<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Detail of the Item"
                  onChange={handleInputChange}
                  value={formData.description}
                  className={`form-input ${errors.description
                    ? "error"
                    : inputState.tile
                      ? "success"
                      : ""
                    }`}
                />
                {errors.description && (
                  <span className="error-message font-size-text ">{errors.description}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                  Quantity<span className="required">*</span>
                </label>
                <input
                  type="number"
                  min={0}
                  id="quantity"
                  name="quantity"
                  placeholder="Quantity"
                  onChange={handleInputChange}
                  value={formData.quantity}
                  className={`form-input ${errors.quantity ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.quantity && (
                  <span className="error-message font-size-text ">{errors.quantity}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const AssetModelUpdate = ({ i, getAsset, emp_code, employeeList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    emp_code: "",
    date: "",
    description: "",
    quantity: "",
    item: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "date",
      "description",
      "quantity",
      "item",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };

      if (name === "emp_code") {
        const selectedEmployee = employeeList.find(emp => emp.emp_code === value);
        if (selectedEmployee) {
          updatedFormData.name = selectedEmployee.name;
        } else {
          updatedFormData.name = "";
        }
      }

      return updatedFormData;
    });
  };

  //******************Valedation End*********************** */

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(`${BASE_URL}/wfm/assets/${i.id}/`, formData);

        if (res.status === 200) {
          await getAsset();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        handleErrorToast(err)
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column form-group-selectt">
                <label htmlFor="emp_code" className="form-labels  font-weight500    font-size-subheading">
                  Employee<span className="required">*</span>
                </label>
                <br />
                <select
                  id="emp_code"
                  name="emp_code"
                  value={formData.emp_code}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.emp_code ? "error" : inputState.emp_code ? "success" : ""
                    }`}
                >
                  <option value="">Select Employee</option>
                  {employeeList.map((i) => (<>
                    <option value={i.emp_code}>{i.emp_code}-{i.name}</option>
                  </>))
                  }
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.emp_code && (
                    <span className="error-message font-size-text ">{errors.emp_code}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
                  Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  placeholder="Date"
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input ${errors.date ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.date && (
                  <span className="error-message font-size-text ">{errors.date}</span>
                )}
              </div>


              <div className="flex-column">
                <label htmlFor="item" className="form-labels  font-weight500    font-size-subheading">
                  Item<span className="required">*</span>
                </label>
                <input
                  type="item"
                  id="item"
                  name="item"
                  placeholder="Name of the item"
                  onChange={handleInputChange}
                  value={formData.item}
                  className={`form-input ${errors.item ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.item && (
                  <span className="error-message font-size-text ">{errors.item}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="description" className="form-labels  font-weight500    font-size-subheading">
                  Description<span className="required">*</span>
                </label>
                <input
                  type="description"
                  id="description"
                  name="description"
                  placeholder="Detail of the Item"
                  onChange={handleInputChange}
                  value={formData.description}
                  className={`form-input ${errors.description
                    ? "error"
                    : inputState.tile
                      ? "success"
                      : ""
                    }`}
                />
                {errors.description && (
                  <span className="error-message font-size-text ">{errors.description}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                  Quantity<span className="required">*</span>
                </label>
                <input
                  type="number"
                  min={0}
                  id="quantity"
                  name="quantity"
                  placeholder="Quantity"
                  onChange={handleInputChange}
                  value={formData.quantity}
                  className={`form-input ${errors.quantity ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.quantity && (
                  <span className="error-message font-size-text ">{errors.quantity}</span>
                )}
              </div>



              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const AssetModelDelete = ({ i, getAsset }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/assets/${i.id}/`);

      if (res.status === 200) {
        await getAsset();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Asset Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

          <div className="parent-div">
            Are You Sure You Want to delete
            <br />Asset detail of : {i.item}
            <br />given to : {i.emp_code}-{i.name}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const Assettable = () => {
  const [assetname, setAssetname] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getAsset = async () => {
    try {
      setBuffering(true); //buffering logic // Start Buffering
      const res = await axios.get(`${BASE_URL}/wfm/assets/`);
      setAssetname(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };



  const [empList, setEmpList] = useState([])
  const getAllEmp_code = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem('company_id')}/`
      );

      const sortedList = customSortByKey(res.data, "emp_code");

      setEmpList(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  useEffect(() => {
    getAsset();
    getAllEmp_code();
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Asset</div>
          <div>
            <AssetModel getAsset={getAsset} employeeList={empList} />
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S.No.</th>
              <th className="align-left">Emp.Code</th>
              <th className="align-leftt">Name</th>
              <th className="align-center">Date</th>
              <th className="align-center">Discription</th>
              <th className="align-center">Quantity</th>
              <th className="align-center">Item</th>
              <th className="align-right width-10vw">Action</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {assetname.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-leftt">{i.emp_code}</td>
                  <td className="align-leftt">{i.name}</td>
                  <td className="align-center">{i.date}</td>
                  <td className="align-center">{i.description}</td>
                  <td className="align-center">{i.quantity}</td>
                  <td className="align-center">{i.item}</td>
                  <td className="align-right width-10vw ">
                    <AssetModelUpdate i={i} getAsset={getAsset} employeeList={empList} />
                    <AssetModelDelete i={i} getAsset={getAsset} />
                  </td>
                </tr>
                </React.Fragment>
              ))}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

export default Assettable;
