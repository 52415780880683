import React, { useState, useEffect } from "react";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
// import Calaneder from "../images/desk.svg";
import {
  AddPerformance,
  PerformanceDelete,
  PerformanceUpdate,
} from "../PerformanceManagement/Performancemanagement";
import TaskStatsArc from "../PerformanceManagement/TaskStatsArc";
import { formattedDate, formattedDateNoYear } from "../Date";
import { handleErrorToast } from "../CustomFunctions";

const PerformanceManagement = () => {
  const [toggleState, setToggleState] = useState(1);

  const [annoucncementname, setAnnouncementname] = useState([]);

  const getAnnouncement = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/settings/informationboardAPI/`);
      setAnnouncementname(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [eventename, setNamename] = useState([]);
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 100 },
  ];
  const getperformance = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/settings/eventAPI/`);
      setNamename(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getperformance();
  }, []);

  const [taskHistory, setTaskHistory] = useState([]);
  const getTaskHistory = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/taskbyemp/${sessionStorage.getItem("emp_code")}/`
      );
      setTaskHistory(res.data);
    } catch (err) {
      // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
      // alert(err.message);
    }
  };
  useEffect(() => {
    getTaskHistory();
  }, []);

  const handlePending = async (e, id) => {
    e.preventDefault();
    try {
      let res = await axios.put(`${BASE_URL}/wfm/task/${id}/`, {
        status: "inprocess",
        // resume_list_action: false,
      });

      if (res.status === 200) {
        await getTaskHistory();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleAccepted = async (e, id) => {
    e.preventDefault();
    try {
      let res = await axios.put(`${BASE_URL}/wfm/task/${id}/`, {
        status: "completed",
        // resume_list_action: true,
        accept_date: formattedDate,
      });

      if (res.status === 200) {
        await getTaskHistory();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleCompletedClose = async (e, id) => {
    e.preventDefault();
    try {
      let res = await axios.put(`${BASE_URL}/wfm/task/${id}/`, {
        status: "close",
        // close_date: formattedDatee,
      });

      if (res.status === 200) {
        await getTaskHistory();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };
  const handleCompletedReassign = async (e, id) => {
    e.preventDefault();
    try {
      let res = await axios.put(`${BASE_URL}/wfm/task/${id}/`, {
        status: "pending",
        // resume_list_action: false,
      });

      if (res.status === 200) {
        await getTaskHistory();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  const createMarkup = (content) => {
    const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
    return {
      __html: content.replace(linkRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }),
    };
  };

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, "0");

  let previousYear = currentYear;
  let previousMonth = today.getMonth();

  if (previousMonth === 0) {
    previousYear--;
    previousMonth = 12;
  }

  const [newLearning, setNewLearning] = useState([]);
  // const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    const getEventData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/settings/eventAPI/`);
        setNewLearning(res.data);
      } catch (err) {
        console.error(err.message);
      }
    };
    getEventData();
  }, []);
  const [empData, setEmpData] = useState({});

  const getJobapppdata = async (selectedOption) => {
    try {
      const res = await axios.get(
        selectedOption === "this month"
          ? `${BASE_URL}/wfm/employeestatus/${currentYear}/${currentMonth}/`
          : `${BASE_URL}/wfm/employeestatus/${previousYear}/${previousMonth}/`
      );

      setEmpData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };
  const [attendancearc, setAttendancearc] = useState({});
  const getJobapppdata1 = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/pendingitems/${sessionStorage.getItem("emp_code")}/`
      );
      setAttendancearc(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  useEffect(() => {
    getJobapppdata1();
  }, []);

  return (
    <div>
      <Dashboardnavbarcopy
        name={"Develoment Planning"}
        url="Performence Management"
      />
      <div className="content-tabs">
        {/* <div className="bloc-tabss  wfm-margin">
          <div className=" dashboardcomponent-active-tabs">
            Develoment Planning
          </div>
        </div> */}

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Task Statistics </div>
            <div className="radialchart-dropdown">
              <select style={{ border: "none" }}>
                <option value="this month">This Month</option>
                <option value="last month">Last Month</option>
                <option value="last month">This Year</option>
                <option value="last month">Last Year</option>
              </select>
            </div>
          </div>
          <div className="taskstats">
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="repo-heading-a font-weight500   font-size-subheading"></div>

            </div> */}

            <div
              className="empData-felx2"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <TaskStatsArc />
              <div className="empData-felx2">
                <div className="empData-dashboard">
                  <div>
                    <div className="task-tab width-15vw Color-tab-green">
                      <div className="task-Tab-value">
                        {attendancearc.tasks_count_total -
                          attendancearc.tasks -
                          attendancearc.tasks_close}{" "}
                      </div>
                      <div className="task-Tab-heading   font-weight500    font-size-heading">Completed</div>
                    </div>
                  </div>
                </div>

                <div className="empData-dashboard">
                  <div>
                    <div className="task-tab width-15vw Color-tab-yellow">
                      <div className="task-Tab-value">
                        {attendancearc.tasks}
                      </div>
                      <div className="task-Tab-heading   font-weight500    font-size-heading">Pending</div>
                    </div>
                  </div>
                </div>

                <div className="empData-dashboard">
                  <div>
                    <div className="task-tab width-15vw Color-tab-red">
                      <div className="task-Tab-value">
                        {attendancearc.tasks_close}
                      </div>
                      <div className="task-Tab-heading   font-weight500    font-size-heading">Closed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* **************************************************************** */}
        <br />
        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Task Assign History</div>
          </div>
          {/* <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr no.</th>
                <th className="align-center">Taks Name</th>
                <th className="align-center">Date</th>
                <th className="align-center">From Assign</th>
                <th className="align-center">Feedback</th>
                <th className="align-center">Action</th>
              </tr>
            </thead>
          </table> */}
          <div className="dashcomp-task-cards birthdaywrap  ">
            <table className="table-css">
              <thead>
                <tr className="custom-table-head-tr">
                  <th className="align-left">Sr no.</th>
                  <th className="align-center">Taks Name</th>
                  <th className="align-center">Date</th>
                  <th className="align-center">From Assign</th>
                  <th className="align-center">Feedback</th>
                  <th className="align-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {taskHistory.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="7"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        <div dangerouslySetInnerHTML={createMarkup(i.task)} />
                      </td>
                      <td className="align-center">
                        {formattedDateNoYear(i.due_date)}
                      </td>
                      <td className="align-center">
                        {i.assignedby}-{i.assignedbyname}
                      </td>
                      <td className="align-center">{i.status}</td>
                      <td className="align-right width-5vw ">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {i.status === "pending" &&
                            i.concern_person ===
                            sessionStorage.getItem("emp_code") ? (
                            <>
                              <button
                                title="Accept"
                                className="model-edit-button"
                                onClick={(e) => handlePending(e, i.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M15 4.5L6.75 12.75L3 9"
                                    stroke="#145650"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </>
                          ) : i.status === "inprocess" &&
                            i.concern_person ===
                            sessionStorage.getItem("emp_code") ? (
                            <>
                              <button
                                title="Complete"
                                className="model-delete-button"
                                onClick={(e) => handleAccepted(e, i.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M15 4.5L6.75 12.75L3 9"
                                    stroke="#F72B50"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </>
                          ) : i.status === "completed" &&
                            i.assignedby ===
                            sessionStorage.getItem("emp_code") ? (
                            <>
                              <button
                                title="Reassign"
                                className="model-edit-button"
                                onClick={(e) =>
                                  handleCompletedReassign(e, i.id)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 100 100"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M50 18.75C56.754 18.7526 63.3254 20.9433 68.73 24.994C74.1345 29.0447 78.0812 34.7373 79.979 41.2192C81.8767 47.7012 81.6234 54.6235 79.257 60.9494C76.8905 67.2752 72.5382 72.6642 66.8521 76.309C61.166 79.9538 54.452 81.6584 47.7159 81.1673C40.9797 80.6762 34.5839 78.016 29.4865 73.585C24.3891 69.1541 20.8645 63.1909 19.4404 56.5887C18.0164 49.9865 18.7697 43.1007 21.5875 36.9625C21.8942 36.2162 21.9004 35.3802 21.6047 34.6295C21.3091 33.8788 20.7345 33.2715 20.0013 32.9348C19.2681 32.5981 18.433 32.558 17.6709 32.8229C16.9088 33.0879 16.2787 33.6373 15.9125 34.3563C12.5313 41.7224 11.6277 49.9858 13.3371 57.9086C15.0464 65.8315 19.2767 72.9872 25.3942 78.3041C31.5118 83.6209 39.1873 86.8126 47.271 87.401C55.3547 87.9895 63.4115 85.9431 70.2346 81.5682C77.0576 77.1934 82.2796 70.7258 85.1184 63.134C87.9571 55.5423 88.2598 47.2351 85.981 39.4569C83.7023 31.6788 78.9648 24.8482 72.4782 19.9885C65.9916 15.1287 58.1051 12.5014 50 12.5V18.75Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M50 27.9125V3.3375C49.9999 3.0406 49.9152 2.74988 49.7558 2.49938C49.5965 2.24888 49.369 2.04897 49.1001 1.92306C48.8313 1.79715 48.5321 1.75044 48.2376 1.78842C47.9432 1.8264 47.6656 1.94749 47.4375 2.1375L32.6875 14.425C32.5117 14.5716 32.3702 14.7551 32.2731 14.9625C32.176 15.1699 32.1257 15.396 32.1257 15.625C32.1257 15.854 32.176 16.0801 32.2731 16.2875C32.3702 16.4949 32.5117 16.6784 32.6875 16.825L47.4375 29.1125C47.6656 29.3025 47.9432 29.4236 48.2376 29.4616C48.5321 29.4996 48.8313 29.4529 49.1001 29.3269C49.369 29.201 49.5965 29.0011 49.7558 28.7506C49.9152 28.5001 49.9999 28.2094 50 27.9125Z"
                                    fill="black"
                                  />
                                </svg>
                              </button>
                            </>
                          ) : i.status === "close" ? (
                            i.assignedby ===
                              sessionStorage.getItem("emp_code") ? (
                              <>
                                <button
                                  className="model-edit-button"
                                  onClick={(e) =>
                                    handleCompletedReassign(e, i.id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 100 100"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M50 18.75C56.754 18.7526 63.3254 20.9433 68.73 24.994C74.1345 29.0447 78.0812 34.7373 79.979 41.2192C81.8767 47.7012 81.6234 54.6235 79.257 60.9494C76.8905 67.2752 72.5382 72.6642 66.8521 76.309C61.166 79.9538 54.452 81.6584 47.7159 81.1673C40.9797 80.6762 34.5839 78.016 29.4865 73.585C24.3891 69.1541 20.8645 63.1909 19.4404 56.5887C18.0164 49.9865 18.7697 43.1007 21.5875 36.9625C21.8942 36.2162 21.9004 35.3802 21.6047 34.6295C21.3091 33.8788 20.7345 33.2715 20.0013 32.9348C19.2681 32.5981 18.433 32.558 17.6709 32.8229C16.9088 33.0879 16.2787 33.6373 15.9125 34.3563C12.5313 41.7224 11.6277 49.9858 13.3371 57.9086C15.0464 65.8315 19.2767 72.9872 25.3942 78.3041C31.5118 83.6209 39.1873 86.8126 47.271 87.401C55.3547 87.9895 63.4115 85.9431 70.2346 81.5682C77.0576 77.1934 82.2796 70.7258 85.1184 63.134C87.9571 55.5423 88.2598 47.2351 85.981 39.4569C83.7023 31.6788 78.9648 24.8482 72.4782 19.9885C65.9916 15.1287 58.1051 12.5014 50 12.5V18.75Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M50 27.9125V3.3375C49.9999 3.0406 49.9152 2.74988 49.7558 2.49938C49.5965 2.24888 49.369 2.04897 49.1001 1.92306C48.8313 1.79715 48.5321 1.75044 48.2376 1.78842C47.9432 1.8264 47.6656 1.94749 47.4375 2.1375L32.6875 14.425C32.5117 14.5716 32.3702 14.7551 32.2731 14.9625C32.176 15.1699 32.1257 15.396 32.1257 15.625C32.1257 15.854 32.176 16.0801 32.2731 16.2875C32.3702 16.4949 32.5117 16.6784 32.6875 16.825L47.4375 29.1125C47.6656 29.3025 47.9432 29.4236 48.2376 29.4616C48.5321 29.4996 48.8313 29.4529 49.1001 29.3269C49.369 29.201 49.5965 29.0011 49.7558 28.7506C49.9152 28.5001 49.9999 28.2094 50 27.9125Z"
                                      fill="black"
                                    />
                                  </svg>
                                </button>
                              </>
                            ) : (
                              "Closed"
                            )
                          ) : (
                            "waiting..."
                          )}
                          {i.status !== "close" &&
                            i.assignedby ===
                            sessionStorage.getItem("emp_code") ? (
                            <>
                              <button
                                title="Close"
                                className="model-delete-button"
                                onClick={(e) => handleCompletedClose(e, i.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M13.5 4.5L4.5 13.5"
                                    stroke="#F72B50"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4.5 4.5L13.5 13.5"
                                    stroke="#F72B50"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* ******************************************* */}

        <br />
        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">New Learning</div>
            <div className="repo-heading font-weight500    font-size-heading">
              Add New Learning{" "}
              <AddPerformance getperformance={getperformance} />
            </div>
          </div>

          <div className="dashcomp-task-cards birthdaywrap EmployeeDashboad-notification-cards ">
            {newLearning.map((data, index) => {
              console.log(data);
              const formattedEndDate = new Date(data.date).toLocaleString(
                "en-US",
                {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                }
              );

              return (
                <>
                  {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                            <Modal.Body>
                                <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                    <img src={selectedImage} alt="profile" className='dashcomp-imagee width-5vw' />
                                </div>
                            </Modal.Body>
                        </Modal> */}
                  <div key={index} className="dashcomp-bg-card">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ alignItems: "center" }}>
                        <img
                          src={
                            `${data.file}`
                              ? `${data.file}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profile"
                          className="dashcomp-event-image2"
                        // onClick={() => handleShow(data.profilepic)}
                        />
                        <div className="performance-div">
                          <h3
                            style={{ flex: 3 }}
                            className="dashcomp-announcement-heading  font-weight500   "
                          >
                            {index + 1}. {data.title}
                          </h3>
                          <p className="dashcomp-announcement-p font-weight400  font-size-text">
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                data.description
                              )}
                            />
                          </p>
                        </div>
                      </div>
                      <div
                        className="performance-button"
                        style={{ display: "flex" }}
                      >
                        <PerformanceUpdate
                          i={data}
                          getperformance={getperformance}
                        />
                        <PerformanceDelete
                          i={data}
                          getperformance={getperformance}
                        />
                      </div>
                    </div>
                    {/* <br /> */}

                    {/* <br /> */}
                    {/* <img
                      src={
                        `${data.file}`
                          ? `${data.file}`
                          : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      }
                      alt="profile"
                      className="dashcomp-event-image"
                      // onClick={() => handleShow(data.profilepic)}
                    /> */}
                    <br />

                    {/* <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.designation}</p>
                            <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.department}</p> */}
                    <br />
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* ************************************************************************************** */}
        <br />

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Talent Identification</div>
            <div>
              <div className="tab-search-long ">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4.3"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                    />
                    <line
                      x1="10.0101"
                      y1="11"
                      x2="8"
                      y2="8.98995"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <input
                    style={{ width: "250px" }}
                    placeholder="Name/Department/Designation "
                    type="text"
                    // value={selectedName}
                    className="navbar-input font-weight400 font-size-text "
                  // onChange={(e) => setName(e.target.value)}
                  // value={searchQuery}
                  // onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr no.</th>
                <th className="align-center">ID</th>
                <th className="align-center">Employee name</th>
                <th className="align-center">Role</th>
                <th className="align-center">Skills</th>
              </tr>
            </thead>
            <tbody>
              {annoucncementname.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.date}</td>
                    <td className="align-center">{i.title}</td>
                    <td className="align-center">{i.description}</td>
                    <td className="align-center">{i.description}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PerformanceManagement;
