import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { formatDate, formatDateDDMMYYYY, formattedDate, formattedDateLong } from "../Date";
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { ToastContainer, toast } from "react-toastify";
import { customSortByKey, downloadAsExcel, formatCurrencyIndian, handleAllError } from "../CustomFunctions";
import { Calender, CircularGrid, DownloadIcon, Employee, Eye, Office, Search, SearchBig } from "../AllSvg";
import { UploadSalaryDataBulk } from "../UserDetails/userDetails-comp/SalaryStructureComponents";
import usePermission from "../../config/permissions";

const RunPayRoll = ({ month, year }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    try {
      let res = await axios.post(
        `${BASE_URL}/wfm/salaryslipallpost/${year}/${month}/`
      );

      if (res.status === 200) {
        handleClose();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false); //loading logic
    }
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[month - 1];

  return (
    <>
      <button
        className="model-view-button model-add"
        title="Run Pay Roll"
        onClick={handleShow}
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="payroll"
        >
          <g>
            <path d="M72.36,492.088a13.5,13.5,0,0,0,14.3-1.8l32.079-26.753,38.644,31.19.093.072a13.438,13.438,0,0,0,16.669-.039l.095-.076,38.2-31.17,38.2,31.17.094.076a13.438,13.438,0,0,0,16.668.039l.092-.072,38.64-31.191,32.072,26.753h0a13.608,13.608,0,0,0,14.371,1.8A12.526,12.526,0,0,0,360,480.775V428.44l21.848,18.291a13.246,13.246,0,0,0,8.516,3.074,12.576,12.576,0,0,0,5.517-1.275A12.248,12.248,0,0,0,403,437.217V384.883l22.127,18.291a13.623,13.623,0,0,0,8.656,3.074,13.839,13.839,0,0,0,5.807-1.275A12.519,12.519,0,0,0,447,393.66V49.843C447,30.264,429.723,14,408.542,14H190.573C169.392,14,152,30.264,152,49.843V58h-4.984C125.834,58,109,73.821,109,93.4V101h-5.542C82.277,101,65,117.378,65,136.958V480.775A12.473,12.473,0,0,0,72.36,492.088ZM168,49.843C168,39.086,178.214,30,190.573,30H408.542C420.9,30,431,39.086,431,49.843V387.034l-28-22.986V93.4C403,73.821,386.166,58,364.984,58H168ZM125,93.4c0-10.757,9.657-19.4,22.016-19.4H364.984C377.343,74,387,82.643,387,93.4V430.592L360,407.6V136.958C360,117.378,342.608,101,321.427,101H125ZM81,136.958C81,126.2,91.1,117,103.458,117H321.427C333.786,117,344,126.2,344,136.958V474.149l-29.193-24.285c-.033-.027-.105-.054-.138-.081a13.441,13.441,0,0,0-16.791-.125c-.031.025-.072.049-.1.073l-38.665,31.207L220.9,449.76l-.1-.077a13.428,13.428,0,0,0-16.716,0l-.095.077-38.21,31.178-38.66-31.207-.092-.073a13.411,13.411,0,0,0-16.771.125c-.033.027-.088.054-.121.081L81,474.149Z"></path>
            <path d="M149.586 404H275.3a8 8 0 0 0 0-16H149.586a8 8 0 0 0 0 16zM212.443 314.73a28 28 0 0 1-27.967-27.966 8 8 0 0 0-16 0A44.038 44.038 0 0 0 202 329.467V344a8 8 0 0 0 16 0V330.367a43.961 43.961 0 0 0-5.557-87.57 27.964 27.964 0 0 1-2.621-55.806c.06 0 .118.009.178.009a7.989 7.989 0 0 0 1.236-.1c.4-.017.8-.031 1.207-.031a28 28 0 0 1 27.966 27.967 8 8 0 1 0 16 0A44.023 44.023 0 0 0 218 171.228V159a8 8 0 0 0-16 0v13.128A43.963 43.963 0 0 0 212.443 258.8a27.967 27.967 0 0 1 0 55.933z"></path>
          </g>
        </svg>
      </button>

      <Modal show={show} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h3 className="task-Tab-heading  font-weight500    font-size-heading">
                Run Pay Rolls for "{monthName}-{year}"
              </h3>
              <p style={{ textAlign: "center" }}>
                Confirm Running Pay Roll for the year: {year}, month:{" "}
                {monthName}
              </p>

              <div className="button-models">
                <button
                  className="model-button model-button-cancel font-weight500   "
                  onClick={handleClose}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button  font-weight500    model-button-leave font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};



const PayRun = () => {
  const [designationname, setDesignationname] = useState([]);
  const [departmentname, setDepartmentname] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const [buffer, setBuffering] = useState(false);
  const [monthlylist, setMonthlylist] = useState([]);
  const [monthlylistfilter, setMonthlylistfilter] = useState([]);
  const [salaryHeadings, setSalaryHeadings] = useState([]);
  const [toggleSubComanpy, setToggleSubComanpy] = useState(sessionStorage.getItem("company_id"));

  const getDesignation = async () => {
    try {
      const des = await axios.get(`${BASE_URL}/wfm/designation/`);
      const dep = await axios.get(`${BASE_URL}/wfm/department/`);
      setDesignationname(des.data);
      setDepartmentname(dep.data);
    } catch (err) {
      console.error("Error fetching designations/departments", err);
    }
  };

  const getSalarylist = async () => {
    setBuffering(true);
    try {
      const res = await axios.get(`${BASE_URL}/wfm/salaryslipall/${yearr}/${monthh}/${toggleSubComanpy}/`);
      const sortedList = customSortByKey(res.data, "emp_code");
      setMonthlylist(sortedList);

      if (sortedList.length > 0) {
        setSalaryHeadings(sortedList[0].deduction_details.map(d => d.name));
      }
    } catch (err) {
      handleAllError(err)
    } finally {
      setBuffering(false);
    }
  };

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

  const [monthh, setMonthh] = useState(`${month}`);
  const [yearr, setYearr] = useState(`${year}`);

  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [selectedDivision, setSelectedDivision] = useState(sessionStorage.getItem("company"));

  const applyFilters = () => {
    let filteredData = monthlylist;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        const codeMatch = employee.emp_code
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return nameMatch || codeMatch;
      });
    }

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.department === selectedDep
      );
    }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.position
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }

    setMonthlylistfilter(filteredData);
  };

  useEffect(() => {
    getDesignation();
    getSalarylist();
  }, [monthh, yearr, toggleSubComanpy]);

  useEffect(() => {
    applyFilters();
  }, [selectedName, selectedDep, selectedDesi, monthlylist]);

  // const navigate = useNavigate();

  // const handleViewSlip = (emp_code, month, year) => {
  //   navigate(`/salarySlip/${year}/${month}/${emp_code}`);
  // };

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + "/salarySlip";
  const salarySlip = (emp_code, month, year) => {
    navigate(baseUrl + `/${year}` + `/${month}` + `/${emp_code}/`);
  };

  const OneSalarySlip = (i, monthh, yearr) => {
    navigate(baseUrl, { state: { i, monthh, yearr } });
  };
  const tableRef = useRef(null);
  const handleDownloadPayRunSheet = () => {
    downloadAsExcel(tableRef, "users", selectedDivision === "" ? `PayRunSheet - ${monthh} - ${yearr}` : `${selectedDivision} - PayRunSheet - ${monthh} - ${yearr}`);
  };

  // const handleSelectChange = (e) => {
  //   const selectedOption = SubCompany.find(i => i.id === e.target.value);
  //   if (selectedOption) {
  //     setToggleSubComanpy(selectedOption.id);
  //     setSelectedDivision(selectedOption.title);
  //   }
  // };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "null") {
      setToggleSubComanpy(null);
      setSelectedDivision(null);
    } else {
      const selectedOption = SUB_COMPANIES.find(i => i.id === selectedValue);
      if (selectedOption) {
        setToggleSubComanpy(selectedOption.id);
        setSelectedDivision(selectedOption.title);
      }
    }
  };

  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              >
                <option value=""> All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.title}>{i.title}</option>
                  ))}
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Designation"
                type="text"
                value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            {/* <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={toggleSubComanpy}
                onChange={(e) => setToggleSubComanpy(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
              </select>
            </div> */}

            {PERMISSION_AUTHORITY ?
              <div className="field-cont-div">
                <Office />
                <select
                  className="attendance-input-field width-10vw date-field"
                  type="text"
                  value={toggleSubComanpy}
                  onChange={handleSelectChange}
                >
                  {/* <option value="null">All Sub Companies</option> */}
                  {SUB_COMPANIES.sort((a, b) => a.title.localeCompare(b.title)).map((i, index) => (
                    <option key={index} value={i.id}>{i.title}</option>
                  ))}
                </select>
              </div>
              : null}
          </div>
          <div className="btn-cont">
            <UploadSalaryDataBulk getPayrunlist={getSalarylist} />
            <button className=" model-button-print" title="" onClick={handleDownloadPayRunSheet}>
              <DownloadIcon />
            </button>
          </div>
        </div>
      </div >

      <div className="custom-table-width">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Pay Run
          </div>
          {/* <RunPayRoll year={yearr} month={monthh} /> */}
        </div>
        {/* <div className="global-whitebg-table"> */}
        <div className="scrollfix-wrapper">
          <table ref={tableRef}

            className="scrollfix-table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Emp Code</th>
                <th className="align-center">Name</th>
                <th className="align-center">Department</th>
                <th className="align-center">Designation</th>
                <th className="align-center">Pay Days</th>
                {salaryHeadings.map((heading, idx) => (
                  <th key={idx} className="align-center width-5vw">{heading}</th>
                ))}
                {/* <th className="align-center">View</th> */}
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> :
              <tbody>
                {monthlylistfilter.map((employee, index) => (
                  <tr key={index}>
                    <td className="align-left">{employee.emp_code}</td>
                    <td className="align-center">{employee.name || '-'}</td>
                    <td className="align-center">{employee.department || '-'}</td>
                    <td className="align-center">{employee.position || '-'}</td>
                    {/* <td className="align-center">{employee.total_paid_days || '-'}/{employee.total_days || '-'}</td> */}
                    <td className="align-center" title={`${employee.total_paid_days || '-'}/${employee.total_days || '-'}`}>{employee.total_paid_days || '-'}</td>
                    {salaryHeadings.sort((a, b) => a.sorting_var - b.sorting_var).map((heading, idx) => {
                      const deduction = employee.deduction_details.find(d => d.name === heading);
                      return (
                        <td key={idx} className="align-center width-5vw">
                          {deduction && typeof deduction.actual_amount === 'number'
                            // ? deduction.actual_amount.toFixed(2)
                            ? formatCurrencyIndian(deduction.actual_amount.toFixed(2))
                            : '-'}
                        </td>
                      );
                    })}

                    {/* <td className="align-center">
                        <button
                          className="allproject-dashboard"
                          title="View using Navigate"
                          onClick={(e) => OneSalarySlip(employee, monthh, yearr)}
                        >
                          <Eye />
                        </button>
                      </td> */}
                  </tr>
                ))}
              </tbody>
            }
          </table>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};


const MonthlySalary = () => {
  const [designationname, setDesignationname] = useState([]);
  const [departmentname, setDepartmentname] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic

  const getDesignation = async () => {
    // setBuffering(true); //buffering logic
    try {
      const des = await axios.get(`${BASE_URL}/wfm/designation/`);
      const dep = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(des.data);
      setDepartmentname(dep.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [yearr, setYearr] = useState(`${year}`);
  const [monthlylist, setMonthlylist] = useState([]);
  const [monthlylistfilter, setMonthlylistfilter] = useState([]);

  const [toggleState, setToggleState] = useState("pending/");
  // const [searchQuery, setSearchQuery] = useState("");

  const getSalarylist = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/monthlyformat/${yearr}/${monthh}`
      );
      setMonthlylist(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };
  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );

  const applyFilters = () => {
    let filteredData = monthlylist;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.salary.employee.name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return nameMatch;
      });
    }

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.salary.employee.department_name === selectedDep
      );
    }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();
      console.log("filteredData");
      console.log(filteredData);
      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.salary.employee.designation_name
          .toLowerCase()
          .includes(lowercaseSelectedName);

        // const desiMatch = employee.position_name.toLowerCase().includes(lowercaseSelectedName);
        return desiMatch;
      });
    }
    if (selectedDivision) {
      filteredData = filteredData.filter(
        (employee) =>
          employee.salary.employee?.division_name === selectedDivision
      );
    }

    setMonthlylistfilter(filteredData);
  };

  useEffect(() => {
    getSubCompany();
    getSalarylist();
  }, [monthh, yearr]);

  useEffect(() => {
    applyFilters();
  }, [selectedName, selectedDep, selectedDesi, monthlylist, selectedDivision]);

  // ?  ************** Put Request Start **************  ! //

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/salarySlip`;
  const salarySlip = (emp_code, month, year) => {
    navigate(baseUrl + `/${year}` + `/${month}` + `/${emp_code}/`);
  };

  const OneSalarySlip = (i, monthh, yearr) => {
    navigate(baseUrl, { state: { i, monthh, yearr } });
  };

  const tableRef = useRef(null);
  const handleDownloadPayMonthSheet = () => {
    downloadAsExcel(tableRef, "users", selectedDivision === "" ? `PayRunSheet-${monthh}-${yearr}` : `${selectedDivision}-PayRunSheet-${monthh}-${yearr}`);
  };

  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw   date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              >
                <option value=""> All Department</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.title}>{i.title}</option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Designation"
                type="text"
                value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Monthly Salary List
            {/* <p className='career-para font-size-subheading">Based your performance</p>          */}
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S.No.</th>
              <th className="align-center">Emp Code</th>
              <th className="align-center">Name</th>
              <th className="align-center">Department</th>
              <th className="align-center">Position</th>
              <th className="align-center">Total Days</th>
              <th className="align-center">Total Salary</th>
              {/* <th className="align-center">View</th> */}
              {/* <th className="align-center">Action</th> */}
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic

            <tbody>
              {monthlylistfilter
                // .filter((e) =>
                //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
                // )
                // .sort((a, b) => Date(a.date) - Date(b.date))
                .map((i, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="6"></td>
                      </tr>
                      <tr className="custom-table-head-tdd">
                        <td className="align-left">{index + 1}</td>
                        <td className="align-center">
                          {i.salary.bank_details?.user}
                        </td>
                        <td className="align-center">{i.salary.name}</td>
                        <td className="align-center">
                          {i.salary.employee.department_name}
                        </td>
                        <td className="align-center">
                          {i.salary.employee.designation_name}
                        </td>
                        <td className="align-center">{i.calculation}</td>
                        <td className="align-center">{i.salary.salarypay}</td>
                        <td className="align-center">
                          {/* <a
                                                    href={`${FRONTEND_URL}/salarySlip/${yearr}/${monthh}/${i.user_detail}/`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="View using href"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />
                                                    </svg>
                                                </a> */}

                          {/* <button className="allproject-dashboard" title="View using Navigate" onClick={(e) => OneSalarySlip(i, monthh, yearr)}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none">

                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />

                                                    </svg>
                                                </button> */}
                        </td>
                        {/* <td className="align-center">
                                                Action
                                            </td> */}
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

const EmployeeDetails = () => {
  const [designationname, setDesignationname] = useState([]);

  const [departmentname, setDepartmentname] = useState([]);
  const [selectedRemark, setRemark] = useState("");
  const [buffer, setBuffering] = useState(false); //buffering logic

  const [emp_codes, setEmp_codes] = useState([]);
  const getDesignation = async () => {
    // setBuffering(true); //buffering logic
    try {
      const des = await axios.get(`${BASE_URL}/wfm/designation/`);
      const dep = await axios.get(`${BASE_URL}/wfm/department/`);
      const emp = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/active/${sessionStorage.getItem('company_id')}/`);

      setDesignationname(des.data);
      setDepartmentname(dep.data);

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: '', numericPart: 0 };
        }
      };

      const sortedList = emp.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } = getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } = getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setEmp_codes(sortedList);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const [employeeDetailed, setemployeeDetailed] = useState([]);
  const [employeeDetailedfilter, setemployeeDetailedfilter] = useState([]);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const month1 = today.getMonth() - 6;
  const month2 = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  const [monthStart, setStartMonth] = useState(month1 > 0 ? `${month1}` : `1`);
  const [monthEnd, setEndMonth] = useState(`${month2}`);
  const [yearr, setYearr] = useState(`${year}`);
  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [monthlylist, setMonthlylist] = useState([]);
  const [monthlylistfilter, setMonthlylistfilter] = useState([]);

  const [selectedEmpcode, setSelectedEmpcode] = useState(
    `${sessionStorage.getItem("emp_code")}`
  );

  const [toggleState, setToggleState] = useState("pending/");
  // const [searchQuery, setSearchQuery] = useState("");
  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };
  const getSalarylist = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeewiseformat/${selectedEmpcode}/`
      );
      setemployeeDetailed(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };


  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );

  const applyFilters = () => {
    let filteredData = employeeDetailed;

    if (yearr) {
      const selectedYear = yearr.toString();

      filteredData = filteredData.filter((employee) => {
        return employee.year === selectedYear;
      });
    }

    if (monthStart && monthEnd) {
      const startMonth = monthStart;
      const endMonth = monthEnd;

      filteredData = filteredData.filter((employee) => {
        const empMonth = Number(employee.month);
        return empMonth >= startMonth && empMonth <= endMonth;
      });
    }

    setemployeeDetailedfilter(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [monthStart, monthEnd, yearr, employeeDetailed]);

  useEffect(() => {
    getSubCompany();
    getSalarylist();
  }, [selectedEmpcode]);


  // ?  ************** Navigate Function **************  ! //

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/salarySlipEmployee`;

  const OneSalarySlip = (i) => {
    navigate(baseUrl, { state: { i } });
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div title="Year Filter" className="field-cont-div">

              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw   date-field"
              />

              <hr className="field-cont-hr" />
            </div>

            <div title="Month Start Filter" className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                value={monthStart}
                max={monthEnd}
                onChange={(e) => setStartMonth(e.target.value)}
              >
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div title="Month End Filter" className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                min={monthStart}
                value={monthEnd}
                onChange={(e) => setEndMonth(e.target.value)}
              >
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div title="Employee Filter" className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={selectedEmpcode}
                onChange={(e) => setSelectedEmpcode(e.target.value)}
              >
                {/* <option value="null">Select Employee Code</option> */}
                {emp_codes
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
            </div>

          </div>
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Employee Details</div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr. No.</th>
              <th className="align-center">Month</th>
              <th className="align-center">Year</th>
              <th className="align-center">Total Pay days</th>
              <th className="align-center">Net Pay</th>
              <th className="align-center ">Salary Receipt</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {employeeDetailedfilter
                // .filter((e) =>
                //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
                // )
                // .sort((a, b) => Date(a.date) - Date(b.date))
                .map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="6"></td>
                      </tr>
                      <tr className="custom-table-head-tdd">
                        <td className="align-left">{index + 1}</td>
                        <td className="align-center">{item.month}</td>
                        <td className="align-center">{item.year}</td>
                        <td className="align-center">{item.calculation}</td>
                        <td className="align-center">{item.salary.salarypay}</td>
                        {/* <td className="align-center">{item.standard_status.stdbasicsalary}</td> */}
                        {/* <td className="align-center">{item.salary.esideduction + item.salary.pfdeduction}</td> */}
                        {/* <td className="align-center">{item.month}</td> */}
                        {/* <td className="align-center">{item.month}</td> */}
                        <td className="align-center">
                          <button
                            className="allproject-dashboard"
                            title="View using Navigate"
                            onClick={(e) => OneSalarySlip(item)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                              <path
                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                fill="#2576BC"
                              />
                            </svg>
                          </button>
                          {/* <img src="" alt="Salary Receipt" /> */}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

const BankFormat = () => {
  const [designationname, setDesignationname] = useState([]);

  const [departmentname, setDepartmentname] = useState([]);
  const [selectedRemark, setRemark] = useState("");
  const [buffer, setBuffering] = useState(false); //buffering logic

  const getDesignation = async () => {
    // setBuffering(true); //buffering logic
    try {
      const des = await axios.get(`${BASE_URL}/wfm/designation/`);
      const dep = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(des.data);
      setDepartmentname(dep.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // console.log(newdate)

  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [yearr, setYearr] = useState(`${year}`);

  const [bankFormat, setbankFormat] = useState([]);
  const [bankFormatfilter, setbankFormatfilter] = useState([]);

  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [selectedFromexp, setFromexp] = useState("");
  const [selectedToexp, setToexp] = useState("");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );

  // const [searchQuery, setSearchQuery] = useState("");

  const getSalarylist = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/bankformat/${yearr}/${monthh}/`
      );
      setbankFormat(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  const applyFilters = () => {
    let filteredData = bankFormat;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        return nameMatch;
      });
    }

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.department_name === selectedDep
      );
    }

    // if (selectedFromexp) {
    //     const fromExpNumber = parseInt(selectedFromexp);
    //     filteredData = filteredData.filter(employee => parseInt(employee.yearofExp) >= fromExpNumber);
    // }

    // if (selectedToexp) {
    //     const toExpNumber = parseInt(selectedToexp);
    //     filteredData = filteredData.filter(employee => parseInt(employee.yearofExp) <= toExpNumber);
    // }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.position_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }
    // if (selectedRemark) {
    //     const lowercaseSelectedRemark = selectedRemark.toLowerCase();

    //     filteredData = filteredData.filter(employee => {
    //         const matchRemark = employee.remarks?.toLowerCase().includes(lowercaseSelectedRemark);

    //         return matchRemark;
    //     });
    // }

    if (selectedDivision) {
      filteredData = filteredData.filter(
        (employee) => employee.employee?.division_name === selectedDivision
      );
    }

    setbankFormatfilter(filteredData);
  };

  useEffect(() => {
    getSubCompany();
    getSalarylist();
  }, [monthh, yearr]);

  useEffect(() => {
    applyFilters();
  }, [
    bankFormat,
    bankFormatfilter,
    selectedName,
    selectedDep,
    selectedDesi,
    selectedFromexp,
    selectedToexp,
    selectedRemark,
    selectedDivision,
  ]);

  // ?  ************** Put Request Start **************  ! //
  const tableRef = useRef(null);
  const handleDownloadBankSheet = () => {
    downloadAsExcel(tableRef, "users", selectedDivision === "" ? `BankFormatSheet-${monthh}-${yearr}` : `${selectedDivision}-BankFormatSheet-${monthh}-${yearr}`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw   date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-15vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

          </div>
          {/* <DownloadTableExcel
            filename={
              selectedDivision === ""
                ? `BankFormatSheet-${monthh}-${yearr}`
                : `${selectedDivision}-BankFormatSheet-${monthh}-${yearr}`
            }
            // filename={`BankFormatSheet-${monthh}-${yearr}`}
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className=" model-button-print">
              <DownloadIcon />
            </button>
          </DownloadTableExcel> */}
          <button className=" model-button-print" onClick={handleDownloadBankSheet}>
            <DownloadIcon />
          </button>
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Bank Format
            {/* <p className='career-para font-size-subheading">Based your performance</p>          */}
          </div>
        </div>
        <table ref={tableRef} className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S.No.</th>
              <th className="align-center">Emp Code</th>
              <th className="align-center">Name</th>
              <th className="align-center">Bank</th>
              <th className="align-center">Account No.</th>
              <th className="align-center">IFSC</th>
              <th className="align-right ">Basic Salary</th>
              {/* <th className="align-center">View</th> */}
              {/* <th className="align-center">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {bankFormatfilter
              // .filter((e) =>
              //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
              // )
              // .sort((a, b) => Date(a.date) - Date(b.date))
              .map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-tdd">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{i.bank_details?.user}</td>
                      <td className="align-center">{i.name}</td>
                      <td className="align-center">
                        {i.bank_details?.bank_name}-
                        {i.bank_details?.branch_address}
                      </td>
                      <td className="align-center">
                        {i.bank_details?.account_number}
                      </td>
                      <td className="align-center">
                        {i.bank_details?.ifsc_code}
                      </td>
                      <td className="align-center">{i.salarypay}</td>
                      {/* Check */}
                      {/* <td className="align-center">
                        {formatDate(i.applied_date)}
                      </td>

                      <td className="align-center">
                        <a
                          href={`${FRONTEND_URL}/salarySlip/${i.user_detail}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="View "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 30 30"
                            fill="none"
                          >
                            <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                            <path
                              d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                              fill="#2576BC"
                            />
                          </svg>
                        </a>
                      </td> */}
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const PrintFormat = () => {
  const [designationname, setDesignationname] = useState([]);
  const [departmentname, setDepartmentname] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const tableRef = useRef(null);

  const getDesignation = async () => {
    // setBuffering(true); //buffering logic

    try {
      const des = await axios.get(`${BASE_URL}/wfm/designation/`);
      const dep = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(des.data);
      setDepartmentname(dep.data);
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [yearr, setYearr] = useState(`${year}`);
  const [monthlylist, setMonthlylist] = useState([]);
  const [monthlylistfilter, setMonthlylistfilter] = useState([]);

  const [toggleState, setToggleState] = useState("pending/");
  // const [searchQuery, setSearchQuery] = useState("");

  const getSalarylist = async () => {
    setBuffering(true); //buffering logic

    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/salaryslipall/${yearr}/${monthh}`
      );
      setMonthlylist(res.data);
    } catch (err) {
      // toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  // const [selectedRH, setRh] = useState('');
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );

  const applyFilters = () => {
    let filteredData = monthlylist;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.user_details.name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return nameMatch;
      });
    }

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.user_details.department_name === selectedDep
      );
    }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.user_details.designation_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }

    // if (selectedRH) {

    //     filteredData = filteredData.filter(employee => employee.rh_assigned === selectedRH);
    // }

    if (selectedDivision) {
      filteredData = filteredData.filter(
        (employee) => employee.user_details?.division_name === selectedDivision
      );
    }

    setMonthlylistfilter(filteredData);
  };

  useEffect(() => {
    getSalarylist();
    getSubCompany();
  }, [monthh, yearr]);

  useEffect(() => {
    applyFilters();
  }, [selectedName, selectedDep, selectedDesi, monthlylist, selectedDivision]);

  // ?  ************** Put Request Start **************  ! //

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/salarySlip`;
  const salarySlip = (emp_code, month, year) => {
    navigate(baseUrl + `/${year}` + `/${month}` + `/${emp_code}/`);
  };

  const OneSalarySlip = (i, monthh, yearr) => {
    navigate(baseUrl, { state: { i, monthh, yearr } });
  };

  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-10vw   date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              >
                <option value=""> All Department</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.title}>{i.title}</option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Designation"
                type="text"
                value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#707070"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#707070 "
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Salary Sheet
            {/* <p className='career-para font-size-subheading">Based your performance</p>*/}
          </div>
          <DownloadTableExcel
            filename={
              selectedDivision === ""
                ? `BankFormatSheet-${monthh}-${yearr}`
                : `${selectedDivision}-BankFormatSheet-${monthh}-${yearr}`
            }
            // filename={`BankFormatSheet-${monthh}-${yearr}`}
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className=" model-button-print">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30" height="30"
                viewBox="0 0 24 24"
                fill='#fff'
                id="print">
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M19 8H5c-1.66 0-3 1.34-3 3v4c0 1.1.9 2 2 2h2v2c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-2h2c1.1 0 2-.9 2-2v-4c0-1.66-1.34-3-3-3zm-4 11H9c-.55 0-1-.45-1-1v-4h8v4c0 .55-.45 1-1 1zm4-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-2-9H7c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1z"></path>
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="22"
                id="download"
                fill="#fff"
              >
                <g
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                </g>
              </svg>
              {/* Download as Excel */}
            </button>
          </DownloadTableExcel>
          {/* <RunPayRoll year={yearr} month={monthh} /> */}
        </div>
        <table ref={tableRef} className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S.No.</th>
              <th className="align-center">Emp Code</th>
              <th className="align-center">Name</th>
              <th className="align-center">Department</th>
              <th className="align-center">Position</th>
              <th className="align-center">Total Days</th>
              <th className="align-center">Total Salary</th>
              <th className="align-center">View</th>
              {/* <th className="align-center">Action</th> */}
            </tr>
          </thead>

          {buffer ? <div className="spinner"></div> : //buffering logic

            <tbody>
              {monthlylistfilter
                // .filter((e) =>
                //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
                // )
                // .sort((a, b) => Date(a.date) - Date(b.date))
                .map((i, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="6"></td>
                      </tr>
                      <tr className="custom-table-head-tdd">
                        <td className="align-left">{index + 1}</td>
                        <td className="align-center">{i.emp_code}</td>
                        <td className="align-center">{i.user_details.name}</td>
                        <td className="align-center">
                          {i.user_details.department_name}
                        </td>
                        <td className="align-center">
                          {i.user_details.designation_name}
                        </td>
                        <td className="align-center">{i.calculation}</td>
                        <td className="align-center">{i.salarypay}</td>
                        <td className="align-center">
                          {/* <a
                                                    href={`${FRONTEND_URL}/salarySlip/${yearr}/${monthh}/${i.emp_code}/`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="View using href"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />
                                                    </svg>
                                                </a> */}

                          {/* <button className="allproject-dashboard" title="View using Navigate" onClick={(e) => salarySlip(i.emp_code, monthh, yearr)}> */}
                          <button
                            className="allproject-dashboard"
                            title="View using Navigate"
                            onClick={(e) => OneSalarySlip(i, monthh, yearr)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                              <path
                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                fill="#2576BC"
                              />
                            </svg>
                          </button>
                        </td>
                        {/* <td className="align-center">
                                                Action
                                            </td> */}
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

const BankSubmitFormat = () => {
  const [designationname, setDesignationname] = useState([]);

  const [departmentname, setDepartmentname] = useState([]);
  const [selectedRemark, setRemark] = useState("");
  const [buffer, setBuffering] = useState(false); //buffering logic

  const getDesignation = async () => {
    // setBuffering(true); //buffering logic
    try {
      const des = await axios.get(`${BASE_URL}/wfm/designation/`);
      const dep = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(des.data);
      setDepartmentname(dep.data);
    } catch (err) {
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthShortNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // console.log(newdate)

  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [yearr, setYearr] = useState(`${year}`);

  const [bankFormat, setbankFormat] = useState([]);
  const [bankFormatfilter, setbankFormatfilter] = useState([]);

  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [selectedFromexp, setFromexp] = useState("");
  const [selectedToexp, setToexp] = useState("");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );

  // const [searchQuery, setSearchQuery] = useState("");

  const getSalarylist = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/bankformat/${yearr}/${monthh}/`
      );
      setbankFormat(res.data);
    } catch (err) {
      handleAllError(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
    }
  };

  const applyFilters = () => {
    let filteredData = bankFormat;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        return nameMatch;
      });
    }

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.department_name === selectedDep
      );
    }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.position_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }

    if (selectedDivision) {
      filteredData = filteredData.filter(
        (employee) => employee.employee?.division_name === selectedDivision
      );
    }

    setbankFormatfilter(filteredData);
  };

  useEffect(() => {
    getSubCompany();
    getSalarylist();
  }, [monthh, yearr]);

  useEffect(() => {
    applyFilters();
  }, [
    bankFormat,
    bankFormatfilter,
    selectedName,
    selectedDep,
    selectedDesi,
    selectedFromexp,
    selectedToexp,
    selectedRemark,
    selectedDivision,
  ]);

  // ?  ************** Put Request Start **************  ! //
  const tableRef = useRef(null);

  // Helper to format date as DDMM
  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adjust for 0-based month
    return `${day}${month}`;
  };

  const customformattedDate = getFormattedDate();

  const handleDownloadBankSheet = () => {
    // downloadAsExcel(tableRef, "users", selectedDivision === "" ? `BankFormatSheet-${monthh}-${yearr}` : `${selectedDivision}-BankFormatSheet-${monthh}-${yearr}`);
    downloadAsExcel(tableRef, "Sheet1", `4051RBI${customformattedDate}.entrycount.xls`);
  };


  const mockData = [
    // Transaction Type 'R' (Salary > 200000)
    {
      name: "Alice Johnson",
      salarypay: 300000,
      bank_details: { bank: "ICICI", account_number: "123456789012", ifsc_code: "ICIC0001234" },
      department_name: "Engineering",
      position_name: "Lead Engineer",
      transaction_date: "2024-09-01",
      email: "alice.johnson@example.com",
    },
    {
      name: "Bob Smith",
      salarypay: 250000,
      bank_details: { bank: "SBI", account_number: "123987654321", ifsc_code: "SBI0002345" },
      department_name: "Marketing",
      position_name: "Marketing Manager",
      transaction_date: "2024-09-02",
      email: "bob.smith@example.com",
    },

    // Transaction Type 'I' (Bank is HDFC)
    {
      name: "Chris Evans",
      salarypay: 150000,
      bank_details: { bank: "HDFC", account_number: "345678901234", ifsc_code: "HDFC0003456" },
      department_name: "Sales",
      position_name: "Sales Executive",
      transaction_date: "2024-09-03",
      email: "chris.evans@example.com",
    },
    {
      name: "Diana Prince",
      salarypay: 180000,
      bank_details: { bank: "HDFC", account_number: "456789012345", ifsc_code: "HDFC0004567" },
      department_name: "Finance",
      position_name: "Finance Specialist",
      transaction_date: "2024-09-04",
      email: "diana.prince@example.com",
    },

    // Transaction Type 'N' (Other Banks and Salary <= 200000)
    {
      name: "Eve Adams",
      salarypay: 120000,
      bank_details: { bank: "Axis Bank", account_number: "567890123456", ifsc_code: "UTIB0005678" },
      department_name: "HR",
      position_name: "HR Coordinator",
      transaction_date: "2024-09-05",
      email: "eve.adams@example.com",
    },
    {
      name: "Frank Lee",
      salarypay: 90000,
      bank_details: { bank: "Canara Bank", account_number: "678901234567", ifsc_code: "CNRB0006789" },
      department_name: "IT",
      position_name: "IT Support",
      transaction_date: "2024-09-06",
      email: "frank.lee@example.com",
    },
  ];


  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw   date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-15vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

          </div>
          {/* <DownloadTableExcel
            filename={
              selectedDivision === ""
                ? `BankFormatSheet-${monthh}-${yearr}`
                : `${selectedDivision}-BankFormatSheet-${monthh}-${yearr}`
            }
            // filename={`BankFormatSheet-${monthh}-${yearr}`}
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className=" model-button-print">
              <DownloadIcon />
            </button>
          </DownloadTableExcel> */}
          <button className=" model-button-print" onClick={handleDownloadBankSheet}>
            <DownloadIcon />
          </button>
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Bank Format
            {/* <p className='career-para font-size-subheading">Based your performance</p>          */}
          </div>
        </div>
        <table ref={tableRef} className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">
                Transaction Type (N – NFET, R – RTGS & I - Fund Transfer in HDFC Bank account)
              </th>
              <th className="align-left">
                Beneficiary Code (Mandatory In case of Fund Transfer only)
              </th>
              <th className="align-left">
                Beneficiary Account Number
              </th>
              <th className="align-left">
                Instrument Amount
              </th>
              <th className="align-left">
                Beneficiary Name (Upto 40 character without any special character)
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Drawee Location
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Print Location
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Bene Address 1
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Bene Address 2
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Bene Address 3
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Bene Address 4
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Bene Address 5
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Instruction Reference Number
              </th>
              <th className="align-left">
                Customer Reference Number(Which needs to be reflected in statement upto character upto 20)
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Payment details 1
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Payment details 2
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Payment details 3
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Payment details 4
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Payment details 5
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Payment details 6
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Payment details 7
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Cheque Number
              </th>
              <th className="align-left">
                Chq / Trn Date (DD/MM/YYYY)
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                MICR Number
              </th>
              <th className="align-left">
                IFSC Code
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Bene Bank Name
              </th>
              <th className="align-left" style={{ display: 'none' }}>
                Bene Bank Branch Name
              </th>
              <th className="align-left">
                Beneficiary email id
              </th>
            </tr>
          </thead>
          <tbody>
            {bankFormatfilter.map((i, index) => {
              const transaction_type = i.salarypay > 200000 ? 'R' : i.bank_details?.bank === 'HDFC' ? 'I' : 'N';
              return (
                <React.Fragment key={index}>
                  <tr className="custom-table-row">
                    <td className="align-left">{transaction_type}</td>
                    {/* I === Bank = HDFC */}
                    {/* N === Bank = Other Banks */}
                    {/* R === Bank = i.salarypay > 2Lakh*/}
                    <td className="align-left">{transaction_type === 'I' ? i.bank_details?.account_number : null}</td>
                    <td className="align-left"> {i.bank_details?.account_number}</td>
                    <td className="align-left">{i.salarypay}</td>
                    <td className="align-left">{i.name}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Drawee Location */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Print Location */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Bene Address 1 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Bene Address 2 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Bene Address 3 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Bene Address 4 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Bene Address 5 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Instruction Reference Number */}</td>
                    <td className="align-left">{i.remarks || `Salary ${monthShortNames[monthh]} ${yearr}`}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Payment details 1 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Payment details 2 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Payment details 3 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Payment details 4 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Payment details 5 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Payment details 6 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Payment details 7 */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Cheque Number */}</td>
                    <td className="align-left">{i.transaction_date ? formatDateDDMMYYYY(i.transaction_date) : null}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* MICR Number */}</td>
                    <td className="align-left">{transaction_type === 'N' ? i.bank_details?.ifsc_code : null}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Bene Bank Name */}</td>
                    <td className="align-left" style={{ display: 'none' }}>{/* Bene Bank Branch Name */}</td>
                    <td className="align-left">{i.email}</td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>

        </table>
      </div>
    </>
  );
};


// const PayRunn = () => {
//   const [designationname, setDesignationname] = useState([]);
//   const [departmentname, setDepartmentname] = useState([]);
//   const [buffer, setBuffering] = useState(false); //buffering logic

//   const getDesignation = async () => {
//     // setBuffering(true); //buffering logic

//     try {
//       const des = await axios.get(`${BASE_URL}/wfm/designation/`);
//       const dep = await axios.get(`${BASE_URL}/wfm/department/`);

//       setDesignationname(des.data);
//       setDepartmentname(dep.data);
//     } catch (err) {
//       //toast Logic
//       // if (err.response) {
//       //   const errorData = err.response.data;

//       //   if (typeof errorData.error === 'string') {
//       //     // Single error message
//       //     toast.error(Error: ${errorData.error});
//       //   } else if (typeof errorData === 'object' && errorData !== null) {
//       //     // Multiple error messages
//       //     Object.entries(errorData).forEach(([field, messages]) => {
//       //       messages.forEach(message => toast.error("${field}": ${message}));
//       //     });
//       //   } else {
//       //     toast.error('Error:- Failed to Process!');
//       //   }
//       // } else {
//       //   toast.error('Error processing your request.');
//       // }
//     } finally {
//       // setBuffering(false);// End Buffering
//     }
//   };

//   useEffect(() => {
//     getDesignation();
//   }, []);

//   const today = new Date();
//   today.setDate(today.getDate() - 1);
//   const year = today.getFullYear();
//   const month = today.getMonth() + 1;
//   const day = today.getDate();

//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   const formattedDate = ${year}-${month.toString().padStart(2, "0")}-${day
//     .toString()
//     .padStart(2, "0")};
//   var datearray = formattedDate.split("-");

//   var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
//   console.log(newdate);

//   const [monthh, setMonthh] = useState(${month});
//   const monthName = monthNames[monthh - 1];

//   const [yearr, setYearr] = useState(${year});
//   const [monthlylist, setMonthlylist] = useState([]);
//   const [monthlylistfilter, setMonthlylistfilter] = useState([]);

//   const [toggleState, setToggleState] = useState("pending/");
//   // const [searchQuery, setSearchQuery] = useState("");

//   const getSalarylist = async () => {
//     setBuffering(true); //buffering logic

//     try {
//       const res = await axios.get(
//         ${BASE_URL}/wfm/salaryslipall/${yearr}/${monthh}
//       );
//       const sortedList = customSortByKey(res.data, "emp_code");
//       setMonthlylist(sortedList);
//     } catch (err) {
//       // toast Logic
//       if (err.response) {
//         const errorData = err.response.data;

//         if (typeof errorData.error === 'string') {
//           // Single error message
//           toast.error(Error: ${errorData.error});
//         } else if (typeof errorData === 'object' && errorData !== null) {
//           // Multiple error messages
//           Object.entries(errorData).forEach(([field, messages]) => {
//             messages.forEach(message => toast.error("${field}": ${message}));
//           });
//         } else {
//           toast.error('Error:- Failed to Process!');
//         }
//       } else {
//         toast.error('Error processing your request.');
//       }
//     } finally {
//       setBuffering(false);// End Buffering
//     }
//   };
//   const [SubCompany, setSubCompany] = useState([]);

//   const getSubCompany = async () => {
//     // setBuffering(true); //buffering logic
//     try {
//       const res = await axios.get(${BASE_URL}/wfm/subcompany/);
//       setSubCompany(res.data);
//     } catch (err) {
//       //toast Logic
//       // if (err.response) {
//       //   const errorData = err.response.data;

//       //   if (typeof errorData.error === 'string') {
//       //     // Single error message
//       //     toast.error(Error: ${errorData.error});
//       //   } else if (typeof errorData === 'object' && errorData !== null) {
//       //     // Multiple error messages
//       //     Object.entries(errorData).forEach(([field, messages]) => {
//       //       messages.forEach(message => toast.error("${field}": ${message}));
//       //     });
//       //   } else {
//       //     toast.error('Error:- Failed to Process!');
//       //   }
//       // } else {
//       //   toast.error('Error processing your request.');
//       // }
//     } finally {
//       // setBuffering(false);// End Buffering
//     }
//   };

//   const [selectedName, setName] = useState("");
//   const [selectedDep, setDep] = useState("");
//   const [selectedDesi, setDesi] = useState("");
//   // const [selectedRH, setRh] = useState('');
//   const [selectedDivision, setDivision] = useState(
//     ${sessionStorage.getItem("company")}
//   );

//   const applyFilters = () => {
//     let filteredData = monthlylist;

//     if (selectedName) {
//       const lowercaseSelectedName = selectedName.toLowerCase();
//       filteredData = filteredData.filter((employee) => {
//         const nameMatch = employee.user_details.name
//           .toLowerCase()
//           .includes(lowercaseSelectedName);
//         return nameMatch;
//       });
//     }

//     if (selectedDep) {
//       filteredData = filteredData.filter(
//         (employee) => employee.user_details.department_name === selectedDep
//       );
//     }

//     if (selectedDesi) {
//       const lowercaseSelectedName = selectedDesi.toLowerCase();

//       filteredData = filteredData.filter((employee) => {
//         const desiMatch = employee.user_details.designation_name
//           .toLowerCase()
//           .includes(lowercaseSelectedName);
//         return desiMatch;
//       });
//     }

//     // if (selectedRH) {

//     //     filteredData = filteredData.filter(employee => employee.rh_assigned === selectedRH);
//     // }

//     if (selectedDivision) {
//       filteredData = filteredData.filter(
//         (employee) => employee.user_details.division_name === selectedDivision
//       );
//     }

//     setMonthlylistfilter(filteredData);
//   };

//   useEffect(() => {
//     getSalarylist();
//     getSubCompany();
//   }, [monthh, yearr]);

//   useEffect(() => {
//     applyFilters();
//   }, [selectedName, selectedDep, selectedDesi, monthlylist, selectedDivision]);

//   // ?  ************** Put Request Start **************  ! //

//   const navigate = useNavigate();
//   const currentUrl = window.location.href;
//   const urlParts = currentUrl.split("/");
//   const baseUrl = "/" + urlParts[3] + /salarySlip;
//   const salarySlip = (emp_code, month, year) => {
//     navigate(baseUrl + /${year} + /${month} + /${emp_code}/);
//   };

//   const OneSalarySlip = (i, monthh, yearr) => {
//     navigate(baseUrl, { state: { i, monthh, yearr } });
//   };
//   const tableRef = useRef(null);
//   const handleDownloadPayRunSheet = () => {
//     downloadAsExcel(tableRef, "users", selectedDivision === "" ? PayRunSheet-${monthh}-${yearr} : ${selectedDivision}-PayRunSheet-${monthh}-${yearr});
//   };
//   return (
//     <>
//       <div className="attendance-history-cont">
//         <div className="attendance-heading font-weight500    font-size-heading"></div>

//         <div className="attendance-subcont">
//           <div className="field-cont">
//             <div className="field-cont-div">
//               <input
//                 type="number"
//                 maxLength={4}
//                 value={yearr}
//                 onChange={(e) => setYearr(e.target.value)}
//                 className="attendance-input-field width-5vw date-field"
//               />
//               <hr className="field-cont-hr" />
//             </div>

//             <div className="field-cont-div">
//               <Calender />

//               <select
//                 className="attendance-input-field width-10vw   date-field"
//                 type="text"
//                 value={monthh}
//                 onChange={(e) => setMonthh(e.target.value)}
//               >
//                 {/* <option value="">All</option> */}
//                 <option value="1">January</option>
//                 <option value="2">February</option>
//                 <option value="3">March</option>
//                 <option value="4">April</option>
//                 <option value="5">May</option>
//                 <option value="6">June</option>
//                 <option value="7">July</option>
//                 <option value="8">August</option>
//                 <option value="9">September</option>
//                 <option value="10">October</option>
//                 <option value="11">November</option>
//                 <option value="12">December</option>
//               </select>

//               <hr className="field-cont-hr" />
//             </div>

//             <div className="field-cont-div">
//               <CircularGrid />

//               <select
//                 className="attendance-input-field width-10vw   date-field"
//                 type="text"
//                 value={selectedDep}
//                 onChange={(e) => setDep(e.target.value)}
//               >
//                 <option value=""> All Department</option>
//                 {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
//                 {departmentname
//                   .sort((a, b) => a.title - b.title)
//                   .map((i, index) => (
//                     <option value={i.title}>{i.title}</option>
//                   ))}
//                 ;
//               </select>
//               <hr className="field-cont-hr" />
//             </div>

//             <div className="field-cont-div">
//               <SearchBig />
//               <input
//                 className="attendance-input-field width-10vw  "
//                 placeholder="Designation"
//                 type="text"
//                 value={selectedDesi}
//                 onChange={(e) => setDesi(e.target.value)}
//               />
//               <hr className="field-cont-hr" />
//             </div>

//             <div className="field-cont-div">
//               <SearchBig />
//               <input
//                 className="attendance-input-field width-10vw  "
//                 placeholder="Name"
//                 type="text"
//                 value={selectedName}
//                 onChange={(e) => setName(e.target.value)}
//               />

//               <hr className="field-cont-hr" />
//             </div>

//             <div className="field-cont-div">
//               <Office />

//               <select
//                 className="attendance-input-field width-10vw   date-field"
//                 type="text"
//                 value={selectedDivision}
//                 onChange={(e) => setDivision(e.target.value)}
//               >
//                 <option value="">All Sub Companies</option>
//                 {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
//                 {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
//                   <option value={i.title}>{i.title}</option>
//                 ))}
//                 ;
//               </select>
//             </div>
//           </div>
//           {/* <DownloadTableExcel
//             filename={
//               selectedDivision === ""
//                 ? PayRunSheet-${monthh}-${yearr}
//                 : ${selectedDivision}-PayRunSheet-${monthh}-${yearr}
//             }
//             // filename={BankFormatSheet-${monthh}-${yearr}}
//             sheet="users"
//             currentTableRef={tableRef.current}
//           >
//             <button className=" model-button-print">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="20"
//                 height="22"
//                 id="download"
//                 fill="#fff"
//               >
//                 <g
//                   fill="none"
//                   stroke="#fff"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   stroke-width="2"
//                 >
//                   <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
//                 </g>
//               </svg>
//             </button>
//           </DownloadTableExcel> */}
//           <button className=" model-button-print" onClick={handleDownloadPayRunSheet}>
//             <DownloadIcon />

//           </button>

//         </div>
//       </div>
//       <div className="table-css-white-background">
//         <div className="table-heading-flex">
//           <div className="repo-heading font-weight500    font-size-heading">
//             Pay Run
//             {/* <p className='career-para font-size-subheading">Based your performance</p>*/}
//           </div>
//           <RunPayRoll year={yearr} month={monthh} />
//         </div>
//         <table ref={tableRef} className="table-css">
//           <thead>
//             <tr className="custom-table-head-tr">
//               {/* <th className="align-left">S.No.</th> */}
//               <th className="align-left">Emp Code</th>
//               <th className="align-center">Name</th>
//               <th className="align-center">Department</th>
//               <th className="align-center">Position</th>
//               <th className="align-center">Total Days</th>
//               <th className="align-center">TDS</th>
//               <th className="align-center">Advance</th>
//               <th className="align-center">Arrear</th>
//               <th className="align-center">InHand Salary</th>
//               <th className="align-center">Gross Salary</th>
//               <th className="align-center">Basic Salary</th>
//               <th className="align-center">HRA</th>
//               <th className="align-center">Transport</th>
//               <th className="align-center">Special Allowance</th>
//               <th className="align-center">ESI</th>
//               <th className="align-center">PF</th>
//               <th className="align-center">Employer ESI</th>
//               <th className="align-center">Employer PF</th>
//               <th className="align-center">Gratuity</th>
//               <th className="align-center">Statutory Bonus</th>
//               <th className="align-center">CTC Monthly</th>
//               <th className="align-center">CTC Yearly</th>
//               <th className="align-center">Account No.</th>
//               <th className="align-center">IFSC Code</th>
//               <th className="align-center">Bank Name</th>
//               <th className="align-center">View</th>
//               {/* <th className="align-center">Action</th> */}
//             </tr>
//           </thead>

//           {buffer ? <div className="spinner"></div> : //buffering logic

//             <tbody>
//               {monthlylistfilter
//                 // .filter((e) =>
//                 //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
//                 // )
//                 // .sort((a, b) => Date(a.date) - Date(b.date))
//                 .map((i, index) => {
//                   return (
//                     <React.Fragment key={index}>
//                       <tr className="tr-border-bottom">
//                         <td colSpan="6"></td>
//                       </tr>
//                       <tr className="custom-table-head-tdd">
//                         {/* <td className="align-left">{index + 1}</td> */}
//                         <td className="align-left">{i.emp_code}</td>
//                         <td className="align-center">{i.user_details.name}</td>
//                         <td className="align-center">
//                           {i.user_details.department_name}
//                         </td>
//                         <td className="align-center">
//                           {i.user_details.designation_name}
//                         </td>
//                         <td className="align-center" title="Total Working Days">{i.calculation}</td>
//                         <td className="align-center" title="TDS">{i.tds}</td>
//                         <td className="align-center" title="Advance">{i.advance}</td>
//                         <td className="align-center" title="Arreal">{i.arial}</td>
//                         <td className="align-center" title="Inhand Salary">{i.salarypay}</td>
//                         <td className="align-center" title="Gross">{i.totalearnings}</td>
//                         <td className="align-center" title="Basic">{i.basicsalary}</td>
//                         <td className="align-center" title="HRA">{i.hra}</td>
//                         <td className="align-center" title="Transport">{i.transport}</td>
//                         <td className="align-center" title="Special Allowance">{i.specialallowance}</td>
//                         <td className="align-center" title="ESI">{i.esideduction}</td>
//                         <td className="align-center" title="PF">{i.pfdeduction}</td>
//                         <td className="align-center" title="EESI">{i.esi}</td>
//                         <td className="align-center" title="EPF">{i.epf}</td>
//                         <td className="align-center" title="Gratuity">{i.gratuity}</td>
//                         <td className="align-center" title="bonus">{i.bonus}</td>
//                         <td className="align-center" title="CTC Monthly">{i.ctcofmonth}</td>
//                         <td className="align-center" title="CTC Yearly">{i.ctcofyear}</td>
//                         <td className="align-center" title="Account Number">{i.bank_details?.account_number ? Acc. No. ${i.bank_details?.account_number} : ""}</td>
//                         <td className="align-center" title="IFSC Code">{i.bank_details?.ifsc_code}</td>
//                         <td className="align-center" title="Bank Name">{i.bank_details?.bank_name}</td>
//                         <td className="align-center">


//                           {/* <a
//                             href={${FRONTEND_URL}/salarySlip/${yearr}/${monthh}/${i.emp_code}/}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             title="View using href"
//                           >
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="40"
//                               height="40"
//                               viewBox="0 0 30 30"
//                               fill="none"
//                             >
//                               <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
//                               <path
//                                 d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
//                                 fill="#2576BC"
//                               />
//                             </svg>
//                           </a> */}

//                           {/* <button className="allproject-dashboard" title="View using Navigate" onClick={(e) => salarySlip(i.emp_code, monthh, yearr)}> */}
//                           <button
//                             className="allproject-dashboard"
//                             title="View using Navigate"
//                             onClick={(e) => OneSalarySlip(i, monthh, yearr)}
//                           >
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="40"
//                               height="40"
//                               viewBox="0 0 30 30"
//                               fill="none"
//                             >
//                               <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
//                               <path
//                                 d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
//                                 fill="#2576BC"
//                               />
//                             </svg>
//                           </button>
//                         </td>
//                         {/* <td className="align-center">
//                                                 Action
//                                             </td> */}
//                       </tr>
//                     </React.Fragment>
//                   );
//                 })}
//             </tbody>
//           }
//         </table>
//       </div>
//     </>
//   );
// };

export { PayRun, MonthlySalary, EmployeeDetails, BankFormat, PrintFormat, BankSubmitFormat };
