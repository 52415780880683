import React from 'react'
import PNF from "../assets/images/pnf.png" 

const PageNotFound = () => {
  return (
    <>

      <img className='pagenotfound' src={PNF} alt="404 PaGe NoT FOunD"/>
    </>
  )
}

export default PageNotFound