import React, { useState, useEffect, useRef } from "react";
import * as d3 from 'd3';
import {
  AttendancePolicyTable,
  DepartmentTable,
  DesignationTable,
  Subcompany,
  Worksite,
  CalenderTable,
  InterComTable,
  WeeklyOffPolicyTable,
  EssLMachineTable
} from "./Organizationtables";
import Tree from "react-d3-tree";
import axios from "axios";
import { BASE_URL } from "../../config/axios";


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import usePermission from "../../config/permissions";
import HierarchyChart from "./OrgChart";
import { AddwithWhiteCircle, Arrow, DropdownArrow, DropdownArrowOption, Eye } from "../AllSvg";
import HierarchyChartBoxed from "./OrgChartBoxed";
import OrgChartBoxed from "./OrgChartBoxed";



const Hierarchy = () => {
  const [treeData, setTreeData] = useState([]);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await axios.get(`${BASE_URL}/wfm/herasubcomp/${sessionStorage.getItem('company_id')}/`);
        // const response = await axios.get(`${BASE_URL}/wfm/hera/`);
        const transformedData = transformData(response.data);

        setTreeData([transformedData]);
        setInitialTranslate();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const setInitialTranslate = () => {
    const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();

    setTranslate({ x: dimensions.width / 2, y: 50 });
  };

  const transformData = (data) => {
    const transformNode = (node) => ({
      name: node.user_detail.name,
      attributes: {
        position: node.designation_name,
        department: node.department_name,
        // profilepic: node.profilepic,
      },
      children: node.subordinates ? node.subordinates.map(transformNode) : [],
    });


    return transformNode(data[0]);
  };



  const CustomLabel = ({ nodeData }) => {
    const profilePicUrl = nodeData.attributes.profilepic
      ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`
      : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    return (
      <div style={{
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        background: '#fff',
        width: '150px',
        height: '150px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <img
          src={profilePicUrl}
          alt={`${nodeData.name}'s profile`}
          style={{ borderRadius: '50%', width: '50px', height: '50px', marginBottom: '10px' }}
          onError={(e) => { e.target.src = 'https://via.placeholder.com/50'; }}
        />
        <strong>{nodeData.name}</strong>
        <div>{nodeData.attributes.position}</div>
        <div>{nodeData.attributes.department}</div>
      </div>
    );
  };

  return (
    <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
      {treeData.length > 0 ? (
        <Tree
          data={treeData}
          orientation="vertical"
          translate={translate}
          nodeSvgShape={{
            shape: 'none',
          }}
          pathFunc="step"
          styles={{
            links: {
              stroke: '#2576BC',
              strokeWidth: 5,
            },
          }}
          nodeLabelComponent={{
            render: <CustomLabel />,
            foreignObjectWrapper: {
              y: -50,
              x: -75,
              width: 150,
              height: 150,
            },
          }}
          collapsible
          initialDepth={1}
          separation={{ siblings: 2, nonSiblings: 2 }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};


const OrgHierarchyStructure = () => {
  const [treeData, setTreeData] = useState([]);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialDepth, setInitialDepth] = useState(1);
  const [expandedNodes, setExpandedNodes] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/wfm/herasubcomp/${sessionStorage.getItem('company_id')}/`);
        const transformedData = transformData(response.data);
        setTreeData([transformedData]);
        setInitialTranslate();
        calculateDepthToExpand(transformedData);
      } catch (error) {
        setError('Error fetching data');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const setInitialTranslate = () => {
    const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();
    setTranslate({ x: dimensions.width / 2, y: 50 });
  };

  const transformData = (data) => {
    const transformNode = (node) => ({
      name: node.user_detail.name,
      attributes: {
        emp_code: node.emp_code,
        position: node.designation_name,
        department: node.department_name,
        profilepic: node.profilepic,
      },
      children: node.subordinates ? node.subordinates.map(transformNode) : [],
      toggled: expandedNodes[node.emp_code] || false, // Using expanded state
    });

    return transformNode(data[0]);
  };

  const calculateDepthToExpand = (tree) => {
    const targetEmpCode = sessionStorage.getItem('emp_code');

    const findDepth = (node, currentDepth) => {
      if (node.attributes.emp_code === targetEmpCode) {
        return currentDepth;
      }

      if (node.children) {
        for (let child of node.children) {
          const childDepth = findDepth(child, currentDepth + 1);
          if (childDepth !== -1) {
            return childDepth;
          }
        }
      }

      return -1; // If no match is found
    };

    const depth = findDepth(tree, 1);
    setInitialDepth(depth !== -1 ? depth : 1); // Default to 1 if not found
  };

  const handleToggleNode = (node) => {
    setExpandedNodes((prevExpandedNodes) => {
      const updatedExpandedNodes = { ...prevExpandedNodes };

      // Close all nodes at the same level by setting them to false
      Object.keys(updatedExpandedNodes).forEach((key) => {
        if (key !== node.attributes.emp_code) {
          updatedExpandedNodes[key] = false;
        }
      });

      // Toggle the current node
      updatedExpandedNodes[node.attributes.emp_code] = !prevExpandedNodes[node.attributes.emp_code];

      return updatedExpandedNodes;
    });
  };

  const CustomLabel = ({ nodeData }) => {
    const profilePicUrl = nodeData.attributes.profilepic
      ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`
      : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    return (
      <div style={customLabelStyle} onClick={() => handleToggleNode(nodeData)}>
        <img
          src={profilePicUrl}
          alt={`${nodeData.name}'s profile`}
          style={imageStyle}
          onError={(e) => { e.target.src = 'https://via.placeholder.com/50'; }}
        />
        <strong>{nodeData.name}</strong>
        <div>{nodeData.attributes.position}</div>
        <div>{nodeData.attributes.department}</div>
      </div>
    );
  };

  const customLabelStyle = {
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'center',
    background: '#fff',
    width: '150px',
    height: '150px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const imageStyle = {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    marginBottom: '10px',
  };

  return (
    <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : treeData.length > 0 ? (
        <Tree
          data={treeData}
          orientation="vertical"
          translate={translate}
          nodeSvgShape={{ shape: 'none' }}
          pathFunc="step"
          styles={{
            links: {
              stroke: '#2576BC',
              strokeWidth: 2,
            },
          }}
          nodeLabelComponent={{
            render: <CustomLabel />,
            foreignObjectWrapper: {
              y: -50,
              x: -75,
              width: 150,
              height: 150,
            },
          }}
          collapsible
          initialDepth={initialDepth}
          separation={{ siblings: 2, nonSiblings: 2 }}
        />
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};


const OrgHierarchyStructure2 = () => {
  const [treeData, setTreeData] = useState([]);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedNode, setExpandedNode] = useState(null);
  const [initialDepth, setInitialDepth] = useState(1);
  const [expandedNodes, setExpandedNodes] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/wfm/herasubcomp/${sessionStorage.getItem('company_id')}/`);
        console.log("response.data")
        console.log(response.data)
        console.log("-------------------------------------------")
        const transformedData = transformData(response.data);
        console.log("[transformedData]")
        console.log([transformedData])
        console.log("+++++++++++++++++++++++++++++++++++++++++++++++")
        setTreeData([transformedData]);
        setInitialTranslate();
        calculateDepthToExpand(transformedData);
      } catch (error) {
        setError('Error fetching data');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const setInitialTranslate = () => {
    const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();
    setTranslate({ x: dimensions.width / 2, y: 50 });
  };

  // const transformData = (data) => {
  //   const transformNode = (node) => ({
  //     name: node.user_detail?.name,
  //     attributes: {
  //       emp_code: node.emp_code,
  //       position: node.designation_name,
  //       department: node.department_name,
  //       profilepic: node.profilepic,
  //     },
  //     toggled: expandedNodes[node.emp_code] || false,
  //     children: node.subordinates ? node.subordinates.map(transformNode) : [],
  //   });

  //   return transformNode(data[0]);
  // };

  const transformData = (data) => {
    // console.log("data")
    // console.log(data)
    const transformNode = (node) => {
      // console.log("node")
      // console.log(node)
      // Safeguard against undefined or missing properties


      return {
        name: node.user_detail.name,
        user_detail: node.user_detail,
        emp_code: node.emp_code,
        position: node.designation_name,
        department: node.department_name,
        profilepic: node.profilepic,
        attributes: {
          emp_code: node.emp_code,
          position: node.designation_name,
          department: node.department_name,
          profilepic: node.profilepic,
        },
        toggled: expandedNodes[node.emp_code] || false,
        children: node.subordinates ? node.subordinates.map(transformNode) : [],
      };
    };
    // console.log("data[0]")
    // console.log(data[0])
    console.log("====================================================================")
    return transformNode(data[0]);
  };



  const calculateDepthToExpand = (tree) => {
    const targetEmpCode = sessionStorage.getItem('emp_code');

    const findDepth = (node, currentDepth) => {
      if (node.attributes.emp_code === targetEmpCode) {
        setExpandedNode(node.attributes.emp_code); // Set the initially expanded node
        return currentDepth;
      }

      if (node.children) {
        for (let child of node.children) {
          const childDepth = findDepth(child, currentDepth + 1);
          if (childDepth !== -1) {
            return childDepth;
          }
        }
      }

      return -1; // If no match is found
    };

    const depth = findDepth(tree, 1);
    setInitialDepth(depth !== -1 ? depth : 1); // Default to 1 if not found
  };
  const handleNodeClick = (node) => {
    setExpandedNodes((prevExpandedNodes) => {
      const updatedExpandedNodes = { ...prevExpandedNodes };
      console.log("Click")
      // Close all nodes at the same level by setting them to false
      Object.keys(updatedExpandedNodes).forEach((key) => {
        if (key !== node.attributes.emp_code) {
          updatedExpandedNodes[key] = false;
        }
      });

      // Toggle the current node's expanded state
      updatedExpandedNodes[node.attributes.emp_code] = !prevExpandedNodes[node.attributes.emp_code];
      console.log("updatedExpandedNodes")
      console.log(updatedExpandedNodes)
      console.log("prevExpandedNodes")
      console.log(prevExpandedNodes)
      return updatedExpandedNodes;
    });

    // Re-transform the tree data with the updated expanded state
    setTreeData((prevTreeData) => {
      console.log("prevTreeData")
      console.log(prevTreeData)
      console.log("-------------------------------------------")
      // console.log("prevTreeData[0]")
      // console.log(prevTreeData[0])
      // console.log("prevTreeData.length")
      // console.log(Object.keys(prevTreeData).length)
      // console.log("prevTreeData.children")
      // console.log(prevTreeData[0].children)
      // console.log("prevTreeData.children.length")
      // console.log(prevTreeData[0].children.length)
      if (prevTreeData.length > 0) {
        const updatedTree = transformData(prevTreeData);
        console.log("[updatedTree]")
        console.log([updatedTree])
        console.log("+++++++++++++++++++++++++++++++++++++++++++++++")
        return [updatedTree];
      }
      return prevTreeData;
    });
  };


  // const CustomLabel = ({ nodeData }) => {
  //   const profilePicUrl = nodeData.attributes.profilepic
  //     ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`
  //     : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  //   return (
  //     <div style={customLabelStyle} onClick={() => handleNodeClick(nodeData)}>
  //       <img
  //         src={profilePicUrl}
  //         alt={`${nodeData.name}'s profile`}
  //         style={imageStyle}
  //         onError={(e) => { e.target.src = 'https://via.placeholder.com/50'; }}
  //       />
  //       <strong>{nodeData.name}</strong>
  //       <div>{nodeData.attributes.position}</div>
  //       <div>{nodeData.attributes.department}</div>
  //     </div>
  //   );
  // };


  const CustomLabel = ({ nodeData }) => {
    const profilePicUrl = nodeData.attributes.profilepic
      ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`
      : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    const boxStyle = {
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      cursor: 'pointer',
      width: '150px',
    };

    const imageStyle = {
      borderRadius: '50%',
      width: '60px',
      height: '60px',
      objectFit: 'cover',
      marginBottom: '10px',
    };

    return (
      <div style={boxStyle} >
        <img
          src={profilePicUrl}
          alt={`${nodeData.name}'s profile`}
          style={imageStyle}
          onError={(e) => {
            e.target.src = 'https://via.placeholder.com/50';
          }}
        />
        <br />
        <strong>{nodeData.name}</strong>
        <div>{nodeData.attributes.department} : </div>
        <div>{nodeData.attributes.position}</div>

        {
          nodeData.children.length > 0 ?
            <button onClick={() => handleNodeClick(nodeData)}>
              <DropdownArrowOption />
            </button>
            : null}
      </div>
    );
  };



  const customLabelStyle = {
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'center',
    background: '#fff',
    width: '150px',
    height: '150px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const imageStyle = {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    marginBottom: '10px',
  };

  return (
    <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : treeData.length > 0 ? (
        // <Tree
        //   data={treeData}
        //   orientation="vertical"
        //   translate={translate}
        //   nodeSvgShape={{ shape: 'none' }}
        //   pathFunc="elbow"
        //   styles={{
        //     links: {
        //       stroke: '#2576BC',
        //       strokeWidth: 2,
        //     },
        //   }}
        //   renderCustomNodeElement={({ nodeDatum }) => (
        //     <foreignObject width={200} height={500} x={-75} y={-50}>
        //       <CustomLabel nodeData={nodeDatum} />
        //     </foreignObject>
        //   )}
        //   // nodeLabelComponent={{
        //   //   render: <CustomLabel />,
        //   //   foreignObjectWrapper: {
        //   //     y: -50,
        //   //     x: -75,
        //   //     width: 150,
        //   //     height: 150,
        //   //   },
        //   // }}
        //   collapsible
        //   initialDepth={initialDepth}
        //   separation={{ siblings: 2, nonSiblings: 2 }}
        //   // onNodeClick={handleNodeClick}
        //   // onNodeClick={handleNodeClick}
        //   onLinkClick={handleNodeClick}
        // />
        <Tree
          data={treeData}
          orientation="vertical"
          translate={translate}
          nodeSvgShape={{ shape: 'none' }}
          pathFunc="step"
          styles={{
            links: {
              stroke: '#2576BC',
              strokeWidth: 2,
            },
          }}
          renderCustomNodeElement={({ nodeDatum }) => (
            <foreignObject width={500} height={500} x={-100} >
              <CustomLabel nodeData={nodeDatum} />
            </foreignObject>
          )}
          collapsible
          initialDepth={initialDepth}
          separation={{ siblings: 2, nonSiblings: 2 }}
        />


      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};




const Orgstructure = () => {
  const [toggleState, setToggleState] = useState(1);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  return (
    <>
      <div>
        <div>
          <div className="bloc-tabs-with-white-bg">
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(1)}
            >
              Hierarchy
            </button>
            {/* <button
              className={
                toggleState === 11
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(11)}
            >
              D3 Hierarchy
            </button> */}
            {/* <button
              className={
                toggleState === 12
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(12)}
            >
              Trial Hierarchy
            </button> */}

            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(2)}
            >
              Department
            </button>
            <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(3)}
            >
              Designation
            </button>
            <button
              className={
                toggleState === 4
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(4)}
            >
              Sub Company
            </button>
            <button
              className={
                toggleState === 5
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(5)}
            >
              Work Sites
            </button>
            {/* <button
              className={
                toggleState === 6
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(6)}
            >
              Attendace Policy
            </button>
            <button
              className={
                toggleState === 7
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(7)}
            >
              Weekly Off Policy
            </button> */}
            <button
              className={
                toggleState === 8
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(8)}
            >
              Calender Table
            </button>
            <button
              className={
                toggleState === 9
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(9)}
            >
              InterCom
            </button>

            {
              PERMISSION_AUTHORITY ?
                <button
                  className={
                    toggleState === 10
                      ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                      : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                  }
                  onClick={() => setToggleState(10)}
                >
                  ESSL
                </button>
                : null}
          </div>
        </div>

        <div className="content-tabs-without-margin">
          {toggleState === 1 ? <Hierarchy /> : null}

          {toggleState === 11 ? <HierarchyChart /> : null}

          {toggleState === 12 ? <HierarchyChartBoxed /> : null}
          {/* {toggleState === 12 ? <OrgChartBoxed /> : null} */}

          {toggleState === 2 ? <DepartmentTable /> : null}

          {toggleState === 3 ? <DesignationTable /> : null}

          {toggleState === 4 ? <Subcompany /> : null}

          {toggleState === 5 ? <Worksite /> : null}

          {toggleState === 6 ? <AttendancePolicyTable /> : null}

          {toggleState === 7 ? <WeeklyOffPolicyTable /> : null}

          {toggleState === 8 ? <CalenderTable /> : null}

          {toggleState === 9 ? <InterComTable /> : null}

          {toggleState === 10 ? <EssLMachineTable /> : null}
        </div>
      </div>
    </>
  );
};

export default Orgstructure;