import React, { useState } from "react";
import { LogCompensatorytable, HRLeavesLogTable, HRNotificationLogTable, HODLeavesLogTable, HODCompensatoryLogTable, HODNotificationLogTable } from "./Requestoutdutytable";



const HRLogRecords = () => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <>
      <div>
        <div>
          <div className="bloc-tabs-with-white-bg">
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(1)}
            >
              Compensatory Logs
            </button>

            {/* <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(2)}
            >
              Leave History Logs
            </button>


            <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(3)}
            >
              Notification History Logs
            </button> */}


          </div>
        </div>

        <div className="content-tabs-without-margin">

          {toggleState === 1 ? <LogCompensatorytable /> : null}

          {toggleState === 2 ? <HRLeavesLogTable /> : null}

          {toggleState === 3 ? <HRNotificationLogTable /> : null}

        </div>
      </div>
    </>
  );
};

const HODLogRecords = () => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <>
      <div>
        <div>
          <div className="bloc-tabs-with-white-bg">
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(1)}
            >
              Department Compensatory Logs
            </button>

            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(2)}
            >
              Department Leaves History
            </button>

            {/* 
            <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(3)}
            >
             Department Notification History
            </button> */}


          </div>
        </div>

        <div className="content-tabs-without-margin">

          {toggleState === 1 ? <HODCompensatoryLogTable /> : null}

          {toggleState === 2 ? <HODLeavesLogTable /> : null}

          {toggleState === 3 ? <HODNotificationLogTable /> : null}

        </div>
      </div>
    </>
  );
};


export { HRLogRecords, HODLogRecords };