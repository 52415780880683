import React, { useState } from 'react';
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import usePermission from '../../config/permissions';
import { SiteDesignationTable, SiteEmployeeTable, SiteSubcompanyTable, SiteProjectTable, SiteTrackRecordTable, CircularTable } from './SiteTables';

const SiteManagement = () => {
    const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission();

    const [toggleState, setToggleState] = useState(1);
    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return 'Site SubCompanies';
            case 2:
                return 'Site Departments';
            case 3:
                return 'Site Employees';
            case 4:
                return 'Site Projects Table';
            case 5:
                return 'Track records';
            default:
                return 'Site Designations';
        }
    };

    return (
        <>
            <Dashboardnavbarcopy name={getSelectedTitle()} url="Site Managment" />
            <div >
                <div className="bloc-tabss2  wfm-margin">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(1)}
                    >
                        Site Sub Companies
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(2)}
                    >
                        Site Designation
                    </button>
                    <button
                        className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(4)}
                    >
                        Site Projects Table
                    </button>
                    <button
                        className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(3)}
                    >
                        Site Employees
                    </button>
                    {/* <button
                        className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(5)}
                    >
                        Letter Tracking
                    </button> */}
                    <button
                        className={toggleState === 6 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(6)}
                    >
                        Circular Table
                    </button>
                </div>
            </div>
            <div className="content-tabs">
                {toggleState === 1 ? <SiteSubcompanyTable /> : null}
                {toggleState === 2 ? <SiteDesignationTable /> : null}
                {toggleState === 3 ? <SiteEmployeeTable /> : null}
                {toggleState === 4 ? <SiteProjectTable /> : null}
                {toggleState === 5 ? <SiteTrackRecordTable /> : null}
                {toggleState === 6 ? <CircularTable /> : null}


            </div>
        </>
    );
};





export default SiteManagement;
