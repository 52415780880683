import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, FRONTEND_URL } from "../../../config/axios"
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';

const OurCompanyDetails = ({ email, e, getOnboarding, departmentname, designationname, rhname, SubCompany, attendpolicy, weeklypolicy, worksite, data }) => {

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [formData, setFormData] = useState({

    emp_code: "",
    machine_code: "",
    user_detail: email,
    department: "",
    designation: "",
    is_reporthead: "",
    // leavebalance: "",
    // casual_leave: "",
    // sick_leave: "",
    employee_type: "",
    joining_date: "",
    location: "",
    division: "",
    attendance_policy: "",
    weeklyoff_policy: "",
    employee_status: "active",
    role: '',
    url: FRONTEND_URL
  });
  const [formDataa, setFormDataa] = useState({
    protal_created: true,
    coordinateby: `${sessionStorage.getItem('emp_code')} - ${sessionStorage.getItem('name')} `,
  });


  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "department",
      'machine_code',
      "designation",
      "is_reporthead",
      // "leavebalance",
      "employee_type",
      "joining_date",
      "location",
      "division",
      "attendance_policy",
      "weeklyoff_policy",
      'role'

    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Cookies.set("userData", JSON.stringify(formData));

      // const updatedFormData = {
      //   ...formData,
      //   employee: formData.email,
      // };

      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic 
      try {
        let res = await axios.post(`${BASE_URL}/wfm/ourcompanyuserdetail/`, formData,)

        if (res.status === 200) {

          let res1 = await axios.put(
            `${BASE_URL}/jd/jobapplicant/${data.id}/`,
            formDataa
          );
          let res2 = await axios.put(
            `${BASE_URL}/user/userupdated/${email}/`
          );

          if (res1.status === 200 && res2.status === 200) {
            toast.done('Done processing your request.')
            window.location.reload();
          }
        } else {
          alert(res);
        }
      } catch (err) {
        console.log(err)
        // alert(err);

        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;
          if (typeof errorData.error === 'string') { // Single error message   
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }

    }
  };
  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today.toISOString().split('T')[0];
  };


  return (
    <>

      <button className="model-edit-button" title="Add Employee " onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z" fill="#145650" />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Onboarding </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

          {/*  ************** timeline Seaction  ************** */}

          <>


            <form className="register-form">
              <div className="form-flex-wrap">


                <div className="flex-column">
                  <label htmlFor="emp_code" className="form-labels  font-weight500    font-size-subheading">
                    Emp Code<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="emp_code"
                    name="emp_code"
                    placeholder="Emp Code"
                    onChange={handleInputChange}
                    value={formData.emp_code}
                    className={`form-input ${errors.emp_code
                      ? "error"
                      : inputState.emp_code
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.emp_code && (
                    <span className="error-message font-size-text ">{errors.emp_code}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor="machine_code" className="form-labels  font-weight500    font-size-subheading">
                    Machine Code<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="machine_code"
                    name="machine_code"
                    placeholder="Machine Code"
                    onChange={handleInputChange}
                    value={formData.machine_code}
                    className={`form-input ${errors.machine_code
                      ? "error"
                      : inputState.machine_code
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.machine_code && (
                    <span className="error-message font-size-text ">{errors.machine_code}</span>
                  )}
                </div>
                <div className="flex-column form-group-selectt">
                  <label htmlFor="department" className="form-labels  font-weight500    font-size-subheading">
                    Department<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="department"
                    name="department"
                    value={formData.department}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                      }`}
                  >
                    <option value="">Select Department</option>
                    {departmentname?.sort((a, b) => a.title?.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.title}</option>
                    </>))
                    }
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.department && (
                      <span className="error-message font-size-text ">{errors.department}</span>
                    )}
                  </div>
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="designation" className="form-labels  font-weight500    font-size-subheading">
                    Designation<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="designation"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                      }`}
                  >
                    <option value="">Select Designation</option>
                    {designationname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.title}</option>
                    </>))
                    }
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.designation && (
                      <span className="error-message font-size-text ">{errors.designation}</span>
                    )}
                  </div>
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="is_reporthead" className="form-labels  font-weight500    font-size-subheading">
                    Is Reporting Head<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="is_reporthead"
                    name="is_reporthead"
                    value={formData.is_reporthead}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.is_reporthead ? "error" : inputState.is_reporthead ? "success" : ""
                      }`}
                  >
                    <option value="">Select Option</option>
                    <option value="True">Yes</option>
                    <option value="False">No</option>




                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.is_reporthead && (
                      <span className="error-message font-size-text ">{errors.is_reporthead}</span>
                    )}
                  </div>
                </div>


                <div className="flex-column form-group-selectt">
                  <label htmlFor="reporting_head" className="form-labels  font-weight500    font-size-subheading">
                    Reporting Head<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="reporting_head"
                    name="reporting_head"
                    value={formData.reporting_head}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.reporting_head ? "error" : inputState.reporting_head ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Reporting Head</option>
                    {rhname?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.user_detail}>{i.name}</option>
                    </>))
                    }




                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.reporting_head && (
                      <span className="error-message font-size-text ">{errors.reporting_head}</span>
                    )}
                  </div>
                </div>

                <div className="flex-column">
                  <label htmlFor="joining_date" className="form-labels  font-weight500    font-size-subheading">
                    Joining Date<span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    id="joining_date"
                    name="joining_date"
                    onChange={handleInputChange}
                    max={getMaxDate()}
                    value={formData.joining_date}
                    className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                      }`}
                  />
                  {errors.joining_date && <span className="error-message font-size-text ">{errors.joining_date}</span>}
                </div>





                <div className="flex-column form-group-selectt">
                  <label htmlFor="attendance_policy" className="form-labels  font-weight500    font-size-subheading">
                    Attendance Policy<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="attendance_policy"
                    name="attendance_policy"
                    value={formData.attendance_policy}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.attendance_policy ? "error" : inputState.attendance_policy ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Attendance Policy</option>
                    {attendpolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.name}</option>
                    </>))
                    }




                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.attendance_policy && (
                      <span className="error-message font-size-text ">{errors.attendance_policy}</span>
                    )}
                  </div>
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="weeklyoff_policy" className="form-labels  font-weight500    font-size-subheading">
                    WeeklyOff Policy<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="weeklyoff_policy"
                    name="weeklyoff_policy"
                    value={formData.weeklyoff_policy}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.weeklyoff_policy ? "error" : inputState.weeklyoff_policy ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Attendance Policy</option>
                    {weeklypolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.name}</option>
                    </>))
                    }
                  </select>

                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.weeklyoff_policy && (
                      <span className="error-message font-size-text ">{errors.weeklyoff_policy}</span>
                    )}
                  </div>
                </div>

                {/* <div className="flex-column">
                  <label htmlFor="leavebalance" className="form-labels  font-weight500    font-size-subheading">
                    Leave Balance<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="leavebalance"
                    name="leavebalance"
                    placeholder="Leave Balance"
                    onChange={handleInputChange}
                    value={formData.leavebalance}
                    className={`form-input ${errors.leavebalance
                      ? "error"
                      : inputState.leavebalance
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.leavebalance && (
                    <span className="error-message font-size-text ">{errors.leavebalance}</span>
                  )}
                </div> */}

                {/* <div className="flex-column">
                  <label htmlFor="joining_date" className="form-labels  font-weight500    font-size-subheading">
                    Joining Date<span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    id="joining_date"
                    name="joining_date"
                    placeholder="Joining Date"
                    onChange={handleInputChange}
                    value={formData.joining_date}
                    className={`form-input ${errors.joining_date
                      ? "error"
                      : inputState.joining_date
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.joining_date && (
                    <span className="error-message font-size-text ">{errors.joining_date}</span>
                  )}
                </div> */}
                <div className="flex-column form-group-selectt">
                  <label htmlFor="division" className="form-labels  font-weight500    font-size-subheading">
                    Division<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="division"
                    name="division"
                    value={formData.division}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.division ? "error" : inputState.division ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Division</option>
                    {SubCompany?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.title}</option>
                    </>))
                    }




                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.division && (
                      <span className="error-message font-size-text ">{errors.division}</span>
                    )}
                  </div>
                </div>


                <div className="flex-column form-group-selectt">
                  <label htmlFor="location" className="form-labels  font-weight500    font-size-subheading">
                    Work Site<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.location ? "error" : inputState.location ? "success" : ""
                      }`}
                  >
                    <option value="">Select a location</option>
                    {worksite?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.title}</option>
                    </>))
                    }

                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.location && (
                      <span className="error-message font-size-text ">{errors.location}</span>
                    )}
                  </div>
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="employee_type" className="form-labels  font-weight500    font-size-subheading">
                    Employee Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="employee_type"
                    name="employee_type"
                    value={formData.employee_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.employee_type ? "error" : inputState.employee_type ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Employee</option>
                    <option value="permanent">Permanent</option>
                    <option value="temporary">Temporary</option>
                    <option value="probation">Probation</option>
                    <option value="termination">Termination</option>
                    <option value="resigned">Resigned</option>
                    <option value="absconding">Absconding</option>
                    <option value="healthissue">Healthissue</option>
                    <option value="intern">Intern</option>
                    <option value="trainee">Trainee</option>

                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.employee_type && (
                      <span className="error-message font-size-text ">{errors.employee_type}</span>
                    )}
                  </div>
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="role" className="form-labels  font-weight500    font-size-subheading">
                    Employee Role<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="role"
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.role ? "error" : inputState.role ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Role</option>
                    <option value="Employee">Employee</option>
                    <option value="ReportingHead">Team Lead</option>
                    <option value="HOD">HOD</option>
                    <option value="HR">HR</option>
                    <option value="Admin">Admin</option>
                    <option value="IT">IT</option>
                    <option value="SiteManagement">Site Management</option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.role && (
                      <span className="error-message font-size-text ">{errors.role}</span>
                    )}
                  </div>
                </div>

              </div>

              <div className="button-models">


                <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading}>
                  Submit
                </button>
              </div>
            </form></>

        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    </>
  );
};

export default OurCompanyDetails;
