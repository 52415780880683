import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { formatDate, formattedDateLong } from "../Date";
import { BASE_URL, IMAGE_URL } from "../../config/axios";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import { useLocation } from "react-router-dom";
import { UpdateProjectAllDetails, AddBillingDetails, AddProjectBill, UpdateBillingDetails, AddEOTDetails, AddCOSDetails, AddMilstoneDetails, AddSiteEmployee } from "./SiteButtons";




const BillTable = ({ projectId }) => {

  const [buffer, setBuffering] = useState(false); //buffering logic

  const [bills, setBills] = useState([]);
  const getProjectBillDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const bill = await axios.get(
        `${BASE_URL}/siteproject/billingbyprojid/${projectId}/`
      );
      setBills(bill.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          if (errorData.detail && errorData.code && Array.isArray(errorData.messages)) {
            toast.error(`Detail: ${errorData.detail}`);
            toast.error(`Code: ${errorData.code}`);
            errorData.messages.forEach((messageObj) => {
              Object.entries(messageObj).forEach(([key, value]) => {
                toast.error(`${key}: ${value}`);
              });
            });
          } else {
            Object.entries(errorData).forEach(([field, messages]) => {
              if (Array.isArray(messages)) {
                messages.forEach((message) =>
                  toast.error(`"${field}": ${message}`)
                );
              } else {
                toast.error(`"${field}": ${messages}`);
              }
            });
          }
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getProjectBillDetails();
  }, []);

  return (

    <>
      <div className="jd-heading-outer">
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Bills</h2>
          <AddProjectBill project={projectId} />
          {/* button here */}
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <>
        {/* <div className="attendance-subcont">
                    <div className="field-cont">
                      <div className="field-cont-div">
                        <input
                          className="attendance-input-field width-10vw   date-field"
                          placeholder="From Date"
                          type="date"
                          value={startdate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                        <hr className="field-cont-hr" />
                      </div>
                      <div className="field-cont-div">
                        <input
                          className="attendance-input-field width-10vw   date-field"
                          placeholder="To Date"
                          type="date"
                          value={enddate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                        <hr className="field-cont-hr" />
                      </div>

                      <div className="field-cont-div-svg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 11 12"
                          fill="none"
                        >
                          <circle
                            cx="5"
                            cy="5"
                            r="4.3"
                            stroke="#707070"
                            stroke-width="1.4"
                          />
                          <line
                            x1="10.0101"
                            y1="11"
                            x2="8"
                            y2="8.98995"
                            stroke="#707070 "
                            stroke-width="1.4"
                            stroke-linecap="round"
                          />
                        </svg>

                        <input
                          className="attendance-input-field width-15vw  "
                          placeholder="Search"
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="btn-cont">
                      <AddProjectBill project={projectId} />
                    </div>
                  </div> */}

        <div className="table-css-white-background">
          <table className="table-css ">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">S.No.</th>
                <th className="align-center">Number</th>
                <th className="align-center">Name</th>
                <th className="align-center">Type</th>
                <th className="align-center">Amount</th>
                <th className="align-center">Date</th>
                <th className="align-center">Period</th>
                <th className="align-center width-5vw">Doc</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {bills
                  .map((billData, monthIndex) => (
                    <React.Fragment key={monthIndex}>
                      <tr className="custom-table-head-td">
                        <td className="align-left">{monthIndex + 1}</td>
                        <td className="align-left">{billData.bill_num}</td>
                        <td className="align-center">{billData.bill_name}</td>
                        <td className="align-center">{billData.bill_type}</td>
                        <td className="align-center">{billData.billing_amount}</td>
                        <td className="align-center">{formattedDateLong(billData.billing_date)}</td>
                        <td className="align-center">{formattedDateLong(billData.bill_period_start)}-{formattedDateLong(billData.bill_period_end)}</td>
                        <td className="align-center ">
                          <a href={billData.document} target="_blank" className="document-download-button  width-5vw  width-5vw">
                            View
                          </a>
                          {/* {billData.document} */}
                          {/* <AddBillingDetails project={projectDataa} /> */}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            )}
          </table>
        </div>

      </>
    </>
  );
}


const EOTTable = ({ projectId }) => {

  const [projectData, setProjectsData] = useState();
  const [eotList, setEotList] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");

  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      let eot = await axios.get(`${BASE_URL}/siteproject/eotfilter/${projectId}/${toggleShow}/`);
      // const eot = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setEotList(eot.data);


      const proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);
      setProjectsData(proj.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };


  useEffect(() => {
    getProjects();
  }, [toggleShow]);

  const handleEotDelete = async (e, i, getProjects) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/eot/${i.id}/`);

      if (res.status === 200) {
        await getProjects();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (

    <>
      <div className="jd-heading-outer">
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Extention of Time</h2>
          {/* button here */}
          <div className="field-cont">
            <div className="table-searchh field-cont-div">
              {/* <div className="table-searchh dropdown-container field-cont-div"> */}
              <select
                onChange={(e) => setToggleShow(e.target.value)}
                value={toggleShow}
                className="dropdown font-size-subheading  ">
                <option value="null">All</option>
                <option value="contractor">Contractor</option>
                <option value="consultant">Consultant</option>
                {/* {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))}; */}

              </select>
            </div>
            <AddEOTDetails project={projectId} getProjects={getProjects} />
          </div>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap">
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S. No.</th>
              <th className="align-center">EOT Code</th>
              <th className="align-center">Date</th>
              <th className="align-center">Duration</th>
              <th className="align-center">Related To</th>

              <th className="align-right width-5vw">Action</th>
            </tr>
          </thead>
          <tbody>
            {eotList?.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.eot_code}</td>
                  <td className="align-center form-text-trasformation-uppercase">
                    {i.approved_date ? formattedDateLong(i.approved_date) : "-"}
                  </td>
                  <td className="align-center">{i.approved_days}</td>
                  <td className="align-center">{i.workperformedby}</td>


                  <td className="align-right width-5vw ">
                    <button className="model-delete-button"
                      onClick={(e) => handleEotDelete(e, i, getProjects)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                          fill="#F72B50"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <hr className="line" />

    </>
  )


}


const COSTable = ({ projectId }) => {

  const [projectData, setProjectsData] = useState();
  const [cosList, setCosList] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");

  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      let cos = await axios.get(`${BASE_URL}/siteproject/cosfilter/${projectId}/${toggleShow}/`);
      setCosList(cos.data);

      const proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);
      setProjectsData(proj.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjects();
  }, [toggleShow]);

  const handleCosDelete = async (e, i, getProjects) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/cos/${i.id}/`);

      if (res.status === 200) {
        await getProjects();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };


  return (

    <>
      <div className="jd-heading-outer">
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Change of Scope</h2>
          {/* button here */}

          <>
            <div className="field-cont">
              <div className="table-searchh  field-cont-div">
                {/* <div className="table-searchh dropdown-container field-cont-div"> */}
                <select
                  onChange={(e) => setToggleShow(e.target.value)}
                  value={toggleShow}
                  className="dropdown font-size-subheading  ">
                  <option value="null">All</option>
                  <option value="contractor">Contractor</option>
                  <option value="consultant">Consultant</option>
                  {/* {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))}; */}

                </select>
              </div>
              <AddCOSDetails project={projectId} getProjects={getProjects} />
            </div>
          </>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap">
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S. No.</th>
              <th className="align-center">COS Code</th>
              <th className="align-center">Date</th>
              <th className="align-center">Amount</th>
              <th className="align-center">By</th>
              <th className="align-right width-5vw">Action</th>
            </tr>
          </thead>
          <tbody>
            {cosList?.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.cos_code}</td>
                  <td className="align-center">
                    {i.approval_date ? formattedDateLong(i.approval_date) : "-"}
                  </td>
                  <td className="align-center">{i.amount}</td>
                  <td className="align-center">{i.workperformedby}</td>

                  <td className="align-right width-5vw ">
                    <button className="model-delete-button"
                      onClick={(e) => handleCosDelete(e, i, getProjects)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                          fill="#F72B50"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <hr className="line" />

    </>
  )


}


const EmployeeTable = ({ projectId }) => {

  const [projectData, setProjectsData] = useState();
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");


  const [siteEmployeeList, setSiteEmployeeList] = useState([]);
  const [siteDepartmentList, setSiteDepartmentList] = useState([]);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
  const [siteProjectList, setSiteProjectList] = useState([]);

  const getProjects = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/employee/`);
      // const res = await axios.get(PERMISSION_AUTHORITY ?
      //     `${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`
      //     :
      //     `${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`
      // );

      // const dep = await axios.get(`${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`);
      // const sub = await axios.get(`${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/${toggleCompany}/active/`);
      const dep = await axios.get(`${BASE_URL}/siteproject/designation/`);
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      const pro = await axios.get(`${BASE_URL}/siteproject/project/`);


      setProjectsData(res.data);
      setSiteDepartmentList(dep.data);
      setSiteSubCompanyList(sub.data);
      setSiteProjectList(pro.data);
    } catch (err) {

      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }

    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const handleEmployeeDelete = async (e, i, getProjects) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/employee/${i.id}/`);

      if (res.status === 200) {
        await getProjects();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };


  return (

    <>
      <div className="jd-heading-outer">
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Employee</h2>
          {/* button here */}
          <>
            <div className="field-cont">
              <div className="table-searchh field-cont-div">
                <select
                  onChange={(e) => setToggleShow(e.target.value)}
                  value={toggleShow}
                  className="dropdown font-size-subheading  ">
                  <option value="null">All</option>
                  <option value="Contractor">Contractor</option>
                  <option value="Consultant">Consultant</option>
                  {/* {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))}; */}

                </select>
              </div>
              <AddSiteEmployee
                projectId={projectId}
                getSiteEmployee={getProjects}
                siteProjectList={siteProjectList}
                siteDepartmentList={siteDepartmentList}
                siteSubCompanyList={siteSubCompanyList}
              />
            </div>
          </>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap">
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S. No.</th>
              <th className="align-center">COS Code</th>
              <th className="align-center">Date</th>
              <th className="align-center">Amount</th>
              <th className="align-right width-5vw">Action</th>
            </tr>
          </thead>
          <tbody>
            {projectData?.employees?.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.cos_code}</td>
                  <td className="align-center">
                    {i.approval_date ? formattedDateLong(i.approval_date) : "-"}
                  </td>
                  <td className="align-center">{i.amount}</td>
                  <td className="align-center">{i.workperformedby}</td>

                  <td className="align-right width-5vw ">
                    <button className="model-delete-button"
                      onClick={(e) => handleEmployeeDelete(e, i, getProjects)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                          fill="#F72B50"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <hr className="line" />

    </>
  )

}



const MilestoneTable = ({ projectId }) => {

  const [projectData, setProjectsData] = useState();
  const [milestoneList, setMilestoneList] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");

  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      let mil = await axios.get(`${BASE_URL}/siteproject/milestonefilter/${projectId}/${toggleShow}/`);
      setMilestoneList(mil.data);

      const mil_date = mil.sort((a, b) => new Date(b.milestone_date) - new Date(a.milestone_date));


      const mil_last_date = mil_date[0]

      const proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);
      setProjectsData(proj.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjects();
  }, [toggleShow]);

  const handleCosDelete = async (e, i, getProjects) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/milestone/${i.id}/`);

      if (res.status === 200) {
        await getProjects();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };


  return (

    <>
      <div className="jd-heading-outer">
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">MileStone</h2>
          {/* button here */}

          <>
            <div className="field-cont">
              <div className="table-searchh  field-cont-div">
                {/* <div className="table-searchh dropdown-container field-cont-div"> */}
                <select
                  onChange={(e) => setToggleShow(e.target.value)}
                  value={toggleShow}
                  className="dropdown font-size-subheading  ">
                  <option value="null">All</option>
                  <option value="contractor">Contractor</option>
                  <option value="consultant">Consultant</option>
                  {/* {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))}; */}

                </select>
              </div>
              <AddMilstoneDetails project={projectId} getProjects={getProjects} />
            </div>
          </>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap">
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S. No.</th>
              <th className="align-center">Name</th>
              <th className="align-center">Milestone Date</th>
              <th className="align-center">Day</th>
              <th className="align-right width-5vw">Action</th>
            </tr>
          </thead>
          <tbody>
            {milestoneList?.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.name}</td>
                  <td className="align-center">
                    {i.milestone_date ? formattedDateLong(i.milestone_date) : "-"}
                  </td>
                  <td className="align-center">{i.day}</td>
                  <td className="align-center">{i.workperformedby}</td>

                  <td className="align-right width-5vw ">
                    <button className="model-delete-button"
                      onClick={(e) => handleCosDelete(e, i, getProjects)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                          fill="#F72B50"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <hr className="line" />

    </>
  )


}


export {
  BillTable,
  EOTTable,
  COSTable,
  EmployeeTable,
  // LetterTable,
  // VehiclesTable,
  MilestoneTable,
};
