import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../config/axios";
import { formatDate, formattedDate, formattedDateLong } from "../../Date";
import { ToastContainer, toast } from "react-toastify";
import { Active, Employee } from "../../AllSvg";
import { customSortByKey } from "../../CustomFunctions";

const DailyAttendance = () => {
  // *********** Employee Details Api Start ***********
  const [allattendance, setAttendnace] = useState([]);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [status_list, setStatus_list] = useState([]);
  const [attendance_list, setAttendance_list] = useState([]);

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [date, setDate] = useState(`${formattedDate}`);

  const getAllAttendance = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/reportinghead/${sessionStorage.getItem(
          "email"
        )}/${date}/`
      );
      setAttendnace(res.data);
      const uniqueDayStatus = [
        ...new Set(res.data.map((entry) => entry.day_status)),
      ];
      const uniqueAttendanceStatus = [
        ...new Set(res.data.map((entry) => entry.attendence_status)),
      ];
      setStatus_list(uniqueDayStatus);
      setAttendance_list(uniqueAttendanceStatus);
    } catch (err) {

      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getAllAttendance();
  }, [date]);
  // *********** Employee Details Api End ***********

  // *********** Filter Logic ***********

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAttendance, setSelectedAttendance] = useState("");
  const [selectedName, setName] = useState("");

  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;

    if (selectedStatus !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.day_status === selectedStatus
      );
    }
    if (selectedAttendance !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.attendence_status === selectedAttendance
      );
    }

    if (selectedName !== "") {
      // Use includes for a case-insensitive search
      filteredData = filteredData.filter((employee) =>
        employee.name.toLowerCase().includes(selectedName.toLowerCase())
      );
    }
    setFilteredEmployee(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedStatus, selectedAttendance, selectedName, allattendance]);

  return (
    <>
      {/* Attendance History Container */}
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"></div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <input
                className="attendance-input-field width-10vw   date-uppercase"
                placeholder="Date"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3351)">
                  <mask
                    id="mask0_650_3351"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="17"
                    height="17"
                  >
                    <path d="M17 0H0V17H17V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_650_3351)">
                    <path
                      d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                      fill="#707070"
                    />
                    <path
                      d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                      fill="#707070"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_650_3351">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>
                {status_list.map((status) => (
                  <option value={status}>{status}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>


            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3351)">
                  <mask
                    id="mask0_650_3351"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="17"
                    height="17"
                  >
                    <path d="M17 0H0V17H17V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_650_3351)">
                    <path
                      d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                      fill="#707070"
                    />
                    <path
                      d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                      fill="#707070"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_650_3351">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedAttendance}
                onChange={(e) => setSelectedAttendance(e.target.value)}
              >
                <option value="">All</option>
                {attendance_list.map((status) => (
                  <option value={status}>{status}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="btn-cont">
            {/* <button className='attendance-btn' onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 11 12" fill="none">
                <circle cx="5" cy="5" r="4.3" stroke="#FFFFFF" stroke-width="1.4" />
                <line x1="10.0101" y1="11" x2="8" y2="8.98995" stroke="#FFFFFF" stroke-width="1.4" stroke-linecap="round" />
              </svg>Search</button> */}
          </div>
        </div>
      </div>

      {/* Attendance History Details */}
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Attendance ( {filteredEmployee.length} of {allattendance.length})
          </div>
          <div></div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr No.</th>

              <th className="align-leftt">Emp Code</th>
              <th className="align-leftt">Name</th>
              <th className="align-center">Punch In</th>
              <th className="align-center">Punch Out</th>
              <th className="align-center">Total hours</th>
              <th className="align-right">Behaviour</th>
              <th className="align-right">Status</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {filteredEmployee
                .sort((a, b) => a.employee - b.employee)
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>

                      <td className="align-leftt">{i.employee}</td>
                      <td className="align-leftt">{i.name}</td>
                      <td className="align-center">{i.in_time}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td
                        data-status={i.day_status}
                        className="align-right attendance-status form-text-trasformation-uppercase"
                      >
                        {i.day_status}
                      </td>
                      <td
                        data-status={i.attendence_status}
                        className="align-right attendance-status form-text-trasformation-uppercase"
                      >
                        {i.attendence_status}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

const EmployeeAttendance = () => {
  // *********** Employee Code Api Start ***********
  const [emp_code, setEmp_code] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic
  useEffect(() => {
    const getAllEmp_code = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {
        const res = await axios.get(
          `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
        );
        const sortedData = customSortByKey(res.data, "emp_code");
        setEmp_code(sortedData);
      } catch (err) {

        //toast Logic
        if (err.response) {
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setBuffering(false);// End Buffering
      }
    };

    getAllEmp_code();
  }, []);

  // *********** Attendance Get Request Api Start ***********

  const today = new Date();
  today.setDate(today.getDate() - 60);

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedDatee = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  // const datearrayy = formattedDate.split("-");

  const [allattendance, setAttendance] = useState([]);
  const [status_list, setStatus_list] = useState([]);

  const [startdate, setStartDate] = useState(`${formattedDatee}`);

  const [enddate, setEndDate] = useState(`${formattedDate}`);
  const [selectedEmpcode, setSelectedEmpcode] = useState(
    `${sessionStorage.getItem("emp_code")}`
  );

  const getAttendanceDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatus/${selectedEmpcode}/${startdate}/${enddate}/`
      );
      // const ress = await axios.get(`${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatusquick/${sessionStorage.getItem("emp_code")}/${startdate}/${enddate}/`)
      setAttendance(res.data);
      const uniqueDayStatus = [
        ...new Set(res.data.map((entry) => entry.day_status)),
      ];
      setStatus_list(uniqueDayStatus);
      // setAttendanceStatus(ress.data)
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getAttendanceDetails();
  }, [startdate, enddate, selectedEmpcode]);

  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;

    if (selectedStatus !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.day_status === selectedStatus
      );
    }

    setFilteredEmployee(filteredData);
  };

  console.log(status_list);
  useEffect(() => {
    applyFilters();
  }, [selectedStatus, allattendance]);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <input
                className="attendance-input-field width-10vw   date-field"
                placeholder="From Date"
                type="date"
                value={startdate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <input
                className="attendance-input-field width-10vw   date-field"
                placeholder="To Date"
                type="date"
                value={enddate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>
                {status_list.map((status) => (
                  <option value={status}>{status}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedEmpcode}
                onChange={(e) => setSelectedEmpcode(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {emp_code
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
            </div>
          </div>
          {/* <div className="btn-cont">
            <button className='attendance-btn' onClick={getAttendanceDetails}><img src={Searchicon} alt="Search" className='searchicon'/>Search</button>
          </div> */}
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Employee Attendance ( {filteredEmployee.length} of{" "}
            {allattendance.length})
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr No</th>
              <th className="align-center">Date</th>
              <th className="align-center">Punch In</th>
              <th className="align-center">Punch Out</th>
              <th className="align-center">Total hours</th>
              <th className="align-right">Behaviour</th>
              <th className="align-right">Status</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {filteredEmployee
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        {formattedDateLong(i.date)}
                      </td>
                      <td className="align-center">{i.in_time}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>

                      <td
                        data-status={i.day_status}
                        className="align-right attendance-status form-text-trasformation-uppercase"
                      >
                        {i.day_status}
                      </td>
                      <td
                        data-status={i.attendence_status}
                        className="align-right attendance-status form-text-trasformation-uppercase"
                      >
                        {i.attendence_status}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};



const SummarySheet = () => {
  const [show, setShow] = useState(false);

  const [indexRow, setIndexRow] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setIndexRow(e);
  };

  console.log(indexRow);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // /attandance/employattandancessheet/222/2/2023/

  const [monthh, setMonthh] = useState(`${month}`);

  const [yearr, setYearr] = useState(`${year}`);
  useEffect(() => {
    getAllattendancedata();
  }, [monthh, yearr]);

  const [allattendance, setAllattendance] = useState([]);

  let getAllattendancedata = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeeattandancessheetbyrh/${monthh}/${yearr}/${sessionStorage.getItem(
          "email"
        )}/`
        // `${BASE_URL}/wfm/employeeattandancessheetall/${monthh}/${yearr}/`
      );
      setAllattendance(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const getAllDatesOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dates = [];
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      dates.push({ date: formattedDate });
    }

    return dates;
  };

  const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
    ...date,
    index,
  }));

  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

  // *********** Filter Logic ***********
  const [filteredEmployee, setFilteredEmployee] = useState([]);

  // const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("active");

  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;

    if (selectedStatus !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.employee_status === selectedStatus
      );
    }

    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code
          .toString()
          .includes(selectedName);
        return nameMatch || empCodeMatch;
      });
    }

    if (selectedDep) {
      const lowercaseSelectedName = selectedDep.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const depMatch = employee.department_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        const rhMatch = employee.rh_name.toLowerCase().includes(selectedDep);
        return depMatch || rhMatch;
      });
    }

    setFilteredEmployee(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedName, selectedStatus, selectedDep, allattendance]);

  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-10vw   date-field"
              />
              <hr />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Name or Code"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              <hr />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Dep || Rep Head Name"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              />

              <hr />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>

                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-table-container">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Attendance ( {filteredEmployee.length} of {allattendance.length})
          </div>
          <div></div>
        </div>


        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr No. </th>
              <th className="align-left">Emp Code</th>
              <th className="align-leftt">Name</th>
              <th className="align-left">Joining Date</th>
              <th className="align-left">Total Days</th>
              <th className="align-left">LB</th>
              <th className="align-left">Pres.</th>
              <th className="align-left">Abse.</th>
              <th className="align-left">RLM</th>
              <th className="align-left">Late</th>
              <th className="align-left">Ont.</th>
              <th className="align-left">HD</th>
              <th className="align-left">WO</th>
              <th className="align-left">WOP</th>
              <th className="align-left">Hols</th>
              <th className="align-left">OD.</th>
              <th className="align-left">HD. OD.</th>
              <th className="align-left">SD. OD.</th>
              <th className="align-left">PL.</th>
              <th className="align-left">HD. L.</th>
              <th className="align-left">SD. L.</th>
              <th className="align-left">UL.</th>
              <th className="align-left">Hd. UL.</th>
              <th className="align-left">Sd. UL.</th>

              {/* <th className="align-left">Status</th> */}
            </tr>
          </thead>
          <tbody>
            {filteredEmployee
              .sort((a, b) => a.emp_code - b.emp_code)
              .map((data, index) => {
                let total_style = dates.length;

                return (
                  <>
                    <tr className="custom-table-head-tdd">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-left">{data.emp_code}</td>
                      <td className="align-leftt">{data?.name}</td>
                      <td className="align-left">
                        {formatDate(data.joining_date)}
                      </td>

                      {/* {show ? <td className="align-left" onClick={handleClose(index+1)}>{data.total_days ? data.total_days : "-"}</td>  : <td className="align-left" onClick={handleShow(index+1)}>{data.total_days ? data.total_days : "-"}</td>} */}

                      {show ? (
                        <td
                          className="align-left"
                          onClick={() => handleClose(index + 1)}
                          style={{
                            color: total_style > data.total ? "red" : "",
                          }}
                        >
                          {data.total ? data.total : "-"}
                        </td>
                      ) : (
                        <td
                          className="align-left"
                          onClick={() => handleShow(index + 1)}
                          style={{
                            color: total_style > data.total ? "red" : "",
                          }}
                        >
                          {data.total ? data.total : "-"}
                        </td>
                      )}

                      {/* 
                      <td className="align-left" onClick={handleShow}>{data.total_days ? data.total_days : "-"}</td> */}
                      <td className="align-left">{data.leavebalance}</td>
                      <td className="align-left">{data.present}</td>
                      <td className="align-left">{data.absent}</td>
                      <td className="align-left">{data.rlm}</td>
                      <td className="align-left">{data.late}</td>
                      <td className="align-left">{data.ontime}</td>
                      <td className="align-left">{data.halfday}</td>
                      <td className="align-left">{data.weeklyoff}</td>
                      <td className="align-left">{data.wop}</td>
                      <td className="align-left">{data.holiday}</td>
                      <td className="align-left">{data.outduty}</td>
                      <td className="align-left">{data.hdoutduty}</td>
                      <td className="align-left">{data.shortoutduty}</td>
                      <td className="align-left">{data.leave}</td>
                      <td className="align-left">{data.halfdayleave}</td>
                      <td className="align-left">{data.shortleave}</td>
                      <td className="align-left">{data.unpaidleave}</td>
                      <td className="align-left">{data.unpaidleavehd}</td>
                      <td className="align-left">{data.unpaidshortleave}</td>
                    </tr>
                    {show === true && index + 1 === indexRow ? (
                      <tr>
                        <td colSpan="28">
                          <table className="custom-table-subtable">
                            <tr className="custom-table-head-tdd">
                              {datees.map((date) => (
                                <td
                                  className="summarysheet-heading-circle"
                                  key={date.date}
                                >
                                  {date.date}
                                </td>
                              ))}
                            </tr>

                            <tr className="custom-table-head-tdd">
                              {dates.map((date) => {
                                const attendanceForDate = data.attendances.find(
                                  (attendance) => attendance.date === date.date
                                );
                                const attendanceStatus = attendanceForDate
                                  ? attendanceForDate.day_status
                                  : "-";

                                const attendanceStatuss = attendanceForDate
                                  ? attendanceForDate.day_status === "ontime"
                                    ? "P"
                                    : attendanceForDate.day_status === "outduty"
                                      ? "OD"
                                      : attendanceForDate.day_status === "halfday"
                                        ? "HD"
                                        : attendanceForDate.day_status ===
                                          "paidleave"
                                          ? "PL"
                                          : attendanceForDate.day_status === "late"
                                            ? "Late"
                                            : attendanceForDate.day_status ===
                                              "weeklyoff"
                                              ? "WO"
                                              : attendanceForDate.day_status === "wop"
                                                ? "WOP"
                                                : attendanceForDate.day_status === "rlm"
                                                  ? "RLM"
                                                  : attendanceForDate.day_status ===
                                                    "halfdayleave"
                                                    ? "HDL"
                                                    : attendanceForDate.day_status ===
                                                      "unpaidleave"
                                                      ? "UL"
                                                      : attendanceForDate.day_status ===
                                                        "shortleave"
                                                        ? "SL"
                                                        : attendanceForDate.day_status ===
                                                          "unpaidshortleave"
                                                          ? "USL"
                                                          : attendanceForDate.day_status ===
                                                            "unpaidleavehd"
                                                            ? "UHD"
                                                            : attendanceForDate.day_status ===
                                                              "hdoutduty"
                                                              ? "HOD"
                                                              : attendanceForDate.day_status ===
                                                                "shortoutduty"
                                                                ? "SOD"
                                                                : attendanceForDate.day_status === "holiday"
                                                                  ? "Hol"
                                                                  : attendanceForDate.day_status === "absent"
                                                                    ? "A"
                                                                    : attendanceForDate.day_status.slice(0, 2)
                                  : "-";

                                // // Example usage:
                                // <span>{attendanceStatus}</span>
                                // {attendanceStatuss.slice(0, 2).toUpperCase()}

                                // const attendanceStatusCircle = attendanceForDate
                                //   ? attendanceForDate.attendence_status === "present"
                                //     ? "Color-tab-green"
                                //     : attendanceForDate.day_status === "absent"
                                //       ? "Color-tab-red"
                                //       : "summarysheet-circle-default" : '';

                                const inTime = attendanceForDate
                                  ? attendanceForDate.in_time
                                  : "-";
                                const outTime = attendanceForDate
                                  ? attendanceForDate.out_time
                                  : "-";
                                const attstat = attendanceForDate
                                  ? attendanceForDate.attendence_status
                                  : "-";
                                const daystatus = attendanceForDate
                                  ? attendanceForDate.day_status
                                  : "-";

                                return (
                                  // <>
                                  <td
                                    className="summarysheet-heading-circle behavior"
                                    data-status={attendanceStatus}
                                    title={`In Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\nDay Status: ${daystatus}`}
                                  >
                                    {attendanceStatuss}
                                  </td>
                                );
                              })}
                            </tr>
                          </table>
                        </td>{" "}
                      </tr>
                    ) : (
                      ""
                    )}{" "}
                  </>
                );
              })}
          </tbody>
        </table>

      </div>
    </>
  );
};

const SummarySheetExcel = () => {
  const [show, setShow] = useState(false);

  const [indexRow, setIndexRow] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setIndexRow(e);
  };

  console.log(indexRow);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // /attandance/employattandancessheet/222/2/2023/

  const [monthh, setMonthh] = useState(`${month}`);

  const [yearr, setYearr] = useState(`${year}`);
  useEffect(() => {
    getAllattendancedata();
  }, [monthh, yearr]);

  const [allattendance, setAllattendance] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  let getAllattendancedata = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeeattandancessheetbyrh/${monthh}/${yearr}/${sessionStorage.getItem(
          "email"
        )}/`
        // `${BASE_URL}/wfm/employeeattandancessheetall/${monthh}/${yearr}/`
      );
      setAllattendance(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic
    }
  };
  const refreshdata = async () => {
    setBuffering(true); //buffering logic
    await getAllattendancedata();
    setBuffering(false); //buffering logic
  };
  const getAllDatesOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dates = [];
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      dates.push({ date: formattedDate });
    }

    return dates;
  };

  const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
    ...date,
    index,
  }));

  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

  // *********** Filter Logic ***********
  const [filteredEmployee, setFilteredEmployee] = useState([]);

  // const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("active");

  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;

    if (selectedStatus !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.employee_status === selectedStatus
      );
    }

    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code
          .toString()
          .includes(selectedName);
        return nameMatch || empCodeMatch;
      });
    }

    if (selectedDep) {
      const lowercaseSelectedName = selectedDep.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const depMatch = employee.department_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        const rhMatch = employee.rh_name.toLowerCase().includes(selectedDep);
        return depMatch || rhMatch;
      });
    }

    setFilteredEmployee(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedName, selectedStatus, selectedDep, allattendance]);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-5vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>

                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-10vw   date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                className="attendance-input-field width-15vw  "
                placeholder="Name or Code"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              {/* <hr className="field-cont-hr" /> */}
            </div>

            {/* <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                className="attendance-input-field width-15vw  "
                placeholder="Dep || Rep Head Name"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div> */}
          </div>
        </div>
      </div>

      <div className="custom-table-width">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Attendance ( {filteredEmployee.length} of {allattendance.length})
          </div>
          <div></div>
          {buffer ?
            <div className="spinner-vsmall"></div> :
            <div
              className="refresh-button"
              onClick={refreshdata}
              disabled={buffer} //buffering logic
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="refresh-icon"
                fill="#fff"
                viewBox="0 0 24 24"
                id="refresh"
              >
                <path d="M21 21a1 1 0 0 1-1-1V16H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5A1 1 0 0 1 21 21zM8 10H3A1 1 0 0 1 2 9V4A1 1 0 0 1 4 4V8H8a1 1 0 0 1 0 2z"></path>
                <path d="M12 22a10 10 0 0 1-9.94-8.89 1 1 0 0 1 2-.22 8 8 0 0 0 15.5 1.78 1 1 0 1 1 1.88.67A10 10 0 0 1 12 22zM20.94 12a1 1 0 0 1-1-.89A8 8 0 0 0 4.46 9.33a1 1 0 1 1-1.88-.67 10 10 0 0 1 19.37 2.22 1 1 0 0 1-.88 1.1z"></path>
              </svg>
            </div>}
        </div>


        <table
          id="my-table"
          style={{
            borderColor: "#323232",
            borderRadius: "5px",
            padding: "0px 5px",
            borderWidth: "1px",
            marginLeft: "10px",
            zoom: "50%",
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              borderColor: "#323232",
              borderRadius: "5px",
              padding: "0px 5px",
              borderWidth: "1px",
              marginLeft: "10px",
            }}
          >
            <tr>
              <th className="summary-excel-tab br-5">Emp</th>
              <th className="summary-excel-tab br-5">Name</th>
              <th className="summary-excel-tab br-5">Joining Date</th>
              {datees.map((date) => (
                <th className="summary-excel-tab br-5" key={date.date}>
                  {date.date}
                </th>
              ))}
              <th className="summary-excel-tab br-5">LB</th>
              <th className="summary-excel-tab br-5">P</th>
              <th className="summary-excel-tab br-5">OT</th>
              <th className="summary-excel-tab br-5">Late</th>
              <th className="summary-excel-tab br-5">RLM</th>
              <th className="summary-excel-tab br-5">HD</th>
              <th className="summary-excel-tab br-5">WOP</th>
              <th className="summary-excel-tab br-5">OD</th>
              <th className="summary-excel-tab br-5">A</th>
              <th className="summary-excel-tab br-5">HOL</th>
              <th className="summary-excel-tab br-5">WO</th>
              <th className="summary-excel-tab br-5">L</th>
              <th className="summary-excel-tab br-5">HDL</th>
              <th className="summary-excel-tab br-5">SDL</th>
              <th className="summary-excel-tab br-5">WFH</th>
              <th className="summary-excel-tab br-5">HDOD</th>
              <th className="summary-excel-tab br-5">SDOD</th>
              <th className="summary-excel-tab br-5">UL</th>
              <th className="summary-excel-tab br-5">HDUL</th>
              <th className="summary-excel-tab br-5">SDUL</th>
              <th className="summary-excel-tab br-5">Miss</th>
              <th className="summary-excel-tab br-5">Paid Days</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployee
              .sort((a, b) => a.emp_code - b.emp_code)
              .map((data, index) => {
                const background = data.late === 0 ? "white" : "#f9935f";

                const inavtivename =
                  data.employee_status === "inactive" ? "#ff1212" : "black";
                const present = data.present === 0 ? "white" : "#a3fc92";
                const ontime = data.ontime === 0 ? "white" : "#a3fc92";
                const late = data.late === 0 ? "white" : "#ffea72";
                const rlm = data.rlm === 0 ? "white" : "#a3fc92";
                const halfday = data.halfday === 0 ? "white" : "#ffea72";
                const wop = data.wop === 0 ? "white" : "#a3fc92";
                const od = data.outduty === 0 ? "white" : "#73DBDBCC";
                const bgabsent = data.absent === 0 ? "white" : "#fccab1";
                const holiday = data.holiday === 0 ? "white" : "#83B8E6CC";
                const weeklyoff = data.weeklyoff === 0 ? "white" : "#83B8E6CC";
                const leave = data.leave === 0 ? "white" : "#a3fc92";
                const halfdayleave =
                  data.halfdayleave === 0 ? "white" : "#a3fc92";
                const shortleave = data.shortleave === 0 ? "white" : "#a3fc92";

                const workfromhome =
                  data.workfromhome === 0 ? "white" : "#a3fc92";
                const hdoutduty = data.hdoutduty === 0 ? "white" : "#73DBDBCC";
                const shortoutduty =
                  data.shortoutduty === 0 ? "white" : "#73DBDBCC";
                const unpaidleave =
                  data.unpaidleave === 0 ? "white" : "#fccab1";
                const unpaidleavehd =
                  data.unpaidleavehd === 0 ? "white" : "#fccab1";
                const unpaidshortleave =
                  data.unpaidshortleave === 0 ? "white" : "#fccab1";
                const misspunch = data.misspunch === 0 ? "white" : "#fccab1";

                const bgtotalpaiddays =
                  data.total === dates.length ? "white" : "#fccab1";

                const hd = data.halfday === 0 ? "white" : "#fccab1";

                return (
                  <>
                    <tr
                      style={{
                        backgroundColor:
                          index === selectedIndex ? "yellow" : "white",
                        color: inavtivename,
                      }}
                      onClick={() => handleClick(index)}
                    >
                      <td className="summary-excel-tab br-5">{data.emp_code}</td>
                      <td className="summary-excel-tab br-5">{data.name}</td>
                      <td className="summary-excel-tab br-5">{data.joining_date}</td>
                      {dates.map((date) => {
                        const attendanceForDate = data.attendances.find(
                          (attendance) => attendance.date === date.date
                        );
                        const attendanceStatus = attendanceForDate
                          ? attendanceForDate.day_status
                          : "-";

                        const attendanceStatuss = attendanceForDate
                          ? attendanceForDate.day_status === "ontime"
                            ? "P"
                            : attendanceForDate.day_status === "outduty"
                              ? "OD"
                              : attendanceForDate.day_status === "halfday"
                                ? "HD"
                                : attendanceForDate.day_status === "paidleave"
                                  ? "PL"
                                  : attendanceForDate.day_status === "late"
                                    ? "Late"
                                    : attendanceForDate.day_status === "weeklyoff"
                                      ? "WO"
                                      : attendanceForDate.day_status === "wop"
                                        ? "WOP"
                                        : attendanceForDate.day_status === "rlm"
                                          ? "RLM"
                                          : attendanceForDate.day_status === "halfdayleave"
                                            ? "HDL"
                                            : attendanceForDate.day_status === "unpaidleave"
                                              ? "UL"
                                              : attendanceForDate.day_status === "shortleave"
                                                ? "SL"
                                                : attendanceForDate.day_status ===
                                                  "unpaidshortleave"
                                                  ? "USL"
                                                  : attendanceForDate.day_status === "unpaidleavehd"
                                                    ? "UHD"
                                                    : attendanceForDate.day_status === "hdoutduty"
                                                      ? "HOD"
                                                      : attendanceForDate.day_status === "shortoutduty"
                                                        ? "SOD"
                                                        : attendanceForDate.day_status === "holiday"
                                                          ? "Hol"
                                                          : attendanceForDate.day_status === "absent"
                                                            ? "A"
                                                            : attendanceForDate.day_status.slice(0, 2)
                          : "-";

                        // // Example usage:
                        // <span>{attendanceStatus}</span>
                        // {attendanceStatuss.slice(0, 2).toUpperCase()}

                        const inTime = attendanceForDate
                          ? attendanceForDate.in_time
                          : "-";
                        const outTime = attendanceForDate
                          ? attendanceForDate.out_time
                          : "-";
                        const attstat = attendanceForDate
                          ? attendanceForDate.attendence_status
                          : "-";
                        const daystatus = attendanceForDate
                          ? attendanceForDate.day_status
                          : "-";

                        return (
                          // <>
                          <td
                            style={{
                              backgroundColor:
                                index === selectedIndex ? "yellow" : "",
                              color: index === selectedIndex ? "black" : "",
                            }}
                            data-status={attendanceStatus}
                            className="behavior summary-excel-tab"
                            title={`In Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\nDay Status: ${daystatus}`}
                          >
                            {attendanceStatuss}
                          </td>
                        );
                      })}
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: "" }}
                      >
                        {data.leavebalance}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: present }}
                      >
                        {data.present}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: ontime }}
                      >
                        {data.ontime}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: late }}
                      >
                        {data.late}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: rlm }}
                      >
                        {data.rlm}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: halfday }}
                      >
                        {data.halfday}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: wop }}
                      >
                        {data.wop}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: od }}
                      >
                        {data.outduty}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: bgabsent }}
                      >
                        {data.absent}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: holiday }}
                      >
                        {data.holiday}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: weeklyoff }}
                      >
                        {data.weeklyoff}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: leave }}
                      >
                        {data.leave}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: halfdayleave }}
                      >
                        {data.halfdayleave}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: shortleave }}
                      >
                        {data.shortleave}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: workfromhome }}
                      >
                        {data.workfromhome}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: hdoutduty }}
                      >
                        {data.hdoutduty}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: shortoutduty }}
                      >
                        {data.shortoutduty}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: unpaidleave }}
                      >
                        {data.unpaidleave}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: unpaidleavehd }}
                      >
                        {data.unpaidleavehd}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: unpaidshortleave }}
                      >
                        {data.unpaidshortleave}
                      </td>
                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: misspunch }}
                      >
                        {data.misspunch}
                      </td>

                      <td
                        className="summary-excel-tab br-5"
                        style={{ backgroundColor: bgtotalpaiddays }}
                      >
                        {data.total}
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>

      </div>
    </>
  );
};

const TlAttendance = () => {

  const [toggleState, setToggleState] = useState(1);


  return (
    <div>
      <div>
        <div >
          <div className="bloc-tabs-with-white-bg">
            <button
              className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
              onClick={() => setToggleState(1)}
            >
              Daily Attendance
            </button>
            <button
              className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
              onClick={() => setToggleState(2)}
            >
              Team Member Attendance
            </button>
            {/* <button
                    className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                    onClick={() => setToggleState(3)}
                >
                    Summary Sheet
                </button> */}
            <button
              className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
              onClick={() => setToggleState(4)}
            >
              Monthly Summary Sheet
            </button>

          </div>
        </div>

        <div className="content-tabs-without-margin">
          {toggleState === 1 ? (
            <DailyAttendance />
          ) : null}

          {toggleState === 2 ? <EmployeeAttendance /> : null}

          {toggleState === 3 ? <SummarySheet /> : null}
          {toggleState === 4 ? <SummarySheetExcel /> : null}



        </div>


      </div>

    </div>
  )
}

export default TlAttendance
