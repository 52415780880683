import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { formatDate, formattedDate } from "../Date";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import {
  RequisitionApprovedAdmin,
  RequisitionApprovedHR,
  RequisitionApprovedRH,
  RequisitionApprovedTLAdmin,
  RequisitionRejectAdmin,
  RequisitionRejectHR,
  RequisitionRejectRH,
  RequisitionRejectTLAdmin,
} from "../workforcemanagement/Requestapproved";
import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import { Active, CircularGrid, Filter, Office, Search, SearchBig } from "../AllSvg";
import { customSortByKey } from "../CustomFunctions";

const HiredPeopleModel = ({ i, getRequisitions }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };
  const [formData, setFormData] = useState({
    name_of_hired_person: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name_of_hired_person"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const updatedformvalue = {
        ...formData,
        hr_status: "approved",
        hired: true,
        hired_date: formattedDate,
        name_of_hired_person: formData.name_of_hired_person,
      };

      try {
        let res = await axios.put(
          `${BASE_URL}/jd/requisition/${i.id}/`,
          updatedformvalue
        );

        if (res.status === 200) {
          await getRequisitions();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  const [allemployee, setAllemployee] = useState([]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem('company_id')}/`);
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );

      const sortedList = customSortByKey(res.data, "emp_code");

      setAllemployee(sortedList);
      // setAllemployee(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);

  const options = allemployee
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.name,
      label: `${e.emp_code} - ${e.name}`,
    }));

  const handleSelectPeople = (selected) => {
    const hiredPeople =
      selected.length > 0 ? selected.map((item) => item.value).join(", ") : "";
    setFormData((prevState) => ({
      ...prevState,
      name_of_hired_person: hiredPeople,
    }));
  };

  // console.log(allemployee);
  return (
    <>
      <button
        className="model-edit-button"
        title="Hired Employees"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {/* <input type="checkbox" checked={i.hired} readOnly onClick={handleShow} className="form-checkbox" /> */}
      {/* <button onClick={handleShow}>
                <input type="checkbox" title="Hired Employees" checked={false} readOnly className="form-checkbox" />
            </button> */}

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Hired Personnel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="flex-column">
              <label htmlFor="off_days" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                Names of Person hired for {i.department_name} -{" "}
                {i.position_name}
              </label>

              <Select
                options={options}
                isMulti
                // value={selectedDays}
                onChange={handleSelectPeople}
              />
              {errors.remarks && (
                <span className="error-message font-size-text ">{errors.remarks}</span>
              )}
            </div>
            <div className="button-models">
              <button className="model-button   font-weight500   " onClick={handleClose}>
                Close
              </button>
              <button
                className="model-button   font-weight500    model-button-submit"
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};
const HiredPeopleModelCheck = ({ i, getRequisitions }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };
  const [formData, setFormData] = useState({
    name_of_hired_person: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name_of_hired_person"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const updatedformvalue = {
        ...formData,
        hired: true,
        name_of_hired_person: formData.name_of_hired_person,
      };

      try {
        let res = await axios.put(
          `${BASE_URL}/jd/requisition/${i.id}/`,
          updatedformvalue
        );

        if (res.status === 200) {
          await getRequisitions();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  const [allemployee, setAllemployee] = useState([]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem('company_id')}/`);
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );

      const sortedList = customSortByKey(res.data, "emp_code");

      setAllemployee(sortedList);
      // setAllemployee(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);

  const options = allemployee
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.name,
      label: `${e.emp_code} - ${e.name}`,
    }));

  const handleSelectPeople = (selected) => {
    const hiredPeople =
      selected.length > 0 ? selected.map((item) => item.value).join(", ") : "";
    setFormData((prevState) => ({
      ...prevState,
      name_of_hired_person: hiredPeople,
    }));
  };

  console.log(allemployee);
  return (
    <>
      {/* <button className="model-edit-button" title="Schedule" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                >
                    <g clip-path="url(#clip0_719_1134)">
                        <path
                            d="M16.4127 2.5126C16.2712 2.37122 16.0946 2.27012 15.9011 2.21969C15.7076 2.16927 15.5041 2.17135 15.3116 2.22572H15.3011L1.80528 6.32072C1.58559 6.3839 1.39037 6.51251 1.24559 6.68941C1.10082 6.86631 1.01337 7.08312 0.994893 7.31096C0.976415 7.53881 1.02778 7.76687 1.14216 7.96479C1.25653 8.16271 1.42849 8.3211 1.63512 8.41885L7.65528 11.27L10.5065 17.2902C10.5965 17.483 10.7398 17.646 10.9194 17.76C11.0991 17.874 11.3076 17.9342 11.5204 17.9335C11.5527 17.9335 11.585 17.9321 11.6174 17.9293C11.8444 17.9109 12.0604 17.8236 12.2364 17.6789C12.4123 17.5342 12.5398 17.3392 12.6018 17.12L16.694 3.62424C16.694 3.62072 16.694 3.61721 16.694 3.61369C16.749 3.42171 16.752 3.21853 16.7026 3.02502C16.6532 2.83151 16.5531 2.65465 16.4127 2.5126ZM11.5267 16.798L11.5232 16.8078L8.75567 10.9656L12.0772 7.6433C12.1783 7.53695 12.2338 7.39534 12.2319 7.24867C12.23 7.10199 12.1709 6.96185 12.0672 6.85812C11.9635 6.75439 11.8233 6.69529 11.6766 6.69341C11.53 6.69154 11.3883 6.74703 11.282 6.84807L7.95973 10.1696L2.11677 7.40213H2.12661L15.6168 3.30854L11.5267 16.798Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_719_1134">
                            <rect
                                width="18"
                                height="18"
                                fill="white"
                                transform="translate(0.429199 0.496094)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </button> */}
      {/* <input type="checkbox" checked={i.hired} readOnly onClick={handleShow} className="form-checkbox" /> */}
      <button onClick={handleShow}>
        <input
          type="checkbox"
          title="Hired Employees"
          checked={false}
          readOnly
          className="form-checkbox"
        />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Hired Personnel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="flex-column">
              <label htmlFor="off_days" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                Names of Person hired for {i.department_name} -{" "}
                {i.position_name}
              </label>

              <Select
                options={options}
                isMulti
                // value={selectedDays}
                onChange={handleSelectPeople}
              />
              {errors.remarks && (
                <span className="error-message font-size-text ">{errors.remarks}</span>
              )}
            </div>
            <div className="button-models">
              <button className="model-button   font-weight500   " onClick={handleClose}>
                Close
              </button>
              <button
                className="model-button   font-weight500    model-button-submit"
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const RequestRequisitionTableRH = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [requisitionList, setRequisitionName] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  // const [allemployee, setAllemployee] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const getRequisitions = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/jd/requisitionbyrh/${sessionStorage.getItem(
          "emp_code"
        )}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/${sessionStorage.getItem('company_id')}/`
      );
      setRequisitionName(res.data);
      console.log(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getRequisitions();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  // const getEmpcode = async () => {
  //     try {
  //         const res = await axios.get(`${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`);
  //         setAllemployee(res.data);
  //     } catch (err) {
  //         // alert(err.message);
  //     }
  // };

  // useEffect(() => {
  //     getEmpcode();
  // }, []);

  const [designationname, setDesignationname] = useState([]);

  const [departmentname, setDepartmentname] = useState([]);

  const getDesignation = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/designation/`);
      const ress = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(res.data);
      setDepartmentname(ress.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const applyFilters = () => {
    let filteredData = requisitionList;

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.department_name === selectedDep
      );
    }
    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.position_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }

    setFilteredList(filteredData);

    console.log(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [requisitionList, selectedDep, selectedDesi]);

  // *********** Employee Details Api End ***********

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              >
                <option value=""> All Department</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.title}>{i.title}</option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Designation"
                type="text"
                value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)}
              />
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Requisition Requests</div>
            {/* <div className="flex-row">
                <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
                <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
              </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">S. No.</th>
                <th className="align-center">Requested On Date</th>
                <th className="align-center">Department</th>
                <th className="align-center">Designation</th>
                <th className="align-center">Status</th>
                {/* <th className="align-center">Hired</th> */}
                <th className="align-right ">Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {filteredList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>

                      <td className="align-center">{formatDate(i.date)}</td>
                      <td className="align-center ">{i.department_name}</td>
                      <td className="align-center ">{i.position_name}</td>

                      <td className="align-center">
                        <span
                          request-status={i.rh_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              : i.rh_status === "approved" &&
                                i.admin_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved"
                                  ? "Admin ✔, "
                                  : "Admin Status: " + i.admin_status}
                        </span>
                        <br />
                        <span
                          request-status={i.hr_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.hr_status === "rejected"
                            ? "HR ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.hr_status === "pending"
                              ? "Pending For HR Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.hr_status === "rejected"
                                ? "HR ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.hr_status === "approved"
                                  ? "HR ✔, "
                                  : "HR Status: " + i.hr_status}
                        </span>
                      </td>
                      {/* <td className="align-left ">
                        <input type="checkbox" checked={i.hired} readOnly className="form-checkbox" />
                      </td> */}

                      <td className="align-right flex-row ">
                        {i.rh_assigned === sessionStorage.getItem("emp_code") ? (
                          <>
                            <RequisitionApprovedTLAdmin
                              i={i}
                              getRequisitions={getRequisitions}
                            />
                            <RequisitionRejectTLAdmin
                              i={i}
                              getRequisitions={getRequisitions}
                            />
                          </>
                        ) : (
                          <>
                            <RequisitionApprovedRH
                              i={i}
                              getRequisitions={getRequisitions}
                            />
                            <RequisitionRejectRH
                              i={i}
                              getRequisitions={getRequisitions}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestRequisitionTableAdmin = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(
    sessionStorage.getItem("company_id")
  );

  const [active, setActive] = useState("active");

  const [checkedItems, setCheckedItems] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [requisitionList, setRequisitionName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const getRequisitions = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/jd/requisitionbyadmin/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/${toggleCompany}/${active}/`
      );
      setRequisitionName(res.data);
      console.log(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }

  };

  useEffect(() => {
    getRequisitions();
  }, [fromdate, todate, selectedStatus, employeeGet, toggleCompany, active]);

  const [filteredList, setFilteredList] = useState([]);
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [designationname, setDesignationname] = useState([]);
  const [departmentname, setDepartmentname] = useState([]);

  const getDesignation = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/designation/`);
      const ress = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(res.data);
      setDepartmentname(ress.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const applyFilters = () => {
    let filteredData = requisitionList;

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.department_name === selectedDep
      );
    }
    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.position_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }

    setFilteredList(filteredData);

    console.log(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [requisitionList, selectedDep, selectedDesi]);

  // *********** Employee Details Api End ***********

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              >
                <option value=""> All Department</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.title}>{i.title}</option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Designation"
                type="text"
                value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            {PERMISSION_AUTHORITY
              ?
              <div className="field-cont-div">
                <Office />
                <select
                  onChange={(e) => setToggleCompany(e.target.value)}
                  value={toggleCompany}
                  className="attendance-input-field width-10vw   date-field"
                // className="dropdown font-size-subheading  "
                >
                  <option value="null">Select Sub Company</option>
                  {SUB_COMPANIES.map((company) => (
                    <option value={company.id}>{company.title}</option>
                  ))}
                  ;
                </select>

                <hr className="field-cont-hr" />
              </div> : null}


            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>




          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Requisition Request</div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">S. No.</th>
                <th className="align-center">Requested On Date</th>
                <th className="align-center">Department</th>
                <th className="align-center">Designation</th>
                <th className="align-center">Status</th>
                {/* <th className="align-center">Hired</th> */}
                <th className="align-right ">Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {filteredList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>

                      <td className="align-center">{formatDate(i.date)}</td>
                      <td className="align-center ">{i.department_name}</td>
                      <td className="align-center ">{i.position_name}</td>

                      <td className="align-center">
                        <span
                          request-status={i.rh_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              : i.rh_status === "approved" &&
                                i.admin_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved"
                                  ? "Admin ✔, "
                                  : "Admin Status: " + i.admin_status}
                        </span>
                        <br />
                        <span
                          request-status={i.hr_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.hr_status === "rejected"
                            ? "HR ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.hr_status === "pending"
                              ? "Pending For HR Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.hr_status === "rejected"
                                ? "HR ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.hr_status === "approved"
                                  ? "HR ✔, "
                                  : "HR Status: " + i.hr_status}
                        </span>
                      </td>
                      {/* <td className="align-left ">
                        <input type="checkbox" checked={i.hired} readOnly className="form-checkbox" />
                      </td> */}

                      <td className="align-right flex-row ">
                        <RequisitionApprovedAdmin
                          i={i}
                          getRequisitions={getRequisitions}
                        />

                        <RequisitionRejectAdmin
                          i={i}
                          getRequisitions={getRequisitions}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestRequisitionTableHR = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(
    sessionStorage.getItem("company_id")
  );
  const [buffer, setBuffering] = useState(true); //buffering logic


  const [active, setActive] = useState("active");


  const [checkedItems, setCheckedItems] = useState([]);

  // *********** Employee Details Api Start ***********
  const [requisitionList, setRequisitionName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const getRequisitions = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/jd/requisitionbyhr/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/${toggleCompany}/${active}/`
      );
      setRequisitionName(res.data);
      console.log(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getRequisitions();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  // *********** Employee Details Api End ***********

  const [filteredList, setFilteredList] = useState([]);
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [designationname, setDesignationname] = useState([]);
  const [departmentname, setDepartmentname] = useState([]);

  const getDesignation = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/designation/`);
      const ress = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(res.data);
      setDepartmentname(ress.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const applyFilters = () => {
    let filteredData = requisitionList;

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.department_name === selectedDep
      );
    }
    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.position_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }

    setFilteredList(filteredData);

    console.log(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [requisitionList, selectedDep, selectedDesi]);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              >
                <option value=""> All Department</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.title}>{i.title}</option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Designation"
                type="text"
                value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            {PERMISSION_AUTHORITY
              ?
              <div className="field-cont-div">
                <Office />
                <select
                  onChange={(e) => setToggleCompany(e.target.value)}
                  value={toggleCompany}
                  className="attendance-input-field width-10vw   date-field"
                // className="dropdown font-size-subheading  "
                >
                  <option value="null">Select Sub Company</option>
                  {SUB_COMPANIES.map((company) => (
                    <option value={company.id}>{company.title}</option>
                  ))}
                  ;
                </select>

                <hr className="field-cont-hr" />
              </div> : null}


            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
              <hr className="field-cont-hr" />
            </div>



          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Requisition Request</div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">S. No.</th>
                <th className="align-center">Requested On Date</th>
                <th className="align-center">Department</th>
                <th className="align-center">Designation</th>
                <th className="align-center">Status</th>
                <th className="align-center">Hired</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {filteredList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>

                      <td className="align-center">{formatDate(i.date)}</td>
                      <td className="align-center ">{i.department_name}</td>
                      <td className="align-center ">{i.position_name}</td>

                      <td className="align-center">
                        <span
                          request-status={i.rh_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              : i.rh_status === "approved" &&
                                i.admin_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved"
                                  ? "Admin ✔, "
                                  : "Admin Status: " + i.admin_status}
                        </span>
                        <br />
                        <span
                          request-status={i.hr_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.hr_status === "rejected"
                            ? "HR ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.hr_status === "pending"
                              ? "Pending For HR Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.hr_status === "rejected"
                                ? "HR ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.hr_status === "approved"
                                  ? "HR ✔, "
                                  : "HR Status: " + i.hr_status}
                        </span>
                      </td>
                      <td className="align-center ">
                        {i.hired ? (
                          i.name_of_hired_person
                        ) : (
                          <>
                            {i.hr_status === "pending" ? (
                              <>
                                <HiredPeopleModel
                                  i={i}
                                  getRequisitions={getRequisitions}
                                />
                                {/* <RequisitionApprovedHR i={i} getRequisitions={getRequisitions} /> */}
                                <RequisitionRejectHR
                                  i={i}
                                  getRequisitions={getRequisitions}
                                />
                              </>
                            ) : i.hr_status === "approved" ? (
                              <RequisitionRejectHR
                                i={i}
                                getRequisitions={getRequisitions}
                              />
                            ) : i.hr_status === "rejected" ? (
                              <HiredPeopleModel
                                i={i}
                                getRequisitions={getRequisitions}
                              />
                            ) : (
                              // <RequisitionApprovedHR i={i} getRequisitions={getRequisitions} />
                              ""
                            )}
                          </>
                        )}
                      </td>
                      {/* <td className="align-left ">
                                            {i.hired ?
                                                i.name_of_hired_person
                                                :
                                                <HiredPeopleModelCheck i={i} getRequisitions={getRequisitions} />
                                            }
                                        </td> */}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

export {
  RequestRequisitionTableRH,
  RequestRequisitionTableAdmin,
  RequestRequisitionTableHR,
};
