import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from 'react-toastify';
import usePermission from "../../config/permissions";
import { formattedDateLong } from "../Date";

// ! *************** Designation Crud Models Start ***************

const AddSiteDepartment = ({ getSiteDepartment, siteDepartmentList, siteSubCompanyList }) => {
  const [show, setShow] = useState(false);
  const { PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredList, setFilteredList] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    // Filter logic based on the input
    if (name === 'title') {
      const filtered = siteDepartmentList.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() && formData.title != null) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/siteproject/designation/`, formData);

        if (res.status === 200) {
          await getSiteDepartment();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        // alert(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Add Site Designation" className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Site Designation Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}

              </div>
              {PERMISSION_AUTHORITY ?
                <div className="flex-column">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    {siteSubCompanyList.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  {errors.title && (
                    <span className="error-message font-size-text ">{errors.title}</span>
                  )}
                </div>
                : null}
              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const UpdateSiteDepartment = ({ i, getSiteDepartment, siteDepartmentList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };

  const [filteredList, setFilteredList] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    // Filter logic based on the input
    if (name === 'title') {
      const filtered = siteDepartmentList.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/designation/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getSiteDepartment();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {

        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Update Site Designation" className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update Designation {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Designation Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="button-models">
                <button className="model-button   font-weight500    " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmitPut}
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteSiteDepartment = ({ i, getSiteDepartment }) => {
  const [show, setShow] = useState(false);
  const [inactiveDepartment, setInactiveDepartment] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/designation/${i.id}/`);

      if (res.status === 200) {
        await getSiteDepartment();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete Site Designation" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Designation {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete designation {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Designation Crud Models End ***************


// ! *************** Sub Company Crud Models Start ***************
const AddSiteSubcompanys = ({ getSiteSubcompanies }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/siteproject/subcompany/`, formData);

        if (res.status === 200) {
          setShow(false);
          await getSiteSubcompanies();
        } else {
          alert(res);
        }
      } catch (err) {
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  maxLength={50}
                  id="title"
                  name="title"
                  placeholder="Company Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                <div className="button-models">
                  <button className="model-button   font-weight500   " onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    //   type="submit"
                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    Submit
                  </button>
                </div>{" "}
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateSiteSubcompanyupdate = ({ i, getSiteSubcompanies }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };

  const [formData, setFormData] = useState({
    title: "",
  });


  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/subcompany/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          setShow(false);
          await getSiteSubcompanies();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update SubCompany {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  maxLength={50}
                  id="title"
                  name="title"
                  placeholder="SubCompany Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button   font-weight500    " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmitPut}
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteSiteSubcompany = ({ i, getSiteSubcompanies }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/subcompany/${i.id}/`);

      if (res.status === 200) {
        setShow(false);
        await getSiteSubcompanies();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete SubCompany {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete SubCompany {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SubComany Crud Models End ***************


// ! *************** Employee Crud Models Start ***************

const AddSiteEmployee = ({ projectId, getSiteEmployee, siteDepartmentList, siteProjectList, siteSubCompanyList }) => {

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({

    emp_code: "",
    name: "",
    email: "",
    mobile: 0,
    designation: "",
    professional_type: "",
    current_address: "",
    joining_date: "",
    employee_status: "",
    sub_company: "",
    project: projectId

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "name",
      "email",
      "mobile",
      "designation",
      "professional_type",
      "current_address",
      "joining_date",
      "sub_company",
      "employee_status"
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(`${BASE_URL}/siteproject/employee/`, formData,)

        if (res.status === 200) {
          await getSiteEmployee()
          handleClose()
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>

      <button className="models-button model-add" onClick={handleShow} style={{ margin: 0 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Add Site Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/*  ************** timeline Seaction  ************** */}

          <>


            <form className="register-form">

              <div className="form-flex-wrap">

                <div title="Emp Code" className="flex-column">
                  <label htmlFor="emp_code" className="form-labels  font-weight500    font-size-subheading">
                    Emp Code<span className="required">*</span>
                  </label>
                  <input
                    maxLength={20}
                    type="text"
                    id="emp_code"
                    name="emp_code"
                    placeholder="Emp Code"
                    onChange={handleInputChange}
                    value={formData.emp_code}
                    className={`form-input ${errors.emp_code
                      ? "error"
                      : inputState.emp_code
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.emp_code && (
                    <span className="error-message font-size-text ">{errors.emp_code}</span>
                  )}
                </div>

                <div title="Name" className="flex-column">
                  <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                    Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={100}
                    id="name"
                    name="name"
                    placeholder="Name"
                    onChange={handleInputChange}
                    value={formData.name}
                    className={`form-input ${errors.name
                      ? "error"
                      : inputState.name
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.name && (
                    <span className="error-message font-size-text ">{errors.name}</span>
                  )}
                </div>

                <div title="Email" className="flex-column">
                  <label htmlFor="email" className="form-labels  font-weight500    font-size-subheading">
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Demo@gmail.com"
                    onChange={handleInputChange}
                    value={formData.email}
                    onInput={(e) => {
                      e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                    }}
                    className={`form-input ${errors.email
                      ? "error"
                      : inputState.email
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.email && (
                    <span className="error-message font-size-text ">{errors.email}</span>
                  )}
                </div>

                <div title="Mobile No." className="flex-column">
                  <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                    Mobile No.<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    maxLength={10}
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile Number."
                    onChange={handleInputChange}
                    value={formData.mobile}
                    className={`form-input ${errors.mobile
                      ? "error"
                      : inputState.mobile
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.mobile && (
                    <span className="error-message font-size-text ">{errors.mobile}</span>
                  )}
                </div>

                <div title="Site Designation" className="flex-column form-group-selectt">
                  <label htmlFor="designation" className="form-labels  font-weight500    font-size-subheading">
                    Site Designation<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="designation"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                      }`}
                  >
                    <option value="">Select Designation</option>
                    {siteDepartmentList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.title}</option>
                    </>))
                    }
                  </select>

                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.designation && (
                      <span className="error-message font-size-text ">{errors.designation}</span>
                    )}
                  </div>
                </div>

                <div title="Professional Type" className="flex-column form-group-selectt">
                  <label htmlFor="professional_type" className="form-labels  font-weight500    font-size-subheading">
                    Employee Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="professional_type"
                    name="professional_type"
                    value={formData.professional_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.professional_type ? "error" : inputState.professional_type ? "success" : ""
                      }`}
                  >
                    <option value="null">Select a Professional </option>
                    <option value="Key Professional">Key Professional</option>
                    <option value="Sub Professional">Sub Professional</option>
                    <option value="Support Staff">Support Staff</option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.professional_type && (
                      <span className="error-message font-size-text ">{errors.professional_type}</span>
                    )}
                  </div>
                </div>


                <div title="Site Sub Company" className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Site Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Site SubCompany</option>
                    {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.title}</option>
                    </>))
                    }
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.sub_company && (
                      <span className="error-message font-size-text ">{errors.sub_company}</span>
                    )}
                  </div>
                </div>

                <div title="Employee Status" className="flex-column form-group-selectt">
                  <label htmlFor="employee_status" className="form-labels  font-weight500    font-size-subheading">
                    Employee Status<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="employee_status"
                    name="employee_status"
                    value={formData.employee_status}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.employee_status ? "error" : inputState.employee_status ? "success" : ""
                      }`}
                  >
                    <option value="">Select Option</option>
                    <option value="active">Active</option>
                    <option value="inactive">In Active</option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.employee_status && (
                      <span className="error-message font-size-text ">{errors.employee_status}</span>
                    )}
                  </div>
                </div>

                <div title="Joining Date" className="flex-column">
                  <label htmlFor="joining_date" className="form-labels  font-weight500    font-size-subheading">
                    Joining Date<span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    id="joining_date"
                    name="joining_date"
                    onChange={handleInputChange}
                    value={formData.joining_date}
                    className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                      }`}
                  />
                  {errors.joining_date && <span className="error-message font-size-text ">{errors.joining_date}</span>}
                </div>

                <div title="Site Projects" className="flex-column form-group-selectt">
                  <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                    Site Projects<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="project"
                    name="project"
                    value={formData.project}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Project</option>
                    {siteProjectList?.sort((a, b) => a.project_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.site_prcode}-{i.project_name}</option>
                    </>))
                    }
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.project && (
                      <span className="error-message font-size-text ">{errors.project}</span>
                    )}
                  </div>
                </div>

                <div title="Current Address" className="flex-column">
                  <label htmlFor="current_address" className="form-labels  font-weight500    font-size-subheading">
                    Current Address<span className="required">*</span>
                  </label>
                  <textarea
                    type="text"
                    id="current_address"
                    name="current_address"
                    maxLength={300}
                    placeholder="Current Address"
                    onChange={handleInputChange}
                    value={formData.current_address}
                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.current_address
                      ? "error"
                      : inputState.current_address
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.current_address && (
                    <span className="error-message font-size-text ">{errors.current_address}</span>
                  )}
                </div>



              </div>

              <div className="button-models">


                <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                  Submit
                </button>
              </div>
            </form>
          </>

          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>

    </>
  );
};

const UpdateSiteEmployee = ({ i, getSiteEmployee, siteDepartmentList, siteProjectList, siteSubCompanyList }) => {

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };

  const [formData, setFormData] = useState({

    emp_code: "",
    name: "",
    email: "",
    mobile: 0,
    designation: "",
    professional_type: "",
    current_address: "",
    joining_date: "",
    employee_status: "",
    sub_company: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "name",
      "designation",
      "professional_type",
      "current_address",
      "mobile",
      "email",
      "joining_date",
      "sub_company",
      "employee_status"
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/siteproject/employee/${i.id}/`, formData)

        if (res.status === 200) {
          await getSiteEmployee()
          handleClose()
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>

      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Update Site Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/*  ************** timeline Seaction  ************** */}

          <>


            <form className="register-form">

              <div className="form-flex-wrap">

                <div title="Emp Code" className="flex-column">
                  <label htmlFor="emp_code" className="form-labels  font-weight500    font-size-subheading">
                    Emp Code<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="emp_code"
                    maxLength={20}
                    name="emp_code"
                    placeholder="Emp Code"
                    onChange={handleInputChange}
                    value={formData.emp_code}
                    className={`form-input ${errors.emp_code
                      ? "error"
                      : inputState.emp_code
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.emp_code && (
                    <span className="error-message font-size-text ">{errors.emp_code}</span>
                  )}
                </div>

                <div title="Name" className="flex-column">
                  <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                    Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={100}
                    id="name"
                    name="name"
                    placeholder="Name"
                    onChange={handleInputChange}
                    value={formData.name}
                    className={`form-input ${errors.name
                      ? "error"
                      : inputState.name
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.name && (
                    <span className="error-message font-size-text ">{errors.name}</span>
                  )}
                </div>

                <div title="Email" className="flex-column">
                  <label htmlFor="email" className="form-labels  font-weight500    font-size-subheading">
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Demo@gmail.com"
                    onChange={handleInputChange}
                    value={formData.email}
                    onInput={(e) => {
                      e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                    }}
                    className={`form-input ${errors.email
                      ? "error"
                      : inputState.email
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.email && (
                    <span className="error-message font-size-text ">{errors.email}</span>
                  )}
                </div>

                <div title="Mobile No." className="flex-column">
                  <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                    Mobile No.<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    maxLength={10}
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile Number."
                    onChange={handleInputChange}
                    value={formData.mobile}
                    className={`form-input ${errors.mobile
                      ? "error"
                      : inputState.mobile
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.mobile && (
                    <span className="error-message font-size-text ">{errors.mobile}</span>
                  )}
                </div>

                <div title="Site Designation" className="flex-column form-group-selectt">
                  <label htmlFor="designation" className="form-labels  font-weight500    font-size-subheading">
                    Site Designation<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="designation"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                      }`}
                  >
                    <option value="">Select Designation</option>
                    {siteDepartmentList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.title}</option>
                    </>))
                    }
                  </select>

                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.designation && (
                      <span className="error-message font-size-text ">{errors.designation}</span>
                    )}
                  </div>
                </div>

                <div title="Professional Type" className="flex-column form-group-selectt">
                  <label htmlFor="professional_type" className="form-labels  font-weight500    font-size-subheading">
                    Employee Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="professional_type"
                    name="professional_type"
                    value={formData.professional_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.professional_type ? "error" : inputState.professional_type ? "success" : ""
                      }`}
                  >
                    <option value="null">Select a Professional </option>
                    <option value="Key Professional">Key Professional</option>
                    <option value="Sub Professional">Sub Professional</option>
                    <option value="Support Staff">Support Staff</option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.professional_type && (
                      <span className="error-message font-size-text ">{errors.professional_type}</span>
                    )}
                  </div>
                </div>

                <div title="Site Sub Company" className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Site Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Site SubCompany</option>
                    {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.title}</option>
                    </>))
                    }
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.sub_company && (
                      <span className="error-message font-size-text ">{errors.sub_company}</span>
                    )}
                  </div>
                </div>

                <div title="Employee Status" className="flex-column form-group-selectt">
                  <label htmlFor="employee_status" className="form-labels  font-weight500    font-size-subheading">
                    Employee Status<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="employee_status"
                    name="employee_status"
                    value={formData.employee_status}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.employee_status ? "error" : inputState.employee_status ? "success" : ""
                      }`}
                  >
                    <option value="">Select Option</option>
                    <option value="active">Active</option>
                    <option value="inactive">In Active</option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.employee_status && (
                      <span className="error-message font-size-text ">{errors.employee_status}</span>
                    )}
                  </div>
                </div>


                <div title="Joining Date" className="flex-column">
                  <label htmlFor="joining_date" className="form-labels  font-weight500    font-size-subheading">
                    Joining Date<span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    id="joining_date"
                    name="joining_date"
                    onChange={handleInputChange}
                    value={formData.joining_date}
                    className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                      }`}
                  />
                  {errors.joining_date && <span className="error-message font-size-text ">{errors.joining_date}</span>}
                </div>

                <div title="Site Projects" className="flex-column form-group-selectt">
                  <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                    Site Projects<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="project"
                    name="project"
                    value={formData.project}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Project</option>
                    {siteProjectList?.sort((a, b) => a.project_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.site_prcode}-{i.project_name}</option>
                    </>))
                    }
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.project && (
                      <span className="error-message font-size-text ">{errors.project}</span>
                    )}
                  </div>
                </div>

                <div title="Current Address" className="flex-column">
                  <label htmlFor="current_address" className="form-labels  font-weight500    font-size-subheading">
                    Current Address<span className="required">*</span>
                  </label>
                  <textarea
                    type="text"
                    id="current_address"
                    name="current_address"
                    maxLength={300}
                    placeholder="Current Address"
                    onChange={handleInputChange}
                    value={formData.current_address}
                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.current_address
                      ? "error"
                      : inputState.current_address
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.current_address && (
                    <span className="error-message font-size-text ">{errors.current_address}</span>
                  )}
                </div>


              </div>

              <div className="button-models">


                <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                  Submit
                </button>
              </div>
            </form></>

          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>

    </>
  );
};

const DeleteSiteEmployee = ({ i, getSiteEmployee }) => {
  const [show, setShow] = useState(false);
  const [inactiveDepartment, setInactiveDepartment] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/employee/${i.id}/`);

      if (res.status === 200) {
        await getSiteEmployee();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete Site Designation" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Employee {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete {i.name}'s data?
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Employee Crud Models End ***************


// ! *************** Project Crud Models Start ***************

const AddProjectDetails = ({ i, getProjects, siteSubCompanyList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    // setFormData(i);
    setShow(true)
  };

  const [formData, setFormData] = useState({
    site_prcode: "",
    project_name: "",
    project_short_name: "",
    location: "",
    project_length: "",
    sub_company: sessionStorage.getItem('company_id'),
    award_date: "",
    start_date: "",
    concern_person: "",
    concern_person_name: "",
    tender_id: "",
    actual_completion_date: "",
    schedule_completion_date: "",
    construction_cost: "",
    remark: "",
    consultancy_fees: "",
    contract_mode: "",
    lead: "",
    jv: "",
    association: "",
    work_performed_by: "",
    jv_share: "",
    today_status: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "site_prcode",
      "project_name",
      "sub_company"
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(`${BASE_URL}/siteproject/project/`, formData,)

        if (res.status === 200) {
          setShow(false)
          await getProjects()
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title='Add Project' className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Add Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">

              <div title="Project Code" className="flex-column">
                <label htmlFor="site_prcode" className="form-labels  font-weight500    font-size-subheading">
                  Project Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="site_prcode"
                  name="site_prcode"
                  placeholder="Project Code"
                  onChange={handleInputChange}
                  value={formData.site_prcode}
                  className={`form-text-trasformation-uppercase form-input ${errors.site_prcode ? "error" : inputState.site_prcode ? "success" : ""}`}
                />
                {errors.site_prcode && <span className="error-message font-size-text ">{errors.site_prcode}</span>}
              </div>

              <div title="Project Name" className="flex-column">
                <label htmlFor="project_name" className="form-labels  font-weight500    font-size-subheading">
                  Project Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="project_name"
                  name="project_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  className={`form-input ${errors.project_name ? "error" : inputState.project_name ? "success" : ""}`}
                />
                {errors.project_name && <span className="error-message font-size-text ">{errors.project_name}</span>}
              </div>

              <div title="Project Short Name" className="flex-column">
                <label htmlFor="project_short_name" className="form-labels  font-weight500    font-size-subheading">
                  Short Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="project_short_name"
                  name="project_short_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_short_name}
                  className={`form-input ${errors.project_short_name ? "error" : inputState.project_short_name ? "success" : ""}`}
                />
                {errors.project_short_name && <span className="error-message font-size-text ">{errors.project_short_name}</span>}
              </div>

              <div title="Location" className="flex-column">
                <label htmlFor="location" className="form-labels  font-weight500    font-size-subheading">
                  Location
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Location"
                  onChange={handleInputChange}
                  value={formData.location}
                  className={`form-input ${errors.location ? "error" : inputState.location ? "success" : ""}`}
                />
                {errors.location && <span className="error-message font-size-text ">{errors.location}</span>}
              </div>

              <div title="Site Sub Company" className="flex-column form-group-selectt">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Site Sub Company<span className="required">*</span>
                </label>
                <br />
                <select
                  id="sub_company"
                  name="sub_company"
                  placeholder="Site SubCompany"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""}`}
                >
                  <option value="">Select a Site SubCompany</option>
                  {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.title}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.sub_company && <span className="error-message font-size-text ">{errors.sub_company}</span>}
              </div>

              <div title="Award Date" className="flex-column">
                <label htmlFor="award_date" className="form-labels  font-weight500    font-size-subheading">
                  Award Date
                </label>
                <input
                  type="date"
                  id="award_date"
                  name="award_date"
                  placeholder="Award Date"
                  onChange={handleInputChange}
                  value={formData.award_date}
                  className={`form-input ${errors.award_date ? "error" : inputState.award_date ? "success" : ""}`}
                />
                {errors.award_date && <span className="error-message font-size-text ">{errors.award_date}</span>}
              </div>

              <div title="Start Date" className="flex-column">
                <label htmlFor="start_date" className="form-labels  font-weight500    font-size-subheading">
                  Start Date
                </label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  placeholder="Start Date"
                  onChange={handleInputChange}
                  value={formData.start_date}
                  className={`form-input ${errors.start_date ? "error" : inputState.start_date ? "success" : ""}`}
                />
                {errors.start_date && <span className="error-message font-size-text ">{errors.start_date}</span>}
              </div>

              <div title="Concern Person" className="flex-column">
                <label htmlFor="concern_person" className="form-labels  font-weight500    font-size-subheading">
                  Concern Person
                </label>
                <input
                  type="text"
                  id="concern_person"
                  name="concern_person"
                  placeholder="Concern Person Code"
                  onChange={handleInputChange}
                  value={formData.concern_person}
                  className={`form-input ${errors.concern_person ? "error" : inputState.concern_person ? "success" : ""}`}
                />
                {errors.concern_person && <span className="error-message font-size-text ">{errors.concern_person}</span>}
              </div>

              <div title="Concern Person Name" className="flex-column">
                <label htmlFor="concern_person_name" className="form-labels  font-weight500    font-size-subheading">
                  Concern Person Name
                </label>
                <input
                  type="text"
                  id="concern_person_name"
                  name="concern_person_name"
                  placeholder="Concern Person Name"
                  onChange={handleInputChange}
                  value={formData.concern_person_name}
                  className={`form-input ${errors.concern_person_name ? "error" : inputState.concern_person_name ? "success" : ""}`}
                />
                {errors.concern_person_name && <span className="error-message font-size-text ">{errors.concern_person_name}</span>}
              </div>

              <div title="Project Length" className="flex-column">
                <label htmlFor="project_length" className="form-labels  font-weight500    font-size-subheading">
                  Project Length
                </label>
                <input
                  type="number"
                  maxLength={10}
                  min={0}
                  id="project_length"
                  name="project_length"
                  placeholder="Project Length"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input ${errors.project_length ? "error" : inputState.project_length ? "success" : ""}`}
                />
                {errors.project_length && <span className="error-message font-size-text ">{errors.project_length}</span>}
              </div>

              <div title="Tender ID" className="flex-column">
                <label htmlFor="tender_id" className="form-labels  font-weight500    font-size-subheading">
                  Tender ID
                </label>
                <input
                  type="text"
                  id="tender_id"
                  name="tender_id"
                  placeholder="Tender ID"
                  onChange={handleInputChange}
                  value={formData.tender_id}
                  className={`form-input ${errors.tender_id ? "error" : inputState.tender_id ? "success" : ""}`}
                />
                {errors.tender_id && <span className="error-message font-size-text ">{errors.tender_id}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="actual_completion_date" className="form-labels  font-weight500    font-size-subheading">
                  Actual Completion Date
                </label>
                <input
                  type="date"
                  id="actual_completion_date"
                  name="actual_completion_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.actual_completion_date}
                  className={`form-input ${errors.actual_completion_date ? "error" : inputState.actual_completion_date ? "success" : ""}`}
                />
                {errors.actual_completion_date && <span className="error-message font-size-text">{errors.actual_completion_date}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="bid_due_date" className="form-labels  font-weight500    font-size-subheading">
                  Bid Due Date
                </label>
                <input
                  type="date"
                  id="bid_due_date"
                  name="bid_due_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.bid_due_date}
                  className={`form-input ${errors.bid_due_date ? "error" : inputState.bid_due_date ? "success" : ""}`}
                />
                {errors.bid_due_date && <span className="error-message font-size-text">{errors.bid_due_date}</span>}
              </div>

              <div title="Target Date" className="flex-column">
                <label htmlFor="schedule_completion_date" className="form-labels  font-weight500   ">
                  Schedule Completion Date
                </label>
                <input
                  type="date"
                  id="schedule_completion_date"
                  name="schedule_completion_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.schedule_completion_date}
                  className={`form-input ${errors.schedule_completion_date ? "error" : inputState.schedule_completion_date ? "success" : ""}`}
                />
                {errors.schedule_completion_date && <span className="error-message">{errors.schedule_completion_date}</span>}
              </div>



              <div title="Construction Cost" className="flex-column">
                <label htmlFor="construction_cost" className="form-labels  font-weight500    font-size-subheading">
                  Construction Cost
                </label>
                <input
                  type="number"
                  min={0}
                  id="construction_cost"
                  name="construction_cost"
                  placeholder="Construction Cost"
                  onChange={handleInputChange}
                  value={formData.construction_cost}
                  className={`form-input ${errors.construction_cost ? "error" : inputState.construction_cost ? "success" : ""}`}
                />
                {errors.construction_cost && <span className="error-message font-size-text ">{errors.construction_cost}</span>}
              </div>

              <div title="Remark" className="flex-column">
                <label htmlFor="remark" className="form-labels  font-weight500    font-size-subheading">
                  Remark
                </label>
                <input
                  type="text"
                  id="remark"
                  name="remark"
                  placeholder="Remark"
                  onChange={handleInputChange}
                  value={formData.remark}
                  className={`form-input ${errors.remark ? "error" : inputState.remark ? "success" : ""}`}
                />
                {errors.remark && <span className="error-message font-size-text ">{errors.remark}</span>}
              </div>

              <div title="Consultancy Fees" className="flex-column">
                <label htmlFor="consultancy_fees" className="form-labels  font-weight500    font-size-subheading">
                  Consultancy Fees
                </label>
                <input
                  type="number"
                  min={0}
                  id="consultancy_fees"
                  name="consultancy_fees"
                  placeholder="Consultancy Fees"
                  onChange={handleInputChange}
                  value={formData.consultancy_fees}
                  className={`form-input ${errors.consultancy_fees ? "error" : inputState.consultancy_fees ? "success" : ""}`}
                />
                {errors.consultancy_fees && <span className="error-message font-size-text">{errors.consultancy_fees}</span>}
              </div>

              <div title="Contract Mode" className="flex-column">
                <label htmlFor="contract_mode" className="form-labels  font-weight500    font-size-subheading">
                  Contract Mode
                </label>
                <input
                  type="text"
                  id="contract_mode"
                  name="contract_mode"
                  placeholder="Contract Mode"
                  onChange={handleInputChange}
                  value={formData.contract_mode}
                  className={`form-input ${errors.contract_mode ? "error" : inputState.contract_mode ? "success" : ""}`}
                />
                {errors.contract_mode && <span className="error-message font-size-text ">{errors.contract_mode}</span>}
              </div>

              <div title="Lead" className="flex-column">
                <label htmlFor="lead" className="form-labels  font-weight500    font-size-subheading">
                  Lead
                </label>
                <input
                  type="text"
                  id="lead"
                  name="lead"
                  placeholder="Lead"
                  onChange={handleInputChange}
                  value={formData.lead}
                  className={`form-input ${errors.lead ? "error" : inputState.lead ? "success" : ""}`}
                />
                {errors.lead && <span className="error-message font-size-text ">{errors.lead}</span>}
              </div>

              <div title="JV" className="flex-column">
                <label htmlFor="jv" className="form-labels  font-weight500    font-size-subheading">
                  JV
                </label>
                <input
                  type="text"
                  id="jv"
                  name="jv"
                  placeholder="JV"
                  onChange={handleInputChange}
                  value={formData.jv}
                  className={`form-input ${errors.jv ? "error" : inputState.jv ? "success" : ""}`}
                />
                {errors.jv && <span className="error-message font-size-text ">{errors.jv}</span>}
              </div>

              <div title="Association" className="flex-column">
                <label htmlFor="association" className="form-labels  font-weight500    font-size-subheading">
                  Association
                </label>
                <input
                  type="text"
                  id="association"
                  name="association"
                  placeholder="Association"
                  onChange={handleInputChange}
                  value={formData.association}
                  className={`form-input ${errors.association ? "error" : inputState.association ? "success" : ""}`}
                />
                {errors.association && <span className="error-message font-size-text ">{errors.association}</span>}
              </div>

              <div title="Work Performed By" className="flex-column">
                <label htmlFor="work_performed_by" className="form-labels  font-weight500    font-size-subheading">
                  Contractor Name
                </label>
                <input
                  type="text"
                  id="work_performed_by"
                  name="work_performed_by"
                  placeholder="Performed By"
                  onChange={handleInputChange}
                  value={formData.work_performed_by}
                  className={`form-input ${errors.work_performed_by ? "error" : inputState.work_performed_by ? "success" : ""}`}
                />
                {errors.work_performed_by && <span className="error-message font-size-text ">{errors.work_performed_by}</span>}
              </div>

              <div title="JV Share" className="flex-column">
                <label htmlFor="jv_share" className="form-labels  font-weight500    font-size-subheading">
                  JV Share
                </label>
                <input
                  type="text"
                  id="jv_share"
                  name="jv_share"
                  placeholder="JV Share"
                  onChange={handleInputChange}
                  value={formData.jv_share}
                  className={`form-input ${errors.jv_share ? "error" : inputState.jv_share ? "success" : ""}`}
                />
                {errors.jv_share && <span className="error-message font-size-text ">{errors.jv_share}</span>}
              </div>

              <div title="Status" className="flex-column">
                <label htmlFor="today_status" className="form-labels  font-weight500    font-size-subheading">
                  Status
                </label>
                <input
                  type="text"
                  id="today_status"
                  name="today_status"
                  placeholder="Status"
                  onChange={handleInputChange}
                  value={formData.today_status}
                  className={`form-input ${errors.today_status ? "error" : inputState.today_status ? "success" : ""}`}
                />
                {errors.today_status && <span className="error-message font-size-text ">{errors.today_status}</span>}
              </div>
            </div>

            <div className="button-models">
              <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                Submit
              </button>
            </div>

          </form>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateProjectDetails = ({ i, getProjects, siteSubCompanyList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    getProjects();
    setShow(false);
  };
  const handleShow = () => {
    getProjectDetails();
    setFormData(i);
    setShow(true);
  };


  const [projectData, setProjectData] = useState();


  const [buffer, setBuffering] = useState(true); //buffering logic

  const getProjectDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const proj = await axios.get(`${BASE_URL}/siteproject/project/${i.id}/`);
      setProjectData(proj.data);

    }
    catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const [formData, setFormData] = useState({
    site_prcode: "",
    project_name: "",
    project_short_name: "",
    location: "",
    project_length: "",
    award_date: "",
    start_date: "",
    concern_person: "",
    concern_person_name: "",
    tender_id: "",
    actual_completion_date: "",
    construction_cost: "",
    remark: "",
    consultancy_fees: "",
    contract_mode: "",
    lead: "",
    jv: "",
    association: "",
    work_performed_by: "",
    jv_share: "",
    today_status: "",
    sub_company: ""
  });



  // ! EOT start ===============================

  const [showAddEot, setShowAddEot] = useState(false);
  const handleCloseEot = () => setShowAddEot(false);
  const handleShowEot = () => setShowAddEot(true);

  const [eotData, setEotData] = useState({
    project: i?.id,
    eot_code: "",
    approved_date: "",
    approved_days: "",
    workperformedby: "",
  });


  const [eoterrors, setEotErrors] = useState({});
  const [eotinputState, setEotInputState] = useState({});

  const validateEotForm = () => {
    const newEotErrors = {};
    const eotRequiredFields = [
      "project",
      "eot_code",
      "approved_date",
      "approved_days",
      "workperformedby",
    ];

    eotRequiredFields.forEach((field) => {
      if (!eotData[field]) {
        newEotErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setEotErrors(newEotErrors);
    toast.warning(newEotErrors);

    return Object.keys(newEotErrors).length === 0;

  };

  const handleEotChange = (e) => {
    const { name, value } = e.target;

    setEotInputState({
      ...eotinputState,
      [name]: value.trim() ? "green" : "",
    });

    setEotData({
      ...eotData,
      [name]: value,
    });
  };


  const handleEotPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateEotForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/eot/`, eotData)

        if (res.status === 200) {
          await getProjectDetails()
          handleCloseEot();
          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleEotDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/eot/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? EOT End ===============================


  // ! COS start ===============================


  const [showAddCos, setShowAddCos] = useState(false);
  const handleCloseCos = () => setShowAddCos(false);
  const handleShowCos = () => setShowAddCos(true);

  const [cosData, setCosData] = useState({
    project: i?.id,
    cos_code: "",
    approval_date: "",
    amount: "",
    workperformedby: "",
  });


  const [coserrors, setCosErrors] = useState({});
  const [cosinputState, setCosInputState] = useState({});

  const validateCosForm = () => {
    const newCosErrors = {};
    const cosRequiredFields = [
      "project",
      "cos_code",
      "approval_date",
      "amount",
      "workperformedby",
    ];

    cosRequiredFields.forEach((field) => {
      if (!cosData[field]) {
        newCosErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setCosErrors(newCosErrors);
    toast.warning(newCosErrors);

    return Object.keys(newCosErrors).length === 0;

  };

  const handleCosChange = (e) => {
    const { name, value } = e.target;

    setCosInputState({
      ...cosinputState,
      [name]: value.trim() ? "green" : "",
    });

    setCosData({
      ...cosData,
      [name]: value,
    });
  };


  const handleCosPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateCosForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/cos/`, cosData)

        if (res.status === 200) {
          await getProjectDetails()
          handleCloseCos();
          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleCosDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/cos/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? Cos End ===============================



  // ! BillingDetail start ===============================


  const [showAddBill, setShowAddBill] = useState(false);
  const handleCloseBill = () => setShowAddBill(false);
  const handleShowBill = () => setShowAddBill(true);

  const [billData, setBillData] = useState({
    project: i?.id,
    billing_date: "",
    billing_amount: "",
  });


  const [billerrors, setBillErrors] = useState({});
  const [billinputState, setBillInputState] = useState({});

  const validateBillForm = () => {
    const newBillErrors = {};
    const billRequiredFields = [
      "project",
      "billing_date",
      "billing_amount",
    ];

    billRequiredFields.forEach((field) => {
      if (!billData[field]) {
        newBillErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setBillErrors(newBillErrors);
    toast.warning(newBillErrors);

    return Object.keys(newBillErrors).length === 0;

  };

  const handleBillChange = (e) => {
    const { name, value } = e.target;

    setBillInputState({
      ...billinputState,
      [name]: value.trim() ? "green" : "",
    });

    setBillData({
      ...billData,
      [name]: value,
    });
  };


  const handleBillPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateBillForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/billingdetail/`, billData)

        if (res.status === 200) {
          await getProjectDetails()
          handleCloseBill();
          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleBillDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/billingdetail/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? BillingDetail End ===============================



  // ! Pic Start ===============================

  const [showAddPic, setShowAddPic] = useState(false);
  const handleClosePic = () => setShowAddPic(false);
  const handleShowPic = () => setShowAddPic(true);

  const [imageData, setimageData] = useState({
    project: i?.id,
    document_name: "",
    document_file: "",
  });


  const [picerrors, setpicErrors] = useState({});
  const [picinputState, setpicInputState] = useState({});

  const validatePicForm = () => {
    const newpicErrors = {};
    const picRequiredFields = [
      "project",
      "document_name",
      "document_file",
    ];

    picRequiredFields.forEach((field) => {
      if (!imageData[field]) {
        newpicErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setpicErrors(newpicErrors);
    toast.warning(newpicErrors);

    return Object.keys(newpicErrors).length === 0;

  };

  const handleDocInputChange = (e) => {
    const { name, value } = e.target;

    setpicInputState({
      ...picinputState,
      [name]: value.trim() ? "green" : "",
    });
    setimageData({
      ...imageData,
      [name]: value,
      // 'pic': e.target.files[0]
    });
  };
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      setimageData({ ...imageData, [name]: file, });
      setFileName(file.name);
      // Additional logic for handling the file can be added here
    } else {
      // setimageData({...imageData,[name]: file,});
      setFileName("");
    }
  };

  const handlePicPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validatePicForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("project", imageData.project);
      formDataa.append("document_name", imageData.document_name);

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = imageData.document_file;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });


      formDataa.append("document_file", customFile);

      try {

        let res = await axios.post(`${BASE_URL}/website/projectimage/`, formDataa)

        if (res.status === 200) {
          await getProjectDetails()
          handleClosePic();

          // Clear formDataa
          formDataa.delete("project");
          formDataa.delete("document_name");
          formDataa.delete("document_file");

          // Reset imageData (assuming imageData is a state managed with useState)
          setimageData({
            project: '',
            document_name: '',
            document_file: null
          });
          setFileName("");

          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handlePicDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/website/projectimage/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? Pic End ===============================



  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      "site_prcode",
      "project_name",
      "sub_company",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;

  };
  const formatNumberToIndian = (number) => {
    const numStr = number.toString();
    const lastThreeDigits = numStr.slice(-3);
    const otherDigits = numStr.slice(0, -3);
    const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    return otherDigits ? `${formattedOtherDigits},${lastThreeDigits}` : lastThreeDigits;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNumInputChange = (e) => {
    const { name, value } = e.target;

    // Remove existing commas from the input value
    const numericValue = value.replace(/,/g, '');

    // Check if the value is a valid number
    if (!isNaN(numericValue) && numericValue.trim() !== '') {
      // Format the number with commas in the Indian numbering system
      const formattedValue = formatNumberToIndian(numericValue);

      // Update inputState based on whether the input is non-empty
      setInputState({
        ...inputState,
        [name]: formattedValue.trim() ? "green" : "",
      });

      // Update formData with the formatted value
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
    } else {
      // If the value is not a number or is empty, update formData and inputState accordingly
      setInputState({
        ...inputState,
        [name]: "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleProjectUpdate = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/siteproject/project/${i.id}/`, formData,)

        if (res.status === 200) {
          setShow(false)
          await getProjects()
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };



  return (
    <>
      <button title='Update Project' className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Update Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form className="register-form">

            {/* <div className="jd-heading-outer"> */}
            <h2 className="jd-heading-main font-weight600  font-size-heading">Project Information</h2>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
            {/* </div> */}
            <br />
            <div className="form-flex-wrap">

              <div title="Project Code" className="flex-column">
                <label htmlFor="site_prcode" className="form-labels  font-weight500    font-size-subheading">
                  Project Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="site_prcode"
                  name="site_prcode"
                  placeholder="Project Code"
                  onChange={handleInputChange}
                  value={formData.site_prcode}
                  className={`form-text-trasformation-uppercase form-input ${errors.site_prcode ? "error" : inputState.site_prcode ? "success" : ""}`}
                />
                {errors.site_prcode && <span className="error-message font-size-text ">{errors.site_prcode}</span>}
              </div>

              <div title="Project Name" className="flex-column">
                <label htmlFor="project_name" className="form-labels  font-weight500    font-size-subheading">
                  Project Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="project_name"
                  name="project_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  className={`form-input ${errors.project_name ? "error" : inputState.project_name ? "success" : ""}`}
                />
                {errors.project_name && <span className="error-message font-size-text ">{errors.project_name}</span>}
              </div>

              <div title="Project Short Name" className="flex-column">
                <label htmlFor="project_short_name" className="form-labels  font-weight500    font-size-subheading">
                  Short Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="project_short_name"
                  name="project_short_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_short_name}
                  className={`form-input ${errors.project_short_name ? "error" : inputState.project_short_name ? "success" : ""}`}
                />
                {errors.project_short_name && <span className="error-message font-size-text ">{errors.project_short_name}</span>}
              </div>

              <div title="Location" className="flex-column">
                <label htmlFor="location" className="form-labels  font-weight500    font-size-subheading">
                  Location
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Location"
                  onChange={handleInputChange}
                  value={formData.location}
                  className={`form-input ${errors.location ? "error" : inputState.location ? "success" : ""}`}
                />
                {errors.location && <span className="error-message font-size-text ">{errors.location}</span>}
              </div>

              <div title="Site Sub Company" className="flex-column form-group-selectt">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Site Sub Company<span className="required">*</span>
                </label>
                <br />
                <select
                  id="sub_company"
                  name="sub_company"
                  placeholder="Site SubCompany"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""}`}
                >
                  <option value="">Select a Site SubCompany</option>
                  {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.title}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.sub_company && <span className="error-message font-size-text ">{errors.sub_company}</span>}
              </div>

              <div title="Award Date" className="flex-column">
                <label htmlFor="award_date" className="form-labels  font-weight500    font-size-subheading">
                  Award Date
                </label>
                <input
                  type="date"
                  id="award_date"
                  name="award_date"
                  placeholder="Award Date"
                  onChange={handleInputChange}
                  value={formData.award_date}
                  className={`form-input ${errors.award_date ? "error" : inputState.award_date ? "success" : ""}`}
                />
                {errors.award_date && <span className="error-message font-size-text ">{errors.award_date}</span>}
              </div>

              <div title="Start Date" className="flex-column">
                <label htmlFor="start_date" className="form-labels  font-weight500    font-size-subheading">
                  Start Date
                </label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  placeholder="Start Date"
                  onChange={handleInputChange}
                  value={formData.start_date}
                  className={`form-input ${errors.start_date ? "error" : inputState.start_date ? "success" : ""}`}
                />
                {errors.start_date && <span className="error-message font-size-text ">{errors.start_date}</span>}
              </div>

              <div title="Concern Person" className="flex-column">
                <label htmlFor="concern_person" className="form-labels  font-weight500    font-size-subheading">
                  Concern Person
                </label>
                <input
                  type="text"
                  id="concern_person"
                  name="concern_person"
                  placeholder="Concern Person Code"
                  onChange={handleInputChange}
                  value={formData.concern_person}
                  className={`form-input ${errors.concern_person ? "error" : inputState.concern_person ? "success" : ""}`}
                />
                {errors.concern_person && <span className="error-message font-size-text ">{errors.concern_person}</span>}
              </div>

              <div title="Concern Person Name" className="flex-column">
                <label htmlFor="concern_person_name" className="form-labels  font-weight500    font-size-subheading">
                  Concern Person Name
                </label>
                <input
                  type="text"
                  id="concern_person_name"
                  name="concern_person_name"
                  placeholder="Concern Person Name"
                  onChange={handleInputChange}
                  value={formData.concern_person_name}
                  className={`form-input ${errors.concern_person_name ? "error" : inputState.concern_person_name ? "success" : ""}`}
                />
                {errors.concern_person_name && <span className="error-message font-size-text ">{errors.concern_person_name}</span>}
              </div>

              <div title="Project Length" className="flex-column">
                <label htmlFor="project_length" className="form-labels  font-weight500    font-size-subheading">
                  Project Length
                </label>
                <input
                  type="number"
                  min={0}
                  maxLength={10}
                  id="project_length"
                  name="project_length"
                  placeholder="Project Length"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input ${errors.project_length ? "error" : inputState.project_length ? "success" : ""}`}
                />
                {errors.project_length && <span className="error-message font-size-text ">{errors.project_length}</span>}
              </div>

              <div title="Tender ID" className="flex-column">
                <label htmlFor="tender_id" className="form-labels  font-weight500    font-size-subheading">
                  Tender ID
                </label>
                <input
                  type="text"
                  id="tender_id"
                  name="tender_id"
                  placeholder="Tender ID"
                  onChange={handleInputChange}
                  value={formData.tender_id}
                  className={`form-input ${errors.tender_id ? "error" : inputState.tender_id ? "success" : ""}`}
                />
                {errors.tender_id && <span className="error-message font-size-text ">{errors.tender_id}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="actual_completion_date" className="form-labels  font-weight500    font-size-subheading">
                  Actual Completion Date
                </label>
                <input
                  type="date"
                  id="actual_completion_date"
                  name="actual_completion_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.actual_completion_date}
                  className={`form-input ${errors.actual_completion_date ? "error" : inputState.actual_completion_date ? "success" : ""}`}
                />
                {errors.actual_completion_date && <span className="error-message  font-size-text">{errors.actual_completion_date}</span>}
              </div >

              <div className="flex-column">
                <label htmlFor="bid_due_date" className="form-labels  font-weight500    font-size-subheading">
                  Bid Due Date
                </label>
                <input
                  type="date"
                  id="bid_due_date"
                  name="bid_due_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.bid_due_date}
                  className={`form-input ${errors.bid_due_date ? "error" : inputState.bid_due_date ? "success" : ""}`}
                />
                {errors.bid_due_date && <span className="error-message font-size-text">{errors.bid_due_date}</span>}
              </div>

              <div title="Target Date" className="flex-column">
                <label htmlFor="schedule_completion_date" className="form-labels  font-weight500   ">
                  Schedule Completion Date
                </label>
                <input
                  type="date"
                  id="schedule_completion_date"
                  name="schedule_completion_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.schedule_completion_date}
                  className={`form-input ${errors.schedule_completion_date ? "error" : inputState.schedule_completion_date ? "success" : ""}`}
                />
                {errors.schedule_completion_date && <span className="error-message">{errors.schedule_completion_date}</span>}
              </div>



              <div title="Construction Cost" className="flex-column">
                <label htmlFor="construction_cost" className="form-labels  font-weight500    font-size-subheading">
                  Construction Cost
                </label>
                <input
                  type="number"
                  id="construction_cost"
                  min={0}
                  name="construction_cost"
                  placeholder="Construction Cost"
                  onChange={handleInputChange}
                  value={formData.construction_cost}
                  className={`form-input ${errors.construction_cost ? "error" : inputState.construction_cost ? "success" : ""}`}
                />
                {errors.construction_cost && <span className="error-message font-size-text ">{errors.construction_cost}</span>}
              </div>

              <div title="Remark" className="flex-column">
                <label htmlFor="remark" className="form-labels  font-weight500    font-size-subheading">
                  Remark
                </label>
                <input
                  type="text"
                  id="remark"
                  name="remark"
                  placeholder="Remark"
                  onChange={handleInputChange}
                  value={formData.remark}
                  className={`form-input ${errors.remark ? "error" : inputState.remark ? "success" : ""}`}
                />
                {errors.remark && <span className="error-message font-size-text ">{errors.remark}</span>}
              </div>

              <div title="Consultancy Fees" className="flex-column">
                <label htmlFor="consultancy_fees" className="form-labels  font-weight500     font-size-subheading">
                  Consultancy Fees
                </label>
                <input
                  type="number"
                  min={0}
                  id="consultancy_fees"
                  name="consultancy_fees"
                  placeholder="Consultancy Fees"
                  onChange={handleInputChange}
                  value={formData.consultancy_fees}
                  className={`form-input ${errors.consultancy_fees ? "error" : inputState.consultancy_fees ? "success" : ""}`}
                />
                {errors.consultancy_fees && <span className="error-message  font-size-text">{errors.consultancy_fees}</span>}
              </div >

              <div title="Contract Mode" className="flex-column">
                <label htmlFor="contract_mode" className="form-labels  font-weight500    font-size-subheading">
                  Contract Mode
                </label>
                <input
                  type="text"
                  id="contract_mode"
                  name="contract_mode"
                  placeholder="Contract Mode"
                  onChange={handleInputChange}
                  value={formData.contract_mode}
                  className={`form-input ${errors.contract_mode ? "error" : inputState.contract_mode ? "success" : ""}`}
                />
                {errors.contract_mode && <span className="error-message font-size-text ">{errors.contract_mode}</span>}
              </div>

              <div title="Lead" className="flex-column">
                <label htmlFor="lead" className="form-labels  font-weight500    font-size-subheading">
                  Lead
                </label>
                <input
                  type="text"
                  id="lead"
                  name="lead"
                  placeholder="Lead"
                  onChange={handleInputChange}
                  value={formData.lead}
                  className={`form-input ${errors.lead ? "error" : inputState.lead ? "success" : ""}`}
                />
                {errors.lead && <span className="error-message font-size-text ">{errors.lead}</span>}
              </div>

              <div title="JV" className="flex-column">
                <label htmlFor="jv" className="form-labels  font-weight500    font-size-subheading">
                  JV
                </label>
                <input
                  type="text"
                  id="jv"
                  name="jv"
                  placeholder="JV"
                  onChange={handleInputChange}
                  value={formData.jv}
                  className={`form-input ${errors.jv ? "error" : inputState.jv ? "success" : ""}`}
                />
                {errors.jv && <span className="error-message font-size-text ">{errors.jv}</span>}
              </div>

              <div title="Association" className="flex-column">
                <label htmlFor="association" className="form-labels  font-weight500    font-size-subheading">
                  Association
                </label>
                <input
                  type="text"
                  id="association"
                  name="association"
                  placeholder="Association"
                  onChange={handleInputChange}
                  value={formData.association}
                  className={`form-input ${errors.association ? "error" : inputState.association ? "success" : ""}`}
                />
                {errors.association && <span className="error-message font-size-text ">{errors.association}</span>}
              </div>

              <div title="Work Performed By" className="flex-column">
                <label htmlFor="work_performed_by" className="form-labels  font-weight500    font-size-subheading">
                  Contractor Name
                </label>
                <input
                  type="text"
                  id="work_performed_by"
                  name="work_performed_by"
                  placeholder="Performed By"
                  onChange={handleInputChange}
                  value={formData.work_performed_by}
                  className={`form-input ${errors.work_performed_by ? "error" : inputState.work_performed_by ? "success" : ""}`}
                />
                {errors.work_performed_by && <span className="error-message font-size-text ">{errors.work_performed_by}</span>}
              </div>

              <div title="JV Share" className="flex-column">
                <label htmlFor="jv_share" className="form-labels  font-weight500    font-size-subheading">
                  JV Share
                </label>
                <input
                  type="text"
                  id="jv_share"
                  name="jv_share"
                  placeholder="JV Share"
                  onChange={handleInputChange}
                  value={formData.jv_share}
                  className={`form-input ${errors.jv_share ? "error" : inputState.jv_share ? "success" : ""}`}
                />
                {errors.jv_share && <span className="error-message font-size-text ">{errors.jv_share}</span>}
              </div>

              <div title="Status" className="flex-column">
                <label htmlFor="today_status" className="form-labels  font-weight500    font-size-subheading">
                  Status
                </label>
                <input
                  type="text"
                  id="today_status"
                  name="today_status"
                  placeholder="Status"
                  onChange={handleInputChange}
                  value={formData.today_status}
                  className={`form-input ${errors.today_status ? "error" : inputState.today_status ? "success" : ""}`}
                />
                {errors.today_status && <span className="error-message font-size-text ">{errors.today_status}</span>}
              </div>
              <div className="flex-column flex-row button-models" style={{ width: "50%", justifyContent: 'center' }}>
                <label htmlFor="" className="form-labels font-weight500 font-size-subheading">
                </label>
                <button onClick={handleProjectUpdate} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                  Update
                </button>
              </div>
            </div >

            <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Extention of Time</h2>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">EOT Code</th>
                      <th className="align-center">Date</th>
                      <th className="align-center">Duration</th>
                      <th className="align-center">Related To</th>

                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData?.eots?.map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.eot_code}</td>
                          <td className="align-center form-text-trasformation-uppercase">
                            {i.approved_date ? formattedDateLong(i.approved_date) : "-"}
                          </td>
                          <td className="align-center">{i.approved_days}</td>
                          <td className="align-center">{i.workperformedby}</td>


                          <td className="align-right width-5vw ">
                            <button className="model-delete-button"
                              onClick={(e) => handleEotDelete(e, i, getProjectDetails)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                  fill="#F72B50"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />

              {showAddEot === true ? (
                <div className="expense-req">
                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="item_name"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      EOT
                    </label>
                    <input
                      id="eot_code"
                      type="text"
                      name="eot_code"
                      placeholder="EOT Code"
                      onChange={handleEotChange}
                      value={eotData.eot_code}
                      className={`form-modal-input ${eoterrors.eot_code
                        ? "error"
                        : eotinputState.eot_code
                          ? "success"
                          : ""
                        }`}
                    />
                    {eoterrors.eot_code && (
                      <span className="error-message font-size-text ">{eoterrors.eot_code}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="date"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Date
                    </label>

                    <input
                      id="date"
                      type="date"
                      name="approved_date"
                      placeholder="EOT Date"
                      onChange={handleEotChange}
                      value={eotData.approved_date}
                      className={`form-modal-input ${eoterrors.approved_date
                        ? "error"
                        : eotinputState.approved_date
                          ? "success"
                          : ""
                        }`}
                    />

                    {eoterrors.approved_date && (
                      <span className="error-message font-size-text ">{eoterrors.approved_date}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="duration"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Duration
                    </label>

                    <input
                      id="duration"
                      type="text"
                      name="approved_days"
                      placeholder="EOT Duration"
                      onChange={handleEotChange}
                      value={eotData.approved_days}
                      className={`form-modal-input ${eoterrors.approved_days
                        ? "error"
                        : eotinputState.approved_days
                          ? "success"
                          : ""
                        }`}
                    />

                    {eoterrors.approved_days && (
                      <span className="error-message font-size-text ">{eoterrors.approved_days}</span>
                    )}
                  </div>
                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="related_to"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Related To
                    </label>

                    <select
                      id="related_to"
                      name="workperformedby"
                      placeholder="Related To"
                      onChange={handleEotChange}
                      value={eotData.workperformedby}
                      className={`form-modal-input form-group-selection-modal ${eoterrors.duration
                        ? "error"
                        : eotinputState.workperformedby
                          ? "success"
                          : ""
                        }`}
                    >
                      <option value="">Select Option</option>
                      <option value="contractor">Contractor</option>
                      <option value="consultant">Consultant</option>

                    </select>

                    {eoterrors.workperformedby && (
                      <span className="error-message font-size-text ">{eoterrors.workperformedby}</span>
                    )}
                  </div>





                  <div className="flex-column">
                    <label
                      htmlFor=""
                      className="announce-date font-weight400 width-60vw form-inputss"
                    ></label>
                    <div className="flex-row action-div">
                      <button className="" onClick={handleCloseEot}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 9L9 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 9L15 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className="form-inputs-submit width-5vw"
                        onClick={(e) => handleEotPost(e, getProjectDetails)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    title="Add EOT Details"
                    onClick={handleShowEot}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}
            </>

            <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Change of Scope</h2>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">COS Code</th>
                      <th className="align-center">Date</th>
                      <th className="align-center">Amount</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData?.coss?.map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.cos_code}</td>
                          <td className="align-center">
                            {i.approval_date ? formattedDateLong(i.approval_date) : "-"}
                          </td>
                          <td className="align-center">{i.amount}</td>
                          <td className="align-center">{i.workperformedby}</td>

                          <td className="align-right width-5vw ">
                            <button className="model-delete-button"
                              onClick={(e) => handleCosDelete(e, i, getProjectDetails)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                  fill="#F72B50"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />

              {showAddCos === true ? (
                <div className="expense-req">
                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="item_name"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      COS
                    </label>
                    <input
                      id="cos_code"
                      type="text"
                      name="cos_code"
                      placeholder="COS Code"
                      onChange={handleCosChange}
                      value={eotData.cos_code}
                      className={`form-modal-input ${coserrors.cos_code
                        ? "error"
                        : cosinputState.cos_code
                          ? "success"
                          : ""
                        }`}
                    />
                    {coserrors.cos_code && (
                      <span className="error-message font-size-text ">{coserrors.cos_code}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="date"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Date
                    </label>

                    <input
                      id="approval_date"
                      type="date"
                      name="approval_date"
                      placeholder="COS Date"
                      onChange={handleCosChange}
                      value={cosData.approval_date}
                      className={`form-modal-input ${coserrors.approval_date
                        ? "error"
                        : cosinputState.approval_date
                          ? "success"
                          : ""
                        }`}
                    />

                    {coserrors.approval_date && (
                      <span className="error-message font-size-text ">{coserrors.approval_date}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="amount"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Amount
                    </label>

                    <input
                      id="amount"
                      min={0}
                      maxLength={10}
                      step="0.01"
                      type="number"
                      name="amount"
                      placeholder="COS Amount"
                      onChange={handleCosChange}
                      value={cosData.amount}
                      className={`form-modal-input ${coserrors.amount
                        ? "error"
                        : cosinputState.amount
                          ? "success"
                          : ""
                        }`}
                    />

                    {coserrors.amount && (
                      <span className="error-message font-size-text ">{coserrors.amount}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="related_to"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Related To
                    </label>

                    <select
                      id="related_to"
                      name="workperformedby"
                      placeholder="Related To"
                      onChange={handleCosChange}
                      value={cosData.workperformedby}
                      className={`form-modal-input form-group-selection-modal ${coserrors.duration
                        ? "error"
                        : cosinputState.workperformedby
                          ? "success"
                          : ""
                        }`}
                    >
                      <option value="">Select Option</option>
                      <option value="contractor">Contractor</option>
                      <option value="consultant">Consultant</option>

                    </select>

                    {coserrors.workperformedby && (
                      <span className="error-message font-size-text ">{coserrors.workperformedby}</span>
                    )}
                  </div>

                  <div className="flex-column">
                    <label
                      htmlFor=""
                      className="announce-date font-weight400 width-60vw form-inputss"
                    ></label>
                    <div className="flex-row action-div">
                      <button className="" onClick={handleCloseCos}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 9L9 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 9L15 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className="form-inputs-submit width-5vw"
                        onClick={(e) => handleCosPost(e, getProjectDetails)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    title="Add COS Details"
                    onClick={handleShowCos}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}
            </>


            {/* <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Billing Details</h2>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>


              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Billing Date</th>
                      <th className="align-center">Billing Amount</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData[0]?.billing_details.map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">
                            {i.billing_date ? formattedDateLong(i.billing_date) : "-"}
                          </td>
                          <td className="align-center">{i.billing_amount}</td>

                          <td className="align-right width-5vw ">
                            <button className="model-delete-button"
                              onClick={(e) => handleBillDelete(e, i, getProjectDetails)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                  fill="#F72B50"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              <hr className="line" />

              {showAddBill === true ? (
                <div className="expense-req">

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="date"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Billing Date
                    </label>

                    <input
                      id="billing_date"
                      type="date"
                      name="billing_date"
                      placeholder="Billing Date"
                      onChange={handleBillChange}
                      value={billData.billing_date}
                      className={`form-modal-input ${billerrors.billing_date
                        ? "error"
                        : billinputState.billing_date
                          ? "success"
                          : ""
                        }`}
                    />

                    {billerrors.billing_date && (
                      <span className="error-message font-size-text ">{billerrors.billing_date}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="billing_amount"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Billing Amount
                    </label>

                    <input
                      id="billing_amount"
                      min={0}
                      maxLength={10}
                      step="0.01"
                      type="number"
                      name="billing_amount"
                      placeholder="Billing Amount"
                      onChange={handleBillChange}
                      value={billData.billing_amount}
                      className={`form-modal-input ${billerrors.billing_amount
                        ? "error"
                        : billinputState.billing_amount
                          ? "success"
                          : ""
                        }`}
                    />

                    {billerrors.billing_amount && (
                      <span className="error-message font-size-text ">{billerrors.billing_amount}</span>
                    )}
                  </div>


                  <div className="flex-column">
                    <label
                      htmlFor=""
                      className="announce-date font-weight400  form-inputss width-60vw"
                    ></label>
                    <div className="flex-row action-div">
                      <button className="" onClick={handleCloseBill}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 9L9 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 9L15 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className="form-inputs-submit width-5vw"
                        onClick={(e) => handleBillPost(e, getProjectDetails)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    title="Add Billing Details"
                    onClick={handleShowBill}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}
            </> */}


            <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Documents </h2>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Name</th>
                      <th className="align-center">File</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData?.employees?.map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">
                            {i.document_name}
                          </td>
                          <td className="align-center">
                            {i.document_file ? (
                              // <a href={i.document_file} target="blank">
                              //   View
                              // </a>
                              <a href={i.document_file} target="blank" ><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16" color="#FF747C">
                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                              </svg></a>
                            ) : (
                              "Document Not Attached"
                            )}
                          </td>

                          <td className="align-right width-5vw ">
                            <button className="model-delete-button"
                              onClick={(e) => handlePicDelete(e, i, getProjectDetails)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                  fill="#F72B50"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />


              {showAddPic === true ? (
                <div className="expense-req ">

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="document_name"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      File Name
                    </label>
                    <input
                      type="text"
                      id="document_name"
                      name="document_name"
                      placeholder="Document Name"
                      onChange={handleDocInputChange}
                      value={formData.document_name}
                      className={`form-modal-input ${picerrors.document_name
                        ? "error"
                        : picinputState.document_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {picerrors.document_name && (
                      <span className="error-message font-size-text ">{picerrors.document_name}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor=""
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Upload File
                    </label>
                    <label
                      htmlFor="document_file"
                      className={`align-center  form-inputss custom-file-upload ${picerrors.document_file
                        ? "error"
                        : picinputState.document_file
                          ? "success"
                          : ""
                        }`}
                    >
                      <svg
                        className="pdf-input"
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                          stroke="#707070"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </label>
                    <input
                      id="document_file"
                      name="document_file"
                      type="file"
                      onChange={handleFileChange}
                      className="file-inputs align-center"
                      style={{ display: "none", position: "relative" }}
                    />
                    {fileName && <p className="file-name align-center">{fileName}</p>}
                    {picerrors.document_file && (
                      <span className="file-name error-message font-size-text ">{picerrors.document_file}</span>
                    )}
                  </div>
                  <div className="flex-column">
                    <label
                      htmlFor=""
                      className="announce-date font-weight400 form-inputss"
                    ></label>
                    <div className="flex-row action-div" >
                      <button className="" onClick={handleClosePic}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 9L9 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 9L15 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className="form-inputs-submit width-5vw"
                        onClick={(e) => handlePicPost(e, getProjectDetails)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    title="Add EOT Details"
                    onClick={handleShowPic}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}

            </>


            <button className="model-button   font-weight500   " onClick={handleClose}>
              Close
            </button>
          </form >
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body >
      </Modal >
    </>
  );
};


const UpdateProjectAllDetails = ({ i, getProjects, siteSubCompanyList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    getProjects();
    setShow(false);
  };
  const handleShow = () => {
    getProjectDetails();
    setFormData(i);
    setShow(true);
  };


  const [projectData, setProjectData] = useState({});


  const [buffer, setBuffering] = useState(true); //buffering logic

  const getProjectDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const proj = await axios.get(`${BASE_URL}/siteproject/project/${i.id}/`);
      setProjectData(proj.data);

    }
    catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const [formData, setFormData] = useState({
    site_prcode: "",
    project_name: "",
    location: "",
    project_length: "",
    award_date: "",
    start_date: "",
    concern_person: "",
    concern_person_name: "",
    tender_id: "",
    actual_completion_date: "",
    construction_cost: "",
    remark: "",
    consultancy_fees: "",
    contract_mode: "",
    lead: "",
    jv: "",
    association: "",
    work_performed_by: "",
    jv_share: "",
    today_status: "",
    sub_company: ""
  });



  // ! EOT start ===============================

  const [showAddEot, setShowAddEot] = useState(false);
  const handleCloseEot = () => setShowAddEot(false);
  const handleShowEot = () => setShowAddEot(true);

  const [eotData, setEotData] = useState({
    project: i?.id,
    eot_code: "",
    approved_date: "",
    approved_days: "",
    workperformedby: "",
  });


  const [eoterrors, setEotErrors] = useState({});
  const [eotinputState, setEotInputState] = useState({});

  const validateEotForm = () => {
    const newEotErrors = {};
    const eotRequiredFields = [
      "project",
      "eot_code",
      "approved_date",
      "approved_days",
      "workperformedby",
    ];

    eotRequiredFields.forEach((field) => {
      if (!eotData[field]) {
        newEotErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setEotErrors(newEotErrors);
    toast.warning(newEotErrors);

    return Object.keys(newEotErrors).length === 0;

  };

  const handleEotChange = (e) => {
    const { name, value } = e.target;

    setEotInputState({
      ...eotinputState,
      [name]: value.trim() ? "green" : "",
    });

    setEotData({
      ...eotData,
      [name]: value,
    });
  };


  const handleEotPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateEotForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/eot/`, eotData)

        if (res.status === 200) {
          await getProjectDetails()
          handleCloseEot();
          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleEotDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/eot/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? EOT End ===============================


  // ! COS start ===============================


  const [showAddCos, setShowAddCos] = useState(false);
  const handleCloseCos = () => setShowAddCos(false);
  const handleShowCos = () => setShowAddCos(true);

  const [cosData, setCosData] = useState({
    project: i?.id,
    cos_code: "",
    approval_date: "",
    amount: "",
    workperformedby: "",
  });


  const [coserrors, setCosErrors] = useState({});
  const [cosinputState, setCosInputState] = useState({});

  const validateCosForm = () => {
    const newCosErrors = {};
    const cosRequiredFields = [
      "project",
      "cos_code",
      "approval_date",
      "amount",
      "workperformedby",
    ];

    cosRequiredFields.forEach((field) => {
      if (!cosData[field]) {
        newCosErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setCosErrors(newCosErrors);
    toast.warning(newCosErrors);

    return Object.keys(newCosErrors).length === 0;

  };

  const handleCosChange = (e) => {
    const { name, value } = e.target;

    setCosInputState({
      ...cosinputState,
      [name]: value.trim() ? "green" : "",
    });

    setCosData({
      ...cosData,
      [name]: value,
    });
  };


  const handleCosPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateCosForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/cos/`, cosData)

        if (res.status === 200) {
          await getProjectDetails()
          handleCloseCos();
          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleCosDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/cos/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? Cos End ===============================



  // ! BillingDetail start ===============================


  const [showAddBill, setShowAddBill] = useState(false);
  const handleCloseBill = () => setShowAddBill(false);
  const handleShowBill = () => setShowAddBill(true);

  const [billData, setBillData] = useState({
    project: i?.id,
    billing_date: "",
    billing_amount: "",
  });


  const [billerrors, setBillErrors] = useState({});
  const [billinputState, setBillInputState] = useState({});

  const validateBillForm = () => {
    const newBillErrors = {};
    const billRequiredFields = [
      "project",
      "billing_date",
      "billing_amount",
    ];

    billRequiredFields.forEach((field) => {
      if (!billData[field]) {
        newBillErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setBillErrors(newBillErrors);
    toast.warning(newBillErrors);

    return Object.keys(newBillErrors).length === 0;

  };

  const handleBillChange = (e) => {
    const { name, value } = e.target;

    setBillInputState({
      ...billinputState,
      [name]: value.trim() ? "green" : "",
    });

    setBillData({
      ...billData,
      [name]: value,
    });
  };


  const handleBillPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateBillForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/billingdetail/`, billData)

        if (res.status === 200) {
          await getProjectDetails()
          handleCloseBill();
          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleBillDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/billingdetail/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? BillingDetail End ===============================



  // ! Pic Start ===============================

  const [showAddPic, setShowAddPic] = useState(false);
  const handleClosePic = () => setShowAddPic(false);
  const handleShowPic = () => setShowAddPic(true);

  const [imageData, setimageData] = useState({
    project: i?.id,
    document_name: "",
    document_file: "",
  });


  const [picerrors, setpicErrors] = useState({});
  const [picinputState, setpicInputState] = useState({});

  const validatePicForm = () => {
    const newpicErrors = {};
    const picRequiredFields = [
      "project",
      "document_name",
      "document_file",
    ];

    picRequiredFields.forEach((field) => {
      if (!imageData[field]) {
        newpicErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setpicErrors(newpicErrors);
    toast.warning(newpicErrors);

    return Object.keys(newpicErrors).length === 0;

  };

  const handleDocInputChange = (e) => {
    const { name, value } = e.target;

    setpicInputState({
      ...picinputState,
      [name]: value.trim() ? "green" : "",
    });
    setimageData({
      ...imageData,
      [name]: value,
      // 'pic': e.target.files[0]
    });
  };

  // const handleFileChange = (e) => {
  //   const { name } = e.target;
  //   const file = e.target.files[0];

  //   setimageData({
  //     ...imageData,
  //     [name]: file,
  //   });
  // };

  const [fileName, setFileName] = useState("");
  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      setimageData({ ...imageData, [name]: file, });
      setFileName(file.name);
      // Additional logic for handling the file can be added here
    } else {
      // setimageData({...imageData,[name]: file,});
      setFileName("");
    }
  };

  const handlePicPost = async (e, getProjectDetails) => {
    e.preventDefault();
    console.log("imageData")
    console.log(imageData)
    if (validatePicForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("project", imageData.project);
      formDataa.append("document_name", imageData.document_name);

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = imageData.document_file;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });


      formDataa.append("document_file", customFile);

      try {

        let res = await axios.post(`${BASE_URL}/website/projectimage/`, formDataa)

        if (res.status === 200) {
          await getProjectDetails()
          handleClosePic();

          // Clear formDataa
          formDataa.delete("project");
          formDataa.delete("document_name");
          formDataa.delete("document_file");

          // Reset imageData (assuming imageData is a state managed with useState)
          setimageData({
            project: '',
            document_name: '',
            document_file: null
          });
          setFileName("");

          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handlePicDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/website/projectimage/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? Pic End ===============================



  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      "site_prcode",
      "project_name",
      "sub_company",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleProjectUpdate = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/siteproject/project/${i.id}/`, formData,)

        if (res.status === 200) {
          setShow(false)
          await getProjects()
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };



  return (
    <>
      <button title='Update All Project Details' style={{ margin: 0 }} className='models-button model-add' onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" id="pen-new-square">
          <path fill="#2576BC" fill-rule="evenodd" d="M11.9426 1.25L13.5 1.25C13.9142 1.25 14.25 1.58579 14.25 2C14.25 2.41421 13.9142 2.75 13.5 2.75H12C9.62177 2.75 7.91356 2.75159 6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.23505 4.75455 2.91245 5.86509 2.80417 8.03734C2.78355 8.45104 2.43146 8.76969 2.01776 8.74907C1.60406 8.72845 1.28541 8.37636 1.30603 7.96266C1.41642 5.74802 1.74641 4.12187 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM22 9.75C22.4142 9.75 22.75 10.0858 22.75 10.5V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574L1.25 12C1.25 11.5858 1.58579 11.25 2 11.25C2.41421 11.25 2.75 11.5858 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12V10.5C21.25 10.0858 21.5858 9.75 22 9.75Z" clip-rule="evenodd"></path>
          <path fill="#2576BC" fill-rule="evenodd" d="M16.7705 2.27592C18.1384 0.908029 20.3562 0.908029 21.7241 2.27592C23.092 3.6438 23.092 5.86158 21.7241 7.22947L18.0928 10.8607C17.7999 11.1536 17.3251 11.1536 17.0322 10.8607C16.7393 10.5678 16.7393 10.093 17.0322 9.80006L19.1051 7.72709C18.5892 7.50519 17.9882 7.14946 17.4193 6.58065C16.8505 6.01185 16.4948 5.41082 16.2729 4.89486L11.2175 9.95026C10.801 10.3668 10.6376 10.532 10.4988 10.71C10.3274 10.9297 10.1804 11.1676 10.0605 11.4192C9.96337 11.623 9.88868 11.8429 9.7024 12.4017L9.27051 13.6974L10.3026 14.7295L11.5983 14.2976C12.1571 14.1113 12.377 14.0366 12.5808 13.9395C12.8324 13.8196 13.0703 13.6726 13.2901 13.5012C13.468 13.3624 13.6332 13.199 14.0497 12.7825C14.3426 12.4896 14.8175 12.4896 15.1104 12.7825C15.4033 13.0754 15.4033 13.5503 15.1104 13.8432L15.076 13.8776C14.7047 14.2489 14.4721 14.4815 14.2126 14.684C13.9069 14.9224 13.5761 15.1268 13.2261 15.2936C12.929 15.4352 12.6169 15.5392 12.1188 15.7052L9.21426 16.6734C8.67801 16.8521 8.0868 16.7126 7.68711 16.3129C7.28742 15.9132 7.14785 15.322 7.3266 14.7857L8.29477 11.8812C8.46079 11.3831 8.56479 11.071 8.7064 10.7739C8.87319 10.4239 9.07761 10.0931 9.31605 9.78742C9.51849 9.52787 9.7511 9.29529 10.1224 8.92401L16.7705 2.27592ZM17.4546 3.7132C17.4773 3.80906 17.509 3.92327 17.5532 4.05066C17.6965 4.46372 17.9677 5.00771 18.48 5.51999C18.9923 6.03227 19.5363 6.30346 19.9493 6.44677C20.0767 6.49097 20.1909 6.52273 20.2868 6.54543L20.6634 6.16881C21.4455 5.38671 21.4455 4.11867 20.6634 3.33658C19.8813 2.55448 18.6133 2.55448 17.8312 3.33658L17.4546 3.7132Z" clip-rule="evenodd"></path>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Update Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form className="register-form">

            {/* <div className="jd-heading-outer"> */}
            <h2 className="jd-heading-main font-weight600  font-size-heading">Project Information</h2>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
            {/* </div> */}
            <br />
            <div className="form-flex-wrap">

              <div title="Project Code" className="flex-column">
                <label htmlFor="site_prcode" className="form-labels  font-weight500    font-size-subheading">
                  Project Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="site_prcode"
                  name="site_prcode"
                  placeholder="Project Code"
                  onChange={handleInputChange}
                  value={formData.site_prcode}
                  className={`form-text-trasformation-uppercase form-input ${errors.site_prcode ? "error" : inputState.site_prcode ? "success" : ""}`}
                />
                {errors.site_prcode && <span className="error-message font-size-text ">{errors.site_prcode}</span>}
              </div>

              <div title="Project Name" className="flex-column">
                <label htmlFor="project_name" className="form-labels  font-weight500    font-size-subheading">
                  Project Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="project_name"
                  name="project_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  className={`form-input ${errors.project_name ? "error" : inputState.project_name ? "success" : ""}`}
                />
                {errors.project_name && <span className="error-message font-size-text ">{errors.project_name}</span>}
              </div>

              <div title="Project Short Name" className="flex-column">
                <label htmlFor="project_short_name" className="form-labels  font-weight500    font-size-subheading">
                  Short Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="project_short_name"
                  name="project_short_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_short_name}
                  className={`form-input ${errors.project_short_name ? "error" : inputState.project_short_name ? "success" : ""}`}
                />
                {errors.project_short_name && <span className="error-message font-size-text ">{errors.project_short_name}</span>}
              </div>


              <div title="Location" className="flex-column">
                <label htmlFor="location" className="form-labels  font-weight500    font-size-subheading">
                  Location
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Location"
                  onChange={handleInputChange}
                  value={formData.location}
                  className={`form-input ${errors.location ? "error" : inputState.location ? "success" : ""}`}
                />
                {errors.location && <span className="error-message font-size-text ">{errors.location}</span>}
              </div>

              <div title="Site Sub Company" className="flex-column form-group-selectt">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Site Sub Company<span className="required">*</span>
                </label>
                <br />
                <select
                  id="sub_company"
                  name="sub_company"
                  placeholder="Site SubCompany"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""}`}
                >
                  <option value="">Select a Site SubCompany</option>
                  {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.title}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.sub_company && <span className="error-message font-size-text ">{errors.sub_company}</span>}
              </div>

              <div title="Award Date" className="flex-column">
                <label htmlFor="award_date" className="form-labels  font-weight500    font-size-subheading">
                  Award Date
                </label>
                <input
                  type="date"
                  id="award_date"
                  name="award_date"
                  placeholder="Award Date"
                  onChange={handleInputChange}
                  value={formData.award_date}
                  className={`form-input ${errors.award_date ? "error" : inputState.award_date ? "success" : ""}`}
                />
                {errors.award_date && <span className="error-message font-size-text ">{errors.award_date}</span>}
              </div>

              <div title="Start Date" className="flex-column">
                <label htmlFor="start_date" className="form-labels  font-weight500    font-size-subheading">
                  Start Date
                </label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  placeholder="Start Date"
                  onChange={handleInputChange}
                  value={formData.start_date}
                  className={`form-input ${errors.start_date ? "error" : inputState.start_date ? "success" : ""}`}
                />
                {errors.start_date && <span className="error-message font-size-text ">{errors.start_date}</span>}
              </div>

              <div title="Concern Person" className="flex-column">
                <label htmlFor="concern_person" className="form-labels  font-weight500    font-size-subheading">
                  Concern Person
                </label>
                <input
                  type="text"
                  id="concern_person"
                  name="concern_person"
                  placeholder="Concern Person Code"
                  onChange={handleInputChange}
                  value={formData.concern_person}
                  className={`form-input ${errors.concern_person ? "error" : inputState.concern_person ? "success" : ""}`}
                />
                {errors.concern_person && <span className="error-message font-size-text ">{errors.concern_person}</span>}
              </div>

              <div title="Concern Person Name" className="flex-column">
                <label htmlFor="concern_person_name" className="form-labels  font-weight500    font-size-subheading">
                  Concern Person Name
                </label>
                <input
                  type="text"
                  id="concern_person_name"
                  name="concern_person_name"
                  placeholder="Concern Person Name"
                  onChange={handleInputChange}
                  value={formData.concern_person_name}
                  className={`form-input ${errors.concern_person_name ? "error" : inputState.concern_person_name ? "success" : ""}`}
                />
                {errors.concern_person_name && <span className="error-message font-size-text ">{errors.concern_person_name}</span>}
              </div>

              <div title="Project Length" className="flex-column">
                <label htmlFor="project_length" className="form-labels  font-weight500    font-size-subheading">
                  Project Length
                </label>
                <input
                  type="number"
                  min={0}
                  maxLength={10}
                  id="project_length"
                  name="project_length"
                  placeholder="Project Length"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input ${errors.project_length ? "error" : inputState.project_length ? "success" : ""}`}
                />
                {errors.project_length && <span className="error-message font-size-text ">{errors.project_length}</span>}
              </div>

              <div title="Tender ID" className="flex-column">
                <label htmlFor="tender_id" className="form-labels  font-weight500    font-size-subheading">
                  Tender ID
                </label>
                <input
                  type="text"
                  id="tender_id"
                  name="tender_id"
                  placeholder="Tender ID"
                  onChange={handleInputChange}
                  value={formData.tender_id}
                  className={`form-input ${errors.tender_id ? "error" : inputState.tender_id ? "success" : ""}`}
                />
                {errors.tender_id && <span className="error-message font-size-text ">{errors.tender_id}</span>}
              </div>
              <div className="flex-column">
                <label htmlFor="actual_completion_date" className="form-labels  font-weight500    font-size-subheading">
                  Actual Completion Date
                </label>
                <input
                  type="date"
                  id="actual_completion_date"
                  name="actual_completion_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.actual_completion_date}
                  className={`form-input ${errors.actual_completion_date ? "error" : inputState.actual_completion_date ? "success" : ""}`}
                />
                {errors.actual_completion_date && <span className="error-message  font-size-text">{errors.actual_completion_date}</span>}
              </div >

              <div title="Target Date" className="flex-column">
                <label htmlFor="schedule_completion_date" className="form-labels  font-weight500   ">
                  Schedule Completion Date
                </label>
                <input
                  type="date"
                  id="schedule_completion_date"
                  name="schedule_completion_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.schedule_completion_date}
                  className={`form-input ${errors.schedule_completion_date ? "error" : inputState.schedule_completion_date ? "success" : ""}`}
                />
                {errors.schedule_completion_date && <span className="error-message">{errors.schedule_completion_date}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="bid_due_date" className="form-labels  font-weight500    font-size-subheading">
                  Bid Due Date
                </label>
                <input
                  type="date"
                  id="bid_due_date"
                  name="bid_due_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.bid_due_date}
                  className={`form-input ${errors.bid_due_date ? "error" : inputState.bid_due_date ? "success" : ""}`}
                />
                {errors.bid_due_date && <span className="error-message font-size-text">{errors.bid_due_date}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="appointed_date" className="form-labels  font-weight500 font-size-subheading">
                  Appointed Date
                </label>
                <input
                  type="date"
                  id="appointed_date"
                  name="appointed_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.appointed_date}
                  className={`form-input ${errors.appointed_date ? "error" : inputState.appointed_date ? "success" : ""}`}
                />
                {errors.appointed_date && <span className="error-message font-size-text">{errors.appointed_date}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="agreement_date" className="form-labels font-weight500 font-size-subheading">
                  Agreement Date
                </label>
                <input
                  type="date"
                  id="agreement_date"
                  name="agreement_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.agreement_date}
                  className={`form-input ${errors.agreement_date ? "error" : inputState.agreement_date ? "success" : ""}`}
                />
                {errors.agreement_date && <span className="error-message font-size-text">{errors.agreement_date}</span>}
              </div>

              <div title="Construction Cost" className="flex-column">
                <label htmlFor="construction_cost" className="form-labels  font-weight500    font-size-subheading">
                  Construction Cost
                </label>
                <input
                  type="number"
                  id="construction_cost"
                  name="construction_cost"
                  min={0}
                  placeholder="Construction Cost"
                  onChange={handleInputChange}
                  value={formData.construction_cost}
                  className={`form-input ${errors.construction_cost ? "error" : inputState.construction_cost ? "success" : ""}`}
                />
                {errors.construction_cost && <span className="error-message font-size-text ">{errors.construction_cost}</span>}
              </div>

              <div title="Remark" className="flex-column">
                <label htmlFor="remark" className="form-labels  font-weight500    font-size-subheading">
                  Remark
                </label>
                <input
                  type="text"
                  id="remark"
                  name="remark"
                  placeholder="Remark"
                  onChange={handleInputChange}
                  value={formData.remark}
                  className={`form-input ${errors.remark ? "error" : inputState.remark ? "success" : ""}`}
                />
                {errors.remark && <span className="error-message font-size-text ">{errors.remark}</span>}
              </div>

              <div title="Consultancy Fees" className="flex-column">
                <label htmlFor="consultancy_fees" className="form-labels  font-weight500     font-size-subheading">
                  Consultancy Fees
                </label>
                <input
                  type="number"
                  min={0}
                  id="consultancy_fees"
                  name="consultancy_fees"
                  placeholder="Consultancy Fees"
                  onChange={handleInputChange}
                  value={formData.consultancy_fees}
                  className={`form-input ${errors.consultancy_fees ? "error" : inputState.consultancy_fees ? "success" : ""}`}
                />
                {errors.consultancy_fees && <span className="error-message  font-size-text">{errors.consultancy_fees}</span>}
              </div >

              <div title="Contract Mode" className="flex-column">
                <label htmlFor="contract_mode" className="form-labels  font-weight500    font-size-subheading">
                  Contract Mode
                </label>
                <input
                  type="text"
                  id="contract_mode"
                  name="contract_mode"
                  placeholder="Contract Mode"
                  onChange={handleInputChange}
                  value={formData.contract_mode}
                  className={`form-input ${errors.contract_mode ? "error" : inputState.contract_mode ? "success" : ""}`}
                />
                {errors.contract_mode && <span className="error-message font-size-text ">{errors.contract_mode}</span>}
              </div>

              <div title="Lead" className="flex-column">
                <label htmlFor="lead" className="form-labels  font-weight500    font-size-subheading">
                  Lead
                </label>
                <input
                  type="text"
                  id="lead"
                  name="lead"
                  placeholder="Lead"
                  onChange={handleInputChange}
                  value={formData.lead}
                  className={`form-input ${errors.lead ? "error" : inputState.lead ? "success" : ""}`}
                />
                {errors.lead && <span className="error-message font-size-text ">{errors.lead}</span>}
              </div>

              <div title="JV" className="flex-column">
                <label htmlFor="jv" className="form-labels  font-weight500    font-size-subheading">
                  JV
                </label>
                <input
                  type="text"
                  id="jv"
                  name="jv"
                  placeholder="JV"
                  onChange={handleInputChange}
                  value={formData.jv}
                  className={`form-input ${errors.jv ? "error" : inputState.jv ? "success" : ""}`}
                />
                {errors.jv && <span className="error-message font-size-text ">{errors.jv}</span>}
              </div>

              <div title="Association" className="flex-column">
                <label htmlFor="association" className="form-labels  font-weight500    font-size-subheading">
                  Association
                </label>
                <input
                  type="text"
                  id="association"
                  name="association"
                  placeholder="Association"
                  onChange={handleInputChange}
                  value={formData.association}
                  className={`form-input ${errors.association ? "error" : inputState.association ? "success" : ""}`}
                />
                {errors.association && <span className="error-message font-size-text ">{errors.association}</span>}
              </div>

              <div title="Work Performed By" className="flex-column">
                <label htmlFor="work_performed_by" className="form-labels  font-weight500    font-size-subheading">
                  Contractor Name
                </label>
                <input
                  type="text"
                  id="work_performed_by"
                  name="work_performed_by"
                  placeholder="Performed By"
                  onChange={handleInputChange}
                  value={formData.work_performed_by}
                  className={`form-input ${errors.work_performed_by ? "error" : inputState.work_performed_by ? "success" : ""}`}
                />
                {errors.work_performed_by && <span className="error-message font-size-text ">{errors.work_performed_by}</span>}
              </div>

              <div title="JV Share" className="flex-column">
                <label htmlFor="jv_share" className="form-labels  font-weight500    font-size-subheading">
                  JV Share
                </label>
                <input
                  type="text"
                  id="jv_share"
                  name="jv_share"
                  placeholder="JV Share"
                  onChange={handleInputChange}
                  value={formData.jv_share}
                  className={`form-input ${errors.jv_share ? "error" : inputState.jv_share ? "success" : ""}`}
                />
                {errors.jv_share && <span className="error-message font-size-text ">{errors.jv_share}</span>}
              </div>

              <div title="Status" className="flex-column">
                <label htmlFor="today_status" className="form-labels  font-weight500    font-size-subheading">
                  Status
                </label>
                <input
                  type="text"
                  id="today_status"
                  name="today_status"
                  placeholder="Status"
                  onChange={handleInputChange}
                  value={formData.today_status}
                  className={`form-input ${errors.today_status ? "error" : inputState.today_status ? "success" : ""}`}
                />
                {errors.today_status && <span className="error-message font-size-text ">{errors.today_status}</span>}
              </div>
              <div className="flex-column flex-row button-models" style={{ width: "50%", justifyContent: 'center' }}>
                <label htmlFor="" className="form-labels font-weight500 font-size-subheading">
                </label>
                <button onClick={handleProjectUpdate} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                  Update
                </button>
              </div>
            </div >

            <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Extention of Time</h2>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">EOT Code</th>
                      <th className="align-center">Date</th>
                      <th className="align-center">Duration</th>
                      <th className="align-center">Related To</th>

                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData?.eots?.map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.eot_code}</td>
                          <td className="align-center form-text-trasformation-uppercase">
                            {i.approved_date ? formattedDateLong(i.approved_date) : "-"}
                          </td>
                          <td className="align-center">{i.approved_days}</td>
                          <td className="align-center">{i.workperformedby}</td>


                          <td className="align-right width-5vw ">
                            <button className="model-delete-button"
                              onClick={(e) => handleEotDelete(e, i, getProjectDetails)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                  fill="#F72B50"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />

              {showAddEot === true ? (
                <div className="expense-req">
                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="item_name"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      EOT
                    </label>
                    <input
                      id="eot_code"
                      type="text"
                      name="eot_code"
                      placeholder="EOT Code"
                      onChange={handleEotChange}
                      value={eotData.eot_code}
                      className={`form-modal-input ${eoterrors.eot_code
                        ? "error"
                        : eotinputState.eot_code
                          ? "success"
                          : ""
                        }`}
                    />
                    {eoterrors.eot_code && (
                      <span className="error-message font-size-text ">{eoterrors.eot_code}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="date"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Date
                    </label>

                    <input
                      id="date"
                      type="date"
                      name="approved_date"
                      placeholder="EOT Date"
                      onChange={handleEotChange}
                      value={eotData.approved_date}
                      className={`form-modal-input ${eoterrors.approved_date
                        ? "error"
                        : eotinputState.approved_date
                          ? "success"
                          : ""
                        }`}
                    />

                    {eoterrors.approved_date && (
                      <span className="error-message font-size-text ">{eoterrors.approved_date}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="duration"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Duration
                    </label>

                    <input
                      id="duration"
                      type="text"
                      name="approved_days"
                      placeholder="EOT Duration"
                      onChange={handleEotChange}
                      value={eotData.approved_days}
                      className={`form-modal-input ${eoterrors.approved_days
                        ? "error"
                        : eotinputState.approved_days
                          ? "success"
                          : ""
                        }`}
                    />

                    {eoterrors.approved_days && (
                      <span className="error-message font-size-text ">{eoterrors.approved_days}</span>
                    )}
                  </div>
                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="related_to"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Related To
                    </label>

                    <select
                      id="related_to"
                      name="workperformedby"
                      placeholder="Related To"
                      onChange={handleEotChange}
                      value={eotData.workperformedby}
                      className={`form-modal-input form-group-selection-modal ${eoterrors.duration
                        ? "error"
                        : eotinputState.workperformedby
                          ? "success"
                          : ""
                        }`}
                    >
                      <option value="">Select Option</option>
                      <option value="contractor">Contractor</option>
                      <option value="consultant">Consultant</option>

                    </select>

                    {eoterrors.workperformedby && (
                      <span className="error-message font-size-text ">{eoterrors.workperformedby}</span>
                    )}
                  </div>





                  <div className="flex-column">
                    <label
                      htmlFor=""
                      className="announce-date font-weight400 width-60vw form-inputss"
                    ></label>
                    <div className="flex-row action-div">
                      <button className="" onClick={handleCloseEot}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 9L9 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 9L15 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className="form-inputs-submit width-5vw"
                        onClick={(e) => handleEotPost(e, getProjectDetails)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    title="Add EOT Details"
                    onClick={handleShowEot}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}
            </>

            <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Change of Scope</h2>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">COS Code</th>
                      <th className="align-center">Date</th>
                      <th className="align-center">Amount</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData?.coss?.map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.cos_code}</td>
                          <td className="align-center">
                            {i.approval_date ? formattedDateLong(i.approval_date) : "-"}
                          </td>
                          <td className="align-center">{i.amount}</td>
                          <td className="align-center">{i.workperformedby}</td>

                          <td className="align-right width-5vw ">
                            <button className="model-delete-button"
                              onClick={(e) => handleCosDelete(e, i, getProjectDetails)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                  fill="#F72B50"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />

              {showAddCos === true ? (
                <div className="expense-req">
                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="item_name"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      COS
                    </label>
                    <input
                      id="cos_code"
                      type="text"
                      name="cos_code"
                      placeholder="COS Code"
                      onChange={handleCosChange}
                      value={eotData.cos_code}
                      className={`form-modal-input ${coserrors.cos_code
                        ? "error"
                        : cosinputState.cos_code
                          ? "success"
                          : ""
                        }`}
                    />
                    {coserrors.cos_code && (
                      <span className="error-message font-size-text ">{coserrors.cos_code}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="date"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Date
                    </label>

                    <input
                      id="approval_date"
                      type="date"
                      name="approval_date"
                      placeholder="COS Date"
                      onChange={handleCosChange}
                      value={cosData.approval_date}
                      className={`form-modal-input ${coserrors.approval_date
                        ? "error"
                        : cosinputState.approval_date
                          ? "success"
                          : ""
                        }`}
                    />

                    {coserrors.approval_date && (
                      <span className="error-message font-size-text ">{coserrors.approval_date}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="amount"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Amount
                    </label>

                    <input
                      id="amount"
                      min={0}
                      maxLength={10}
                      step="0.01"
                      type="number"
                      name="amount"
                      placeholder="COS Amount"
                      onChange={handleCosChange}
                      value={cosData.amount}
                      className={`form-modal-input ${coserrors.amount
                        ? "error"
                        : cosinputState.amount
                          ? "success"
                          : ""
                        }`}
                    />

                    {coserrors.amount && (
                      <span className="error-message font-size-text ">{coserrors.amount}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="related_to"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Related To
                    </label>

                    <select
                      id="related_to"
                      name="workperformedby"
                      placeholder="Related To"
                      onChange={handleCosChange}
                      value={cosData.workperformedby}
                      className={`form-modal-input form-group-selection-modal ${coserrors.duration
                        ? "error"
                        : cosinputState.workperformedby
                          ? "success"
                          : ""
                        }`}
                    >
                      <option value="">Select Option</option>
                      <option value="contractor">Contractor</option>
                      <option value="consultant">Consultant</option>

                    </select>

                    {coserrors.workperformedby && (
                      <span className="error-message font-size-text ">{coserrors.workperformedby}</span>
                    )}
                  </div>

                  <div className="flex-column">
                    <label
                      htmlFor=""
                      className="announce-date font-weight400 width-60vw form-inputss"
                    ></label>
                    <div className="flex-row action-div">
                      <button className="" onClick={handleCloseCos}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 9L9 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 9L15 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className="form-inputs-submit width-5vw"
                        onClick={(e) => handleCosPost(e, getProjectDetails)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    title="Add COS Details"
                    onClick={handleShowCos}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}
            </>


            {/* <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Billing Details</h2>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>


              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Billing Date</th>
                      <th className="align-center">Billing Amount</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData[0]?.billing_details.map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">
                            {i.billing_date ? formattedDateLong(i.billing_date) : "-"}
                          </td>
                          <td className="align-center">{i.billing_amount}</td>

                          <td className="align-right width-5vw ">
                            <button className="model-delete-button"
                              onClick={(e) => handleBillDelete(e, i, getProjectDetails)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                  fill="#F72B50"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              <hr className="line" />

              {showAddBill === true ? (
                <div className="expense-req">

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="date"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Billing Date
                    </label>

                    <input
                      id="billing_date"
                      type="date"
                      name="billing_date"
                      placeholder="Billing Date"
                      onChange={handleBillChange}
                      value={billData.billing_date}
                      className={`form-modal-input ${billerrors.billing_date
                        ? "error"
                        : billinputState.billing_date
                          ? "success"
                          : ""
                        }`}
                    />

                    {billerrors.billing_date && (
                      <span className="error-message font-size-text ">{billerrors.billing_date}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="billing_amount"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Billing Amount
                    </label>

                    <input
                      id="billing_amount"
                      min={0}
                      maxLength={10}
                      step="0.01"
                      type="number"
                      name="billing_amount"
                      placeholder="Billing Amount"
                      onChange={handleBillChange}
                      value={billData.billing_amount}
                      className={`form-modal-input ${billerrors.billing_amount
                        ? "error"
                        : billinputState.billing_amount
                          ? "success"
                          : ""
                        }`}
                    />

                    {billerrors.billing_amount && (
                      <span className="error-message font-size-text ">{billerrors.billing_amount}</span>
                    )}
                  </div>


                  <div className="flex-column">
                    <label
                      htmlFor=""
                      className="announce-date font-weight400  form-inputss width-60vw"
                    ></label>
                    <div className="flex-row action-div">
                      <button className="" onClick={handleCloseBill}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 9L9 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 9L15 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className="form-inputs-submit width-5vw"
                        onClick={(e) => handleBillPost(e, getProjectDetails)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    title="Add Billing Details"
                    onClick={handleShowBill}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}
            </> */}


            <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Documents </h2>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Name</th>
                      <th className="align-center">File</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData?.employees?.map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">
                            {i.document_name}
                          </td>
                          <td className="align-center">
                            {i.document_file ? (
                              // <a href={i.document_file} target="blank">
                              //   View
                              // </a>
                              <a href={i.document_file} target="blank" ><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16" color="#FF747C">
                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                              </svg></a>
                            ) : (
                              "Document Not Attached"
                            )}
                          </td>

                          <td className="align-right width-5vw ">
                            <button className="model-delete-button"
                              onClick={(e) => handlePicDelete(e, i, getProjectDetails)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                  fill="#F72B50"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />


              {showAddPic === true ? (
                <div className="expense-req ">

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor="document_name"
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      File Name
                    </label>
                    <input
                      type="text"
                      id="document_name"
                      name="document_name"
                      placeholder="Document Name"
                      onChange={handleDocInputChange}
                      value={formData.document_name}
                      className={`form-modal-input ${picerrors.document_name
                        ? "error"
                        : picinputState.document_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {picerrors.document_name && (
                      <span className="error-message font-size-text ">{picerrors.document_name}</span>
                    )}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label
                      htmlFor=""
                      className="form-labels announce-date font-weight500  font-size-heading"
                    >
                      Upload File
                    </label>
                    <label
                      htmlFor="document_file"
                      className={`align-center  form-inputss custom-file-upload ${picerrors.document_file
                        ? "error"
                        : picinputState.document_file
                          ? "success"
                          : ""
                        }`}
                    >
                      <svg
                        className="pdf-input"
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                          stroke="#707070"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </label>
                    <input
                      id="document_file"
                      name="document_file"
                      type="file"
                      onChange={handleFileChange}
                      className="file-inputs align-center"
                      style={{ display: "none", position: "relative" }}
                    />
                    {fileName && <p className="file-name align-center">{fileName}</p>}
                    {picerrors.document_file && (
                      <span className="file-name error-message font-size-text ">{picerrors.document_file}</span>
                    )}
                  </div>

                  <div className="flex-column">
                    <label
                      htmlFor=""
                      className="announce-date font-weight400 form-inputss"
                    ></label>
                    <div className="flex-row action-div" >
                      <button className="" onClick={handleClosePic}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 9L9 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 9L15 15"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className="form-inputs-submit width-5vw"
                        onClick={(e) => handlePicPost(e, getProjectDetails)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    title="Add EOT Details"
                    onClick={handleShowPic}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}

            </>


            <button className="model-button   font-weight500   " onClick={handleClose}>
              Close
            </button>
          </form >
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body >
      </Modal >
    </>
  );
};


const AddProjectBill = ({ project, getProjects }) => {

  // console.log(project)
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    project: project ? project : '',
    bill_type: '',
    bill_name: '',
    bill_num: '',
    bill_period_start: '',
    bill_period_end: '',
    billing_date: '',
    billing_amount: '',
    document: null,
    recommended_date: '',
    recommended_amount: ''

  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'bill_type', 'bill_name', 'bill_num',
      'bill_period_start', 'bill_period_end', 'billing_date', 'billing_amount'
    ];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.replace(/_/g, ' ').toUpperCase()} is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  console.log(errors)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      document: e.target.files[0]
    });
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      document: null,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach(key => {
        formDataToSubmit.append(key, formData[key]);
      });

      try {
        const res = await axios.post(`${BASE_URL}/siteproject/billingdetailfield/`, formDataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (res.status === 200) {
          setShow(false);
          await getProjects();
          toast.dismiss(loadingToastId);
          toast.success("Billing detail added successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add bill detail!");
        }
      } catch (err) {
        toast.dismiss(loadingToastId);
        toast.error('Error processing your request.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <button title='Add Bill' className='models-button model-add' style={{ margin: 0 }} onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Bill Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleFormSubmit}>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Bill Name</label>
                  <input
                    type="text"
                    name="bill_name"
                    placeholder="Bill Name"
                    value={formData.bill_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bill_name ? 'is-invalid' : ''}`}
                  />
                  {errors.bill_name && <div className="error-message font-size-text">{errors.bill_name}</div>}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Bill Number</label>
                  <input
                    type="text"
                    name="bill_num"
                    placeholder="Bill Number"
                    value={formData.bill_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bill_num ? 'is-invalid' : ''}`}
                  />
                  {errors.bill_num && <div className="error-message font-size-text">{errors.bill_num}</div>}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="document_type" className="form-labels  font-weight500    font-size-subheading">
                    Bill Type</label>
                  <br />

                  <select
                    id="bill_type"
                    name="bill_type"
                    value={formData.bill_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection  ${errors.bill_type ? 'is-invalid' : ''}`}
                  >
                    <option value="">Select a Type</option>
                    <option value="SPS">SPS</option>
                    <option value="IPC">IPC</option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.bill_type && <div className="error-message font-size-text">{errors.bill_type}</div>}
                  </div>
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Bill Period Start</label>
                  <input
                    type="date"
                    name="bill_period_start"
                    value={formData.bill_period_start}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bill_period_start ? 'is-invalid' : ''}`}
                  />
                  {errors.bill_period_start && <div className="error-message font-size-text">{errors.bill_period_start}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Bill Period End</label>
                  <input
                    type="date"
                    name="bill_period_end"
                    value={formData.bill_period_end}
                    onChange={handleInputChange}
                    min={formData.bill_period_start}
                    className={`form-input ${errors.bill_period_end ? 'is-invalid' : ''}`}
                  />
                  {errors.bill_period_end && <div className="error-message font-size-text">{errors.bill_period_end}</div>}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Billing Date</label>
                  <input
                    type="date"
                    name="billing_date"
                    value={formData.billing_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.billing_date ? 'is-invalid' : ''}`}
                  />
                  {errors.billing_date && <div className="error-message font-size-text">{errors.billing_date}</div>}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Billing Amount</label>
                  <input
                    type="number"
                    min={0}
                    name="billing_amount"
                    placeholder="Billing Amount"
                    value={formData.billing_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.billing_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.billing_amount && <div className="error-message font-size-text">{errors.billing_amount}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Recommended Date</label>
                  <input
                    type="date"
                    name="recommended_date"
                    value={formData.recommended_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.recommended_date ? 'is-invalid' : ''}`}
                  />
                  {errors.recommended_date && <div className="error-message font-size-text">{errors.recommended_date}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Recommended Amount</label>
                  <input
                    type="number"
                    min={0}
                    name="recommended_amount"
                    placeholder="Recommended Amount"
                    value={formData.recommended_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.recommended_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.recommended_amount && <div className="error-message font-size-text">{errors.recommended_amount}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Document</label>
                  <input
                    type="file"
                    id="fileInput"
                    name="document"
                    onChange={handleFileChange}
                    className={`file-input ${errors.document ? 'is-invalid' : ''}`}

                  // style={{ display: 'none' }} // Initially hide the file input
                  />
                  {formData.document ? (
                    <span
                      className="file-clear"
                      onClick={() => handleFileClear("file")}
                    >
                      Clear
                    </span>
                  ) : null}
                  {errors.document && <div className="error-message font-size-text">{errors.document}</div>}
                </div>

                <div className="button-models">
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" type="submit" disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>
  );
};



const AddBillingDetails = ({ project, getProjects, projectsList }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    project: project,
    month: '',
    year: '',
    consultancy: '',
    contractor_submitted: '',
    contractor_recommended: '',
    contractor_bal_submitted: '',
    contractor_bal_recommended: '',
    contractor_remarks: '',
    consultancy_remarks: '',
    recommended: false,
    ld_amt: 0,
    withheld_amt: 0,
    released_withheld_amt: 0
  });


  const formDatamap = [
    'consultancy',
    'contractor_submitted',
    'contractor_recommended',
    'contractor_bal_submitted',
    'contractor_bal_recommended',


  ];

  //  'contractor_remarks',
  //   'consultancy_remarksconsultancy_remarks'



  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);


  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];


  const yearOptions = Array.from({ length: 101 }, (_, i) => (2000 + i).toString());


  const [billDetails, setBillDetails] = useState();


  // console.log(projectsList)

  console.log(project)


  const getBillDetails = async () => {
    //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/department/`);
      const res = await axios.get(
        `${BASE_URL}/siteproject/billingbyprojid/${project}/`
      );

      console.log(res.data)

      setBillDetails(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  console.log(billDetails)



  useEffect(() => {
    getBillDetails();
  }, []);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      // 'month', 'year', 'consultancy', 'contractor_submitted',
      // 'contractor_recommended', 'contractor_bal_submitted',
      // 'contractor_bal_recommended', 'contractor_remarks',
      // 'consultancy_remarks', 'ld_amt', 'withheld_amt',
      // 'released_withheld_amt'

      'consultancy',
    ];
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      try {
        const res = await axios.post(`${BASE_URL}/siteproject/billingdetail/`, formData);
        if (res.status === 200) {
          setShow(false);
          await getProjects();
          toast.dismiss(loadingToastId);
          toast.success("Billing details added successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add billing details!");
        }
      } catch (err) {
        toast.dismiss(loadingToastId);
        toast.error('Error processing your request.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <button title='Add Project Billing Details' className='models-button model-add' onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 16.933 16.933"
          id="bill">
          {/* <circle cx="" cy="" r="15" fill="#F6F7F9" /> */}
          {/* <circle cx="8.5" cy="8.5" r="8.5" fill="#000" /> */}

          <path
            // style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;shape-padding:0;isolation:auto;mix-blend-mode:normal"
            d="M1.854 0C.819 0 0 .83 0 1.852c-.002 4.94.002 9.878.002 14.816 0 .221.256.345.429.207l1.488-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.488-1.19 1.49 1.19a.265.265 0 0 0 .33 0l1.489-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.487-1.19 1.488 1.19c.174.139.43.015.43-.207l.002-8.733h2.91a.26.26 0 0 0 .263-.263V1.854A1.85 1.85 0 0 0 15.083 0Zm0 .53h11.932c-.35.337-.556.8-.556 1.324l-.002 14.265-1.222-.98a.265.265 0 0 0-.33 0l-1.49 1.191-1.488-1.191a.264.264 0 0 0-.33 0L6.878 16.33 5.391 15.14a.265.265 0 0 0-.33 0L3.575 16.33 2.085 15.14a.264.264 0 0 0-.33 0l-1.224.98L.53 1.852c0-.77.634-1.322 1.324-1.322zm13.228 0c.73 0 1.324.586 1.324 1.324v5.554H13.76V1.854c0-.738.592-1.324 1.322-1.324zM3.707 3.439c-.133 0-.265.089-.265.266v.287c-.599.116-1.058.63-1.058 1.252 0 .42.217.672.48.799.264.127.552.147.816.174.265.027.506.06.639.125.133.064.181.15.181.32 0 .406-.34.743-.782.746-.384-.014-.658-.23-.784-.623-.106-.339-.614-.175-.503.162.152.475.48.844 1.011.96v.294c0 .354.53.354.53 0v-.282c.598-.116 1.058-.635 1.058-1.257 0-.388-.18-.654-.48-.799-.264-.126-.552-.146-.817-.173-.264-.028-.502-.056-.639-.126-.122-.062-.181-.142-.181-.32 0-.41.34-.742.783-.745.421.016.666.26.783.622.107.339.615.175.504-.162-.152-.474-.5-.857-1.012-.959v-.295c0-.177-.132-.266-.264-.266zm2.645.265c-.355 0-.355.527 0 .53h4.763c.353 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.53 0 .53h4.763c.352 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.527 0 .527h4.763c.352 0 .352-.527 0-.527zM2.649 9.26c-.356 0-.356.537 0 .529h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.53 0 .53h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.529 0 .53h8.466c.352 0 .352-.53 0-.53z"
            fill="#2576BC"
          // font-family="sans-serif"
          // font-weight="400"
          // overflow="visible"
          >
          </path>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update Billing Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleFormSubmit}>

                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" >Month</label>
                  <br />
                  <select
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.month ? 'is-invalid' : ''}`}
                  >
                    <option value="">Select month</option>
                    {monthOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.month && <div className="error-message font-size-text">{errors.month}</div>}
                </div>
                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" >Year</label>
                  <br />
                  <select
                    name="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.year ? 'is-invalid' : ''}`}
                  >
                    <option value="">Select year</option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.year && <div className="error-message font-size-text">{errors.year}</div>}
                </div>
                {/* Render form fields dynamically */}
                {formDatamap.map((key) => {

                  // console.log(key)

                  return (
                    <div className="flex-column form-group-selectt" key={key}>
                      <label className="form-labels font-weight500 font-size-subheading form-text-trasformation-uppercase" >{key.replace(/_/g, ' ').toLowerCase()}</label>
                      <br />
                      <select
                        name={key}
                        value={formData[key]}
                        onChange={handleInputChange}
                        className={`form-input form-group-selection ${errors[key] ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select a bill</option>
                        {billDetails?.map((detail) => (
                          <option key={detail.id} value={detail.id}>
                            {detail.bill_type}-{detail.bill_name}
                          </option>
                        ))}

                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          class="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                      {errors[key] && <div className="error-message font-size-text">{errors[key]}</div>}
                    </div>
                  )
                })}
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Contractor Remarks</label>
                  <input
                    type="text"
                    name="contractor_remarks"
                    placeholder="Contractor Remarks"
                    value={formData.contractor_remarks}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Consultancy Remarks</label>
                  <input
                    type="text"
                    name="consultancy_remarks"
                    placeholder="Consultancy Remarks"
                    value={formData.consultancy_remarks}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>


                <div className="flex-row">
                  <label className="form-labels font-weight500 font-size-subheading" >Recommended : </label>
                  <input
                    type="checkbox"
                    name="recommended"
                    checked={formData.recommended}
                    onChange={(e) => setFormData({ ...formData, recommended: e.target.checked })}
                    className="form-checkbox input-checkbox "
                  />
                  {errors.recommended && <span className="error-message font-size-text ">{errors.recommended}</span>}

                </div>
                <div className="button-models">
                  <button type="submit" className='model-button font-weight500 model-button-submit' disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </>
  );
};

const UpdateBillingDetails = ({ project, getProjects, projectsList, billingData }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    project: project,
    month: billingData.month || '',
    year: billingData.year || '',
    consultancy: billingData.consultancy?.id || '',
    contractor_submitted: billingData.contractor_submitted?.id || '',
    contractor_recommended: billingData.contractor_recommended?.id || '',
    contractor_bal_submitted: billingData.contractor_bal_submitted?.id || '',
    contractor_bal_recommended: billingData.contractor_bal_recommended?.id || '',
    contractor_remarks: billingData.contractor_remarks || '',
    consultancy_remarks: billingData.consultancy_remarks || '',
    recommended: billingData.recommended || false,
    ld_amt: billingData.ld_amt || 0,
    withheld_amt: billingData.withheld_amt || 0,
    released_withheld_amt: billingData.released_withheld_amt || 0
  });



  const formDatamap = [
    'consultancy',
    'contractor_submitted',
    'contractor_recommended',
    'contractor_bal_submitted',
    'contractor_bal_recommended',


  ];

  //  'contractor_remarks',
  //   'consultancy_remarksconsultancy_remarks'



  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);


  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];


  const yearOptions = Array.from({ length: 101 }, (_, i) => (2000 + i).toString());


  const [billDetails, setBillDetails] = useState();


  // console.log(projectsList)

  console.log(project)


  const getBillDetails = async () => {
    //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/department/`);
      const res = await axios.get(
        `${BASE_URL}/siteproject/billingbyprojid/${project}/`
      );

      console.log(res.data)

      setBillDetails(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  console.log(billDetails)



  useEffect(() => {
    getBillDetails();
  }, []);



  const handleClose = () => setShow(false);
  const handleShow = () => {
    // console.log(billingData);
    setShow(true)
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      // 'month', 'year', 'consultancy', 'contractor_submitted',
      // 'contractor_recommended', 'contractor_bal_submitted',
      // 'contractor_bal_recommended', 'contractor_remarks',
      // 'consultancy_remarks', 'ld_amt', 'withheld_amt',
      // 'released_withheld_amt'

      'consultancy',
    ];
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      try {
        const res = await axios.post(`${BASE_URL}/siteproject/billingdetail/`, formData);
        if (res.status === 200) {
          setShow(false);
          await getProjects();
          toast.dismiss(loadingToastId);
          toast.success("Billing details added successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add billing details!");
        }
      } catch (err) {
        toast.dismiss(loadingToastId);
        toast.error('Error processing your request.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <button title='Add Project Billing Details' className='models-button model-add' onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#2576BC" viewBox="0 0 24 24" id="pen-new-square">
          <path fill="#2576BC" fill-rule="evenodd" d="M11.9426 1.25L13.5 1.25C13.9142 1.25 14.25 1.58579 14.25 2C14.25 2.41421 13.9142 2.75 13.5 2.75H12C9.62177 2.75 7.91356 2.75159 6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.23505 4.75455 2.91245 5.86509 2.80417 8.03734C2.78355 8.45104 2.43146 8.76969 2.01776 8.74907C1.60406 8.72845 1.28541 8.37636 1.30603 7.96266C1.41642 5.74802 1.74641 4.12187 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM22 9.75C22.4142 9.75 22.75 10.0858 22.75 10.5V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574L1.25 12C1.25 11.5858 1.58579 11.25 2 11.25C2.41421 11.25 2.75 11.5858 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12V10.5C21.25 10.0858 21.5858 9.75 22 9.75Z" clip-rule="evenodd"></path>
          <path fill="#2576BC" fill-rule="evenodd" d="M16.7705 2.27592C18.1384 0.908029 20.3562 0.908029 21.7241 2.27592C23.092 3.6438 23.092 5.86158 21.7241 7.22947L18.0928 10.8607C17.7999 11.1536 17.3251 11.1536 17.0322 10.8607C16.7393 10.5678 16.7393 10.093 17.0322 9.80006L19.1051 7.72709C18.5892 7.50519 17.9882 7.14946 17.4193 6.58065C16.8505 6.01185 16.4948 5.41082 16.2729 4.89486L11.2175 9.95026C10.801 10.3668 10.6376 10.532 10.4988 10.71C10.3274 10.9297 10.1804 11.1676 10.0605 11.4192C9.96337 11.623 9.88868 11.8429 9.7024 12.4017L9.27051 13.6974L10.3026 14.7295L11.5983 14.2976C12.1571 14.1113 12.377 14.0366 12.5808 13.9395C12.8324 13.8196 13.0703 13.6726 13.2901 13.5012C13.468 13.3624 13.6332 13.199 14.0497 12.7825C14.3426 12.4896 14.8175 12.4896 15.1104 12.7825C15.4033 13.0754 15.4033 13.5503 15.1104 13.8432L15.076 13.8776C14.7047 14.2489 14.4721 14.4815 14.2126 14.684C13.9069 14.9224 13.5761 15.1268 13.2261 15.2936C12.929 15.4352 12.6169 15.5392 12.1188 15.7052L9.21426 16.6734C8.67801 16.8521 8.0868 16.7126 7.68711 16.3129C7.28742 15.9132 7.14785 15.322 7.3266 14.7857L8.29477 11.8812C8.46079 11.3831 8.56479 11.071 8.7064 10.7739C8.87319 10.4239 9.07761 10.0931 9.31605 9.78742C9.51849 9.52787 9.7511 9.29529 10.1224 8.92401L16.7705 2.27592ZM17.4546 3.7132C17.4773 3.80906 17.509 3.92327 17.5532 4.05066C17.6965 4.46372 17.9677 5.00771 18.48 5.51999C18.9923 6.03227 19.5363 6.30346 19.9493 6.44677C20.0767 6.49097 20.1909 6.52273 20.2868 6.54543L20.6634 6.16881C21.4455 5.38671 21.4455 4.11867 20.6634 3.33658C19.8813 2.55448 18.6133 2.55448 17.8312 3.33658L17.4546 3.7132Z" clip-rule="evenodd"></path>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Update Billing Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleFormSubmit}>

                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" >Month</label>
                  <br />
                  <select
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.month ? 'is-invalid' : ''}`}
                  >
                    <option value="">Select month</option>
                    {monthOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.month && <div className="error-message font-size-text">{errors.month}</div>}
                </div>
                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" >Year</label>
                  <br />
                  <select
                    name="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.year ? 'is-invalid' : ''}`}
                  >
                    <option value="">Select year</option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.year && <div className="error-message font-size-text">{errors.year}</div>}
                </div>
                {/* Render form fields dynamically */}
                {formDatamap.map((key) => {

                  // console.log(key)

                  return (
                    <div className="flex-column form-group-selectt" key={key}>
                      <label className="form-labels font-weight500 font-size-subheading form-text-trasformation-uppercase" >{key.replace(/_/g, ' ').toLowerCase()}</label>
                      <br />
                      <select
                        name={key}
                        value={formData[key]}
                        onChange={handleInputChange}
                        className={`form-input form-group-selection ${errors[key] ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select a bill</option>
                        {billDetails?.map((detail) => (
                          <option key={detail.id} value={detail.id}>
                            {detail.bill_type}-{detail.bill_name}
                          </option>
                        ))}

                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          class="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                      {errors[key] && <div className="error-message font-size-text">{errors[key]}</div>}
                    </div>
                  )
                })}
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Contractor Remarks</label>
                  <input
                    type="text"
                    name="contractor_remarks"
                    placeholder="Contractor Remarks"
                    value={formData.contractor_remarks}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Consultancy Remarks</label>
                  <input
                    type="text"
                    name="consultancy_remarks"
                    placeholder="Consultancy Remarks"
                    value={formData.consultancy_remarks}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>


                <div className="flex-row">
                  <label className="form-labels font-weight500 font-size-subheading" >Recommended : </label>
                  <input
                    type="checkbox"
                    name="recommended"
                    checked={formData.recommended}
                    onChange={(e) => setFormData({ ...formData, recommended: e.target.checked })}
                    className="form-checkbox input-checkbox "
                  />
                  {errors.recommended && <span className="error-message font-size-text ">{errors.recommended}</span>}

                </div>
                <div className="button-models">
                  <button type="submit" className='model-button font-weight500 model-button-submit' disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </>
  );
};




const AddTeamMember = ({ project, getProjects }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    // getCompleteJobpostlist();
    console.log("project:")
    console.log(project)
    setShow(true)
  };


  const [formData, setFormData] = useState({
    member_name: "",
    member_designation: "",
  });


  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "member_name",
      "member_designation",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(`${BASE_URL}/project/addcustomprojectteam/${project.id}`, formData)

        if (res.status === 200) {
          setShow(false)
          await getProjects()
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title='Add Team Member' className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}



              <div className="flex-column">
                <label htmlFor="member_name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="member_name"
                  name="member_name"
                  onChange={handleInputChange}
                  value={formData.member_name}
                  className={`form-text-trasformation-uppercase form-input ${errors.member_name ? "error" : inputState.member_name ? "success" : ""
                    }`}
                />
                {errors.member_name && <span className="error-message font-size-text ">{errors.member_name}</span>}
              </div>


              <div className="flex-column">
                <label htmlFor="member_designation" className="form-labels  font-weight500    font-size-subheading">
                  Designation<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="member_designation"
                  name="member_designation"
                  onChange={handleInputChange}
                  value={formData.member_designation}
                  className={`form-input ${errors.member_designation ? "error" : inputState.member_designation ? "success" : ""
                    }`}
                />
                {errors.member_designation && <span className="error-message font-size-text ">{errors.member_designation}</span>}
              </div>


              <div className="button-models">
                <button className="model-button   font-weight500    " onClick={handleClose}>Cancel</button>

                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add Member
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


// ? *************** Project Crud Models End ***************


const AddTrackRecordDetails = ({ getProjects, projectList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    // setFormData(i);
    setShow(true)
  };

  const [formData, setFormData] = useState({
    track_id: "",
    project: "",
    reviewed_by: "",
    subject: "",
    sent_letter_no: "",
    sent_letter_date: "",
    received_letter_no: "",
    received_letter_date: "",
    status: "",
    sent_to_tl: "",
    reply_pending: "",
    reply_sent: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "track_id",
      "project",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(`${BASE_URL}/siteproject/trackrecord/`, formData,)

        if (res.status === 200) {
          setShow(false)
          await getProjects()
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title='Add Project' className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Add Track Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">

              <div title="Track ID" className="flex-column">
                <label htmlFor="track_id" className="form-labels  font-weight500    font-size-subheading">
                  Track ID
                </label>
                <input
                  type="text"
                  id="track_id"
                  name="track_id"
                  placeholder="Track ID"
                  onChange={handleInputChange}
                  value={formData.track_id}
                  className={`form-input ${errors.track_id ? "error" : inputState.track_id ? "success" : ""}`}
                />
                {errors.track_id && <span className="error-message font-size-text ">{errors.track_id}</span>}
              </div>

              <div title="Project" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Project<span className="required">*</span>
                </label>
                <br />
                <select
                  id="project"
                  name="project"
                  placeholder="Project"
                  value={formData.project}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                >
                  <option value="">Select a Project</option>
                  {projectList?.sort((a, b) => a.project_name.localeCompare(b.project_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.project_name}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
              </div>

              <div title="Reviewed By" className="flex-column">
                <label htmlFor="reviewed_by" className="form-labels  font-weight500    font-size-subheading">
                  Reviewed By<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="reviewed_by"
                  name="reviewed_by"
                  placeholder="Reviewed By"
                  onChange={handleInputChange}
                  value={formData.reviewed_by}
                  className={`form-input ${errors.reviewed_by ? "error" : inputState.reviewed_by ? "success" : ""}`}
                />
                {errors.reviewed_by && <span className="error-message font-size-text ">{errors.reviewed_by}</span>}
              </div>

              <div title="Subject" className="flex-column">
                <label htmlFor="subject" className="form-labels  font-weight500    font-size-subheading">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  max={150}
                  name="subject"
                  placeholder="Subject"
                  onChange={handleInputChange}
                  value={formData.subject}
                  className={`form-input ${errors.subject ? "error" : inputState.subject ? "success" : ""}`}
                />
                {errors.subject && <span className="error-message font-size-text ">{errors.subject}</span>}
              </div>

              <div title="Sent Letter No." className="flex-column">
                <label htmlFor="sent_letter_no" className="form-labels  font-weight500    font-size-subheading">
                  Sent Letter No.
                </label>
                <input
                  type="text"
                  id="sent_letter_no"
                  name="sent_letter_no"
                  placeholder="Sent Letter No."
                  onChange={handleInputChange}
                  value={formData.sent_letter_no}
                  className={`form-input ${errors.sent_letter_no ? "error" : inputState.sent_letter_no ? "success" : ""}`}
                />
                {errors.sent_letter_no && <span className="error-message font-size-text ">{errors.sent_letter_no}</span>}
              </div>

              <div title="Sent Letter Date" className="flex-column">
                <label htmlFor="sent_letter_date" className="form-labels  font-weight500    font-size-subheading">
                  Sent Letter Date
                </label>
                <input
                  type="date"
                  id="sent_letter_date"
                  name="sent_letter_date"
                  placeholder="Sent Letter Date"
                  onChange={handleInputChange}
                  value={formData.sent_letter_date}
                  className={`form-input ${errors.sent_letter_date ? "error" : inputState.sent_letter_date ? "success" : ""}`}
                />
                {errors.sent_letter_date && <span className="error-message font-size-text ">{errors.sent_letter_date}</span>}
              </div>

              <div title="Received Letter No." className="flex-column">
                <label htmlFor="received_letter_no" className="form-labels  font-weight500    font-size-subheading">
                  Received Letter No.
                </label>
                <input
                  type="text"
                  id="received_letter_no"
                  name="received_letter_no"
                  placeholder="Received Letter No."
                  onChange={handleInputChange}
                  value={formData.received_letter_no}
                  className={`form-input ${errors.received_letter_no ? "error" : inputState.received_letter_no ? "success" : ""}`}
                />
                {errors.received_letter_no && <span className="error-message font-size-text ">{errors.received_letter_no}</span>}
              </div>

              <div title="Received Letter Date" className="flex-column">
                <label htmlFor="received_letter_date" className="form-labels  font-weight500    font-size-subheading">
                  Received Letter Date
                </label>
                <input
                  type="date"
                  id="received_letter_date"
                  name="received_letter_date"
                  placeholder="Received Letter Date"
                  onChange={handleInputChange}
                  value={formData.received_letter_date}
                  className={`form-input ${errors.received_letter_date ? "error" : inputState.received_letter_date ? "success" : ""}`}
                />
                {errors.received_letter_date && <span className="error-message font-size-text ">{errors.received_letter_date}</span>}
              </div>

              <div title="Status" className="flex-column form-group-selectt">
                <label htmlFor="status" className="form-labels  font-weight500    font-size-subheading">
                  Status<span className="required">*</span>
                </label>
                <br />
                <select
                  id="status"
                  name="status"
                  placeholder="Status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""}`}
                >
                  <option value="">Select a Status</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.status && <span className="error-message font-size-text ">{errors.status}</span>}
              </div>



              <div title="Sent to Tl" className="flex-column">
                <label htmlFor="sent_to_tl" className="form-labels  font-weight500    font-size-subheading">
                  Sent to Tl
                </label>
                <input
                  type="text"
                  id="sent_to_tl"
                  name="sent_to_tl"
                  placeholder="Sent to Tl"
                  onChange={handleInputChange}
                  value={formData.sent_to_tl}
                  className={`form-input ${errors.sent_to_tl ? "error" : inputState.sent_to_tl ? "success" : ""}`}
                />
                {errors.sent_to_tl && <span className="error-message font-size-text ">{errors.sent_to_tl}</span>}
              </div>


              <div title="Reply Pending" className="flex-column">
                <label htmlFor="reply_pending" className="form-labels  font-weight500    font-size-subheading">
                  Reply Pending
                </label>
                <input
                  type="checkbox"
                  id="reply_pending"
                  name="reply_pending"
                  value={formData.reply_pending}
                  onChange={handleInputChange}
                  className={`form-checkbox input-checkbox ${errors.reply_pending ? "error" : inputState.reply_pending ? "success" : ""}`}
                />
                {errors.reply_pending && <span className="error-message font-size-text ">{errors.reply_pending}</span>}
              </div>

              <div title="Reply Sent" className="flex-column">
                <label htmlFor="reply_sent" className="form-labels  font-weight500    font-size-subheading">
                  Reply Sent
                </label>
                <input
                  type="checkbox"
                  id="reply_sent"
                  name="reply_sent"
                  value={formData.reply_sent}
                  onChange={handleInputChange}
                  className={`form-checkbox input-checkbox ${errors.reply_sent ? "error" : inputState.reply_sent ? "success" : ""}`}
                />
                {errors.reply_sent && <span className="error-message font-size-text ">{errors.reply_sent}</span>}
              </div>


            </div>

            <div className="button-models">
              <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                Submit
              </button>
            </div>

          </form>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateTrackRecordDetails = ({ i, getProjects, projectList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    // setFormData(i);
    setShow(true)
  };

  const [formData, setFormData] = useState({
    track_id: "",
    project: "",
    reviewed_by: "",
    subject: "",
    sent_letter_no: "",
    sent_letter_date: "",
    received_letter_no: "",
    received_letter_date: "",
    status: "",
    sent_to_tl: "",
    reply_pending: "",
    reply_sent: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "track_id",
      "project",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/siteproject/trackrecord/`, formData,)

        if (res.status === 200) {
          setShow(false)
          await getProjects()
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title='Add Project' className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Add Track Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">

              <div title="Track ID" className="flex-column">
                <label htmlFor="track_id" className="form-labels  font-weight500    font-size-subheading">
                  Track ID
                </label>
                <input
                  type="text"
                  id="track_id"
                  name="track_id"
                  placeholder="Track ID"
                  onChange={handleInputChange}
                  value={formData.track_id}
                  className={`form-input ${errors.track_id ? "error" : inputState.track_id ? "success" : ""}`}
                />
                {errors.track_id && <span className="error-message font-size-text ">{errors.track_id}</span>}
              </div>

              <div title="Project" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Project<span className="required">*</span>
                </label>
                <br />
                <select
                  id="project"
                  name="project"
                  placeholder="Project"
                  value={formData.project}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                >
                  <option value="">Select a Project</option>
                  {projectList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.title}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
              </div>

              <div title="Reviewed By" className="flex-column">
                <label htmlFor="reviewed_by" className="form-labels  font-weight500    font-size-subheading">
                  Reviewed By<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="reviewed_by"
                  name="reviewed_by"
                  placeholder="Reviewed By"
                  onChange={handleInputChange}
                  value={formData.reviewed_by}
                  className={`form-input ${errors.reviewed_by ? "error" : inputState.reviewed_by ? "success" : ""}`}
                />
                {errors.reviewed_by && <span className="error-message font-size-text ">{errors.reviewed_by}</span>}
              </div>

              <div title="Subject" className="flex-column">
                <label htmlFor="subject" className="form-labels  font-weight500    font-size-subheading">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  max={150}
                  name="subject"
                  placeholder="Subject"
                  onChange={handleInputChange}
                  value={formData.subject}
                  className={`form-input ${errors.subject ? "error" : inputState.subject ? "success" : ""}`}
                />
                {errors.subject && <span className="error-message font-size-text ">{errors.subject}</span>}
              </div>

              <div title="Sent Letter No." className="flex-column">
                <label htmlFor="sent_letter_no" className="form-labels  font-weight500    font-size-subheading">
                  Sent Letter No.
                </label>
                <input
                  type="text"
                  id="sent_letter_no"
                  name="sent_letter_no"
                  placeholder="Sent Letter No."
                  onChange={handleInputChange}
                  value={formData.sent_letter_no}
                  className={`form-input ${errors.sent_letter_no ? "error" : inputState.sent_letter_no ? "success" : ""}`}
                />
                {errors.sent_letter_no && <span className="error-message font-size-text ">{errors.sent_letter_no}</span>}
              </div>

              <div title="Sent Letter Date" className="flex-column">
                <label htmlFor="sent_letter_date" className="form-labels  font-weight500    font-size-subheading">
                  Sent Letter Date
                </label>
                <input
                  type="date"
                  id="sent_letter_date"
                  name="sent_letter_date"
                  placeholder="Sent Letter Date"
                  onChange={handleInputChange}
                  value={formData.sent_letter_date}
                  className={`form-input ${errors.sent_letter_date ? "error" : inputState.sent_letter_date ? "success" : ""}`}
                />
                {errors.sent_letter_date && <span className="error-message font-size-text ">{errors.sent_letter_date}</span>}
              </div>

              <div title="Received Letter No." className="flex-column">
                <label htmlFor="received_letter_no" className="form-labels  font-weight500    font-size-subheading">
                  Received Letter No.
                </label>
                <input
                  type="text"
                  id="received_letter_no"
                  name="received_letter_no"
                  placeholder="Received Letter No."
                  onChange={handleInputChange}
                  value={formData.received_letter_no}
                  className={`form-input ${errors.received_letter_no ? "error" : inputState.received_letter_no ? "success" : ""}`}
                />
                {errors.received_letter_no && <span className="error-message font-size-text ">{errors.received_letter_no}</span>}
              </div>

              <div title="Received Letter Date" className="flex-column">
                <label htmlFor="received_letter_date" className="form-labels  font-weight500    font-size-subheading">
                  Received Letter Date
                </label>
                <input
                  type="date"
                  id="received_letter_date"
                  name="received_letter_date"
                  placeholder="Received Letter Date"
                  onChange={handleInputChange}
                  value={formData.received_letter_date}
                  className={`form-input ${errors.received_letter_date ? "error" : inputState.received_letter_date ? "success" : ""}`}
                />
                {errors.received_letter_date && <span className="error-message font-size-text ">{errors.received_letter_date}</span>}
              </div>

              <div title="Status" className="flex-column form-group-selectt">
                <label htmlFor="status" className="form-labels  font-weight500    font-size-subheading">
                  Status<span className="required">*</span>
                </label>
                <br />
                <select
                  id="status"
                  name="status"
                  placeholder="Status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""}`}
                >
                  <option value="">Select a Status</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.status && <span className="error-message font-size-text ">{errors.status}</span>}
              </div>



              <div title="Sent to Tl" className="flex-column">
                <label htmlFor="sent_to_tl" className="form-labels  font-weight500    font-size-subheading">
                  Sent to Tl
                </label>
                <input
                  type="text"
                  id="sent_to_tl"
                  name="sent_to_tl"
                  placeholder="Sent to Tl"
                  onChange={handleInputChange}
                  value={formData.sent_to_tl}
                  className={`form-input ${errors.sent_to_tl ? "error" : inputState.sent_to_tl ? "success" : ""}`}
                />
                {errors.sent_to_tl && <span className="error-message font-size-text ">{errors.sent_to_tl}</span>}
              </div>


              <div title="Reply Pending" className="flex-column">
                <label htmlFor="reply_pending" className="form-labels  font-weight500    font-size-subheading">
                  Reply Pending
                </label>
                <input
                  type="checkbox"
                  id="reply_pending"
                  name="reply_pending"
                  value={formData.reply_pending}
                  onChange={handleInputChange}
                  className={`form-checkbox input-checkbox ${errors.reply_pending ? "error" : inputState.reply_pending ? "success" : ""}`}
                />
                {errors.reply_pending && <span className="error-message font-size-text ">{errors.reply_pending}</span>}
              </div>

              <div title="Reply Sent" className="flex-column">
                <label htmlFor="reply_sent" className="form-labels  font-weight500    font-size-subheading">
                  Reply Sent
                </label>
                <input
                  type="checkbox"
                  id="reply_sent"
                  name="reply_sent"
                  value={formData.reply_sent}
                  onChange={handleInputChange}
                  className={`form-checkbox input-checkbox ${errors.reply_sent ? "error" : inputState.reply_sent ? "success" : ""}`}
                />
                {errors.reply_sent && <span className="error-message font-size-text ">{errors.reply_sent}</span>}
              </div>


            </div>

            <div className="button-models">
              <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                Submit
              </button>
            </div>

          </form>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};




// ! *************** Sector Crud Models Start ***************



const AddCircular = ({ getAnnouncement }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const [formData, setFormData] = useState({
    title: "",
    file: "",
    description: "",
    publish_date: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title", "description", "file"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("title", formData.title);
      formDataa.append("description", formData.description);
      formDataa.append("publish_date", formData.publish_date);

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.file;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });
      formDataa.append("file", customFile);

      try {
        let res = await axios.post(
          `${BASE_URL}/siteproject/circularupload/`,
          formDataa,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          window.location.reload();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const validFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];

      if (validFileTypes.includes(file.type)) {
        setFormData({
          ...formData,
          [name]: file,
        });
        setErrors({
          ...errors,
          [name]: "", // Clear any previous errors for this field
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF or DOC file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };


  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="task-Tab-heading font-weight500    font-size-heading">Circular</h6>

              <form>
                <div className="flex-column">
                  <label htmlFor="title" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                    Title
                  </label>
                  <input
                    id="title"
                    type="title"
                    name="title"
                    maxLength={50}
                    placeholder="title"
                    onChange={handleInputChange}
                    value={formData.title}
                    className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                      }`}
                  />
                  {errors.title && (
                    <span className="error-message font-size-text ">{errors.title}</span>
                  )}
                </div>
                <div className="flex-column">
                  <label htmlFor="date" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                    Publish Date
                  </label>
                  <input
                    id="date"
                    type="date"
                    name="publish_date"
                    placeholder="date"
                    onChange={handleInputChange}
                    value={formData.publish_date}
                    className={`form-input ${errors.publish_date ? "error" : inputState.publish_date ? "success" : ""
                      }`}
                  />
                  {errors.publish_date && (
                    <span className="error-message font-size-text ">{errors.publish_date}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label
                    htmlFor="description"
                    className="form-labels font-weight500    announce-date font-weight400  font-size-heading"
                  >
                    Description
                  </label>
                  <textarea
                    placeholder="Description"
                    type="text"
                    name="description"
                    onChange={handleInputChange}
                    value={formData.description}
                    className={`form-input-textarea font-weight400  font-size-subheading form-input-background ${errors.description
                      ? "error"
                      : inputState.description
                        ? "success"
                        : ""
                      }`}
                  />

                  {errors.description && (
                    <span className="error-message font-size-text ">{errors.description}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label
                    htmlFor="file"
                    className="form-labels font-weight500    announce-date font-weight400  font-size-heading"
                  >
                    Attachment
                  </label>
                  <input
                    id="file"
                    type="file"
                    name="file"
                    accept=".pdf, .doc, .docx, .xls, .xlsx"
                    onChange={handleFileChange}
                    className="file-input"
                  />

                  {errors.file && (
                    <span className="error-message font-size-text ">{errors.file}</span>
                  )}
                  {formData.file ? (
                    <span
                      className="file-clear"
                      onClick={() => handleFileClear("file")}
                    >
                      Clear
                    </span>
                  ) : null}
                </div>
              </form>
              <div className="button-models">
                <button className="model-button font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const UpdateCircular = ({ i, getWebsiteSectors }) => {
  const [show, setShow] = useState(false);
  const { PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredList, setFilteredList] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() && formData.title != null) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/website/sectors/`, formData);

        if (res.status === 200) {
          await getWebsiteSectors();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        // alert(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Add Sector" className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Created By<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Created By"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const DeleteCircular = ({ i, getSiteDepartment }) => {
  const [show, setShow] = useState(false);
  const [inactiveDepartment, setInactiveDepartment] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/designation/${i.id}/`);

      if (res.status === 200) {
        await getSiteDepartment();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete Site Designation" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Designation {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete designation {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};



const AddEOTDetails = ({ project, getProjects }) => {

  // console.log(project)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    project: project,
    eot_code: "",
    approved_date: "",
    approved_days: "",
    workperformedby: "",
  });


  const [type, setType] = useState();
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateEotForm = () => {
    const newErrors = {};
    const RequiredFields = [
      "project",
      "eot_code",
      "approved_date",
      "approved_days",
      "workperformedby",
    ];

    RequiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleEotPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateEotForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/eot/`, formData)

        if (res.status === 200) {
          await getProjects()
          handleClose();
          setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };




  return (
    <>
      <button title='Add EOT' className='models-button model-add' style={{ margin: 0 }} onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add EOT Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleEotPost}>

                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >EOT Code</label>
                  <input
                    type="text"
                    name="eot_code"
                    placeholder="EOT Code"
                    value={formData.eot_code}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Approved Date</label>
                  <input
                    type="date"
                    name="approved_date"
                    // placeholder="Contractor Remarks"
                    value={formData.approved_date}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Approved Days</label>
                  <input
                    type="text"
                    name="approved_days"
                    placeholder="Approved Days"
                    value={formData.approved_days}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>



                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" >Work Performed By</label>
                  <br />
                  <select
                    name="workperformedby"
                    value={formData.workperformedby}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection`}
                  >
                    <option value="">Select Option</option>
                    <option value="contractor">Contractor</option>

                    <option value="consultant">Consultant</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.month && <div className="error-message font-size-text">{errors.month}</div>}
                </div>

                <div className="button-models">
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" type="submit" disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>
  );
};



const AddCOSDetails = ({ project, getProjects }) => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    project: project,
    cos_code: "",
    approval_date: "",
    amount: "",
    workperformedby: "",
  });


  const [type, setType] = useState();
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateEotForm = () => {
    const newErrors = {};
    const RequiredFields = [
      "project",
      "cos_code",
      "approval_date",
      "amount",
      "workperformedby",
    ];

    RequiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleEotPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateEotForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/cos/`, formData)

        if (res.status === 200) {
          await getProjects()
          handleClose();
          setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };





  return (
    <>
      <button title='Add COS' className='models-button model-add' style={{ margin: 0 }} onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add COS Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleEotPost}>


                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >COS Code</label>
                  <input
                    type="text"
                    name="cos_code"
                    placeholder="COS Code"
                    value={formData.cos_code}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>



                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Approved Date</label>
                  <input
                    type="date"
                    name="approved_date"
                    // placeholder="Contractor Remarks"
                    value={formData.approved_date}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>


                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Amount</label>
                  <input
                    type="text"
                    name="amount"
                    placeholder="Amount"
                    value={formData.amount}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>



                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" >Work Performed By</label>
                  <br />
                  <select
                    name="workperformedby"
                    value={formData.workperformedby}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection`}
                  >
                    <option value="">Select Option</option>
                    <option value="contractor">Contractor</option>

                    <option value="consultant">Consultant</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.month && <div className="error-message font-size-text">{errors.month}</div>}
                </div>



                <div className="button-models">
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" type="submit" disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>
  );
};


const AddMilstoneDetails = ({ project, getProjects, appointed_date }) => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    project: project,
    name: "",
    milestone_date: "",
    day: "",
    workperformedby: "",
  });


  const [type, setType] = useState();
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateEotForm = () => {
    const newErrors = {};
    const RequiredFields = [
      "project",
      "name",
      "milestone_date",
      "day",
      "workperformedby",
    ];

    RequiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleEotPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validateEotForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {

        let res = await axios.post(`${BASE_URL}/siteproject/milestone/`, formData)

        if (res.status === 200) {
          await getProjects()
          handleClose();
          setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };





  return (
    <>
      <button title='Add Milestone' className='models-button model-add' style={{ margin: 0 }} onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Milestone Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleEotPost}>


                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>



                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" >Milestone Date</label>
                  <input
                    type="date"
                    name="milestone_date"
                    // placeholder="Contractor Remarks"
                    value={formData.milestone_date}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>


                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">Day</label>
                  <input
                    type="text"
                    name="day"
                    placeholder="Day"
                    value={formData.day}
                    onChange={handleInputChange}

                    className="form-input"
                  />
                </div>



                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" >Work Performed By</label>
                  <br />
                  <select
                    name="workperformedby"
                    value={formData.workperformedby}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection`}
                  >
                    <option value="">Select Option</option>
                    <option value="contractor">Contractor</option>

                    <option value="consultant">Consultant</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.month && <div className="error-message font-size-text">{errors.month}</div>}
                </div>



                <div className="button-models">
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" type="submit" disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>
  );
};





export {
  AddSiteDepartment,
  UpdateSiteDepartment,
  DeleteSiteDepartment,

  AddSiteSubcompanys,
  UpdateSiteSubcompanyupdate,
  DeleteSiteSubcompany,

  AddSiteEmployee,
  UpdateSiteEmployee,
  DeleteSiteEmployee,

  AddProjectDetails,
  UpdateProjectDetails,
  UpdateProjectAllDetails,

  AddProjectBill,
  AddBillingDetails,
  UpdateBillingDetails,

  AddTeamMember,

  AddTrackRecordDetails,
  UpdateTrackRecordDetails,

  AddCircular,
  UpdateCircular,
  DeleteCircular,


  AddEOTDetails,
  AddCOSDetails,
  AddMilstoneDetails

};
