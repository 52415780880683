import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from 'react-toastify';
import usePermission from "../../config/permissions";
import { formattedDate, formattedDateLong } from "../Date";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



// ! *************** Sector Crud Models Start ***************

const AddSector = ({ getWebsiteSectors, sectorList }) => {
  const [show, setShow] = useState(false);
  const { PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredList, setFilteredList] = useState([]);

  const getCurrentDateTime = () => {
    const date = new Date();
    const pad = (n) => (n < 10 ? '0' + n : n);

    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1); // Months are zero-based
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());
    const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
    const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return isoString;
  };
  const [formData, setFormData] = useState({
    icon: "",
    name: "",
    created_by: sessionStorage.getItem('emp_code'),
    created_at: getCurrentDateTime(),
    // sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'name') {
      const filtered = sectorList.filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }

  };


  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataa = new FormData();
      formDataa.append('icon', formData.icon);
      formDataa.append('name', formData.name);
      formDataa.append('created_by', formData.created_by);
      formDataa.append('created_at', formData.created_at);
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/website/sectors/`, formDataa);

        if (res.status === 200) {
          await getWebsiteSectors();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        // alert(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Add Sector" className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}

              {/* <div className="flex-column">
                <label htmlFor="icon" className="form-labels  font-weight500    font-size-subheading">
                  Icon<span className="required">*</span>
                </label>
                <ReactQuill
                  id="icon"
                  name="icon"
                  value={icon}
                  onChange={setIcon}
                  className={`form-input-quill ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.icon && (
                  <span className="error-message font-size-text ">{errors.icon}</span>
                )}
              </div> */}

              <div className="flex-column">
                <label htmlFor="icon" className="form-labels  font-weight500    font-size-subheading">
                  Icon<span className="required">*</span>
                </label>
                <input
                  type="file"
                  name="icon"
                  accept=".jpg, .jpeg, .png, .svg "
                  onChange={handleFileChange}
                  // className="file-input "
                  className={`file-input ${errors.name ? "error" : inputState.name ? "success" : ""}`}
                />
                {formData.attachment ? (
                  <span
                    className="file-clear"
                    onClick={() => handleFileClear("attachment")}
                  >
                    Clear
                  </span>
                ) : null}
                {errors.icon && (
                  <span className="error-message font-size-text ">{errors.icon}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.name}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const UpdateSector = ({ i, getWebsiteSectors }) => {
  const [show, setShow] = useState(false);
  const { PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredList, setFilteredList] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() && formData.title != null) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/website/sectors/`, formData);

        if (res.status === 200) {
          await getWebsiteSectors();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        // alert(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Add Sector" className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Created By<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Created By"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const DeleteSector = ({ i, getSiteDepartment }) => {
  const [show, setShow] = useState(false);
  const [inactiveDepartment, setInactiveDepartment] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/designation/${i.id}/`);

      if (res.status === 200) {
        await getSiteDepartment();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete Site Designation" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Designation {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete designation {i.title}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const AddSectorDescription = ({ getWebsiteSectors, sectorList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [sector, setSector] = useState('');
  const [pic1, setPic1] = useState(null);
  const [pic2, setPic2] = useState(null);
  const [pic3, setPic3] = useState(null);
  const [description, setDescription] = useState('');

  const handleFileChange = (e, setPic) => {
    setPic(e.target.files[0]);
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('sector', sector);
    formData.append('pic1', pic1);
    formData.append('pic2', pic2);
    formData.append('pic3', pic3);
    formData.append('description', description);

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      const res = await axios.post(`${BASE_URL}/website/sectorsdetail/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.status === 200) {
        await getWebsiteSectors();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      // alert(err);
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };
  return (
    <>
      <button title="Add Sector Description" className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 64 64"
          fill="#2576BC"
          id="add">
          <path fill="#2576BC" d="M12.71,29.71A1.052,1.052,0,0,0,13,29a.852.852,0,0,0-.08-.38,1.155,1.155,0,0,0-.21-.33,1.047,1.047,0,0,0-1.42,0,1.155,1.155,0,0,0-.21.33A.838.838,0,0,0,11,29a1,1,0,0,0,1.71.71Z"></path>
          <path fill="#2576BC" d="M25 2H7A5.006 5.006 0 0 0 2 7V25a5.006 5.006 0 0 0 5 5H9a1 1 0 0 0 0-2H7a3 3 0 0 1-3-3V7A3 3 0 0 1 7 4H25a3 3 0 0 1 3 3V25a3 3 0 0 1-3 3H15a1 1 0 0 0 0 2H25a5.006 5.006 0 0 0 5-5V7A5.006 5.006 0 0 0 25 2zM57 2H39a5.006 5.006 0 0 0-5 5V25a5.006 5.006 0 0 0 5 5H57a5.006 5.006 0 0 0 5-5V7A5.006 5.006 0 0 0 57 2zm3 23a3 3 0 0 1-3 3H39a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3H57a3 3 0 0 1 3 3zM25 34H7a5.006 5.006 0 0 0-5 5V57a5.006 5.006 0 0 0 5 5H25a5.006 5.006 0 0 0 5-5V39A5.006 5.006 0 0 0 25 34zm3 23a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V39a3 3 0 0 1 3-3H25a3 3 0 0 1 3 3zM58 45H51V38a3 3 0 0 0-6 0v7H38a3 3 0 0 0 0 6h7v7a3 3 0 0 0 6 0V51h7a3 3 0 0 0 0-6zm0 4H50a1 1 0 0 0-1 1v8a1 1 0 0 1-2 0V50a1 1 0 0 0-1-1H38a1 1 0 0 1 0-2h8a1 1 0 0 0 1-1V38a1 1 0 0 1 2 0v8a1 1 0 0 0 1 1h8a1 1 0 0 1 0 2z"></path>
        </svg> */}
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleSubmit}>

                <div title="Sector" className="flex-column form-group-selectt">
                  <label htmlFor="Sector" className="form-labels  font-weight500    font-size-subheading">
                    Sector<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sector"
                    name="sector"
                    placeholder="Sector"
                    value={sector}
                    onChange={(e) => setSector(e.target.value)}
                    className={`form-input form-group-selection`}
                  >
                    <option value="">Select a Sector</option>
                    {sectorList
                      // .sort((a, b) => a.created_by.localeCompare(b.created_by, 'en', { ignorePunctuation: true }))
                      .map((i, index) => (
                        <option key={index} value={i.id}>{i.name ? i.name : i.created_at}</option>
                      ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                </div>


                <div className="flex-column">
                  <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                    Picture 1<span className="required">*</span>
                  </label>
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, setPic1)}
                    className="file-input"
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                    Picture 2<span className="required">*</span>
                  </label>
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, setPic2)}
                    className="file-input"
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                    Picture 3<span className="required">*</span>
                  </label>
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, setPic3)}
                    className="file-input"
                  />
                </div>

                <br />
                <div className="flex-column">
                  <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                    Description<span className="required">*</span>
                  </label>
                  <ReactQuill
                    value={description}
                    onChange={setDescription}
                    className="form-input-quill"
                  />
                </div>
                {/* <button type="submit">Submit</button> */}

                <div className="button-models">
                  <button className="model-button   font-weight500   " onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                    disabled={loading}
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};
const UpdateSectorDescription = ({ getWebsiteSectors, i }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setDescription(i.description);
  }

  const [description, setDescription] = useState('');

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      const res = await axios.put(`${BASE_URL}/website/sectorsdetail/${i.id}/`, { description: description });
      if (res.status === 200) {
        await getWebsiteSectors();
        setShow(false);
        // handleClose();
      } else {
        alert(res);
      }
    } catch (err) {
      // alert(err);
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // console.log(i)
  return (
    <>

      <button title='Update Sector Description' className='model-edit-button' onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleSubmit}>
                <div className="flex-column" id={i.id}>
                  <h5 className="font-weight500    font-size-subheading"> Sector: {i.name}</h5>
                </div>
                <br />
                <div className="flex-column">
                  <h5 className="font-weight500    font-size-subheading">Images</h5>
                  <div className="flex-row justify-between" id={i.id}>
                    <img src={`${i.pic1}`} className="width-10vw image-update-modal" alt="" />
                    <img src={`${i.pic2}`} className="width-10vw image-update-modal" alt="" />
                    <img src={`${i.pic3}`} className="width-10vw image-update-modal" alt="" />
                  </div>
                </div>
                <br />

                <div className="flex-column">
                  <label htmlFor="description" className="form-labels  font-weight500    font-size-subheading">
                    Description<span className="required">*</span>
                  </label>
                  <ReactQuill
                    type="text"
                    id="description"
                    name="description"
                    value={description}
                    onChange={setDescription}
                    className="form-input-quill"
                  />
                </div>
                {/* <button type="submit">Submit</button> */}

                <div className="button-models">
                  <button className="model-button   font-weight500   " onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                    disabled={loading}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};
const DeleteSectorDescription = ({ getWebsiteSectors, i }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }


  const [loading, setLoading] = useState(false); //loading logic
  const handleDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      const res = await axios.delete(`${BASE_URL}/website/sectorsdetail/${i.id}/`)
      if (res.status === 200) {
        await getWebsiteSectors();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      // alert(err);
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // console.log(i)
  return (
    <>


      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Sector description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Sector Description of "{i.name}"

            <div className="button-models">
              <button className="model-button model-button-cancel   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

// ? *************** Sector Crud Models End ***************


// ! *************** Sector Crud Models Start ***************

const AddProject = ({ getWebsiteProjectData, projectList }) => {
  const [show, setShow] = useState(false);
  const { PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredList, setFilteredList] = useState([]);

  const getCurrentDateTime = () => {
    const date = new Date();
    const pad = (n) => (n < 10 ? '0' + n : n);

    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1); // Months are zero-based
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());
    const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
    const isoString = `${year}-${month} -${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return isoString;
  };
  const [formData, setFormData] = useState({
    project_name: "",
    association: "",
    jv: "",
    lead: "",
    project_length: "",
    cost: "",
    project_descritpion: "",
    project_type: "",
    created_by: sessionStorage.getItem('emp_code'),
    created_at: getCurrentDateTime(),
    // sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["project_name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    // if (name === 'namae') {
    //   const filtered = sectorList.filter(item =>
    //     item.name.toLowerCase().includes(value.toLowerCase())
    //   );
    //   setFilteredList(filtered);
    // }

  };


  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(`${BASE_URL}/website/project/`, formData);

        if (res.status === 200) {
          await getWebsiteProjectData();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        // alert(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {

          toast.error('Error processing your requests.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Add Sector" className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="project_name" className="form-labels  font-weight500    font-size-subheading">
                  Project Name<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="project_name"
                  name="project_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  className={`form-input ${errors.project_name ? "error" : inputState.project_name ? "success" : ""
                    }`}
                />
                {errors.project_name && (
                  <span className="error-message font-size-text ">{errors.project_name}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_name}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="association" className="form-labels  font-weight500    font-size-subheading">
                  Association<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="association"
                  name="association"
                  placeholder="Association"
                  onChange={handleInputChange}
                  value={formData.association}
                  className={`form-input ${errors.association ? "error" : inputState.association ? "success" : ""
                    }`}
                />
                {errors.association && (
                  <span className="error-message font-size-text ">{errors.association}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.association}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="jv" className="form-labels  font-weight500    font-size-subheading">
                  Jv<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="jv"
                  name="jv"
                  placeholder="Jv"
                  onChange={handleInputChange}
                  value={formData.jv}
                  className={`form-input ${errors.jv ? "error" : inputState.jv ? "success" : ""
                    }`}
                />
                {errors.jv && (
                  <span className="error-message font-size-text ">{errors.jv}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.jv}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="lead" className="form-labels  font-weight500    font-size-subheading">
                  Lead<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="lead"
                  name="lead"
                  placeholder="Lead"
                  onChange={handleInputChange}
                  value={formData.lead}
                  className={`form-input ${errors.lead ? "error" : inputState.lead ? "success" : ""
                    }`}
                />
                {errors.lead && (
                  <span className="error-message font-size-text ">{errors.lead}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.lead}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="project_length" className="form-labels  font-weight500    font-size-subheading">
                  Project Length<span className="required">*</span>
                </label>
                <input
                  maxLength={10}
                  type="number"
                  id="project_length"
                  name="project_length"
                  placeholder="Project Length"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input ${errors.project_length ? "error" : inputState.project_length ? "success" : ""
                    }`}
                />
                {errors.project_length && (
                  <span className="error-message font-size-text ">{errors.project_length}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_length}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="cost" className="form-labels  font-weight500    font-size-subheading">
                  Cost<span className="required">*</span>
                </label>
                <input
                  maxLength={10}
                  type="number"
                  id="cost"
                  name="cost"
                  placeholder="Cost"
                  onChange={handleInputChange}
                  value={formData.cost}
                  className={`form-input ${errors.cost ? "error" : inputState.cost ? "success" : ""
                    }`}
                />
                {errors.cost && (
                  <span className="error-message font-size-text ">{errors.cost}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.cost}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="project_descritpion" className="form-labels  font-weight500    font-size-subheading">
                  Project Description<span className="required">*</span>
                </label>
                <textarea
                  maxLength={2000}
                  type="text"
                  id="project_descritpion"
                  name="project_descritpion"
                  placeholder="Project Description"
                  onChange={handleInputChange}
                  value={formData.project_descritpion}
                  className={`form-input-textarea   font-weight400  font-size-subheading  ${errors.project_descritpion ? "error" : inputState.project_descritpion ? "success" : ""
                    }`}
                />
                {errors.project_descritpion && (
                  <span className="error-message font-size-text ">{errors.project_descritpion}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_descritpion}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="project_type" className="form-labels  font-weight500    font-size-subheading">
                  Project Type<span className="required">*</span>
                </label>

                <select
                  id="project_type"
                  maxLength={100}
                  name="project_type"
                  type="text"
                  value={formData.project_type}
                  onChange={handleInputChange}
                  // className="attendance-input-field width-5vw   date-field"
                  className={`form-input ${errors.project_type ? "error" : inputState.project_type ? "success" : ""
                    }`}
                >
                  <option value="">Project Type</option>
                  <option value="Supervision">Supervision</option>
                  <option value="Detail Design">Detail Design</option>
                  <option value="Safety">Safety</option>
                  <option value="Proof">Proof</option>
                  <option value="DPR">DPR</option>
                  <option value="Pretender">Pretender</option>
                  <option value="Others">Others</option>
                </select>
                {errors.project_type && (
                  <span className="error-message font-size-text ">{errors.project_type}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_type}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};


const UpdateProject = ({ i, getWebsiteProjectData, projectList }) => {
  const [show, setShow] = useState(false);
  const { PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };
  const [filteredList, setFilteredList] = useState([]);

  const getCurrentDateTime = () => {
    const date = new Date();
    const pad = (n) => (n < 10 ? '0' + n : n);

    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1); // Months are zero-based
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());
    const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
    const isoString = `${year}-${month} -${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return isoString;
  };
  const [formData, setFormData] = useState({
    project_name: "",
    association: "",
    jv: "",
    lead: "",
    project_length: "",
    cost: "",
    project_descritpion: "",
    project_type: "",
    created_by: sessionStorage.getItem('emp_code'),
    created_at: getCurrentDateTime(),
    // sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["project_name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    // if (name === 'namae') {
    //   const filtered = sectorList.filter(item =>
    //     item.name.toLowerCase().includes(value.toLowerCase())
    //   );
    //   setFilteredList(filtered);
    // }

  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(`${BASE_URL}/website/project/${i.id}/`, formData);

        if (res.status === 200) {
          await getWebsiteProjectData();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {

          toast.error('Error processing your requests.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title='Update Project' className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}

              <div className="flex-column">
                <label htmlFor="project_name" className="form-labels  font-weight500    font-size-subheading">
                  Project Name<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="project_name"
                  name="project_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  className={`form-input ${errors.project_name ? "error" : inputState.project_name ? "success" : ""
                    }`}
                />
                {errors.project_name && (
                  <span className="error-message font-size-text ">{errors.project_name}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_name}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="association" className="form-labels  font-weight500    font-size-subheading">
                  Association<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="association"
                  name="association"
                  placeholder="Association"
                  onChange={handleInputChange}
                  value={formData.association}
                  className={`form-input ${errors.association ? "error" : inputState.association ? "success" : ""
                    }`}
                />
                {errors.association && (
                  <span className="error-message font-size-text ">{errors.association}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.association}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="jv" className="form-labels  font-weight500    font-size-subheading">
                  Jv<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="jv"
                  name="jv"
                  placeholder="Jv"
                  onChange={handleInputChange}
                  value={formData.jv}
                  className={`form-input ${errors.jv ? "error" : inputState.jv ? "success" : ""
                    }`}
                />
                {errors.jv && (
                  <span className="error-message font-size-text ">{errors.jv}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.jv}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="lead" className="form-labels  font-weight500    font-size-subheading">
                  Lead<span className="required">*</span>
                </label>
                <input
                  maxLength={100}
                  type="text"
                  id="lead"
                  name="lead"
                  placeholder="Lead"
                  onChange={handleInputChange}
                  value={formData.lead}
                  className={`form-input ${errors.lead ? "error" : inputState.lead ? "success" : ""
                    }`}
                />
                {errors.lead && (
                  <span className="error-message font-size-text ">{errors.lead}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.lead}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="project_length" className="form-labels  font-weight500    font-size-subheading">
                  Project Length<span className="required">*</span>
                </label>
                <input
                  maxLength={10}
                  type="number"
                  id="project_length"
                  name="project_length"
                  placeholder="Project Length"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input ${errors.project_length ? "error" : inputState.project_length ? "success" : ""
                    }`}
                />
                {errors.project_length && (
                  <span className="error-message font-size-text ">{errors.project_length}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_length}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="cost" className="form-labels  font-weight500    font-size-subheading">
                  Cost<span className="required">*</span>
                </label>
                <input
                  maxLength={10}
                  type="number"
                  id="cost"
                  name="cost"
                  placeholder="Cost"
                  onChange={handleInputChange}
                  value={formData.cost}
                  className={`form-input ${errors.cost ? "error" : inputState.cost ? "success" : ""
                    }`}
                />
                {errors.cost && (
                  <span className="error-message font-size-text ">{errors.cost}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.cost}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="project_descritpion" className="form-labels  font-weight500    font-size-subheading">
                  Project Description<span className="required">*</span>
                </label>
                <textarea
                  maxLength={2000}
                  type="text"
                  id="project_descritpion"
                  name="project_descritpion"
                  placeholder="Project Description"
                  onChange={handleInputChange}
                  value={formData.project_descritpion}
                  className={`form-input-textarea   font-weight400  font-size-subheading  ${errors.project_descritpion ? "error" : inputState.project_descritpion ? "success" : ""
                    }`}
                />
                {errors.project_descritpion && (
                  <span className="error-message font-size-text ">{errors.project_descritpion}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_descritpion}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="project_type" className="form-labels  font-weight500    font-size-subheading">
                  Project Type<span className="required">*</span>
                </label>

                <select
                  id="project_type"
                  maxLength={100}
                  name="project_type"
                  type="text"
                  value={formData.project_type}
                  onChange={handleInputChange}
                  // className="attendance-input-field width-5vw   date-field"
                  className={`form-input ${errors.project_type ? "error" : inputState.project_type ? "success" : ""
                    }`}
                >
                  <option value="">Project Type</option>
                  <option value="Supervision">Supervision</option>
                  <option value="Detail Design">Detail Design</option>
                  <option value="Safety">Safety</option>
                  <option value="Proof">Proof</option>
                  <option value="DPR">DPR</option>
                  <option value="Pretender">Pretender</option>
                  <option value="Others">Others</option>
                </select>
                {errors.project_type && (
                  <span className="error-message font-size-text ">{errors.project_type}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_type}
                      </div>
                    ))}
                  </div>
                )}

              </div>


              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};


const DeleteProject = ({ i, getWebsiteProjectData }) => {
  const [show, setShow] = useState(false);
  const [inactiveDepartment, setInactiveDepartment] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/website/project/${i.id}`);

      if (res.status === 200) {
        await getWebsiteProjectData();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete ProductData" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Project : {i.project_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete the project "{i.project_name}"
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const AddProjectImage = ({ i, getWebsiteProjectData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    getProjectDetails();
    setShow(true);
  };

  const [projectData, setProjectData] = useState();


  const [buffer, setBuffering] = useState(true); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic

  const getProjectDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const proj = await axios.get(`${BASE_URL}/website/projectdetail/${i.id}/`);
      setProjectData(proj.data);

    }
    catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  // ! Pic Start ===============================

  const [showAddPic, setShowAddPic] = useState(false);
  const handleClosePic = () => setShowAddPic(false);
  const handleShowPic = () => setShowAddPic(true);

  const [imageData, setimageData] = useState({
    project: i.id,
    pic: "",
  });


  const [picerrors, setpicErrors] = useState({});
  const [picinputState, setpicInputState] = useState({});

  const validatePicForm = () => {
    const newpicErrors = {};
    const picRequiredFields = [
      "project",
      "pic",
    ];

    picRequiredFields.forEach((field) => {
      if (!imageData[field]) {
        newpicErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setpicErrors(newpicErrors);
    toast.warning(newpicErrors);

    return Object.keys(newpicErrors).length === 0;

  };

  const handlepicChange = (e) => {
    const { name, value } = e.target;

    setpicInputState({
      ...picinputState,
      [name]: value.trim() ? "green" : "",
    });
    setimageData({
      ...imageData,
      [name]: value,
      // 'pic': e.target.files[0]
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    setimageData({
      ...imageData,
      [name]: file,
    });
  };

  const handlePicPost = async (e, getProjectDetails) => {
    e.preventDefault();

    if (validatePicForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("project", imageData.project);

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = imageData.pic;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });
      formDataa.append("pic", customFile);

      try {

        let res = await axios.post(`${BASE_URL}/website/projectimage/`, formDataa)

        if (res.status === 200) {
          await getProjectDetails()
          handleClosePic();
          // setShow(false)
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handlePicDelete = async (e, i, getProjectDetails) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/website/projectimage/${i.id}/`);

      if (res.status === 200) {
        await getProjectDetails();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error("Error: Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  // ? Pic End ===============================









  return (
    <>
      <button title="Add Project Image" className="model-add-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 64 64"
          fill="#2576BC"
          id="add">
          <path fill="#2576BC" d="M12.71,29.71A1.052,1.052,0,0,0,13,29a.852.852,0,0,0-.08-.38,1.155,1.155,0,0,0-.21-.33,1.047,1.047,0,0,0-1.42,0,1.155,1.155,0,0,0-.21.33A.838.838,0,0,0,11,29a1,1,0,0,0,1.71.71Z"></path>
          <path fill="#2576BC" d="M25 2H7A5.006 5.006 0 0 0 2 7V25a5.006 5.006 0 0 0 5 5H9a1 1 0 0 0 0-2H7a3 3 0 0 1-3-3V7A3 3 0 0 1 7 4H25a3 3 0 0 1 3 3V25a3 3 0 0 1-3 3H15a1 1 0 0 0 0 2H25a5.006 5.006 0 0 0 5-5V7A5.006 5.006 0 0 0 25 2zM57 2H39a5.006 5.006 0 0 0-5 5V25a5.006 5.006 0 0 0 5 5H57a5.006 5.006 0 0 0 5-5V7A5.006 5.006 0 0 0 57 2zm3 23a3 3 0 0 1-3 3H39a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3H57a3 3 0 0 1 3 3zM25 34H7a5.006 5.006 0 0 0-5 5V57a5.006 5.006 0 0 0 5 5H25a5.006 5.006 0 0 0 5-5V39A5.006 5.006 0 0 0 25 34zm3 23a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V39a3 3 0 0 1 3-3H25a3 3 0 0 1 3 3zM58 45H51V38a3 3 0 0 0-6 0v7H38a3 3 0 0 0 0 6h7v7a3 3 0 0 0 6 0V51h7a3 3 0 0 0 0-6zm0 4H50a1 1 0 0 0-1 1v8a1 1 0 0 1-2 0V50a1 1 0 0 0-1-1H38a1 1 0 0 1 0-2h8a1 1 0 0 0 1-1V38a1 1 0 0 1 2 0v8a1 1 0 0 0 1 1h8a1 1 0 0 1 0 2z"></path>
        </svg> */}
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="custom-modal width-10vw">
        <Modal.Header closeButton>
          <Modal.Title className="align-center">Project Images </Modal.Title>
          {/* <Modal.Title>Project Images</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <div className="parent-div">
            <div className="bdy-div">

              <>

                <div className="form-flex-wrap">
                  <table className="table-css">
                    <thead>
                      <tr className="custom-table-head-tr">
                        <th className="align-left">S. No.</th>
                        <th className="align-center">Image</th>
                        <th className="align-right width-5vw">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectData?.project_images_project.map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>

                            <td className="align-center">

                              {i.pic
                                ? <img
                                  className="img-modal-table"
                                  src={`${i.pic}`}
                                  alt={`Project Image-${index + 1}`}
                                /> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                  <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                  <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                </svg>
                              }

                            </td>

                            <td className="align-right width-5vw ">
                              <button className="model-delete-button"
                                onClick={(e) => handlePicDelete(e, i, getProjectDetails)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                    fill="#F72B50"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                <hr className="line" />

                {showAddPic === true ? (
                  <div className="expense-req">

                    <div className="flex-column">
                      <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                        Add Picture
                      </label>
                      <input
                        id="pic"
                        name="pic"
                        type="file"
                        // onChange={(e) => handleFileChange(e, setPic)}
                        onChange={handleFileChange}
                        className="file-input"
                      />
                    </div>

                    <div className="flex-column">
                      <label
                        htmlFor=""
                        className="announce-date font-weight400  form-inputss"
                      ></label>
                      <div className="flex-row action-div">
                        <button className="" onClick={handleClosePic}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15 9L9 15"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 9L15 15"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button
                          className="form-inputs-submit width-5vw"
                          onClick={(e) => handlePicPost(e, getProjectDetails)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      title="Add EOT Details"
                      onClick={handleShowPic}
                      className="jd-heading-main font-weight600 -right-button"
                      style={{ float: "right" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_450_2151)">
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_450_2151">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <br />
                  </div>
                )}
              </>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

// ? *************** Sector Crud Models End ***************

const ReplyEnquirys = ({ i, getEnquiryData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };

  const [formData, setFormData] = useState({
    reply_by: `${sessionStorage.getItem(
      "emp_code"
    )} - ${sessionStorage.getItem("name")} `,
    reply_date: formattedDate,
    reply_remark: "",
    replied: false,
  });


  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["reply_remark"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    const updatedformvalue = {
      ...formData,
      reply_by: `${sessionStorage.getItem(
        "emp_code"
      )} - ${sessionStorage.getItem("name")} `,
      reply_date: formattedDate,
      replied: true,
    };

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/website/contactform/${i.id}/`,
          updatedformvalue
        );

        if (res.status === 200) {
          await getEnquiryData();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button
        className="model-edit-button"
        title="Update Remarks"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          enable-background="new 0 0 70 70"
          viewBox="0 0 70 70"
          id="edit"
        >
          <path
            d="M45.338623,11.0065918H14.4987793c-1.9287109,0-3.4980469,1.5639648-3.4980469,3.4868164v41.0019531
	c0,1.9287109,1.5693359,3.4980469,3.4980469,3.4980469H45.338623c1.9287109,0,3.4980469-1.5693359,3.4980469-3.4980469V42.144043
	l9.1123047-9.1125488c1.4003906-1.3999023,1.4003906-3.6777344,0-5.0776367l-2.4541016-2.4545898
	c-1.4003906-1.4003906-3.6777344-1.4003906-5.078125,0l-1.5800781,1.5801392V14.4934082
	C48.8366699,12.5705566,47.267334,11.0065918,45.338623,11.0065918z M40.456543,47.6958008l-4.7038574-4.7038574
	L48.43927,30.3049927l4.7040405,4.7040405L40.456543,47.6958008z M34.9246826,44.9920654l3.5315552,3.5315552l-4.5199585,0.9883423
	L34.9246826,44.9920654z M46.8366699,55.4953613c0,0.8261719-0.671875,1.4980469-1.4980469,1.4980469H14.4987793
	c-0.8261719,0-1.4980469-0.671875-1.4980469-1.4980469V14.4934082c0-0.8198242,0.671875-1.4868164,1.4980469-1.4868164H45.338623
	c0.8261719,0,1.4980469,0.6669922,1.4980469,1.4868164v14.5860596l-8.0836182,8.0838623H18.7185059c-0.5527344,0-1,0.4477539-1,1
	s0.4472656,1,1,1h18.0346069l-3.121521,3.121582c-0.0849609,0.0846558-0.1463623,0.1864624-0.1954956,0.2943726
	c-0.0147095,0.0321045-0.0249634,0.0636597-0.0360107,0.0968628c-0.0115967,0.034668-0.0303345,0.0664673-0.0380249,0.102417
	l-0.156189,0.7143555H18.7185059c-0.5527344,0-1,0.4477539-1,1s0.4472656,1,1,1h14.0501099l-0.9464722,4.3291016H18.7185059
	c-0.5527344,0-1,0.4477539-1,1s0.4472656,1,1,1H32.626709c0.0712891,0,0.1425781-0.0073242,0.2138672-0.0229492l7.8300781-1.7119141
	c0.036377-0.0079956,0.0687256-0.0269775,0.1038208-0.0388794c0.0325928-0.0109863,0.0634766-0.0211792,0.0949707-0.0355835
	c0.1079712-0.0493774,0.2099609-0.1108398,0.2943726-0.1955566l5.6728516-5.6730347V55.4953613z M51.8308105,26.9133301
	c0.6210938-0.6201172,1.6289063-0.6201172,2.25,0l2.4541016,2.4545898c0.6201172,0.6201172,0.6201172,1.6293945,0,2.2495117
	l-1.9775391,1.9775391l-4.7041016-4.7041016L51.8308105,26.9133301z"
          ></path>
          <path d="M18.7185059 20.1750488h22.4042969c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H18.7185059c-.5527344 0-1 .4477539-1 1S18.1657715 20.1750488 18.7185059 20.1750488zM18.7185059 26.5046387h22.4042969c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H18.7185059c-.5527344 0-1 .4477539-1 1S18.1657715 26.5046387 18.7185059 26.5046387zM18.7185059 32.8337402h22.4042969c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H18.7185059c-.5527344 0-1 .4477539-1 1S18.1657715 32.8337402 18.7185059 32.8337402z"></path>
        </svg>

      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Reply to {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <div className="parent-div">

            <div className="flex-column">
              <label htmlFor="remarks" className="form-labels font-weight500    font-size-subheading">
                Date<span className="required"></span>
              </label>
              {formattedDateLong(formattedDate)}
            </div>

            <div className="flex-column">
              <label htmlFor="reply_remark" className="form-labels font-weight500    font-size-subheading">
                Reply<span className="required"></span>
              </label>
              <textarea
                type="text"
                id="reply_remark"
                name="reply_remark"
                placeholder="Reply"
                onChange={handleInputChange}
                maxLength={100}
                readOnly={formData.replied}
                value={formData.reply_remark}
                className={`form-input-textarea   font-weight400  font-size-subheading ${errors.reply_remark ? "error" : inputState.reply_remark ? "success" : ""
                  }`}
              />
              {errors.reply_remark && (
                <span className="error-message font-size-text ">{errors.reply_remark}</span>
              )}
            </div>


            <div className="button-models">
              <button className="model-button font-weight500" onClick={handleClose}>
                Close
              </button>
              <button
                className="model-button  font-weight500    model-button-submit"
                onClick={handleFormSubmitPut}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};


const UpdateEnquiryContact = ({ i, getEnquiryData }) => {
  const [show, setShow] = useState(false);
  const { PERMISSION_AUTHORITY } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };
  const [filteredList, setFilteredList] = useState([]);

  const getCurrentDateTime = () => {
    const date = new Date();
    const pad = (n) => (n < 10 ? '0' + n : n);

    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1); // Months are zero-based
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());
    const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
    const isoString = `${year}-${month} -${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return isoString;
  };
  const [formData, setFormData] = useState({
    project_name: "",
    association: "",
    jv: "",
    lead: "",
    project_length: "",
    cost: "",
    project_descritpion: "",
    project_type: "",
    created_by: sessionStorage.getItem('emp_code'),
    created_at: getCurrentDateTime(),
    // sub_company: sessionStorage.getItem('company_id')
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["project_name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    // if (name === 'namae') {
    //   const filtered = sectorList.filter(item =>
    //     item.name.toLowerCase().includes(value.toLowerCase())
    //   );
    //   setFilteredList(filtered);
    // }

  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(`${BASE_URL}/website/project/${i.id}/`, formData);

        if (res.status === 200) {
          await getEnquiryData();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {

          toast.error('Error processing your requests.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title='Update Project' className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="project_name"
                  name="project_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  className={`form-input ${errors.project_name ? "error" : inputState.project_name ? "success" : ""
                    }`}
                />
                {errors.project_name && (
                  <span className="error-message font-size-text ">{errors.project_name}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_name}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="association" className="form-labels  font-weight500    font-size-subheading">
                  Association<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="association"
                  name="association"
                  placeholder="Association"
                  onChange={handleInputChange}
                  value={formData.association}
                  className={`form-input ${errors.association ? "error" : inputState.association ? "success" : ""
                    }`}
                />
                {errors.association && (
                  <span className="error-message font-size-text ">{errors.association}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.association}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="jv" className="form-labels  font-weight500    font-size-subheading">
                  Jv<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="jv"
                  name="jv"
                  placeholder="Jv"
                  onChange={handleInputChange}
                  value={formData.jv}
                  className={`form-input ${errors.jv ? "error" : inputState.jv ? "success" : ""
                    }`}
                />
                {errors.jv && (
                  <span className="error-message font-size-text ">{errors.jv}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.jv}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="lead" className="form-labels  font-weight500    font-size-subheading">
                  Lead<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="lead"
                  name="lead"
                  placeholder="Lead"
                  onChange={handleInputChange}
                  value={formData.lead}
                  className={`form-input ${errors.lead ? "error" : inputState.lead ? "success" : ""
                    }`}
                />
                {errors.lead && (
                  <span className="error-message font-size-text ">{errors.lead}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.lead}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="project_length" className="form-labels  font-weight500    font-size-subheading">
                  Project Length<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="number"
                  id="project_length"
                  name="project_length"
                  placeholder="Project Length"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input ${errors.project_length ? "error" : inputState.project_length ? "success" : ""
                    }`}
                />
                {errors.project_length && (
                  <span className="error-message font-size-text ">{errors.project_length}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_length}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="cost" className="form-labels  font-weight500    font-size-subheading">
                  Cost<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="number"
                  id="cost"
                  name="cost"
                  placeholder="Cost"
                  onChange={handleInputChange}
                  value={formData.cost}
                  className={`form-input ${errors.cost ? "error" : inputState.cost ? "success" : ""
                    }`}
                />
                {errors.cost && (
                  <span className="error-message font-size-text ">{errors.cost}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.cost}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="flex-column">
                <label htmlFor="project_descritpion" className="form-labels  font-weight500    font-size-subheading">
                  Project Description<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="project_descritpion"
                  name="project_descritpion"
                  placeholder="Project Description"
                  onChange={handleInputChange}
                  value={formData.project_descritpion}
                  className={`form-input ${errors.project_descritpion ? "error" : inputState.project_descritpion ? "success" : ""
                    }`}
                />
                {errors.project_descritpion && (
                  <span className="error-message font-size-text ">{errors.project_descritpion}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_descritpion}
                      </div>
                    ))}
                  </div>
                )}

              </div>
              <div className="flex-column">
                <label htmlFor="project_type" className="form-labels  font-weight500    font-size-subheading">
                  Project Type<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="project_type"
                  name="project_type"
                  placeholder="Project Type"
                  onChange={handleInputChange}
                  value={formData.project_type}
                  className={`form-input ${errors.project_type ? "error" : inputState.project_type ? "success" : ""
                    }`}
                />
                {errors.project_type && (
                  <span className="error-message font-size-text ">{errors.project_type}</span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.project_type}
                      </div>
                    ))}
                  </div>
                )}

              </div>

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};




const DeleteEnquiryContact = ({ i, getEnquiryData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/website/contactform/${i.id}`);

      if (res.status === 200) {
        await getEnquiryData();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete ProductData" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Enquiry by {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Enquiry Data of "{i.email}" submitted on : "{i.date_time}"
            <div className="button-models">
              <button className="model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button font-weight500 model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


export {
  AddSector,
  AddSectorDescription,
  UpdateSectorDescription,
  UpdateSector,
  DeleteSector,
  AddProject,
  AddProjectImage,
  UpdateProject,
  DeleteProject,
  DeleteSectorDescription,
  ReplyEnquirys,
  DeleteEnquiryContact
};
