
import React, { useEffect, useState, useRef } from 'react';
import usePermission from '../../config/permissions';
import axios from 'axios';
import { BASE_URL } from '../../config/axios';
import { ToastContainer, toast } from "react-toastify";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Modal from "react-bootstrap/Modal";
import { formattedDateLong, formattedDateNoYear } from '../Date';
import { AddCircular, AddProjectDetails, AddSiteDepartment, AddSiteEmployee, AddSiteSubcompanys, AddTeamMember, AddTrackRecordDetails, DeleteSiteDepartment, DeleteSiteEmployee, DeleteSiteSubcompany, UpdateProjectDetails, UpdateSiteDepartment, UpdateSiteEmployee, UpdateSiteSubcompanyupdate } from './SiteButtons';
import { useNavigate } from "react-router-dom";
import { customSortByKey } from '../CustomFunctions';



const SiteDesignationTable = () => {
    const [siteDepartmentList, setSiteDepartmentList] = useState([]);
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { PERMISSION_AUTHORITY } = usePermission();
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

    const getSiteDepartment = async () => {
        setBuffering(true); // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSiteSubCompanyList(sub.data);


            // const res = await axios.get(`${BASE_URL}/siteproject/designation/`);
            const res = await axios.get(`${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`);
            setSiteDepartmentList(res.data);


        } catch (err) {

            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getSiteDepartment();
    }, [toggleCompany]);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Site Designation</div>
                <div className="field-cont">

                    {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
                        <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
                            <option value="null">Select Sub Company</option>
                            {siteSubCompanyList.map(company => (<option value={company.id}>{company.title}</option>))};

                        </select>
                    </div> : null
                    }
                    <div className="table-searchh field-cont-div">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                            >
                                <circle
                                    cx="5"
                                    cy="5"
                                    r="4.3"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                />
                                <line
                                    x1="10.0101"
                                    y1="11"
                                    x2="8"
                                    y2="8.98995"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        <div>
                            <input
                                placeholder="Search"
                                className="navbar-inputt font-weight400  font-size-text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <AddSiteDepartment
                        getSiteDepartment={getSiteDepartment}
                        siteDepartmentList={siteDepartmentList}
                        siteSubCompanyList={siteSubCompanyList}
                    />
                </div>
            </div>

            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="align-left">ID</th>
                        <th className="align-center">Designation</th>
                        <th className="align-center">Sub Company</th>
                        <th className="align-center">Employee Count</th>
                        <th className="align-center">Employee View</th>
                        <th className="align-right width-5vw">Action</th>
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> :
                    <tbody>
                        {siteDepartmentList
                            ?.filter((e) =>
                                e.title.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) =>
                                a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                            )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">{i.title}</td>
                                        <td className="align-center">{i.sub_company_name}</td>

                                        <td className="align-center">{i?.employee_count}</td>
                                        {/* <td className="align-center">View </td> */}
                                        <td className="align-center">
                                            <EmployeebySiteDesignationTable designation={i} />{" "}
                                        </td>
                                        <td className="align-right flex-row ">
                                            <UpdateSiteDepartment
                                                i={i}
                                                getSiteDepartment={getSiteDepartment}
                                                siteDepartmentList={siteDepartmentList}
                                            />

                                            <DeleteSiteDepartment i={i} getSiteDepartment={getSiteDepartment} />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

const EmployeebySiteDesignationTable = ({ designation }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getSiteDepartmentEmployees(designation.id);
    };

    const [departmentEmployees, setDepartmentEmployees] = useState([]);

    const getSiteDepartmentEmployees = async (designation) => {
        setBuffering(true); //buffering logic // Start Buffering 
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeedetailsbydesignation/${designation}/active/`
            );
            const sortedList = customSortByKey(res.data, "emp_code");
            setDepartmentEmployees(sortedList);
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const navigate = useNavigate();
    const Viewdetails = (emp_code, user_detail) => {
        navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`);
    };
    return (
        <>
            <button
                className=""
                title="View Employees"
                style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
                onClick={handleShow}
            >
                View
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <>
                        {/* <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"></div>
              </div> */}
                        <div className="table-css-white-background">
                            <div className="table-heading-flex">
                                <div className="repo-heading font-weight500    font-size-heading">
                                    Employees with : <br />
                                    {designation.title}
                                </div>
                                <div className="flex-row">
                                    <div className="table-searchh">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11"
                                                height="12"
                                                viewBox="0 0 11 12"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.3"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                />
                                                <line
                                                    x1="10.0101"
                                                    y1="11"
                                                    x2="8"
                                                    y2="8.98995"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <div>
                                            <input
                                                placeholder="Search"
                                                className="navbar-inputt font-weight400  font-size-text"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table-css">
                                <thead>
                                    <tr className="custom-table-head-tr">
                                        <th className="align-left">ID</th>
                                        <th className="align-center">Emp Code</th>
                                        <th className="align-center"> Name </th>
                                    </tr>
                                </thead>
                                {buffer ? <div className="spinner"></div> : //buffering logic
                                    <tbody>
                                        {departmentEmployees
                                            ?.filter(
                                                (e) =>
                                                    e.name
                                                        .toLowerCase()
                                                        .includes(searchQuery.toLowerCase()) ||
                                                    e.emp_code
                                                        .toString()
                                                        .includes(searchQuery.toLowerCase())
                                            )
                                            .sort((a, b) => a.emp_code - b.emp_code)
                                            .map((i, index) => (
                                                <React.Fragment key={index}>
                                                    {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                                                    <tr className="custom-table-head-td">
                                                        <td className="align-left">{index + 1}</td>
                                                        <td className="align-center">{i.emp_code}</td>
                                                        <td className="align-center">{i.name}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                }
                            </table>
                        </div>
                    </>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const SiteSubcompanyTable = () => {
    const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [subCompanyList, setSubCompanyList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const getSiteSubcompanies = async () => {
        setBuffering(true); //buffering logic // Start Buffering 
        try {
            const res = await axios.get(
                // `${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/${toggleCompany}/active/`
                `${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/null/active/`
                // `${BASE_URL}/siteproject/subcompany`
            );
            setSubCompanyList(res.data);
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getSiteSubcompanies();
    }, []);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Sub Company</div>
                <div className="flex-row">
                    <div className="table-searchh">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                            >
                                <circle
                                    cx="5"
                                    cy="5"
                                    r="4.3"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                />
                                <line
                                    x1="10.0101"
                                    y1="11"
                                    x2="8"
                                    y2="8.98995"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        <div>
                            <input
                                placeholder="Search"
                                className="navbar-inputt font-weight400  font-size-text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    {PERMISSION_AUTHORITY ? (
                        <AddSiteSubcompanys getSiteSubcompanies={getSiteSubcompanies} />
                    ) : null}
                </div>
            </div>
            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="align-left">ID</th>
                        <th className="align-center">Company Name</th>
                        <th className="align-center">Employee Count</th>
                        <th className="align-center">Employees</th>
                        <th className="align-right width-5vw">Action</th>
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                    <tbody>
                        {subCompanyList
                            // ?.filter((e) => e.name.toLowerCase().includes(searchQuery.toLowerCase()))
                            .sort((a, b) => a.title - b.title)
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">{i.title}</td>
                                        <td className="align-center">{i.employee_count}</td>
                                        <td className="align-center">
                                            <EmployeebySiteSubCompanyTable subcompany={i} />{" "}
                                        </td>
                                        <td className="align-right flex-row ">
                                            <UpdateSiteSubcompanyupdate i={i} getSiteSubcompanies={getSiteSubcompanies} />

                                            {PERMISSION_AUTHORITY ? <DeleteSiteSubcompany i={i} getSiteSubcompanies={getSiteSubcompanies} /> : null}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

const EmployeebySiteSubCompanyTable = ({ subcompany }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getSubCompanyEmployees(subcompany.id);
    };

    const [subcompanyEmployees, setSubCompanyEmployees] = useState([]);

    const getSubCompanyEmployees = async (subcompanyId) => {
        setBuffering(true); //buffering logic // Start Buffering 
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeedetailsbysubcompany/${subcompanyId}/active/`
            );
            const sortedList = customSortByKey(res.data, "emp_code");
            setSubCompanyEmployees(sortedList);
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // useEffect(() => {
    //   getSubCompanyEmployees();
    // }, []);

    const navigate = useNavigate();
    const Viewdetails = (emp_code, user_detail) => {
        navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`);
    };
    return (
        <>
            <button
                className=""
                title="View Employees"
                style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
                onClick={handleShow}
            >
                View
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <>
                        {/* <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"></div>
              </div> */}
                        <div className="table-css-white-background">
                            <div className="table-heading-flex">
                                <div className="repo-heading font-weight500    font-size-heading">
                                    Employees in : <br />
                                    {subcompany.title}
                                </div>
                                <div className="flex-row">
                                    <div className="table-searchh">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11"
                                                height="12"
                                                viewBox="0 0 11 12"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.3"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                />
                                                <line
                                                    x1="10.0101"
                                                    y1="11"
                                                    x2="8"
                                                    y2="8.98995"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <div>
                                            <input
                                                placeholder="Search"
                                                className="navbar-inputt font-weight400  font-size-text"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table-css">
                                <thead>
                                    <tr className="custom-table-head-tr">
                                        <th className="align-left">ID</th>
                                        <th className="align-center">Emp Code</th>
                                        <th className="align-center"> Name </th>
                                        <th className="align-right"> Designation </th>
                                    </tr>
                                </thead>
                                {buffer ? <div className="spinner"></div> : //buffering logic
                                    <tbody>
                                        {subcompanyEmployees
                                            ?.filter(
                                                (e) => e.emp_code.includes(searchQuery.toLowerCase()) || e.name.toLowerCase().includes(searchQuery.toLowerCase())

                                            )
                                            .sort((a, b) => a.emp_code - b.emp_code)
                                            .map((i, index) => (
                                                <React.Fragment key={index}>
                                                    {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                                                    <tr className="custom-table-head-td">
                                                        <td className="align-left">{index + 1}</td>
                                                        <td className="align-center">{i.emp_code}</td>
                                                        <td className="align-center">{i.name}</td>
                                                        <td className="align-center">{i.designation_name}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                }
                            </table>
                        </div>
                    </>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const SiteEmployeeTable = () => {
    const [siteEmployeeList, setSiteEmployeeList] = useState([]);
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [siteDepartmentList, setSiteDepartmentList] = useState([]);
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
    const [siteProjectList, setSiteProjectList] = useState([]);

    const getSiteEmployee = async () => {
        setBuffering(true); // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/employee/`);
            // const res = await axios.get(PERMISSION_AUTHORITY ?
            //     `${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`
            //     :
            //     `${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`
            // );

            // const dep = await axios.get(`${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`);
            // const sub = await axios.get(`${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/${toggleCompany}/active/`);
            const dep = await axios.get(`${BASE_URL}/siteproject/designation/`);
            const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            const pro = await axios.get(`${BASE_URL}/siteproject/project/`);


            setSiteEmployeeList(res.data);
            setSiteDepartmentList(dep.data);
            setSiteSubCompanyList(sub.data);
            setSiteProjectList(pro.data);
        } catch (err) {

            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getSiteEmployee();
    }, [toggleCompany]);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Site Employees</div>
                <div className="field-cont">

                    {/* {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
                        <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
                            <option value="null">Select Sub Company</option>
                            {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

                        </select>
                    </div> : null
                    } */}
                    <div className="table-searchh field-cont-div">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                            >
                                <circle
                                    cx="5"
                                    cy="5"
                                    r="4.3"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                />
                                <line
                                    x1="10.0101"
                                    y1="11"
                                    x2="8"
                                    y2="8.98995"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        <div>
                            <input
                                placeholder="Search"
                                className="navbar-inputt font-weight400  font-size-text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <AddSiteEmployee
                        getSiteEmployee={getSiteEmployee}
                        siteProjectList={siteProjectList}
                        siteDepartmentList={siteDepartmentList}
                        siteSubCompanyList={siteSubCompanyList}
                    />
                </div>
            </div>

            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="align-left">ID</th>
                        <th className="align-center">Employee</th>
                        <th className="align-center">Email</th>
                        <th className="align-center">Mobile</th>
                        <th className="align-center">Designation</th>
                        <th className="align-center">Proffesional</th>
                        <th className="align-center">Address</th>
                        <th className="align-center">Join Date</th>
                        <th className="align-center">Sub Company</th>
                        <th className="align-right width-5vw">Action</th>
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> :
                    <tbody>
                        {siteEmployeeList
                            ?.filter((e) =>
                                e.name.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) =>
                                a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
                            )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">{i.emp_code}-{i.name}</td>
                                        <td className="align-center">{i.email}</td>
                                        <td className="align-center">{i.mobile}</td>
                                        <td className="align-center">{i.designation_name}</td>
                                        <td className="align-center">{i.professional_type}</td>
                                        <td className="align-center">{i.current_address}</td>
                                        <td className="align-center">{formattedDateLong(i.joining_date)}</td>
                                        <td className="align-center">{i.sub_company_name}</td>


                                        <td className="align-right flex-row ">
                                            <UpdateSiteEmployee
                                                i={i}
                                                getSiteEmployee={getSiteEmployee}
                                                siteProjectList={siteProjectList}
                                                siteDepartmentList={siteDepartmentList}
                                                siteSubCompanyList={siteSubCompanyList}
                                            />
                                            <DeleteSiteEmployee
                                                i={i}
                                                getSiteEmployee={getSiteEmployee}

                                            />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};


const getFirstName = (fullName) => {
    return fullName.split(' ')[0]; // Split by space and take the first part
}

const SiteProjectTable = () => {
    const tableRef = useRef(null);
    const [toggleCompany, setToggleCompany] = useState("null");
    const { PERMISSION_AUTHORITY } = usePermission();
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

    const [selectedName, setName] = useState('');
    const [employees, setEmployees] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDep, setDep] = useState('');
    const [selectedDesi, setDesi] = useState('');
    const [selectedYears, setYears] = useState('');
    const [selectedMonths, setMonths] = useState('');
    const [selectedCC, setSelectedCC] = useState('1');

    const [projectsList, setProjectsList] = useState([]);
    const [eotList, setEotList] = useState([]);
    const [cosList, setcosList] = useState([]);
    const [billList, setBillList] = useState([]);


    const dummydata = [];

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const navigate = useNavigate()
    const ViewBillDetails = (project) => {
        navigate(`/${urlParts[3]}/siteProjectBillDetails/`, { state: { project } })
    }
    const [buffer, setBuffering] = useState(true); //buffering logic

    const getProjects = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSiteSubCompanyList(sub.data);

            const res = await axios.get(`${BASE_URL}/siteproject/projectdashboard/`);
            setProjectsList(res.data);

        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false);// End Buffering
        }
    };


    useEffect(() => {
        getProjects();
    }, [toggleCompany, selectedCC]);

    useEffect(() => {
        applyFilters();
    }, [selectedName, employees, selectedDep, selectedDesi, selectedYears, selectedMonths]);

    const applyFilters = () => {
        let filteredData = employees;
        if (selectedName) {
            const lowercaseSelectedName = selectedName.toLowerCase();
            filteredData = filteredData.filter(employee => {
                const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
                const empCodeMatch = employee.company_details.emp_code.toString().includes(selectedName);
                const emailMatch = employee.email.toLowerCase().includes(lowercaseSelectedName);
                return nameMatch || empCodeMatch || emailMatch;
                // return nameMatch || empCodeMatch;
            });
        }

        if (selectedDep) {
            filteredData = filteredData.filter(employee => employee.company_details.department_name === selectedDep);
        }
        if (selectedDesi) {
            const lowercaseSelectedName = selectedDesi.toLowerCase();
            filteredData = filteredData.filter(employee => {
                const desiMatch = employee.company_details.designation_name.toLowerCase().includes(lowercaseSelectedName);
                return desiMatch;
            });
        }
        if (selectedYears !== '' || selectedMonths !== '') {
            const years = parseInt(selectedYears) || 0;
            const months = parseInt(selectedMonths) || 0;

            filteredData = filteredData.filter(employee => {
                const [employeeYears, employeeMonths] = employee.total_work_experience.split(', ').map(str => parseInt(str));

                if (selectedYears !== '' && selectedMonths !== '') {
                    // Both year and month filters are applied
                    if (employeeYears === years && employeeMonths === months) {
                        return true;
                    }

                    // Include edge case where employee has (years - 1) and 12 months
                    if (employeeYears === (years - 1) && employeeMonths === 12 && months === 0) {
                        return true;
                    }

                    return false;
                } else if (selectedYears !== '') {
                    // Only year filter is applied
                    if (employeeYears === years) {
                        return true;
                    }

                    // Include edge case where employee has (years - 1) and 12 months
                    if (employeeYears === (years - 1) && employeeMonths === 12) {
                        return true;
                    }

                    return false;
                } else {
                    // Only month filter is applied
                    return employeeMonths === months;
                }
            });
        }
        setFilteredData(filteredData);
    };


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                {/* {loading || buffer ? ( */}

                <>
                    <div>
                        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                        <div className="attendance-subcont">
                            <div className="field-cont">

                                {PERMISSION_AUTHORITY ?
                                    <div title="Company Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <select
                                            onChange={(e) => setToggleCompany(e.target.value)}
                                            value={toggleCompany}
                                            className="attendance-input-field width-15vw   date-field"
                                        // className="dropdown font-size-subheading  "
                                        >
                                            <option value="null">Select Sub Company</option>
                                            {siteSubCompanyList.map((company) => (
                                                <option value={company.id}>{company.title}</option>
                                            ))}
                                            ;
                                        </select>

                                        <hr className="field-cont-hr" />
                                    </div> : null}
                                {/* <hr /> */}
                                <div title='Search Filter' className="field-cont-div">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 11 12"
                                        fill="none"
                                    >
                                        <circle
                                            cx="5"
                                            cy="5"
                                            r="4.3"
                                            stroke="#707070"
                                            stroke-width="1.4"
                                        />
                                        <line
                                            x1="10.0101"
                                            y1="11"
                                            x2="8"
                                            y2="8.98995"
                                            stroke="#707070 "
                                            stroke-width="1.4"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                    <input className="attendance-input-field width-15vw  " placeholder='Name/ Code/ Email' type="text" value={selectedName}
                                        // </svg><input className="attendance-input-field width-10vw  " placeholder='Email / Code / RH' type="text" value={selectedName}
                                        onChange={(e) => setName(e.target.value)} />

                                    <hr className="field-cont-hr" />
                                </div>


                                <div title="CC Filter" className="field-cont-div">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                    >
                                        <g clip-path="url(#clip0_650_3324)">
                                            <path
                                                d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                fill="#707070"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_650_3324">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <select
                                        className="attendance-input-field width-10vw   date-field"
                                        type="text"
                                        value={selectedCC}
                                        onChange={(e) => setSelectedCC(e.target.value)}
                                    >
                                        {/* <option value=""> All View</option> */}
                                        <option value="1"> Contractor</option>
                                        <option value="2"> Consultant</option>

                                    </select>
                                    <hr className="field-cont-hr" />
                                </div>
                                {/* 
                                    <div title="Designation Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Designation"
                                            type="text"
                                            value={selectedDesi}
                                            onChange={(e) => setDesi(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div> */}


                                {/* <div title="Year Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Years"
                                            type="number"
                                            min={0}
                                            value={selectedYears}
                                            onChange={(e) => setYears(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div>

                                    <div title="Month Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Months"
                                            type="number"
                                            min={0}
                                            max={12}
                                            value={selectedMonths}
                                            onChange={(e) => setMonths(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div> */}

                                {/* <div className="field-cont-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14" fill="none">
                                        <g clip-path="url(#clip0_650_3324)">
                                            <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                                            <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                                            <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                                            <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                                            <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                                            <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                                            <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                                            <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                                            <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_650_3324">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <select className="attendance-input-field width-10vw   date-field" type="text"
                                        value={selectedDivision}
                                        onChange={(e) => (e.target.value)}
                                    >
                                        <option value=""> All Sub Companies</option>
                                        {subCompanyList.sort((a, b) => a.title - b.title).map((i, index) => (<option value={i.title}>{i.title}</option>))};

                                    </select>
                                </div> */}
                            </div>
                            <div className="btn-cont">
                                <AddProjectDetails getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
                                {/* <AddTeamMember project={projectsList} getProjects={getProjects} /> */}
                                <DownloadTableExcel
                                    filename="Custom-ProjectDetails"
                                    sheet="projectDetails"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className="model-button font-weight500 model-button-print">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                            <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                                <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                            </g>
                                        </svg>
                                    </button>
                                </DownloadTableExcel>

                            </div>
                        </div>
                    </div>

                    {buffer ? (
                        <p>Loading Details... <div className="spinner-small"> </div></p> //buffering logic 
                    ) : error ? (
                        <p>Error fetching data: {error.message}</p>
                    ) : (<>


                        {selectedCC === `1` ?
                            <ProjectContractorView projectsList={projectsList} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
                            :
                            <ProjectConsultantView projectsList={projectsList} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
                        }
                    </>
                    )}
                </>

            </div >
        </>
    );
};


const ProjectContractorView = ({ projectsList, getProjects, siteSubCompanyList }) => {
    const tableRef = useRef(null);

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const navigate = useNavigate()
    const toggleshow = "Contractor"

    const ViewAllProjectDetails = (project) => {
        navigate(`/${urlParts[3]}/ProjectAllDetails/`, { state: { project, toggleshow } })
    }

    const ViewProjectDetails = (project) => {
        navigate(`/${urlParts[3]}/ProjectContractorDetails/`, { state: { project, toggleshow } })
    }

    const ViewContractorBillDetails = (project) => {
        navigate(`/${urlParts[3]}/siteProjectBillDetails/`, { state: { project, toggleshow } })
    }

    return (
        <>
            {/* <div className='filter-showing'>
                <div>Contractor Details:</div>
                <div>Showing {projectsList.length} of {projectsList.length} </div>
            </div> */}

            <div className="table-css-white-background">
                <table ref={tableRef} className="font-size-label table-css">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className='align-left'>Project Name</th>
                            <th className='align-center '>Progress</th>
                            <th className='align-center'>Project Details</th>
                            <th className='align-center'>Billing details</th>
                            <th className='align-center'>Extension of Time</th>
                            <th className='align-center'>Change of Scope</th>
                            <th className='align-center width-10vw'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projectsList.map(project => (
                            <tr key={project.id} className="custom-table-head-td4" >
                                <td className='align-left' style={{ minWidth: '8%', maxWidth: '15%' }} >{project.site_prcode} : <br /> {project.project_short_name}
                                </td>
                                <td className='align-center'>
                                    <div className='employee-card-image'>
                                        <table className="nested-table font-size-subheading" style={{ cursor: "pointer" }} onClick={() => ViewProjectDetails(project)} title="Go to Project Contractor Details">
                                            <tbody>
                                                <tr>
                                                    <td className='width-5vw'>Month</td>
                                                    <td className='width-5vw'>{project?.monthh ? project?.award_date : "Jan"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Physical</td>
                                                    <td>{project?.physical ? project?.appointed_date : "20%"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Financial</td>
                                                    <td>{project?.financial ? project?.project_completion_date : "10%"}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                                <td className='align-center'>
                                    {project.project_name ? (

                                        <div className='employee-card-image'>
                                            <table className="nested-table font-size-subheading" style={{ cursor: "pointer" }} onClick={() => ViewProjectDetails(project)} title="Go to Project Contractor Details">
                                                <tbody>
                                                    <tr>
                                                        <td>Contractor Award</td>
                                                        <td>{project?.award_date ? formattedDateLong(project?.award_date) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Contractor Appointed</td>
                                                        <td>{project?.appointed_date ? formattedDateLong(project?.appointed_date) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Completion Date</td>
                                                        <td>{project?.project_completion_date ? formattedDateLong(project?.project_completion_date) : "-"}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Construction Cost</td>
                                                        <td>{project?.construction_cost ? project?.construction_cost : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Contract Mode</td>
                                                        <td>{project?.contract_mode ? project?.contract_mode : "-"}</td>
                                                    </tr>

                                                    {/* <tr>
                                                        <td>Lead, <br />JV,<br />Association</td>
                                                        <td>{getFirstName(project.lead)},<br />{getFirstName(project.jv)},<br />{getFirstName(project.association)}</td>

                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className='align-center'>
                                            <p>Project Details Unavailable.</p>
                                        </div>
                                    )}
                                </td>


                                <td className='align-center'>
                                    {project.billing_details?.length > 0 ? (
                                        <>
                                            <div className='employee-card-image'>
                                                <table className="nested-table font-size-subheading">
                                                    <thead>
                                                        <tr>
                                                            <th>Month</th>
                                                            <th>SPS</th>
                                                            <th>Recmd. SPS</th>
                                                            <th>Recmd. IPC</th>
                                                            <th>Total Recmd. SPS + IPC</th>
                                                            <th>Withheld</th>
                                                            <th>Total Withheld</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {project.billing_details.map(billDetails => (
                                                            <tr>
                                                                <td>{billDetails.month}</td>
                                                                <td>{billDetails.contractor_submitted?.billing_amount}</td>
                                                                <td>{billDetails.contractor_recomended?.billing_amount}</td>
                                                                <td>{billDetails.contractor_bal_recommended?.billing_amount}</td>
                                                                <td>{billDetails.contractor_recomended?.billing_amount}+{billDetails.contractor_bal_recommended?.billing_amount}</td>
                                                                {/* <td><span title='Withheld' className='behavior' data-status={"green"}>{100}</span><span title='Released Withheld' className='behavior' data-status={"red"}>-{50}</span></td> */}
                                                                <td><span className='behavior' data-status={"green"}>{billDetails.withheld_amt}</span><span className='behavior' data-status={"red"}>-{billDetails.released_withheld_amt}</span></td>
                                                                <td>{billDetails.withheld_amt - billDetails.released_withheld_amt}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <br />
                                            </div>


                                            <button onClick={() => ViewContractorBillDetails(project)} title="View Bill Details">
                                                {/* <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 30 30"
                                                    fill="none"
                                                >
                                                    <circle
                                                        cx="15"
                                                        cy="15"
                                                        r="15"
                                                        fill="#2576BC"
                                                    ></circle>
                                                    <path
                                                        d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                        fill="#F6F7F9"
                                                    ></path>
                                                </svg> */}
                                                <div className='behavior' data-status={"blue"}>{"View Bill Details >>"}</div>

                                            </button>
                                        </>
                                    ) : (
                                        <div className='align-center'>
                                            <p>No Bill Data Available.</p>

                                            <button onClick={() => ViewContractorBillDetails(project)} title="Go to Bill Details">

                                                <div className='behavior' data-status={"blue"}>{"Go to Bill Details >>"}</div>

                                            </button>
                                        </div>
                                    )}
                                </td>

                                <td className='align-center'>
                                    {project.eots.contractor_eots.length > 0 ? (

                                        <div className='employee-card-image'>
                                            <table className="nested-table font-size-subheading">
                                                <thead>
                                                    <tr>
                                                        <th>EOT</th>
                                                        <th>Approved Date</th>
                                                        <th>Duration</th>
                                                        {/* <th>Performed By</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {project.eots.contractor_eots.map(eotDetails => (
                                                        <tr>
                                                            <td>{eotDetails.eot_code}</td>
                                                            <td>{formattedDateLong(eotDetails.approved_date)}</td>
                                                            <td>{eotDetails.approved_days}</td>
                                                            {/* <td>{eotDetails.workperformedby}</td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className='align-center'>
                                            <p>No Contractor EOT Data Available.</p>
                                        </div>
                                    )
                                    }
                                </td>

                                <td className='align-center'>
                                    {project.coss.contractor_coss?.length > 0 ? (

                                        <div className='employee-card-image'>
                                            <table className="nested-table font-size-subheading">
                                                <thead>
                                                    <tr>
                                                        <th>COS</th>
                                                        <th>Approved Date</th>
                                                        <th>Amount</th>
                                                        {/* <th>Performed By</th> */}

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {project.coss.contractor_coss.map(cosDetails => (
                                                        <tr>
                                                            <td>{cosDetails.cos_code}</td>
                                                            <td>{formattedDateLong(cosDetails.approval_date)}</td>
                                                            <td>{cosDetails.amount}</td>
                                                            {/* <td>{cosDetails.workperformedby}</td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className=' align-center'>
                                            <p>No Contractor COS Data Available.</p>
                                        </div>
                                    )}
                                </td>

                                {/* <td className='align-center width-5vw'>
                                    <button className='' onClick={() => ViewBillDetails(project)} title="View Bill Details">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="40"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                        >
                                            <circle
                                                cx="15"
                                                cy="15"
                                                r="15"
                                                fill="#2576BC"
                                            ></circle>
                                            <path
                                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                fill="#F6F7F9"
                                            ></path>
                                        </svg>
                                    </button>
                                </td> */}
                                <td className='align-center'>
                                    <UpdateProjectDetails i={project} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} projectsList={projectsList} />
                                    <button
                                        onClick={() => ViewAllProjectDetails(project)}
                                        title="All Project Details"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="40"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                        >
                                            <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                            <path
                                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                fill="#2576BC"
                                            />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};


const ProjectConsultantView = ({ projectsList, getProjects, siteSubCompanyList }) => {
    const tableRef = useRef(null);

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const navigate = useNavigate()
    const toggleshow = "Consultant"

    const ViewAllProjectDetails = (project) => {
        navigate(`/${urlParts[3]}/ProjectAllDetails/`, { state: { project, toggleshow } })
    }

    const ViewProjectDetails = (project) => {
        navigate(`/${urlParts[3]}/ProjectConsultantDetails/`, { state: { project, toggleshow } })
    }

    const ViewConsultantBillDetails = (project) => {
        navigate(`/${urlParts[3]}/siteProjectBillDetails/`, { state: { project, toggleshow } })
    }

    return (
        <>
            <div className='filter-showing'>
                <div>Consultant details:</div>
                <div>Showing {projectsList.length} of {projectsList.length} </div>
            </div>

            <div className="table-css-white-background">
                <table ref={tableRef} className="font-size-label table-css">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className='align-left'>Project Name</th>
                            <th className='align-center'>Progress</th>
                            <th className='align-center'>Project Details</th>
                            <th className='align-center'>Billing details</th>
                            <th className='align-center'>Extension of Time</th>
                            <th className='align-center'>Change of Scope</th>
                            <th className='align-right width-10vw'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projectsList.map(project => (
                            <tr key={project.id} className="custom-table-head-td4" >
                                <td className='align-left' style={{ minWidth: '10%', maxWidth: '20%' }} >{project.site_prcode} : {project.project_short_name}</td>

                                <td className='align-center'>
                                    <div className='employee-card-image'>
                                        <table className="nested-table font-size-subheading" style={{ cursor: "pointer" }} onClick={() => ViewProjectDetails(project)} title="Go to Project Contractor Details">
                                            <tbody>
                                                <tr>
                                                    <td>Month</td>
                                                    <td>{project?.monthh ? project?.award_date : "Jan"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Physical</td>
                                                    <td>{project?.physical ? project?.appointed_date : "20%"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Financial</td>
                                                    <td>{project?.financial ? project?.project_completion_date : "10%"}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                                <td className='align-center'>
                                    {project.project_name ? (

                                        <div className='employee-card-image'>
                                            <table className="nested-table font-size-subheading" style={{ cursor: "pointer" }} onClick={() => ViewProjectDetails(project)} title="Go to Project Consultant Details">
                                                <tbody>

                                                    <tr>
                                                        <td>Consultant LOA </td>
                                                        <td>{project?.award_date ? formattedDateLong(project?.award_date) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Consultant Start Date</td>
                                                        <td>{project?.start_date ? formattedDateLong(project?.start_date) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Completion Date</td>
                                                        <td>{project?.project_completion_date ? formattedDateLong(project?.project_completion_date) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Consultancy Fees</td>
                                                        <td>{project?.consultancy_fees ? project?.consultancy_fees : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Contract Mode</td>
                                                        <td>{project?.contract_mode ? project?.contract_mode : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lead, <br />JV,<br />Association</td>
                                                        <td>{getFirstName(project.lead)},<br />{getFirstName(project.jv)},<br />{getFirstName(project.association)}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className='table-heading-flex'>
                                            <p>Project Details Unavailable.</p>
                                        </div>
                                    )}
                                </td>


                                <td className='align-center'>
                                    {project.billing_details?.length > 0 ? (
                                        <>
                                            <div className='employee-card-image'>
                                                <table className="nested-table font-size-subheading">
                                                    <thead>
                                                        <tr>
                                                            <th>Month</th>
                                                            <th>Billing</th>
                                                            <th>Recieved</th>
                                                            <th>Total Remaining</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {project.billing_details.map(billDetails => (
                                                            <tr>
                                                                <td>{billDetails.month}</td>
                                                                <td>{billDetails.consultancy_billing_sum}</td>
                                                                <td>{billDetails.consultancy_received_billing_sum}</td>
                                                                <td>{billDetails.consultancy_billing_sum - billDetails.consultancy_received_billing_sum}</td>
                                                                {/* <td>{billDetails.contractor_bal_recommended?.billing_amount}</td> */}
                                                                {/* <td>{billDetails.contractor_recomended?.billing_amount}+{billDetails.contractor_bal_recommended?.billing_amount}</td> */}
                                                                {/* <td>{billDetails.released_withheld_amt}</td> */}
                                                                {/* <td>{billDetails.withheld_amt}</td> */}
                                                                {/* <td>{billDetails.released_withheld_amt}+{billDetails.withheld_amt}</td> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <br />
                                            </div>


                                            <button onClick={() => ViewConsultantBillDetails(project)} title="View Bill Details">
                                                {/* <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 30 30"
                                                    fill="none"
                                                >
                                                    <circle
                                                        cx="15"
                                                        cy="15"
                                                        r="15"
                                                        fill="#2576BC"
                                                    ></circle>
                                                    <path
                                                        d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                        fill="#F6F7F9"
                                                    ></path>
                                                </svg> */}
                                                <div className='behavior' data-status={"blue"}>{"View Bill Details >>"}</div>

                                            </button>
                                        </>
                                    ) : (
                                        <div className='align-center'>
                                            <p>No Bill Data Available.</p>

                                            <button onClick={() => ViewConsultantBillDetails(project)} title="Go to Bill Details">

                                                <div className='behavior' data-status={"blue"}>{"Go to Bill Details >>"}</div>

                                            </button>
                                        </div>
                                    )}
                                </td>

                                <td className='align-center'>
                                    {project.eots.consultant_eots.length > 0 ? (

                                        <div className='employee-card-image'>
                                            <table className="nested-table font-size-subheading">
                                                <thead>
                                                    <tr>
                                                        <th>EOT</th>
                                                        <th>Approved Date</th>
                                                        <th>Duration</th>
                                                        {/* <th>Performed By</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {project.eots.consultant_eots.map(eotDetails => (
                                                        <tr>
                                                            <td>{eotDetails.eot_code}</td>
                                                            <td>{formattedDateLong(eotDetails.approved_date)}</td>
                                                            <td>{eotDetails.approved_days}</td>
                                                            {/* <td>{eotDetails.workperformedby}</td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className='align-center'>
                                            <p>No Consultant EOT Data Available.</p>
                                        </div>
                                    )
                                    }
                                </td>


                                <td className='align-center'>
                                    {project.coss.consultant_coss?.length > 0 ? (

                                        <div className='employee-card-image'>
                                            <table className="nested-table font-size-subheading">
                                                <thead>
                                                    <tr>
                                                        <th>COS</th>
                                                        <th>Approved Date</th>
                                                        <th>Amount</th>
                                                        {/* <th>Performed By</th> */}

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {project.coss.consultant_coss.map(cosDetails => (
                                                        <tr>
                                                            <td>{cosDetails.cos_code}</td>
                                                            <td>{formattedDateLong(cosDetails.approval_date)}</td>
                                                            <td>{cosDetails.amount}</td>
                                                            {/* <td>{cosDetails.workperformedby}</td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className=' align-center'>
                                            <p>No Consultant COS Data Available.</p>
                                        </div>
                                    )}
                                </td>


                                <td className='align-center width-10vw'>
                                    <UpdateProjectDetails i={project} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
                                    <button
                                        onClick={() => ViewAllProjectDetails(project)}
                                        title="All Project Details"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="40"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                        >
                                            <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                            <path
                                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                fill="#2576BC"
                                            />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};


const SiteTrackRecordTable = () => {
    const tableRef = useRef(null);
    const [toggleCompany, setToggleCompany] = useState("null");
    const { PERMISSION_AUTHORITY } = usePermission();
    const [projectList, setProjectList] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedName, setName] = useState('');
    const [selectedDep, setDep] = useState('');
    const [selectedDesi, setDesi] = useState('');
    const [selectedYears, setYears] = useState('');
    const [selectedMonths, setMonths] = useState('');

    const [trackRecordList, setTrackRecordList] = useState([]);


    const [buffer, setBuffering] = useState(true); //buffering logic

    const getProjects = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
            setProjectList(pro.data);

            const res = await axios.get(`${BASE_URL}/siteproject/trackrecord/`);
            setTrackRecordList(res.data);

        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false);// End Buffering
        }
    };


    useEffect(() => {
        getProjects();
    }, [toggleCompany]);

    useEffect(() => {
        applyFilters();
    }, [selectedName, selectedDep, selectedDesi, selectedYears, selectedMonths]);

    const applyFilters = () => {
        let filteredData = [];
        if (selectedName) {
            const lowercaseSelectedName = selectedName.toLowerCase();
            filteredData = filteredData.filter(employee => {
                const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
                const empCodeMatch = employee.company_details.emp_code.toString().includes(selectedName);
                const emailMatch = employee.email.toLowerCase().includes(lowercaseSelectedName);
                return nameMatch || empCodeMatch || emailMatch;
                // return nameMatch || empCodeMatch;
            });
        }

        if (selectedDep) {
            filteredData = filteredData.filter(employee => employee.company_details.department_name === selectedDep);
        }
        if (selectedDesi) {
            const lowercaseSelectedName = selectedDesi.toLowerCase();
            filteredData = filteredData.filter(employee => {
                const desiMatch = employee.company_details.designation_name.toLowerCase().includes(lowercaseSelectedName);
                return desiMatch;
            });
        }
        if (selectedYears !== '' || selectedMonths !== '') {
            const years = parseInt(selectedYears) || 0;
            const months = parseInt(selectedMonths) || 0;

            filteredData = filteredData.filter(employee => {
                const [employeeYears, employeeMonths] = employee.total_work_experience.split(', ').map(str => parseInt(str));

                if (selectedYears !== '' && selectedMonths !== '') {
                    // Both year and month filters are applied
                    if (employeeYears === years && employeeMonths === months) {
                        return true;
                    }

                    // Include edge case where employee has (years - 1) and 12 months
                    if (employeeYears === (years - 1) && employeeMonths === 12 && months === 0) {
                        return true;
                    }

                    return false;
                } else if (selectedYears !== '') {
                    // Only year filter is applied
                    if (employeeYears === years) {
                        return true;
                    }

                    // Include edge case where employee has (years - 1) and 12 months
                    if (employeeYears === (years - 1) && employeeMonths === 12) {
                        return true;
                    }

                    return false;
                } else {
                    // Only month filter is applied
                    return employeeMonths === months;
                }
            });
        }
        setFilteredData(filteredData);
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                {/* {loading || buffer ? ( */}

                <>
                    <div>
                        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                        <div className="attendance-subcont">
                            <div className="field-cont">

                                {PERMISSION_AUTHORITY ?
                                    <div title="Company Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <select
                                            onChange={(e) => setToggleCompany(e.target.value)}
                                            value={toggleCompany}
                                            className="attendance-input-field width-15vw   date-field"
                                        // className="dropdown font-size-subheading  "
                                        >
                                            <option value="null">Select Sub Company</option>
                                            {projectList.map((company) => (
                                                <option value={company.id}>{company.title}</option>
                                            ))}
                                            ;
                                        </select>

                                        <hr className="field-cont-hr" />
                                    </div> : null}
                                {/* <hr /> */}
                                <div title='Search Filter' className="field-cont-div">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 11 12"
                                        fill="none"
                                    >
                                        <circle
                                            cx="5"
                                            cy="5"
                                            r="4.3"
                                            stroke="#707070"
                                            stroke-width="1.4"
                                        />
                                        <line
                                            x1="10.0101"
                                            y1="11"
                                            x2="8"
                                            y2="8.98995"
                                            stroke="#707070 "
                                            stroke-width="1.4"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                    <input className="attendance-input-field width-15vw  " placeholder='Name/ Code/ Email' type="text" value={selectedName}
                                        // </svg><input className="attendance-input-field width-10vw  " placeholder='Email / Code / RH' type="text" value={selectedName}
                                        onChange={(e) => setName(e.target.value)} />

                                    <hr className="field-cont-hr" />
                                </div>

                                <div title="Designation Filter" className="field-cont-div">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                    >
                                        <g clip-path="url(#clip0_650_3324)">
                                            <path
                                                d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                fill="#707070"
                                            />
                                            <path
                                                d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                fill="#707070"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_650_3324">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <input
                                        className="attendance-input-field width-15vw  "
                                        placeholder="Designation"
                                        type="text"
                                        value={selectedDesi}
                                        onChange={(e) => setDesi(e.target.value)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>


                            </div>
                            <div className="btn-cont">
                                <AddTrackRecordDetails getProjects={getProjects} projectList={projectList} />
                                <DownloadTableExcel
                                    filename="Site-ProjectDetails"
                                    sheet="projectDetails"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className="model-button   font-weight500   model-button-print">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                            <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                                <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                            </g>
                                        </svg>
                                    </button>
                                </DownloadTableExcel>

                            </div>
                        </div>
                    </div>
                    <div className='filter-showing'>
                        <div>Letter Records:</div>
                        <div>Showing {trackRecordList.length} of {trackRecordList.length} </div>
                    </div>

                    <div className="table-css-white-background">
                        <table ref={tableRef} className="table-css">
                            <thead>
                                <tr className='custom-table-head-tr'>
                                    <th className='align-left'>Track Id</th>
                                    <th className='align-center'>Project Id</th>
                                    <th className='align-center'>Reviewed By</th>
                                    <th className='align-center'>Subject</th>
                                    <th className='align-center'>Sent Letter No.</th>
                                    <th className='align-center'>Sent Letter Date</th>
                                    <th className='align-center'>Received Letter No.</th>
                                    <th className='align-center'>Received Letter Date</th>
                                    <th className='align-center'>Status</th>
                                    <th className='align-center'>Sent to TL</th>
                                    <th className='align-center'>Reply Pending</th>
                                    <th className='align-center'>Reply Sent</th>
                                    <th className='align-right width-5vw'>Actions</th>
                                </tr>
                            </thead>
                            {buffer ? (
                                <p>Loading Details... <div className="spinner-small"> </div></p> //buffering logic 
                            ) : error ? (
                                <p>Error fetching data: {error.message}</p>
                            ) :
                                <tbody>
                                    {trackRecordList.map((i, index) => (
                                        <tr className="custom-table-head-td">
                                            <td className='align-left'>{i.track_id}</td>
                                            <td className='align-center'>{i.project}</td>
                                            <td className='align-center'>{i.reviewed_by}</td>
                                            <td className='align-center'>{i.subject}</td>
                                            <td className='align-center'>{i.sent_letter_no}</td>
                                            <td className='align-center'>{formattedDateLong(i.sent_letter_date)}</td>
                                            <td className='align-center'>{i.received_letter_no}</td>
                                            <td className='align-center'>{formattedDateLong(i.received_letter_date)}</td>
                                            <td className='align-center'>{i.status}</td>
                                            <td className='align-center'>{i.sent_to_tl}</td>
                                            <td className='align-center'>{i.reply_pending}</td>
                                            <td className='align-right width-5vw'>{i.reply_sent}</td>


                                            <td>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>}
                        </table>
                    </div>
                </>
            </div>
        </>
    );
};


const CircularTable = () => {
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { PERMISSION_AUTHORITY } = usePermission();
    const [sectorData, setSectorData] = useState([]);

    const getWebsiteSectors = async () => {
        setBuffering(true); // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/siteproject/circularupload/`);
            setSectorData(sub.data);

        } catch (err) {

            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getWebsiteSectors();
    }, [toggleCompany]);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500 font-size-heading">Circular</div>
                <div className="field-cont">

                    <AddCircular getWebsiteSectors={getWebsiteSectors} sectorList={sectorData} />


                </div>
            </div>
            {/* <SectorsDetailForm /> */}
            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr ">
                        <th className="align-left">ID</th>
                        <th className="align-center">Title</th>
                        <th className="align-center">Publish Date</th>
                        <th className="align-center">Description</th>



                        <th className="align-center">Attachment</th>
                        {/* <th className="align-center">Created By</th> */}
                        {/* <th className="align-center">Created At</th> */}
                        {/* <th className="align-right width-5vw">Action</th> */}
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> :
                    <tbody>
                        {sectorData
                            // ?.filter((e) =>
                            //     e.title.toLowerCase().includes(searchQuery.toLowerCase())
                            // )
                            // .sort((a, b) =>
                            //     a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                            // )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        {/* <td className="align-center">{i.name}</td> */}
                                        <td className="align-center">
                                            {i.title}
                                            {/* {i.icon
                                                ? <img
                                                    className="img-table"
                                                    src={`${i.icon}`}
                                                    alt="Icon"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                                    <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                                    <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                                </svg>
                                            } */}

                                        </td>

                                        <td className="align-center form-text-trasformation-uppercase">
                                            {i.publish_date ? formattedDateLong(i.publish_date) : "-"}
                                        </td>


                                        <td className="align-center ">
                                            {i.description}
                                        </td>
                                        <td className="align-center ">
                                            <a href={i.file} target="blank" ><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16" color="#FF747C">
                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                                <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                            </svg></a>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export { SiteDesignationTable, SiteEmployeeTable, SiteProjectTable, SiteSubcompanyTable, SiteTrackRecordTable, CircularTable };
