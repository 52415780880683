import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../config/axios"
import Modal from "react-bootstrap/Modal";
import { formatDate, formattedDate } from "../../Date";
import { ToastContainer, toast } from "react-toastify";

const RequisitionAddmodel = ({ getOnboarding, departmentname, designationname }) => {

    const [buffer, setBuffering] = useState(true); //buffering logic
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getCompanyDetails(sessionStorage.getItem("rh"))
        setShow(true)
        console.log("empCode");
        console.log(empCode);
    };

    const [empCode, setEmpCode] = useState()
    const getCompanyDetails = async (email) => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemail/${email}/`);
            setEmpCode(res.data.emp_code)
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [formData, setFormData] = useState({

        empcode: sessionStorage.getItem("emp_code"),
        name: sessionStorage.getItem("name"),
        rh_assigned: empCode,
        department: "",
        date: formattedDate,
        designation: "",

    });



    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "department",
            "designation",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };



    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const updatedFormData = {
                ...formData,
                empcode: sessionStorage.getItem("emp_code"),
                name: sessionStorage.getItem("name"),
                sub_company: sessionStorage.getItem('company_id'),
                rh_assigned: empCode,


            };

            try {

                let res = await axios.post(`${BASE_URL}/jd/requisition/`, updatedFormData,)


                if (res.status === 200) {
                    handleClose()
                    await getOnboarding()
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };







    return (
        <>

            <button className="models-button model-add" title="Add Requisition Request" onClick={handleShow} style={{ margin: "2px" }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title>New Requisition Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*  ************** timeline Seaction  ************** */}

                    <>


                        <form className="register-form">
                            <div className="form-flex-wrap">

                                {/* <div className="flex-column">

                                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                                        Name<span className="required">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Candidate name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.name
                                            ? "error"
                                            : inputState.name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.name && (
                                        <span className="error-message font-size-text ">{errors.name}</span>
                                    )}
                                </div> */}

                                {/* <div className="flex-column">
                                    <label htmlFor="email" className="form-labels  font-weight500    font-size-subheading">
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Demo@gmail.com"
                                        onChange={handleInputChange}
                                        value={formData.email}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.toLowerCase(); // Convert input to lowercase
                                        }}
                                        className={`form-input ${errors.email
                                            ? "error"
                                            : inputState.email
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.email && (
                                        <span className="error-message font-size-text ">{errors.email}</span>
                                    )}
                                </div> */}





                                <div className="flex-row form-group-selectt">
                                    <label htmlFor="department" className="form-labels  font-weight500    font-size-subheading">
                                        Department<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="department"
                                        name="department"
                                        value={formData.department}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Department</option>
                                        {departmentname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.department && (
                                            <span className="error-message font-size-text ">{errors.department}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-row form-group-selectt">
                                    <label htmlFor="designation" className="form-labels  font-weight500    font-size-subheading">
                                        Designation<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="designation"
                                        name="designation"
                                        value={formData.designation}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Designation</option>
                                        {designationname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.designation && (
                                            <span className="error-message font-size-text ">{errors.designation}</span>
                                        )}
                                    </div>
                                </div>

                            </div>

                            <div className="button-models">

                                <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                                    Submit
                                </button>
                            </div>
                        </form></>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                </Modal.Body>
            </Modal>

        </>
    );
};

export default RequisitionAddmodel;
