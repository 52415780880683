import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react'

import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import Orgstructure from './Orgstructure'
import Attendance from './Attendance'
import EmployeeDetails from './EmployeeDetails'
import Requestmanagment from './Requestmanagment'
import TLRequestmanagment from './tlwfm/Requestmanagement'
import { RequestRequisitionTableAdmin } from '../RequisitionManagement/RequisitionManagementTables';
import EmployeeDataExcel, { EmployeeDataExcelEducation, EmployeeDataExcelWork, EmployeeDatasheet } from './EmployeeDataExcel';
import usePermission from '../../config/permissions';
import OrgPolicies from "./OrgPolicies";
import { HRLogRecords } from "./EmpLogRecords";

const Workforcemanagement = () => {
  const location = useLocation();
  const firsttoggle = location.state !== null ? location.state?.firsttoggle : 1;
  const secondtoggle = location.state !== null ? location.state?.secondtoggle : 1;
  const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission();

  const [toggleState, setToggleState] = useState(firsttoggle);


  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'Organization Structure';
      case 2:
        return 'Employee Management';
      case 3:
        return 'Attendance';
      case 4:
        return 'Team Request Management';
      case 5:
        return 'HR Request Management';
      case 6:
        return 'Requisition Management';
      case 7:
        return 'Employee Data Management';
      case 9:
        return 'Logs Record';
      default:
        return 'Organization Structure';
    }
  };

  return (
    <div>
      <Dashboardnavbarcopy name={getSelectedTitle()} url="Workforce Managment" />
      <div className="bloc-tabss2  wfm-margin">
        <button
          className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(1)}
        >
          Organization Structure
        </button>
        <button
          className={toggleState === 8 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(8)}
        >
          Organization Policies
        </button>
        <button
          className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(2)}
        >
          Employee Management
        </button>
        <button
          className={toggleState === 7 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(7)}
        >
          Employee Details Table
        </button>
        <button
          className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(3)}
        >
          Attendance
        </button>
        {/* <button
          className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(4)}
        >
          Team Request Management
        </button> */}
        {/* <button
          className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(5)}
        >
          HR Request Management
        </button> */}
        {/* {PERMISSION_AUTHORITY ?
          <button
            className={toggleState === 6 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
            onClick={() => setToggleState(6)}
          >
            Requisition Management
          </button> : ''} */}

        <button
          className={toggleState === 9 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(9)}
        >
          Logs Record
        </button>



      </div>
      <div className="content-tabs">

        {toggleState === 1 ? <Orgstructure /> : null}

        {toggleState === 8 ? <OrgPolicies /> : null}

        {toggleState === 2 ? <EmployeeDetails /> : null}

        {toggleState === 3 ? <Attendance /> : null}

        {toggleState === 4 ? <TLRequestmanagment secondtoggle={secondtoggle} /> : null}

        {toggleState === 5 ? <Requestmanagment secondtoggle={secondtoggle} /> : null}

        {toggleState === 6 ? <RequestRequisitionTableAdmin /> : null}

        {/* {toggleState === 7 ? <EmployeeDataExcel /> : null} */}
        {toggleState === 7 ? <EmployeeDatasheet /> : null}

        {toggleState === 9 ? <HRLogRecords /> : null}

      </div>
    </div>
  )
}

export default Workforcemanagement
