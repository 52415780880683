import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../config/axios"
import Modal from "react-bootstrap/Modal";
import Docs from "../../../assets/svg/docs.svg";
import { formattedDate } from "../../Date";
import { ToastContainer, toast } from "react-toastify";


const ResumeAddmodel = () => {
  // let { id, email } = useParams()

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({

    // emp_code: "",
    name: "",
    user_detail: "",
    mobile: "",
    // reference: "",
    current_address: "",
    remarks: "",
    id: "",
    coordinateby: `${sessionStorage.getItem('emp_code')} - ${sessionStorage.getItem('name')} `,
    email: "",
    currentCTC: "",
    monthlyinhand: "",
    expectedCTC: "",
    yearofExp: "",
    document_name: "",
    document_type: "",
    document_file: "",
    sub_company: sessionStorage.getItem('company_id')


  });
  const [docStatus, setDocStatus] = useState(false);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [buffer, setBuffering] = useState(true); //buffering logic

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "email",
      "mobile",
      "current_address",
      "id",
      "yearofExp",
      "currentCTC",
      "monthlyinhand",
      "expectedCTC",
      "document_file"
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };




  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    setFormData({
      ...formData,
      [name]: file,
    });
  };



  const symbols = '!@#$%^&*()_-+=';
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;

  let newPassword = '';
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    newPassword += allChars[randomIndex];
  }



  // ?  ************** Validation End **************  ! //


  const [jobposition, setJobPosition] = useState([])


  const getJobpositionlist = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/active/${sessionStorage.getItem("company_id")}/`);
      setJobPosition(res.data)
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getJobpositionlist();
  }, []);


  // console.log(formData)
  const [loading, setLoading] = useState(false); //loading logic
  const [posData, setPosData] = useState(false)

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log("check1");
    // console.log(formData);
    // console.log(docData.find(document => document.document_name === 'CV'));
    if (docData.find(document => document.document_name === 'CV')) {
      var docs = docData.find(document => document.document_name === 'CV')
      formData.document_name = docs.document_name
    }
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      // console.log("check2");


      const Registeter = {
        name: formData.name,
        email: formData.email,
        password: newPassword,
        role: "Jobapplicant"
      }

      const updatedPersonalFormData = {
        ...formData,
        // user_detail: formData.email,
        employee: formData.email
      };


      // console.log(formData)
      const formDataa = new FormData();
      formDataa.append("user", formData.email);

      formDataa.append("document_name", "CV");
      formDataa.append("document_type", "identity");

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.document_file;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });
      formDataa.append("document_file", customFile);



      const updatedPositionFormData = {
        ...formData,
        user: formData.email,
        employee: formData.email,
        password: newPassword,
        role: "Jobapplicant"
      };

      const updatedJobApplicant = {
        ...formData,

        jobpostdetails: formData.id,
        applied_date: formattedDate,
        user_detail: formData.email,
        remarks: formData.remarks,
        coordinateby: formData.coordinateby,
        user: formData.email,
        employee: formData.email,
        // current_address: formData.current_address,
        // email: formData.email,
        // mobile: formData.mobile,
        // name: formData.name,
        sub_company: sessionStorage.getItem('company_id'),
        password: newPassword,
        role: "Jobapplicant"
      }




      try {
        // let res1 = await axios.post(`${BASE_URL}/user/register/`, Registeter)

        // let res2 = await axios.post(`${BASE_URL}/userdata/userdata/`, updatedPersonalFormData,)
        let res4 = await axios.post(`${BASE_URL}/jd/jobapplicant/`, updatedJobApplicant,)

        let res5 = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (posData == false) {
          let res3 = await axios.post(`${BASE_URL}/userdata/positiondata/`, updatedPositionFormData);
        }


        if (
          // res1.status === 200
          // && res2.status === 200
          res4.status === 200
          && res5.status === 200
          // res4.status === 200
        ) {
          handleClose()
          // await getOnboarding()
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };




  const [jdid, setJdid] = useState()
  const [jdemail, setJdemail] = useState()
  const [docData, setDocData] = useState([])
  // const [jdposition, setJdposition] = useState()
  const getUserDocs = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const ress = await axios.get(`${BASE_URL}/userdata/userdocsfilter/${formData.email}/`);
      setDocData(ress.data);
    } catch (err) {
      // //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  useEffect(() => {



    const getUserPositionDetails = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {
        const res = await axios.get(`${BASE_URL}/userdata/positiondata/${formData.email}/`)
        // setFormData(res.data);
        setFormData({
          ...formData,
          currentCTC: res.data.currentCTC,
          monthlyinhand: res.data.monthlyinhand,
          expectedCTC: res.data.expectedCTC,
          yearofExp: res.data.yearofExp,
        });
        if (res.status === 200) {
          setPosData(true)
        }
        else {
          setPosData(false)
        }

        // setDataFetched(true);
      } catch (err) {
        // //toast Logic
        // if (err.response) {
        //   const errorData = err.response.data;

        //   if (typeof errorData.error === 'string') {
        //     // Single error message
        //     toast.error(`Error: ${errorData.error}`);
        //   } else if (typeof errorData === 'object' && errorData !== null) {
        //     // Multiple error messages
        //     Object.entries(errorData).forEach(([field, messages]) => {
        //       messages.forEach(message => toast.error(`"${field}": ${message}`));
        //     });
        //   } else {
        //     toast.error('Error:- Failed to Process!');
        //   }
        // } else {
        //   toast.error('Error processing your request.');
        // }
      } finally {
        setBuffering(false);// End Buffering
      }
    };

    const getUserJobDetails = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {

        const ress = await axios.get(`${BASE_URL}/jd/jobapplicant/${formData.id}/${formData.email}/`)

        setJdemail(ress.data.user_detail);
        setJdid(ress.data.jobpostdetails)


      } catch (err) {
        // //toast Logic
        // if (err.response) {
        //   const errorData = err.response.data;

        //   if (typeof errorData.error === 'string') {
        //     // Single error message
        //     toast.error(`Error: ${errorData.error}`);
        //   } else if (typeof errorData === 'object' && errorData !== null) {
        //     // Multiple error messages
        //     Object.entries(errorData).forEach(([field, messages]) => {
        //       messages.forEach(message => toast.error(`"${field}": ${message}`));
        //     });
        //   } else {
        //     toast.error('Error:- Failed to Process!');
        //   }
        // } else {
        //   toast.error('Error processing your request.');
        // }
      } finally {
        setBuffering(false);// End Buffering
      }
    };



    getUserDocs();
    getUserPositionDetails();
    getUserJobDetails();
  }, [formData.email, formData.id, jdid, jdemail]);

  return (
    <>

      <button className="models-button model-add" onClick={handleShow} style={{ margin: "2px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {buffer ? <div className="spinner-z"></div> : null}{/* buffering logic */}
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          {/*  ************** timeline Seaction  ************** */}

          <>


            <form className="register-form">
              <div className="form-flex-wrap">

                <div className="flex-column">

                  <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                    Name<span className="required">*</span>
                  </label>

                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Candidate name"
                    onChange={handleInputChange}
                    value={formData.name}
                    style={{ textTransform: "capitalize" }}
                    className={`form-text-trasformation-uppercase form-input ${errors.name
                      ? "error"
                      : inputState.name
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.name && (
                    <span className="error-message font-size-text ">{errors.name}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor="email" className="form-labels  font-weight500    font-size-subheading">
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    autoComplete="off"
                    name="email"
                    placeholder="Demo@gmail.com"
                    onChange={handleInputChange}
                    value={formData.email}
                    onInput={(e) => {
                      e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                    }}
                    className={`form-input ${errors.email
                      ? "error"
                      : inputState.email
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.email && (
                    <span className="error-message font-size-text ">{errors.email}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                    Mobile<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    id="mobile"
                    name="mobile"
                    placeholder="99999 88888"
                    onChange={handleInputChange}
                    value={formData.mobile}
                    min="0"
                    className={`form-input ${errors.mobile ? "error" : inputState.mobile ? "success" : ""
                      }`}
                  />
                  {errors.mobile && (
                    <span className="error-message font-size-text ">{errors.mobile}</span>
                  )}
                </div>


                <div className="flex-column form-group-selectt">
                  <label htmlFor="department" className="form-labels  font-weight500    font-size-subheading">
                    Applying For<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="department"
                    name="id"
                    value={formData.id}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                      }`}
                  >
                    <option value="">Available post</option>
                    {/* {formData.id?
                    <option value={formData.id}>{formData.id}</option>
                    :<></>
                    } */}
                    {/* {jobposition?.sort((a, b) => a.department_name.localeCompare(b.department_name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.department_name} - {i.position_name}
                      </option>
                    </>))
                    } */}
                    {jobposition
                      // .filter(i => i.status === 'active')
                      .sort((a, b) => a.department_name.localeCompare(b.department_name, 'en', { ignorePunctuation: true }))
                      .map((i, index) => (
                        <option value={`${i.id}`}>
                          {i.department_name} - {i.position_name}
                        </option>
                      ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.department && (
                      <span className="error-message font-size-text ">{errors.department}</span>
                    )}
                  </div>
                </div>


                {/* <div className="flex-column form-group-selectt">
                  <label htmlFor="department" className="form-labels  font-weight500    font-size-subheading">
                    Department<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="department"
                    name="department"
                    value={jobposition}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                      }`}
                  >
                    <option value="">Select Department</option>
                    {jobposition?.sort((a, b) => a.department_name.localeCompare(b.department_name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.department_name}</option>
                    </>))
                    }
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.department && (
                      <span className="error-message font-size-text ">{errors.department}</span>
                    )}
                  </div>
                </div> */}

                {/* <div className="flex-column form-group-selectt">
                  <label htmlFor="designation" className="form-labels  font-weight500    font-size-subheading">
                    Designation<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="designation"
                    name="designation"
                    value={jobposition}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                      }`}
                  >
                    <option value="">Select Designation</option>
                    {jobposition?.sort((a, b) => a.position_name.localeCompare(b.position_name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                      <option value={i.id}>{i.position_name}</option>
                    </>))
                    }
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.designation && (
                      <span className="error-message font-size-text ">{errors.designation}</span>
                    )}
                  </div>
                </div> */}



                <div className="flex-column">
                  <label htmlFor="currentCTC" className="form-labels  font-weight500    font-size-subheading">
                    Monthly Current CTC<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    id="currentCTC"
                    name="currentCTC"
                    placeholder="Enter your current CTC"
                    onChange={handleInputChange}
                    min={0}
                    step="1"
                    readOnly={posData ? true : false}
                    value={formData.currentCTC}
                    onKeyPress={(e) => {
                      // Prevent the entry of decimal points
                      if (e.key === '.' || e.key === 'e' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    className={`form-input ${errors.currentCTC ? "error" : inputState.currentCTC ? "success" : ""}`}
                  />
                  {errors.currentCTC && (
                    <span className="error-message font-size-text ">{errors.currentCTC}</span>
                  )}
                </div>




                <div className="flex-column">
                  <label htmlFor="monthlyinhand" className="form-labels  font-weight500    font-size-subheading">
                    Monthly Current Inhand<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    id="monthlyinhand"
                    name="monthlyinhand"
                    placeholder="Enter your monthly Inhand"
                    onChange={handleInputChange}
                    value={formData.monthlyinhand}
                    step="1"
                    min={0}

                    readOnly={posData ? true : false}

                    onKeyPress={(e) => {
                      // Prevent the entry of decimal points
                      if (e.key === '.' || e.key === 'e' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    className={`form-input ${errors.monthlyinhand ? "error" : inputState.monthlyinhand ? "success" : ""}`}
                  />
                  {errors.monthlyinhand && (
                    <span className="error-message font-size-text ">{errors.monthlyinhand}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor="expectedCTC" className="form-labels  font-weight500    font-size-subheading">
                    Monthly Expected CTC<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    id="expectedCTC"
                    name="expectedCTC"
                    placeholder="Enter your expected CTC"
                    onChange={handleInputChange}
                    value={formData.expectedCTC}
                    readOnly={posData ? true : false}

                    step="1"
                    min={0}
                    onKeyPress={(e) => {
                      // Prevent the entry of decimal points
                      if (e.key === '.' || e.key === 'e' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    className={`form-input ${errors.expectedCTC ? "error" : inputState.expectedCTC ? "success" : ""}`}
                  />
                  {errors.expectedCTC && (
                    <span className="error-message font-size-text ">{errors.expectedCTC}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor="yearofExp" className="form-labels  font-weight500    font-size-subheading">
                    Year of Experience<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    id="yearofExp"
                    name="yearofExp"
                    placeholder="Enter your Your Experience"
                    onChange={handleInputChange}
                    readOnly={posData ? true : false}

                    // step="1"
                    min={0}
                    value={formData.yearofExp}
                    className={`form-input ${errors.yearofExp ? "error" : inputState.yearofExp ? "success" : ""}`}
                  />
                  {errors.yearofExp && (
                    <span className="error-message font-size-text ">{errors.yearofExp}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor="remarks" className="form-labels  font-weight500    font-size-subheading">
                    Remarks<span className="required"></span>
                  </label>
                  <textarea
                    type="text"
                    id="remarks"
                    name="remarks"
                    placeholder="Remarks"
                    onChange={handleInputChange}
                    maxLength={200}
                    value={formData.remarks}
                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.remarks
                      ? "error"
                      : inputState.remarks
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.remarks && (
                    <span className="error-message font-size-text ">{errors.remarks}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor="current_address" className="form-labels  font-weight500    font-size-subheading">
                    Current Address<span className="required">*</span>
                  </label>
                  <textarea
                    type="text"
                    id="current_address"
                    name="current_address"
                    placeholder="Current Address"
                    onChange={handleInputChange}
                    value={formData.current_address}
                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.current_address
                      ? "error"
                      : inputState.current_address
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.current_address && (
                    <span className="error-message font-size-text ">{errors.current_address}</span>
                  )}
                </div>

                {docData.find(document => document.document_name === 'CV') ?
                  <div className="document-item1" >
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{docData.find(document => document.document_name === 'CV').document_name}</div>
                          <div className="document-upload-date font-size-subheading  ">
                            Doc Type <b>{docData.find(document => document.document_name === 'CV').document_type}</b>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', }}>
                        <a style={{ marginLeft: '10px' }} href={docData.find(document => document.document_name === 'CV').document_file} target="_blank" className="document-download-button  width-5vw  width-5vw">
                          View
                        </a>
                      </div>
                    </div>
                  </div> :

                  (<div className="flex-column">
                    <label htmlFor="bestAchieve" className="form-labels  font-weight500    font-size-subheading">
                      Upload CV<span className="required">*</span>
                    </label>

                    <div className="" key={"index"}>
                      <div className="document-icons">
                        <div className="document-name-div">
                          <img
                            src={Docs}
                            alt="Document Icon"
                            className="document-icon"
                          />
                          <div>
                            <div className="document-name">CV</div>
                            <input
                              id="file"
                              type="file"
                              name="document_file"
                              accept=".pdf"
                              onChange={handleFileChange}
                              className="file-input"
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>)
                }
                {errors.document_file && (
                  <span className="error-message font-size-text ">{errors.document_file}</span>
                )}

              </div>

              <div className="button-models">

                {(jdid === formData.id && jdemail === formData.email) ?
                  <span className="required">Details For this Email and this Job Post Already Exists</span>
                  :
                  ((formData.document_file) || docData.find(document => document.document_name === 'CV') ?
                    <>
                      <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading}>
                        Submit
                      </button>{/*//loading logic */}
                    </>
                    : "")
                }

              </div>
            </form></>

        </Modal.Body>
      </Modal>

    </>
  );
};

export default ResumeAddmodel;
