import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {
  Department,
  DepartmentUpdate,
  DepartmentDelete,
  DesignationName,
  DesignationUpdate,
  DesignationDelete,
  Subcompanys,
  Subcompanyupdate,
  SubcompanyDelete,
  AttendancePolicy,
  AttendancePolicyUpdate,
  AttendancePolicyDelete,
  Woksiteadd,
  Woksiteupdate,
  WoksiteDelete,
  CalenderModel,
  CalenderUpdate,
  CalenderDelete,
  InterCom,
  InterComUpdate,
  InterComDelete,
  WeeklyOffPolicy,
  WeeklyOffPolicyDelete,
  WeeklyOffPolicyUpdate,
  EsslList,
  EsslUpdate,
  EsslDelete,
  SalaryCalculationPolicyAdd,
  SalaryCalculationPolicyUpdate,
  SalaryCalculationPolicyDelete,
  SalaryStructureHeadingAdd,
  SalaryStructureHeadingUpdate,
  SalaryStructureHeadingDelete,
  SalaryDeductionPolicyUpdate,
  SalaryDeductionPolicyDelete,
  SalaryDeductionPolicyAdd,
  InvestmentHeadingAdd,
  InvestmentHeadingUpdate,
  InvestmentHeadingDelete,
} from "./Wfmmodels";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { formattedDateLong } from "../Date";
import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import { customSortByKey, handleErrorToast } from "../CustomFunctions";
import { Arrow, Office, Search, SearchBig, SearchSmall } from "../AllSvg";

const DepartmentTable = () => {
  const [departmentname, setDepartmentname] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getDepartment = async () => {
    setBuffering(true); // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/departmentwfm/`);
      const res = await axios.get(PERMISSION_AUTHORITY ?
        `${BASE_URL}/wfm/departmentfilterbysubcompanycount/${toggleCompany}/active/`
        :
        `${BASE_URL}/wfm/departmentfilterbysubcompanycount/${toggleCompany}/active/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/910ee6d1-9fd8-4da1-a9cb-369b1ffecd7c/active/`);
      setDepartmentname(res.data);
    } catch (err) {

      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }

    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    getDepartment();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Department</div>
        <div className="field-cont">

          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select Sub Company</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }
          <div className="table-searchh field-cont-div">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <Department
            getDepartment={getDepartment}
            departmentname={departmentname}
          />
        </div>
      </div>

      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Department</th>
            <th className="align-center">Company</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> :
          <tbody>
            {departmentname
              ?.filter((e) =>
                e.title.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .sort((a, b) =>
                a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.title}</td>
                    <td className="align-center">{i.sub_company_name}</td>

                    <td className="align-center">{i?.employee_count}</td>
                    {/* <td className="align-center">View </td> */}
                    <td className="align-center">
                      <EmployeebyDepartmentTable department={i} />{" "}
                    </td>
                    <td className="align-right width-5vw ">
                      <DepartmentUpdate
                        i={i}
                        getDepartment={getDepartment}
                        departmentname={departmentname}
                      />

                      <DepartmentDelete i={i} getDepartment={getDepartment} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const EmployeebyDepartmentTable = ({ department }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getDepartmentEmployees(department.id);
  };

  const [departmentEmployees, setDepartmentEmployees] = useState([]);

  const getDepartmentEmployees = async (department) => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeedetailsbydepartment/${department}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");

      setDepartmentEmployees(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[3]
  // console.log("url")
  // console.log(url)
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  Employees in : <br />
                  {department.title}
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                  <tbody>
                    {departmentEmployees
                      ?.filter(
                        (e) =>
                          e.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          e.emp_code
                            .toString()
                            .includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-center">{i.emp_code}</td>
                            <td className="align-center">{i.name}</td>

                            <td className="align-center">
                              <button
                                className="employee-edit"
                                onClick={() =>
                                  Viewdetails(i.emp_code, i.user_detail)
                                }
                                title="View Profile"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                  <path
                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                    fill="#2576BC"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                }
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const DesignationTable = () => {
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [designationname, setDesignationname] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(PERMISSION_AUTHORITY ? "null" :
    sessionStorage.getItem("company_id")
  );
  const [searchQuery, setSearchQuery] = useState("");

  const getDesignation = async () => {
    setBuffering(true);
    try {
      const res = await axios.get(
        PERMISSION_AUTHORITY ?
          `${BASE_URL}/wfm/designationfilterbysubcompanycount/${toggleCompany}/active/`
          :
          `${BASE_URL}/wfm/designationfilterbysubcompanycount/${toggleCompany}/active/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/designationwfm/`);
      setDesignationname(res.data);
      setBuffering(false);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    getDesignation();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Designation</div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select Sub Company</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }
          <div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <DesignationName
            getDesignation={getDesignation}
            designationname={designationname}
          />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Designation</th>
            <th className="align-center">Company</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> :

          <tbody>
            {designationname
              ?.filter((e) =>
                e.title.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .sort((a, b) =>
                a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.title}</td>
                    <td className="align-center">{i.sub_company_name}</td>
                    <td className="align-center">{i.employee_count}</td>
                    <td className="align-center">
                      <EmployeebyDesignationTable designation={i} />
                    </td>
                    <td className="align-right width-5vw ">
                      <DesignationUpdate
                        i={i}
                        getDesignation={getDesignation}
                        designationname={designationname}
                      />

                      <DesignationDelete i={i} getDesignation={getDesignation} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>}
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const EmployeebyDesignationTable = ({ designation }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getDesignationEmployees(designation.id);
  };
  const [designationEmployees, setDesignationEmployees] = useState([]);

  const getDesignationEmployees = async (designationId) => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeedetailsbydesignation/${designationId}/`
      );
      setDesignationEmployees(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[3]
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  Employees as : <br />
                  {designation.title}
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                  <tbody>
                    {designationEmployees
                      ?.filter(
                        (e) =>
                          e.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          e.emp_code
                            .toString()
                            .includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-center">{i.emp_code}</td>
                            <td className="align-center">{i.name}</td>

                            <td className="align-center">
                              <button
                                className="employee-edit"
                                onClick={() =>
                                  Viewdetails(i.emp_code, i.user_detail)
                                }
                                title="View Profile"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                  <path
                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                    fill="#2576BC"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>}
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const Subcompany = () => {
  const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [SubCompany, setSubCompany] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSubCompany = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/subcompanywfm/`);
      // const res = await axios.get(`${BASE_URL}/wfm/subcompanyfilterbysubcompanycount/${sessionStorage.getItem('company_id')}/active/`);
      const res = await axios.get(
        `${BASE_URL}/wfm/subcompanyfilterbysubcompanycount/null/active/`
      );
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getSubCompany();
  }, []);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Sub Company</div>
        <div className="flex-row">
          <div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          {PERMISSION_AUTHORITY ? (
            <Subcompanys getSubCompany={getSubCompany} />
          ) : null}
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Company Name</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {SubCompany?.filter((e) =>
              e.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
              .sort((a, b) =>
                a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.title}</td>
                    <td className="align-center">{i.employee_count}</td>
                    <td className="align-center">
                      <EmployeebySubCompanyTable subcompany={i} />
                    </td>
                    <td className="align-right width-5vw ">
                      <Subcompanyupdate i={i} getSubCompany={getSubCompany} />

                      {PERMISSION_AUTHORITY ? <SubcompanyDelete i={i} getSubCompany={getSubCompany} /> : null}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const EmployeebySubCompanyTable = ({ subcompany }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getSubCompanyEmployees(subcompany.id);
  };

  const [subcompanyEmployees, setSubCompanyEmployees] = useState([]);

  const getSubCompanyEmployees = async (subcompanyId) => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeedetailsbysubcompany/${subcompanyId}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");

      setSubCompanyEmployees(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getSubCompanyEmployees();
  // }, []);

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[3]
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  Employees in : <br />
                  {subcompany.title}
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                  <tbody>
                    {subcompanyEmployees
                      ?.filter(
                        (e) =>
                          e.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          e.emp_code
                            .toString()
                            .includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-center">{i.emp_code}</td>
                            <td className="align-center">{i.name}</td>

                            <td className="align-center">
                              <button
                                className="employee-edit"
                                onClick={() =>
                                  Viewdetails(i.emp_code, i.user_detail)
                                }
                                title="View Profile"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                  <path
                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                    fill="#2576BC"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                }
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const Worksite = () => {
  const [worksite, setWorksite] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState("null");
  const [buffer, setBuffering] = useState(true); //buffering logic


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getWoksiteList = async () => {
    try {
      setBuffering(true); //buffering logic // Start Buffering 
      // const res = await axios.get(`${BASE_URL}/wfm/worksitewfm/`);
      // const res = await axios.get(`${BASE_URL}/wfm/worksitefilterbysubcompanycount/${sessionStorage.getItem('company_id')}/active/`);
      const res = await axios.get(
        PERMISSION_AUTHORITY ?
          `${BASE_URL}/wfm/worksitefilterbysubcompanycount/${toggleCompany}/active/`
          :
          `${BASE_URL}/wfm/worksitefilterbysubcompanycount/${toggleCompany}/active/`
      );
      setWorksite(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };
  useEffect(() => {
    getWoksiteList();
  }, [toggleCompany]);
  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Work Site</div>
        <div className="field-cont">

          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select Sub Company</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }<div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <Woksiteadd getWoksiteList={getWoksiteList} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Woksite Name</th>
            <th className="align-center">Company</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {worksite
              ?.filter((e) =>
                e.title.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .sort((a, b) =>
                a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.title}</td>
                    <td className="align-center">{i.sub_company_name}</td>
                    <td className="align-center">{i.employee_count}</td>
                    <td className="align-center">
                      <EmployeebyWorkSiteTable worksite={i} />
                    </td>
                    <td className="align-right width-5vw ">
                      <Woksiteupdate i={i} getWoksiteList={getWoksiteList} />

                      <WoksiteDelete i={i} getWoksiteList={getWoksiteList} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const EmployeebyWorkSiteTable = ({ worksite }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getWorkSiteEmployees(worksite.id);
  };

  const [worksiteEmployees, setWorkSiteEmployees] = useState([]);

  const getWorkSiteEmployees = async (worksiteId) => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeedetailsbyworksite/${worksiteId}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");

      setWorkSiteEmployees(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getWorkSiteEmployees();
  // }, []);

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[3]
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  Employees in : <br /> {worksite.title}{" "}
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                  <tbody>
                    {worksiteEmployees
                      ?.filter(
                        (e) =>
                          e.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          e.emp_code
                            .toString()
                            .includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-center">{i.emp_code}</td>
                            <td className="align-center">{i.name}</td>

                            <td className="align-center">
                              <button
                                className="employee-edit"
                                onClick={() =>
                                  Viewdetails(i.emp_code, i.user_detail)
                                }
                                title="View Profile"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                  <path
                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                    fill="#2576BC"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                }
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const AttendancePolicyTable = () => {
  const [attpolicyname, setAttPolicyname] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getAttPolicyName = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        PERMISSION_AUTHORITY ?
          `${BASE_URL}/wfm/attendancepolicyfilterall/${toggleCompany}/active/`
          :
          `${BASE_URL}/wfm/attendancepolicyfilterall/${sessionStorage.getItem(
            "company_id"
          )}/active/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/attendancepolicy/`);
      setAttPolicyname(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getAttPolicyName();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Attendance Policy</div>
        <div className="field-cont">

          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <Office />
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select Sub Company</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }
          <div className="table-searchh field-cont-div">
            <div>
              <SearchBig />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <AttendancePolicy getAttPolicyName={getAttPolicyName} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Name</th>
            <th className="align-center">Company</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {attpolicyname
              ?.filter((e) =>
                e.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .sort((a, b) =>
                a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.name}</td>
                    <td className="align-center">{i.sub_company_title}</td>
                    <td className="align-center">{i?.employee_count}</td>
                    <td className="align-center">
                      <EmployeebyAttendancePolicyTable policy={i} />
                    </td>
                    <td className="align-right width-5vw ">
                      <AttendancePolicyUpdate
                        i={i}
                        getAttPolicyName={getAttPolicyName}
                      />

                      <AttendancePolicyDelete
                        i={i}
                        getAttPolicyName={getAttPolicyName}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const EmployeebyAttendancePolicyTable = ({ policy }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getAttendancePolicyEmployees(policy.id);
  };
  const [attendancePolicyEmployees, setAttendancePolicyEmployees] = useState(
    []
  );

  const getAttendancePolicyEmployees = async (policyId) => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeedetailsbyattendancepolicy/${policyId}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");

      setAttendancePolicyEmployees(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[3]
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  Employees with : <br /> {policy.name} Policy
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <SearchBig />
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                  <tbody>
                    {attendancePolicyEmployees
                      ?.filter(
                        (e) =>
                          e.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          e.emp_code
                            .toString()
                            .includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-center">{i.emp_code}</td>
                            <td className="align-center">{i.name}</td>
                            <td className="align-center">
                              <button
                                className="employee-edit"
                                onClick={() =>
                                  Viewdetails(i.emp_code, i.user_detail)
                                }
                                title="View Profile"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                  <path
                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                    fill="#2576BC"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                }
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const WeeklyOffPolicyTable = () => {
  const [WeeklyOffpolicyname, setWeeklyOffPolicyname] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getWeeklyOffPolicyName = async (PERMISSION_AUTHORITY) => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        PERMISSION_AUTHORITY ?
          `${BASE_URL}/wfm/weeklyoffpolicyfilterall/${toggleCompany}/active/`
          :
          `${BASE_URL}/wfm/weeklyoffpolicyfilterall/${sessionStorage.getItem(
            "company_id"
          )}/active/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/weeklyoffPolicy/`);
      setWeeklyOffPolicyname(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getWeeklyOffPolicyName(PERMISSION_AUTHORITY);
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Weekly Off Policy</div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <Office />
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select Sub Company</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }          <div className="table-searchh">
            <div>
              <SearchBig />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <WeeklyOffPolicy getWeeklyOffPolicyName={getWeeklyOffPolicyName} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Name</th>
            <th className="align-center">Company</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {WeeklyOffpolicyname?.filter((e) =>
              e.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
              .sort((a, b) =>
                a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.name}</td>
                    <td className="align-center">{i.sub_company_title}</td>
                    <td className="align-center">{i?.employee_count}</td>
                    <td className="align-center">
                      <EmployeebyWeeklyOffPolicyTable policy={i} />
                    </td>
                    <td className="align-right width-5vw ">
                      <WeeklyOffPolicyUpdate
                        i={i}
                        getWeeklyOffPolicyName={getWeeklyOffPolicyName}
                      />

                      <WeeklyOffPolicyDelete
                        i={i}
                        getWeeklyOffPolicyName={getWeeklyOffPolicyName}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const EmployeebyWeeklyOffPolicyTable = ({ policy }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getWeeklyOffPolicyEmployees(policy.id);
  };
  const [weekOffPolicyEmployees, setWeekOffPolicyEmployees] = useState([]);

  const getWeeklyOffPolicyEmployees = async (policyId) => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeedetailsbyweeklyoffpolicy/${policyId}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");

      setWeekOffPolicyEmployees(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getWeeklyOffPolicyEmployees();
  // }, []);

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[3]
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees List"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  Employees with : <br /> {policy.name} Policy
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <SearchBig />

                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                <tbody>
                  {weekOffPolicyEmployees
                    ?.filter(
                      (e) =>
                        e.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        e.emp_code
                          .toString()
                          .includes(searchQuery.toLowerCase())
                    )
                    .sort((a, b) => a.emp_code - b.emp_code)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.emp_code}</td>
                          <td className="align-center">{i.name}</td>
                          <td className="align-center">
                            <button
                              className="employee-edit"
                              onClick={() =>
                                Viewdetails(i.emp_code, i.user_detail)
                              }
                              title="View Profile"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const SalaryStructureHeadingTable = () => {
  const [salaryStructureHeadings, setSalaryStructureHeadings] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSalaryStructureHeadings = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(`${BASE_URL}/wfm/salaryHeadingbysubcompany/${toggleCompany}/`);
      setSalaryStructureHeadings(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getSalaryStructureHeadings();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Salary Structure Headings</div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <Office />
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select SubCompany</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }
          <div className="table-searchh">
            <div>
              <SearchSmall />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <SalaryStructureHeadingAdd salaryStructureHeadings={salaryStructureHeadings} getSalaryStructureHeadings={getSalaryStructureHeadings} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr. No.</th>
            <th className="align-center">Position No.</th>
            <th className="align-center">Name</th>
            <th className="align-center">Type</th>
            <th className="align-right">Company</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {salaryStructureHeadings?.filter((e) =>
              e.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
              .sort((a, b) => a.sorting_var - b.sorting_var)
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.sorting_var}</td>
                    <td className="align-center">{i.title}</td>
                    <td className="align-center form-text-trasformation-uppercase">{i.salary_type}</td>
                    <td className="align-right">{i.sub_company_title}</td>
                    <td className="align-right width-5vw ">
                      <SalaryStructureHeadingUpdate
                        i={i}
                        salaryStructureHeadings={salaryStructureHeadings}
                        getSalaryStructureHeadings={getSalaryStructureHeadings}
                      />
                      {/* <SalaryStructureHeadingDelete
                        i={i}
                        getSalaryStructureHeadings={getSalaryStructureHeadings}
                      /> */}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};


const SalaryCalculationPolicyTable = () => {
  const [salaryCalculationPolicies, setSalaryCalculationPolicy] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSalaryCalculationPolicyList = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(`${BASE_URL}/wfm/salarypolicybysubcompany/${toggleCompany}/`);
      setSalaryCalculationPolicy(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getSalaryCalculationPolicyList();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Salary Calculation Policies</div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <Office />
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select SubCompany</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }
          <div className="table-searchh">
            <div>
              <SearchSmall />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <SalaryCalculationPolicyAdd getSalaryCalculationPolicyList={getSalaryCalculationPolicyList} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr. No.</th>
            <th className="align-center">Company</th>
            <th className="align-center">Name</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {salaryCalculationPolicies?.filter((e) =>
              e.policy_name?.toLowerCase().includes(searchQuery.toLowerCase())
            )
              .sort((a, b) =>
                a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.sub_company_title}</td>
                    <td className="align-center">{i.policy_name}</td>
                    <td className="align-right width-5vw ">
                      <SalaryCalculationPolicyUpdate
                        i={i}
                        getSalaryCalculationPolicyList={getSalaryCalculationPolicyList}
                      />
                      <SalaryCalculationPolicyDelete
                        i={i}
                        getSalaryCalculationPolicyList={getSalaryCalculationPolicyList}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};



const SalaryDeductionPolicyTable = () => {
  const [salaryDeductionPolicies, setSalaryDeductionPolicy] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSalaryDeductionPolicyList = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(`${BASE_URL}/wfm/salarypolicybysubcompany/${toggleCompany}/`);
      setSalaryDeductionPolicy(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getSalaryDeductionPolicyList();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Salary Deduction Policies</div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <Office />
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select SubCompany</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

            </select>
          </div> : null
          }
          <div className="table-searchh">
            <div>
              <SearchSmall />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <SalaryDeductionPolicyAdd getSalaryDeductionPolicyList={getSalaryDeductionPolicyList} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr. No.</th>
            <th className="align-center">Company</th>
            <th className="align-center">Name</th>
            {/* <th className="align-center">Employee Count</th> */}
            {/* <th className="align-center">Employee View</th> */}
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {salaryDeductionPolicies?.filter((e) =>
              e.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
              .sort((a, b) =>
                a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.sub_company_title}</td>
                    <td className="align-center">{i.name}</td>

                    <td className="align-right width-5vw ">
                      <SalaryDeductionPolicyUpdate
                        i={i}
                        getSalaryDeductionPolicyList={getSalaryDeductionPolicyList}
                      />
                      <SalaryDeductionPolicyDelete
                        i={i}
                        getSalaryDeductionPolicyList={getSalaryDeductionPolicyList}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const InvestmentHeadingTable = () => {
  const [investmentHeadings, setInvestmentHeadings] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getInvestmentHeadings = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(`${BASE_URL}/wfm/investmentheadingbysub_company/${toggleCompany}/`);
      setInvestmentHeadings(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getInvestmentHeadings();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500 font-size-heading">Investment Headings</div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <Office />
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select SubCompany</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
            </select>
          </div> : null
          }
          <div className="table-searchh">
            <div>
              <SearchSmall />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <InvestmentHeadingAdd getInvestmentHeadings={getInvestmentHeadings} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr. No.</th>
            <th className="align-center">Company</th>
            <th className="align-center">Name</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {investmentHeadings?.filter((e) =>
              e.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
              .sort((a, b) =>
                a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.sub_company_title}</td>
                    <td className="align-center">{i.title}</td>

                    <td className="align-right width-5vw ">
                      <InvestmentHeadingUpdate
                        i={i}
                        getInvestmentHeadings={getInvestmentHeadings}
                      />
                      <InvestmentHeadingDelete
                        i={i}
                        getInvestmentHeadings={getInvestmentHeadings}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const CalenderTable = () => {
  const [calendername, setCalendername] = useState([]);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getcalender = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(PERMISSION_AUTHORITY ?
        `${BASE_URL}/wfm/calenderfilterbysubcompany/${toggleCompany}/`
        :
        `${BASE_URL}/wfm/calenderfilterbysubcompany/${sessionStorage.getItem(
          "company_id"
        )}/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/calander/`);
      setCalendername(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getcalender();
  }, [toggleCompany]);

  return (
    <>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Calender </div>
          <div className="field-cont">
            {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
              <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
                <option value="null">Select Sub Company</option>
                {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

              </select>
            </div> : null
            }
            <div className="table-searchh">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                  fill="none"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="4.3"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.0101"
                    y1="11"
                    x2="8"
                    y2="8.98995"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div>
                <input
                  placeholder="Search"
                  className="navbar-inputt font-weight400  font-size-text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="date"
                />
              </div>
            </div>
            <CalenderModel getcalender={getcalender} />
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">Company</th>
              <th className="align-center">Date</th>
              <th className="align-center">Description</th>
              <th className="align-center">Type</th>
              <th className="align-right width-10vw">Action</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {calendername
                ?.filter((e) =>
                  e.date.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{i.sub_company_title}</td>
                      <td className="align-center">
                        {formattedDateLong(i.date)}
                      </td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.offtype}
                      </td>
                      <td className="align-center">{i.descritpion}</td>
                      <td className="align-right width-5vw ">
                        <CalenderUpdate i={i} getcalender={getcalender} />
                        <CalenderDelete i={i} getcalender={getcalender} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const TlCalenderTable = () => {
  const [calendername, setCalendername] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getcalender = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/calenderfilterbysubcompany/${sessionStorage.getItem(
          "company_id"
        )}/`
      );
      setCalendername(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getcalender();
  }, []);

  return (
    <>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Calender </div>
          <div>{/* <CalenderModel getcalender={getcalender} /> */}</div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">Company</th>
              <th className="align-center">Date</th>
              <th className="align-center">Description</th>
              <th className="align-center">Type</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {calendername
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{i.sub_company_title}</td>
                      <td className="align-center">
                        {formattedDateLong(i.date)}
                      </td>
                      <td className="align-right width-10vw form-text-trasformation-uppercase">
                        {i.offtype}
                      </td>
                      <td className="align-center">{i.descritpion}</td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const InterComTable = () => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [interCom, setInterCom] = useState([]);

  const getintercom = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(PERMISSION_AUTHORITY ?
        `${BASE_URL}/wfm/intercomfilterbysubcompany/${toggleCompany}/`
        :
        `${BASE_URL}/wfm/intercomfilterbysubcompany/${toggleCompany}/`
      );
      const sortedList = customSortByKey(res.data, "user_detail");
      // const res = await axios.get(`${BASE_URL}/wfm/intercom/`);
      // console.log(sortedList)
      setInterCom(sortedList);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };


  const [empList, setEmpList] = useState([])
  const getAllEmp_code = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem('company_id')}/`
      );

      const sortedList = customSortByKey(res.data, "emp_code");

      setEmpList(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getAllEmp_code();
    getintercom();
  }, [toggleCompany]);

  console.log(interCom)

  return (
    <>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">InterCom </div>
          <div className="field-cont">
            {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
              <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
                <option value="null">Select Sub Company</option>
                {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

              </select>
            </div> : null
            }
            <div className="table-searchh">
              <div>
                <SearchBig />
                <input
                  placeholder="Search Extension No."
                  className="navbar-inputt font-weight400  font-size-text"
                  value={searchQuery}
                  min={0}
                  onChange={handleSearchChange}
                  type="number"
                />
              </div>
            </div>
            <InterCom getintercom={getintercom} employeeList={empList} />
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">Extension no.</th>
              <th className="align-center">Company</th>
              <th className="align-center">User Detail</th>
              <th className="align-center">Department : Designation</th>
              <th className="align-center">Work Email</th>
              <th className="align-center">Contact no.</th>
              <th className="align-right width-10vw">Action</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {interCom
                ?.filter((e) =>
                  e.extensionno.toString().includes(searchQuery.toLowerCase())
                )
                .sort((a, b) => a.extensionno - b.extensionno)
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{i.extensionno}</td>
                      <td className="align-center">{i.sub_company_title}</td>
                      <td className="align-center">
                        {i.user_detail}
                        <p>{i.name}</p>
                      </td>
                      <td className="align-center">
                        {i.department} : {i.designation}
                      </td>
                      <td className="align-center">{i.work_email}</td>
                      <td className="align-center">{i.contactno}</td>
                      <td className="align-right width-5vw ">
                        <InterComUpdate i={i} getintercom={getintercom} employeeList={empList} />
                        <InterComDelete i={i} getintercom={getintercom} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const TLintercomTable = () => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [interCom, setInterCom] = useState([]);

  const getintercom = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/intercomfilterbysubcompany/${sessionStorage.getItem(
          "company_id"
        )}/`
      );
      setInterCom(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getintercom();
  }, []);

  return (
    <>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">InterCom </div>
          <div></div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">Extension no.</th>
              <th className="align-center">Company</th>
              <th className="align-center">User Detail</th>
              <th className="align-center">Department : Designation</th>
              <th className="align-center">Work Email</th>
              <th className="align-center">Contact no.</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {interCom
                .sort((a, b) => a.extensionno - b.extensionno)
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{i.extensionno}</td>
                      <td className="align-center">{i.sub_company_title}</td>
                      <td className="align-center">
                        {i.user_detail}
                        <p>{i.name}</p>
                      </td>
                      <td className="align-center">
                        {i.department} : {i.designation}
                      </td>
                      <td className="align-center">{i.work_email}</td>
                      <td className="align-center">{i.contactno}</td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const EssLMachineTable = () => {
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [buffer, setBuffering] = useState(true); //buffering logic
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [esslList, setEssslList] = useState([]);

  const getEssl = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/esslfilterbysubcompany/${sessionStorage.getItem(
          "company_id"
        )}/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/esslList/`);
      setEssslList(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };
  const matchesSearchQuery = (item, query) => {
    const lowerCaseQuery = query.toLowerCase();
    return (
      item.name.toLowerCase().includes(lowerCaseQuery) ||
      item.url.toLowerCase().includes(lowerCaseQuery) ||
      item.serial_number.toLowerCase().includes(lowerCaseQuery) ||
      item.sub_company_title.toLowerCase().includes(lowerCaseQuery)
    );
  };

  useEffect(() => {
    getEssl();
  }, []);

  return (
    <>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Essl List </div>
          <div className="flex-row">
            <div className="table-searchh">
              <div>
                <SearchBig />
                <input
                  placeholder="Search"
                  className="navbar-inputt font-weight400  font-size-text"
                  value={searchQuery}
                  // min={0}
                  onChange={handleSearchChange}
                // type=""
                />
              </div>
            </div>
            {/* <EsslList getEssl={getEssl} /> */}
            {/* -+*9*- */}
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">Name</th>
              <th className="align-center">URL</th>
              <th className="align-center">Serial No.</th>
              <th className="align-center">SubCompany</th>
              <th className="align-right width-10vw">Action</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {esslList
                ?.filter((e) => matchesSearchQuery(e, searchQuery))
                .sort((a, b) =>
                  a.name
                    .toString()
                    .localeCompare(b.name, "en", { ignorePunctuation: true })
                )
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{i.name}</td>
                      <td className="align-center">{i.url}</td>
                      <td className="align-center">{i.serial_number}</td>
                      <td className="align-center">{i.sub_company_title}</td>
                      <td className="align-right width-5vw ">
                        {/* <EsslUpdate i={i} getEssl={getEssl} />
                    <EsslDelete i={i} getEssl={getEssl} /> */}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

export {
  DepartmentTable,
  DesignationTable,
  Subcompany,
  Worksite,
  AttendancePolicyTable,
  WeeklyOffPolicyTable,
  SalaryStructureHeadingTable,
  SalaryCalculationPolicyTable,
  SalaryDeductionPolicyTable,
  InvestmentHeadingTable,
  CalenderTable,
  TlCalenderTable,
  InterComTable,
  TLintercomTable,
  EmployeebyDepartmentTable,
  EssLMachineTable,
};
