import React, { useState } from "react";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";

import { ToastContainer, toast } from "react-toastify";
import { handleErrorToast } from "../CustomFunctions";
import { useLocation } from "react-router-dom";

import Expenses from "../UserDetails/Expenses";

const ImprestExpenseManagement = () => {
    const location = useLocation();
    const toggleexpense = location.state !== null ? location.state?.toggleexpense : 1;
    const expensetogglemanagenet = true;
    // const expensetogglemanagenet = toggleexpense;
    const emp_code = sessionStorage.getItem("emp_code")
    const email = sessionStorage.getItem("email")
    const [toggleState, setToggleState] = useState(toggleexpense);

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return "My Imprests";
            case 2:
                return "My Expenses";
            default:
                return "My Imprests";
        }
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Dashboardnavbarcopy
                // name={getSelectedTitle()}
                name={"My Imprests & Expenses"}
                url="Imprest & Expense Management"
            />

            <div className="content-tabs">
                <Expenses email={email} emp_code={emp_code} toggleexpense={toggleexpense} expensetogglemanagenet={expensetogglemanagenet} />
            </div>
        </>
    );
};

export default ImprestExpenseManagement;
