
import React, { useEffect, useState, useRef } from 'react';
import usePermission from '../../config/permissions';
import axios from 'axios';
import { BASE_URL, IMAGE_URL } from '../../config/axios';
import { ToastContainer, toast } from "react-toastify";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Modal from "react-bootstrap/Modal";
import { formattedDateLong, formattedDateNoYear } from '../Date';
import { useNavigate } from "react-router-dom";
import { AddProject, AddProjectImage, AddSector, AddSectorDescription, DeleteEnquiryContact, DeleteProject, DeleteSectorDescription, ReplyEnquirys, UpdateProject, UpdateSectorDescription } from './WebsiteButtons';


const SectorManagementTabs = () => {
    const [toggleState, setToggleState] = useState(1);
    return (
        <>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Sector Table
                        </button>

                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Sector Description Table
                        </button>
                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? <SectorsTable /> : null}
                    {toggleState === 2 ? <SectorsDescriptionTable /> : null}
                </div>
            </div>
        </>
    )
}

const SectorsTable = () => {
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { PERMISSION_AUTHORITY } = usePermission();
    const [sectorData, setSectorData] = useState([]);

    const getWebsiteSectors = async () => {
        setBuffering(true); // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/website/sectors/`);
            setSectorData(sub.data);

        } catch (err) {

            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getWebsiteSectors();
    }, [toggleCompany]);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Sectors</div>
                <div className="field-cont">

                    <AddSector getWebsiteSectors={getWebsiteSectors} sectorList={sectorData} />
                    {/* <AddSectorDescription getWebsiteSectors={getWebsiteSectors} sectorList={sectorData} /> */}
                    {/* <div className="table-searchh field-cont-div">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                            >
                                <circle
                                    cx="5"
                                    cy="5"
                                    r="4.3"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                />
                                <line
                                    x1="10.0101"
                                    y1="11"
                                    x2="8"
                                    y2="8.98995"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        <div>
                            <input
                                placeholder="Search"
                                className="navbar-inputt font-weight400  font-size-text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div> */}

                </div>
            </div>
            {/* <SectorsDetailForm /> */}
            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="align-left">ID</th>
                        <th className="align-center">Icon</th>
                        <th className="align-center">Name</th>
                        {/* <th className="align-center">Created By</th> */}
                        {/* <th className="align-center">Created At</th> */}

                        <th className="align-right">Action</th>
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> :
                    <tbody>
                        {sectorData
                            // ?.filter((e) =>
                            //     e.title.toLowerCase().includes(searchQuery.toLowerCase())
                            // )
                            // .sort((a, b) =>
                            //     a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                            // )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">{i.name}</td>
                                        <td className="align-center">

                                            {i.icon
                                                ? <img
                                                    className="img-table"
                                                    src={`${i.icon}`}
                                                    alt="Icon"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                                    <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                                    <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                                </svg>
                                            }

                                        </td>
                                        {/* <td className="align-center">{i.created_by}</td> */}
                                        {/* <td className="align-center">{i.created_at}</td> */}
                                        <td className="align-right flex-row ">

                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

const SectorsDescriptionTable = () => {
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { PERMISSION_AUTHORITY } = usePermission();
    const [sectorData, setSectorData] = useState([]);
    const [sectorDescData, setSectorDescata] = useState([]);

    const getWebsiteSectors = async () => {
        setBuffering(true); // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/website/sectors/`);
            setSectorData(sub.data);

            const sec = await axios.get(`${BASE_URL}/website/sectorsdetail/`);
            setSectorDescata(sec.data);

        } catch (err) {

            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getWebsiteSectors();
    }, [toggleCompany]);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Sector Descriptions</div>
                <div className="field-cont">

                    {/* <AddSector getWebsiteSectors={getWebsiteSectors} sectorList={sectorData} /> */}
                    <AddSectorDescription getWebsiteSectors={getWebsiteSectors} sectorList={sectorData} />
                    {/* <div className="table-searchh field-cont-div">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                            >
                                <circle
                                    cx="5"
                                    cy="5"
                                    r="4.3"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                />
                                <line
                                    x1="10.0101"
                                    y1="11"
                                    x2="8"
                                    y2="8.98995"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        <div>
                            <input
                                placeholder="Search"
                                className="navbar-inputt font-weight400  font-size-text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div> */}

                </div>
            </div>
            {/* <SectorsDetailForm /> */}
            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="align-left">ID</th>
                        <th className="align-center">Pics</th>
                        <th className="align-center">Name</th>
                        <th className="align-center">Description</th>
                        {/* <th className="align-center">Created By</th> */}
                        {/* <th className="align-center">Created At</th> */}

                        <th className="align-center ">Action</th>
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> :
                    <tbody>
                        {sectorDescData
                            // ?.filter((e) =>
                            //     e.title.toLowerCase().includes(searchQuery.toLowerCase())
                            // )
                            // .sort((a, b) =>
                            //     a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                            // )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">

                                            {i.pic1
                                                ? <img
                                                    className="img-table"
                                                    src={`${i.pic1}`}
                                                    alt="pic1"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                                    <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                                    <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                                </svg>
                                            }
                                            {i.pic2
                                                ? <img
                                                    className="img-table"
                                                    src={`${i.pic2}`}
                                                    alt="pic2"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                                    <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                                    <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                                </svg>
                                            }
                                            {i.pic3
                                                ? <img
                                                    className="img-table"
                                                    src={`${i.pic3}`}
                                                    alt="pic3"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                                    <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                                    <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                                </svg>
                                            }

                                            {/* {i.icon} */}
                                        </td>
                                        <td className="align-center">{i.name}</td>
                                        <td className="align-center" dangerouslySetInnerHTML={{ __html: i.description }}></td>

                                        <td className="align-center ">

                                            <div className='flex-row'>
                                                <UpdateSectorDescription i={i} getWebsiteSectors={getWebsiteSectors} />
                                                <DeleteSectorDescription i={i} getWebsiteSectors={getWebsiteSectors} />
                                            </div>

                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

const ProjectsManagementTabs = () => {
    const [toggleState, setToggleState] = useState(1);
    return (
        <>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Projects Table
                        </button>

                        {/* <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Projects Images Table
                        </button>  */}
                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? <ProjectsTable /> : null}
                    {toggleState === 2 ? <ProjectImagesTable /> : null}
                </div>
            </div>
        </>
    )
}

const ProjectsTable = () => {
    const [projectImageList, setProjectImageList] = useState([]);
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { PERMISSION_AUTHORITY } = usePermission();
    const [projectList, setProjectList] = useState([]);

    const getWebsiteProjectData = async () => {
        setBuffering(true); // Start Buffering
        try {

            const pl = await axios.get(`${BASE_URL}/website/project/`);
            setProjectList(pl.data);

            const pil = await axios.get(`${BASE_URL}/website/projectimage/`);
            setProjectImageList(pil.data);

        } catch (err) {

            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getWebsiteProjectData();
    }, [toggleCompany]);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Projects</div>
                <div className="field-cont">

                    <AddProject getWebsiteProjectData={getWebsiteProjectData} projectList={projectList} />

                </div>
            </div>
            {/* <SectorsDetailForm /> */}
            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="align-left">ID</th>
                        {/* <th className="align-center">Icon</th> */}
                        <th className="align-center">Name</th>
                        <th className="align-center">Association</th>
                        <th className="align-center">JV</th>
                        <th className="align-center">Lead</th>
                        <th className="align-center">Length</th>
                        <th className="align-center">Cost</th>
                        <th className="align-center">Description</th>
                        <th className="align-center">Type</th>
                        <th className="align-center width-10vw">Action</th>
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> :
                    <tbody>
                        {projectList
                            // ?.filter((e) =>
                            //     e.title.toLowerCase().includes(searchQuery.toLowerCase())
                            // )
                            // .sort((a, b) =>
                            //     a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                            // )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">{i.project_name}</td>
                                        <td className="align-center">{i.association}</td>
                                        <td className="align-center">{i.jv}</td>
                                        <td className="align-center">{i.lead}</td>
                                        <td className="align-center">{i.project_length}</td>
                                        <td className="align-center">{i.cost}</td>
                                        <td className="align-center">{i.project_descritpion}</td>
                                        <td className="align-center">{i.project_type}</td>
                                        <td className="align-right table-action-flex width-10vw ">
                                            <AddProjectImage i={i} getWebsiteProjectData={getWebsiteProjectData} />
                                            <UpdateProject i={i} getWebsiteProjectData={getWebsiteProjectData} projectList={projectList} />
                                            <DeleteProject i={i} getWebsiteProjectData={getWebsiteProjectData} />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

const ProjectImagesTable = () => {
    const [projectImageList, setProjectImageList] = useState([]);
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { PERMISSION_AUTHORITY } = usePermission();
    const [projectList, setProjectList] = useState([]);

    const getWebsiteProjectData = async () => {
        setBuffering(true); // Start Buffering
        try {
            const pl = await axios.get(`${BASE_URL}/website/project/`);
            setProjectList(pl.data);

            const pil = await axios.get(`${BASE_URL}/website/projectimage/`);
            setProjectImageList(pil.data);


        } catch (err) {

            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getWebsiteProjectData();
    }, [toggleCompany]);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Projects Images</div>
                <div className="field-cont">

                    {/* <AddSector getWebsiteProjectData={getWebsiteProjectData} projectList={projectList} /> */}
                    <AddProjectImage getWebsiteProjectData={getWebsiteProjectData} projectList={projectList} />

                </div>
            </div>
            {/* <SectorsDetailForm /> */}
            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="align-left">ID</th>
                        <th className="align-center">Pics</th>
                        <th className="align-center">Name</th>
                        <th className="align-center">Description</th>
                        <th className="align-right ">Action</th>
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> :
                    <tbody>
                        {projectImageList
                            // ?.filter((e) =>
                            //     e.title.toLowerCase().includes(searchQuery.toLowerCase())
                            // )
                            // .sort((a, b) =>
                            //     a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                            // )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">

                                            {i.pic1
                                                ? <img
                                                    className="img-table"
                                                    src={`${i.pic1}`}
                                                    alt="pic1"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                                    <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                                    <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                                </svg>
                                            }
                                            {i.pic2
                                                ? <img
                                                    className="img-table"
                                                    src={`${i.pic2}`}
                                                    alt="pic2"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                                    <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                                    <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                                </svg>
                                            }
                                            {i.pic3
                                                ? <img
                                                    className="img-table"
                                                    src={`${i.pic3}`}
                                                    alt="pic3"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="image">
                                                    <path d="M3 24.3398438c.5527344 0 1-.4477539 1-1v-.9998779V7.5c0-.5512695.4482422-1 1-1h14.8400269.9998169c.5527344 0 1-.4477539 1-1s-.4472656-1-1-1H5c-1.6542969 0-3 1.3457031-3 3v15.8398438C2 23.8920898 2.4472656 24.3398438 3 24.3398438zM28.49823 4.9162598l1.2078247-1.2077637c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0l-2.4971313 2.4970093c-.0012817.0011597-.0024414.0023193-.0036011.0036011L9.2645264 21.321167c-.0007324.0007324-.0015259.0015259-.0022583.0022583l-6.9683228 6.9680786c-.390625.390625-.390625 1.0234375 0 1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688L5.9136353 27.5H27c1.6542969 0 3-1.3457031 3-3v-17C30 6.3938599 29.3915405 5.4364624 28.49823 4.9162598zM28 24.5c0 .5512695-.4482422 1-1 1H7.9137573l2.4692383-2.4691772 15.81427.0390015c.0009766 0 .0019531 0 .0029297 0 .375 0 .71875-.2099609.8896484-.5439453.171875-.3344727.140625-.7373047-.0791016-1.0419922l-3.7099609-5.1298828c-.2578125-.3544922-.7138672-.4995117-1.1259766-.3632812l-3.3164062 1.1020508-1.3103638-1.2266846 1.7567749-1.7567749c.597168.5880127 1.4113159.9404907 2.2850342.9404907 1.7919922 0 3.25-1.4580078 3.25-3.25 0-.8752441-.3532715-1.6879272-.9436035-2.2817383L26.9144287 6.5H27c.5517578 0 1 .4487305 1 1V24.5zM22.4727783 10.9414673c.2231445.2266235.3670654.526001.3670654.8583374 0 .6894531-.5605469 1.25-1.25 1.25-.3317871 0-.6325073-.1422729-.8621826-.3632812L22.4727783 10.9414673zM16.1329346 17.2810669l1.7938232 1.678894c.2685547.2514648.6533203.3349609 1 .21875l3.1826172-1.0585938 2.1298828 2.9443359-11.8609619-.0288086L16.1329346 17.2810669z"></path>
                                                    <path d="M13.0107422,11.6376953c-0.2148438-0.199707-0.5068359-0.2910156-0.796875-0.2607422 c-0.2910156,0.0341797-0.5527344,0.1943359-0.7158203,0.4379883l-3.2900391,4.9296875 c-0.2688599,0.4028931-0.1903076,0.9180908,0.1391602,1.2481689c0.0462646,0.0463867,0.081543,0.1008301,0.1381836,0.1385498 c0.1699219,0.1142578,0.3632812,0.168457,0.5537109,0.168457c0.3232422,0,0.6396484-0.15625,0.8330078-0.4448242 l2.6376953-3.9526367c0.4023438,0.3647461,1.0273438,0.3383789,1.4023438-0.0610352 c0.3671875-0.3953247,0.34729-1.0046997-0.0300903-1.3833618c-0.0090332-0.0090942-0.012207-0.0214233-0.0216675-0.0302124 L13.0107422,11.6376953z"></path>
                                                </svg>
                                            }
                                        </td>
                                        <td className="align-center">{i.name}</td>
                                        <td className="align-center" dangerouslySetInnerHTML={{ __html: i.description }}></td>
                                        <td className="align-right flex-row ">
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};


const ContactUsTable = () => {
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [enquiryData, setEnquiryData] = useState([]);

    const [replied, setReplied] = useState(`False`);
    const [startdate, setStartDate] = useState(`null`);
    const [enddate, setEndDate] = useState(`null`);

    const getEnquiryData = async () => {
        setBuffering(true); // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/website/contactformfilter/${startdate}/${enddate}/${replied}/`);
            // const res = await axios.get(`${BASE_URL}/website/contactform/`);
            setEnquiryData(res.data);
        } catch (err) {

            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getEnquiryData();
    }, [toggleCompany, startdate, enddate, replied]);

    return (

        <div className="content-tabs">
            <div className="attendance-subcont">
                <div className="field-cont">

                    <div className="field-cont-div">
                        <select
                            className="attendance-input-field width-10vw date-field"
                            type="text"
                            value={replied}
                            onChange={(e) => setReplied(e.target.value)}
                        >
                            <option value="null">Reply</option>
                            <option value="True">Replied</option>
                            <option value="False">Pending</option>
                        </select>
                        <hr className="field-cont-hr" />
                    </div>
                    {
                        replied === 'False' ?
                            null :
                            <>
                                <div title='Reply Start-Date Filter' className="field-cont-div">
                                    <input
                                        className="attendance-input-field width-10vw   date-field"
                                        placeholder="From Date"
                                        type="date"
                                        value={startdate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                <div title='Reply End-Date Filter' className="field-cont-div">
                                    <input
                                        className="attendance-input-field width-10vw   date-field"
                                        placeholder="To Date"
                                        type="date"
                                        value={enddate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                            </>
                    }
                </div>
                <div className="btn-cont">
                </div>
            </div>

            <div className="table-css-white-background">
                <div className="table-heading-flex">
                    <div className="repo-heading font-weight500 font-size-heading">Contact Us Enquiry Data</div>
                    <div className="field-cont">
                        <div className="table-searchh field-cont-div">
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="11"
                                    height="12"
                                    viewBox="0 0 11 12"
                                    fill="none"
                                >
                                    <circle
                                        cx="5"
                                        cy="5"
                                        r="4.3"
                                        stroke="#2B3674"
                                        strokeWidth="1.4"
                                    />
                                    <line
                                        x1="10.0101"
                                        y1="11"
                                        x2="8"
                                        y2="8.98995"
                                        stroke="#2B3674"
                                        strokeWidth="1.4"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </div>
                            <div>
                                <input
                                    placeholder="Search"
                                    className="navbar-inputt font-weight400  font-size-text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>


                    </div>
                </div>

                <table className="table-css">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className="align-left">ID</th>
                            <th className="align-leftt">Reply</th>
                            <th className="align-center">Name</th>
                            <th className="align-center">Email</th>
                            <th className="align-center">Mobile</th>
                            <th className="align-center">Submitted On</th>
                            <th className="align-center">Form Type</th>
                            <th className="align-right ">Action</th>
                        </tr>
                    </thead>
                    {buffer ? <div className="spinner"></div> :
                        <tbody>
                            {enquiryData
                                ?.filter((e) =>
                                    e.name.toLowerCase().includes(searchQuery.toLowerCase())
                                )
                                .sort((a, b) =>
                                    a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
                                )
                                .map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>
                                            <td className="align-leftt">
                                                <input
                                                    type="checkbox"
                                                    checked={i.replied}
                                                />
                                            </td>
                                            <td className="align-center">{i.name}</td>
                                            <td className="align-center">{i.email}</td>
                                            <td className="align-center">{i.mobile}</td>
                                            <td className="align-center">{formattedDateLong(i.date_time)}</td>
                                            <td className="align-center">{i.form_type}</td>

                                            <td className="align-right table-action-flex flex-row ">
                                                <ReplyEnquirys i={i} getEnquiryData={getEnquiryData} />
                                                <DeleteEnquiryContact i={i} getEnquiryData={getEnquiryData} />

                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    }
                </table>
                <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </div>
        </div>
    );
};


export { SectorManagementTabs, ProjectsManagementTabs, ProjectsTable, ContactUsTable };
