import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { formatDate, formattedDateLong } from "../Date";
import { BASE_URL, IMAGE_URL } from "../../config/axios";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import { useLocation } from "react-router-dom";
import { UpdateProjectAllDetails, AddBillingDetails, AddProjectBill, UpdateBillingDetails } from "./SiteButtons";
import { BillTable, COSTable, EmployeeTable, EOTTable, MilestoneTable } from "./SiteProjectManagementComponents";




const AllBillDetails = ({ buffer, projectDataa, billDetails, }) => {


  const [filteredBillData, setFilteredBillData] = useState([]);

  // *********** Filter Logic ***********
  const [startdate, setStartDate] = useState(``);
  const [enddate, setEndDate] = useState(``);
  const [search, setSearch] = useState("");

  const handleSearch = () => {
    let filteredData = billDetails;

    if (search) {
      const lowercaseSelectedName = search.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item.billing_details.some((detail) => {
          const billNameMatch = detail.consultancy.bill_name
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billTypeMatch = detail.consultancy.bill_type
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billNumMatch = detail.consultancy.bill_num
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billAmountMatch = detail.consultancy.billing_amount
            .toString()
            .includes(search) ||
            (detail.contractor_submitted && detail.contractor_submitted.billing_amount
              .toString()
              .includes(search)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.billing_amount
              .toString()
              .includes(search)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.billing_amount
              .toString()
              .includes(search));

          return billNameMatch || billTypeMatch || billNumMatch || billAmountMatch;
        });
      });
    }

    if (startdate !== "" || enddate !== "") {
      filteredData = filteredData.filter((item) => {
        return item.billing_details.some((detail) => {
          const isWithinDateRange = (date) =>
            (!startdate || date >= startdate) &&
            (!enddate || date <= enddate);

          return (
            isWithinDateRange(detail.consultancy.billing_date) ||
            isWithinDateRange(detail.consultancy.bill_period_start) ||
            isWithinDateRange(detail.consultancy.bill_period_end) ||
            (detail.contractor_submitted && (
              isWithinDateRange(detail.contractor_submitted.billing_date) ||
              isWithinDateRange(detail.contractor_submitted.bill_period_start) ||
              isWithinDateRange(detail.contractor_submitted.bill_period_end)
            )) ||
            (detail.contractor_bal_submitted && (
              isWithinDateRange(detail.contractor_bal_submitted.billing_date) ||
              isWithinDateRange(detail.contractor_bal_submitted.bill_period_start) ||
              isWithinDateRange(detail.contractor_bal_submitted.bill_period_end)
            )) ||
            (detail.contractor_bal_recommended && (
              isWithinDateRange(detail.contractor_bal_recommended.billing_date) ||
              isWithinDateRange(detail.contractor_bal_recommended.bill_period_start) ||
              isWithinDateRange(detail.contractor_bal_recommended.bill_period_end)
            ))
          );
        });
      });
    }

    setFilteredBillData(filteredData);
  };
  useEffect(() => {
    handleSearch();
  },
    [
      billDetails,
      search,
      startdate,
      enddate,
    ]);


  return (

    <>
      <div className="attendance-subcont">
        <div className="field-cont">
          <div className="field-cont-div">
            <input
              className="attendance-input-field width-10vw   date-field"
              placeholder="From Date"
              type="date"
              value={startdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>
          <div className="field-cont-div">
            <input
              className="attendance-input-field width-10vw   date-field"
              placeholder="To Date"
              type="date"
              value={enddate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>

          <div className="field-cont-div-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 11 12"
              fill="none"
            >
              <circle
                cx="5"
                cy="5"
                r="4.3"
                stroke="#707070"
                stroke-width="1.4"
              />
              <line
                x1="10.0101"
                y1="11"
                x2="8"
                y2="8.98995"
                stroke="#707070 "
                stroke-width="1.4"
                stroke-linecap="round"
              />
            </svg>

            <input
              className="attendance-input-field width-15vw  "
              placeholder="Search"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-cont">
          <AddProjectBill project={projectDataa} />
          <AddBillingDetails project={projectDataa} />
        </div>
      </div>

      <div className="table-css-white-background">
        <table className="table-css nested-table-lightblue">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Month</th>
              <th className="align-center">Details</th>
              <th className="align-right">Actions</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div>
          ) : (
            <tbody>
              {filteredBillData
                .map((billingData, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{billingData.month}-{billingData.year}</td>
                      <td className="align-center">
                        {billingData.billing_details.length > 0 ? (
                          <div>
                            {billingData.billing_details.map((billDetails, rowIndex) => (
                              <table key={rowIndex} className="nested-table font-size-subheading">
                                <thead>
                                  <tr>
                                    <th rowSpan="2" className="align-center">Project Details</th>
                                    <th colSpan="5" className="align-center">Contractor</th>
                                    <th colSpan="3" className="align-center">Consultancy</th>
                                    {/* <th rowSpan="2" className="align-center">Remarks</th> */}
                                  </tr>
                                  <tr>
                                    <th>Submitted</th>
                                    <th>Recommended (%)</th>
                                    <th>Bal Submitted</th>
                                    <th>Bal Recommended (%)</th>
                                    <th>Remarks</th>
                                    <th>Billing Details</th>
                                    <th>Received Details</th>
                                    <th>Remarks</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Bill No.</td>
                                    <td>{`${billDetails.contractor_submitted?.bill_type || ''} ${billDetails.contractor_submitted?.bill_name || ''} ${billDetails.contractor_submitted?.bill_num || '-'}`}</td>
                                    <td>{`${billDetails.contractor_recommended?.bill_type || ''} ${billDetails.contractor_recommended?.bill_name || ''} ${billDetails.contractor_recommended?.bill_num || '-'}`}</td>
                                    <td>{`${billDetails.contractor_bal_submitted?.bill_type || ''} ${billDetails.contractor_bal_submitted?.bill_name || ''} ${billDetails.contractor_bal_submitted?.bill_num || '-'}`}</td>
                                    <td>{`${billDetails.contractor_bal_recommended?.bill_type || ''} ${billDetails.contractor_bal_recommended?.bill_name || ''} ${billDetails.contractor_bal_recommended?.bill_num || '-'}`}</td>
                                    <td rowSpan="5">{billDetails.contractor_remarks || '-'}</td>
                                    <td>{`${billDetails.consultancy?.bill_type || ''} ${billDetails.consultancy?.bill_name || ''} ${billDetails.consultancy?.bill_num || '-'}`}</td>
                                    <td>{`${billDetails.consultancy_receieved?.bill_type || ''} ${billDetails.consultancy_receieved?.bill_name || ''} ${billDetails.consultancy_receieved?.bill_num || '-'}`}</td>
                                    <td rowSpan="5">{billDetails.consultancy_remarks || '-'}</td>
                                  </tr>
                                  <tr>
                                    <td>Bill Date</td>
                                    <td>{billDetails.contractor_submitted?.billing_date || '-'}</td>
                                    <td>{billDetails.contractor_recommended?.billing_date || '-'}</td>
                                    <td>{billDetails.contractor_bal_submitted?.billing_date || '-'}</td>
                                    <td>{billDetails.contractor_bal_recommended?.billing_date || '-'}</td>
                                    <td>{billDetails.consultancy?.billing_date || '-'}</td>
                                    <td>{billDetails.consultancy_receieved?.billing_date || '-'}</td>
                                  </tr>
                                  <tr>
                                    <td>Bill Period</td>
                                    <td>
                                      {billDetails.contractor_submitted?.bill_period_start || billDetails.contractor_submitted?.bill_period_end
                                        ? `${formattedDateLong(billDetails.contractor_submitted?.bill_period_start || '-')} - ${formattedDateLong(billDetails.contractor_submitted?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.contractor_recommended?.bill_period_start || billDetails.contractor_recommended?.bill_period_end
                                        ? `${formattedDateLong(billDetails.contractor_recommended?.bill_period_start || '-')} - ${formattedDateLong(billDetails.contractor_recommended?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.contractor_bal_submitted?.bill_period_start || billDetails.contractor_bal_submitted?.bill_period_end
                                        ? `${formattedDateLong(billDetails.contractor_bal_submitted?.bill_period_start || '-')} - ${formattedDateLong(billDetails.contractor_bal_submitted?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.contractor_bal_recommended?.bill_period_start || billDetails.contractor_bal_recommended?.bill_period_end
                                        ? `${formattedDateLong(billDetails.contractor_bal_recommended?.bill_period_start || '-')} - ${formattedDateLong(billDetails.contractor_bal_recommended?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.consultancy?.bill_period_start || billDetails.consultancy?.bill_period_end
                                        ? `${formattedDateLong(billDetails.consultancy?.bill_period_start || '-')} - ${formattedDateLong(billDetails.consultancy?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.consultancy_receieved?.bill_period_start || billDetails.consultancy_receieved?.bill_period_end
                                        ? `${formattedDateLong(billDetails.consultancy_receieved?.bill_period_start || '-')} - ${formattedDateLong(billDetails.consultancy_receieved?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Bill Amt.</td>
                                    <td>{billDetails.contractor_submitted?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_recommended?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_bal_submitted?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_bal_recommended?.billing_amount || '-'}</td>
                                    <td>{billDetails.consultancy?.billing_amount || '-'}</td>
                                    <td>{billDetails.consultancy_receieved?.billing_amount || '-'}</td>
                                  </tr>
                                  <tr>
                                    <td>Witheld Amt.</td>
                                    <td>{billDetails.contractor_submitted?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_recommended?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_bal_submitted?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_bal_recommended?.billing_amount || '-'}</td>
                                    <td>{billDetails.consultancy?.billing_amount || '-'}</td>
                                    <td>{billDetails.consultancy_receieved?.billing_amount || '-'}</td>
                                  </tr>
                                </tbody>
                              </table>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>No Billing Data Available.</p>
                          </div>
                        )}
                      </td>
                      <td className="align-right ">
                        {billingData.billing_details.length > 0 ? (
                          <div>
                            {billingData.billing_details.map((billDetails, rowIndex) => (

                              <UpdateBillingDetails billingData={billDetails} project={projectDataa} />
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>No Billing Data Available.</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          )}
        </table>
      </div>

    </>
  );
}

const ContractorBillDetails = ({ buffer, projectDataa, billDetails }) => {


  const [filteredBillData, setFilteredBillData] = useState([]);

  // *********** Filter Logic ***********
  const [startdate, setStartDate] = useState(``);
  const [enddate, setEndDate] = useState(``);
  const [search, setSearch] = useState("");

  const handleSearch = () => {
    let filteredData = billDetails;

    if (search) {
      const lowercaseSelectedName = search.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item.billing_details.some((detail) => {
          const billNameMatch = detail.consultancy.bill_name
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billTypeMatch = detail.consultancy.bill_type
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billNumMatch = detail.consultancy.bill_num
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billAmountMatch = detail.consultancy.billing_amount
            .toString()
            .includes(search) ||
            (detail.contractor_submitted && detail.contractor_submitted.billing_amount
              .toString()
              .includes(search)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.billing_amount
              .toString()
              .includes(search)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.billing_amount
              .toString()
              .includes(search));

          return billNameMatch || billTypeMatch || billNumMatch || billAmountMatch;
        });
      });
    }

    if (startdate !== "" || enddate !== "") {
      filteredData = filteredData.filter((item) => {
        return item.billing_details.some((detail) => {
          const isWithinDateRange = (date) =>
            (!startdate || date >= startdate) &&
            (!enddate || date <= enddate);

          return (
            isWithinDateRange(detail.consultancy.billing_date) ||
            isWithinDateRange(detail.consultancy.bill_period_start) ||
            isWithinDateRange(detail.consultancy.bill_period_end) ||
            (detail.contractor_submitted && (
              isWithinDateRange(detail.contractor_submitted.billing_date) ||
              isWithinDateRange(detail.contractor_submitted.bill_period_start) ||
              isWithinDateRange(detail.contractor_submitted.bill_period_end)
            )) ||
            (detail.contractor_bal_submitted && (
              isWithinDateRange(detail.contractor_bal_submitted.billing_date) ||
              isWithinDateRange(detail.contractor_bal_submitted.bill_period_start) ||
              isWithinDateRange(detail.contractor_bal_submitted.bill_period_end)
            )) ||
            (detail.contractor_bal_recommended && (
              isWithinDateRange(detail.contractor_bal_recommended.billing_date) ||
              isWithinDateRange(detail.contractor_bal_recommended.bill_period_start) ||
              isWithinDateRange(detail.contractor_bal_recommended.bill_period_end)
            ))
          );
        });
      });
    }

    setFilteredBillData(filteredData);
  };
  useEffect(() => {
    handleSearch();
  },
    [
      billDetails,
      search,
      startdate,
      enddate,
    ]);


  return (

    <>
      <div className="attendance-subcont">
        <div className="field-cont">
          <div className="field-cont-div">
            <input
              className="attendance-input-field width-10vw   date-field"
              placeholder="From Date"
              type="date"
              value={startdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>
          <div className="field-cont-div">
            <input
              className="attendance-input-field width-10vw   date-field"
              placeholder="To Date"
              type="date"
              value={enddate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>

          <div className="field-cont-div-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 11 12"
              fill="none"
            >
              <circle
                cx="5"
                cy="5"
                r="4.3"
                stroke="#707070"
                stroke-width="1.4"
              />
              <line
                x1="10.0101"
                y1="11"
                x2="8"
                y2="8.98995"
                stroke="#707070 "
                stroke-width="1.4"
                stroke-linecap="round"
              />
            </svg>

            <input
              className="attendance-input-field width-15vw  "
              placeholder="Search"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-cont">
          <AddProjectBill project={projectDataa} />
          <AddBillingDetails project={projectDataa} />
        </div>
      </div>

      <div className="table-css-white-background">
        <table className="table-css nested-table-lightblue">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Month</th>
              <th className="align-center">Details</th>
              <th className="align-center">Actions</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div>
          ) : (
            <tbody>
              {filteredBillData
                .map((billingData, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{billingData.month}-{billingData.year}</td>
                      <td className="align-center">
                        {billingData.billing_details.length > 0 ? (
                          <div>
                            {billingData.billing_details.map((billDetails, rowIndex) => (
                              <table key={rowIndex} className="nested-table font-size-subheading">
                                <thead>
                                  <tr>
                                    <th rowSpan="2" className="align-center">Project Details</th>
                                    <th colSpan="5" className="align-center">Contractor</th>
                                    {/* <th rowSpan="2" className="align-center">Remarks</th> */}
                                  </tr>
                                  <tr>
                                    <th>Submitted</th>
                                    <th>Recommended (%)</th>
                                    <th>Bal Submitted</th>
                                    <th>Bal Recommended (%)</th>
                                    <th>Remarks</th>


                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Bill No.</td>
                                    <td>{`${billDetails.contractor_submitted?.bill_type || ''} ${billDetails.contractor_submitted?.bill_name || ''} ${billDetails.contractor_submitted?.bill_num || '-'}`}</td>
                                    <td>{`${billDetails.contractor_recommended?.bill_type || ''} ${billDetails.contractor_recommended?.bill_name || ''} ${billDetails.contractor_recommended?.bill_num || '-'}`}</td>
                                    <td>{`${billDetails.contractor_bal_submitted?.bill_type || ''} ${billDetails.contractor_bal_submitted?.bill_name || ''} ${billDetails.contractor_bal_submitted?.bill_num || '-'}`}</td>
                                    <td>{`${billDetails.contractor_bal_recommended?.bill_type || ''} ${billDetails.contractor_bal_recommended?.bill_name || ''} ${billDetails.contractor_bal_recommended?.bill_num || '-'}`}</td>
                                    <td rowSpan="5">{billDetails.contractor_remarks || '-'}</td>

                                  </tr>
                                  <tr>
                                    <td>Bill Date</td>
                                    <td>{billDetails.contractor_submitted?.billing_date || '-'}</td>
                                    <td>{billDetails.contractor_recommended?.billing_date || '-'}</td>
                                    <td>{billDetails.contractor_bal_submitted?.billing_date || '-'}</td>
                                    <td>{billDetails.contractor_bal_recommended?.billing_date || '-'}</td>

                                  </tr>
                                  <tr>
                                    <td>Bill Period</td>
                                    <td>
                                      {billDetails.contractor_submitted?.bill_period_start || billDetails.contractor_submitted?.bill_period_end
                                        ? `${formattedDateLong(billDetails.contractor_submitted?.bill_period_start || '-')} - ${formattedDateLong(billDetails.contractor_submitted?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.contractor_recommended?.bill_period_start || billDetails.contractor_recommended?.bill_period_end
                                        ? `${formattedDateLong(billDetails.contractor_recommended?.bill_period_start || '-')} - ${formattedDateLong(billDetails.contractor_recommended?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.contractor_bal_submitted?.bill_period_start || billDetails.contractor_bal_submitted?.bill_period_end
                                        ? `${formattedDateLong(billDetails.contractor_bal_submitted?.bill_period_start || '-')} - ${formattedDateLong(billDetails.contractor_bal_submitted?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.contractor_bal_recommended?.bill_period_start || billDetails.contractor_bal_recommended?.bill_period_end
                                        ? `${formattedDateLong(billDetails.contractor_bal_recommended?.bill_period_start || '-')} - ${formattedDateLong(billDetails.contractor_bal_recommended?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>

                                  </tr>
                                  <tr>
                                    <td>Bill Amt.</td>
                                    <td>{billDetails.contractor_submitted?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_recommended?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_bal_submitted?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_bal_recommended?.billing_amount || '-'}</td>

                                  </tr>
                                  <tr>
                                    <td>Witheld Amt.</td>
                                    <td>{billDetails.contractor_submitted?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_recommended?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_bal_submitted?.billing_amount || '-'}</td>
                                    <td>{billDetails.contractor_bal_recommended?.billing_amount || '-'}</td>

                                  </tr>
                                </tbody>
                              </table>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>No Billing Data Available.</p>
                          </div>
                        )}
                      </td>
                      <td className="align-center ">
                        {billingData.billing_details.length > 0 ? (
                          <div>
                            {billingData.billing_details.map((billDetails, rowIndex) => (

                              <UpdateBillingDetails billingData={billDetails} project={projectDataa} />
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>No Billing Data Available.</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          )}
        </table>
      </div>

    </>
  );
}

const ConsultantBillDetails = ({ buffer, projectDataa, billDetails }) => {


  const [filteredBillData, setFilteredBillData] = useState([]);

  // *********** Filter Logic ***********
  const [startdate, setStartDate] = useState(``);
  const [enddate, setEndDate] = useState(``);
  const [search, setSearch] = useState("");

  const handleSearch = () => {
    let filteredData = billDetails;

    if (search) {
      const lowercaseSelectedName = search.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item.billing_details.some((detail) => {
          const billNameMatch = detail.consultancy.bill_name
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_name
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billTypeMatch = detail.consultancy.bill_type
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_type
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billNumMatch = detail.consultancy.bill_num
            .toLowerCase()
            .includes(lowercaseSelectedName) ||
            (detail.contractor_submitted && detail.contractor_submitted.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.bill_num
              .toLowerCase()
              .includes(lowercaseSelectedName));

          const billAmountMatch = detail.consultancy.billing_amount
            .toString()
            .includes(search) ||
            (detail.contractor_submitted && detail.contractor_submitted.billing_amount
              .toString()
              .includes(search)) ||
            (detail.contractor_bal_submitted && detail.contractor_bal_submitted.billing_amount
              .toString()
              .includes(search)) ||
            (detail.contractor_bal_recommended && detail.contractor_bal_recommended.billing_amount
              .toString()
              .includes(search));

          return billNameMatch || billTypeMatch || billNumMatch || billAmountMatch;
        });
      });
    }

    if (startdate !== "" || enddate !== "") {
      filteredData = filteredData.filter((item) => {
        return item.billing_details.some((detail) => {
          const isWithinDateRange = (date) =>
            (!startdate || date >= startdate) &&
            (!enddate || date <= enddate);

          return (
            isWithinDateRange(detail.consultancy.billing_date) ||
            isWithinDateRange(detail.consultancy.bill_period_start) ||
            isWithinDateRange(detail.consultancy.bill_period_end) ||
            (detail.contractor_submitted && (
              isWithinDateRange(detail.contractor_submitted.billing_date) ||
              isWithinDateRange(detail.contractor_submitted.bill_period_start) ||
              isWithinDateRange(detail.contractor_submitted.bill_period_end)
            )) ||
            (detail.contractor_bal_submitted && (
              isWithinDateRange(detail.contractor_bal_submitted.billing_date) ||
              isWithinDateRange(detail.contractor_bal_submitted.bill_period_start) ||
              isWithinDateRange(detail.contractor_bal_submitted.bill_period_end)
            )) ||
            (detail.contractor_bal_recommended && (
              isWithinDateRange(detail.contractor_bal_recommended.billing_date) ||
              isWithinDateRange(detail.contractor_bal_recommended.bill_period_start) ||
              isWithinDateRange(detail.contractor_bal_recommended.bill_period_end)
            ))
          );
        });
      });
    }

    setFilteredBillData(filteredData);
  };
  useEffect(() => {
    handleSearch();
  },
    [
      billDetails,
      search,
      startdate,
      enddate,
    ]);


  return (

    <>
      <div className="attendance-subcont">
        <div className="field-cont">
          <div className="field-cont-div">
            <input
              className="attendance-input-field width-10vw   date-field"
              placeholder="From Date"
              type="date"
              value={startdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>
          <div className="field-cont-div">
            <input
              className="attendance-input-field width-10vw   date-field"
              placeholder="To Date"
              type="date"
              value={enddate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>

          <div className="field-cont-div-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 11 12"
              fill="none"
            >
              <circle
                cx="5"
                cy="5"
                r="4.3"
                stroke="#707070"
                stroke-width="1.4"
              />
              <line
                x1="10.0101"
                y1="11"
                x2="8"
                y2="8.98995"
                stroke="#707070 "
                stroke-width="1.4"
                stroke-linecap="round"
              />
            </svg>

            <input
              className="attendance-input-field width-15vw  "
              placeholder="Search"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-cont">
          <AddProjectBill project={projectDataa} />
          <AddBillingDetails project={projectDataa} />
        </div>
      </div>

      <div className="table-css-white-background">
        <table className="table-css nested-table-lightblue">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Month</th>
              <th className="align-center">Details</th>
              <th className="align-center">Actions</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div>
          ) : (
            <tbody>
              {filteredBillData
                .map((billingData, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{billingData.month}-{billingData.year}</td>
                      <td className="align-center">
                        {billingData.billing_details.length > 0 ? (
                          <div>
                            {billingData.billing_details.map((billDetails, rowIndex) => (
                              <table key={rowIndex} className="nested-table font-size-subheading">
                                <thead>
                                  <tr>
                                    <th rowSpan="2" className="align-center">Project Details</th>
                                    <th colSpan="3" className="align-center">Consultancy</th>
                                    {/* <th rowSpan="2" className="align-center">Remarks</th> */}
                                  </tr>
                                  <tr>

                                    <th>Billing Details</th>
                                    <th>Received Details</th>
                                    <th>Remarks</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Bill No.</td>

                                    <td>{`${billDetails.consultancy?.bill_type || ''} ${billDetails.consultancy?.bill_name || ''} ${billDetails.consultancy?.bill_num || '-'}`}</td>
                                    <td>{`${billDetails.consultancy_receieved?.bill_type || ''} ${billDetails.consultancy_receieved?.bill_name || ''} ${billDetails.consultancy_receieved?.bill_num || '-'}`}</td>
                                    <td rowSpan="5">{billDetails.consultancy_remarks || '-'}</td>
                                  </tr>
                                  <tr>
                                    <td>Bill Date</td>

                                    <td>{billDetails.consultancy?.billing_date || '-'}</td>
                                    <td>{billDetails.consultancy_receieved?.billing_date || '-'}</td>
                                  </tr>
                                  <tr>
                                    <td>Bill Period</td>
                                    <td>
                                      {billDetails.consultancy?.bill_period_start || billDetails.consultancy?.bill_period_end
                                        ? `${formattedDateLong(billDetails.consultancy?.bill_period_start || '-')} - ${formattedDateLong(billDetails.consultancy?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {billDetails.consultancy_receieved?.bill_period_start || billDetails.consultancy_receieved?.bill_period_end
                                        ? `${formattedDateLong(billDetails.consultancy_receieved?.bill_period_start || '-')} - ${formattedDateLong(billDetails.consultancy_receieved?.bill_period_end || '-')}`
                                        : '-'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Bill Amt.</td>

                                    <td>{billDetails.consultancy?.billing_amount || '-'}</td>
                                    <td>{billDetails.consultancy_receieved?.billing_amount || '-'}</td>
                                  </tr>
                                  <tr>
                                    <td>Witheld Amt.</td>

                                    <td>{billDetails.consultancy?.billing_amount || '-'}</td>
                                    <td>{billDetails.consultancy_receieved?.billing_amount || '-'}</td>
                                  </tr>
                                </tbody>
                              </table>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>No Billing Data Available.</p>
                          </div>
                        )}
                      </td>
                      <td className="align-center ">
                        {billingData.billing_details.length > 0 ? (
                          <div>
                            {billingData.billing_details.map((billDetails, rowIndex) => (

                              <UpdateBillingDetails billingData={billDetails} project={projectDataa} />
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>No Billing Data Available.</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          )}
        </table>
      </div>

    </>
  );
}

const SiteProjectBillDetails = () => {

  const location = useLocation();
  const projectData = location.state ? location.state.project : null;
  const projectDataa = location.state ? location.state.project.id : null;
  const show = location.state ? location.state.toggleshow ? location.state.toggleshow : "null" : "null";

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [loading, setLoading] = useState(false);
  const [toggleShow, setToggleShow] = useState(show);
  const [toggleView, setToggleView] = useState("Bill Details");

  // *********** Employee Details Api Start ***********
  const [billDetails, setProjectBillDetails] = useState([]);
  const [bills, setBills] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);




  const getProjectBillDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/billingdetailbyprojectall/${projectDataa}/`
      );
      setProjectBillDetails(res.data);

      const bill = await axios.get(
        `${BASE_URL}/siteproject/billingbyprojid/${projectDataa}/`
      );
      setBills(bill.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          if (errorData.detail && errorData.code && Array.isArray(errorData.messages)) {
            toast.error(`Detail: ${errorData.detail}`);
            toast.error(`Code: ${errorData.code}`);
            errorData.messages.forEach((messageObj) => {
              Object.entries(messageObj).forEach(([key, value]) => {
                toast.error(`${key}: ${value}`);
              });
            });
          } else {
            Object.entries(errorData).forEach(([field, messages]) => {
              if (Array.isArray(messages)) {
                messages.forEach((message) =>
                  toast.error(`"${field}": ${message}`)
                );
              } else {
                toast.error(`"${field}": ${messages}`);
              }
            });
          }
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };


  // const getProjects = async () => {
  //   setBuffering(true); //buffering logic // Start Buffering
  //   try {
  //     const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
  //     setSiteSubCompanyList(sub.data);

  //     const res = await axios.get(`${BASE_URL}/siteproject/projectdashboard/`);
  //     setProjectsList(res.data);

  //   } catch (err) {
  //     //toast Logic
  //     if (err.response) {
  //       const errorData = err.response.data;

  //       if (typeof errorData.error === 'string') {
  //         // Single error message
  //         toast.error(`Error: ${errorData.error}`);
  //       } else if (typeof errorData === 'object' && errorData !== null) {
  //         // Multiple error messages
  //         Object.entries(errorData).forEach(([field, messages]) => {
  //           messages.forEach(message => toast.error(`"${field}": ${message}`));
  //         });
  //       } else {
  //         toast.error('Error:- Failed to Process!');
  //       }
  //     } else {
  //       toast.error('Error processing your request.');
  //     }
  //   } finally {
  //     setBuffering(false);// End Buffering
  //   }
  // };

  useEffect(() => {
    getProjectBillDetails();
  }, []);
  // *********** Employee Details Api End ***********





  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dashboardnavbarcopy name={`Bill Details`} url="Site Project" />

      <div className="content-tabs">

        {/* <div className="table-heading-flex"> */}
        <div className="field-cont-between">
          <div className="attendance-heading font-weight500 font-size-heading">Bill Details of:<br />{projectData.project_short_name}</div>
          <div className="field-cont">
            <div className="table-searchh dropdown-container field-cont-div">
              <select
                onChange={(e) => setToggleShow(e.target.value)}
                value={toggleShow}
                className="dropdown-wh font-size-subheading  ">
                <option value="null">All</option>
                <option value="Contractor">Contractor</option>
                <option value="Consultant">Consultant</option>
                {/* {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))}; */}

              </select>
            </div>
            <div className="table-searchh dropdown-container field-cont-div">
              <select
                onChange={(e) => setToggleView(e.target.value)}
                value={toggleView}
                className="dropdown-wh font-size-subheading  ">
                <option value="Bill Details">Bill Details</option>
                <option value="Bills">Bills</option>
                {/* {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))}; */}

              </select>
            </div>
          </div>

        </div>


        {/* </div> */}
        {toggleView == 'Bill Details' ?
          <div className="content-tabs">
            {toggleShow === "null" ? <AllBillDetails buffer={buffer} projectDataa={projectDataa} projectData={projectData} billDetails={billDetails} /> : null}
            {toggleShow === "Contractor" ? <ContractorBillDetails buffer={buffer} projectDataa={projectDataa} projectData={projectData} billDetails={billDetails} /> : null}
            {toggleShow === "Consultant" ? <ConsultantBillDetails buffer={buffer} projectDataa={projectDataa} projectData={projectData} billDetails={billDetails} /> : null}
          </div>

          :
          toggleView == 'Bills' ?

            <>
              <div className="attendance-subcont">
                {/* <div className="field-cont">
                  <div className="field-cont-div">
                    <input
                      className="attendance-input-field width-10vw   date-field"
                      placeholder="From Date"
                      type="date"
                      value={startdate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <hr className="field-cont-hr" />
                  </div>
                  <div className="field-cont-div">
                    <input
                      className="attendance-input-field width-10vw   date-field"
                      placeholder="To Date"
                      type="date"
                      value={enddate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                    <hr className="field-cont-hr" />
                  </div>

                  <div className="field-cont-div-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 11 12"
                      fill="none"
                    >
                      <circle
                        cx="5"
                        cy="5"
                        r="4.3"
                        stroke="#707070"
                        stroke-width="1.4"
                      />
                      <line
                        x1="10.0101"
                        y1="11"
                        x2="8"
                        y2="8.98995"
                        stroke="#707070 "
                        stroke-width="1.4"
                        stroke-linecap="round"
                      />
                    </svg>

                    <input
                      className="attendance-input-field width-15vw  "
                      placeholder="Search"
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="btn-cont">
                  <AddProjectBill project={projectDataa} />
                  {/* <AddBillingDetails project={projectDataa} /> */}
                </div>
              </div>

              <div className="table-css-white-background">
                <table className="table-css nested-table-lightblue">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S.No.</th>
                      <th className="align-center">Number</th>
                      <th className="align-center">Name</th>
                      <th className="align-center">Type</th>
                      <th className="align-center">Amount</th>
                      <th className="align-center">Date</th>
                      <th className="align-center">Period</th>
                      <th className="align-center width-5vw">Doc</th>
                    </tr>
                  </thead>
                  {buffer ? (
                    <div className="spinner"></div>
                  ) : (
                    <tbody>
                      {bills
                        .map((billData, monthIndex) => (
                          <React.Fragment key={monthIndex}>
                            <tr className="custom-table-head-td">
                              <td className="align-left">{monthIndex + 1}</td>
                              <td className="align-left">{billData.bill_num}</td>
                              <td className="align-center">{billData.bill_name}</td>
                              <td className="align-center">{billData.bill_type}</td>
                              <td className="align-center">{billData.billing_amount}</td>
                              <td className="align-center">{formattedDateLong(billData.billing_date)}</td>
                              <td className="align-center">{formattedDateLong(billData.bill_period_start)}-{formattedDateLong(billData.bill_period_end)}</td>
                              <td className="align-center ">
                                <a href={billData.document} target="_blank" className="document-download-button  width-5vw  width-5vw">
                                  View
                                </a>
                                {/* {billData.document} */}
                                {/* <AddBillingDetails project={projectDataa} /> */}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>

            </>
            : null
        }

      </div >
    </>
  );
};

const SiteProjectDetails = () => {

  const location = useLocation();
  // const projectData = location.state ? location.state.project : null;
  const projectId = location.state ? location.state.project.id : null;

  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false);
  const [toggleView, setToggleView] = useState("Bill Details");

  // *********** Employee Details Api Start ***********
  const [billDetails, setProjectBillDetails] = useState([]);
  const [filteredBillData, setFilteredBillData] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [projectData, setProjectsData] = useState([]);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);


  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);

      const res = await axios.get(`${BASE_URL}/siteproject/projectdashboard/`);
      setProjectsList(res.data);

      const proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);
      setProjectsData(proj.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjects();
  }, []);
  // *********** Employee Details Api End ***********
  console.log("projectData")
  console.log(projectData)

  // *********** Filter Logic ***********
  const [startdate, setStartDate] = useState(``);
  const [enddate, setEndDate] = useState(``);
  const [search, setSearch] = useState("");












  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dashboardnavbarcopy name={`Project Details`} url="Site Project" />

      <div className="content-tabs">
        <div className="info-box-div-bg">

          <div className="jd-heading-outer">
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Project Details of "{projectData?.site_prcode} : {projectData?.project_short_name ? projectData?.project_short_name : "-"}"</h2>
              <UpdateProjectAllDetails i={projectData} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>
          {/* <div className="flex-row info-box-div-head">
            <div className="attendance-heading font-weight500 font-size-heading">Project Details</div>
            <UpdateProjectAllDetails i={projectData} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />

            </div> */}
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text ">Project Name</td>
            <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_name}</th>
          </tr>
          <div className="info-box-div">
            <div className="personal-detail">

              {/* <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3> */}
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Location</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.location}</th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Tender Id</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.tender_id}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Lead</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.lead}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">JV</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv ? projectData?.jv : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">JV Share</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv_share ? projectData?.jv_share : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Association</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.association}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Employee Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.employees?.length}</th>
                  </tr >
                </table >
              }
            </div >
            <div className="personal-detail">
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_completion_date ? formattedDateLong(projectData?.project_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Actual Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_completion_date ? formattedDateLong(projectData?.actual_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Schedule Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_completion_date ? formattedDateLong(projectData?.schedule_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Agreement Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.agreement_date ? formattedDateLong(projectData?.agreement_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Status</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.status ? projectData?.status : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Remark</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.remark ? projectData?.remark : "-"}</th>
                  </tr >

                </table >
              }
            </div >

          </div >
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text ">Project Key Point</td>
            <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_key_point}</th>
          </tr >


          <div className="jd-heading-outer">
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Contractor Details</h2>
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>

          <div className="info-box-div">
            <div className="personal-detail">
              {/* <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3> */}
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Construction Cost</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {projectData?.construction_cost}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Name</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.contractor_name}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contract Mode</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.contract_mode}</th>
                  </tr >

                </table >
              }
            </div >
            <div className="personal-detail">
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Agreement Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.agreement_date ? formattedDateLong(projectData?.agreement_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Appointed Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.appointed_date ? formattedDateLong(projectData?.appointed_date) : "-"}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.eots?.filter(eot => eot.workperformedby === "contractor").length}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Change of Scope Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.coss?.filter(eot => eot.workperformedby === "contractor").length}</th>
                  </tr >

                </table >
              }
            </div >

          </div >


          <div className="jd-heading-outer">
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Consultant Details</h2>
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>

          <div className="info-box-div">
            <div className="personal-detail">
              {/* <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3> */}
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultancy Fees</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {projectData?.consultancy_fees}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultant Award</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.award_date ? formattedDateLong(projectData?.award_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultant Start</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.start_date ? formattedDateLong(projectData?.start_date) : "-"}</th>
                  </tr >
                </table >
              }
            </div >
            <div className="personal-detail">
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.eots?.filter(eot => eot.workperformedby === "consultant").length}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Change of Scope Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.coss?.filter(eot => eot.workperformedby === "consultant").length}</th>
                  </tr >
                </table >
              }
            </div >

          </div >



        </div >

      </div >
    </>
  );
};

const ProjectAllDetails = () => {

  const location = useLocation();
  // const projectData = location.state ? location.state.project : null;
  const projectId = location.state ? location.state.project.id : null;

  const [buffer, setBuffering] = useState(false); //buffering logic

  // *********** Employee Details Api Start ***********

  const [projectData, setProjectsData] = useState();
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);


  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);


      const proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);
      setProjectsData(proj.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjects();
    getProjectBillDetails();
  }, []);
  // *********** Employee Details Api End ***********

  const [toggleState, setToggleState] = useState(1);



  const [loading, setLoading] = useState(false); //loading logic

  const [bills, setBills] = useState([]);
  const getProjectBillDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const bill = await axios.get(
        `${BASE_URL}/siteproject/billingbyprojid/${projectId}/`
      );
      setBills(bill.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          if (errorData.detail && errorData.code && Array.isArray(errorData.messages)) {
            toast.error(`Detail: ${errorData.detail}`);
            toast.error(`Code: ${errorData.code}`);
            errorData.messages.forEach((messageObj) => {
              Object.entries(messageObj).forEach(([key, value]) => {
                toast.error(`${key}: ${value}`);
              });
            });
          } else {
            Object.entries(errorData).forEach(([field, messages]) => {
              if (Array.isArray(messages)) {
                messages.forEach((message) =>
                  toast.error(`"${field}": ${message}`)
                );
              } else {
                toast.error(`"${field}": ${messages}`);
              }
            });
          }
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };





  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dashboardnavbarcopy name={`Project Details`} url="Site Project" />

      <div className="content-tabs">
        <div className="info-box-div-bg">

          <div className="jd-heading-outer">
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Project Details of "{projectData?.site_prcode} : {projectData?.project_short_name ? projectData?.project_short_name : "-"}"</h2>
              <UpdateProjectAllDetails i={projectData} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>
          {/* <div className="flex-row info-box-div-head">
            <div className="attendance-heading font-weight500 font-size-heading">Project Details</div>
            <UpdateProjectAllDetails i={projectData} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />

            </div> */}
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text ">Project Name</td>
            <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_name}</th>
          </tr>
          <div className="info-box-div">
            <div className="personal-detail">

              {/* <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3> */}
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Location</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.location}</th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Tender Id</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.tender_id}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Lead</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.lead}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">JV</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv ? projectData?.jv : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">JV Share</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv_share ? projectData?.jv_share : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Association</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.association}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Employee Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.employees?.length}</th>
                  </tr >
                </table >
              }
            </div >
            <div className="personal-detail">
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_completion_date ? formattedDateLong(projectData?.project_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Actual Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_completion_date ? formattedDateLong(projectData?.actual_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Schedule Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_completion_date ? formattedDateLong(projectData?.schedule_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Agreement Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.agreement_date ? formattedDateLong(projectData?.agreement_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Status</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.status ? projectData?.status : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Remark</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.remark ? projectData?.remark : "-"}</th>
                  </tr >

                </table >
              }
            </div >

          </div >
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text ">Project Key Point</td>
            <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_key_point}</th>
          </tr >


          <div className="jd-heading-outer">
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Contractor Details</h2>
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>

          <div className="info-box-div">
            <div className="personal-detail">
              {/* <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3> */}
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Construction Cost</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {projectData?.construction_cost}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Name</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.contractor_name}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contract Mode</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.contract_mode}</th>
                  </tr >

                </table >
              }
            </div >
            <div className="personal-detail">
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Agreement Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.agreement_date ? formattedDateLong(projectData?.agreement_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Appointed Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.appointed_date ? formattedDateLong(projectData?.appointed_date) : "-"}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.eots?.filter(eot => eot.workperformedby === "contractor").length}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Change of Scope Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.coss?.filter(eot => eot.workperformedby === "contractor").length}</th>
                  </tr >

                </table >
              }
            </div >

          </div >


          <div className="jd-heading-outer">
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Consultant Details</h2>
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>

          <div className="info-box-div">
            <div className="personal-detail">
              {/* <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3> */}
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultancy Fees</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {projectData?.consultancy_fees}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultant Award</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.award_date ? formattedDateLong(projectData?.award_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultant Start</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.start_date ? formattedDateLong(projectData?.start_date) : "-"}</th>
                  </tr >
                </table >
              }
            </div >
            <div className="personal-detail">
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.eots?.filter(eot => eot.workperformedby === "consultant").length}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Change of Scope Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.coss?.filter(eot => eot.workperformedby === "consultant").length}</th>
                  </tr >
                </table >
              }
            </div >
          </div >
        </div >
        <>
          <div className="bloc-tabss wfm-marginn">
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(1)}
            >
              Bills
            </button>
            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs "
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(2)}
            >
              EOT
            </button>
            <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(3)}
            >
              COS
            </button>
            <button
              className={
                toggleState === 4
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(4)}
            >
              Employee
            </button>

            <button
              className={
                toggleState === 5
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(5)}
            >
              Letters
            </button>
            <button
              className={
                toggleState === 6
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(6)}
            >
              Vehicles
            </button>
            <button
              className={
                toggleState === 7
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(7)}
            >
              Milestone
            </button>
          </div>

          <div className="info-box-div-bg">
            {toggleState === 1 ?
              <>
                <BillTable projectId={projectId} />
              </>
              : null}
            {toggleState === 2 ?
              <>
                <EOTTable projectId={projectId} />
              </>
              : null}
            {toggleState === 3 ?
              <>
                <COSTable projectId={projectId} />
              </>
              : null}
            {toggleState === 4 ? <>
              <EmployeeTable projectId={projectId} />
            </> : null}
            {toggleState === 5 ? <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Letters</h2>
                  {/* button here */}
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div></> : null}
            {toggleState === 6 ? <>
              <div className="jd-heading-outer">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">Vehicles</h2>
                  {/* button here */}
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div></> : null}
            {toggleState === 7 ? <>
              <MilestoneTable projectId={projectId} appointed_date={projectData.appointed_date} />
            </> : null}
          </div>
        </>

      </div >
    </>
  );
};

const ProjectContractorDetails = () => {

  const location = useLocation();
  const projectId = location.state ? location.state.project.id : null;


  const [buffer, setBuffering] = useState(false); //buffering logic

  // *********** Employee Details Api Start ***********

  const [projectData, setProjectsData] = useState([]);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);




  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);

      const proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);
      setProjectsData(proj.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjects();
  }, []);
  // *********** Employee Details Api End ***********












  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dashboardnavbarcopy name={`Project Details`} url="Site Project" />

      <div className="content-tabs">
        <div className="info-box-div-bg">

          <div className="jd-heading-outer">
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Project Contractor Details of "{projectData?.site_prcode} : {projectData?.project_short_name ? projectData?.project_short_name : "-"}"</h2>
              <UpdateProjectAllDetails i={projectData} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text ">Project Name</td>
            <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_name}</th>
          </tr>
          <div className="info-box-div">
            <div className="personal-detail">
              {/* <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3> */}
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Tender Id</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.tender_id}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Location</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.location}</th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Construction Cost</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {projectData?.construction_cost}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Name</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.contractor_name}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contract Mode</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.contract_mode}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Lead</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.lead}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">JV</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">JV Share</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv_share}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Association</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.association}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Employee Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.employees?.length}</th>
                  </tr >
                </table >
              }
            </div >
            <div className="personal-detail">
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">


                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_completion_date ? formattedDateLong(projectData?.project_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Actual Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_completion_date ? formattedDateLong(projectData?.actual_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Agreement Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.agreement_date ? formattedDateLong(projectData?.agreement_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Appointed Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.appointed_date ? formattedDateLong(projectData?.appointed_date) : "-"}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Status</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.status}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Remark</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.remark}</th>
                  </tr >

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.eots?.filter(eot => eot.workperformedby === "contractor").length}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Change of Scope Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.coss?.filter(eot => eot.workperformedby === "contractor").length}</th>
                  </tr >

                </table >
              }
            </div >

          </div >
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text ">Project Key Point</td>
            <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_key_point}</th>
          </tr >
        </div >

      </div >
    </>
  );
};

const ProjectConsultantDetails = () => {

  const location = useLocation();
  const projectId = location.state ? location.state.project.id : null;


  const [buffer, setBuffering] = useState(false); //buffering logic

  const [projectData, setProjectsData] = useState([]);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);




  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);

      const proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);
      setProjectsData(proj.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };



  useEffect(() => {
    getProjects();
  }, []);










  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dashboardnavbarcopy name={`Project Details`} url="Site Project" />

      <div className="content-tabs">
        <div className="info-box-div-bg">

          <div className="jd-heading-outer">
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Project Consultant Details of "{projectData?.site_prcode} : {projectData?.project_short_name ? projectData?.project_short_name : "-"}"</h2>
              <UpdateProjectAllDetails i={projectData} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text ">Project Name</td>
            <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_name}</th>
          </tr>
          <div className="info-box-div">
            <div className="personal-detail">
              {/* <h3 className="personal-detail-heading   font-weight500  font-size-heading">Primary Details</h3> */}
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Location</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.location}</th>
                  </tr>

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultancy Fees</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {projectData?.consultancy_fees}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Lead</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.lead}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">JV</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">JV Share</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv_share}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Association</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.association}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Employee Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.coss?.filter(eot => eot.workperformedby === "consultant").length}</th>
                  </tr >
                </table >
              }
            </div >
            <div className="personal-detail">
              {buffer ? <div className="spinner-small"></div> :
                <table className="width-40vw">

                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultant Award</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.award_date ? formattedDateLong(projectData?.award_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Consultant Start</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.start_date ? formattedDateLong(projectData?.start_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_completion_date ? formattedDateLong(projectData?.project_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Actual Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_completion_date ? formattedDateLong(projectData?.actual_completion_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Agreement Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.agreement_date ? formattedDateLong(projectData?.agreement_date) : "-"}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Status</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.status}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Remark</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.remark}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Tender Id</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.tender_id}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.eots?.filter(eot => eot.workperformedby === "consultant").length}</th>
                  </tr >
                  <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Change of Scope Count</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.coss?.filter(eot => eot.workperformedby === "consultant").length}</th>
                  </tr >

                </table >
              }
            </div >

          </div >
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text ">Project Key Point</td>
            <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_key_point}</th>
          </tr >
        </div >

      </div >
    </>
  );
};


export {
  SiteProjectBillDetails,
  ProjectAllDetails,
  ProjectContractorDetails,
  ProjectConsultantDetails,
};
