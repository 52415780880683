import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';

const SuccessModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="" onClick={handleShow} >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15 4.5L6.75 12.75L3 9"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>

            <Modal show={show} onHide={handleClose} dialogClassName='custom-notify-modal'>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{ padding: 0 }}>
                    <div className="Color-tab-green" style={{ borderRadius: '25px' }}>
                        <div className="notify_modal_body_div">
                            <div className='flex-column'>
                                <button className="notify_modal_logo success_color">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M15 4.5L6.75 12.75L3 9"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>

                                </button>
                            </div>
                            <div className='flex-column'>
                                <h6 className="  font-weight600  font-size-heading success_color_text">Success:</h6>
                                <h3 className="notify_modal_content  font-weight500 font-size-subheading  ">Your changes are saved successfully</h3>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const NeutralModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div style={{ padding: '0px 10px' }} onClick={handleShow} >
                n
            </div>

            <Modal show={show} onHide={handleClose} dialogClassName='custom-notify-modal'>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{ padding: 0 }}>
                    <div className="Color-tab-grey" style={{ borderRadius: '25px' }}>
                        <div className="notify_modal_body_div">
                            <div className='flex-column'>
                                <button className="notify_modal_logo neutral_color" style={{ color: 'white' }}>
                                    i
                                </button>
                            </div>
                            <div className='flex-column'>
                                <h6 className="  font-weight600  font-size-heading neutral_color_text">Neutral:</h6>
                                <h3 className="notify_modal_content  font-weight500 font-size-subheading  ">This is just text</h3>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const AlertModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="" onClick={handleShow} >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clipPath="url(#clip0_1297_50061)">
                        <path
                            d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z"
                            fill="#A3AED0"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1297_50061">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <Modal show={show} onHide={handleClose} dialogClassName='custom-notify-modal'>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{ padding: 0 }}>
                    <div className="Color-tab-yellow" style={{ borderRadius: '25px' }}>
                        <div className="notify_modal_body_div">
                            <div className='flex-column'>
                                <button className="notify_modal_logo alert_color" style={{ color: 'white' }}>
                                    !
                                </button>
                            </div>
                            <div className='flex-column'>
                                <h6 className="  font-weight600  font-size-heading alert_color_text">Alert:</h6>
                                <h3 className="notify_modal_content  font-weight500 font-size-subheading  ">Please confirm with password</h3>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const InformationModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div style={{ padding: '0px 10px' }} onClick={handleShow} >
                i
            </div>

            <Modal show={show} onHide={handleClose} dialogClassName='custom-notify-modal'>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{ padding: 0 }}>
                    <div className="Color-tab-blue" style={{ borderRadius: '25px' }}>
                        <div className="notify_modal_body_div">
                            <div className='flex-column'>
                                <button className="notify_modal_logo info_color" style={{ color: 'white' }}>
                                    i
                                </button>
                            </div>
                            <div className='flex-column'>
                                <h6 className="  font-weight600  font-size-heading info_color_text">Information:</h6>
                                <h3 className="notify_modal_content  font-weight500 font-size-subheading  ">There are some information for you</h3>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const ErrorModal = ({ message, show, onHide }) => {


    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                onHide();  // Call onHide after 5 seconds
            }, 2000);  // 5000 milliseconds = 5 seconds

            return () => clearTimeout(timer);  // Clear the timer if the component unmounts
        }
    }, [show, onHide]);

    return (
        <>

            <Modal show={show} onHide={onHide} dialogClassName='custom-notify-modal'>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{ padding: 0 }}>
                    <div className="Color-tab-red" style={{ borderRadius: '25px' }}>
                        <div className="notify_modal_body_div">
                            <div className='flex-column'>
                                <button className="notify_modal_logo error_color">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.5 4.5L4.5 13.5"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M4.5 4.5L13.5 13.5"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>

                                </button>
                            </div>
                            <div className='flex-column'>
                                <h6 className="  font-weight600  font-size-heading error_color_text">Error:</h6>
                                <h3 className="notify_modal_content  font-weight500 font-size-subheading  ">{message}</h3>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export {
    SuccessModal, NeutralModal, AlertModal, InformationModal, ErrorModal
};
// export default SuccessModal
