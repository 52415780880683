import React, { useState, useEffect } from "react";
import {
  TlCalenderTable,
  TLintercomTable,
} from "./Organizationtables";
import Tree from "react-d3-tree";
import axios from "axios";
import { BASE_URL } from "../../config/axios";



const Hierarchy = () => {
  const [treeData, setTreeData] = useState([]);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {

        // const response = await axios.get(`${BASE_URL}/wfm/herasubcomp/${sessionStorage.getItem('company_id')}/`);
        const response = await axios.get(`${BASE_URL}/wfm/herasubcomp/025d0b52-e892-4868-a3bb-6b70dcdffb4d/`);
        // const response = await axios.get(`${BASE_URL}/wfm/hera/`);
        const transformedData = transformData(response.data);

        setTreeData([transformedData]);
        setInitialTranslate();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const setInitialTranslate = () => {
    const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();

    setTranslate({ x: dimensions.width / 2, y: 50 });
  };

  const transformData = (data) => {
    const transformNode = (node) => ({
      name: node.user_detail.name,
      attributes: {
        position: node.designation_name,
        department: node.department_name,
        // profilepic: node.profilepic,
      },
      children: node.subordinates ? node.subordinates.map(transformNode) : [],
    });


    return transformNode(data[0]);
  };



  const CustomLabel = ({ nodeData }) => {
    const profilePicUrl = nodeData.attributes.profilepic
      ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`
      : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    return (
      <div style={{
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        background: '#fff',
        width: '150px',
        height: '150px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <img
          src={profilePicUrl}
          alt={`${nodeData.name}'s profile`}
          style={{ borderRadius: '50%', width: '50px', height: '50px', marginBottom: '10px' }}
          onError={(e) => { e.target.src = 'https://via.placeholder.com/50'; }}
        />
        <strong>{nodeData.name}</strong>
        <div>{nodeData.attributes.position}</div>
        <div>{nodeData.attributes.department}</div>
      </div>
    );
  };

  return (
    <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
      {treeData.length > 0 ? (
        <Tree
          data={treeData}
          orientation="vertical"
          translate={translate}
          nodeSvgShape={{
            shape: 'none',
          }}
          pathFunc="step"
          styles={{
            links: {
              stroke: '#2576BC',
              strokeWidth: 5,
            },
          }}
          nodeLabelComponent={{
            render: <CustomLabel />,
            foreignObjectWrapper: {
              y: -50,
              x: -75,
              width: 150,
              height: 150,
            },
          }}
          collapsible
          initialDepth={1}
          separation={{ siblings: 2, nonSiblings: 2 }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};


const CustomLabel = ({ nodeData }) => (
  <foreignObject width={120} height={50}>
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>{nodeData.name}</div>
      <div style={{ fontSize: "10px", color: "gray" }}>
        {nodeData.attributes.position}
      </div>
    </div>
  </foreignObject>
);

const HodOrgstructure = () => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <>
      <div>
        <div>
          <div className="bloc-tabs-with-white-bg">
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(1)}
            >
              Hierarchy
            </button>
            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(2)}
            >
              Calender table
            </button>
            <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => setToggleState(3)}
            >
              InterCom
            </button>





          </div>
        </div>

        <div className="content-tabs-without-margin">
          {toggleState === 1 ? <Hierarchy /> : null}

          {toggleState === 2 ? <TlCalenderTable /> : null}

          {toggleState === 3 ? <TLintercomTable /> : null}




        </div>
      </div>
    </>
  );
};

export default HodOrgstructure;
